import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";

// Assets
import classes from "./ProfileForm.module.css";

// Components
import { SaveIcon } from "components/UI/icons";
import CustomInput from "components/CustomInput/CustomInput.js";

// Utils
import { validateRequired } from "utils";
import { Typography } from "@material-ui/core";

export const ProfileForm = ({
  type = "add",
  initialValues = null,
  onSubmit = () => {}
}) => {
  const componentRef = useRef(null);
  const [values, setValues] = useState(initialValues);
  const [isSubmitted, setIsSubmitted] = useState(null);
  const [fieldValidations, setFieldValidations] = useState(null);
  const isFieldRequired = true;
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [passwordEdit, setPasswordEdit] = useState(false);

  useEffect(() => {
    validateHandler("user", values?.user, validateRequired, isFieldRequired);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const componentClasses = `${classes.component} ${
    type === "update" ? classes.update : classes.add
  }`;

  function fieldHandler(propLabel, value) {
    setIsSubmitted(false);
    setFieldValidations((prevState) => {
      return {
        ...prevState,
        [propLabel]: {
          isValid: true
        }
      };
    });
    setValues((prevState) => {
      return { ...prevState, [propLabel]: value };
    });
  }

  function validateHandler(
    propLabel,
    value,
    validateFunction,
    isRequired = false
  ) {
    setIsSubmitted(false);
    if (isRequired && (typeof value === "undefined" || value === "" || !value))
      setFieldValidations((prevState) => {
        return { ...prevState, [propLabel]: { isValid: false } };
      });
    else {
      const isValid = validateFunction(value, isRequired);
      setFieldValidations((prevState) => {
        return { ...prevState, [propLabel]: isValid };
      });
    }
  }

  function checkFieldError(propLabel) {
    return fieldValidations?.[propLabel]?.isValid === false;
  }

  function getErrorMessage(propLabel) {
    return fieldValidations?.[propLabel]?.errorMessage;
  }

  function validatePasswords() {
    const newPassword = values?.newPassword;
    const newPassword2 = values?.newPassword2;
    if (newPassword === newPassword2) return;
    else {
      setFieldValidations((prevState) => {
        return {
          ...prevState,
          newPassword: {
            isValid: false
          },
          newPassword2: {
            isValid: false
          }
        };
      });
      setValues((prevState) => {
        return { ...prevState, newPassword: "", newPassword2: "" };
      });
    }
  }

  function validateForm() {
    passwordEdit && validatePasswords();
    validateHandler("user", values?.user, validateRequired, isFieldRequired);
    setIsSubmitted(true);
  }

  useEffect(() => {
    if (isSubmitted) {
      if (passwordEdit) {
        if (
          fieldValidations?.user?.isValid === true &&
          fieldValidations?.newPassword?.isValid === true &&
          fieldValidations?.newPassword2?.isValid === true
        ) {
          onSubmit(values);
          setValues((prevState) => {
            return {
              ...prevState,
              oldPassword: "",
              newPassword: "",
              newPassword2: ""
            };
          });
          setBtnDisabled(false);
          setPasswordEdit(false);
        }
      } else if (fieldValidations?.user?.isValid === true) {
        onSubmit(values);
        setBtnDisabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValidations, isSubmitted]);

  const btnClasses = `${classes.btn} ${btnDisabled ? classes.disabled : ""}`;
  const Button = ({ ...props }) => (
    <button type="submit" className={btnClasses} {...props}>
      {props.children}
    </button>
  );

  function formSubmitHandler() {
    componentRef.current.handleSubmit();
  }

  const saveBtnComponent = (
    <div className={classes.btn_container}>
      <Button
        onClick={(event) => {
          event.preventDefault();
          formSubmitHandler();
        }}
        variant="save"
      >
        <SaveIcon className={classes.icon} />
        <Typography uppercase>Salva</Typography>
      </Button>
    </div>
  );

  function oldPasswordHandler(oldPassword) {
    if (oldPassword?.length > 0) {
      setPasswordEdit(true);
      setBtnDisabled(true);
    } else {
      setValues((prevState) => {
        return { ...prevState, newPassword: "", newPassword2: "" };
      });
      setPasswordEdit(false);
      setBtnDisabled(false);
    }
  }

  useEffect(() => {
    if (values?.newPassword && values?.newPassword2) setBtnDisabled(false);
  }, [values?.newPassword, values?.newPassword2]);

  return (
    <div className={componentClasses}>
      <div className={classes.content}>
        <Formik
          innerRef={componentRef}
          initialValues={{ ...values }}
          onSubmit={validateForm}
        >
          <form>
            <Typography variant="h5">Informazioni Account</Typography>
            <div className={classes.row}>
              <Typography style={{ marginTop: "3vh" }}>Ufficio</Typography>
              <CustomInput
                inputProps={{
                  disabled: true,
                  value: values?.office
                }}
              />
            </div>
            <div className={classes.row}>
              <Typography style={{ marginTop: "3vh" }}>Username</Typography>
              <CustomInput
                inputProps={{
                  disabled: true,
                  value: values?.username
                }}
                error={checkFieldError("username")}
              />
            </div>
            <div className={classes.row}>
              <Typography style={{ marginTop: "3vh" }}>Utente</Typography>
              <CustomInput
                inputProps={{
                  error: checkFieldError("user"),
                  disabled: false,
                  value: values?.user,
                  onChange: (event) => fieldHandler("user", event.target.value)
                }}
              />
            </div>
            <div className={classes.password_container}>
              <Typography variant="h5">Reset Password</Typography>
              <div className={classes.row}>
                <Typography style={{ marginTop: "3vh" }}>
                  Vecchia Password
                </Typography>
                <CustomInput
                  inputProps={{
                    type: "password",
                    value: values?.oldPassword,
                    onChange: (event) => {
                      fieldHandler("oldPassword", event.target.value);
                    },
                    onBlur: () => oldPasswordHandler(values?.oldPassword),
                    error: checkFieldError("oldPassword")
                  }}
                />
              </div>
              <div className={classes.row}>
                <Typography style={{ marginTop: "3vh" }}>
                  Nuova Password
                </Typography>
                <CustomInput
                  inputProps={{
                    type: "password",
                    disabled: !passwordEdit,
                    value: values?.newPassword,
                    onChange: (event) =>
                      fieldHandler("newPassword", event.target.value),
                    error: checkFieldError("newPassword")
                  }}
                />
              </div>
              <div className={classes.row}>
                <Typography style={{ marginTop: "3vh" }}>
                  Nuova Password
                </Typography>
                <CustomInput
                  inputProps={{
                    type: "password",
                    disabled: !passwordEdit,
                    value: values?.newPassword2,
                    onChange: (event) =>
                      fieldHandler("newPassword2", event.target.value),
                    error: checkFieldError("newPassword2")
                  }}
                />
              </div>
            </div>
            {saveBtnComponent}
          </form>
        </Formik>
      </div>
    </div>
  );
};
