import React from "react";
import LoaderSpinner from "../../Layout/LoaderSpinner";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import Card from "../../Card/Card";
import CardHeader from "../../Card/CardHeader";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "../../CustomButtons/Button";
import UndoIcon from "@material-ui/icons/Undo";
import CardBody from "../../Card/CardBody";
import CustomInput from "../../CustomInput/CustomInput";
import CardFooter from "../../Card/CardFooter";
import ButtonSaveWithLoading from "../../CustomButtons/ButtonSaveWithLoading";

class ModificaEmail extends React.Component {

    state = {
        selectedEmailUtente: null,
        title: null
    }

    constructor(props) {
        super(props);
        this.SetText = this.SetText.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
    }

    onSaveClick() {
        return this.props.onSaveClick(this.state.selectedEmailUtente);
    }

    SetText(field, event) {
        let selectedEmailUtente = this.state.selectedEmailUtente;
        selectedEmailUtente[field] = event.target.value;
        this.setState({
            selectedEmailUtente: selectedEmailUtente
        });
    }

    componentDidMount() {
        this.setState({
            selectedEmailUtente: this.props.selectedEmailUtente,
            title: this.props.selectedEmailUtente.id === 0 ? 'Nuova' : 'Modifica'
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedEmailUtente.id !== prevProps.selectedEmailUtente.id) {
            this.setState({
                selectedEmailUtente: this.props.selectedEmailUtente,
                title: this.props.selectedEmailUtente.id === 0 ? 'Nuova' : 'Modifica'
            });
        }
    }

    render() {
        if (this.state.selectedEmailUtente === null)
            return <LoaderSpinner fullHeight={false} />;
        else
            return (
                <div>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="success">
                                    <h4
                                        style={{
                                            lineHeight: "0",
                                            display: "inline",
                                            verticalAlign: "bottom"
                                        }}
                                    >
                                        {this.state.title} Email
                                    </h4>
                                    <Tooltip placement="top" classes={{ tooltip: "tooltip-me" }}
                                             arrow id="tooltip-top" title="Annulla"
                                    >
                                        <Button
                                            style={{ verticalAlign: "center", lineHeight: "1", margin: "0.1%" }}
                                            color="white" aria-label="edit" justIcon round className="float-right"
                                            onClick={this.props.onAnnullaClick}
                                        >
                                            <UndoIcon />
                                        </Button>
                                    </Tooltip>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Utente"
                                                id="id-disabled"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    disabled: true,
                                                    value: this.state.selectedEmailUtente.utente ?? ""
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Email"
                                                id="email"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: event => this.SetText('email', event),
                                                    value: this.state.selectedEmailUtente?.email ?? ""
                                                }}
                                            />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={4}>
                                            <CustomInput
                                                labelText="Password"
                                                id="password"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    onChange: event => this.SetText('password', event),
                                                    value: this.state.selectedEmailUtente?.password ?? "",
                                                    type: "password"
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter>
                                    <ButtonSaveWithLoading
                                        onClick={this.onSaveClick}
                                        text={"SALVA"}
                                        isValidForm={() => true}
                                    />
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            );
    }
}

export default ModificaEmail;