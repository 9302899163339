import Utils from "./Utils";

const EmailUtenteAPI = {

    GetByUtente: async function (utente) {
        const url = 'coasco/email_utente/getByUtente?utente=' + utente;
        const response = await Utils.CreatePostRequest(url);
        if (response?.message?.includes('200'))
            return response.result;
        else return null;
    },

    Delete: async function (id) {
        const url = 'coasco/email_utente/delete?id=' + id;
        const response = await Utils.CreateDeleteRequest(url);
        if (response?.message?.includes('200'))
            return response.result;
        else return null;
    },

    NewEmailUtente: function (utente) {
        return {
            email: null,
            password: null,
            utente: utente,
            id: 0
        };
    },

    Insert: async function (emailUtente) {
        const url = 'coasco/email_utente/insert';
        const response = await Utils.CreatePostRequest(url, emailUtente);
        if (response?.message?.includes('200'))
            return response.result;
        else return null;
    },

    Update: async function (emailUtente) {
        const url = 'coasco/email_utente/update';
        const response = await Utils.CreatePutRequest(url, emailUtente);
        if (response?.message?.includes('200'))
            return response.result;
        else return null;
    },

};

export default EmailUtenteAPI;