import Utils from "./Utils";
import * as AppConstants from "../helpers/AppConstants";

const partialURL = "ebinprof/RichiesBSResult";
const baseURL = AppConstants.SiteUrl + partialURL;

const StatisticheRichiesteBorseAPI = {
  getRichiesteAnnoTipoPervenute: async function (year) {
    return await Utils.SendPostRequestTo(
      `${baseURL}/getRichiesteAnnoTipoPervenute?anno=${year}`
    );
  },

  getRichiesteAnnoTipoSesso: async function (year) {
    return await Utils.SendPostRequestTo(
      `${baseURL}/getRichiesteAnnoTipoSesso?anno=${year}`
    );
  },

  getRichiesteAnnoTipoProvincia: async function (year) {
    return await Utils.SendPostRequestTo(
      `${baseURL}/getRichiesteAnnoTipoProvincia?anno=${year}`,
    );
  }
};

export default StatisticheRichiesteBorseAPI;
