import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Switch from "@material-ui/core/Switch";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AziendaHeader } from "components/Views/Aziende/AziendaHeader.js";
import Helpers from "helpers/Helpers.js";
import VersamentiAPI from "API/VersamentiAPI";
import {rowsPerPageOptions} from "../../helpers/AppConstants";

class VersamentiTrasferiti extends React.Component {
  state = {
    rows: [],
    rowsRicevuti: [],
    selectedGrid: null,
    loadingGrid: false,
    loadingGridRicevuti: false,
    filterGridRicevuti: null,
    filterGrid: null,
    selectedGridRicevuti: null,

    matricola: null
  };

  constructor(props) {
    super(props);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.ChangeFilterGridRicevuti = this.ChangeFilterGridRicevuti.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.handleTableClickRicevuti = this.handleTableClickRicevuti.bind(this);
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true,
      loadingGridRicevuti: true
    });

    let aziendaId = this.props.match.params.versamentiId;
    let year = this.props.match.params.year;
    const codiceQuota = this.props.match.params.codiceQuota;

    let rowsTrasferiti = await VersamentiAPI.GetVersamentiTrasferiti(
      aziendaId,
      year,
      codiceQuota
    );
    let rowsRicevuti = await VersamentiAPI.GetVersamentiRicevuti(
      aziendaId,
      year,
      codiceQuota
    );

    this.setState({
      rows: rowsTrasferiti,
      rowsRicevuti: rowsRicevuti
    });
    this.setState({
      loadingGrid: false,
      loadingGridRicevuti: false
    });
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  async ChangeFilterGridRicevuti(e) {
    this.setState({ filterGridRicevuti: e.target.checked });
  }

  handleTableClick(e) {
    this.setState({
      selectedGrid: e.value
    });
  }

  handleTableClickRicevuti(e) {
    this.setState({
      selectedGridRicevuti: e.value
    });
  }
  render() {
    return (
      <>
        <AziendaHeader
          aziendaId={this.props.match.params.versamentiId}
          {...this.props}
        />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Versamenti Trasferiti Verso
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10} rowsPerPageOptions={rowsPerPageOptions}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  loading={this.state.loadingGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="causale"
                    headerStyle={{ width: "120px" }}
                    header="Causale"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="matricolaorig"
                    header="Matricola Orig."
                    headerStyle={{ width: "170px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="matricoladest"
                    header="Matricola Dest."
                    headerStyle={{ width: "170px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="codqorig"
                    header="Cod. Quota Orig."
                    headerStyle={{ width: "190px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="codqdest"
                    header="Cod. Quota Dest."
                    headerStyle={{ width: "190px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="comporig"
                    header="Comp. Orig."
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="compdest"
                    header="Comp. Dest."
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importo"
                    header="Importo"
                    headerStyle={{ width: "150px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "importo")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="datatrasf"
                    header="Data Trasf."
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Versamenti Ricevuti DA
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGridRicevuti}
                      onChange={this.ChangeFilterGridRicevuti}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rowsRicevuti}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10} rowsPerPageOptions={rowsPerPageOptions}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGridRicevuti}
                  loading={this.state.loadingGridRicevuti}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClickRicevuti}
                  selectionMode="single"
                >
                  <Column
                    field="causale"
                    headerStyle={{ width: "120px" }}
                    header="Causale"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="matricolaorig"
                    header="Matricola Orig."
                    headerStyle={{ width: "170px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="matricoladest"
                    header="Matricola Dest."
                    headerStyle={{ width: "170px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="codqorig"
                    header="Cod. Quota Orig."
                    headerStyle={{ width: "190px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="codqdest"
                    header="Cod. Quota Dest."
                    headerStyle={{ width: "190px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="comporig"
                    header="Comp. Orig."
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="compdest"
                    header="Comp. Dest."
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importo"
                    header="Importo"
                    headerStyle={{ width: "150px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "importo")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="datatrasf"
                    header="Data Trasf."
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default VersamentiTrasferiti;
