import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Switch from "@material-ui/core/Switch";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Dropdown } from "primereact/dropdown";
import Helpers from "helpers/Helpers.js";
import CardFooter from "components/Card/CardFooter.js";
import ButtonsMUI from "@material-ui/core/Button";
import { Calendar } from "primereact/calendar";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import CassaAPI from "API/CassaAPI";
import moment from "moment";
import {dateTimeFmt, rowsPerPageOptions} from "helpers/AppConstants";
import { dateFmt } from "helpers/AppConstants";

class AllineaCassa extends React.Component {
  state = {
    rows: [],
    selectedGrid: null,
    loadingGrid: false,

    dataincasso: new Date(),
    totalRows: "",
    banca: null,
    listaBanche: null
  };

  constructor(props) {
    super(props);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  async refreshGrid() {
    this.setState({
      selectedGrid: null,
      loadingGrid: true
    });
    let rw = await CassaAPI.GetAssociaPagamentiForAllineaCassa(null);
    let banche = await CassaAPI.banche();
    this.setState({
      rows: rw,
      listaBanche: banche
    });
    this.setState({
      loadingGrid: false
    });
  }

  handleTableClick = async (e) => {
    let i;
    let total = 0;
    let d = "";

    for (i = 0; i < e.value.length; i++) {
      total += e.value[i].diff;
      d =
        Helpers.DecimalInputTemplate(total).props.children[0] +
        Helpers.DecimalInputTemplate(total).props.children[1];
    }

    this.setState({
      selectedGrid: e.value,
      totalRows: d
    });
  };

  upload = async () => {
    if (
      this.state.selectedGrid !== null &&
      this.state.banca !== null &&
      this.state.dataincasso !== null
    ) {
      await CassaAPI.AddCassa(
        this.state.selectedGrid,
        this.state.banca,
        this.state.dataincasso
      );
      await this.refreshGrid();
    } else {
      this.props.addAlert.current.addAlert(
        "warning",
        "Assicurarsi di aver selezionato i campi necessari"
      );
    }
  };

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Associazione Pagamenti a Tabulati
                </h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4} className="field">
                    <p className="lblNoMargin">Banca</p>
                    <Dropdown
                      value={this.state.banca}
                      optionValue="cod_banca"
                      optionLabel="denominazione"
                      className="dropdownCustomisedForMUIGian"
                      options={this.state.listaBanche}
                      onChange={(e) => this.HandleChange("banca", e.value)}
                      filter
                      filterBy="name"
                      resetFilterOnHide
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <p className="lblNoMargin">Data Incasso</p>
                    <Calendar
                      id="icon"
                      value={this.state.dataincasso}
                      placeholder="Data incasso"
                      dateFormat="dd/mm/yy"
                      onChange={(e) =>
                        this.HandleChange("dataincasso", e.value)
                      }
                      showIcon
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={4}
                    style={{ marginTop: "12px" }}
                  >
                    <ButtonsMUI
                      className="float-right"
                      variant="contained"
                      onClick={this.upload}
                      color="secondary"
                    >
                      <PublishRoundedIcon /> Carica in cassa
                    </ButtonsMUI>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofsuccess">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Allinea Cassa per Tabulati
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10} rowsPerPageOptions={rowsPerPageOptions}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  loading={this.state.loadingGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="multiple"
                  metaKeySelection={false}
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "50px" }}
                  />
                  <Column
                    field="datacaric"
                    header="Data Caric."
                    headerStyle={{ width: "140px" }}
                    body={(row) =>
                      moment(row.datacaric, dateTimeFmt).format(dateFmt)
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="codquota"
                    header="Cod. Quota"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="sede"
                    header="Sede"
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="riparto"
                    header="Riparto"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="competenza"
                    header="Competenza"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="numrighe"
                    header="Num. Righe"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importo"
                    header="Importo"
                    headerStyle={{ width: "110px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "importo")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="accr"
                    header="Accreditato"
                    headerStyle={{ width: "150px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "accr")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="diff"
                    headerStyle={{ width: "150px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "diff")
                    }
                    header="Differenza"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>

              <CardFooter>
                <GridContainer>
                  <GridItem xs={10} />
                  <GridItem xs={2}>
                    <CustomInput
                      labelText="Totale selezionato"
                      id="total"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.totalRows
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default AllineaCassa;
