import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { AziendaHeader } from "components/Views/Aziende/AziendaHeader.js";
import Button from "components/CustomButtons/Button.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import YearPicker from "components/YearPicker/index";
import ButtonsMUI from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import InserimentoUniemens from "components/Views/Uniemens/InserimentoUniemens.js";
import queryString from "query-string";
import AziendeAPI from "API/AziendeAPI";
import LoaderSpinner from "components/Layout/LoaderSpinner.js";
import DipUniAPI from "API/DipUniAPI";
import DipendentiAPI from "API/DipendentiAPI";
import {
  DialogContentText,
  DialogContent,
  DialogActions, Tooltip
} from "@material-ui/core";
import helpers from "helpers/Helpers";
import { dateTimeFmt } from "helpers/AppConstants";
import { dateFmt } from "helpers/AppConstants";
import moment from "moment";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

class UniemensXAzienda extends Component {

  state = {
    rowsCust: [],
    rowsPuli: [],

    selectedGridCust: null,
    selectedGridPuli: null,
    selectedUniemens: null,

    filterCustodi: null,
    visibleLoader: false,
    filterPulitori: null,

    isChecked: false,
    codiceQuota: null,

    competenzaAnno: new Date().getFullYear(),
    loadingGridCust: true,
    loadingGridPuli: true,
    radioChecked: null,
    selectedLoadingType: null,
    title: "Tutti",
    isVisible: false,
    azienda: {},
    employees: null,
    noEmployees: false, // forwardRef for Dialog
    VisibleButtons: false,
    cfVistiPuli: [],
    cfVistiCust: [],
    firstCust: 0,
    firstPuli: 0,
    rowsNumCust: 12,
    rowsNumPuli: 12,
    puliSortOrder: 1,
    custSortOrder: 1,
    custSortField: "dipendente.cf",
    puliSortField: "dipendente.cf",

    rdlNotExistsPuli: false,
    rdlNotExistsCust: false,
    selectedCf: null
  };

  constructor(props) {
    super(props);
    this.ChangeFilter = this.ChangeFilter.bind(this);
    this.ChangeFilterPulitori = this.ChangeFilterPulitori.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.handleTableClickPulitori = this.handleTableClickPulitori.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.IncreaseYear = this.IncreaseYear.bind(this);
    this.DecreaseYear = this.DecreaseYear.bind(this);
    this.RadioHandleChange = this.RadioHandleChange.bind(this);
    this.EyeButton = this.EyeButton.bind(this);
    this.onEditFittizi = this.onEditFittizi.bind(this);
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
    this.btnSearchRef = React.createRef();
    this.insertNegatives = this.insertNegatives.bind(this);
    this.changeCodQ = this.changeCodQ.bind(this);
    this.getSelectedUniemens = this.getSelectedUniemens.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortHandler = this.onSortHandler.bind(this);
    this.redirectToRDLSearch = this.redirectToRDLSearch.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
    this.showInserisciRDL = this.showInserisciRDL.bind(this);

  }

  redirectToRDLSearch(codiceQuota) {
    let cf;
    try{
      if (codiceQuota === "CUST") {
        cf = this.state.selectedGridCust[0].dipendente.cf;
      } else {
        cf = this.state.selectedGridPuli[0].dipendente.cf;
      }
    } catch(err){}

    cf=this.state.selectedCf;

    const employee = this.state.employees.find((e) => e.cf === cf);
    this.props.history.push(
      `/admin/RDL/${this.props.match.params.aziendaId}/${employee.id}?type=fromUniemens`
    );
  }

  async onAnnullaClick() {
    this.setState(
      {
        isVisible: false
      },
      this.refreshGrid
    );
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }
 
  async showInserisciRDL(obj){
    if(!!obj && obj.cod_quota === 'PULI'){
      this.setState({ rdlNotExistsPuli: true, selectedCf: obj.cf });

    } else if(!!obj && obj.cod_quota === 'CUST'){
      this.setState({ rdlNotExistsCust: true, selectedCf: obj.cf });
    }
  }

  async componentDidMount() {
    let qS = queryString.parse(this.props.location.search);
    if (!qS.type)
      this.setState({
        VisibleButtons: false
      });
    else if (qS.type === "fromVersamenti")
      this.setState({
        VisibleButtons: true
      });

    let aziendaId = this.props.match.params.aziendaId;
    const aziendas = await AziendeAPI.GetAziendaById(aziendaId);
    const employees = await DipendentiAPI.GetDipendentiForAzienda(aziendaId);
    const year = localStorage.getItem("year");

    if (qS.type === "fromVersamenti" && year) {
      this.setState({competenzaAnno: year});
    }

    this.setState({
      isVisible: qS.open === "1",
      noEmployees: employees.length === 0,
      employees: employees,
      azienda: aziendas[0]
    });
    await this.refreshGrid();
  }

  async handleDateChange(dateParam) {
    this.setState(
      {
        competenzaAnno: dateParam
      },
      async () => await this.refreshGrid()
    );
  }

  async ChangeFilter(e) {
    this.setState({ filterCustodi: e.target.checked });
  }

  async ChangeFilterPulitori(e) {
    this.setState({ filterPulitori: e.target.checked });
  }

  onEditFittizi(uniemens) {
    this.setState({
      selectedUniemens: uniemens,
      isVisible: true
    });
  }

  async IncreaseYear() {
    this.setState(
      {
        competenzaAnno: parseInt(this.state.competenzaAnno) + 1
      },
      async () => await this.refreshGrid()
    );
  }

  async DecreaseYear() {
    this.setState(
      {
        competenzaAnno: parseInt(this.state.competenzaAnno) - 1
      },
      async () => await this.refreshGrid()
    );
  }

  EyeButton(uniemens) {
    if (uniemens.descr_caric === "Manuale")
      return (
        <>
          <Button
            round
            color="info"
            className="actionButton actionButtonRound btnRound  "
            key={uniemens.id}
            onClick={() =>
              this.setState({
                selectedUniemens: uniemens,
                isVisible: true
              })
            }
          >
            <VisibilityIcon className="icon" />
          </Button>
        </>
      );
  }

  async refreshGrid() {
    let aziendaId = this.state.azienda.id;

    this.setState({ loadingGridPuli: true });
    const puli = await DipUniAPI.GetByIdAziendaCodQAnno(
      aziendaId,
      "PULI",
      this.state.competenzaAnno
    );
    this.setState({ loadingGridPuli: false });

    this.setState({ loadingGridCust: true });
    const cust = await DipUniAPI.GetByIdAziendaCodQAnno(
      aziendaId,
      "CUST",
      this.state.competenzaAnno
    );
    this.setState({ loadingGridCust: false });

    helpers.parseDates(cust, "data_caric");
    helpers.parseDates(puli, "data_caric");
    helpers.formatCurrency(cust, "imponibile", "importo");
    helpers.formatCurrency(puli, "imponibile", "importo");

    this.setState({
      selectedGridCust: null,
      selectedGridPuli: null,
      rowsCust: cust,
      rowsPuli: puli
    });
  }

  RadioHandleChange(e, val) {
    this.setState({
      [e]: val
    });

    if (val !== null)
      this.setState(
        {
          radioChecked: val.key,
          title: val.name
        },
        async () => await this.refreshGrid()
      );
  }

  handleTableClick(e) {
    this.setState({ rdlNotExistsCust: false });
    if (e.value[e.value.length - 1]) {
      this.setState({
        selectedUniemens: e.value[e.value.length - 1]
      });
    }
    this.setState({
      selectedGridCust: e.value,
      visibleLoader: false
    });
  }

  handleTableClickPulitori(e) {
    this.setState({ rdlNotExistsPuli: false });
    if (e.value[e.value.length - 1]) {
      this.setState({
        selectedUniemens: e.value[e.value.length - 1]
      });
    }
    this.setState({
      selectedGridPuli: e.value,
      visibleLoader: false
    });
  }

  async insertNegatives(codQ) {
    const uniemens =
      codQ === "PULI"
        ? this.state.selectedGridPuli
        : this.state.selectedGridCust;
    if (!uniemens || uniemens.length === 0) {
      this.props.addAlert.current.addAlert(
        "warning",
        "Selezionare gli Uniemens prima"
      );
      return;
    }
    const response = await DipUniAPI.InsertNegatives(uniemens.map((u) => u.id));
    if (response && response.length > 0)
      this.props.addAlert.current.addAlert(
        "success",
        `Aggiunti ${response.length} Uniemens`
      );
    else
      this.props.addAlert.current.addAlert(
        "danger",
        "salvataggio non riuscito"
      );

    return await this.refreshGrid();
  }

  async changeCodQ(codQ) {
    const uniemens =
      codQ === "PULI"
        ? this.state.selectedGridPuli
        : this.state.selectedGridCust;

    if (!uniemens || uniemens.length === 0) {
      this.props.addAlert.current.addAlert(
        "warning",
        "Selezionare gli Uniemens prima"
      );
      return;
    }

    const response = await DipUniAPI.TransferQuotaCode(
      uniemens.map((u) => u.id)
    );

    if (response && response.result && response.result.length > 0) {
      this.props.addAlert.current.addAlert(
        "success",
        `Aggiunti ${response.length} Uniemens`
      );

      this.setState({ rdlNotExistsPuli: false });
      this.setState({ rdlNotExistsCust: false });

      return await this.refreshGrid();
    } else {
      if (response.message === "error.rdl.notFound") {
        if (codQ === "PULI") this.setState({ rdlNotExistsPuli: true });
        else if (codQ === "CUST") this.setState({ rdlNotExistsCust: true });

        this.props.addAlert.current.addAlert(
          "warning",
          "salvataggio non riuscito: RDL non trovato per il dipendente selezionato"
        );
      } else {
        this.setState({ rdlNotExistsPuli: false });
        this.setState({ rdlNotExistsCust: false });

        this.props.addAlert.current.addAlert("danger", response.message);
      }
    }
  }

  getSelectedUniemens() {
    if (this.state.selectedUniemens) return this.state.selectedUniemens;

    if (this.state.selectedGridCust && this.state.selectedGridCust.length > 0)
      return this.state.selectedGridCust[0];

    if (this.state.selectedGridPuli && this.state.selectedGridPuli.length > 0)
      return this.state.selectedGridPuli[0];

    return null;
  }

  manipolaCfCust(row) {
    if (
      this.state.custSortField !== "dipendente.cf" ||
      (row &&
        row.dipendente.cf !== null &&
        !this.state.cfVistiCust.includes(row.dipendente.cf))
    ) {
      let cfVisti = this.state.cfVistiCust;
      cfVisti.push(row.dipendente.cf);
      return <>{row.dipendente.cf}</>;
    } else {
      return <></>;
    }
  }

  manipolaCfPuli(row) {
    if (
      this.state.puliSortField !== "dipendente.cf" ||
      (row &&
        row.dipendente.cf !== null &&
        !this.state.cfVistiPuli.includes(row.dipendente.cf))
    ) {
      let cfVisti = this.state.cfVistiPuli;
      cfVisti.push(row.dipendente.cf);
      return <>{row.dipendente.cf}</>;
    } else {
      return <></>;
    }
  }

  onPageChange(event, cfVisti, first, row) {
    cfVisti = [];
    let tmp = {};
    tmp[first] = event.first;
    tmp[row] = event.rows;
    this.setState(tmp);
  }

  onSortHandler(e, tipo) {
    if (tipo === "cust") {
      this.setState({
        custSortField: e.sortField,
        custSortOrder: e.sortOrder
      });
    } else {
      this.setState({
        puliSortField: e.sortField,
        puliSortOrder: e.sortOrder
      });
    }
  }

  backButtonClick() {
    let qS = queryString.parse(this.props.location.search);
    let maintainSearch = "?maintainSearch=1";
    if (!qS.type)
      this.props.history.push('/admin/aziende/' + this.props.match.params.aziendaId + maintainSearch);
    else if (qS.type === "fromVersamenti")
      this.props.history.push('/admin/versamenti/details/' + this.props.match.params.aziendaId + maintainSearch);
    else if (qS.type === "fromModificaRichieste")
      this.props.history.push('/admin/richiedenti/modificarichieste/' + this.props.match.params.aziendaId + '/' + qS.typeId + maintainSearch);
    else if (qS.type === "fromRichieste")
      this.props.history.push('/admin/richiedenti/modificarichieste/' + this.props.match.params.aziendaId + '/' + qS.typeId + maintainSearch + '&type=' + qS.type);
    else if (qS.type === "fromConsulenti")
      this.props.history.push('/admin/consulenti/' + this.props.match.params.aziendaId + maintainSearch);
    else if (qS.type === "fromAmministratori")
      this.props.history.push('/admin/amministratori/' + this.props.match.params.aziendaId + maintainSearch);
    else if (qS.type === "fromDipendenti")
      this.props.history.push('/admin/dipendenti/' + this.props.match.params.aziendaId + maintainSearch);
  }

  render() {
    this.state.cfVistiCust = [];
    this.state.cfVistiPuli = [];
    if (this.state.noEmployees)
      return (
        <Dialog
          onClose={() => this.props.history.goBack()}
          aria-labelledby="simple-dialog-title"
          open={this.state.noEmployees}
        >
          <DialogTitle id="simple-dialog-title">Attenzione!</DialogTitle>
          <DialogContent>
            <DialogContentText
              variant="subtitle1"
              color="error"
              display="block"
            >
              Non esistono dipendenti per l&lsquo; azienda{" "}
              {this.state.azienda.denominazione}.<br />
              Si prega di inserire prima i dipendenti e i relativi Rapporti Di
              Lavoro.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.props.history.goBack()} color="primary">
              Chiudi
            </Button>
          </DialogActions>
        </Dialog>
      );
    return (
      <div>
        <div ref={this.btnSearchRef} />
        <AziendaHeader
          aziendaId={this.props.match.params.aziendaId}
          {...this.props}
        />
        {this.state.visibleLoader && <LoaderSpinner fullHeight={true} />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  className="float-left"
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Custodi
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterCustodi}
                      onChange={this.ChangeFilter}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
                <div
                  className="float-left date"
                  style={{
                    verticalAlign: "top",
                    lineHeight: "1",
                    margin: "8px",
                    display: "inline-flex"
                  }}
                >
                  <SkipPreviousIcon
                    onClick={this.DecreaseYear}
                    className="slide"
                  />
                  <YearPicker
                    onChange={this.handleDateChange}
                    value={this.state.competenzaAnno}
                    className="date"
                  />
                  <SkipNextIcon onClick={this.IncreaseYear} className="slide" />
                </div>

                {this.state.rdlNotExistsCust && (
                  <Button
                    onClick={() => this.redirectToRDLSearch("CUST")}
                    color="warning"
                    aria-label="edit"
                    className="float-center"
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%",
                      marginLeft: "100px",
                      marginRight: "0"
                    }}
                  >
                    Inserisci RDL
                  </Button>
                )}

                {this.state.VisibleButtons && (
                  <ButtonGroup
                    variant="contained"
                    color="primary"
                    size="small"
                    aria-label="contained primary button group"
                    className="float-right"
                    style={{ verticalAlign: "center", margin: "0.1%" }}
                  >
                    <ButtonsMUI
                      onClick={() =>
                        this.setState({
                          selectedUniemens: null,
                          isVisible: true,
                          codiceQuota: "CUST"
                        })
                      }
                    >
                      Inserisci Uniemens fittizi
                    </ButtonsMUI>
                    <ButtonsMUI onClick={() => this.insertNegatives("CUST")}>
                      Inserisci Uniemens fittizi negativi
                    </ButtonsMUI>
                    <ButtonsMUI onClick={() => this.changeCodQ("CUST")}>
                      Trasferisci con cambio di codice quota
                    </ButtonsMUI>
                  </ButtonGroup>
                )}
                {!this.state.VisibleButtons && (
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Alla pagina precedente"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%"
                      }}
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.backButtonClick}
                    >
                      <ArrowBackIcon />
                    </Button>
                  </Tooltip>
                )}
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rowsCust}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsNumCust}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  loading={this.state.loadingGridCust}
                  selection={this.state.selectedGridCust}
                  scrollable
                  onSort={(e) => this.onSortHandler(e, "cust")}
                  sortOrder={this.state.custSortOrder}
                  sortField={this.state.custSortField}
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="multiple"
                  metaKeySelection={false}
                  onPage={(event) =>
                    this.onPageChange(
                      event,
                      this.state.cfVistiCust,
                      "firstCust",
                      "rowsNumCust"
                    )
                  }
                  first={this.state.firstCust}
                  rowsPerPageOptions={[12, 24, 36, 48]}
                >
                  {this.state.VisibleButtons && (
                    <Column
                      header=""
                      headerStyle={{ width: "40px" }}
                      body={this.EyeButton}
                      className="colDocActions"
                    />
                  )}
                  <Column
                    field="dipendente.cf"
                    headerStyle={{ width: "160px" }}
                    header="Codice Fiscale"
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    body={(row) => this.manipolaCfCust(row, "dipendente.cf")}
                  />
                  <Column
                    field="dipendente.nominativo"
                    header="nominativo"
                    headerStyle={{ width: "160px" }}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="comp"
                    header="Competenza"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="imponibile"
                    header="imponibile"
                    headerStyle={{ width: "130px" }}
                    body={(row) => helpers.DecimalDTTemplate(row, "imponibile")}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importo"
                    header="importo"
                    headerStyle={{ width: "110px" }}
                    body={(row) => helpers.DecimalDTTemplate(row, "importo")}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="rip"
                    header="Riparto"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data_caric"
                    header="Data Caric."
                    headerStyle={{ width: "130px" }}
                    body={(row) =>
                      moment(row.data_caric, dateTimeFmt).format(dateFmt)
                    }
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="descr_caric"
                    header="Descr. Caric."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cod_quota"
                    header="Cod. Quota"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  className="float-left"
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Pulitori
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterPulitori}
                      onChange={this.ChangeFilterPulitori}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />

                {this.state.rdlNotExistsPuli && (
                  <Button
                    onClick={() => this.redirectToRDLSearch("PULI")}
                    color="warning"
                    aria-label="edit"
                    className="float-center"
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%",
                      marginLeft: "100px",
                      marginRight: "0"
                    }}
                  >
                    Inserisci RDL
                  </Button>
                )}

                {this.state.VisibleButtons && (
                  <ButtonGroup
                    variant="contained"
                    color="primary"
                    size="small"
                    aria-label="contained primary button group"
                    className="float-right"
                    style={{ verticalAlign: "center", margin: "0.1%" }}
                  >
                    <ButtonsMUI
                      onClick={() =>
                        this.setState({
                          selectedUniemens: null,
                          isVisible: true,
                          codiceQuota: "PULI"
                        })
                      }
                    >
                      Inserisci Uniemens fittizi
                    </ButtonsMUI>
                    <ButtonsMUI onClick={() => this.insertNegatives("PULI")}>
                      Inserisci Uniemens fittizi negativi
                    </ButtonsMUI>
                    <ButtonsMUI onClick={() => this.changeCodQ("PULI")}>
                      Trasferisci con cambio di codice quota
                    </ButtonsMUI>
                  </ButtonGroup>
                )}
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rowsPuli}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsNumPuli}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  loading={this.state.loadingGridPuli}
                  selection={this.state.selectedGridPuli}
                  scrollable
                  sortOrder={this.state.puliSortOrder}
                  sortField={this.state.puliSortField}
                  onSort={(e) => this.onSortHandler(e, "puli")}
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClickPulitori}
                  selectionMode="multiple"
                  metaKeySelection={false}
                  onPage={(event) =>
                    this.onPageChange(
                      event,
                      this.state.cfVistiPuli,
                      "firstPuli",
                      "rowsNumPuli"
                    )
                  }
                  first={this.state.firstPuli}
                  rowsPerPageOptions={[12, 24, 36, 48]}
                >
                  {this.state.VisibleButtons && (
                    <Column
                      header=""
                      headerStyle={{ width: "40px" }}
                      body={this.EyeButton}
                      className="colDocActions"
                    />
                  )}
                  <Column
                    field="dipendente.cf"
                    headerStyle={{ width: "160px" }}
                    header="Codice Fiscale"
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    body={(row) => this.manipolaCfPuli(row, "dipendente.cf")}
                  />
                  <Column
                    field="dipendente.nominativo"
                    header="nominativo"
                    headerStyle={{ width: "160px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="comp"
                    header="Competenza"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="imponibile"
                    header="imponibile"
                    headerStyle={{ width: "130px" }}
                    body={(row) => helpers.DecimalDTTemplate(row, "imponibile")}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importo"
                    header="importo"
                    headerStyle={{ width: "110px" }}
                    body={(row) => helpers.DecimalDTTemplate(row, "importo")}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="rip"
                    header="riparto"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data_caric"
                    header="Data Caric."
                    headerStyle={{ width: "130px" }}
                    body={(row) =>
                      moment(row.data_caric, dateTimeFmt).format(dateFmt)
                    }
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="descr_caric"
                    header="Descr. Caric."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cod_quota"
                    header="Cod. Quota"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.isVisible && (
          <InserimentoUniemens
            onAnnullaClick={this.onAnnullaClick}
            showInserisciRDL={this.showInserisciRDL}
            azienda={this.state.azienda}
            addAlert={this.props.addAlert}
            employees={this.state.employees}
            history={this.props.history}
            match={this.props.match}
            // Choose the first element of the two merged selected lists. Priority to cust
            selectedUniemens={this.state.selectedUniemens}
            year={this.state.competenzaAnno}
            onDelete={this.refreshGrid}
            codiceQuota={this.state.codiceQuota}
          />
        )}
      </div>
    );
  }
}
export default UniemensXAzienda;
