import React from "react";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import LoaderSpinner from "../../Layout/LoaderSpinner.js";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CardBody from "components/Card/CardBody.js";
import ButtonsMUI from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import UndoIcon from "@material-ui/icons/Undo";

export default class ModificaChiusuraTabulati extends React.Component {
  state = {
    RowItem: null,
    openPopUp: false,
    smallModal: false,
    modalita: null
  };

  constructor(props) {
    super(props);

    this.SetText = this.SetText.bind(this);
    this.onChiusClick = this.onChiusClick.bind(this);
    this.onSiClick = this.onSiClick.bind(this);
    this.CancelChius = this.CancelChius.bind(this);
  }

  onChiusClick() {
    this.setState({
      openPopUp: true
    });
  }

  CancelChius() {
    this.setState({
      openPopUp: false,
      smallModal: false
    });
  }

  async onSiClick() {
    this.props.changeTabulato(this.state.RowItem);
    this.setState({
      openPopUp: false,
      smallModal: false
    });
  }

  SetText(field, newValue) {
    var newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.dataItem !== this.props.dataItem) {
      this.setState({
        RowItem: this.props.dataItem,
        modalita: this.props.dataItem.chiuso === "NO" ? "Chiudi" : "Apri"
      });
    }
  }

  componentDidMount() {
    if (this.props.dataItem !== null)
      this.setState({
        RowItem: this.props.dataItem,
        modalita: this.props.dataItem.chiuso === "NO" ? "Chiudi" : "Apri"
      });
  }

  render() {
    if (this.state.RowItem === null)
      return (
        <>
          {" "}
          <LoaderSpinner fullHeight={false} />{" "}
        </>
      );
    else
      return (
        <div>
          <Dialog
            open={this.state.openPopUp}
            onClose={this.CancelChius}
            aria-labelledby="small-modal-slide-title"
            aria-describedby="small-modal-slide-description"
          >
            <DialogContent id="small-modal-slide-description">
              <h5>
                Si &egrave; sicuri di voler procedere con
                {this.state.modalita === "Apri"
                  ? " l'apertura"
                  : " la chiusura "}
                del tabulato?
              </h5>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.onSiClick} color="primary">
                Si
              </Button>
              <Button onClick={this.CancelChius} color="transparent" simple>
                No
              </Button>
            </DialogActions>
          </Dialog>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="ebinprofinfo">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.state.modalita} Tabulati
                  </h4>

                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Annulla"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.props.onAnnullaClick}
                    >
                      <UndoIcon />
                    </Button>
                  </Tooltip>
                  <ButtonGroup
                    variant="contained"
                    color="secondary"
                    size="small"
                    aria-label="contained secondary button group"
                    className="float-right"
                    style={{ verticalAlign: "center", margin: "0.5%" }}
                  >
                    <ButtonsMUI onClick={this.onChiusClick}>
                      {this.state.modalita} Tabulato
                    </ButtonsMUI>
                  </ButtonGroup>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Codice"
                        id="codice"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.cod_quota || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Sede"
                        id="sede"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.sede || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Anno"
                        id="anno"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.anno || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Chiuso"
                        id="chiuso"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.chiuso || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Note"
                        id="note"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("note", event.target.value),
                          value: this.state.RowItem.note || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}
