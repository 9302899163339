import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  createRef
} from "react";
import { useHistory } from "react-router-dom";
import classes from "./Login.module.css";
import {
  getToken,
  setToken,
  removeToken,
  getRole,
  setRole,
  removeRole
} from "common";
import AlertWrapper from "components/InfoArea/AlertWrapper";
import { AuthContext } from "providers";

const Login = ({ ...props }) => {
  const usernameRef = useRef();
  const passwordRef = useRef();
  const { push } = useHistory();
  const [errors, setErrors] = useState(false);
  const alertRef = createRef();
  const {
    isAuthenticated,
    login,
    setIsAuthenticated,
    setRole: setApplicationRole,
    setToken: setApplicationToken,
    setProfile
  } = useContext(AuthContext);

  useEffect(() => {
    if (isAuthenticated) push("/admin");
  }, [isAuthenticated]);

  function inputHandler() {
    setErrors(false);
  }

  const Input = ({ label, componentRef, style, css, ...props }) => (
    <div className={classes.input_container} style={style}>
      <span className={classes.label}>{label}</span>
      <input
        ref={componentRef}
        className={classes.input}
        onChange={inputHandler}
        className={css}
        {...props}
      />
    </div>
  );

  const Button = ({ disabled, onClick, ...props }) => {
    const btnClasses = `${classes.button} ${disabled && classes.disabled}`;

    return (
      <button className={btnClasses} onClick={onClick} {...props}>
        {props.children}
      </button>
    );
  };

  async function loginHandler() {
    const username = usernameRef.current.value;
    const password = passwordRef.current.value;
    if (username.trim() !== "" && password.trim() !== "") {
      const res = await login(
        usernameRef.current.value,
        passwordRef.current.value
      );
      if (!res || res?.status === 500 || !res?.result) setErrors(true);
      else {
        setIsAuthenticated(true);
        setToken(res.result[0].token);
        setApplicationToken(res.result[0].token);
        setRole(
          res.result[0].authorities[0].authority === "ROLE_ADMIN" && "admin"
        );
        setApplicationRole(
          res.result[0].authorities[0].authority === "ROLE_ADMIN" && "admin"
        );
        setProfile({
          username: res.result[0].username,
          ufficio: res.result[0].ufficio
        });
      }
    } else {
      if (username.trim() === "" && password.trim() === "") {
        alertRef.current.addAlert("warning", "Inserire le credenziali");
        setErrors(true);
      } else {
        if (username.trim() === "") {
          alertRef.current.addAlert("warning", "Inserire l'username");
          setErrors(true);
        }
        if (password.trim() === "") {
          alertRef.current.addAlert("warning", "Inserire la password");
          setErrors(true);
        }
      }
    }
  }

  function onLogin() {
    loginHandler();
  }

  const inputClasses = `${classes.input} ${errors ? classes.input_error : ""}`;

  const cardComponent = (
    <div className={classes.card}>
      {errors && "Credenziali Inserite Errate"}
      <Input label="Username" componentRef={usernameRef} css={inputClasses} />
      <Input
        type="password"
        label="Password"
        componentRef={passwordRef}
        style={{ marginTop: "2vh" }}
        css={inputClasses}
      />
      <div className={classes.button_container}>
        <Button
          onClick={(event) => {
            event.preventDefault();
            onLogin();
          }}
        >
          Log In
        </Button>
      </div>
      <div style={{ marginTop: "2vh", textAlign: "center" }}>
        Contatta l'assistenza: <br />
        <a href="mailto:assistenzasoftware@fondocoasco.it" style={{textDecoration: "underline", color: "inherit"}}>
          assistenzasoftware@fondocoasco.it
        </a>
        <br/>oppure<br/>
        <a href="mailto:assistenza.password@ebinprof.it" style={{textDecoration: "underline", color: "inherit"}}>
          assistenza.password@ebinprof.it
        </a>
      </div>
    </div>
  );

  return (
    <div className={classes.container}>
      {cardComponent}
      <AlertWrapper ref={alertRef} />
    </div>
  );
};

export default Login;
