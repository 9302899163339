import Utils from "./Utils";
import moment from "moment";

const ElencoAPI = {
  GetElencoMovimentazioni: async function (type) {
    const response = await Utils.CreatePostRequest('coasco/elencoMovimentazioni/getByType?type=' + type);
    if (response?.message?.includes('200')) {
      return response.result.map(row => {
        return {tipo: row[0], mese: moment(row[1]).toDate(), numero: row[2]};
      });
    } else return null;
  }
};
export default ElencoAPI;
