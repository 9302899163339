import React, { Component, Fragment } from "react";
import "./styles.css";
import { CSSTransition } from "react-transition-group";

import SnackbarContent from "components/Snackbar/SnackbarContent.js";

export default class AlertWrapper extends Component {
  state = {
    alerts: [],
    animationSpeed: 300
  };

  constructor(props) {
    super(props);
  }

  addAlert = (status, messege, time = 5000) => {
    var alert = this.state.alerts;
    var indexx = 1;

    if (alert && alert.length > 0) indexx = alert[alert.length - 1].index + 1;

    alert.push({
      showAlert: true,
      alertCode: status,
      alertMessage: messege,
      index: indexx
    });
    this.setState({ alerts: alert }, () => {
      setTimeout(() => this.removeAlert(indexx), time);
    });
    return indexx;
  };

  removeAlert = (indexToChange) => {
    var alertsChange = this.state.alerts;
    alertsChange.forEach((x, index) => {
      if (x.index === indexToChange) alertsChange[index].showAlert = false;
    });
    this.setState(
      {
        alerts: alertsChange
      },
      () => {
        setTimeout(() => {
          var alertsUpdated = this.state.alerts.filter(
            (x) => x.index !== indexToChange
          );
          this.setState({ alerts: alertsUpdated });
        }, 500);
      }
    );
  };

  renderAlerts = () => {
    return this.state.alerts.map((x) => (
      <li key={x.index}>
        <CSSTransition
          key={x.index}
          in={x.showAlert}
          appear={true}
          timeout={this.state.animationSpeed}
          classNames="fade"
        >
          <SnackbarContent
            message={x.alertMessage}
            close
            color={x.alertCode}
            onCloseClick={() => this.removeAlert(x.index)}
          />
        </CSSTransition>
      </li>
    ));
  };

  render() {
    return (
      <Fragment>
        <ul className="ulList">{this.renderAlerts()}</ul>
      </Fragment>
    );
  }
}
