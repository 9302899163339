import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import helpers from "helpers/Helpers";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Tooltip from "@material-ui/core/Tooltip";
import queryString from "query-string";
import { DipendenteHeader } from "components/Views/Dipendenti/DipendenteHeader.js";
import Button from "components/CustomButtons/Button.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import StoricoAPI from "API/StoricoAPI";
import {rowsPerPageOptions} from "../../helpers/AppConstants";

export class StoricoXDipendente extends Component {
  state = {
    rows: null,
    selectedRow: null,
    selectedGridStorico: null,
    filterGrid: false,
    rowsPerPage: 10,
    loadingGrid: false
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });
    var rw = await StoricoAPI.GetStoricoForDipendentiById(
      this.props.match.params.dipendenteId
    );

    this.setState({
      rows: rw
    });
    this.setState({
      loadingGrid: false
    });
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  async handleTableClick(e) {
    this.setState({
      selectedGridStorico: e.value
    });
  }

  backButtonClick = () => {

    let qS = queryString.parse(this.props.history.location.search);
    let aziendaId = this.props.match.params.aziendaId;
    let dipendentiId = this.props.match.params.dipendenteId;
    let maintainSearch = "?maintainSearch=1";
    if (!qS.type) {
      if (aziendaId > 0)
        this.props.history.push('/admin/DipendentiXAzienda/' + aziendaId + "?typeId=" + dipendentiId);
      else if (aziendaId == 0)
        this.props.history.push('/admin/dipendenti/' + dipendentiId + maintainSearch);
    }
    else if (qS.type === "fromVersamenti")
      this.props.history.push('/admin/DipendentiXAzienda/' + aziendaId + "?type=" + qS.type + "&typeId=" + dipendentiId);

    else if (qS.type === "fromModificaRichieste")
      this.props.history.push('/admin/DipendentiXAzienda/' + aziendaId + "?type=" + qS.type + '&typeId=' + qS.typeId);

    else if (qS.type === "fromRichieste")
      this.props.history.push('/admin/DipendentiXAzienda/' + aziendaId + "?type=" + qS.type + '&typeId=' + qS.typeId);
    else if (qS.type === "fromModificaDipendente")
      this.props.history.push("/admin/ModificaDipendente/" + dipendentiId);
  }

  render() {
    return (
      <div>
        <DipendenteHeader {...this.props} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Storico
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Alla pagina precedente"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.backButtonClick}
                  >
                    <ArrowBackIcon />
                  </Button>
                </Tooltip>
              </CardHeader>

              <CardBody>
                {
                  <div className="card">
                    <DataTable
                      emptyMessage=""
                      className="p-datatable-striped p-datatable-sm"
                      value={this.state.rows}
                      dataKey="id"
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                      rows={this.state.rowsPerPage} rowsPerPageOptions={rowsPerPageOptions}
                      paginatorLeft={this.paginatorLeft}
                      paginatorRight={this.paginatorRight}
                      loading={this.state.loadingGrid}
                      selection={this.state.selectedGridStorico}
                      scrollable
                      style={{ width: "100%" }}
                      onSelectionChange={this.handleTableClick}
                      selectionMode="single"
                    >
                      <Column
                        field="indirizzo"
                        header="Indirizzo"
                        headerStyle={{ width: "250px" }}
                        sortable={true}
                        filter={this.state.filterGrid}
                        filterMatchMode="contains"
                        filterPlaceholder=""
                      />
                      <Column
                        field="cap"
                        header="CAP"
                        sortable={true}
                        filter={this.state.filterGrid}
                        filterMatchMode="contains"
                        filterPlaceholder=""
                      />
                      <Column
                        field="comune"
                        header="Comune"
                        sortable={true}
                        filter={this.state.filterGrid}
                        filterMatchMode="contains"
                        filterPlaceholder=""
                      />
                      <Column
                        field="prov"
                        header="PROV."
                        sortable={true}
                        filter={this.state.filterGrid}
                        filterMatchMode="contains"
                        filterPlaceholder=""
                      />
                      <Column
                        field="sesso"
                        header="Sesso"
                        sortable={true}
                        filter={this.state.filterGrid}
                        filterMatchMode="contains"
                        filterPlaceholder=""
                      />
                      <Column
                        field="data_ult_agg"
                        header="Ultimo Agg."
                        body={(rowData) =>
                          helpers.DateTemplateWithHours(rowData, "data_ult_agg")
                        }
                        sortable={true}
                        filter={this.state.filterGrid}
                        filterMatchMode="contains"
                        filterPlaceholder=""
                      />
                      <Column
                        field="utente"
                        header="Utente"
                        sortable={true}
                        filter={this.state.filterGrid}
                        filterMatchMode="contains"
                        filterPlaceholder=""
                      />
                    </DataTable>
                  </div>
                }
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default StoricoXDipendente;
