import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// core components
import CaricamentiServerUniemensAPI from "API/CaricamentiServerUniemensAPI";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Helpers from "helpers/Helpers.js";
import CaricamentiAPI from "API/CaricamentiAPI";
class VerificaCaricamentiUniemens extends React.Component {
  state = {
    rows: [],
    loadingGrid: false,
    selectedGrid: null
  };

  constructor(props) {
    super(props);

    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
  }

  handleTableClick(e) {
    this.setState({
      selectedGrid: e.value
    });
  }

  ChangeFilterGrid(e) {
    this.setState({
      filterGrid: e.target.checked
    });
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  async refreshGrid() {
    const uploadsRes = await CaricamentiServerUniemensAPI.getAll();
    this.setState({
      loadingGrid: true
    });
    this.setState({
      rows: uploadsRes
    });
    this.setState({
      loadingGrid: false
    });
  }

  render() {
    return (
      <>
        <div ref={this.btnSearchRef} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Caricamenti Server Uniemens
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10}
                  //rowsPerPageOptions={[10, 20, 30]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  loading={this.state.loadingGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="progr"
                    header="Progr"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cq"
                    header="Codice Quota"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="tipo"
                    header="Tipo"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="righe"
                    header="NumRighe"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importo"
                    header="Importo"
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "importo")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="datacaric"
                    header="Data Caric"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default VerificaCaricamentiUniemens;
