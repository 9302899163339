import Utils from "./Utils";
import helpers from "../helpers/Helpers";

const CustodiPulitoriAPI = {
  GetPulitoriCustodiByAziendaId: async function (aziendaId, year, codq) {
    let result;
    const resultObj = await Utils.CreatePostRequest(
      "coasco/versamenti/getbyidazienda?idazienda=" +
        aziendaId +
        "&anno=" +
        year +
        "&codQ=" +
        codq
    );
    if (resultObj)
      {
        result = resultObj.result.map((item) => ({
        ...item,
        codcaus: item.caus_ins.codice
      }));
        helpers.formatCurrency(result, "retribuzioni", "importo_quota");
    }
    else result = {};
    return result;
  },

  GetPulitoriCustodiById: async function (versamentiId) {
    const result = await Utils.CreatePostRequest(
      "coasco/versamenti/getbyid?id=" + versamentiId
    );
    return result.result;
  },

  GetCustodiPulitoriVersamentiForAzienda: async function (
    type,
    aziendaId,
    year
  ) {
    let result = await Utils.CreatePostRequest(
      "coasco/versuni/getversuni?idazienda=" +
        aziendaId +
        "&anno=" +
        year +
        "&codQuota=" +
        type
    );
    if (result === null) return [];
    else {
      helpers.formatCurrency(result.result, "quotaVersata", "quotaUNIEM");
      return result.result;
    }
  },

  GetCustodiPulitoriCongruitaVersamentiForAzienda: async function (
    type,
    matricola,
    year
  ) {
    return await Utils.CreatePostRequest(
      "coasco/verscongr/getbyAnnoMatrQuota?matricola=" +
        matricola +
        "&quota=" +
        type +
        "&anno=" +
        year
    );
  },

  GetPulitoriCustodiUniemensForAzienda: async function (aziendaId, year, type) {
    const result = await Utils.CreatePostRequest(
      "coasco/dipuni/getbyidaziendacodqanno?idazienda=" +
        aziendaId +
        "&codQ=" +
        type +
        "&anno=" +
        year
    );
    if (result === null) return [];
    else {
      return result.result;
    }
  },

  GetNuovoPulitoriCustodiByAziendaId() {
    return {
      id: 0,
      caus_ins: null,
      sede: null,
      azienda: null,
      codice_quota: null,
      importo_quota: null,
      competenza_anno: null,
      competenza_mese: null,
      mese_fine: null
    };
  },

  GetNuovoPulitoriCustodiByAziendaIdNuovo() {
    return {
      annoComp: null,
      causale: null,
      codQuotaOrig: null,
      competenzaOrig: null,
      id: null,
      importoQuota: null,
      matricolaDest: null,
      matricolaOrig: null,
      meseFineCompDest: null,
      meseInizCompDest: null,
      sede: null
    };
  },

  AddCustodiPulitori: async function () {
    return 1;
  },

  UpdateCustodiPulitori: async function () {
    return 1;
  },

  DeleteVersamenti: async function (id) {
    id.causale = id.causale.split(" ")[0];
    return await Utils.CreatePostRequest("coasco/versamenti/delete", id);
  }
};
export default CustodiPulitoriAPI;
