import React, { Component } from "react";
import clone from "clone";
import { Dropdown } from "primereact/dropdown";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import LoaderSpinner from "../../Layout/LoaderSpinner.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import { Calendar } from "primereact/calendar";
import UndoIcon from "@material-ui/icons/Undo";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import helpers from "helpers/Helpers";
import AziendeAPI from "../../../API/AziendeAPI";
import { AutoComplete } from "primereact/autocomplete";
export default class ModificaDitte extends Component {
  state = {
    RowItem: null,
    ModificaTitle: "Nuovo",
    groupClass: "",
    disabledFields: false,

    filteredMatricolaListByInput: null,
    currentMatricola: "",
    //VALIDATIONS
    fieldValidations: {},
    validationRules: {
      matricola: "EQ-10"
      //cf: "EQ-16",
    }
  };

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.SetText = this.SetText.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.searchMatricola = this.searchMatricola.bind(this);

    this.monthNavigatorTemplate = this.monthNavigatorTemplate.bind(this);
    this.yearNavigatorTemplate = this.yearNavigatorTemplate.bind(this);
  }

  monthNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }

  yearNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }

  SetText(field, newValue) {
    var newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
  }

  async isValidForm() {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);

    let isValid = Object.values(fieldValidations).every((x) => x === "success");

    this.setState({
      fieldValidations: fieldValidations
    });

    return isValid;
  }

  HandleChange(field, newValue) {
    var newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
  }

  HandleSaveClick = async () => {
    if (await this.isValidForm()) {
      var sd = await this.props.onSaveClick(this.state.RowItem);
      return sd;
    } else return;
  };

  searchMatricola(event) {
    setTimeout(async () => {
      var list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByMatr(event.query)
          : [];
      this.setState({
        filteredMatricolaListByInput: list
      });
    }, 250);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.dataItem.id !== prevProps.dataItem.id)
      this.setState({
        RowItem: {
          ...this.props.dataItem,
          data_inizio: helpers.GetDateObjectFromStringDate(
            this.props.dataItem.data_inizio
          ),
          data_fine: helpers.GetDateObjectFromStringDate(
            this.props.dataItem.data_fine
          ),
          matricola:
            this.props.dataItem.matricola || this.state.currentMatricola
        },
        ModificaTitle: this.props.dataItem.id > 0 ? "Modifica" : "Nuovo",
        disabledFields: this.props.dataItem.id > 0 ? true : false,
        groupClass: this.props.dataItem.id > 0 ? "" : "hide"
      });
  }

  async componentDidMount() {
    if (
      this.state.currentMatricola === "" &&
      this.props.match.params.aziendaId > 0
    ) {
      this.setState({
        currentMatricola: (
          await AziendeAPI.GetAziendaById(this.props.match.params.aziendaId)
        )[0].matricola
      });
    }

    if (this.props.dataItem !== null) {
      this.setState({
        RowItem: {
          ...this.props.dataItem,
          data_inizio: helpers.GetDateObjectFromStringDate(
            this.props.dataItem.data_inizio
          ),
          data_fine: helpers.GetDateObjectFromStringDate(
            this.props.dataItem.data_fine
          ),
          matricola:
            this.props.dataItem.matricola || this.state.currentMatricola,
          cf: this.props.dataItem.cf
        },
        ModificaTitle: this.props.dataItem.id > 0 ? "Modifica" : "Nuovo",
        disabledFields: this.props.dataItem.id > 0 ? true : false,
        groupClass: this.props.dataItem.id > 0 ? "" : "hide"
      });
    }
  }

  HandleChangeMaxLength(field, newValue) {
    let test = newValue.toString();

    if (test.length <= 10) {
      var newRowItem = this.state.RowItem;
      newRowItem[field] = newValue;
      this.setState({ RowItem: newRowItem });
    }
  }

  render() {
    if (this.state.RowItem === null)
      return (
        <>
          {" "}
          <LoaderSpinner fullHeight={false} />{" "}
        </>
      );
    else
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.state.ModificaTitle} Azienda
                  </h4>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Annulla"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.props.onAnnullaClick}
                    >
                      <UndoIcon />
                    </Button>
                  </Tooltip>

                  {this.props.children}
                </CardHeader>

                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Id"
                        id="id-disabled"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.id || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Ultimo Aggiornamento"
                        id="datault"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.data_ult_agg || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Tipo Ultimo Aggiornamento"
                        id="ultimoagg2"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.tipo_ult_agg || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Utente Ultimo Aggiornamento"
                        id="ultimoagg3"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.utente || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Codice Fiscale"
                        id="cf"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.props.amministratore.cf || ""
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      style={{ marginTop: "2px" }}
                    >
                      <p
                        className="calendarParagraph"
                        style={{
                          color:
                            this.state.fieldValidations.matricola &&
                            this.state.fieldValidations.matricola === "error"
                              ? "#f44336"
                              : "#565656"
                        }}
                      >
                        <small>Matricola *</small>
                      </p>
                      <AutoComplete
                        style={{ height: "26px" }}
                        disabled={this.props.match.params.aziendaId !== "0"}
                        value={this.state.RowItem.matricola}
                        suggestions={this.state.filteredMatricolaListByInput}
                        completeMethod={this.searchMatricola}
                        onChange={(e) =>
                          this.HandleChangeMaxLength("matricola", e.value)
                        }
                        className="matricolaAutocomplete"
                        type="number"
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      style={{ marginTop: "2px" }}
                    >
                      <p
                        className="calendarParagraph"
                        style={{ color: "#565656" }}
                      >
                        <small>Data Inizio</small>
                      </p>
                      <Calendar
                        className="calendarCustomedForMUI"
                        id="dataconst"
                        value={
                          new Date(
                            helpers.ConvertToItalianDateForPrime(
                              this.state.RowItem.data_inizio
                            )
                          )
                        }
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          this.HandleChange("data_inizio", e.target.value)
                        }
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      style={{ marginTop: "2px" }}
                    >
                      <p
                        className="calendarParagraph"
                        style={{ color: "#565656" }}
                      >
                        <small>Data Fine</small>
                      </p>

                      <Calendar
                        className="calendarCustomedForMUI"
                        id="dataconst"
                        value={
                          new Date(
                            helpers.ConvertToItalianDateForPrime(
                              this.state.RowItem.data_fine
                            )
                          )
                        }
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          this.HandleChange("data_fine", e.target.value)
                        }
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>

                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}
