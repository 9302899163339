import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ButtonsMUI from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { DataTable } from "primereact/datatable";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import helpers from "helpers/Helpers";
import CustomButton from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Dialog } from "primereact/dialog";
import Danger from "components/Typography/Danger.js";
import Warning from "@material-ui/icons/Warning";
import { Dropdown } from "primereact/dropdown";
import DocsAPI from "API/DocsAPI";
import RichiestiAPI from "API/RichiestiAPI";
import AutocompleteAPI from "API/AutocompleteAPI";
import queryString from "query-string";
import AnniAccademiciAPI from "API/AnniAccademiciAPI";
import TipoEsitoAPI from "API/TipoEsitoAPI";
import EtichetteAPI from "API/EtichetteAPI";
import { dateTimeFmt } from "helpers/AppConstants";
import moment from "moment";
import { dateFmt } from "helpers/AppConstants";
import TipoBorseAPI from "API/TipoBorsaAPI";
import StatoRichiestaAPI from "API/StatoRichiestaAPI";
import GeneraLettereAPI from "API/GeneraLettereAPI";
import { AutoComplete } from "primereact/autocomplete";
import { Tooltip } from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import LettersGenerator from "./components/LettersGenerator.component";
import { AuthContext } from "providers";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import UtentiAPI from "API/Utenti.api";
import EmailUtenteAPI from "API/EmailUtenteAPI";
import StaticLists from "../../helpers/StaticLists";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";

class Richieste extends Component {
  static contextType = AuthContext;

  state = {
    rows: [],
    selectedGrid: null,
    loadingGrid: false,

    initialRows: [],
    filteredRows: [],

    DocRows: [],
    AnniAccademici: [],
    Ordina: [],
    TipoBorsa: [],
    Esito: [],
    StatoLavor: [],
    Etichette: [],
    loadingPage: false,

    CodfisFullList: [],
    ProtocolloFullList: [],
    CognomeFullList: [],

    filteredByProtocolloListByInput: null,
    filteredCodfisListByInput: null,
    filteredCognomeListByInput: null,

    filterByCodice: "",
    filterByNominativo: "",
    filterByProtocollo: "",
    filterByStato: null,
    filterByTipoBorsa: null,
    filterByEtichette: null,
    filterByAnni: null,
    filterByOrdina: null,

    filterByStatoLavor: null,
    selectedEsito: null,
    generaVisible: false,
    openPopUp: false,

    firstRowToDisplay: 0,
    RowItem: null,

    fieldValidations: {},
    validationRules: {
      esito: "",
      stato: ""
    },

    results: 5
  };

  constructor(props) {
    super(props);

    this.btnSearchRef = React.createRef();
    this.getFilteredRows = this.getFilteredRows.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.templateCheckboxGrid = this.templateCheckboxGrid.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.DocActionsGridTemplate = this.DocActionsGridTemplate.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.onCambioClick = this.onCambioClick.bind(this);
    this.onRedirectClick = this.onRedirectClick.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.activeSnackbar = this.activeSnackbar.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.LoadElementsInGrid = this.LoadElementsInGrid.bind(this);
    this.pageSizeHandler = this.pageSizeHandler.bind(this);
    this.printList = this.printList.bind(this);
    this.lettersGeneratorHandler = this.lettersGeneratorHandler.bind(this);
    this.printRequestedCards = this.printRequestedCards.bind(this);
    this.multipleStateChange = this.multipleStateChange.bind(this);
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true,
      lettersGenerator: false,
      isResultDisabled: true
    });

    let esito = [];
    const resEsito = await TipoEsitoAPI.GetAll();
    resEsito.map((r) => {
      return esito.push({ label: r.descr, value: r.idEsito });
    });

    this.setState({
      AnniAccademici: await AnniAccademiciAPI.AnnoAccademiciForBorse(),
      TipoBorsa: (await TipoBorseAPI.GetAllTipoBorse()).result,
      Ordina: await RichiestiAPI.Ordina_Richieste(),
      Esito: esito,
      Etichette: await EtichetteAPI.EtichetteForBorse(null)
    });

    this.setState({
      firstRowToDisplay: 0,
      pageSize: 10
    });
    const requests = await RichiestiAPI.getByFilter(
      this.state.firstRowToDisplay,
      this.state.pageSize
    );

    let filterByCodice = this.state.filterByCodice;
    let filterByNominativo = this.state.filterByNominativo;
    let filterByTipoBorsa = this.state.filterByTipoBorsa;
    let filterByAnni = this.state.filterByAnni;
    let filterByStato = this.state.filterByStato;
    let filterByProtocollo = this.state.filterByProtocollo;

    let queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      let savedFilters = helpers.GetSearchFilters("RichiestiList");
      if (savedFilters !== null) {
        filterByNominativo = savedFilters.filterByNominativo;
        filterByCodice = savedFilters.filterByCodice;
        filterByTipoBorsa = savedFilters.filterByTipoBorsa;
        filterByAnni = savedFilters.filterByAnni;
        filterByStato = savedFilters.filterByStato;
        filterByProtocollo = savedFilters.filterByProtocollo;
      }
    } else helpers.SaveSearchFilters(null, "RichiestiList");

    this.setState({
      resultSizes: [
        { label: "10", value: 10 },
        { label: "20", value: 20 },
        { label: "30", value: 30 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "150", value: 150 }
      ],
      initialRows: requests?.result,
      filteredRows: requests?.result,
      totalRecords: requests?.tot,
      filterByCodice: filterByCodice,
      filterByTipoBorsa: filterByTipoBorsa,
      filterByProtocollo: filterByProtocollo,
      filterByAnni: filterByAnni,
      filterByStato: filterByStato,
      filterByNominativo: filterByNominativo,
      CodfisFullList: await AutocompleteAPI.GetCodFisForAutocomplete(
        this.state.filteredCodfisListByInput
      ),
      ProtocolloFullList: await AutocompleteAPI.GetProtocolloForAutocomplete(
        this.state.filterByProtocollo
      ),
      CognomeFullList: await AutocompleteAPI.GetCognomeForAutocomplete(
        this.state.filteredCognomeListByInput
      )
    });

    const { profile } = this.context;
    const { username } = profile;

    const userRes = await UtentiAPI.getUserByUsername(username);
    const user = userRes[0];
    const emailsRes = await EmailUtenteAPI.GetByUtente(username);
    const senderEmails = [];
    emailsRes.map((email) => {
      return senderEmails.push({
        id: email?.id,
        email: email?.email
      });
    });

    this.setState({
      user: username,
      senderId: user?.codice,
      senderEmails: senderEmails.concat(StaticLists.Corrisp_Account_ebin())
    });

    const jobStates = [];
    const res = await StatoRichiestaAPI.GetAllStato();
    res.map((jobState) => {
      return jobStates.push({
        label: jobState.descr,
        value: jobState.idStato
      });
    });

    this.setState({
      jobStates,
      statoLavoro: res
    });

    this.setState({
      loadingGrid: false
    });
  }

  onAnnullaClick() {
    this.setState({ lettersGenerator: false });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  async LoadElementsInGrid(newPage = 0) {
    let page = newPage;
    if (page === undefined || page === null) page = this.state.lastPage;

    helpers.SaveLastPage(page, "RichiesteListPage");
    const res = await RichiestiAPI.getByFilter(
      this.state.firstRowToDisplay,
      this.state.pageSize,
      this.state.sortField,
      this.state.sortOrder,
      this.state.filters?.join("&")
    );

    this.setState({
      filteredRows: res?.result,
      totalRecords: res?.tot,
      loadingGrid: false
    });

    if (newPage)
      this.setState({
        lastPage: newPage
      });
  }

  async onPageChange(e) {
    this.setState(
      {
        firstRowToDisplay: e.first,
        loadingGrid: true
      },
      async () => await this.LoadElementsInGrid(e.page)
    );
  }

  async onSortChange(e) {
    helpers.SaveSearchFilters(
      {
        sortField: e.sortField,
        sortOrder: e.sortOrder
      },
      "RichiesteListOrder"
    );

    this.setState(
      {
        sortField: e.sortField,
        sortOrder: e.sortOrder,
        loadingGrid: true
      },
      async () => {
        await this.LoadElementsInGrid();
      }
    );
  }

  DocActionsGridTemplate(rowData) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <CustomButton
          round
          color="info"
          className="actionButton actionButtonRound btnRound  "
          key={rowData.Id}
          onClick={(e) => this.onRedirectClick(e, rowData)}
        >
          <VisibilityIcon className="icon" />
        </CustomButton>
      </div>
    );
  }

  onRedirectClick(e, rowData) {
    e.stopPropagation();
    this.props.history.push(
      `/admin/richiedenti/modificarichieste/${rowData.cfRich.idRichied}/${rowData.idRichies}?maintainSearch=1&type=fromRichieste`
    );
  }

  onCambioClick() {
    if (this.state.selectedGrid && this.state.selectedGrid.length > 0)
      this.setState({
        openPopUp: !this.state.openPopUp
      });
    else this.activeSnackbar("warning", "Selezionare almeno una riga");
  }

  async isValidForm() {
    if (this.state.stato !== "" && this.state.esito !== "") {
      this.setState({
        fieldValidations: {
          stato: "success",
          esito: "success"
        }
      });
      return true;
    } else {
      this.setState({
        fieldValidations: {
          stato: "error",
          esito: "error"
        }
      });
      return false;
    }
  }

  async HandleSaveClick(data)  {
    const res = await GeneraLettereAPI.GeneraLettere(
      new Date().getFullYear(), moment().format(dateTimeFmt),
      encodeURIComponent(data?.object), encodeURIComponent(data?.emailText),
      encodeURIComponent(data?.senderEmail), data?.sendType,
      data?.causal, data?.files, this.state.selectedGrid.map((x) => x.cfRich)
    );
    
    res === 200
    ? this.props.addAlert.current.addAlert(
        "success",
        "Richiesta di preparazione mail presa in carico, per il download del report accedere alla sezione “Report Comunicazioni."
      )
    : this.props.addAlert.current.addAlert(
        "danger",
        "La richiesta di preparazione mail non è stata presa in carico correttamente."
      );

    return true;
  }

  async getFilteredRows() {
    let filter = [];
    if (this.state.filterByCodice)
      filter.push(`cfdip=${this.state.filterByCodice}`);

    if (this.state.filterByNominativo)
      filter.push(`cognome=${this.state.filterByNominativo}`);

    if (this.state.filterByTipoBorsa) {
      filter.push(`tipoBorsa=${this.state.filterByTipoBorsa}`);
    }

    if (this.state.filterByAnni) {
      filter.push(`descrannoAcc=${this.state.filterByAnni.descr}`);
    }

    if (this.state.filterByStatoLavor) {
      filter.push(`statolavor=${this.state.filterByStatoLavor}`);
    }

    if (this.state.filterByProtocollo)
      filter.push(`protrichies=${this.state.filterByProtocollo}`);

    this.setState({
      filters: filter
    });

    return await RichiestiAPI.getByFilter(
      this.state.firstRowToDisplay,
      this.state.pageSize,
      null,
      null,
      filter.join("&")
    );
  }

  HandleDropDownChange(e, val, propName) {
    if (e === "filterByStatoLavor") {
      if (val === 4) {
        this.setState({
          selectedEsito: 3,
          isResultDisabled: true
        });
      } else if (val === 5) {
        this.setState({
          selectedEsito: null,
          isResultDisabled: false
        });
      } else {
        this.setState({
          isResultDisabled: true,
          selectedEsito: null
        });
      }
    }

    let rowItem = this.state.RowItem;
    if (rowItem) {
      rowItem[propName] = val;
    } else {
      rowItem = {
        [propName]: val
      };
    }

    this.setState({
      [e]: val,
      RowItem: rowItem
    });
  }

  async HandleChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  handleSearchClick = async () => {
    helpers.SaveSearchFilters(
      {
        filterByCodice: this.state.filterByCodice,
        filterByTipoBorsa: this.state.filterByTipoBorsa,
        filterByAnni: this.state.filterByAnni,
        filterByStato: this.state.filterByStato,
        filterByNominativo: this.state.filterByNominativo
      },
      "RichiestiList"
    );

    this.setState({
      loadingGrid: true
    });

    const res = await this.getFilteredRows();

    this.setState({
      selectedGrid: null,
      filteredRows: res?.result,
      totalRecords: res?.tot
    });
    this.setState({
      loadingGrid: false
    });
  };

  async handleClearClick() {
    helpers.SaveSearchFilters(null, "RichiestiList");
    this.setState({
      loadingGrid: true
    });

    const res = await RichiestiAPI.getByFilter(
      this.state.firstRowToDisplay,
      this.state.pageSize
    );

    this.setState({
      selectedRichiedenti: null,
      filteredRows: res?.result,
      totalRecords: res?.tot,
      filters: null,
      selectedGrid: null,
      filteredCodfisListByInput: null,
      filteredByProtocolloListByInput: null,
      filteredCognomeListByInput: null,
      filterByCodice: "",
      filterByProtocollo: "",
      filterByNominativo: "",
      filterByTipoBorsa: null,
      filterByAnni: null,
      filterByStatoLavor: null,
      filterByOrdina: null,
      anni: "",
      generaVisible: false
    });

    this.setState({
      loadingGrid: false
    });
  }

  templateCheckboxGrid(rowData) {
    return (
      <div className="p-field-checkbox">
        <Checkbox
          inputId="binary"
          checked={rowData.stato}
          onChange={async (e) => {
            this.setState({ loadingGrid: true });
            if (await DocsAPI.ChangeGetDocsPresentatiStato(rowData.id)) {
              let list = this.state.DocRows;
              let el = list.find((x) => x.id === rowData.id);
              el.stato = e.checked;

              this.setState({ DocRows: list });
            }

            this.setState({ loadingGrid: false });
          }}
        />
      </div>
    );
  }

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: e.value
    });

    if (e.value.length > 0)
      this.setState({
        arrayId: e.value[0].id
      });
    else {
      this.setState({ isPreparaMail: false });
    }
  };

  activeSnackbar(type, message) {
    this.props.addAlert.current.addAlert(type, message);
  }

  async printLabel() {
    if (!this.state.filterByEtichette)
      this.activeSnackbar(
        "warning",
        "Selezionare la dimensione dell'etichetta"
      );
    else {
      this.setState({ loadingPage: true });
      const width = this.state.filterByEtichette.larghezza;
      const height = this.state.filterByEtichette.altezza;
      const requestIds = [];
      this.state.selectedGrid.map((request) => {
        return requestIds.push(request.idRichies);
      });
      const res = await RichiestiAPI.printLabels(requestIds, width, height);
      helpers.generatePDF(res, "Etichette");
      this.setState({ loadingPage: false });
    }
  }

  pageSizeHandler(event) {
    const page = event.target.value;
    this.setState({
      pageSize: page
    });
  }

  async printRequestedCards() {
    this.setState({ loadingPage: true });
    if (this.state.selectedGrid && this.state.selectedGrid.length > 0) {
      const requestsId = [];
      this.state.selectedGrid.map((request) => {
        return requestsId.push(request?.idRichies);
      });
      const res = await RichiestiAPI.printRequestedCards(requestsId);
      helpers.generatePDF(res, "Schede Richieste");
    } else this.activeSnackbar("warning", "Selezionare almeno una richiesta");
    this.setState({ loadingPage: false });
  }

  async printList() {
    if(!this.state.selectedGrid) {
      this.props.addAlert.current.addAlert(
        "warning",
        "Selezionare almeno un risultato"
      );
      return;
    }
    this.setState({ loadingPage: true });
    const academicYears = this.state.filterByAnni?.descr;
    const scholarshipType = this.state.filterByTipoBorsa;
    const filters = [];
    academicYears && filters.push(`descrannoAcc=${academicYears}`);
    scholarshipType && filters.push(`tipoBorsa=${scholarshipType}`);
    const _filters = filters.join("&");
    const res = await RichiestiAPI.printList(this.state.selectedGrid.map((x) => x.idRichies),_filters);
    helpers.generateExcel(res, "Elenco");
    this.setState({ loadingPage: false });
  }

  lettersGeneratorHandler() {
    if (this.state.selectedGrid?.length > 0)
      this.setState({
        lettersGenerator: !this.state.lettersGenerator
      });
    else {
      this.activeSnackbar("warning", "Selezionare almeno una corrispondenza");
    }
  }

  async multipleStateChange() {
    const requestIds = [];
    this.state.selectedGrid.map((request) => {
      return requestIds.push(request.idRichies);
    });
    const jobState = this.state.filterByStatoLavor;
    const result = this.state.selectedEsito;
    const data = [];
    requestIds.map((request) => {
      return data.push({
        esito: result,
        statoLavor: jobState,
        idRichies: request
      });
    });

    const res = await RichiestiAPI.multipleStateChange(data);
    if (res && res?.result) {
      this.activeSnackbar("success", "Stato cambiato con successo");
      this.setState({
        openPopUp: false
      });
      this.setState({
        selectedGrid: []
      });
      return true;
    } else {
      this.activeSnackbar("danger", "Errore");
      return true;
    }
  }

  render() {
    const stampaEtichetta = (
      <GridItem
        md={6}
        style={{
          float: "right",
          marginLeft: "0px",
          padding: "1px !important",
          display: "flex",
          alignItems: "center"
        }}
      >
        <Dropdown
          display="chip"
          placeholder="Scegli Etichetta"
          optionLabel="name"
          value={this.state.filterByEtichette}
          options={this.state.Etichette}
          onChange={(e) =>
            this.HandleChange("filterByEtichette", e.target.value)
          }
        />
        <CustomButton
          disabled={
            !this.state.selectedGrid || this.state.selectedGrid.length === 0
          }
          onClick={this.printLabel}
          style={{ marginLeft: "1vh" }}
        >
          Stampa Etichetta
        </CustomButton>
      </GridItem>
    );
    const table = (
      <DataTable
        emptyMessage=""
        className="p-datatable-striped p-datatable-sm"
        value={this.state.filteredRows}
        dataKey="idRichies"
        first={this.state.firstRowToDisplay}
        paginator
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
        rows={this.state.pageSize}
        onSort={this.onSortChange}
        sortField={this.state.sortField}
        sortOrder={this.state.sortOrder}
        totalRecords={this.state.totalRecords}
        loading={this.state.loadingGrid}
        paginatorLeft={this.paginatorLeft}
        paginatorRight={this.paginatorRight}
        selection={this.state.selectedGrid}
        scrollable
        style={{ width: "100%" }}
        onSelectionChange={this.handleTableClick}
        selectionMode="multiple"
        metaKeySelection={false}
      >
        <Column
          selectionMode="multiple"
          headerStyle={{ width: "40px", paddingLeft: "3px" }}
        />
        <Column
          header=""
          headerStyle={{
            width: "100px",
            textAlign: "center"
          }}
          body={this.DocActionsGridTemplate}
          className="colDocActions"
        />
        <Column
          field="anno"
          body={(rowData) => rowData?.idBorsa?.annoAcc?.descr}
          header="Anno"
          sortable={true}
          headerStyle={{ width: "90px" }}
        />
        <Column
          field="protRichies"
          header="Protocollo"
          sortable={true}
          headerStyle={{ width: "140px" }}
        />
        <Column
          field="tipoborsa"
          body={(rowData) => rowData?.idBorsa?.tipoBorsa?.descrBr}
          header="Tipo"
          sortable={true}
          headerStyle={{ width: "80px" }}
        />
        <Column
          field="cfdip"
          body={(rowData) => rowData?.cfRich?.cfRich}
          header="C. F. Richiedente"
          sortable={true}
          headerStyle={{ width: "160px" }}
        />
        <Column
          field="cognome"
          header="Cognome"
          body={(rowData) => rowData?.cfRich?.cognome}
          sortable={true}
          headerStyle={{ width: "150px" }}
        />
        <Column
          field="nome"
          header="Nome"
          body={(rowData) => rowData?.cfRich?.nome}
          sortable={true}
          headerStyle={{ width: "150px" }}
        />
        <Column
          field="cfRich.dataNasc"
          header="Data Nascita"
          body={(row) =>
            moment(row.cfRich.dataNasc, dateTimeFmt).format(dateFmt)
          }
          sortable={true}
          headerStyle={{ width: "140px" }}
        />
        <Column
          field="disabile"
          header="Dis."
          sortable={true}
          headerStyle={{ width: "70px" }}
        />
        <Column
          field="orfano"
          header="Orf."
          sortable={true}
          headerStyle={{ width: "80px" }}
        />
        <Column
          field="studlav"
          body={(rowData) => rowData?.studLav}
          header="Stud. Lav."
          sortable={true}
          headerStyle={{ width: "120px" }}
        />
        <Column
          field="statolavor"
          body={(rowData) => rowData?.statoLavor?.descr}
          header="Stato"
          sortable={true}
          headerStyle={{ width: "100px" }}
        />
        <Column
          field="esito"
          body={(rowData) => rowData?.esito?.descr}
          header="Esito"
          sortable={true}
          headerStyle={{ width: "100px" }}
        />
        <Column
          field="motivo"
          body={(rowData) => rowData?.motivo?.descr}
          header="Motivo"
          sortable={true}
          headerStyle={{ width: "100px" }}
        />
        <Column
          field="voto"
          header="Voto"
          sortable={true}
          headerStyle={{ width: "90px" }}
        />
        <Column
          field="lode"
          header="Lode"
          sortable={true}
          headerStyle={{ width: "80px" }}
        />
        <Column
          field="mediapes"
          header="Media Pesata"
          sortable={true}
          headerStyle={{ width: "140px" }}
          body={(row) => row.mediaPes?.toFixed(2)}
        />
        <Column
          field="cfucons"
          body={(rowData) => rowData?.cfuCons}
          header="CFU Cons."
          sortable={true}
          headerStyle={{ width: "120px" }}
        />
        <Column
          field="cfuperc"
          body={(rowData) => rowData?.cfuPerc}
          header="CFU Perc."
          sortable={true}
          headerStyle={{ width: "120px" }}
        />
        <Column
          field="verifvers"
          body={(rowData) => rowData?.verifVers}
          header="Pagamenti"
          sortable={true}
          headerStyle={{ width: "130px" }}
        />
        <Column
          field="nota"
          header="Note"
          sortable={true}
          headerStyle={{ width: "120px" }}
          body={(row) => helpers.truncateString(row, "nota")}
        />
        <Column
          field="comune"
          body={(rowData) => rowData?.cfRich?.comune}
          header="Residenza"
          sortable={true}
          headerStyle={{ width: "120px" }}
        />
        <Column
          field="scuola"
          header="IstUniv"
          sortable={true}
          headerStyle={{ width: "120px" }}
          body={(row) => helpers.truncateString(row, "scuola")}
        />
        <Column
          field="corso"
          header="Corso"
          sortable={true}
          headerStyle={{ width: "250px" }}
        />
        <Column
          field="facolta"
          header="Facolta"
          sortable={true}
          headerStyle={{ width: "150px" }}
        />
        <Column
          field="indirscuola"
          body={(rowData) => rowData?.indirScuola}
          header="IndFacolta"
          sortable={true}
          headerStyle={{ width: "200px" }}
        />
        <Column
          header=""
          headerStyle={{ width: "35px" }}
          body={this.DeletePulitoriGridTemplate}
          icon="pi pi-times"
          label="Delete"
          className="p-button-danger p-button-outlined colDelete"
        />
      </DataTable>
    );

    return (
      <>
        {this.state.loadingPage && <LoaderSpinner />}
        <div ref={this.btnSearchRef} />
        <Dialog
          header="Cambio stato multiplo"
          visible={this.state.openPopUp}
          style={{ width: "50vw" }}
          onHide={() => {
            this.setState({
              openPopUp: false
            });
          }}
        >
          <GridContainer>
            <GridItem xs={6}>
              <p
                className="lblNoMargin"
                style={{
                  color:
                    this.state.fieldValidations["stato"] === "error"
                      ? "#f44336"
                      : "#565656"
                }}
              >
                Stato Lavor *
              </p>
              <Dropdown
                display="chip"
                success={
                  this.state.fieldValidations.stato &&
                  this.state.fieldValidations.stato === "success"
                }
                error={
                  this.state.fieldValidations.stato &&
                  this.state.fieldValidations.stato === "error"
                }
                required={true}
                placeholder="Cerca stato lavor"
                value={this.state.filterByStatoLavor}
                options={this.state.jobStates}
                onChange={(e) => {
                  this.setState((prevState) => {
                    return { ...prevState, selectedJobState: e.target.value };
                  });
                  this.HandleDropDownChange(
                    "filterByStatoLavor",
                    e.target.value,
                    "stato"
                  );
                }}
              />
            </GridItem>
            {(this.state.selectedJobState === 4 ||
              this.state.selectedJobState === 5) && (
              <GridItem xs={6}>
                <p
                  className="lblNoMargin"
                  style={{
                    color:
                      this.state.fieldValidations["esito"] === "error"
                        ? "#f44336"
                        : "#565656"
                  }}
                >
                  Esito *
                </p>
                <Dropdown
                  disabled={this.state.isResultDisabled}
                  display="chip"
                  success={
                    this.state.fieldValidations.esito &&
                    this.state.fieldValidations.esito === "success"
                  }
                  error={
                    this.state.fieldValidations.esito &&
                    this.state.fieldValidations.esito === "error"
                  }
                  required={true}
                  placeholder="Cerca esito"
                  value={this.state.selectedEsito}
                  options={this.state.Esito}
                  onChange={(e) => {
                    this.HandleDropDownChange(
                      "selectedEsito",
                      e.target.value,
                      "idEsito"
                    );
                  }}
                />
              </GridItem>
            )}
          </GridContainer>

          <GridContainer style={{ marginTop: "80px", marginLeft: "5px" }}>
            <Danger>
              <Warning />
              Selezionare i valori di interese da applicare alle Richieste
              evidenziate
            </Danger>
          </GridContainer>
          <GridContainer>
            <GridItem style={{ marginLeft: "35%", marginRight: "40%" }}>
              <ButtonSaveWithLoading
                onClick={this.multipleStateChange}
                text={"Avvia"}
                isValidForm={this.isValidForm}
              />
            </GridItem>
          </GridContainer>
        </Dialog>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <h4 className="d-inline">
                  Ricerca Richieste di Borse di Studio
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <p className="lblNoMargin">Anno</p>
                    <Dropdown
                      display="chip"
                      placeholder="Ricerca per anni"
                      optionLabel="descr"
                      value={this.state.filterByAnni}
                      options={this.state.AnniAccademici}
                      onChange={(e) =>
                        this.HandleChange("filterByAnni", e.target.value)
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <p className="lblNoMargin">Tipo Borsa</p>
                    <Dropdown
                      display="chip"
                      placeholder="Cerca per tipo"
                      optionLabel="descr"
                      optionValue="descr"
                      value={this.state.filterByTipoBorsa}
                      options={this.state.TipoBorsa}
                      onChange={(e) =>
                        this.HandleChange("filterByTipoBorsa", e.target.value)
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5}>
                    <p className="lblNoMargin">Cognome</p>
                    <AutoComplete
                      value={this.state.filterByNominativo}
                      placeholder="Cerca per Cognome"
                      suggestions={this.state.filteredCognomeListByInput}
                      onChange={(e) =>
                        this.HandleChange("filterByNominativo", e.target.value)
                      }
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer className="mt-2">
                  <GridItem xs={12} sm={12} md={3}>
                    <p className="lblNoMargin">Codice Fiscale</p>
                    <AutoComplete
                      value={this.state.filterByCodice}
                      placeholder="Cerca per Codice Fiscale"
                      suggestions={this.state.filteredCodfisListByInput}
                      onChange={(e) =>
                        this.HandleChange("filterByCodice", e.target.value)
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <p className="lblNoMargin">Protocollo</p>
                    <AutoComplete
                      value={this.state.filterByProtocollo}
                      placeholder="Cerca per Protocollo"
                      suggestions={this.state.filteredByProtocolloListByInput}
                      onChange={(e) =>
                        this.HandleChange("filterByProtocollo", e.target.value)
                      }
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <p className="lblNoMargin">Stato</p>
                    <Dropdown
                      display="chip"
                      placeholder="Cerca per stato"
                      optionLabel="descr"
                      optionValue="descr"
                      value={this.state.filterByStatoLavor}
                      options={this.state.statoLavoro}
                      onChange={(e) => {
                        this.HandleDropDownChange(
                          "filterByStatoLavor",
                          e.target.value,
                          "idStato"
                        );
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <p className="lblNoMargin">Righe</p>
                    <Dropdown
                      display="chip"
                      value={this.state.pageSize}
                      options={this.state.resultSizes}
                      onChange={(e) => this.pageSizeHandler(e)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={1}>
                    <Tooltip
                      arrow
                      style={{ marginTop: "20px" }}
                      id="tooltip-top"
                      title="Cerca"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <CustomButton
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        onClick={this.handleSearchClick}
                      >
                        <Search />
                      </CustomButton>
                    </Tooltip>

                    <Tooltip
                      arrow
                      style={{ marginLeft: "4px", marginTop: "20px" }}
                      id="tooltip-top"
                      title="Reimposta"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <CustomButton
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        onClick={this.handleClearClick}
                      >
                        <Clear />
                      </CustomButton>
                    </Tooltip>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borsesuccess">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Richieste presentate
                </h4>

                <ButtonGroup
                  variant="contained"
                  color="default"
                  size="small"
                  aria-label="contained default button group"
                  className={"float-right " + this.state.groupClass}
                  style={{ verticalAlign: "center", margin: "0.5%" }}
                >
                  <ButtonsMUI onClick={this.printList}>
                    Stampa Elenco
                  </ButtonsMUI>
                  <ButtonsMUI onClick={this.lettersGeneratorHandler}>
                    Genera Lettere
                  </ButtonsMUI>
                  <ButtonsMUI onClick={this.printRequestedCards}>
                    Stampa Schede Richieste
                  </ButtonsMUI>
                  <ButtonsMUI onClick={this.onCambioClick}>
                    Cambio Stato Multiplo
                  </ButtonsMUI>
                </ButtonGroup>

                <GridContainer style={{ display: "inline-block" }}>
                  {stampaEtichetta}

                  <GridItem
                    xs={12}
                    sm={12}
                    md={3}
                    style={{
                      float: "right",
                      padding: "1px !important",
                      width: "200px",
                      marginTop: "5px"
                    }}
                  >
                    {this.state.generaVisible && (
                      <ButtonGroup
                        variant="contained"
                        color="default"
                        size="small"
                        aria-label="contained default button group"
                        className={"float-right " + this.state.groupClass}
                        style={{ verticalAlign: "center", margin: "0.5%" }}
                      >
                        <ButtonsMUI>Genera Etichette</ButtonsMUI>
                      </ButtonGroup>
                    )}
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody>{table}</CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12}>
            {this.state.lettersGenerator && (
              <LettersGenerator
                senderEmails={this.state.senderEmails}
                onSave={this.HandleSaveClick}
                onAnnullaClick={this.onAnnullaClick}
                {...this.props}
              />
            )}
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default Richieste;
