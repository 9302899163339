import helpers from "../helpers/Helpers";
import Utils from "./Utils";

const DitteAPI = {
  GetAllDitteByAmministratoriIdAziendaId: async function (
    amministratoriId,
    aziendaId
  ) {
    let result = await Utils.CreatePostRequest(
      "coasco/amministratori_aziende/getbyidamministratoreidazienda?idamministratore=" +
        amministratoriId +
        "&idazienda=" +
        aziendaId
    );

    if (result !== null)
      result = result.result.map((item) => ({
        ...item.azienda,
        data_inizio: item.data_inizio,
        data_fine: item.data_fine,
        data_ult_agg: item.data_ult_agg,
        tipo_ult_agg: item.tipo_ult_agg,
        utente: item.utente,
        id_ditte: item.azienda.id,
        id: item.id,
        cf: item.cf
      }));

    return result;
  },

  GetAllDitteByAmministratoriId: async function (amministratoriId) {
    let result = await Utils.CreatePostRequest(
      "coasco/amministratori_aziende/getbyidamministratore?idamministratore=" +
        amministratoriId
    );

    if (result !== null)
      result = result.result.map((item) => ({
        ...item.azienda,
        data_inizio: item.data_inizio,
        data_fine: item.data_fine,
        data_ult_agg: item.data_ult_agg,
        tipo_ult_agg: item.tipo_ult_agg,
        utente: item.utente,
        id_azienda: item.azienda.id,
        id_amministratore: item.amministratore.id,
        id: item.id,
        cf: item.cf
      }));

    return result;
  },

  GetAllAmministratoriStId: async function (amministratoriId) {
    let result = await Utils.CreatePostRequest(
      "coasco/amministratori_aziendest/getbyidamministratoreazienda?id=" +
        amministratoriId
    );

    if (result !== null)
      result = result.result.map((item) => ({
        ...item.azienda,
        data_inizio: item.data_inizio,
        data_fine: item.data_fine,
        data_ult_agg: item.data_ult_agg,
        tipo_ult_agg: item.tipo_ult_agg,
        utente: item.utente,
        id_amministratore_azienda: item.id_amministratore_azienda,
        idst: item.idst,
        cf: item.cf
      }));

    return result;
  },

  GetAllDitteByConsulenteIdAziendaId: async function (consulentiId, aziendaId) {
    let result = await Utils.CreatePostRequest(
      "coasco/consulenti_aziende/getbyidconsulenteidazienda?idconsulente=" +
        consulentiId +
        "&idazienda=" +
        aziendaId
    );

    if (result !== null)
      result = result.result.map((item) => ({
        ...item.azienda,
        data_inizio: item.data_inizio,
        data_fine: item.data_fine,
        data_ult_agg: item.data_ult_agg,
        tipo_ult_agg: item.tipo_ult_agg,
        utente: item.utente,
        id_ditte: item.azienda.id,
        id: item.id,
        cf: item.cf
      }));

    return result;
  },

  GetAllDitteByConsulentiId: async function (consulentiId) {
    let result = await Utils.CreatePostRequest(
      "coasco/consulenti_aziende/getbyidconsulente?idconsulente=" + consulentiId
    );

    if (result !== null)
      result = result.result.map((item) => ({
        ...item.azienda,
        data_inizio: item.data_inizio,
        data_fine: item.data_fine,
        data_ult_agg: item.data_ult_agg,
        tipo_ult_agg: item.tipo_ult_agg,
        utente: item.utente,
        id_ditte: item.azienda.id,
        id: item.id,
        cf: item.cf
      }));

    return result;
  },

  GetAllStoricoDitteByConsulentiId: async function (consulentiId) {
    let result = await Utils.CreatePostRequest(
      "coasco/consulenti_aziendest/getbyidconsulenteazienda?id=" + consulentiId
    );

    if (result !== null)
      result = result.result.map((item) => ({
        ...item.azienda,
        data_inizio: item.data_inizio,
        data_fine: item.data_fine,
        data_ult_agg: item.data_ult_agg,
        tipo_ult_agg: item.tipo_ult_agg,
        utente: item.utente,
        id_ditte: item.id_consulente_azienda,
        id: item.idst,
        cf: item.cf
      }));

    return result;
  },

  GetNuovoDitteByAmministratoriId: async function () {
    return {
      id: "",
      matricola: "",
      data_ult_agg: "",
      tipo_ult_agg: "",
      id_ditte: "",
      cf: "",
      data_inizio: "",
      data_fine: "",
      utente: ""
    };
  },

  GetNuovoDitteForConsulenteById: async function () {
    return {
      id: "",
      matricola: "",
      data_ult_agg: "",
      tipo_ult_agg: "",
      id_ditte: "",
      cf: "",
      data_inizio: "",
      data_fine: "",
      utente: ""
    };
  },

  UpdateAmministratoreDitte: async function (ditteobj) {
    let ditteAmministratore = {
      data_inizio: helpers.ConvertDateAsArrayWithSlash(ditteobj?.data_inizio),
      data_fine: helpers.ConvertDateAsArrayWithSlash(ditteobj?.data_fine),
      matricola: ditteobj.matricola,
      id_amministratoreazienda: ditteobj.id
    };
    return await Utils.CreatePutRequest(
      "coasco/amministratori_aziende/updaterelation?id_amministratoreazienda=" +
        ditteobj.id,
      ditteAmministratore
    );
  },

  AddAmministratoreDitte: async function (ditteobj, idAmministratore) {
    let ditteAmministratore = {
      data_inizio: helpers.ConvertDateAsArrayWithSlash(ditteobj?.data_inizio),
      data_fine: helpers.ConvertDateAsArrayWithSlash(ditteobj?.data_fine),
      matricola: ditteobj.matricola,
      id_amministratoreazienda: 0
    };
    return await Utils.CreatePutRequest(
      "coasco/amministratori_aziende/insertrelation?id_amministratore=" +
        idAmministratore,
      ditteAmministratore
    );
  },

  UpdateConsulenteDitte: async function (ditteobj) {
    let ditteConsulente = {
      data_inizio: helpers.ConvertDateAsArrayWithSlash(ditteobj?.data_inizio),
      data_fine: helpers.ConvertDateAsArrayWithSlash(ditteobj?.data_fine),
      matricola: ditteobj.matricola,
      id_consulenteazienda: ditteobj.id
    };
    await Utils.CreatePutRequest(
      "coasco/consulenti_aziende/updaterelation?id_consulenteazienda=" +
        ditteobj.id,
      ditteConsulente
    );
  },

  AddConsulenteDitte: async function (ditteobj, idConsulente) {
    let ditteConsulente = {
      data_inizio: helpers.ConvertDateAsArrayWithSlash(ditteobj?.data_inizio),
      data_fine: helpers.ConvertDateAsArrayWithSlash(ditteobj?.data_fine),
      matricola: ditteobj.matricola,
      id_consulenteazienda: 0
    };

    return await Utils.CreatePutRequest(
      "coasco/consulenti_aziende/insertrelation?id_consulente=" + idConsulente,
      ditteConsulente
    );
  },

  DeleteAziendeConsulenti: async function (consId) {
    return await Utils.CreateDeleteRequest(
      "coasco/consulenti_aziende/delete?id=" + consId
    );
  }
};
export default DitteAPI;
