import Utils from "./Utils";

const StoricoAPI = {
  GetStoricoForAmministratoriById: async function (id) {
    let result = await Utils.CreatePostRequest(
      "coasco/amministratorist/getbyidamministratore?id=" + id
    );

    if (result === null) result = [];
    return result.result;
  },

  GetStoricoForAziendeById: async function (id) {
    let result = await Utils.CreatePostRequest(
      "coasco/aziendest/getbyidazienda?id=" + id
    );

    if (result === null) result = [];
    return result.result;
  },

  GetStoricoForConsulentiById: async function (id) {
    let result = await Utils.CreatePostRequest(
      "coasco/consulentist/getbyidconsulente?id=" + id
    );

    if (result === null) result = [];
    return result.result;
  },
  GetStoricoForDipendentiById: async function (id) {
    let result = await Utils.CreatePostRequest(
      "coasco/dipendentist/getbyiddipendente?id=" + id
    );

    if (result === null) result = [];
    return result.result;
  },
  GetStoricoForRDLById: async function (id) {
    let result = await Utils.CreatePostRequest(
      "coasco/rdlst/getbyidrdl?id=" + id
    );

    if (result === null) result = [];
    return result.result;
  },

  GetStoricoRegolarizzate: async function () {
    const url = 'coasco/anomalieregolarizzate/getAll';
    const response = await Utils.CreatePostRequest(url);
    if (response && response.message && response.message.includes('200'))
      return response.result;
    else return null;
  },

  GetStoricoCaricamenti: async function () {
    const url = 'coasco/anomaliecaricate/getAll';
    const response = await Utils.CreatePostRequest(url);
    if (response && response.message && response.message.includes('200'))
      return response.result;
    else return null;
  },

  GetStoricoAnnullate: async function () {
    const url = 'coasco/anomalieannullate/getAll';
    const response = await Utils.CreatePostRequest(url);
    if (response && response.message && response.message.includes('200'))
      return response.result;
    else return null;
  },

  GetStoricoAziendaByYear: async function (aziendaId, year) {
    let rows = [
      { id: 1, firstName: "Vasile", lastName: "Popescu", aziendaYear: 2001 },
      { id: 2, firstName: "Ion", lastName: "Toma", aziendaYear: 2018 },
      { id: 3, firstName: "Maria", lastName: "Popovici", aziendaYear: 2018 },
      { id: 4, firstName: "Mariana", lastName: "Ionescu", aziendaYear: 2019 },
      { id: 5, firstName: "Magda", lastName: "Ciortescu", aziendaYear: 2019 },
      { id: 6, firstName: "Costel", lastName: "Vasilescu", aziendaYear: 2020 },
      { id: 7, firstName: "Paul", lastName: "Vasilache", aziendaYear: 2020 },
      { id: 8, firstName: "Catalin", lastName: "Petrache", aziendaYear: 2021 },
      { id: 9, firstName: "Ionut", lastName: "Dumitrescu", aziendaYear: 2021 },
      { id: 10, firstName: "Adelina", lastName: "Toma", aziendaYear: 2017 },
      { id: 11, firstName: "Ioana", lastName: "Popovici", aziendaYear: 2017 },
      { id: 12, firstName: "Catalina", lastName: "Ionescu", aziendaYear: 2017 },
      {
        id: 13,
        firstName: "Vasilica",
        lastName: "Ciortescu",
        aziendaYear: 2016
      },
      { id: 14, firstName: "Elena", lastName: "Vasilescu", aziendaYear: 2015 },
      { id: 15, firstName: "Paula", lastName: "Vasilache", aziendaYear: 2014 },
      { id: 16, firstName: "Daniel", lastName: "Petrache", aziendaYear: 2014 }
    ];
    return rows.filter((row) => row.aziendaYear === year);
  },

  GetStoricoHeaderByDipendenteId: async function () {
    return [
      {
        id: 1,
        indirizzo: "VIA DEL CASTELLO 00020",
        cap: 32043,
        comune: "CORTINA",
        prov: "BL",
        sesso: "",
        ultimoagg: "24/03/2020",
        utente: "Sistema"
      },
      {
        id: 2,
        indirizzo: "VIA DEL CASTELLO",
        cap: 20,
        comune: "32043",
        prov: "CORTINA",
        sesso: "",
        cf: "BL",
        ultimoagg: "",
        utente: "24/03/2020"
      },
      {
        id: 3,
        indirizzo: "VIA DEL CASTELLO",
        cap: 20,
        comune: "32043",
        prov: "CORTINA",
        sesso: "",
        cf: "BL",
        ultimoagg: "",
        utente: "24/03/2020"
      },
      {
        id: 4,
        indirizzo: "VIA DEL CASTELLO 20",
        cap: 32043,
        comune: "CORTINA",
        prov: "BL",
        sesso: "",
        cf: "",
        ultimoagg: "",
        utente: ""
      }
    ];
  },

  GetStoricoHeaderByConsulenteId: async function () {
    return [
      {
        id: 1,
        indirizzo: "VIA DEL CASTELLO 00020",
        cap: 32043,
        comune: "CORTINA",
        prov: "BL",
        tel: "",
        fax: "",
        email: "",
        ultimoagg: "24/03/2020",
        utente: "Sistema"
      },
      {
        id: 2,
        indirizzo: "VIA DEL CASTELLO",
        cap: 20,
        comune: "32043",
        prov: "CORTINA",
        tel: "",
        fax: "",
        email: "",
        ultimoagg: "",
        utente: "24/03/2020"
      },
      {
        id: 3,
        indirizzo: "VIA DEL CASTELLO",
        cap: 20,
        comune: "32043",
        prov: "CORTINA",
        tel: "0922 292 313",
        fax: "",
        email: "",
        ultimoagg: "",
        utente: "24/03/2020"
      },
      {
        id: 4,
        indirizzo: "VIA DEL CASTELLO 20",
        cap: 32043,
        comune: "CORTINA",
        prov: "BL",
        tel: "",
        fax: "",
        email: "",
        ultimoagg: "",
        utente: ""
      }
    ];
  }
};
export default StoricoAPI;
