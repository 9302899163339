import React from "react";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Tooltip from "@material-ui/core/Tooltip";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import Button from "components/CustomButtons/Button.js";
import ButtonsMUI from "@material-ui/core/Button";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import AnomalieAPI from "API/AnomalieAPI";
import moment from "moment";
import AziendeAPI from "../../API/AziendeAPI";
import { dateFmt } from "../../helpers/AppConstants";
import Utils from "../../API/Utils";
import helpers from "../../helpers/Helpers";

class AnomalieLavorate extends React.Component {

  state = {
    rows: [],
    rowsReg: [],
    rowsAnn: [],
    loadingGrid: false,
    loadingAnnGrid: false,
    loadingRegGrid: false,
    selectedGrid: null,
    selectedRegGrid: null,
    selectedAnnGrid: null,
    selectedMatricola: null,
    arrayId: null,
    inizioDate: new Date(),
    filteredMatricola: null,
    searchRagioneSociale: "",

    firstRowToDisplay: 0,
    firstRowToDisplayReg: 0,
    firstRowToDisplayAnn: 0,

    rowsPerPege: 5,
    rowsPerPegeReg: 5,
    rowsPerPegeAnn: 5,

    totalRecords: 0,
    totalRecordsReg: 0,
    totalRecordsAnn: 0,

    sortField: "",
    sortOrder: 0,

    sortFieldReg: "",
    sortOrderReg: 0,

    sortFieldAnn: "",
    sortOrderAnn: 0,
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.handleRegTableClick = this.handleRegTableClick.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.searchMatricola = this.searchMatricola.bind(this);
    this.HandleDateChange = this.HandleDateChange.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onPageReg = this.onPageReg.bind(this);
    this.onPageAnn = this.onPageAnn.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onSortReg = this.onSortReg.bind(this);
    this.onSortAnn = this.onSortAnn.bind(this);
    this.btnSearchRef = React.createRef();
  }

  onSort(e) {
    this.setState(
        {sortField: e.sortField, sortOrder: e.sortOrder},
        async () => await this.refreshGrid()
    );
  }

  onSortReg(e) {
    this.setState(
        {sortFieldReg: e.sortField, sortOrderReg: e.sortOrder},
        async () => await this.refreshGrid()
    );
  }

  onSortAnn(e) {
    this.setState(
        {sortFieldAnn: e.sortField, sortOrderAnn: e.sortOrder},
        async () => await this.refreshGrid()
    );
  }

  onPage(e) {
    this.setState(
        {rowsPerPege: e.rows, firstRowToDisplay: e.first},
        async () => await this.refreshGrid()
    );
  }

  onPageReg(e) {
    this.setState(
        {rowsPerPegeReg: e.rows, firstRowToDisplayReg: e.first},
        async () => await this.refreshGrid()
    );
  }

  onPageAnn(e) {
    this.setState(
        {rowsPerPegeAnn: e.rows, firstRowToDisplayAnn: e.first},
        async () => await this.refreshGrid()
    );
  }

  dateToString(date) {
    if (date !== null)
      return <>{moment(date).format(dateFmt)}</>;
    else return null;
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  searchMatricola(event) {
    setTimeout(async () => {
      let list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByMatr(event.query)
          : [];
      this.setState({ filteredMatricola: list });
    }, 250);
  }

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  HandleDateChange(e) {
    this.setState({
      inizioDate: e.target.value
    });
  }

  handleSearchClick = async () => {
    this.setState({
      selectedGrid: null,
      selectedAnnGrid: null,
      selectedRegGrid: null
    });

    await this.refreshGrid();
  };

  async handleClearClick() {
    this.setState(
      {
        selectedGrid: null,
        selectedAnnGrid: null,
        selectedRegGrid: null,
        selectedProvincia: null,
        selectedRegione: null,
        selectedSAP: null,
        selectedAnomalia: null,
        selectedCodice: null,
        filteredMatricola: null,
        selectedMatricola: null,
        inizioDate: new Date()
      },
      async () => await this.refreshGrid()
    );
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true,
      loadingAnnGrid: true,
      loadingRegGrid: true
    });

    const date = moment(this.state.inizioDate).format(dateFmt),
      selectedMatricola = this.state.selectedMatricola;

    AnomalieAPI.GetAnomalieCaricate(date, selectedMatricola, this.state.firstRowToDisplay, this.state.rowsPerPege, this.state.sortField, this.state.sortOrder).then((response) =>
      this.setState({
        rows: response.result,
        loadingGrid: false,
        totalRecords: response.tot
      })
    );

    AnomalieAPI.GetAnomalieRegolarizzate(date, selectedMatricola, this.state.firstRowToDisplayReg, this.state.rowsPerPegeReg, this.state.sortFieldReg, this.state.sortOrderReg).then((response) =>
      this.setState({
        rowsReg: response.result,
        loadingRegGrid: false,
        totalRecordsReg: response.tot
      })
    );

    AnomalieAPI.GetAnomalieAnnullate(date, selectedMatricola, this.state.firstRowToDisplayAnn, this.state.rowsPerPegeAnn, this.state.sortFieldAnn, this.state.sortOrderAnn).then((response) =>
      this.setState({
        rowsAnn: response.result,
        loadingAnnGrid: false,
        totalRecordsAnn: response.tot
      })
    );
  }

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: e.value
    });

    if (e.value.length > 0)
      this.setState({
        arrayId: e.value[0].id
      });
  };

  handleRegTableClick = async (e) => {
    this.setState({
      selectedRegGrid: e.value
    });

    if (e.value.length > 0)
      this.setState({
        arrayId: e.value[0].id
      });
  };

  handleAnnTableClick = async (e) => {
    this.setState({
      selectedAnnGrid: e.value
    });

    if (e.value.length > 0)
      this.setState({
        arrayId: e.value[0].id
      });
  };

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4>Anomalie</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2} className="date">
                    <p className="lblNoMargin">Data Riferimento</p>
                    <Calendar
                      id="icon"
                      value={this.state.inizioDate}
                      dateFormat="dd/mm/yy"
                      onChange={this.HandleDateChange}
                      showIcon
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <p className="lblNoMargin">Azienda</p>
                    <AutoComplete
                      value={this.state.selectedMatricola}
                      placeholder="Cerca per Matricola"
                      suggestions={this.state.filteredMatricola}
                      completeMethod={this.searchMatricola}
                      onChange={(e) =>
                        this.HandleChange("selectedMatricola", e.value)
                      }
                      className="matricolaAutocomplete"
                      type="number"
                    />
                  </GridItem>

                  <GridItem xs={2}>
                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Cerca"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <Button
                        ref={this.btnSearchRef}
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%",
                          marginLeft: "0",
                          marginRight: "4%",
                          marginTop: "10%"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        onClick={this.handleSearchClick}
                      >
                        <Search />
                      </Button>
                    </Tooltip>

                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Reimposta"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <Button
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%",
                          marginRight: "0",
                          marginTop: "10%"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        onClick={this.handleClearClick}
                      >
                        <Clear />
                      </Button>
                    </Tooltip>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Anomalie Caricate{" "}
                </h4>
                <ButtonGroup
                    variant="contained"
                    color="primary"
                    size="small"
                    aria-label="contained primary button group"
                    className="float-right"
                    style={{
                      verticalAlign: "center",
                      marginTop: "12px",
                      marginBottom: "10px",
                      marginLeft: "10px",
                      marginRight: "10px"
                    }}
                >
                  <ButtonsMUI onClick={() => Utils.ExportExcel(this.state.rows)}>
                    Esporta
                  </ButtonsMUI>
                </ButtonGroup>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator lazy
                  onPage={this.onPage}
                  totalRecords={this.state.totalRecords}
                  first={this.state.firstRowToDisplay}
                  onSort={this.onSort}
                  sortField={this.state.sortField}
                  sortOrder={this.state.sortOrder}
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPege}
                  loading={this.state.loadingGrid}
                  rowsPerPageOptions={[5, 10, 15, 100]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="matricola"
                    header="Matricola"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                  />
                  <Column
                    field="codQuota"
                    header="Cod. Quota"
                    sortable={true}
                    headerStyle={{ width: "140px" }}
                  />
                  <Column
                    field="cod_anomalia"
                    header="Tipo"
                    sortable={true}
                    headerStyle={{ width: "90px" }}
                  />
                  <Column
                    field="comp"
                    header="Comp."
                    sortable={true}
                    headerStyle={{ width: "90px" }}
                  />
                  <Column
                    field="cf_dip"
                    header="C. F. Dip."
                    sortable={true}
                    headerStyle={{ width: "150px" }}
                  />
                  <Column
                    field="importoDov"
                    header="Dovuto"
                    sortable={true}
                    headerStyle={{ width: "120px" }}
                    body={row => helpers.DecimalDTTemplate(row, 'importoDov')}
                  />
                  <Column
                    field="importoDic"
                    header="Dichiarato"
                    sortable={true}
                    headerStyle={{ width: "140px" }}
                    body={row => helpers.DecimalDTTemplate(row, 'importoDic')}
                  />
                  <Column
                    field="dataRilevaz"
                    header="Data Rilevaz."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={row => this.dateToString(row.dataRilevaz)}
                  />
                  <Column
                    field="dataComunic"
                    header="Data Comunic."
                    sortable={true}
                    headerStyle={{ width: "160px" }}
                    body={row => this.dateToString(row.dataComunic)}
                  />
                  <Column
                    field="numComun"
                    header="N. Com."
                    sortable={true}
                    headerStyle={{ width: "130px" }}
                  />
                  <Column
                    field="dataOk"
                    header="Data Ok"
                    sortable={true}
                    headerStyle={{ width: "110px" }}
                    body={row => this.dateToString(row.dataOk)}
                  />
                  <Column
                    field="tipo_ok"
                    header="Tipo Ok"
                    sortable={true}
                    headerStyle={{ width: "110px" }}
                  />
                  <Column
                    field="utente"
                    header="Utente"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                  />
                  <Column
                    field="note"
                    header="Note"
                    headerStyle={{ width: "300px" }}
                    sortable={true}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Anomalie Regolarizzate{" "}
                </h4>
                <ButtonGroup
                    variant="contained"
                    color="primary"
                    size="small"
                    aria-label="contained primary button group"
                    className="float-right"
                    style={{
                      verticalAlign: "center",
                      marginTop: "12px",
                      marginBottom: "10px",
                      marginLeft: "10px",
                      marginRight: "10px"
                    }}
                >
                  <ButtonsMUI onClick={() => Utils.ExportExcel(this.state.rowsReg)}>
                    Esporta
                  </ButtonsMUI>
                </ButtonGroup>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rowsReg}
                  dataKey="id"
                  paginator lazy
                  onPage={this.onPageReg}
                  totalRecords={this.state.totalRecordsReg}
                  first={this.state.firstRowToDisplayReg}
                  onSort={this.onSortReg}
                  sortField={this.state.sortFieldReg}
                  sortOrder={this.state.sortOrderReg}
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPegeReg}
                  loading={this.state.loadingRegGrid}
                  rowsPerPageOptions={[5, 10, 15, 100]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedRegGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleRegTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="matricola"
                    header="Matricola"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                  />
                  <Column
                    field="codQuota"
                    header="Cod. Quota"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                  />
                  <Column
                    field="cod_anomalia"
                    header="Tipo"
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                  />
                  <Column
                    field="comp"
                    header="Comp."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                  />
                  <Column
                    field="cf_dip"
                    header="C. F. Dip."
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                  />
                  <Column
                    field="importoDov"
                    header="Dovuto"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    body={row => helpers.DecimalDTTemplate(row, 'importoDov')}
                  />
                  <Column
                    field="importoDic"
                    header="Dichiarato"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={row => helpers.DecimalDTTemplate(row, 'importoDic')}
                  />
                  <Column
                    field="dataRilevaz"
                    header="Data Rilevaz."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={row => this.dateToString(row.dataRilevaz)}
                  />
                  <Column
                    field="dataComunic"
                    header="Data Comunic."
                    headerStyle={{ width: "160px" }}
                    sortable={true}
                    body={row => this.dateToString(row.dataComunic)}
                  />
                  <Column
                    field="numComun"
                    header="N. Com."
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                  />
                  <Column
                    field="dataOk"
                    header="Data Ok"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    body={row => this.dateToString(row.dataOk)}
                  />
                  <Column
                    field="tipo_ok"
                    header="Tipo ok"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                  />
                  <Column
                    field="utente"
                    header="Utente"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                  />
                  <Column
                    field="note"
                    header="Note"
                    headerStyle={{ width: "300px" }}
                    sortable={true}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Anomalie Annullate{" "}
                </h4>
                <ButtonGroup
                    variant="contained"
                    color="primary"
                    size="small"
                    aria-label="contained primary button group"
                    className="float-right"
                    style={{
                      verticalAlign: "center",
                      marginTop: "12px",
                      marginBottom: "10px",
                      marginLeft: "10px",
                      marginRight: "10px"
                    }}
                >
                  <ButtonsMUI onClick={() => Utils.ExportExcel(this.state.rowsAnn)}>
                    Esporta
                  </ButtonsMUI>
                </ButtonGroup>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rowsAnn}
                  dataKey="id"
                  paginator lazy
                  onPage={this.onPageAnn}
                  totalRecords={this.state.totalRecordsAnn}
                  first={this.state.firstRowToDisplayAnn}
                  onSort={this.onSortAnn}
                  sortField={this.state.sortFieldAnn}
                  sortOrder={this.state.sortOrderAnn}
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPegeAnn}
                  loading={this.state.loadingAnnGrid}
                  rowsPerPageOptions={[5, 10, 15, 100]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedAnnGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleAnnTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="matricola"
                    header="Matricola"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                  />
                  <Column
                    field="codQuota"
                    header="Cod. Quota"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                  />
                  <Column
                    field="cod_anomalia"
                    header="Tipo"
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                  />
                  <Column
                    field="comp"
                    header="Comp."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                  />
                  <Column
                    field="cf_dip"
                    header="C. F. Dip."
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                  />
                  <Column
                    field="importoDov"
                    header="Dovuto"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    body={row => helpers.DecimalDTTemplate(row, 'importoDov')}
                  />
                  <Column
                    field="importoDic"
                    header="Dichiarato"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={row => helpers.DecimalDTTemplate(row, 'importoDic')}
                  />
                  <Column
                    field="dataRilevaz"
                    header="Data Rilevaz."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={row => this.dateToString(row.dataRilevaz)}
                  />
                  <Column
                    field="dataComunic"
                    header="Data Comunic."
                    headerStyle={{ width: "160px" }}
                    sortable={true}
                    body={row => this.dateToString(row.dataComunic)}
                  />
                  <Column
                    field="numComun"
                    header="N. Com."
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                  />
                  <Column
                    field="dataOk"
                    header="Data ok"
                    sortable={true}
                    headerStyle={{ width: "110px" }}
                    body={row => this.dateToString(row.dataOk)}
                  />
                  <Column
                    field="tipo_ok"
                    header="Tipo ok"
                    sortable={true}
                    headerStyle={{ width: "110px" }}
                  />
                  <Column
                    field="utente"
                    header="Utente"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                  />
                  <Column
                    field="note"
                    header="Note"
                    sortable={true}
                    headerStyle={{ width: "300px" }}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default AnomalieLavorate;
