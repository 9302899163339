import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import UndoIcon from "@material-ui/icons/Undo";
import LoaderSpinner from "components/Layout/LoaderSpinner.js";
import Tooltip from "@material-ui/core/Tooltip";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import helpers from "helpers/Helpers";
import AmministratoriAPI from "API/AmministratoriAPI";
import ConsulentiAPI from "API/ConsulentiAPI";
import DipendentiAPI from "API/DipendentiAPI";
import {InputText} from "primereact/inputtext";
import moment from "moment";
import {dateFmt, rowsPerPageOptions} from "../../helpers/AppConstants";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import CustomButton from "../../components/CustomButtons/Button";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import { InputTextarea } from "primereact/inputtextarea";

class Protocollo extends React.Component {
  state = {
    rows: [],
    selectedCorrisp: null,
    selectedGrid: null,
    loadingGrid: false,
    visibleLoader: false,
    name: "",
    disabled: true,
    radioChecked: 1,
    attachments: [],
    selectedAttach: new Set(),
    firstRowToDisplay: 0,
    tipo: "",
    Anno: new Date().getFullYear(),
    HeaderDate: null,

    prot: "",
    headerDate: "",
    causale: "",
    sede: "",
    provSap: "",
    sapMatricola: "",
    daMatricola: "",
    aMatricola: "",

    initialRows: [],
    azienda: null,
    people: [],
    selectedTipo: 2,
    sortField: "data",
    sortOrder: -1,
    currentPage: 0,

    causali: null,
    rowsPerPage: 10,
    selectedAnno: null,
    selectedProtocollo: null,
    selectedTipoProt: null,
    selectedData: null,
    selectedCausale: null,
    selectedCfRich: null,
    selectedOggetto: null
  };

  constructor(props) {
    super(props);

    this.handleTableClick = this.handleTableClick.bind(this);
    this.templateCheckboxGrid = this.templateCheckboxGrid.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.RadioHandleChange = this.RadioHandleChange.bind(this);
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.LoadElementsInGrid = this.LoadElementsInGrid.bind(this);
    this.setSelectedTipo = this.setSelectedTipo.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.buildJson = this.buildJson.bind(this);
    this.headerRef = React.createRef();
  }

  setSelectedTipo(n) {
    this.setState({
      selectedTipo: parseInt(n)
    });
  }

  templateCheckboxGrid(rowData) {
    return (
      <div className="p-field-checkbox">
        <Checkbox
          inputId="binary"
          checked={rowData.pathattach}
          onChange={e => {
            e.checked
              ? this.state.selectedAttach.add(rowData.id)
              : this.state.selectedAttach.delete(rowData.id);
            let list = this.state.rows;
            let el = list.find((x) => x.id === rowData.id);
            el.pathattach = e.checked;
            this.setState({ rows: list, selectedCorrisp: null });
          }}
        />
      </div>
    );
  }

  async RadioHandleChange(e, val) {
    this.setState({
      [e]: val
    });

    const res = await this.filterMails(val.name);

    if (val !== null) {
      this.setState({
        radioChecked: val.id,
        name: val.name,
        tipo: val.name,
        rows: res?.array,
        totalRecords: res?.tot
      });
    }
  }

  async filterMails(type) {
    const _date = this.state.data;
    const month = _date?.getMonth() + 1;
    const day = _date?.getDate();
    const _month = parseInt(month) > 10 ? month : `0${month}`;
    const _day = parseInt(day) > 10 ? day : `0${day}`;

    const date = `${_day}/${_month}/${_date?.getFullYear()}`;

    switch (type) {
      case "Entrata":
        const res = await CorrispondenzaAPI.GetCorrispondenzaBy(0, 10, {
          tipo: type,
          anno: _date ? null : this.state.Anno,
          prot: this.state.prot,
          data: _date ? date : null,
          causale: this.state.causale,
          sapFilter: this.state.sede,
          provsap: this.state.provSap,
          sap_matri: this.state.sapMatricola,
          fromMatr: this.state.daMatricola,
          toMatr: this.state.aMatricola
        });
        const totalRecords = parseInt(res?.tot / 10);
        return { array: res?.result, totalRecords };
      case "Uscita":
        const res2 = await CorrispondenzaAPI.GetCorrispondenzaBy(0, 10, {
          tipo: type,
          anno: _date ? null : this.state.Anno,
          prot: this.state.prot,
          data: _date ? date : null,
          causale: this.state.causale,
          sapFilter: this.state.sede,
          provsap: this.state.provSap,
          sap_matri: this.state.sapMatricola,
          fromMatr: this.state.daMatricola,
          toMatr: this.state.aMatricola
        });
        const totalRecords2 = parseInt(res2?.tot / 10);
        return { array: res2?.result, totalRecords: totalRecords2 };
      case "Tutta":
        const res3 = await CorrispondenzaAPI.GetCorrispondenzaBy(0, 10, {
          anno: _date ? null : this.state.Anno,
          prot: this.state.prot,
          data: _date ? date : null,
          causale: this.state.causale,
          sapFilter: this.state.sede,
          provsap: this.state.provSap,
          sap_matri: this.state.sapMatricola,
          fromMatr: this.state.daMatricola,
          toMatr: this.state.aMatricola
        });
        const totalRecords3 = parseInt(res3?.tot / 10);
        return { array: res3?.result, totalRecords: totalRecords3 };
    }
  }

  async getPeople(aziendaId) {
    const adms = await AmministratoriAPI.GetAmministratoriForAziendaByAziendaId(
      aziendaId
    );
    const cons = await ConsulentiAPI.GetConsulentiForAziendaByAziendaId(
      aziendaId
    );
    const dips = await DipendentiAPI.GetDipendentiForAzienda(aziendaId);

    return [cons, dips, adms];
  }

  async onNuovoClick() {
    this.setState({
      visibleLoader: true
    });
    this.setState({
      selectedCorrisp: await CorrispondenzaAPI.GetNuovoCorrispondenza(
        this.state.azienda?.matricola
      ),
      selectedGrid: null,
      disabled: false
    });
    this.setState({
      visibleLoader: false
    });
  }

  onAnnullaClick() {
    this.setState({
      selectedCorrisp: null,
      disabled: false
    });
    try {
      this.headerRef.current.scrollIntoView();
    } catch(err){}
  }

  onCloseGridClick() {
    this.setState({
      selectedGrid: null,
      selectedCorrisp: null
    });
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true
    });

    const res = await CorrispondenzaAPI.GetCorrispondenzaEbinBy(this.state.firstRowToDisplay, this.state.rowsPerPage, {
      tipo: this.state.selectedTipoProt,
      anno: this.state.selectedAnno,
      prot: this.state.selectedProtocollo,
      data: this.state.selectedData ? moment(this.state.selectedData).format(dateFmt) : null,
      causale: this.state.selectedCausale,
      cfrichied: this.state.selectedCfRich,
      oggetto: this.state.selectedOggetto,
      sort: this.state.sortField,
      order: this.state.sortOrder === -1 ? "DESC" : "ASC"
    });

    this.setState({
      rows: res.result,
      totalRecords: res?.tot,
      loadingGrid: false
    });
  }

  handleTableClick = async (e) => {
    this.setState((prevState) => {
      return { ...prevState, loadingGrid: true };
    });
    try {
      const attachs = await CorrispondenzaAPI.GetCorrispondenzaEbinPathAttach(
        e.value.id
      );
      this.setState((prevState) => {
        return { ...prevState, protocol: e.value, attachments: attachs.result };
      });
    } catch (err) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Errore nel recupero degli allegati"
      );
    }
    this.setState((prevState) => {
      return { ...prevState, loadingGrid: false };
    });
  };

  handleChange(field, e) {
    this.setState({
      [field]: e.target.value
    });
  }

  async componentDidMount() {
    const causali = await CorrispondenzaAPI.GetCausaliEbinForCorrispondenza();
    this.setState({causali, name: "Entrata"}, async () => await this.refreshGrid());
  }

  downloadFiles() {
    if (this.state.selectedCorrisp?.id) {
      const response = CorrispondenzaAPI.GetExportedFiles(
        [this.state.selectedCorrisp.id],
        "Corrispondenza Protocollo"
      );
      response.then({});
    } else
      this.props.addAlert.current.addAlert(
        "warning",
        "Selezionare una corrispondenza prima"
      );
  }

  buildJson = (rowItem) => {
    return {
      utente: rowItem?.user?.username,
      azienda: rowItem?.azienda,
      codsap: rowItem?.codsap,
      tipo: this.state.name,
      prot: rowItem?.prot,
      anno: new Date().getFullYear(),
      data: rowItem?.data,
      causale: rowItem?.causale,
      note: rowItem?.note,
      dtlett: rowItem?.dtlett,
      mitt_dest: rowItem?.mitt_dest,
      oggetto: rowItem?.oggetto,
      mailtext: rowItem?.mailtext || "",
      id: rowItem?.id,
      prov: rowItem?.prov,
      numtab: isNaN(parseInt(rowItem?.numtab))
        ? null
        : parseInt(rowItem?.numtab),
      numfat: isNaN(parseInt(rowItem?.numfat))
        ? null
        : parseInt(rowItem?.numfat),
      numpreav: isNaN(parseInt(rowItem?.numpreav))
        ? null
        : parseInt(rowItem?.numpreav),
      w350: rowItem?.w350,
      w300: rowItem?.w300,
      amm: rowItem?.amm,
      ncontabbanc: isNaN(parseInt(rowItem?.ncontabbanc))
        ? null
        : parseInt(rowItem?.ncontabbanc),
      prbuchicont: rowItem?.prbuchicont,
      pathattach: rowItem?.pathattach,
      fldoppi: rowItem?.fldoppi,
      sede_inps: rowItem?.sede_inps || "",
      ninvio: rowItem?.ninvio,
      flamm: rowItem?.flamm,
      flaz: rowItem?.flaz,
      flcons: rowItem?.flcons,
      fldip: rowItem?.fldip,
      fldocele: rowItem?.fldocele,
      flmodo: rowItem?.flmodo,
      puli: rowItem?.puli,
      cust: rowItem?.cust,
      tipoinvio: rowItem?.tipoinvio,
      mittmail: rowItem?.mittmail?.toLowerCase(),
      destmail: rowItem?.destmail?.toLowerCase(),
      cfcons: rowItem?.cfcons,
      cfamm: rowItem?.cfamm,
      cfdip: rowItem?.cfdip,
      haAllegati: null,
      testomail: rowItem?.testomail || ""
    };
  };

  HandleSaveClick = async (RowItem, files, isEdit) => {
    const rowItem = RowItem;
    const senderId = rowItem?.user?.codice;
    let recipientEmails = [];
    rowItem?.administratorEmail &&
      recipientEmails.push(rowItem?.administratorEmail);
    rowItem?.consultantEmail && recipientEmails.push(rowItem?.consultantEmail);
    rowItem?.employeeEmail && recipientEmails.push(rowItem?.employeeEmail);
    rowItem?.sendCompanyEmail && recipientEmails.push(rowItem?.company?.email);
    rowItem?.recipientEmail && recipientEmails.push(rowItem?.recipientEmail);
    const object = rowItem?.oggetto;
    const data = this.buildJson(rowItem);

    if (!isEdit) {
      if (RowItem?.oggetto) {
        if (RowItem?.causale) {
          const res = await CorrispondenzaAPI.AddCorrispondenza(
            senderId,
            recipientEmails,
            object,
            data,
            files
          );
          if (res !== undefined && res.status === 200) {
            if (this.state.selectedTipo === 2) {
              this.props.addAlert.current.addAlert(
                "success",
                "Numero protocollo inserito: " + res?.data?.result[0]?.prot,
                86_400_000 // 24h.
              );
              this.setState({
                selectedCorrisp: null,
                selectedGrid: null
              });
              this.onNuovoClick();
            } else {
              if (this.state.name === "Entrata") {
                const resLastProtocol =
                  await CorrispondenzaAPI.GetLastInsertedProtocolEbin(
                    data?.anno,
                    this.state.name
                  );
                const protocol = resLastProtocol?.result[0];
                this.props.addAlert.current.addAlert(
                  "success",
                  `Numero protocollo inserito: ${protocol}`,
                  86_400_000 // 24h.
                );
                this.setState({
                  selectedCorrisp: null,
                  selectedGrid: null
                });
                await this.onNuovoClick();
              } else {
                const ris = new Blob([res.data]);
                let url = window.URL.createObjectURL(ris);
                let a = document.createElement("a");
                a.href = url;
                a.download = "lettera.pdf";
                a.click();
                a.remove();
                const resLastProtocol =
                  await CorrispondenzaAPI.GetLastInsertedProtocolEbin(
                    data?.anno,
                    this.state.name
                  );
                const protocol = resLastProtocol?.result[0];
                this.props.addAlert.current.addAlert(
                  "success",
                  `Numero protocollo inserito: ${protocol}`,
                  86_400_000 // 24h.
                );
                this.setState({
                  selectedCorrisp: null,
                  selectedGrid: null
                });
                this.onNuovoClick();
              }
            }
          } else
            this.props.addAlert.current.addAlert(
              "danger",
              "Errore nell'inserimento"
            );
        } else
          this.props.addAlert.current.addAlert(
            "warning",
            "Inserire la causale"
          );
      } else
        this.props.addAlert.current.addAlert("warning", "Inserire l'oggetto");
    } else {
        const res = await CorrispondenzaAPI.UpdateCorrispondenza(data, files);
        if (res) {
          this.props.addAlert.current.addAlert(
            "success",
            `Protocollo aggiornato correttamente`
          );
          this.setState({
            selectedCorrisp: null,
            selectedGrid: null
          });
          this.onNuovoClick();
        }
    }
    return true;
  };

  handleClearClick() {
    this.setState({
      Anno: new Date().getFullYear(),
      HeaderDate: null,
      selectedAnno: "",
      selectedProtocollo: "",
      selectedTipoProt: "",
      selectedData: "",
      selectedCausale: "",
      selectedCfRich: "",
      selectedOggetto: ""
    }, async () => await this.refreshGrid());
  }

  async handleSearchClick() {
    await this.refreshGrid();
  }

  async LoadElementsInGrid(newPage = 0, sortField, sortOrder) {
    let page = newPage;
    if (page === undefined || page === null) page = this.state.lastPage;

    helpers.SaveLastPage(page, "ProtocolliListPage");
    let responseObject = await CorrispondenzaAPI.GetCorrispondenzaEbinBy(
      page * 10,
      10,
      {
        sort: sortField,
        order: sortOrder === 1 ? "DESC" : "ASC"
      }
    );

    const totalRecords = parseInt(responseObject.tot / 10);
    this.setState({
      rows: responseObject.result,
      totalRecords,
      loadingGrid: false
    });

    if (newPage)
      this.setState({
        lastPage: newPage
      });
  }

  async onPageChange(e) {
    this.setState({
      currentPage: e.page,
      firstRowToDisplay: e.first,
      rowsPerPage: e.rows,
      loadingGrid: true
    }, async () => await this.refreshGrid());
  }

  async onSortChange(e) {
    this.setState(
      {
        sortField: e.sortField,
        sortOrder: e.sortOrder,
        loadingGrid: true
      },
      async () => await this.refreshGrid()
    );
  }

  async attachmentsHandleTableClick(rowData) {
    const _rowData = rowData?.value;
    const paths = _rowData?.path?.split(".");
    const extension = paths[paths.length - 1];
    if (extension === "pdf") {
      const _data = _rowData.path?.split(".");
      await CorrispondenzaAPI.openPdfEbin(_rowData?.id, _data[_data.length - 1]);
    } else {
      await CorrispondenzaAPI.downloadCorrespondenceAttachmentEbin(_rowData?.id, extension)
    }
  }

  render() {
    const dynamicDownloadColumns = this.state.downloadcolumns?.map((col) => {
      return (
        <Column
          key={col.body}
          body={col.body}
          icon={col.icon}
          label={col.label}
          className={col.className}
          header={col.header}
        />
      );
    });

    const protocol = (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="borseinfo">
              <h4
                style={{
                  lineHeight: "0",
                  display: "inline",
                  verticalAlign: "bottom"
                }}
              >
                Dettagli Protocollo
              </h4>
              <Tooltip
                arrow
                id="tooltip-top"
                title="Annulla"
                placement="top"
                classes={{ tooltip: "tooltip-me" }}
              >
                <Button
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%"
                  }}
                  color="white"
                  aria-label="edit"
                  justIcon
                  round
                  className="float-right"
                  onClick={() =>
                    this.setState((prevState) => {
                      return { ...prevState, protocol: null };
                    })
                  }
                >
                  <UndoIcon />
                </Button>
              </Tooltip>
              {this.props.children}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Anno"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.protocol?.anno
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Data Protocllo"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.protocol?.data
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Protocollo"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.protocol?.prot
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Tipo"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.protocol?.tipo
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="ID"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.protocol?.id
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Matricola"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.protocol?.azienda
                    }}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Provincia"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.protocol?.prov
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Sede INPS"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.protocol?.sede_inps
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Causale"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.protocol?.causale?.descrizione
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Tipo Invio"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.protocol?.tipoinvio?.tipoinvio
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Oggetto"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      disabled: true,
                      value: this.state.protocol?.oggetto
                    }}
                  />
                </GridItem>
              </GridContainer>
              {this.state.protocol?.tipo === 'USCITA' && this.state.protocol?.tipoinvio?.tipoinvio === 'EMAIL' && (
                <GridContainer style={{ marginBottom: "2vh", marginTop : "2vh" }}>
                  <GridItem xs={12} sm={12} md={6}>
                      <label htmlFor="testoMail">Testo Email</label>
                      <InputTextarea id="testoMail"
                        disabled={true}
                        autoResize
                        value={this.state.protocol?.testoMail || ""} 
                      />    
                  </GridItem>
                </GridContainer>
              )}
            </CardBody>
          </Card>
        </GridItem>
        <DataTable
          emptyMessage=""
          className="p-datatable-striped p-datatable-sm table"
          value={this.state.attachments}
          dataKey="id"
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
          rows={10}
          loading={this.state.loadingGrid}
          paginatorLeft={this.paginatorLeft}
          paginatorRight={this.paginatorRight}
          selection={this.state.selectedGrid}
          scrollable
          style={{ width: "100%" }}
          onSelectionChange={this.attachmentsHandleTableClick}
          selectionMode="single"
        >
          <Column
            field="path"
            header="Allegati"
            sortable={true}
          />
          {dynamicDownloadColumns}
        </DataTable>
      </GridContainer>
    );

    return (
      <>
        <div ref={this.headerRef} />
        {this.state.visibleLoader && <LoaderSpinner fullHeight={true} />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <h4>Ricerca Corrispondenza</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <InputText placeholder="Anno" value={this.state.selectedAnno} onChange={e => this.handleChange("selectedAnno", e)} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <InputText placeholder="Protocollo" value={this.state.selectedProtocollo} onChange={e => this.handleChange("selectedProtocollo", e)} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Dropdown placeholder="Tipo" options={["Entrata", "Uscita"]} value={this.state.selectedTipoProt} onChange={e => this.handleChange("selectedTipoProt", e)} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Calendar placeholder="Data" value={this.state.selectedData} onChange={e => this.handleChange("selectedData", e)} className="w-100" />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <Dropdown options={this.state.causali} placeholder="Causale" value={this.state.selectedCausale}
                              onChange={e => this.handleChange("selectedCausale", e)}
                              optionLabel={e => e.codice + " - " + e.descrizione} optionValue="codice"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer className="mt-4">
                  <GridItem xs={12} sm={12} md={3}>
                    <InputText placeholder="Codice Fiscale Richiedente" value={this.state.selectedCfRich} onChange={e => this.handleChange("selectedCfRich", e)} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <InputText placeholder="Protocollo Richiesta" />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <InputText placeholder="Oggetto" value={this.state.selectedOggetto} onChange={e => this.handleChange("selectedOggetto", e)} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Tooltip arrow id="tooltip-top" title="Cerca" placement="top">
                      <CustomButton
                        color="white" aria-label="edit" justIcon round
                        className="p-button-rounded p-button-primary p-button-sm float-left mt-0"
                        icon="pi pi-arrow-left"
                        onClick={this.handleSearchClick}
                      >
                        <Search />
                      </CustomButton>
                    </Tooltip>
                    <Tooltip arrow placement="top" style={{ marginLeft: "4px"}} id="tooltip-top" title="Reimposta">
                      <CustomButton
                        color="white" aria-label="edit" justIcon round
                        className="p-button-rounded p-button-primary p-button-sm float-left mt-0"
                        icon="pi pi-arrow-left"
                        onClick={this.handleClearClick}
                      >
                        <Clear />
                      </CustomButton>
                    </Tooltip>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borsesuccess">
                <h4>
                  Corrispondenza Generale relativa alle Richieste di Borsa di
                  Studio
                </h4>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  first={this.state.firstRowToDisplay}
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  onPage={this.onPageChange}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.protocol}
                  scrollable scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  lazy
                  onSort={this.onSortChange}
                  sortField={this.state.sortField}
                  sortOrder={this.state.sortOrder}
                  totalRecords={this.state.totalRecords}
                  selectionMode="single"
                >
                  <Column
                    field="anno"
                    header="Anno"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                  />
                  <Column
                    field="prot"
                    header="Prot."
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                  />
                  <Column
                    field="tipo"
                    header="Tipo"
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                  />
                  <Column
                    field="data"
                    header="Data"
                    body={(rowData) =>
                      helpers.DateTemplateWithHours(rowData, "data")
                    }
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                  />
                  <Column
                    field="causale"
                    body={(rowData) => rowData?.causale?.codice}
                    header="Causale"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                  />
                  <Column
                    field="cf"
                    body={(rowData) => rowData?.cfrichied}
                    header="C.F. Richiedente"
                    headerStyle={{ width: "170px" }}
                    sortable={true}
                  />
                  <Column
                    field="mitt_dest"
                    header="Mitt. / Dest."
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                  />
                  <Column
                    field="protRichies"
                    header="Protocollo Richiesta"
                    headerStyle={{ width: "210px" }}
                    sortable={true}
                  />
                  <Column
                    field="oggetto"
                    header="Oggetto"
                    headerStyle={{ width: "400px" }}
                    sortable={true}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.protocol && protocol}
      </>
    );
  }
}

export default Protocollo;
