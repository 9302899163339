import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import Switch from "@material-ui/core/Switch";
import CustomButtons from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { InputText } from "primereact/inputtext";
import Helpers from "helpers/Helpers.js";
import StaticLists from "helpers/StaticLists";
import ScarichiAPI from "API/ScarichiAPI";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import { confirmPopup } from "primereact/confirmpopup";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "moment/locale/it";
import moment from "moment";
import { dateTimeFmt, dateFmt, rowsPerPageOptions } from "helpers/AppConstants";
import BancheAPI from "../../API/BancheAPI";
import Utils from "../../API/Utils";

moment.locale("it");

class Scarichi extends React.Component {
  state = {
    yearfilter: new Date().getFullYear(),
    rows: [],
    rowsannoxcod: [],
    viewAnnoXCodice: false,
    viewAnnoCodice: false,
    loadingGrid: false,
    loadingGridAnnoXCodice: false,
    loadingGridAnnoXCodiceDetails: false,
    loadingPagamenti: false,
    selectedGrid: null,
    type: null,
    selectedGridAnnoXCodice: null,
    selectedGridAnnoXCodiceDetails: null,
    Codice: [],
    viewDetails: false,
    detailsrows: [],
    filterLabelTextAnnoXCodice: "Attiva Filtri",
    viewPagamentiSpese: false,
    pagamentiRows: [],
    speseRows: [],
    DocRows: [],
    inputAnnoDefaultValue: parseInt(new Date().getFullYear()),
    prog: null,
    chiuso: null,
    listaCreditori: [
      { id: 1, name: "SISPI" },
      { id: 2, name: "INPS" }
    ],
    listaBanche: null
  };

  constructor(props) {
    super(props);

    //#region Props
    this.originalRows = {};
    this.originalSpeseRows = {};
    this.ChangeFilterGridAnnoXCodice =
      this.ChangeFilterGridAnnoXCodice.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleTableClickAnnoXCodice =
      this.handleTableClickAnnoXCodice.bind(this);
    this.handleTableClickAnnoXCodiceDetails =
      this.handleTableClickAnnoXCodiceDetails.bind(this);
    this.bancaEditor = this.bancaEditor.bind(this);
    this.onRowEditCancel = this.onRowEditCancel.bind(this);
    this.onRowEditInit = this.onRowEditInit.bind(this);
    this.onSpeseEditInit = this.onSpeseEditInit.bind(this);
    this.onRowEditSave = this.onRowEditSave.bind(this);
    this.rowEditorValidator = this.rowEditorValidator.bind(this);
    this.onSpeseEditSave = this.onSpeseEditSave.bind(this);
    this.speseEditorValidator = this.speseEditorValidator.bind(this);
    this.onSpeseEditCancel = this.onSpeseEditCancel.bind(this);
    this.inputTextEditor = this.inputTextEditor.bind(this);
    this.onEditorValueChange = this.onEditorValueChange.bind(this);
    this.importoEditor = this.importoEditor.bind(this);
    this.dataPagEditor = this.dataPagEditor.bind(this);
    this.annoEditor = this.annoEditor.bind(this);
    this.codiceEditor = this.codiceEditor.bind(this);

    this.btnSearchRef = React.createRef();

    this.onNuovoPagamentiClick = this.onNuovoPagamentiClick.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.inputAnnoEditor = this.inputAnnoEditor.bind(this);
    this.inputFullAnnoEditor = this.inputFullAnnoEditor.bind(this);
    this.inputCodiceEditor = this.inputCodiceEditor.bind(this);
    this.onNuovoSpeseClick = this.onNuovoSpeseClick.bind(this);
    this.creditoreEditor = this.creditoreEditor.bind(this);
    this.datafatEditor = this.datafatEditor.bind(this);
    this.codiEditor = this.codiEditor.bind(this);
    this.progEditor = this.progEditor.bind(this);
    this.gridPagamentiRef = React.createRef();
    this.templateCheckboxGrid = this.templateCheckboxGrid.bind(this);
    this.gridSpeseRef = React.createRef();
    this.ConvertField = this.ConvertField.bind(this);
    this.inputNumberEditor = this.inputNumberEditor.bind(this);
    this.Delete = this.Delete.bind(this);
    this.DeleteSpese = this.DeleteSpese.bind(this);
    this.onEditorDateValueChange = this.onEditorDateValueChange.bind(this);
    this.popupHandler = this.popupHandler.bind(this);
    this.onCloseExhaust = this.onCloseExhaust.bind(this);
  }

  async onCloseExhaust(rowData, state) {
    this.setState({ loadingGridAnnoXCodiceDetails: true });
    if (
      await ScarichiAPI.ChangeGetScarichiF24AnnoXCodiceDettaglioChiuso(
        rowData.id,
        state
      )
    ) {
      this.setState({
        DocRows:
          this.state.type !== null
            ? await ScarichiAPI.GetScarichiF24AnnoXCodiceDettaglio(
                null,
                this.state.yearfilter,
                this.state.type
              )
            : null
      });
    }
    this.setState({ loadingGridAnnoXCodiceDetails: false });
  }

  popupHandler(event, state, rowData) {
    event.preventDefault();
    event.stopPropagation();
    confirmPopup({
      target: event.originalEvent.currentTarget,
      message: state
        ? "Vuoi riaprire questo scarico?"
        : "Vuoi chiudere questo scarico?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => this.onCloseExhaust(rowData, !state),
      reject: () => {}
    });
  }

  handleTableClickAnnoXCodiceDetails(e) {
    this.setState(
      {
        selectedGridAnnoXCodiceDetails: e.value,
        viewPagamentiSpese: true
      },
      async () => await this.refreshGrid()
    );
  }

  templateCheckboxGrid(rowData) {
    return (
      <div>
        <Checkbox
          inputId="chiuso"
          checked={rowData.chius === "SI" || rowData?.chius}
          // onChange={async (e) => {
          //   e.stopPropagation();
          //   this.setState({ loadingGridAnnoXCodiceDetails: true });
          //   if (
          //     await ScarichiAPI.ChangeGetScarichiF24AnnoXCodiceDettaglioChiuso(
          //       rowData.id,
          //       e.checked
          //     )
          //   ) {
          //     var list = this.state.DocRows;
          //     var el = list.find((x) => x.id === rowData.id);
          //     el.chius = e.checked;

          //     this.setState({ DocRows: list });
          //   }
          //   this.setState({ loadingGridAnnoXCodiceDetails: false });
          // }}
          onChange={(event) =>
            this.popupHandler(event, rowData.chius === "SI", rowData)
          }
        />
      </div>
    );
  }

  onAnnullaClick() {
    this.setState({
      selectedGridAnnoXCodiceDetails: null,
      viewDetails: false
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  //#region  Editor
  inputAnnoEditor(productKey, props, field) {
    return (
      <InputNumber
        max={9999}
        value={props.rowData[field]}
        useGrouping={false}
        onChange={(e) =>
          this.onEditorValueChange(productKey, props, e.value, "anno")
        }
      />
    );
  }

  inputNumberEditor(productKey, props, field) {
    return (
      <InputText
        ref={this.speseRef}
        type="number"
        value={props.rowData[field]}
        onChange={(e) =>
          this.onEditorValueChange(productKey, props, e.target.value, "")
        }
      />
    );
  }

  inputFullAnnoEditor(productKey, props, field) {
    return (
      <KeyboardDatePicker
        className="ctrlMuiToPF"
        clearable
        value={props.rowData[field]}
        placeholder="gg/MM/aaaa"
        onChange={(e) => this.onEditorDateValueChange(productKey, props, e)}
        format="DD/MM/YYYY"
      />
    );
  }

  inputCodiceEditor(productKey, props, field) {
    return (
      <Dropdown
        options={this.state.Codice}
        optionValue="name"
        style={{ width: "100%" }}
        optionLabel="name"
        type="text"
        value={props.rowData[field]}
        onChange={(e) =>
          this.onEditorValueChange(productKey, props, e.value, "")
        }
        itemTemplate={(option) => {
          return <span>{option.name}</span>;
        }}
      />
    );
  }

  inputTextEditor(productKey, props, field) {
    return (
      <InputText
        type="text"
        value={props.rowData[field]}
        onChange={(e) =>
          this.onEditorValueChange(productKey, props, e.target.value, "")
        }
      />
    );
  }

  onEditorDateValueChange(productKey, props, value) {
    if (value === null || value === "")
      this.onEditorValueChange(productKey, props, value);
    else this.onEditorValueChange(productKey, props, value, "fullanno");
  }

  onEditorValueChange(productKey, props, value, type) {
    if (type === "anno") {
      let updatededitRows = [...props.value];

      value = value / 10000 >= 1 ? parseInt(value / 10) : value;
      updatededitRows[props.rowIndex][props.field] = value;
      this.setState({ [`${productKey}`]: updatededitRows });
    } else if (type === "fullanno") {
      let updatededitRows = [...props.value];
      let val = value.format("MM/DD/YYYY");
      value = val;
      updatededitRows[props.rowIndex][props.field] = value;
      this.setState({ [`${productKey}`]: updatededitRows });
    } else {
      let updatededitRows = [...props.value];
      updatededitRows[props.rowIndex][props.field] = value;
      this.setState({ [`${productKey}`]: updatededitRows });
    }
  }

  //#region Pagamenti
  bancaEditor(productKey, props) {
    return (
      <Dropdown
        ref={this.pagamentiRef}
        value={props.rowData["banca"]}
        optionValue="cod_banca"
        optionLabel="cod_banca"
        className="dropdownCustomisedForMUI"
        // options={props.rowData['creditore']}
        options={this.state.listaBanche}
        onChange={(e) =>
          this.onEditorValueChange(productKey, props, e.value, "banca")
        }
        filter
        filterBy="name"
        resetFilterOnHide
      />
    );
  }

  importoEditor(productKey, props) {
    return this.inputNumberEditor(productKey, props, "importo");
  }

  dataPagEditor(productKey, props) {
    return this.inputFullAnnoEditor(productKey, props, "datapag");
  }

  annoEditor(productKey, props) {
    return this.inputAnnoEditor(productKey, props, "anno");
  }

  codiceEditor(productKey, props) {
    return this.inputCodiceEditor(productKey, props, "codi");
  }

  progEditor(productKey, props) {
    return this.inputNumberEditor(productKey, props, "prog");
  }

  onRowEditInit(event) {
    this.originalRows[event.index] = {
      ...this.state.pagamentiRows[event.index]
    };
  }

  async onRowEditSave(event) {
    if (event.valid) {
      let obj = {
        id: event.data.id === 0 ? null : event.data.id,
        anno: event.data.anno,
        cod_quota: event.data.codi,
        importo: Number.parseFloat(event.data.importo).toFixed(2),
        datapag: event.data.datapag,
        banca: event.data.banca,
        progrscar: event.data.prog
      };
      let appoggio = obj.datapag.split("/");
      obj.datapag =
        appoggio[1] + "/" + appoggio[0] + "/" + appoggio[2] + " 00:00:00";

      obj.id === null
        ? await ScarichiAPI.cassaF24Insert(obj)
        : await ScarichiAPI.cassaF24Update(obj);
      await this.refreshGrid();
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "Compilare tutti i campi!"
      );
    }
  }

  rowEditorValidator(event) {
    return Utils.CheckAllFieldNotEmpty(event);
  }

  async onSpeseEditSave(event) {
    if (event.valid) {
      let obj = {
        id: event.data.id === 0 ? null : event.data.id,
        anno: event.data.anno,
        cod_quota: event.data.codi,
        importo: event.data.importo,
        data_fattura: event.data.datafat,
        creditore: event.data.creditore,
        progrscar: event.data.prog
      };
      let appoggio = obj.data_fattura.split("/");
      obj.data_fattura =
        appoggio[1] + "/" + appoggio[0] + "/" + appoggio[2] + " 00:00:00";

      obj.id === null
        ? await ScarichiAPI.speseF24Insert(obj)
        : await ScarichiAPI.speseF24Update(obj);

      await this.refreshGrid();
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "Compilare tutti i campi!"
      );
    }
  }

  speseEditorValidator(event) {
    return Utils.CheckAllFieldNotEmpty(event);
  }

  onRowEditCancel(event) {
    let editRows = [...this.state.pagamentiRows];
    editRows[event.index] = this.originalRows[event.index];
    delete this.originalRows[event.index];

    if (!(editRows[0].id > 0)) editRows.shift();

    this.setState({ pagamentiRows: editRows });
  }

  onSpeseEditInit(event) {
    this.originalSpeseRows[event.index] = {
      ...this.state.speseRows[event.index]
    };
  }

  onSpeseEditCancel(event) {
    let editRows = [...this.state.speseRows];
    editRows[event.index] = this.originalSpeseRows[event.index];
    delete this.originalSpeseRows[event.index];

    if (!(editRows[0].id > 0)) editRows.shift();

    this.setState({ speseRows: editRows });
  }

  creditoreEditor(productKey, props) {
    return (
      <Dropdown
        value={props.rowData["creditore"]}
        optionValue="name"
        optionLabel="name"
        className="dropdownCustomisedForMUI"
        options={this.state.listaCreditori}
        onChange={(e) =>
          this.onEditorValueChange(productKey, props, e.value, "creditore")
        }
        filter
        filterBy="name"
        resetFilterOnHide
      />
    );
  }

  datafatEditor(productKey, props) {
    return this.inputFullAnnoEditor(productKey, props, "datafat");
  }

  codiEditor(productKey, props) {
    return this.inputCodiceEditor(productKey, props, "codi");
  }

  async onNuovoSpeseClick() {
    Helpers.CloseAllEditRows(this.gridSpeseRef);
    var speseRows = this.state.speseRows;
    var newLine = await ScarichiAPI.GetEmptyScarichiF24Spese(
      this.state.selectedGridAnnoXCodiceDetails.codi || "",
      this.state.selectedGridAnnoXCodiceDetails.anno || "",
      this.state.selectedGridAnnoXCodiceDetails.progr || ""
    );
    speseRows.splice(0, 0, newLine);
    this.setState({ speseRows: speseRows }, function () {
      Helpers.OpenEditFirstRow(this.gridSpeseRef);
    });
  }

  async onNuovoPagamentiClick() {
    Helpers.CloseAllEditRows(this.gridPagamentiRef);
    var pagamentiRows = this.state.pagamentiRows;
    var newLine = await ScarichiAPI.GetEmptyScarichiF24Pagamento(
      this.state.selectedGridAnnoXCodiceDetails.codi || "",
      this.state.selectedGridAnnoXCodiceDetails.anno || "",
      this.state.selectedGridAnnoXCodiceDetails.progr || ""
    );

    newLine.banca = this.getDefaultBanca(newLine.codi);

    pagamentiRows.splice(0, 0, newLine);

    this.setState({ pagamentiRows: pagamentiRows }, function () {
      Helpers.OpenEditFirstRow(this.gridPagamentiRef);
    });
  }
  async ChangeFilterGridAnnoXCodice(e) {
    this.setState({ filterGridAnnoXCodice: e.target.checked });
  }

  ChangeFilterGrid(e) {
    this.setState({
      filterGrid: e.target.checked
    });
  }

  async handleTableClickAnnoXCodice(e) {
    let t;
    if (e.value !== null) {
      t = e.value.codi;
      if (e.value.codi === "PULI") {
        t = "pulitori";
      } else if (e.value.codi === "CUST") {
        t = "custodi";
      }
    }
    this.setState({
      selectedGridAnnoXCodiceDetails: null,
      selectedGridAnnoXCodice: e.value,
      viewDetails: true,
      viewPagamentiSpese: false,
      type: e.value.codi,
      t
    });

    await this.refreshGrid();
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  ConvertField(field) {
    var convert;
    if (field !== null) {
      convert =
        Helpers.DecimalInputTemplate(field).props.children[0] +
        Helpers.DecimalInputTemplate(field).props.children[1];
    }
    field = convert;
    return field;
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true,
      loadingGridAnnoXCodice: true,
      loadingGridAnnoXCodiceDetails: true,
      loadingPagamenti: true,
      loadingSpese: true
    });
    let rw = await ScarichiAPI.GetTotScarichiF24();

    let scarichiannoxcod = await ScarichiAPI.GetScarichiF24AnnoXCodice(
      null,
      this.state.yearfilter
    );
    let dettagli = null;
    if (this.state.type !== null) {
      dettagli = await ScarichiAPI.GetScarichiF24AnnoXCodiceDettaglio(
        null,
        this.state.yearfilter,
        this.state.type
      );
    }
    //var dettagli = await ScarichiAPI.GetScarichiF24AnnoXCodiceDettaglio(null, this.state.yearfilter, this.state.type);

    let pagrws = null,
      spsrws = null,
      banche = null;

    if (this.state.selectedGridAnnoXCodiceDetails?.progr !== undefined) {
      pagrws = await ScarichiAPI.GetScarichiF24Pagamenti(
        this.state.selectedGridAnnoXCodiceDetails?.progr,
        this.state.yearfilter,
        this.state.type
      );
      spsrws = await ScarichiAPI.GetScarichiF24Spese(
        this.state.selectedGridAnnoXCodiceDetails?.progr,
        this.state.yearfilter,
        this.state.type
      );
      banche = await BancheAPI.GetAll();
    }
    this.setState({
      rowsannoxcod: scarichiannoxcod,
      DocRows:
        this.state.type !== null
          ? await ScarichiAPI.GetScarichiF24AnnoXCodiceDettaglio(
              null,
              this.state.yearfilter,
              this.state.type
            )
          : null,
      rows: rw,
      detailsrows: dettagli,
      pagamentiRows: pagrws,
      speseRows: spsrws,
      Codice: StaticLists.CodiceQuota(),
      chiuso: this.state.selectedGridAnnoXCodiceDetails?.chius,
      listaBanche: banche
    });

    this.setState({
      loadingGrid: false,
      loadingGridAnnoXCodice: false,
      loadingGridAnnoXCodiceDetails: false,
      loadingSpese: false,
      loadingPagamenti: false
    });
  }

  async handleTableClick(e) {
    this.setState({
      selectedGrid: e.value,
      selectedGridAnnoXCodice: null,
      viewPagamentiSpese: false,
      viewDetails: false,
      yearfilter: e.value.anno,
      viewAnnoCodice: true
    });

    await this.refreshGrid();
  }

  getDefaultBanca(codiceQuota) {
    for (let banca of this.state.listaBanche)
      if (banca.descrizione === codiceQuota) return banca.cod_banca;
  }

  Delete(rowData) {
    if (rowData.id !== 0) {
      return (
        <Button
          round
          color="danger"
          className="actionButton actionButtonRound btnRound float-right"
          key={rowData.id}
          onClick={(e) => this.onDeleteClick(e, rowData.id, "cassa")}
        >
          <Close className="icon" />
        </Button>
      );
    } else {
      return <label></label>;
    }
  }

  DeleteSpese(rowData) {
    if (rowData.id !== 0) {
      return (
        <Button
          round
          color="danger"
          className="actionButton actionButtonRound btnRound float-right"
          key={rowData.id}
          onClick={(e) => this.onDeleteClick(e, rowData.id, "spese")}
        >
          <Close className="icon" />
        </Button>
      );
    } else {
      return <label></label>;
    }
  }

  onDeleteClick(event, id, tipo) {
    event.stopPropagation();

    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => this.DeleteRecord(id, tipo),
      reject: () => {}
    });
  }

  async DeleteRecord(id, tipo) {
    let response;
    if (tipo === "cassa") {
      response = await ScarichiAPI.deleteCassa(id);
    } else if (tipo === "spese") {
      response = await ScarichiAPI.deleteSpese(id);
    }

    if (response.result === null) {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("warning", response.message);
      }, 1200);
    } else {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("success", "Dati eliminati.");
      }, 1200);
    }

    this.setState({
      loadingGrid: true,
      selectedConsulente: null
    });

    await this.refreshGrid();
  }

  render() {
    return (
      <>
        {" "}
        <div ref={this.btnSearchRef} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Scarichi
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={this.state.filterLabelTextAnnoXCodice}
                />
              </CardHeader>

              <CardBody>
                <DataTable
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="anno"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10}
                  rowsPerPageOptions={rowsPerPageOptions}
                  emptyMessage=""
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  loading={this.state.loadingGrid}
                  scrollable
                  scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="anno"
                    header="Anno"
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="impMese1"
                    header="Imp. M. 1"
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "impMese1")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "120px" }}
                  />
                  <Column
                    field="righeM1"
                    header="Righe M. 1"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "120px" }}
                  />
                  <Column
                    field="impMese2"
                    header="Imp. M. 2"
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "impMese2")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "120px" }}
                  />
                  <Column
                    field="righeM2"
                    header="Righe M. 2"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "120px" }}
                  />
                  <Column
                    field="righeTot"
                    header="Righe Tot."
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "120px" }}
                  />
                  <Column
                    field="impTot"
                    header="Imp. Tot. "
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "impTot")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "120px" }}
                  />
                  <Column
                    field="pagato"
                    header="Pagato"
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "pagato")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "120px" }}
                  />
                  <Column
                    field="totspese"
                    header="Spese"
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "totspese")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "120px" }}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.viewAnnoCodice && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="ebinprofsuccess">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    Scarichi F24 AnnoXCodice
                  </h4>

                  <FormControlLabel
                    className="float-right"
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%",
                      color: "white"
                    }}
                    control={
                      <Switch
                        checked={this.state.filterGridAnnoXCodice}
                        onChange={this.ChangeFilterGridAnnoXCodice}
                        name="chkFilterGrid"
                        color="default"
                      />
                    }
                    label={this.state.filterLabelTextAnnoXCodice}
                  />
                </CardHeader>

                <CardBody>
                  <DataTable
                    className="p-datatable-striped p-datatable-sm"
                    value={this.state.rowsannoxcod}
                    dataKey="key"
                    paginator
                    emptyMessage=""
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                    rows={10}
                    paginatorLeft={this.paginatorLeft}
                    paginatorRight={this.paginatorRight}
                    selection={this.state.selectedGridAnnoXCodice}
                    loading={this.state.loadingGridAnnoXCodice}
                    scrollable
                    style={{ width: "100%" }}
                    onSelectionChange={this.handleTableClickAnnoXCodice}
                    selectionMode="single"
                  >
                    <Column
                      field="anno"
                      header="Anno"
                      headerStyle={{ width: "90px" }}
                      sortable={true}
                      filter={this.state.filterGridAnnoXCodice}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="codi"
                      header="Cod. Quota"
                      sortable={true}
                      filter={this.state.filterGridAnnoXCodice}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                      headerStyle={{ width: "130px" }}
                    />
                    <Column
                      field="totlm1"
                      header="Tot. Imp. M. 1"
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "totlm1")
                      }
                      sortable={true}
                      filter={this.state.filterGridAnnoXCodice}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                      headerStyle={{ width: "150px" }}
                    />
                    <Column
                      field="totrm"
                      header="Tot. Rig. M. 1"
                      sortable={true}
                      filter={this.state.filterGridAnnoXCodice}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                      headerStyle={{ width: "150px" }}
                    />
                    <Column
                      field="totlm2"
                      header="Tot. I. M. 2"
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "totlm2")
                      }
                      sortable={true}
                      filter={this.state.filterGridAnnoXCodice}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                      headerStyle={{ width: "150px" }}
                    />
                    <Column
                      field="totrm2"
                      header="Tot. Rig. M. 2"
                      sortable={true}
                      filter={this.state.filterGridAnnoXCodice}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                      headerStyle={{ width: "150px" }}
                    />
                    <Column
                      field="totr"
                      header="Tot. Righe"
                      sortable={true}
                      filter={this.state.filterGridAnnoXCodice}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                      headerStyle={{ width: "130px" }}
                    />
                    <Column
                      field="totl"
                      header="Tot. Imp."
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "totl")
                      }
                      sortable={true}
                      filter={this.state.filterGridAnnoXCodice}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                      headerStyle={{ width: "130px" }}
                    />
                    <Column
                      field="accr"
                      header="Accr."
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "accr")
                      }
                      sortable={true}
                      filter={this.state.filterGridAnnoXCodice}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                      headerStyle={{ width: "130px" }}
                    />
                    <Column
                      field="spese"
                      header="Spese"
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "spese")
                      }
                      sortable={true}
                      filter={this.state.filterGridAnnoXCodice}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                      headerStyle={{ width: "130px" }}
                    />
                  </DataTable>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
        {this.state.viewDetails && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="ebinprofsuccess">
                  <h4
                    style={{
                      lineHeight: "1",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    Scarichi F24 AnnoXCodice - Dettaglio
                  </h4>
                </CardHeader>

                <CardBody>
                  <DataTable
                    className="p-datatable-striped p-datatable-sm"
                    value={this.state.DocRows}
                    dataKey="id"
                    selection={this.state.selectedGridAnnoXCodiceDetails}
                    onSelectionChange={this.handleTableClickAnnoXCodiceDetails}
                    selectionMode="single"
                    emptyMessage=""
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                    rows={12}
                    loading={this.state.loadingGridAnnoXCodiceDetails}
                    paginatorLeft={this.paginatorLeft}
                    paginatorRight={this.paginatorRight}
                    scrollable
                    style={{ width: "100%" }}
                  >
                    <Column
                      field="anno"
                      header="Anno"
                      headerStyle={{ width: "90px" }}
                      sortable={true}
                    />
                    <Column
                      field="codi"
                      header="Cod. Quota"
                      headerStyle={{ width: "130px" }}
                      sortable={true}
                    />
                    <Column
                      field="progr"
                      header="Prog."
                      headerStyle={{ width: "90px" }}
                      sortable={true}
                    />
                    <Column
                      field="datain"
                      header="Inizio"
                      headerStyle={{ width: "90px" }}
                      body={(row) =>
                        moment(row.datain, dateTimeFmt).format(dateFmt)
                      }
                      sortable={true}
                    />
                    <Column
                      field="datafn"
                      header="Fine"
                      headerStyle={{ width: "90px" }}
                      body={(row) =>
                        moment(row.datafn, dateTimeFmt).format(dateFmt)
                      }
                      sortable={true}
                    />
                    <Column
                      field="totlm1"
                      header="Tot. Imp. M. 1"
                      headerStyle={{ width: "140px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "totlm1")
                      }
                      sortable={true}
                    />
                    <Column
                      field="totrm"
                      header="Tot. Rig. M. 1"
                      headerStyle={{ width: "140px" }}
                      sortable={true}
                    />
                    <Column
                      field="totlm2"
                      header="Tot. Imp. M. 2"
                      headerStyle={{ width: "140px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "totlm2")
                      }
                      sortable={true}
                    />
                    <Column
                      field="totrm2"
                      header="Tot. Rig. M. 2"
                      headerStyle={{ width: "140px" }}
                      sortable={true}
                    />
                    <Column
                      field="totr"
                      header="Tot. Rig."
                      headerStyle={{ width: "105px" }}
                      sortable={true}
                    />
                    <Column
                      field="totl"
                      header="Tot. Imp."
                      headerStyle={{ width: "150px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "totl")
                      }
                      sortable={true}
                    />
                    <Column
                      field="accr"
                      header="Accr."
                      headerStyle={{ width: "150px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "accr")
                      }
                      sortable={true}
                    />
                    <Column
                      field="spese"
                      header="Spese"
                      headerStyle={{ width: "130px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "spese")
                      }
                      sortable={true}
                    />
                    <Column
                      header="Chiuso"
                      headerStyle={{ width: "90px" }}
                      body={this.templateCheckboxGrid}
                    />
                  </DataTable>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
        {this.state.viewPagamentiSpese && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="ebinprofinfo">
                  <h4
                    style={{
                      lineHeight: "2",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    Pagamenti
                  </h4>

                  {this.state.chiuso === "NO" && (
                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Inserisci"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <CustomButtons
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="float-right"
                        onClick={this.onNuovoPagamentiClick}
                      >
                        <AddIcon />
                      </CustomButtons>
                    </Tooltip>
                  )}
                </CardHeader>

                <CardBody style={{ minHeight: "538px" }}>
                  <DataTable
                    emptyMessage=""
                    scrollHeight={"470px"}
                    ref={this.gridPagamentiRef}
                    className="p-datatable-striped p-datatable-sm abcdef"
                    value={this.state.pagamentiRows}
                    dataKey="id"
                    loading={this.state.loadingPagamenti}
                    onRowEditInit={this.onRowEditInit}
                    onRowEditCancel={this.onRowEditCancel}
                    onRowEditSave={this.onRowEditSave}
                    rowEditorValidator={this.rowEditorValidator}
                    editMode="row"
                    scrollable
                    style={{ width: "100%" }}
                    selectionMode="single"
                  >
                    {this.state.chiuso === "NO" && (
                      <Column
                        rowEditor
                        headerStyle={{ width: "30px" }}
                        bodyStyle={{ textAlign: "center" }}
                      />
                    )}
                    <Column
                      field="banca"
                      header="Banca"
                      headerStyle={{ width: "65px" }}
                      editor={(props) =>
                        this.bancaEditor("pagamentiRows", props)
                      }
                    />
                    <Column
                      field="importo"
                      header="Importo"
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "importo")
                      }
                      headerStyle={{ width: "100px" }}
                      editor={(props) =>
                        this.importoEditor("pagamentiRows", props)
                      }
                    />
                    <Column
                      field="datapag"
                      header="DataPag"
                      headerStyle={{ width: "100px" }}
                      body={(row) =>
                        moment(row.datapag, dateTimeFmt).format(dateFmt)
                      }
                      editor={(props) =>
                        this.dataPagEditor("pagamentiRows", props)
                      }
                    />
                    <Column
                      field="codi"
                      header="Cod"
                      headerStyle={{ width: "60px" }}
                      bodyStyle={{ paddingTop: "0px" }}
                      editor={(props) =>
                        this.codiceEditor("pagamentiRows", props)
                      }
                    />
                    <Column
                      field="anno"
                      header="Anno"
                      headerStyle={{ width: "60px" }}
                      editor={(props) =>
                        this.annoEditor("pagamentiRows", props)
                      }
                    />
                    <Column
                      field="prog"
                      header="Prog"
                      headerStyle={{ width: "60px" }}
                      editor={(props) =>
                        this.progEditor("pagamentiRows", props)
                      }
                    />
                    {this.state.chiuso === "NO" && (
                      <Column
                        header=""
                        headerStyle={{ width: "40px" }}
                        body={this.Delete}
                        icon="pi pi-times"
                        label="Delete"
                        className="p-button-danger p-button-outlined colDelete"
                      />
                    )}
                  </DataTable>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="ebinprofinfo">
                  <h4
                    style={{
                      lineHeight: "2",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    Spese
                  </h4>
                  {this.state.chiuso === "NO" && (
                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Inserisci"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <CustomButtons
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="float-right"
                        onClick={this.onNuovoSpeseClick}
                      >
                        <AddIcon />
                      </CustomButtons>
                    </Tooltip>
                  )}
                </CardHeader>

                <CardBody style={{ minHeight: "538px" }}>
                  <DataTable
                    ref={this.gridSpeseRef}
                    scrollHeight={"470px"}
                    className="p-datatable-striped p-datatable-sm abcdef"
                    value={this.state.speseRows}
                    dataKey="id"
                    loading={this.state.loadingSpese}
                    onRowEditInit={this.onSpeseEditInit}
                    onRowEditCancel={this.onSpeseEditCancel}
                    onRowEditSave={this.onSpeseEditSave}
                    rowEditorValidator={this.speseEditorValidator}
                    editMode="row"
                    scrollable
                    style={{ width: "100%" }}
                    selectionMode="single"
                  >
                    {this.state.chiuso === "NO" && (
                      <Column
                        rowEditor
                        headerStyle={{ width: "30px" }}
                        bodyStyle={{ textAlign: "center" }}
                      />
                    )}

                    <Column
                      field="importo"
                      header="Importo"
                      headerStyle={{ width: "100px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "importo")
                      }
                      editor={(props) => this.importoEditor("speseRows", props)}
                    />
                    <Column
                      field="creditore"
                      header="Creditore"
                      headerStyle={{ width: "60px" }}
                      editor={(props) =>
                        this.creditoreEditor("speseRows", props)
                      }
                    />
                    <Column
                      field="datafat"
                      header="DataFat"
                      headerStyle={{ width: "100px" }}
                      body={(row) =>
                        moment(row.datafat, dateTimeFmt).format(dateFmt)
                      }
                      editor={(props) => this.datafatEditor("speseRows", props)}
                    />
                    <Column
                      field="codi"
                      header="Cod"
                      editor={(props) => this.codiEditor("speseRows", props)}
                      headerStyle={{ width: "50px" }}
                    />
                    <Column
                      field="anno"
                      header="Anno"
                      editor={(props) => this.annoEditor("speseRows", props)}
                      headerStyle={{ width: "60px" }}
                    />
                    <Column
                      field="prog"
                      header="Prog"
                      editor={(props) => this.progEditor("speseRows", props)}
                      headerStyle={{ width: "40px" }}
                    />
                    {this.state.chiuso === "NO" && (
                      <Column
                        header=""
                        headerStyle={{ width: "40px" }}
                        body={this.DeleteSpese}
                        icon="pi pi-times"
                        label="Delete"
                        className="p-button-danger p-button-outlined colDelete"
                      />
                    )}
                  </DataTable>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </>
    );
  }
}

export default Scarichi;
