import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import ModificaChiusuraTabulati from "components/Views/Versamenti/ModificaChiusuraTabulati.js";
import LoaderSpinner from "components/Layout/LoaderSpinner.js";
import Accordion from "components/Accordion/Accordion.js";
import Tooltip from "@material-ui/core/Tooltip";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import Button from "components/CustomButtons/Button.js";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import StaticLists from "helpers/StaticLists";
import TabulatiAPI from "API/TabulatiAPI";
import SedeComuneProvAPI from "API/SedeComuneProvAPI";
import YearPicker from "components/YearPicker/index";
import { dateTimeFmt, dateFmt } from "helpers/AppConstants";
class ChiusuraTabulati extends React.Component {
  state = {
    rows: [],
    loadingGrid: false,
    selectedCodice: null,
    selectedGrid: null,
    arrayId: null,
    selectedSede: null,
    Sede: [],
    CodiceQuota: [],
    isVisible: false,
    visibleLoader: false,
    Tabulati: [],
    prendiAnno: new Date(),
    anno: null,

    showAlert: false,
    alertMessage: null
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.handleTabulatoChange = this.handleTabulatoChange.bind(this);
    this.btnSearchRef = React.createRef();
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  async componentDidMount() {
    let anno = this.state.prendiAnno.getFullYear();
    this.setState({ anno: anno });
    var allSedi = await SedeComuneProvAPI.GetAllSede();
    await this.refreshGrid();

    this.setState({
      CodiceQuota: await StaticLists.CodiceQuota(),
      Sede: allSedi.map((x) => {
        return { sap: x.sap, descrizione: x.sap + " - " + x.descrizione };
      })
    });
  }

  onAnnullaClick() {
    this.setState({
      isVisible: false
    });
  }

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  handleSearchClick = async () => {
    this.setState({
      selectedGrid: null,
      loadingGrid: true
    });
    if (
      this.state.selectedCodice !== null &&
      this.state.selectedSede !== null &&
      this.state.anno !== null
    ) {
      var rw = await TabulatiAPI.GetTabulatiPerCodiceSedeAnnoDaChiusura(
        this.state.selectedCodice.name,
        this.state.selectedSede,
        this.state.anno
      );
      if (!rw || rw.length === 0)
        rw = [
          {
            id: null,
            cod_quota: this.state.selectedCodice.name,
            note: null,
            chiuso: "NO",
            anno: this.state.anno,
            sede: this.state.selectedSede
          }
        ];
      this.setState({
        isVisible: true,
        Tabulati: rw?.pop()
      });
    } else {
      const missingFields = [];
      if (!this.state.selectedCodice) missingFields.push("Codice Quota");
      if (!this.state.selectedSede) missingFields.push("Sede");
      if (!this.state.anno) missingFields.push("Anno");
      const alertMessage = `Si prega di compilare i seguenti campi obbligatori per affinare la ricerca dei tabulati:
      ${missingFields.join(", ")}`;
      this.showAlert("ATTENZIONE", alertMessage);
    }
    await this.refreshGrid();

    this.setState({
      loadingGrid: false
    });
  };

  showAlert(title, message) {
    this.setState({
      alertMessage: message,
      alertHeader: title,
      showAlert: true
    });
  }

  handleClearClick = () => {
    this.setState({
      anno: this.state.prendiAnno.getFullYear(),
      fineDate: new Date(),
      isVisible: false,
      selectedSede: null,
      selectedCodice: null,
      selectedGrid: null
    });
  };

  async refreshGrid() {
    this.setState({
      loadingGrid: true
    });
    this.setState({
      rows: (await TabulatiAPI.GetChiusuraTabulati()).result
    });
    this.setState({
      loadingGrid: false
    });
  }

  showMessage(level, message) {
    this.props.addAlert.current.addAlert(level, message);
  }

  async handleTabulatoChange(tabulato) {
    if (tabulato.chiuso === "NO") {
      const resp = await TabulatiAPI.CloseTabulato(tabulato);
      if (!resp.result)
        this.showMessage("danger", "Il tabulato non è stato chiuso");
      else {
        this.onAnnullaClick();
        this.showMessage("success", "Il tabulato è stato chiuso correttamente");
      }
    } else {
      const resp = await TabulatiAPI.OpenTabulato(tabulato.id);
      if (!resp.result)
        this.showMessage("danger", "Il tabulato non è stato aperto");
      else {
        this.onAnnullaClick();
        this.showMessage("success", "Il tabulato è stato aperto correttamente");
      }
    }

    await this.refreshGrid();
  }

  handleTableClick = async (e) => {
    this.setState({
      visibleLoader: true
    });
    this.setState({
      selectedGrid: e.value,
      Tabulati: e.value,
      isVisible: true
    });
    this.setState({
      visibleLoader: false
    });
  };

  render() {
    return (
      <>
        {this.state.visibleLoader && <LoaderSpinner fullHeight={true} />}
        <Dialog
          header={this.state.alertHeader}
          visible={this.state.showAlert}
          style={{ width: "50vw" }}
          onHide={() => this.setState({ showAlert: false })}
        >
          <p>{this.state.alertMessage}</p>
        </Dialog>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Chiusura Tabulati
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={2}>
                    <p className="lblNoMargin">Sede</p>
                    <Dropdown
                      display="chip"
                      optionLabel="descrizione"
                      maxSelectedLabels={1}
                      optionValue="sap"
                      value={this.state.selectedSede}
                      options={this.state.Sede}
                      filter
                      filterBy="descrizione"
                      onChange={(e) =>
                        this.HandleChange("selectedSede", e.value)
                      }
                    />
                  </GridItem>
                  <GridItem xs={2}>
                    <p className="lblNoMargin">Codice Quota</p>
                    <Dropdown
                      display="chip"
                      optionLabel="name"
                      maxSelectedLabels={1}
                      value={this.state.selectedCodice}
                      options={this.state.CodiceQuota}
                      onChange={(e) =>
                        this.HandleChange("selectedCodice", e.value)
                      }
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{ marginTop: "3px", zIndex: "1000" }}
                    className="yearPicker"
                  >
                    <p className="lblNoMargin">Anno</p>
                    <YearPicker
                      onChange={(e) => this.HandleChange("anno", e)}
                      value={this.state.anno}
                      style={{}}
                    />
                  </GridItem>
                  <GridItem xs={2} style={{ marginTop: "15px" }}>
                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Cerca"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <Button
                        ref={this.btnSearchRef}
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%",
                          marginLeft: "0",
                          marginTop: "0px",
                          marginRight: "4px"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        onClick={this.handleSearchClick}
                      >
                        <Search />
                      </Button>
                    </Tooltip>
                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Reimposta"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <Button
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%",
                          marginRight: "0"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        onClick={this.handleClearClick}
                      >
                        <Clear />
                      </Button>
                    </Tooltip>
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ marginTop: "10px" }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <Accordion
                      active={1}
                      collapses={[
                        {
                          title: "Visualizza tutte le Chiusure",
                          content: (
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <DataTable
                                  emptyMessage=""
                                  className="p-datatable-striped p-datatable-sm"
                                  value={this.state.rows}
                                  dataKey="id"
                                  paginator
                                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                                  rows={5}
                                  loading={this.state.loadingGrid}
                                  paginatorLeft={this.paginatorLeft}
                                  paginatorRight={this.paginatorRight}
                                  selection={this.state.selectedGrid}
                                  scrollable
                                  style={{ width: "100%" }}
                                  onSelectionChange={this.handleTableClick}
                                  selectionMode="single"
                                >
                                  <Column
                                    field="sede"
                                    header="Sede"
                                    headerStyle={{ width: "150px" }}
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterPlaceholder=""
                                  />
                                  <Column
                                    field="anno"
                                    header="Anno"
                                    headerStyle={{ width: "150px" }}
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterPlaceholder=""
                                  />
                                  <Column
                                    field="cod_quota"
                                    header="CodQuota"
                                    headerStyle={{ width: "150px" }}
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterPlaceholder=""
                                  />
                                  <Column
                                    field="chiuso"
                                    header="Chiuso"
                                    headerStyle={{ width: "150px" }}
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterPlaceholder=""
                                  />
                                  <Column
                                    field="data_op"
                                    header="DataOp."
                                    headerStyle={{ width: "150px" }}
                                    body={(row) =>
                                      moment(row.data_op, dateTimeFmt).format(
                                        dateFmt
                                      )
                                    }
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterPlaceholder=""
                                  />
                                </DataTable>
                              </GridItem>
                            </GridContainer>
                          )
                        }
                      ]}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.isVisible && (
          <ModificaChiusuraTabulati
            onAnnullaClick={this.onAnnullaClick}
            dataItem={this.state.Tabulati}
            changeTabulato={this.handleTabulatoChange}
          />
        )}
      </>
    );
  }
}
export default ChiusuraTabulati;
