import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { Button } from "primereact/button";
import Close from "@material-ui/icons/Close";
import { confirmPopup } from "primereact/confirmpopup";
import CustomButtons from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import DipendentiAPI from "API/DipendentiAPI";
import RichiestiAPI from "API/RichiestiAPI";
import queryString from "query-string";
import VersamentiAPI from "API/VersamentiAPI";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ButtonsMUI from "@material-ui/core/Button";
import Utils from "../../API/Utils";
import helpers from "../../helpers/Helpers";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";
import {rowsPerPageOptions} from "../../helpers/AppConstants";

class VerificaContributiva extends Component {
  state = {
    RowItemRichidenti: [],
    RowItemRichiesti: [],
    RowItemDipendenti: [],
    selectedGrid: null,
    filterGrid: false,
    loadingGrid: true,
    isVisible: false,
    rows: [],
    loadingPage: false
  };

  constructor(props) {
    super(props);
    this.onBackClick = this.onBackClick.bind(this);
    this.DeleteAttachment = this.DeleteAttachment.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.esporta = this.esporta.bind(this);
    this.btnSearchRef = React.createRef();
  }

  async esporta() {
    this.setState({ loadingPage: true });
    Utils.ExportExcel(this.state.rows);
    this.setState({ loadingPage: false });
  }

  DeleteAttachment(rowData) {
    return (
      <CustomButtons
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData.Id}
        onClick={this.onDeleteClick}
      >
        <Close className="icon" />
      </CustomButtons>
    );
  }

  onDeleteClick(event) {
    event.stopPropagation();

    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {},
      reject: () => {}
    });
  }

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: e.value
    });
  };

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  onBackClick() {
    let qS = queryString.parse(this.props.history.location.search);
    if (qS.type === null)
      this.props.history.push(
        "/admin/richiedenti/modificarichieste/" +
          this.props.match.params.richiedentiId +
          "/" +
          this.props.match.params.richiesteId
      );
    else if (qS.type === "fromRichieste")
      this.props.history.push(
        "/admin/richiedenti/modificarichieste/" +
          this.props.match.params.richiedentiId +
          "/" +
          this.props.match.params.richiesteId +
          "?type=" +
          qS.type
      );
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });

    const requestId = this.props.match.params.richiesteId;
    const resRequest = await RichiestiAPI.GetRequestById(requestId);
    this.setState({
      request: resRequest.pop()
    });

    const employeeFC = this.state.request.cfDip;
    const resEmployee = await DipendentiAPI.GetByCF(employeeFC);
    this.setState({
      employee: resEmployee.result?.pop()
    });

    this.setState({
      rows: await VersamentiAPI.GetVersamentiUniemensForDipendente(
        this.state.request.cfDip
      )
    });

    this.setState({
      loadingGrid: false
    });
  }

  onSaveClick() {
    this.setState({
      IsVisible: false
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  HandleSaveClick = async () => {
    window.setTimeout(() => {
      this.onSaveClick();
      this.props.addAlert.current.addAlert("success", "Dati salvati.");
    }, 2200);
    return true;
  };

  render() {
    return (
      <>
        {this.state.loadingPage && <LoaderSpinner />}
        <div ref={this.btnSearchRef} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <h4
                  style={{
                    lineHeight: "2",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  &nbsp;Verifica Contributiva
                </h4>

                <Button
                  icon="pi pi-arrow-left"
                  onClick={() => this.props.history.goBack()}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{
                    verticalAlign: "center",
                    marginRight: "2px",
                    marginTop: "6px"
                  }}
                />
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={2}>
                        <CustomInput
                          labelText="Id Richiesta"
                          id="Id"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.request?.idRichies || ""
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={2}>
                        <CustomInput
                          labelText="Richiedente"
                          id="applicant"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value:
                              this.state.request?.cfRich?.cognome +
                                " " +
                                this.state.request?.cfRich?.nome || ""
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={2}>
                        <CustomInput
                          labelText="Protocollo"
                          id="Protocollo"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.request?.protRichies || ""
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          labelText="Tipo"
                          id="Tipo"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value:
                              this.state.request?.idBorsa?.tipoBorsa?.descr ||
                              ""
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={1}>
                        <CustomInput
                          labelText="Anno"
                          id="Anno"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value:
                              this.state.request?.idBorsa?.annoAcc.descr || ""
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={2}>
                        <CustomInput
                          labelText="Data Verifica"
                          id="Data"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.request?.dataVerifVers || ""
                          }}
                        />
                      </GridItem>
                    </GridContainer>

                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Codice Fiscale Dipendente"
                          id="CodiceFiscale"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.request?.cfDip || ""
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Nominativo"
                          id="Nominativo"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.employee?.nominativo || ""
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borsesuccess">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Uniemens e Versamenti Presenti per il Dipendente
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
                <ButtonGroup
                  variant="contained"
                  color="inherit"
                  size="small"
                  aria-label="contained primary button group"
                  className="float-right m-1"
                >
                  <ButtonsMUI onClick={this.esporta}>Esporta</ButtonsMUI>
                </ButtonGroup>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="competenza"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={5}
                  rowsPerPageOptions={rowsPerPageOptions}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  loading={this.state.loadingGrid}
                  selection={this.state.selectedGrid}
                  scrollable scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="competenza"
                    header="Mese Competenza"
                    headerStyle={{ width: "180px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cod_quota"
                    header="Codice Quota"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="matricola"
                    header="matricola"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="quotaUNIEM"
                    header="Dichiarazione Uniemens"
                    headerStyle={{ width: "230px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    body={(row) => helpers.DecimalDTTemplate(row, "quotaUNIEM")}
                  />
                  <Column
                    field="quotaVersata"
                    header="Quota Versata"
                    headerStyle={{ width: "160px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    body={(row) =>
                      helpers.DecimalDTTemplate(row, "quotaVersata")
                    }
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default VerificaContributiva;
