import * as AppConstants from "../helpers/AppConstants";
import saveAs from "file-saver";

import Utils from "./Utils";
import moment from "moment";
import AziendeAPI from "./AziendeAPI";
import StaticLists from "../helpers/StaticLists";

const CorrispondenzaAPI = {
  getEbinCorrespondenceById: async function (id) {
    const url = `coasco/corrispondenzaebin/getbyid?id=${id}`;
    const res = await Utils.CreatePostRequest(url);
    return res?.result[0];
  },

  async getLastForCompany(id) {
    const { matricola } = (await AziendeAPI.GetAziendaById(id))[0];
    return await Utils.CreatePostRequest(
      "coasco/corrispondenza/getLastForCompany?matr=" + matricola
    );
  },

  getById: async function (id) {
    const url = `coasco/corrispondenza/getbyid?id=${id}`;
    return await Utils.CreatePostRequest(url);
  },

  getByEmployeeId: async function (employeeId) {
    const url = `coasco/corrispondenza/getbyiddipendente?iddipendente=${employeeId}`;
    return await Utils.CreatePostRequest(url);
  },

  getByEmployeeIdEbin: async function (employeeId) {
    const url = `coasco/corrispondenzaebin/getbyiddipendente?iddipendente=${employeeId}`;
    return await Utils.CreatePostRequest(url);
  },

  getByConsultantId: async function (consultantId) {
    const url = `coasco/corrispondenza/getbyidconsulente?idconsulente=${consultantId}`;
    return await Utils.CreatePostRequest(url);
  },

  getByConsultantIdEbin: async function (consultantId) {
    const url = `coasco/corrispondenzaebin/getbyidconsulente?idconsulente=${consultantId}`;
    return await Utils.CreatePostRequest(url);
  },

  getByAdministratorId: async function (administratorId) {
    const url = `coasco/corrispondenza/getbyidamministratore?idamministratore=${administratorId}`;
    return await Utils.CreatePostRequest(url);
  },

  getByAdministratorIdEbin: async function (administratorId) {
    const url = `coasco/corrispondenzaebin/getbyidamministratore?idamministratore=${administratorId}`;
    return await Utils.CreatePostRequest(url);
  },

  get: async function (start, size, type) {
    return await Utils.CreatePostRequest(
      `coasco/corrispondenza/getby?start=${start}&size=${size}&tipo=${type}`
    );
  },

  getAll: async function (start, size) {
    return await Utils.CreatePostRequest(
      `coasco/corrispondenza/getby?start=${start * 10}&size=${size}`
    );
  },

  getByIdRichies: async function (idRichies) {
    return await Utils.CreatePostRequest(
      `coasco/corrispondenzaebin/getbyidrichies?idrichies=${idRichies}`
    );
  },

  GetExportedFiles: async function (ids, fileName) {
    return await Utils.CreatePostRequestDownloadZip(
      `coasco/corrispondenza/export`,
      ids,
      fileName
    );
  },

  GetCorrispondenza: async function (aziendaId) {
    return {
      Id: aziendaId,
      Matricola: "Matricola" + aziendaId,
      RagioneSociale: "RagioneSociale" + aziendaId,
      Sede: " ",
      Sede2: " ",
      Aziende: " ",
      Aziende2: " ",
      Aziende3: " ",
      Dipendenti: " ",
      Dipendenti2: " ",
      Dipendenti3: " ",
      Consulenti: " ",
      Consulenti2: " ",
      Consulenti3: " ",
      Amministratori: " ",
      Amministratori2: " ",
      Amministratori3: " ",
      SapMatricola: "",
      Causale: 2,
      Causale2: "scritto condominio",
      Sap: 2,
      Prov: " ",
      DataLettera: new Date(),
      Oggetto: "CONTRIBUTI IRREGOLARI",
      Tabulati: "0",
      Fatture: "0",
      Preavvisi: "0",
      Contabili: "0",
      W300: 1,
      W350: 1,
      Puli: 1,
      Cust: 1,
      Amm: 1,
      Buchi: 1,
      Allegati: " ",
      Lettera: "fondocoasco@fondocoasco.it",
      TipoProt: 2,
      Prot: "1071",
      Anno: "",
      Data: ""
    };
  },

  GetCorrispondenzaAttachments: async function (corrispId) {
    return await Utils.CreatePostRequest(
      "coasco/corrispallegati/getbyid?id=" + corrispId
    );
  },
  
  GetCorrispondenzaEbinAttachments: async function (corrispId) {
    return await Utils.CreatePostRequest(
      "coasco/corrispallegatiebin/getbyid?id=" + corrispId
    );
  },

  GetCorrispondenzaPathAttach: async function (corrispId) {
    return await Utils.CreatePostRequest(
      "coasco/corrispallegati/getbyidcorrispondenza?id_corrispondenza=" +
        corrispId
    );
  },

  GetCorrispondenzaEbinPathAttach: async function (corrispId) {
    return await Utils.CreatePostRequest(
      "coasco/corrispallegatiebin/getbyidcorrispondenza?id_corrispondenza=" +
        corrispId
    );
  },

  GetCorrispondenzaPathAttachFromUfficio: async function (ufficio, id) {
    if (ufficio?.toUpperCase() === "EBINPROF") {
      return this.GetCorrispondenzaEbinPathAttach(id);
    } else {
      return this.GetCorrispondenzaPathAttach(id);
    }
  },

  GetCorrispondenzaEbinByCF: async function (cf) {
    return await Utils.CreatePostRequest(
      "coasco/corrispondenzaebin/getbycfrichied?cfrichied=" + cf
    );
  },

  GetNuovoCorrispondenza: function (matr) {
    return {
      amm: null,
      anno: new Date().getFullYear(),
      azienda: matr,
      causale: null,
      cfamm: null,
      cfcons: null,
      cfdip: null,
      codsap: null,
      cust: null,
      data: moment(new Date()).format(AppConstants.dateTimeFmt),
      destmail: null,
      dtlett: null,
      flamm: 0,
      flaz: 0,
      flcons: 0,
      fldip: 0,
      fldocele: null,
      fldoppi: null,
      flmodo: null,
      id: null,
      mitt_dest: null,
      mittmail: null,
      ncontabbanc: null,
      ninvio: null,
      note: null,
      numfat: null,
      numpreav: null,
      numtab: null,
      oggetto: "",
      pathattach: null,
      prbuchicont: null,
      prot: null,
      prov: null,
      puli: null,
      sede_inps: null,
      tipo: "Uscita",
      tipoinvio: 0,
      utente: "Sistema",
      w300: null,
      w350: null
    };
  },

  DeleteCorrispondenzaAttachment: async function (attachId) {
    const url = new URL(
      `${AppConstants.SiteUrl}coasco/corrispallegati/delete?id=${attachId}`
    );
    return await Utils.SendDeleteRequestTo(url);
  },

  DeleteCorrispondenzaEbinAttachment: async function (attachId) {
    const url = new URL(
      `${AppConstants.SiteUrl}coasco/corrispallegatiebin/delete?id=${attachId}`
    );
    return await Utils.SendDeleteRequestTo(url);
  },

  DownloadCorrispondenzaAttachment: async function (attachId, extension) {
    const url = new URL(
      `${AppConstants.SiteUrl}coasco/corrispondenza/download?id_allegato=${attachId}`
    );
    return await Utils.CreatePostRequestDownloadFile(
      url,
      "Corrispondenza",
      extension
    );
  },

  downloadCorrespondenceAttachmentEbin: async function (attachId, extension) {
    const url = new URL(
      `${AppConstants.SiteUrl}coasco/corrispondenzaebin/download/${attachId}`
    );
    return await Utils.httpGetDownloadFile(url, "Corrispondenza", extension);
  },

  openPdf: async function (attachId, extension) {
    const url = new URL(
      `${AppConstants.SiteUrl}coasco/corrispondenza/download?id_allegato=${attachId}`
    );
    return await Utils.CreatePostRequestOpenPdf(
      url,
      "Corrispondenza",
      extension
    );
  },

  openPdfEbin: async function (attachId, extension) {
    const url = new URL(
      `${AppConstants.SiteUrl}coasco/corrispondenzaebin/download/${attachId}`
    );
    return await Utils.httpGetOpenPdf(url, "Corrispondenza", extension);
  },

  DownloadCorrispondenzaEbinAttachment: async function (attachId) {
    return await Utils.CreatePostRequest(
      `coasco/corrispallegatiebin/download?id=${attachId}`
    );
  },

  GetCorrispondenzaForAzienda: async function (aziendaId) {
    const response = await Utils.CreatePostRequest(
      "coasco/corrispondenza/getbyidazienda?idazienda=" + aziendaId
    );
    if (response && response.result) return response.result;
    else return [];
  },

  GetCorrispondenzaForAziendaForVersamenti: async function (aziendaId) {
    const response = await Utils.CreatePostRequest(
      "coasco/corrispondenza/getByIdAziendaForVersamenti?idAzienda=" + aziendaId
    );
    if (response && response.result) return response.result;
    else return [];
  },

  GetCorrispondenzaForAziendaEbin: async function (aziendaId) {
    const response = await Utils.CreatePostRequest(
      "coasco/corrispondenzaebin/getbyidazienda?idazienda=" + aziendaId
    );
    if (response && response.result) return response.result;
    else return [];
  },

  GetCorrispondenzaById: async function (id) {
    const response = await Utils.CreatePostRequest(
      "coasco/corrispondenza/getbyid?id=" + id
    );
    if (response && response.result) return response.result;
    else return [];
  },

  GetCorrispondenzaBy: async function (start, size, params) {
    const url = new URL(`${AppConstants.SiteUrl}coasco/corrispondenza/getby`);
    return await this.SearchCorrispBy(url, start, size, params);
  },

  SearchCorrispBy: async function (url, start, size, params) {
    url.searchParams.append("start", start);
    url.searchParams.append("size", size);

    for (const key in params) {
      if (params[key]) {
        url.searchParams.append(
          key,
          !(params[key] instanceof Date)
            ? params[key]
            : params[key].toLocaleString()
        );
      }
    }
    return await Utils.SendPostRequestTo(url, null).catch((err) =>
      console.error(err)
    );
  },

  GetCorrispondenzaEbinBy: async function (start, size, params) {
    const url = new URL(
      AppConstants.SiteUrl + "coasco/corrispondenzaebin/getby"
    );
    return await this.SearchCorrispBy(url, start, size, params);
  },

  GetNuovoCorrispondenzaForRichiedente() {
    return {
      Id: "",
      Anno: "",
      Prot: "",
      DataProt: new Date(),
      Utente: "",
      Utente2: "",
      Richiedente: "",
      Richiedente2: "",
      Richiedente3: "",
      Richiesta: "",
      Richiesta2: "",
      Causale: 2,
      Causale2: "",
      DataLettera: new Date(),
      Oggetto: "",
      Allegati: "",
      TipoProt: 2
    };
  },

  GetLastInsertedProtocol: async function (year, type) {
    const url = new URL(
      `${
        AppConstants.SiteUrl
      }coasco/corrispondenza/getlastprot?anno=${year}&tipo=${type.toUpperCase()}`
    );
    return await Utils.SendPostRequestTo(url, null);
  },

  GetLastInsertedProtocolEbin: async function (year, type) {
    const url = new URL(
      `${
        AppConstants.SiteUrl
      }coasco/corrispondenzaebin/getlastprot?anno=${year}&tipo=${type.toUpperCase()}`
    );
    return await Utils.SendPostRequestTo(url, null);
  },

  GetCausaliForCorrispondenza: async function () {
    const response = await Utils.CreatePostRequest("coasco/caucorr/getall");
    if (response === null) return [];
    return response.result.sort(
      (a, b) => parseInt(a.codice) - parseInt(b.codice)
    );
  },

  async getCausaliFromTipoUfficio(user) {
    if (!user) return [];
    else if (user.descrizione_ufficio?.toUpperCase() === "EBINPROF") {
      return await this.GetCausaliEbinForCorrispondenza();
    } else {
      return await this.GetCausaliForCorrispondenza();
    }
  },

  getAccountEmailFromTipoUfficio(user) {
    if (!user) return [];
    else if (user.descrizione_ufficio.toUpperCase() === "EBINPROF") {
      return StaticLists.Corrisp_Account_ebin();
    } else {
      return StaticLists.Corrisp_Account();
    }
  },

  GetCausaliEbinForCorrispondenza: async function () {
    const response = await Utils.CreatePostRequest("coasco/caucorrebin/getall");
    if (response === null) return [];
    return response.result.sort(
      (a, b) => parseInt(a.codice) - parseInt(b.codice)
    );
  },

  GetCorrispondenzaByType: async function (start, size, anno, matricola, tipo) {
    return await this.GetCorrispondenzaBy(start, size, {
      anno: anno,
      fromMatr: matricola,
      tipo: tipo
    });
  },

  ChangeGetCorrispAllegatiStato: async function () {
    return true;
  },

  AddCorrispondenza: async function (
    senderId,
    recipientEmails,
    object,
    json,
    file
  ) {
    const _json = {
      ...json,
      azienda: typeof json?.azienda === "undefined" ? null : json?.azienda
    };
    if (_json.tipoinvio === 1) {
      return await Utils.CreatePostRequestWithFile(
        "coasco/corrispondenza/insertv1",
        senderId,
        recipientEmails,
        object,
        _json,
        file
      );
    } else {
      return await Utils.CreatePostRequestWithFileMail(
        "coasco/corrispondenza/insertv1",
        senderId,
        recipientEmails,
        object,
        _json,
        file
      );
    }
  },

  AddCorrispondenzaEbin: async function (
    senderId,
    recipientEmails,
    object,
    json,
    file
  ) {
    const _json = {
      ...json,
      azienda: typeof json?.azienda === "undefined" ? null : json?.azienda
    };
    if (_json.tipoinvio === 1) {
      return await Utils.CreatePostRequestWithFile(
        "coasco/corrispondenzaebin/insertv1",
        senderId,
        recipientEmails,
        object,
        _json,
        file
      );
    } else {
      return await Utils.CreatePostRequestWithFileMail(
        "coasco/corrispondenzaebin/insertv1",
        senderId,
        recipientEmails,
        object,
        _json,
        file
      );
    }
  },

  UpdateCorrispondenzaEbin: async function (
    senderId,
    recipientEmails,
    object,
    json,
    file
  ) {
    const _json = {
      ...json,
      azienda: typeof json?.azienda === "undefined" ? null : json?.azienda,
      causale: typeof json?.causale === 'object' ? json.causale.codice : json.causale,
      tipoinvio: typeof json?.tipoinvio === 'object' ? json?.tipoinvio?.id : json?.tipoinvio
    };
    if (_json.tipoinvio === 1) {
      return await Utils.CreatePostRequestWithFile(
        "coasco/corrispondenzaebin/updatev1",
        null,
        recipientEmails,
        object,
        _json,
        file
      );
    } else {
      return await Utils.CreatePostRequestWithFileMail(
        "coasco/corrispondenzaebin/updatev1",
        null,
        recipientEmails,
        object,
        _json,
        file
      );
    }
  },
  //metodo duplicato perche probabilmente viene usato in altri punti (per evitare di spaccare)
  UpdateCorrispondenzaEbin1: async function (
    senderId,
    recipientEmails,
    object,
    json,
    file
  ) {
    const _json = {
      ...json,
      azienda: typeof json?.azienda === "undefined" ? null : json?.azienda,
      tipoinvio: typeof json?.tipoinvio === 'object' ? json?.tipoinvio?.id : json?.tipoinvio

    };
    if (_json.tipoinvio === 1) {
      return await Utils.CreatePostRequestWithFile(
        "coasco/corrispondenzaebin/updatev1",
        null,
        recipientEmails,
        object,
        _json,
        file
      );
    } else {
      return await Utils.CreatePostRequestWithFileMail(
        "coasco/corrispondenzaebin/updatev1",
        null,
        recipientEmails,
        object,
        _json,
        file
      );
    }
  },

  UpdateCorrispondenza: async function (json, file) {
    const _json = {
      ...json,
      azienda: typeof json?.azienda === "undefined" ? null : json?.azienda,
      tipoinvio: typeof json?.tipoinvio === 'object' ? json?.tipoinvio?.id : json?.tipoinvio,
      causale: typeof json?.causale === 'object' ? json?.causale?.codice : json?.causale
    };
    if (_json.tipoinvio === 1) {
      return await Utils.CreatePostRequestWithFile(
        "coasco/corrispondenza/updatev1",
        null,
        null,
        null,
        _json,
        file
      );
    } else {
      return await Utils.CreatePostRequestWithFileMail(
        "coasco/corrispondenza/updatev1",
        null,
        null,
        null,
        _json,
        file
      );
    }
  },

  newEbinCorrespondence: async function (
    senderId,
    recipientEmails,
    object,
    json,
    file
  ) {
    let obj;
    if (json.tipoinvio === 1) {
      obj = await Utils.CreatePostRequestWithFile(
        "coasco/corrispondenzaebin/insertv1",
        senderId,
        recipientEmails,
        object,
        json,
        file
      );
      saveAs(new Blob([obj.data]), "lettera_richiesta_ebin.pdf");
    } else {
      obj = await Utils.CreatePostRequestWithFileMail(
        "coasco/corrispondenzaebin/insertv1",
        senderId,
        recipientEmails,
        object,
        json,
        file
      );
    }
    return obj;
  },

  PreparaMailGenerico: async function (
    anno,
    dataProt,
    oggetto,
    emailText,
    senderEmail,
    files,
    selectedCaus,
    name,
    selectedGrid,
    altroDest,
    ufficio
  ) {
    const res = await Utils.httpPostWithStatus(
      "coasco/" +
      (ufficio === "EBINPROF" ? "corrispondenzaebin" : "corrispondenza") +
      "/preparaMailGenerico" +
      "?anno=" + anno +
      "&dataProt=" + dataProt +
      "&oggetto=" + encodeURIComponent(oggetto) +
      "&emailText=" + encodeURIComponent(emailText) +
      "&senderEmail=" + encodeURIComponent(senderEmail) +
      "&selectedCaus=" + selectedCaus +
      "&name=" + name +
      "&altroDest=" +  altroDest,
      files,
      selectedGrid.map((x) => x.id),
      "ids"
    );
    return res;
  }
};
export default CorrispondenzaAPI;
