import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ButtonsMUI from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Helpers from "helpers/Helpers.js";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "primereact/button";
import Close from "@material-ui/icons/Close";
import { confirmPopup } from "primereact/confirmpopup";
import CustomButtons from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import CustomInput from "components/CustomInput/CustomInput.js";
import ModificaPunteggi from "components/Views/Punteggi/ModificaPunteggi.js";
import NuovoPunteggio from "components/Views/Punteggi/NuovoPunteggio.js";
import PunteggiAPI from "API/PunteggiAPI";
import RichiestiAPI from "API/RichiestiAPI";
import queryString from "query-string";
import LoaderSpinner from "components/Layout/LoaderSpinner.js";
import { dateTimeFmt, rowsPerPageOptions } from "helpers/AppConstants";
import { dateFmt } from "helpers/AppConstants";
import moment from "moment";
import Utils from "../../API/Utils";
class Punteggi extends Component {
  state = {
    RowItemRichidenti: [],
    request: [],
    PunteggiRows: [],
    visibleLoader: false,
    selectedGrade: null,
    filterGrid: null,
    loadingGrid: false,
    nuovoModificaTitle: "Modifica",
    gridStyle: {},
    PunteggiItems: null,
    updatenewid: false,
    updatedRows: null,
    punteggiLength: 0,
    showUpdateForm: false,
    showAddForm: true,
    rows: []
  };

  constructor(props) {
    super(props);
    this.onBackClick = this.onBackClick.bind(this);
    this.DeleteAttachment = this.DeleteAttachment.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.gridPunteggiRef = React.createRef();
    this.onAddClick = this.onAddClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.showActionResult = this.showActionResult.bind(this);
    this.handelDeleteGrade = this.handelDeleteGrade.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
    this.stampaElencoPunteggi = this.stampaElencoPunteggi.bind(this);
    this.btnSearchRef = React.createRef();
    this.consolidatesHandler = this.consolidatesHandler.bind(this);
  }

  async stampaElencoPunteggi() {
    this.setState({ visibleLoader: true });
    await Utils.CreatePostRequestDownloadReport(
      "ebinprof/PuntRichies/stampaElencoPunteggi?richiesId=" +
        this.state.request.idRichies
    );
    this.setState({ visibleLoader: false });
  }

  DeleteAttachment(rowData) {
    return (
      <CustomButtons
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData.id}
        onClick={(event) => {
          event.stopPropagation();
          this.onDeleteClick(event, rowData);
        }}
      >
        <Close className="icon" />
      </CustomButtons>
    );
  }

  async handelDeleteGrade(id) {
    const response = await PunteggiAPI.DeletePunteggi(id);
    if (response && response.result) {
      this.props.addAlert.current.addAlert(
        "success",
        "Punteggio eliminato correttamente"
      );
      await this.refreshTable();
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "Eliminazione non riuscita"
      );
    }
  }

  onDeleteClick(event, rowData) {
    const id = rowData.idPunt;
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: async () => {
        await this.handelDeleteGrade(id);
      },
      reject: () => {}
    });
  }

  async onAddClick() {
    this.setState({
      visibleLoader: true
    });
    this.setState({
      selectedGrade: null,
      showAddForm: true,
      showUpdateForm: false,
      nuovoModificaTitle: "Inserisci",
      gridStyle: { width: "65%", float: "left" },
      updatenewid: true,
      PunteggiItems: PunteggiAPI.GetNuovoPunteggiConseguiti(null)
    });
    this.setState({
      visibleLoader: false
    });
  }

  handleTableClick = async (e) => {
    this.setState({
      visibleLoader: true
    });
    this.setState({
      selectedGrade: e.value,
      showUpdateForm: true,
      gridStyle: { width: "65%", float: "left" },
      updatenewid: false,
      nuovoModificaTitle: "Modifica"
    });
    this.setState({
      visibleLoader: false
    });
  };

  onAnnullaClick() {
    this.setState({
      gridStyle: null,
      selectedGrade: null,
      showUpdateForm: false,
      showAddForm: false
    });
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  onBackClick() {
    let qS = queryString.parse(this.props.history.location.search);
    if (!qS.type)
      this.props.history.push(
        "/admin/richiedenti/modificarichieste/" +
          this.props.match.params.richiedentiId +
          "/" +
          this.props.match.params.richiesteId
      );
    else if (qS.type === "fromRichieste")
      this.props.history.push(
        "/admin/richiedenti/modificarichieste/" +
          this.props.match.params.richiedentiId +
          "/" +
          this.props.match.params.richiesteId +
          "?type=" +
          qS.type
      );
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });

    const punteggi = await PunteggiAPI.GetPunteggiConseguiti(
      this.props.match.params.richiesteId
    );
    const response = await RichiestiAPI.GetRequestById(
      this.props.match.params.richiesteId
    );
    const request = response?.pop();

    const punteggiRes = await PunteggiAPI.getByIdRichies(
      this.props.match.params.richiesteId
    );

    this.setState({
      loadingGrid: true,
      RowItemRichidenti: request.cfRich, // contains the applicant of the request
      request: request,
      PunteggiRows: punteggiRes.result,
      punteggiLength: punteggi.length
    });
    this.setState({
      loadingGrid: false
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.PunteggiItems !== null) {
      if (this.state.PunteggiItems !== prevState.PunteggiItems) {
        this.setState({
          updatedRows: this.state.PunteggiItems
        });
      }
    }
  }

  async onSaveClick() {
    this.setState({
      gridStyle: {},
      PunteggiItems: null
    });

    if (this.state.nuovoModificaTitle === "Inserisci") {
      Helpers.CloseAllEditRows(this.gridPunteggiRef);
      let PunteggiRows = this.state.PunteggiRows;
      PunteggiAPI.GetNuovoPunteggiConseguiti(null);
      PunteggiRows.push(this.state.PunteggiItems);

      this.setState({ PunteggiRows: PunteggiRows }, function () {
        Helpers.OpenEditFirstRow(this.gridPunteggiRef);
      });
    }
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  showActionResult(response) {
    if (response && response.result) {
      this.props.addAlert.current.addAlert("success", `Dati salvati`);
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "Si è verificato un errore durante il salvataggio dei dati"
      );
    }
  }

  HandleSaveClick = async (RowItem) => {
    if (RowItem.idpunt === 0) {
      this.showActionResult(await PunteggiAPI.AddPunteggi(RowItem));
    } else {
      this.showActionResult(await PunteggiAPI.UpdatePunteggi(RowItem));
    }
    await this.onSaveClick();
    return true;
  };

  refreshTable = async () => {
    this.setState({
      loadingGrid: true
    });

    const requestId = this.props?.match?.params?.richiesteId;

    const res = await PunteggiAPI.getByIdRichies(requestId);
    this.setState({
      PunteggiRows: res?.result
    });

    this.setState({
      loadingGrid: false
    });
  };

  newScoreHandler = () => {
    const value = this.state?.showAddForm;
    value
      ? this.setState({
          showAddForm: false,
          gridStyle: null
        })
      : this.setState({
          showAddForm: true,
          gridStyle: { width: "65%", float: "left" }
        });
  };

  updateScoreHandler = () => {
    const value = this.state?.showUpdateForm;
    value
      ? this.setState({
          showUpdateForm: false,
          gridStyle: null,
          selectedGrade: null
        })
      : this.setState({
          showUpdateForm: true,
          gridStyle: { width: "65%", float: "left" }
        });
  };

  async consolidatesHandler() {
    this.setState({
      loadingGrid: true,
      showAddForm: false
    });
    const requestId = this.props.match.params.richiesteId;
    await PunteggiAPI.consolidates(requestId);
    this.setState({
      loadingGrid: false
    });
  }

  render() {
    return (
      <>
        <div ref={this.btnSearchRef} />
        {this.state.visibleLoader && <LoaderSpinner fullHeight={true} />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <h4
                  style={{
                    lineHeight: "2",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Punteggi
                </h4>

                <Button
                  icon="pi pi-arrow-left"
                  onClick={this.onBackClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left mr-2"
                  style={{
                    verticalAlign: "center",
                    marginTop: "6px"
                  }}
                />
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={2}>
                        <CustomInput
                          labelText="Id Richiesta"
                          id="Id"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.props.match.params.richiesteId || ""
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={2}>
                        <CustomInput
                          labelText="Richiedente"
                          id="applicant"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value:
                              this.state.RowItemRichidenti.cognome +
                                " " +
                                this.state.RowItemRichidenti.nome || ""
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={2}>
                        <CustomInput
                          labelText="Protocollo"
                          id="Protocollo"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.request.protRichies || ""
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Tipo"
                          id="Tipo"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value:
                              this.state.request?.idBorsa?.tipoBorsa?.descr ||
                              ""
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={1}>
                        <CustomInput
                          labelText="Anno"
                          id="Anno"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value:
                              this.state.request?.idBorsa?.annoAcc?.descr || ""
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={1}>
                        <CustomInput
                          labelText="Data"
                          id="Data"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value:
                              moment(
                                this.state.request.dataIns,
                                dateTimeFmt
                              ).format(dateFmt) || ""
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          {this.state.showUpdateForm && (
            <GridItem xs={12} sm={12} md={12}>
              <ModificaPunteggi
                onSaveClick={this.HandleSaveClick}
                nuovoModificaTitle={this.state.nuovoModificaTitle}
                updatenewid={this.state.updatenewid}
                onAnnullaClick={this.onAnnullaClick}
                punteggiLength={this.state.punteggiLength}
                dataItem={this.state.selectedGrade}
                refreshTable={this.refreshTable}
                updateScoreHandler={this.updateScoreHandler}
                addAlert={this.props.addAlert.current.addAlert}
              />
            </GridItem>
          )}
          {this.state.showAddForm && (
            <GridItem xs={12} sm={12} md={12}>
              <NuovoPunteggio
                requestId={this.props.match.params.richiesteId}
                onAnnullaClick={this.onAnnullaClick}
                refreshTable={this.refreshTable}
                newScoreHandler={this.newScoreHandler}
                {...this.props}
              />
            </GridItem>
          )}
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borsesuccess">
                <h4
                  className="float-left"
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Punteggi Conseguiti
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <CustomButtons
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onAddClick}
                  >
                    <AddIcon />
                  </CustomButtons>
                </Tooltip>

                <ButtonGroup
                  variant="contained"
                  color="default"
                  size="small"
                  aria-label="contained default button group"
                  className="float-right"
                  style={{ verticalAlign: "center", margin: "0.5%" }}
                >
                  <ButtonsMUI onClick={this.consolidatesHandler}>
                    Consolida
                  </ButtonsMUI>
                  <ButtonsMUI onClick={this.stampaElencoPunteggi}>
                    Stampa Elenco
                  </ButtonsMUI>
                </ButtonGroup>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  ref={this.gridPunteggiRef}
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.PunteggiRows}
                  dataKey="idPunt"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10}
                  rowsPerPageOptions={rowsPerPageOptions}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrade}
                  scrollable
                  scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="idoneita"
                    header="Idoneità"
                    sortable={true}
                    filter={this.state.filterGrid}
                    headerStyle={{ width: "110px" }}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="punteggio"
                    header="Punteggio"
                    sortable={true}
                    filter={this.state.filterGrid}
                    headerStyle={{ width: "130px" }}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cfuRich"
                    header="CFU Richiesti"
                    sortable={true}
                    filter={this.state.filterGrid}
                    headerStyle={{ width: "140px" }}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cfuCons"
                    header="CFU Conseguiti"
                    sortable={true}
                    filter={this.state.filterGrid}
                    headerStyle={{ width: "160px" }}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="descr"
                    header="Descrizione"
                    sortable={true}
                    filter={this.state.filterGrid}
                    headerStyle={{ width: "140px" }}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    header=""
                    body={this.DeleteAttachment}
                    icon="pi pi-times"
                    label="Delete"
                    className="p-button-danger p-button-outlined colDelete"
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default Punteggi;
