import Utils from "./Utils";

const SedeComuneProvAPI = {
  GetAllSede: async function () {
    let response = await Utils.CreatePostRequest("coasco/sedeinps/getall", {});
    return response.result;
  },

  async UpdateSedeInps(sede) {
    const response = await Utils.CreatePutRequest("coasco/sedeinps/update", sede);
    if (response?.message?.includes("200")) {
      return response.result;
    } else return null;
  },

  async InsertSedeInps(sede) {
    const response = await Utils.CreatePostRequest("coasco/sedeinps/insert", sede);
    if (response?.message?.includes("200")) {
      return response.result;
    } else return null;
  },

  async DeleteSedeInps(sede) {
    const response = await Utils.CreateDeleteRequest("coasco/sedeinps/delete?id=" + sede.sap);
    if (response?.message?.includes("200")) {
      return response.result;
    } else return null;
  },

  GetNewSedeInps() {
    return {
      cap: "",
      comune: "",
      descrizione: "",
      indirizzo: "",
      path_archivio: "",
      prov: "",
      sap: "",
      sede: ""
    };
  },

  searchBySap: async function (sap) {
    let result = await Utils.CreatePostRequest(
      `coasco/sedeinps/searchbysap?sap=${sap}`,
      {}
    );
    const res = result?.result.map((item) => item.sap);

    return res;
  },

  searchBySapFull: async function (sap) {
    let result = await Utils.CreatePostRequest(
      `coasco/sedeinps/searchbysap?sap=${sap}`,
      {}
    );
    return result.result;
  },

  GetSedeBySap: async function (sap) {
    let response = await Utils.CreatePostRequest(
      "coasco/sedeinps/getbysap?sap=" + sap
    );
    return response.result;
  },

  GetAllComune: async function () {
    let response = await Utils.CreatePostRequest("coasco/comune/getall", {});
    return response.result;
  },

  GetComuneByCap: async function (cap) {
    let resultObj = await Utils.CreatePostRequest(
      "coasco/comune/searchbycap?cap=" + cap
    );

    let result = resultObj.result.map((item) => ({
      nome: item.nome,
      prov: item.prov.sigla
    }));

    if (result !== null) return result;
    else return {};
  },

  GetAllProvincieProvincie: async function () {
    let result = await Utils.CreatePostRequest("coasco/province/getall", {});
    return result.result.sort((first, second) =>
      first.sigla > second.sigla ? 1 : -1
    );
  },

  GetProvByCode: async function (code) {
    let response = await Utils.CreatePostRequest(
      `coasco/province/searchbysigla?sigla=${code}`
    );
    return response.result.sort((first, second) =>
      first.sigla > second.sigla ? 1 : -1
    );
  },

  GetComuneByProv: async function (code) {
    let response = await Utils.CreatePostRequest(
      `coasco/comune/getbyprov?prov=${code}`
    );
    return response.result;
  }
};
export default SedeComuneProvAPI;
