import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { ModificaAmministratoriXAzienda } from "components/Views/Amministratori/ModificaAmministratoriXAzienda.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import helpers from "helpers/Helpers";
import { AutoComplete } from "primereact/autocomplete";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ButtonsMUI from "@material-ui/core/Button";
import queryString from "query-string";
import Close from "@material-ui/icons/Close";
import { confirmPopup } from "primereact/confirmpopup";
import AmministratoriAPI from "API/AmministratoriAPI";
import UtentiAPI from "API/Utenti.api";
import { Button as BackButton } from "primereact/button";
import LoaderSpinner from "components/Layout/LoaderSpinner";
import { AuthContext } from "providers";
import {rowsPerPageOptions} from "../../helpers/AppConstants";

class Amministratori extends React.Component {
  static contextType = AuthContext;

  anchor = null;
  state = {
    filteredRows: [],
    initialRows: [],
    viewButtons: false,

    selectedGridAmministratori: null,
    selectedAmministratori: null,

    visibleLoader: false,

    rowsPerPage: 10,
    loadingGrid: false,

    filterByNominativo: "",
    filterByCodice: "",
    nominativoFullList: [],
    codiceFullList: [],
    filteredCodiceListByInput: null,
    filteredNominativoListByInput: null,

    firstRowToDisplay: 0,
    viewAziendaNuovoAmm: false
  };

  constructor(props) {
    super(props);

    this.handleTableClick = this.handleTableClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.btnSearchRef = React.createRef();
    this.RedirectBtn = this.RedirectBtn.bind(this);
    this.getFilteredRows = this.getFilteredRows.bind(this);
    this.DeletePulitoriGridTemplate =
      this.DeletePulitoriGridTemplate.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.DeleteRecord = this.DeleteRecord.bind(this);
    this.searchCf = this.searchCf.bind(this);
    this.searchNominativo = this.searchNominativo.bind(this);
    this.RefreshGrid = this.RefreshGrid.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
  }

  searchNominativo(event) {
    setTimeout(() => {
      this.setState({
        filteredNominativoListByInput: helpers.FilteredListForAutocomplete(
          event,
          this.state.nominativoFullList
        )
      });
    }, 250);
  }

  searchCf(event) {
    setTimeout(() => {
      this.setState({
        filteredCodiceListByInput: helpers.FilteredCfList(
          event,
          this.state.codiceFullList
        )
      });
    }, 250);
  }

  async onNuovoClick() {
    this.setState({
      visibleLoader: true
    });

    const nuovoamministratore =
      await AmministratoriAPI.GetNuovoAmministratoriById();
    this.setState({
      selectedGridAmministratori: null,
      selectedAmministratori: nuovoamministratore,
      viewButtons: false,
      viewAziendaNuovoAmm: false
    });

    this.setState({
      visibleLoader: false
    });
  }

  getFilteredRows() {
    let filteredRows = this.state.initialRows;

    if (this.state.filterByNominativo)
      filteredRows = filteredRows.filter(
        (row) =>
          row.nominativo &&
          row.nominativo
            .toLowerCase()
            .indexOf(this.state.filterByNominativo.toString().toLowerCase()) >=
            0
      );

    if (this.state.filterByCodice)
      filteredRows = filteredRows.filter(
        (row) =>
          row.cf &&
          row.cf
            .toLowerCase()
            .indexOf(this.state.filterByCodice.toLowerCase()) >= 0
      );

    return filteredRows;
  }

  RedirectBtn(buttonName) {
    if (buttonName === "Storico")
      this.props.history.push(
        "/admin/StoricoXAmministratori/" +
          "0" +
          "/" +
          this.state.selectedAmministratori.id +
          "?type=fromAmministratori"
      );
    else if (buttonName === "Ditte")
      this.props.history.push(
        "/admin/DitteXAmministratore/" +
          "0" +
          "/" +
          this.state.selectedAmministratori.id +
          "?type=fromAmministratori"
      );
    else if (buttonName === "Corrisp")
      this.props.history.push(
        `/admin/Amministratori/${this.state.selectedAmministratori.id}/Corrispondenza`
      );
  }

  handleTableClick = async (e) => {
    this.setState({
      visibleLoader: true
    });

    const amministratore = await AmministratoriAPI.GetAmministratoriById(
      e.value.id
    );

    this.setState({
      selectedAmministratori: amministratore[0],
      selectedGridAmministratori: e.value,
      viewButtons: true,
      viewAziendaNuovoAmm: false
    });

    this.setState({
      visibleLoader: false
    });
  };

  HandleChange = async (event, val) => {
    this.setState({
      [event]: val
    });
  };

  handleSearchClick = async () => {
    helpers.SaveSearchFilters(
      {
        filterByCodice: this.state.filterByCodice,
        filterByNominativo: this.state.filterByNominativo
      },
      "AmministratoriList"
    );

    this.setState({
      selectedAmministratori: null,
      selectedGridAmministratori: null,
      filteredRows: this.getFilteredRows()
    });
  };

  async handleClearClick() {
    helpers.SaveSearchFilters(null, "AmministratoriList");
    this.setState({
      loadingGrid: true
    });
    this.setState({
      filteredRows: await AmministratoriAPI.GetAll(),
      selectedAmministratori: null,
      selectedGridAmministratori: null,
      filteredCodiceListByInput: null,
      filteredNominativoListByInput: null,
      filterByNominativo: "",
      filterByCodice: ""
    });
    this.setState({
      loadingGrid: false
    });
  }

  async onCloseGridClick() {
    this.setState({
      selectedGridAmministratori: null,
      selectedAmministratori: null
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  DeletePulitoriGridTemplate(rowData) {
    if (rowData.utente !== "Sistema")
      return (
        <Button
          round
          color="danger"
          className="actionButton actionButtonRound btnRound float-right"
          key={rowData.Id}
          onClick={(e) => this.onDeleteClick(e, rowData.id)}
        >
          <Close className="icon" />
        </Button>
      );
  }

  onDeleteClick(event, id) {
    const recordId = id;
    event.stopPropagation();

    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => this.DeleteRecord(recordId),
      reject: () => {}
    });
  }

  async DeleteRecord(id) {
    let response;

    response = await AmministratoriAPI.DeleteAmministratori(id);

    if (response.result === null) {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("warning", response.message);
      }, 1200);
    } else {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("success", "Dati eliminati.");
      }, 1200);
    }

    this.setState({
      loadingGrid: true,
      selectedAmministratori: null
    });

    const initialRows = await AmministratoriAPI.GetAll();

    const mycflist = await AmministratoriAPI.GetAllCf(this.state.filterByCodice);
    let mynominativolist = [];

    if (initialRows.length > 0) {
      initialRows.filter((val) => {
        return (
          val?.nominativo?.length > 0 && mynominativolist.push(val.nominativo)
        );
      });
    }

    let filterByCodice = this.state.filterByCodice;
    let filterByNominativo = this.state.filterByNominativo;

    const queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      const savedFilters = helpers.GetSearchFilters("AmministratoriList");
      if (savedFilters !== null) {
        filterByCodice = savedFilters.filterByCodice;
        filterByNominativo = savedFilters.filterByNominativo;
      }
    } else helpers.SaveSearchFilters(null, "AmministratoriList");

    this.setState(
      {
        initialRows: initialRows,
        filterByCodice: filterByCodice,
        filterByNominativo: filterByNominativo,

        codiceFullList: mycflist.length > 0 ? mycflist : [],
        nominativoFullList: mynominativolist.length > 0 ? mynominativolist : []
      },
      async () => {
        let filteredRows = this.getFilteredRows();

        let selectedRow = null;
        let firstRowToDisplay = 0;

        const amministratoreId = this.props.match.params.amministratoreId;

        if (amministratoreId > 0) {
          selectedRow = helpers.ReturnRowFromListById(
            filteredRows,
            amministratoreId
          );
          firstRowToDisplay = helpers.GetFirstElementOfPageIncludingId(
            amministratoreId,
            filteredRows,
            10
          );
        }

        this.setState({
          firstRowToDisplay: firstRowToDisplay,
          filteredRows: filteredRows
        });

        if (selectedRow)
          await this.handleTableClick({
            value: selectedRow
          });
      }
    );

    this.setState({
      loadingGrid: false
    });
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });

    const initialRows = await AmministratoriAPI.GetAll();

    const mycflist = await AmministratoriAPI.GetAllCf(this.state.filterByCodice);
    let mynominativolist = [];

    if (initialRows.length > 0) {
      initialRows.filter((val) => {
        return (
          val?.nominativo?.length > 0 && mynominativolist.push(val.nominativo)
        );
      });
    }

    let filterByCodice = this.state.filterByCodice;
    let filterByNominativo = this.state.filterByNominativo;

    const queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      const savedFilters = helpers.GetSearchFilters("AmministratoriList");
      if (savedFilters !== null) {
        filterByCodice = savedFilters.filterByCodice;
        filterByNominativo = savedFilters.filterByNominativo;
      }
    } else helpers.SaveSearchFilters(null, "AmministratoriList");

    this.setState(
      {
        initialRows: initialRows,
        filterByCodice: filterByCodice,
        filterByNominativo: filterByNominativo,

        codiceFullList: mycflist.length > 0 ? mycflist : [],
        nominativoFullList: mynominativolist.length > 0 ? mynominativolist : []
      },
      async () => {
        let filteredRows = this.getFilteredRows();

        let selectedRow = null;
        let firstRowToDisplay = 0;

        const amministratoreId = parseInt(
          this.props.match.params.amministratoreId
        );

        if (amministratoreId > 0) {
          selectedRow = helpers.ReturnRowFromListById(
            filteredRows,
            amministratoreId
          );
          firstRowToDisplay = helpers.GetFirstElementOfPageIncludingId(
            amministratoreId,
            filteredRows,
            10
          );
        }

        this.setState({
          firstRowToDisplay: firstRowToDisplay,
          filteredRows: filteredRows
        });

        if (selectedRow)
          await this.handleTableClick({
            value: selectedRow
          });
      }
    );

    this.setState({
      loadingGrid: false
    });
  }

  HandleSaveClick = async (RowItem) => {
    const { profile } = this.context;
    const { username } = profile;
    const res = await UtentiAPI.getUserByUsername(username);
    const user = res[0].utente;
    if (RowItem.id === null) {
      const amministratoreNuovo = await AmministratoriAPI.AddAmministratori(
        RowItem,
        user
      );
      this.state.selectedAmministratori = amministratoreNuovo.result[0];
      this.state.viewAziendaNuovoAmm = true;
    } else {
      await AmministratoriAPI.UpdateAmministratori(RowItem, user);
    }

    this.props.addAlert.current.addAlert("success", "Dati salvati.");

    const initialRows = await AmministratoriAPI.GetAll();

    const mycflist = await AmministratoriAPI.GetAllCf(this.state.filterByCodice);
    let mynominativolist = [];

    if (initialRows.length > 0) {
      initialRows.filter((val) => {
        return (
          val?.nominativo?.length > 0 && mynominativolist.push(val.nominativo)
        );
      });
    }

    let filterByCodice = this.state.filterByCodice;
    let filterByNominativo = this.state.filterByNominativo;

    const queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      const savedFilters = helpers.GetSearchFilters("AmministratoriList");
      if (savedFilters !== null) {
        filterByCodice = savedFilters.filterByCodice;
        filterByNominativo = savedFilters.filterByNominativo;
      }
    } else helpers.SaveSearchFilters(null, "AmministratoriList");

    this.setState(
      {
        initialRows: initialRows,
        filterByCodice: filterByCodice,
        filterByNominativo: filterByNominativo,

        codiceFullList: mycflist.length > 0 ? mycflist : [],
        nominativoFullList: mynominativolist.length > 0 ? mynominativolist : []
      },
      async () => {
        let filteredRows = this.getFilteredRows();

        let selectedRow = null;
        let firstRowToDisplay = 0;

        const amministratoreId = this.props.match.params.amministratoreId;

        if (amministratoreId > 0) {
          selectedRow = helpers.ReturnRowFromListById(
            filteredRows,
            amministratoreId
          );
          firstRowToDisplay = helpers.GetFirstElementOfPageIncludingId(
            amministratoreId,
            filteredRows,
            10
          );
        }

        this.setState({
          firstRowToDisplay: firstRowToDisplay,
          filteredRows: filteredRows
        });

        if (selectedRow)
          await this.handleTableClick({
            value: selectedRow
          });
      }
    );

    this.setState({
      loadingGrid: false
    });

    return true;
  };

  async RefreshGrid() {
    this.setState({
      loadingGrid: true
    });
    this.setState({
      filteredRows: await AmministratoriAPI.GetAll()
    });
    this.setState({
      loadingGrid: false
    });
  }

  ChangeButtons = async (existentAmministratore) => {
    if (existentAmministratore.tipo === "amministratore") {
      await this.handleTableClick({
        value: existentAmministratore[0]
      });
    } else if (existentAmministratore.tipo === "consulente") {
      this.setState({
        selectedGridAmministratori: null,
        selectedAmministratori: existentAmministratore,
        viewButtons: false,
        viewAziendaNuovoAmm: false
      });
    }
  };

  async backButtonClick() {
    const qS = queryString.parse(this.props.location.search);
    if (!qS.type) await this.handleClearClick();
    else if (qS.type === "fromFiltroGenerico")
      this.props.history.push("/admin/filtrogenerico?maintainSearch=1");
  }

  render() {
    return (
      <>
        {this.state.visibleLoader && <LoaderSpinner />}

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <BackButton
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />
                <h4 style={{ lineHeight: "1" }}>
                  Amministratori
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={3} style={{ marginTop: "6px" }}>
                    <AutoComplete
                      value={this.state.filterByNominativo}
                      suggestions={this.state.filteredNominativoListByInput}
                      placeholder="Cerca per Nominativo"
                      completeMethod={this.searchNominativo}
                      onChange={(e) =>
                        this.HandleChange("filterByNominativo", e.value)
                      }
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.handleSearchClick();
                        }
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3} style={{ marginTop: "6px" }}>
                    <AutoComplete
                      value={this.state.filterByCodice}
                      suggestions={this.state.filteredCodiceListByInput}
                      completeMethod={this.searchCf}
                      placeholder="Cerca per Codice"
                      onChange={(e) =>
                        this.HandleChange("filterByCodice", e.value)
                      }
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.handleSearchClick();
                        }
                      }}
                    />
                  </GridItem>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Cerca"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      ref={this.btnSearchRef}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleSearchClick}
                    >
                      <Search />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Reimposta"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{ marginLeft: "4px" }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleClearClick}
                    >
                      <Clear />
                    </Button>
                  </Tooltip>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Elenco Amministratori
                </h4>

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.filteredRows}
                  dataKey="id"
                  first={this.state.firstRowToDisplay}
                  onPage={e => this.setState({ firstRowToDisplay: e.first, rowsPerPage: e.rows })}
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage} rowsPerPageOptions={rowsPerPageOptions}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGridAmministratori}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="nominativo"
                    header="Nominativo"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                  />
                  <Column
                    field="cf"
                    header="Codice fiscale"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                  />
                  <Column
                    field="indirizzo"
                    header="Indirizzo"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                  />
                  <Column
                    field="comunex"
                    header="Comune"
                    sortable={true}
                  />
                  <Column
                    field="email"
                    header="E-mail"
                    sortable={true}
                  />
                  <Column
                    field="pec"
                    header="PEC"
                    sortable={true}
                  />
                  <Column
                    header=""
                    body={this.DeletePulitoriGridTemplate}
                    icon="pi pi-times"
                    label="Delete"
                    className="p-button-danger p-button-outlined colDelete"
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.selectedAmministratori && (
          <ModificaAmministratoriXAzienda
            dataItem={this.state.selectedAmministratori}
            onSaveClick={this.HandleSaveClick}
            history={this.props.history}
            onAnnullaClick={this.onCloseGridClick}
            addAlert={this.props.addAlert}
            ChangeButtons={this.ChangeButtons}
          >
            {this.state.viewButtons && (
              <ButtonGroup
                variant="contained"
                color="primary"
                size="small"
                aria-label="contained primary button group"
                className="float-right"
                style={{ verticalAlign: "center", margin: "0.5%" }}
              >
                <ButtonsMUI onClick={() => this.RedirectBtn("Storico")}>
                  Storico Amm.
                </ButtonsMUI>
                <ButtonsMUI onClick={() => this.RedirectBtn("Ditte")}>
                  Aziende
                </ButtonsMUI>
                <ButtonsMUI onClick={() => this.RedirectBtn("Corrisp")}>
                  Corrisp.
                </ButtonsMUI>
              </ButtonGroup>
            )}
            {this.state.viewAziendaNuovoAmm && (
              <ButtonGroup
                variant="contained"
                color="primary"
                size="small"
                aria-label="contained primary button group"
                className="float-right"
                style={{ verticalAlign: "center", margin: "0.5%" }}
              >
                <ButtonsMUI onClick={() => this.RedirectBtn("Ditte")}>
                  Aziende
                </ButtonsMUI>
              </ButtonGroup>
            )}
          </ModificaAmministratoriXAzienda>
        )}
      </>
    );
  }
}

export default Amministratori;
