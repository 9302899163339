import React, { Component } from "react";
import clone from "clone";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import LoaderSpinner from "../../../Layout/LoaderSpinner.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import { Calendar } from "primereact/calendar";
import UndoIcon from "@material-ui/icons/Undo";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import MenuItem from "@material-ui/core/MenuItem";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Close from "@material-ui/icons/Close";
import { confirmPopup } from "primereact/confirmpopup";
import { FileUpload } from "primereact/fileupload";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import helpers from "helpers/Helpers";
import AziendeAPI from "API/AziendeAPI";
import StaticLists from "helpers/StaticLists";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import { dateTimeFmt } from "helpers/AppConstants";
import TipoInvioAPI from "API/TipoInvioAPI.js";
import SedeComuneProvAPI from "API/SedeComuneProvAPI.js";
import { TestoEmail } from "../TestoEmail.component";
import { TestoLettera } from "../TestoLettera.component";
import { FormControlLabel, FormGroup, FormLabel } from "@material-ui/core";
import { Checkbox } from "primereact/checkbox";
import { AuthContext } from "providers";
import UtentiAPI from "API/Utenti.api.js";
import EmailUtenteAPI from "API/EmailUtenteAPI.js";
import { InputTextarea } from "primereact/inputtextarea";


export class ModificaCorrispondenzaAmministratore extends Component {
  static contextType = AuthContext;

  state = {
    RowItem: null,
    ModificaTitle: "Nuovo",
    groupClass: "",
    deletecolumns: [],
    downloadcolumns: [],
    tipoinvioList: [],
    Protocollo_List: [],
    Causale_List: [],
    W350_List: [],
    W300_List: [],
    Amm_List: [],
    Buchi_List: [],
    Cust_List: [],
    Puli_List: [],
    selectedTipo: 2,
    selectedW350: null,
    selectedW300: null,
    selectedAmm: null,
    selectedBuchi: null,
    selectedCust: null,
    selectedPuli: null,
    selectedProt: null,
    selectedCaus: null,
    isVisible: false,
    loadingGrid: false,
    files: [],
    isValid: true,
    altroDest: "",

    INPS_List: [],
    selectedSap: null,
    selectedINPS: null,

    senderEmail: "",
    emailText: "",
    showEmailText: false,
    showLetterText: false,
    showAdm: false,
    showCompany: false,
    showCons: false,
    showDip: false,
    lastInsertedProt: "",

    aziendasList: [],
    aziendaFilter: "",

    cons: null,
    dips: null,
    adms: null,
    selectedAdm: null,
    selectedCons: null,
    selectedDip: null,

    //VALIDATIONS
    fieldValidations: {},
    validationRules: {
      RagioneSociale: "",
      Matricola: ""
    },
    listaSap: [],
    destMail: null
  };

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.handleSenderEmail = this.handleSenderEmail.bind(this);
    this.handleRcipientEmail = this.handleRcipientEmail.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.DeleteAttachment = this.DeleteAttachment.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.DownloadAttachmment = this.DownloadAttachmment.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.handleTextEmail = this.handleTextEmail.bind(this);
    this.searchMatricola = this.searchMatricola.bind(this);
    this.searchSap = this.searchSap.bind(this);
    this.monthNavigatorTemplate = this.monthNavigatorTemplate.bind(this);
    this.yearNavigatorTemplate = this.yearNavigatorTemplate.bind(this);
    this.handleChangeTipoInvio = this.handleChangeTipoInvio.bind(this);
    this.handleChangeCausale = this.handleChangeCausale.bind(this);
    this.canSendEmail = this.canSendEmail.bind(this);
    this.refreshAttachmentTable = this.refreshAttachmentTable.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.getLastProt = this.getLastProt.bind(this);
    this.buildOggetto = this.buildOggetto.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.componentRef = React.createRef();
  }

  async handleTableClick(rowData) {
    const _rowData = rowData?.value;
    const paths = _rowData?.path?.split(".");
    const extension = paths[paths.length - 1];
    if (extension === "pdf") {
      const _data = _rowData.path?.split(".");
      this.state?.user?.descrizione_ufficio === 'EBINPROF' ?
      await CorrispondenzaAPI.openPdfEbin(_rowData?.id, _data[_data.length - 1]) :
      await CorrispondenzaAPI.openPdf(_rowData?.id, _data[_data.length - 1]);
    }
  }

  monthNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }

  yearNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }

  async refreshAttachmentTable() {
    this.setState({ loadingGrid: true }, async () =>
      this.setState(
        {
          attachments:
            await CorrispondenzaAPI.GetCorrispondenzaPathAttachFromUfficio(
              this.state.user?.descrizione_ufficio,
              this.props.dataItem?.id
            )
        },
        () => this.setState({ loadingGrid: false })
      )
    );
  }

  async onUpload(event) {
    this.setState({ files: event.files });
    this.props.addAlert.current.addAlert(
      "success",
      "File pronti al caricamento"
    );
  }

  sedeInpsTemplate(sede) {
    return <div>{sede.sap ? `${sede.sap} - ${sede.descrizione}` : ``}</div>;
  }

  sedeInpsValueTemplate(sede, props) {
    if (sede) return `${sede.sap} - ${sede.descrizione}`;
  }

  filterINPSBySap(sap) {
    this.setState({
      selectedINPS: this.state.INPS_List.find((sede) => sede.sap === sap)
    });
  }

  DeleteAttachment(rowData) {
    return (
      <Button
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData?.id}
        onClick={(event) => this.onDeleteClick(event, rowData?.id)}
      >
        <Close className="icon" />
      </Button>
    );
  }

  DownloadAttachmment(rowData) {
    const paths = rowData?.path?.split(".");
    const extension = paths[paths.length - 1];
    return (
      <Button
        round
        color="primary"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData?.id}
        onClick={(event) => this.onDownloadClick(event, rowData?.id, extension)}
      >
        <GetAppRoundedIcon className="icon" />
      </Button>
    );
  }

  async handleDeleteAttachment(id) {
    const response = this.state?.user?.descrizione_ufficio === 'EBINPROF' ?
    await CorrispondenzaAPI.DeleteCorrispondenzaEbinAttachment(id) :
      await CorrispondenzaAPI.DeleteCorrispondenzaAttachment(id);
    if (response && response.result) {
      this.props.addAlert.current.addAlert("success", "Allegato eliminato");
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "Eliminazione non riuscita"
      );
    }
    await this.refreshAttachmentTable();
  }

  async handleDownloadAttachment(id, extension) {
    const response = this.state?.user?.descrizione_ufficio === 'EBINPROF' ? 
      await CorrispondenzaAPI.downloadCorrespondenceAttachmentEbin(id, extension) :
      await CorrispondenzaAPI.DownloadCorrispondenzaAttachment(id, extension);
    await this.refreshAttachmentTable();
  }

  onDeleteClick(event, id) {
    event.preventDefault();
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        this.handleDeleteAttachment(id);
      },
      reject: () => {}
    });
  }

  onDownloadClick(event, id, extension) {
    event.preventDefault();
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi scaricarlo?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        this.handleDownloadAttachment(id, extension);
      },
      reject: () => {}
    });
  }

  isValidForm = async () => {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);

    this.setState({
      fieldValidations: fieldValidations
    });

    return true;
  };

  HandleChange(field, newValue) {
    var newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;
    this.setState({ RowItem: newRowItem });
  }

  HandleSaveClick = async () => {
    
    let corr = this.state.RowItem;

    //if(!this.state.RowItem?.id || this.state.RowItem?.id == 0 || this.state.RowItem?.id == '0'){
      corr.tipoinvio = this.state.selectedTipo;
    //}

    if (!corr.data) {
      corr.data = moment(new Date()).format(dateTimeFmt);
    } else if (corr.data instanceof Date) {
      corr.data = moment(corr.data).format(dateTimeFmt);
    }

    if (this.isValidForm()) {
      let files = [...this.state.files];
      if (this.state.emailText) {
        corr.testomail = this.state.emailText;
      }
      const _corr = {
        ...corr,
        sede_inps: !!this.state.RowItem?.prot ? this.state.RowItem?.sede_inps : this.state.selSap?.sap,
        altroDest: this.state.altroDest 
      };
      const isEdit = this.state.ModificaTitle === "Modifica";
      let sd = await this.props.onSaveClick(
        _corr,
        files,
        isEdit,
        this.state?.senderEmail?.toLowerCase(),
        this.props.administrator?.email?.toLowerCase()
      );
      if (sd) {
        let lastProt = await CorrispondenzaAPI.GetLastInsertedProtocol(
          new Date().getFullYear(),
          this.props.type
        );
        this.setState({
          lastInsertedProt: lastProt.result[0]
        });
      }
      return sd;
      
    }
    
  };

  removeFile(event) {
    if (this.state.files) {
      const files = this.state.files?.filter((file) => file !== event.file);
      this.setState({ files: files });
    }
  }

  async componentDidUpdate(prevProp, prevState, snapshot) {
    if (prevProp.dataItem?.id !== this.props.dataItem?.id) {
      this.setState({ loadingGrid: true });
      const isChanging = this.props.dataItem?.id > 0;
      this.setState({
        ModificaTitle: isChanging ? "Modifica" : "Inserisci",
        RowItem: {...this.props.dataItem},
        selectedCaus: this.props.dataItem.causale?.codice,
        selectedTipo: this.props.dataItem.tipoinvio?.id,
        attachments: isChanging
          ? await CorrispondenzaAPI.GetCorrispondenzaPathAttachFromUfficio(
              this.state.user?.descrizione_ufficio,
              this.props.dataItem?.id
            )
          : null
      });
      const type = this.props.type;
      const sendType = this.props.dataItem?.tipoinvio?.id;

      const disableAll = this.state.RowItem?.tipo?.toUpperCase() === "USCITA" && (sendType === 2 || sendType === 3); //Uscita
      this.setState({disableAll })
      this.setState({
        showEmailText: this.state.RowItem.tipo?.toUpperCase() === "USCITA" &&  this.state.selectedTipo === 2,
        showLetterText: this.state.RowItem.tipo?.toUpperCase() === "USCITA" && this.state.selectedTipo === 1,
        emailText: this.state.RowItem?.testoMail
      });

      if(isChanging){
        this.setState({
          destMail: this.props.dataItem?.destmail?.split(",")
        });
      }

      this.setState({ loadingGrid: false });
    }
  }

  filterPersonByCF(people, cf) {
    return people?.filter((p) => p.cf.toUpperCase() === cf.toUpperCase());
  }

  canSendEmail() {
    return (
      (this.state.azienda?.email ||
        this.state.selectedAdm?.email ||
        this.state.selectedCons?.email ||
        this.state.selectedDip?.email) !== undefined
    );
  }

  handleChangeTipoInvio(e) {
    this.HandleChange("tipoinvio", e.target.value);
    if (e.target.value === 2) {
      this.HandleChange("mittmail", null);
      this.setState({
        showEmailText: true,
        showLetterText: false
      });
    } else if (e.target.value === 1)
      this.setState({
        showEmailText: false,
        showLetterText: true
      });
    else
      this.setState({
        showEmailText: false,
        showLetterText: false
      });
    this.setState({ selectedTipo: e.target.value });
    this.props.onChangeTipoInvio(e.target.value);
  }

  buildOggetto(causObj) {
    const prot = this.state.lastInsertedProt + 1;
    const year = this.props.dataItem.anno;
    const type = this.state.RowItem.tipo[0];
    const matr = this.state.azienda?.matricola;
    const name = this.state.azienda?.denominazione;

    const causSub = causObj.oggetto
      .replace("(nome ragione sociale)", name)
      .replace("(matricola INPS)", matr);

    return `${causSub}`;
  }

  handleChangeCausale(e) {
    this.HandleChange("causale", e.target.value);
    if (this.props.type !== "Entrata") {
      const causObj = this.state.Causale_List.find(
        (item) => item.codice === e.target.value
      );
      if (
        causObj &&
        causObj.oggetto &&
        this.state.RowItem.tipo?.toUpperCase() === "USCITA"
      ) {
        this.HandleChange("oggetto", this.buildOggetto(causObj));
      }
      if (this.state.user.descrizione_ufficio !== "EBINPROF")
        this.handleTextEmail(
          `Buongiorno,\n ${causObj.testomail || ""}\n--\nFondo Coasco`
        );
      this.setState({
        selectedCaus: e.target.value,
        emailText:
          this.state.user.descrizione_ufficio !== "EBINPROF"
            ? `Buongiorno,\n ${causObj.testomail || ""}\n--\nFondo Coasco`
            : ""
      });
    } else
      this.setState({
        selectedCaus: e.target.value
      });
  }

  async getLastProt(corr) {
    const year = new Date().getFullYear();
    const type = corr.tipo?.toUpperCase() || "USCITA";
    const lastProt = await CorrispondenzaAPI.GetLastInsertedProtocol(year, type)
      .then((response) => response?.result?.pop())
      .catch((error) => {
        console.error(error);
        this.props.addAlert.current.addAlert(
          "danger",
          "Si è verificato un errore nel recupero numero protocollo inserito"
        );
      });
    return lastProt;
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });

    // Get the administrator Companies
    const administratorId = this.props.administratorId;
    const administratorCompaniesRes =
      await AziendeAPI.getCompaniesByAdministratorId(administratorId);
    const administratorCompanies = [];
    const res = administratorCompaniesRes?.result?.map((x) => {
      return administratorCompanies.push({
        ...x.azienda,
        value: x?.azienda?.matricola,
        label: x?.azienda?.matricola
      });
    });
    this.setState({
      administratorCompanies
    });

    

    const allProvince = await SedeComuneProvAPI.GetAllProvincieProvincie();
    const allSedi = await SedeComuneProvAPI.GetAllSede();

    const isChanging = this.props.dataItem?.id > 0;
    const lastProt = this.props.dataItem?.id
      ? await this.getLastProt(this.props.dataItem)
      : 0;

    const { profile } = this.context;

    const userRes = await UtentiAPI.getUserByUsername(profile?.username);

    const rowItem = this.state.RowItem;

    this.setState({
      user: userRes[0],
      RowItem: {
        ...rowItem
      }
    });

    const userEmails = await EmailUtenteAPI.GetByUtente(userRes[0]?.utente);
    this.setState({
      userEmails
    });

    const sendTypeRes = await TipoInvioAPI.GetAll();

    const administratorEmails = [];
    this.props.administrator?.email &&
      administratorEmails.push({
        key: this.props.administrator?.email,
        value: this.props.administrator?.email
      });
    this.props.administrator?.email2 &&
      administratorEmails.push({
        key: this.props.administrator?.email2,
        value: this.props.administrator?.email2
      });
    this.setState({
      administratorEmails
    });

    this.setState({
      deletecolumns: [
        {
          header: "",
          body: this.DeleteAttachment,
          icon: "pi pi-times",
          label: "Delete",
          className: "p-button-danger p-button-outlined colDelete"
        }
      ],
      downloadcolumns: [
        {
          header: "",
          body: this.DownloadAttachmment,
          icon: "pi pi-times",
          label: "Download",
          className: "p-button-danger p-button-outlined colDownload"
        }
      ],
      tipoinvioList: sendTypeRes,
      RowItem: { ...this.props.dataItem, user: userRes[0] },

      lastInsertedProt: lastProt,
      selectedTipo: isChanging ? this.props.dataItem?.tipoinvio?.id : 2,
      selectedCaus: isChanging ? this.props.dataItem.causale?.codice : "",
      aziendaFilter: this.state.azienda?.matricola,
      azienda: this.state.azienda,
      provices: allProvince,
      Protocollo_List: await StaticLists.Corrisp_TipoProtocollo(),
      Causale_List: await CorrispondenzaAPI.getCausaliFromTipoUfficio(
        userRes[0]
      ),
      W350_List: await StaticLists.Corrisp_W350(),
      W300_List: await StaticLists.Corrisp_W300(),
      Amm_List: await StaticLists.Corrisp_Amm(),
      INPS_List: allSedi,
      isVisible: isChanging,
      Buchi_List: await StaticLists.Corrisp_Buchi(),
      Cust_List: await StaticLists.Corrisp_Cust(),
      Puli_List: await StaticLists.Corrisp_Puli(),
      ModificaTitle: isChanging ? "Modifica" : "Nuovo",
      groupClass: isChanging ? "" : "hide",

      attachments: isChanging
        ? await CorrispondenzaAPI.GetCorrispondenzaPathAttachFromUfficio(
            this.state.user?.descrizione_ufficio,
            this.props.dataItem?.id
          )
        : null,

      cons: this.props.people?.cons,
      adms: this.props.people?.adms,
      dips: this.props.people?.dips,

      showCompany: this.props.dataItem.cfamm !== null,
      // showAdm: this.props.dataItem.cfamm !== null,
      showCons: this.props.dataItem.cfcons !== null,
      showDip: this.props.dataItem.cfdip !== null
    });

    if(isChanging){
      this.setState({
        destMail: this.props.dataItem?.destmail?.split(",")
      });
    }
    
    // Get the Company
    if (this.props.dataItem?.azienda) {
      const companyRes = await AziendeAPI.GetAziendaByMatr(
        this.props.dataItem.azienda
      );
      const rowItem = this.state.RowItem;
      this.setState({
        RowItem: {
          ...rowItem,
          mitt_dest: companyRes.denominazione,
          denominazione: companyRes?.denominazione,
          sap_matri: companyRes?.sede,
          prov: companyRes?.prov
        }
      });
    }

    this.setState({
      showEmailText: this.state.selectedTipo === 2
    })

    const type = this.props.type;
    const sendType = this.props.dataItem?.tipoinvio?.id;
    const disableAll = this.state.RowItem?.tipo?.toUpperCase() === "USCITA" && (sendType === 2 || sendType === 3); //Uscita
    this.setState({ disableAll})
    this.setState({
      showLetterText: this.state.RowItem.tipo?.toUpperCase() === "USCITA" && this.state.selectedTipo === 1,
      emailText: this.state.RowItem?.testoMail
    });
    
    this.setState({
      loadingGrid: false
    });

    try {
      this.componentRef.current.scrollIntoView();
    } catch(err){}
  }

  handleSenderEmail(account) {
    this.HandleChange("mittmail", account);
    this.setState({
      senderEmail: account
    });
  }

  handleRcipientEmail(account) {
    this.HandleChange("mitt_dest", account);
    this.setState({
      recipientEmail: account
    });
  }

  handleTextEmail(text) {
    this.HandleChange("testomail", text);
    this.setState({
      emailText: text
    });
  }

  SetNum(field, newValue, maxLength) {
    if (newValue.toString().length <= maxLength) {
      var newRowItem = this.state.RowItem;
      newRowItem[field] = newValue;

      this.setState({ RowItem: newRowItem });
    }
    alert(this.state.selectedAmm)
  }

  async onSelectMatricola(e) {
    const rowItem = this.state.RowItem;
    

    if (!e?.value){
      this.setState({
        RowItem: {
          ...rowItem,
          mitt_dest: null,          
          sap_matri: null,
          prov: null,
          azienda : null
        },
        azienda : null,       
      })
    }else{
      const company = await AziendeAPI.GetAziendaByMatr(e.value);
      this.HandleChange("mitt_dest", company?.denominazione);

      try {
        this.setState({
          // aziendaFilter: e.value,
          companyEmail: company?.email,
          azienda: company,
          // aziendaFilter: company?.matricola,
          selectedINPS: null,
          selectedSap: "",
          RowItem: {
            ...rowItem,
            denominazione: company?.denominazione,
            company
          }
        });
        this.HandleChange("azienda", company?.matricola);
      } catch (err) {
        this.props.addAlert.current.addAlert(
          "danger",
          "Si è verificato un errore sul server"
        );
      }
    }
  }

  handleDropdownChange(value, propLabel) {
    this.HandleChange(propLabel, value.toUpperCase());
    if (propLabel === "cfamm") {
      const administrator = this.state.administrators.find(
        (adm) => adm?.amministratore?.cf === value
      );
      this.setState({
        selectedAdm: administrator?.amministratore
      });
    }
  }

  async OnInputBlurCheck(valToCheck, type) {
    let validationRules = null;

    type === "matricola"
      ? (validationRules = {
          matricola: "EQ-10"
        })
      : (validationRules = {
          iban: "EQ-27"
        });
    let fieldValidations = helpers.ValidateForm(
      this.state.RowItem,
      validationRules
    );

    this.setState({
      fieldValidations: fieldValidations
    });

    let existentMatricola = [];
    if (
      this.state.RowItem?.matricola?.length > 0 &&
      this.props.dataItem?.id === null &&
      type === "matricola"
    ) {
      existentMatricola = await AziendeAPI.GetAllMatricola(
        this.state.RowItem?.matricola
      );
      if (existentMatricola.length > 0) {
        this.props.addAlert.current.addAlert("warning", "Matricola esistente");
      }
    }
  }

  searchMatricola(event) {
    setTimeout(async () => {
      let list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByMatr(event.query)
          : [];
      this.setState({
        aziendasList: list
      });
    }, 250);
  }

  searchSap(event) {
    setTimeout(async () => {
      var list =
        event.query.trim().length >= 3
          ? await SedeComuneProvAPI.searchBySapFull(event.query)
          : [];
      let saplist = list.map((item) => item.sap);
      this.setState({
        sapList: saplist,
        listaSap: list
      });
    }, 250);
  }

  render() {
    const dynamicDeleteColumns = this.state.deletecolumns?.map((col) => {
      return (
        <Column
          key={col.body}
          body={col.body}
          icon={col.icon}
          label={col.label}
          className={col.className}
          header={col.header}
        />
      );
    });

    const dynamicDownloadColumns = this.state.downloadcolumns?.map((col) => {
      return (
        <Column
          key={col.body}
          body={col.body}
          icon={col.icon}
          label={col.label}
          className={col.className}
          header={col.header}
        />
      );
    });

    const destinatariEmail = 
    <div>
      <br/>
      <label>Destinatari Email</label>
      <GridContainer>
      {this.state.destMail?.map(el => 
          <GridItem xs={12} sm={12} md={4} style={{ marginTop: "-1vh" }}>
            <CustomInput id={el} formControlProps={{fullWidth: true}} inputProps={{disabled: true, value:el}}/>
          </GridItem>
      )}
      </GridContainer>
    </div>;

    if (this.state.RowItem === null)
      return <LoaderSpinner fullHeight={false} />;
    else
      return (
        <div ref={this.componentRef}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.state.ModificaTitle} Protocollo
                  </h4>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Annulla"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.props.onAnnullaClick}
                    >
                      <UndoIcon />
                    </Button>
                  </Tooltip>
                  {this.props.children}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Anno"
                        id="anno"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disableAll || true,
                          value:
                            this.state.RowItem.anno || new Date().getFullYear()
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "4px" }}
                    >
                      <p
                        className="calendarParagraph"
                        style={{
                          color: "#565656",
                          position: "relative",
                          top: "5px"
                        }}
                      >
                        <small>Data Protocollo</small>
                      </p>
                      <Calendar
                        className="calendarCustomedForMUI"
                        id="dataprot"
                        view="date"
                        value={
                          moment(
                            this.state.RowItem.data,
                            dateTimeFmt
                          ).toDate() || new Date()
                        }
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          this.HandleChange(
                            "data",
                            moment(e.target.value).format(dateTimeFmt)
                          )
                        }
                        disabled={true}
                        style={{ background: "#eaea7b", color: "#000000" }}
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Protocollo"
                        id="prot"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.prot || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Tipo Protocollo"
                        id="prot"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.props.type
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText="Id"
                        id="id"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem?.id || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    {!this.state.disableAll ? (
                      <GridItem
                        xs={12}
                        sm={12}
                        md={3}
                        style={{ marginTop: "4px" }}
                      >
                        <p
                          className="lblNoMargin"
                          style={{ position: "relative", top: "4px" }}
                        >
                          <small>Matricola</small>
                        </p>
                        <Dropdown
                          className="dropdownCustomisedForMUI"
                          value={this.state.RowItem?.azienda}
                          options={this.state?.administratorCompanies}
                          onChange={(event) => {
                            this.setState({ aziendaFilter: event.value });
                            this.onSelectMatricola(event);
                          }}
                          showClear = {true}
                        />
                      </GridItem>
                    ) : (
                      <GridItem
                        xs={12}
                        sm={12}
                        md={3}
                        style={{ marginTop: "4px" }}
                      >
                        <CustomInput
                          labelText="Matricola"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.RowItem.azienda || ""
                          }}
                        />
                      </GridItem>
                    )}
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Ragione Sociale"
                        id="ragione"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.HandleChange("mitt_dest", event.target.value),
                          disabled: true,
                          value : this.state.RowItem?.mitt_dest || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText="SAP Matri."
                        id="sap_matri"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.HandleChange("sap_matri", event.target.value),
                        value: this.state.azienda?.sede ||
                            this.state.RowItem?.sap_matri?.sap ||  
                            this.state.RowItem?.sap_matri || "",
                        disabled: true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText="Provincia"
                        id="Prov"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.HandleChange("prov", event.target.value),
                          value:
                            this.state.azienda?.prov ||
                            this.state.RowItem.prov ||
                            "",
                          disabled: true
                        }}
                      />
                    </GridItem>
                    {this.state.RowItem.mittmail && this.state.selectedTipo == 1 &&(
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Mittente"
                          id="mitt"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.RowItem.mittmail || ""
                          }}
                        />
                      </GridItem>
                    )}
                  </GridContainer>
                  {/* {this.props.type !== "Uscita" &&
                    this.state.ModificaTitle === "Nuovo" && ( */}
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            id="admName"
                            labelText="Codice Fiscale Amministratore"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: this.state.disableAll || this.state.RowItem?.prot,
                              value: this.props?.administrator?.cf || ""
                            }}
                          />
                        </GridItem>
                        
                        <GridItem xs={12} sm={12} md={4}>
                          <CustomInput
                            id="admName"
                            labelText="Nominativo Amministratore"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: this.state.disableAll || this.state.RowItem?.prot,
                              value: this.props?.administrator?.nominativo || ""
                            }}
                          />
                        </GridItem>
                        {this.state.selectedTipo == 2 && this.props.type == "USCITA" &&(
                            <GridItem xs={12} sm={12} md={4}>
                              <FormLabel>Indirizzata a</FormLabel>
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox color="primary" checked={true} />
                                  }
                                  label="Amministratore"
                                  className="labelCheckbox"
                                />
                              </FormGroup>
                            </GridItem>
                          )}
                        {!this.props.dataItem?.tipoinvio?.id === 1 && this.state.administratorEmails && this.state.administratorEmails.length > 0 && (
                            <GridItem xs={12} sm={12} md={4} className="select">
                              <CustomSelect
                                labelText="Email"
                                formControlProps={{
                                  fullWidth: true
                                }}
                                inputProps={{
                                  disabled: this.state.disableAll,
                                  onChange: (e) => {
                                    this.HandleChange(
                                      "administratorEmail",
                                      e.target.value
                                    );
                                    const rowItem = this.state.RowItem;
                                    this.setState({
                                      RowItem: {
                                        ...rowItem,
                                        administratorEmail: e.target.value
                                      }
                                    });
                                  },
                                  value:
                                    this.state.RowItem?.administratorEmail || ""
                                }}
                              >
                                {this.state.administratorEmails?.map(
                                  (email) => (
                                    <MenuItem
                                      value={email.value}
                                      key={email.key}
                                    >
                                      {email?.value}
                                    </MenuItem>
                                  )
                                )}
                              </CustomSelect>
                            </GridItem>
                          )}
                      </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6} className="select">
                      <CustomSelect
                        disabled={
                          this.state.disableAll
                        }
                        labelText="Causale"
                        id="caus"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: this.handleChangeCausale,
                          value: this.state.selectedCaus || ""
                        }}
                      >
                        {this.state.Causale_List?.map((item) => (
                          <MenuItem value={item.codice} key={item.codice}>
                            {item.codice + " - " + item.descrizione}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                    {this.state.selectedTipo == 1 && (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "4px" }}
                    >
                      <p
                        className="calendarParagraph"
                        style={{
                          color: "#565656",
                          position: "relative",
                          top: "5px"
                        }}
                      >
                        <small>Data Lettera</small>
                      </p>
                      <Calendar
                        className="calendarCustomedForMUI"
                        id="dataconst"
                        view="date"
                        value={moment(
                          this.state.RowItem.dtlett,
                          dateTimeFmt
                        ).toDate()}
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          this.HandleChange(
                            "dtlett",
                            moment(e.target.value).format(dateTimeFmt)
                          )
                        }
                        style={{
                          background:
                            !this.state.disableAll
                              ? "#ffffff"
                              : "#eaea7b",
                          color: "#000000"
                        }}
                        disabled={
                          this.state.disableAll
                        }
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                      />
                    </GridItem>
                    )}
                    <GridItem xs={12} sm={12} md={3} className="select">
                      <CustomSelect
                        labelText="Tipo Invio"
                        id="tipoinvioList"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled:this.state.disableAll,
                          onChange: this.handleChangeTipoInvio,
                          value: this.state.selectedTipo
                        }}
                      >
                        {this.state?.tipoinvioList?.map((item) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item?.id} - {item?.tipoinvio}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={10}>
                      <CustomInput
                        labelText="Oggetto"
                        id="ogg"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.HandleChange("oggetto", event.target.value),
                          value: this.state.RowItem.oggetto || "",
                          disabled:
                            this.state.disableAll
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  {this.state.destMail && destinatariEmail}

                  {this.state.user?.descrizione_ufficio.toUpperCase() !==
                    "EBINPROF" && (
                    <>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                          <CustomInput
                            labelText="Tabulati"
                            id="tab"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled : this.state.disableAll,
                              type: "number",
                              onChange: (event) =>
                                this.HandleChange("numtab", event.target.value),
                              value: this.state.RowItem.numtab || ""
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2}>
                          <CustomInput
                            labelText="Preavvisi"
                            id="pre"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled : this.state.disableAll,
                              type: "number",
                              onChange: (event) =>
                                this.HandleChange(
                                  "numpreav",
                                  event.target.value
                                ),
                              value: this.state.RowItem.numpreav || ""
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} className="select">
                          <CustomSelect
                            labelText="W300"
                            id="w300"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled : this.state.disableAll,
                              onChange: (event) =>
                                this.HandleChange("w300", event.target.value),
                              value: this.state.RowItem.w300 || ""
                            }}
                          >
                            {this.state.W300_List?.map((item, i) => (
                              <MenuItem value={item.id} key={i}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} className="select">
                          <CustomSelect
                            labelText="Puli"
                            id="Puli"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled : this.state.disableAll,
                              onChange: (event) =>
                                this.HandleChange("puli", event.target.value),
                              value: this.state.RowItem.puli || ""
                            }}
                          >
                            {this.state.Puli_List?.map((item) => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} className="select">
                          <CustomSelect
                            labelText="Amm"
                            id="Amm"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled : this.state.disableAll,
                              onChange: (event) =>
                                this.HandleChange("amm", event.target.value),
                                value: this.state.RowItem.amm || ""
                            }}
                          >
                            {this.state.Amm_List?.map((item) => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                          <CustomInput
                            labelText="Fatture"
                            id="fa"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled : this.state.disableAll,
                              type: "number",
                              onChange: (event) =>
                                this.HandleChange("numfat", event.target.value),
                              value: this.state.RowItem.numfat || ""
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2}>
                          <CustomInput
                            labelText="Contabili"
                            id="co"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled : this.state.disableAll,
                              type: "number",
                              onChange: (event) =>
                                this.HandleChange(
                                  "ncontabbanc",
                                  event.target.value
                                ),
                              value: this.state.RowItem.ncontabbanc || ""
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} className="select">
                          <CustomSelect
                            labelText="W350"
                            id="W350"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled : this.state.disableAll,
                              onChange: (e) =>
                                this.HandleChange("w350", e.target.value),
                              value: this.state.RowItem.w350 || ""
                            }}
                          >
                            {this.state.W350_List?.map((item) => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} className="select">
                          <CustomSelect
                            labelText="Cust"
                            id="Cust"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled : this.state.disableAll,
                              onChange: (e) =>
                                this.HandleChange("cust", e.target.value),
                              value: this.state.RowItem.cust || ""
                            }}
                          >
                            {this.state.Cust_List?.map((item) => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} className="select">
                          <CustomSelect
                            labelText="Pr. Buchi Contr."
                            id="Buchi"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled : this.state.disableAll,
                              onChange: (e) =>
                                this.HandleChange(
                                  "prbuchicont",
                                  e.target.value
                                ),
                              value: this.state.RowItem.prbuchicont || ""
                            }}
                            
                          >
                            {this.state.Buchi_List?.map((item) => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </GridItem>
                      </GridContainer>
                    </>
                  )}
                   {this.state.ModificaTitle !== "Nuovo" && this.props?.type === "USCITA" 
                    && this.state.RowItem?.tipoinvio?.id === 2 && (
                      <GridContainer style={{ marginBottom: "2vh", marginTop : "2vh" }}>
                      <GridItem xs={12} sm={12} md={6}>              
                      <label htmlFor="testoMail">Testo Email</label>
                      <InputTextarea id="testoMail"
                        disabled={true}
                        autoResize
                        value={this.props.dataItem?.testoMail || ""} 
                        />                   
                      </GridItem>
                    </GridContainer>
                  )}

                  <GridContainer className="pt-3">
                    <GridItem
                      xs={12}
                      sm={12}
                      className="upload"
                      md={
                        this.props.type !== "Entrata" &&
                        (this.state.showEmailText || this.state.showLetterText)
                          ? 6
                          : 12
                      }
                    >
                      {!this.state.disableAll && (
                        <Card>
                          <CardHeader color="info">
                            <h4
                              style={{
                                lineHeight: "1",
                                display: "inline",
                                verticalAlign: "bottom"
                              }}
                            >
                              Selezione Allegato
                            </h4>
                          </CardHeader>
                          <CardBody>
                            <FileUpload
                              url=""
                              name="attachs"
                              customUpload
                              uploadLabel={"Aggiungi"}
                              uploadHandler={this.onUpload}
                              onRemove={this.removeFile}
                              accept=".txt,.pdf,.csv,.xlsx,.docx,.doc"
                              maxFileSize={100_000_000}
                              onClear={() => this.setState({ files: [] })}
                              emptyTemplate={
                                <p className="p-m-0">Seleziona file</p>
                              }
                            />
                          </CardBody>
                        </Card>
                      )}
                    </GridItem>

                    {this.state.showLetterText && this.props.type !== "ENTRATA" && (
                        <GridItem xs={12} sm={12} md={6}>
                          <TestoLettera
                            text={this.state.emailText}
                            setEmailText={this.handleTextEmail}              
                          />
                        </GridItem>
                    )}

                    {this.state.showEmailText && this.props.type !== "ENTRATA" && this.state.ModificaTitle !== 'Modifica' && (
                      <GridItem xs={12} sm={12} md={6}>
                        <TestoEmail
                          accounts={CorrispondenzaAPI.getAccountEmailFromTipoUfficio(
                            this.state.user
                          )}
                          setAccount={this.handleSenderEmail}
                          selectedAccount={this.state.senderEmail}
                          text={this.state.emailText} //emailText
                          setEmailText={this.handleTextEmail}
                          companyFreshman={this.state.RowItem?.azienda}
                          recipientEmail={this.state.recipientEmail}
                          setRecipientEmail={this.handleRcipientEmail}
                          userEmails={this.state.userEmails}
                          altroDest={this.state.altroDest ?? ""}
                          setAltroDest={(val) =>
                            this.setState({ altroDest: val })
                          }
                        />
                      </GridItem>
                    )}
                  </GridContainer>

                  <DataTable
                    emptyMessage=""
                    className="p-datatable-striped p-datatable-sm table"
                    value={this.state.attachments?.result}
                    dataKey="id"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                    rows={10}
                    loading={this.state.loadingGrid}
                    paginatorLeft={this.paginatorLeft}
                    paginatorRight={this.paginatorRight}
                    selection={this.state.selectedGrid}
                    scrollable
                    style={{ width: "100%" }}
                    onSelectionChange={this.handleTableClick}
                    selectionMode="single"
                  >
                    <Column
                      field="path"
                      header="Allegati"
                      sortable={true}
                      filter={this.state.filterGrid}
                      filterPlaceholder=""
                    />
                    {!this.state.disableAll && dynamicDeleteColumns}
                    {dynamicDownloadColumns}
                  </DataTable>
                </CardBody>
                {!(this.state.ModificaTitle =="Modifica"  && this.state.disableAll) && (
                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                </CardFooter>
                )}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}
