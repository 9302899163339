import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import LoaderSpinner from "../../components/Layout/LoaderSpinner.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import queryString from "query-string";
import CustomButtons from "components/CustomButtons/Button.js";
import { ModificaCorrispondenzaXVersamenti } from "components/Views/Corrispondenza/ModificaCorrispondenzaXVersamenti.js";
import { AziendaHeader } from "components/Views/Aziende/AziendaHeader.js";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import AziendeAPI from "API/AziendeAPI.js";
import AmministratoriAPI from "API/AmministratoriAPI";
import ConsulentiAPI from "API/ConsulentiAPI";
import DipendentiAPI from "API/DipendentiAPI";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { Checkbox } from "primereact/checkbox";
import ButtonsMUI from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import UtentiAPI from "API/Utenti.api";
import authApi from "../../API/auth.api";
import { rowsPerPageOptions } from "../../helpers/AppConstants";
import Button from "components/CustomButtons/Button.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { parse } from "query-string";

class Corrispondenza extends React.Component {
  state = {
    rows: [],
    selectedCorrisp: null,
    selectedGrid: null,
    filterGrid: false,
    visibleLoader: false,
    loadingGrid: false,
    disabled: true,
    visAField: true,
    visCField: true,
    visDField: true,
    radioChecked: null,
    rowsPerPage: 10,
    attachments: [],
    Indirizzo: [
      { name: "Amministratore", key: "AM" },
      { name: "Consulente", key: "CO" },
      { name: "Dipendente", key: "DI" }
    ],
    azinda: null,
    people: [],
    selectedAttach: new Set(),
    name: "Uscita",
    ufficio: null,
    utente: null
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.templateCheckboxGrid = this.templateCheckboxGrid.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.btnSearchRef = React.createRef();
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);
    this.buildJson = this.buildJson.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
  }

  templateCheckboxGrid(rowData) {
    return (
      <div className="p-field-checkbox">
        <Checkbox
          inputId="binary"
          checked={rowData.pathattach}
          onChange={(e) => {
            e.checked
              ? this.state.selectedAttach.add(rowData.id)
              : this.state.selectedAttach.delete(rowData.id);
            let list = this.state.rows;
            let el = list.find((x) => x.id === rowData.id);
            el.pathattach = e.checked;
            this.setState({ rows: list, selectedCorrisp: null });
          }}
        />
      </div>
    );
  }

  onNuovoClick() {
    this.setState({
      visibleLoader: true,
      selectedCorrisp: null
    });

    setTimeout(() => {
      let qS = queryString.parse(this.props.history.location.search);

      let nuovocorrispondenza = CorrispondenzaAPI.GetNuovoCorrispondenza(0);
  
      if (qS.type === undefined || qS.type === null) {
        this.setState({
          selectedGrid: null,
          selectedCorrisp: nuovocorrispondenza,
          disabled: false,
          visAField: false,
          visCField: false,
          visDField: false
        });
      } else {
        if (qS.type === "fromConsulenti") {
          this.setState({
            selectedGrid: null,
            selectedCorrisp: nuovocorrispondenza,
            disabled: false,
            radioChecked: "CO",
            visDField: false,
            visAField: false,
            visCField: true
          });
        } else if (qS.type === "fromDipendenti") {
          this.setState({
            selectedGrid: null,
            selectedCorrisp: nuovocorrispondenza,
            disabled: false,
            radioChecked: "DI",
            visDField: true,
            visAField: false,
            visCField: false
          });
        } else if (qS.type === "fromAmministratori") {
          this.setState({
            selectedGrid: null,
            selectedCorrisp: nuovocorrispondenza,
            disabled: false,
            radioChecked: "AM",
            visDField: false,
            visAField: true,
            visCField: false
          });
        }
      }
      this.setState({
        visibleLoader: false
      });
    }, 200);
  }

  onAnnullaClick() {
    this.setState({
      selectedGrid: null,
      selectedCorrisp: null
    });
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  handleTableClick = async (e) => {
    this.setState({
      visibleLoader: true
    });

    this.setState({
      selectedCorrisp: e.value,
      selectedGrid: e.value,
      disabled: true,
      visAField: true,
      visCField: true,
      visDField: true
    });

    this.setState({
      visibleLoader: false
    });
  };

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  async componentDidMount() {
    let qS = queryString.parse(this.props.history.location.search);
    if (qS.type === "fromConsulenti") {
      this.setState({
        radioChecked: "CO",
        visDField: false,
        visAField: false,
        visCField: true
      });
    } else if (qS.type === "fromDipendenti") {
      this.setState({
        radioChecked: "DI",
        visDField: true,
        visAField: false,
        visCField: false
      });
    } else if (qS.type === "fromAmministratori") {
      this.setState({
        radioChecked: "AM",
        visDField: false,
        visAField: true,
        visCField: false
      });
    }

    this.setState({
      loadingGrid: true
    });

    const profile = (await authApi.getProfile()).result[0];
    const { username } = profile;
    const res = await UtentiAPI.getUserByUsername(username);

    this.setState({
      utente: res[0].utente,
      ufficio: profile.ufficio
    });

    let aziendaId = this.props.match.params.aziendaId;
    let rw =
      profile.ufficio === "EBINPROF"
        ? await CorrispondenzaAPI.GetCorrispondenzaForAziendaEbin(aziendaId)
        : await CorrispondenzaAPI.GetCorrispondenzaForAzienda(aziendaId);
    let att = profile.ufficio === "EBINPROF" ?
      await CorrispondenzaAPI.GetCorrispondenzaEbinAttachments(aziendaId) :
      await CorrispondenzaAPI.GetCorrispondenzaAttachments(aziendaId);
    let azienda = await AziendeAPI.GetAziendaById(aziendaId);

    this.setState({
      rows: rw,
      attachments: att,
      loadingGrid: false,
      azienda: azienda,
      people: await this.getPeople(aziendaId)
    });
  }

  HandleSaveClick = async (
    RowItem,
    files,
    isEdit,
    administratorEmail,
    consultantEmail,
    employeeEmail,
    companyEmail,
    senderEmail
  ) => {
    const rowItem = RowItem;
    let recipientEmails = [];
    /*rowItem?.administratorEmail && recipientEmails.push(rowItem?.administratorEmail);
    rowItem?.consultantEmail && recipientEmails.push(rowItem?.consultantEmail);
    rowItem?.employeeEmail && recipientEmails.push(rowItem?.employeeEmail);
    rowItem?.sendCompanyEmail && recipientEmails.push(rowItem?.company?.email);*/
    rowItem?.altroDest && recipientEmails.push(rowItem?.altroDest);
    administratorEmail && recipientEmails.push(administratorEmail);
    consultantEmail && recipientEmails.push(consultantEmail);
    employeeEmail && recipientEmails.push(employeeEmail);
    companyEmail && recipientEmails.push(companyEmail);
    const object = rowItem?.oggetto;

    const data = this.buildJson(rowItem);

    if (!isEdit) {
      if (RowItem?.oggetto) {
        if (RowItem?.causale) {
          let res;
          if (this.state.ufficio === "EBINPROF") {
            res = await CorrispondenzaAPI.AddCorrispondenzaEbin(
              null,
              recipientEmails,
              object,
              data,
              files
            );
          } else {
            res = await CorrispondenzaAPI.AddCorrispondenza(
              null,
              recipientEmails,
              object,
              data,
              files
            );
          }
          if (res !== undefined && res.status === 200) {
            if (data.tipo.toUpperCase() === 'USCITA' && data.tipoinvio === 2) {
              this.props.addAlert.current.addAlert(
                "success",
                "Numero protocollo inserito: " + res?.data?.result[0]?.prot,
                86_400_000 // 24h.
              );
              this.setState(
                {
                  selectedCorrisp: null,
                  selectedGrid: null
                },
                () => this.onNuovoClick()
              );
            } else {
              if (data.tipo.toUpperCase() === 'ENTRATA') {
                const resLastProtocol = this.state.ufficio === "EBINPROF" ? 
                  await CorrispondenzaAPI.GetLastInsertedProtocolEbin(data?.anno, data.tipo) :
                  await CorrispondenzaAPI.GetLastInsertedProtocol(data?.anno, data.tipo);
                const protocol = resLastProtocol?.result[0];
                this.props.addAlert.current.addAlert(
                  "success",
                  `Numero protocollo inserito: ${protocol}`,
                  86_400_000 // 24h.
                );
                this.setState(
                  {
                    selectedCorrisp: null,
                    selectedGrid: null
                  },
                  () => this.onNuovoClick()
                );
              } else {
                const ris = new Blob([res.data]);
                let url = window.URL.createObjectURL(ris);
                let a = document.createElement("a");
                a.href = url;
                a.download = "lettera.pdf";
                document.body.appendChild(a);
                a.click();
                a.remove();
                const resLastProtocol = this.state.ufficio === "EBINPROF" ? 
                  await CorrispondenzaAPI.GetLastInsertedProtocolEbin(data?.anno, data.tipo) :
                  await CorrispondenzaAPI.GetLastInsertedProtocol(data?.anno, data.tipo);
                const protocol = resLastProtocol?.result[0];
                this.props.addAlert.current.addAlert(
                  "success",
                  `Numero protocollo inserito: ${protocol}`,
                  86_400_000 // 24h.
                );
                this.setState(
                  {
                    selectedCorrisp: null,
                    selectedGrid: null
                  },
                  () => this.onNuovoClick()
                );
              }
            }
          } else
            this.props.addAlert.current.addAlert(
              "danger",
              "Errore nell'inserimento"
            );
        } else
          this.props.addAlert.current.addAlert(
            "warning",
            "Inserire la causale"
          );
      } else
        this.props.addAlert.current.addAlert("warning", "Inserire l'oggetto");
    } else {
        const res = this.state.ufficio === 'EBINPROF' ? 
          await CorrispondenzaAPI.UpdateCorrispondenzaEbin(null, null, null, data, files) :
          await CorrispondenzaAPI.UpdateCorrispondenza(data, files);
        if (res) {
          this.props.addAlert.current.addAlert(
            "success",
            `Protocollo aggiornato correttamente`
          );
          this.setState(
            {
              selectedCorrisp: null,
              selectedGrid: null
            },
            () => this.onNuovoClick()
          );
        }
    }
    try {
      let aziendaId = this.props.match.params.aziendaId;
      const profile = (await authApi.getProfile()).result[0];
      let rw = this.state.ufficio === "EBINPROF" ? 
          await CorrispondenzaAPI.GetCorrispondenzaForAziendaEbin(aziendaId)
          : await CorrispondenzaAPI.GetCorrispondenzaForAzienda(aziendaId);
      let att = this.state.ufficio === "EBINPROF" ?
        await CorrispondenzaAPI.GetCorrispondenzaEbinAttachments(aziendaId) :
        await CorrispondenzaAPI.GetCorrispondenzaAttachments(aziendaId);
  
        this.setState({
          rows: rw,
          attachments: att
        });
    } catch(err){
      console.error("error reloading corrispondenze");
    }
    return true;
  };

  async getPeople(aziendaId) {
    const adms = await AmministratoriAPI.GetAmministratoriForAziendaByAziendaId(
      aziendaId
    );
    const cons = await ConsulentiAPI.GetConsulentiForAziendaByAziendaId(
      aziendaId
    );
    const dips = await DipendentiAPI.GetDipendentiForAzienda(aziendaId);

    return [cons, dips, adms];
  }

  downloadFiles() {
    if (this.state.selectedAttach && this.state.selectedAttach.size > 0) {
      const response = CorrispondenzaAPI.GetExportedFiles(
        [...this.state.selectedAttach],
        "Corrispondenza"
      );
      response.then({});
    } else
      this.props.addAlert.current.addAlert(
        "warning",
        "Selezionare una corrispondenza prima"
      );
  }

  buildJson = (rowItem) => {
    let tipo = rowItem.tipo;
    if(typeof rowItem.tipo === 'number'){
      tipo = rowItem.tipo == 1 ? 'ENTRATA': 'USCITA';
    }
    return {
      utente: this.state.utente.username,
      azienda: rowItem?.azienda || this.state?.azienda[0].matricola,
      codsap: rowItem?.codsap,
      tipo: tipo,
      prot: rowItem?.prot,
      anno: rowItem?.anno,
      data: rowItem?.data,
      causale: rowItem?.causale,
      note: rowItem?.note,
      dtlett: rowItem?.dtlett,
      mitt_dest: rowItem?.mitt_dest,
      oggetto: rowItem?.oggetto,
      mailtext: rowItem?.mailtext || "",
      id: rowItem?.id,
      prov: rowItem?.prov,
      numtab: isNaN(parseInt(rowItem?.numtab))
        ? null
        : parseInt(rowItem?.numtab),
      numfat: isNaN(parseInt(rowItem?.numfat))
        ? null
        : parseInt(rowItem?.numfat),
      numpreav: isNaN(parseInt(rowItem?.numpreav))
        ? null
        : parseInt(rowItem?.numpreav),
      w350: rowItem?.w350,
      w300: rowItem?.w300,
      amm: rowItem?.amm,
      ncontabbanc: isNaN(parseInt(rowItem?.ncontabbanc))
        ? null
        : parseInt(rowItem?.ncontabbanc),
      prbuchicont: rowItem?.prbuchicont,
      pathattach: rowItem?.pathattach,
      fldoppi: rowItem?.fldoppi,
      sede_inps: rowItem?.sede_inps || "",
      ninvio: rowItem?.ninvio,
      flamm: rowItem?.flamm,
      flaz: rowItem?.flaz,
      flcons: rowItem?.flcons,
      fldip: rowItem?.fldip,
      fldocele: rowItem?.fldocele,
      flmodo: rowItem?.flmodo,
      puli: rowItem?.puli,
      cust: rowItem?.cust,
      tipoinvio: rowItem?.tipoinvio,
      mittmail: rowItem?.mittmail?.toLowerCase(),
      destmail: rowItem?.destmail?.toLowerCase(),
      cfcons: rowItem?.cfcons,
      cfamm: rowItem?.cfamm,
      cfdip: rowItem?.cfdip,
      haAllegati: null,
      testomail: rowItem?.testomail || ""
    };
  };

  backButtonClick() {
    let qS = parse(this.props.location.search);
    let maintainSearch = "?maintainSearch=1";
    if (!qS.type)
      this.props.history.push(
        "/admin/aziende/" + this.props.match.params.aziendaId + maintainSearch
      );
    else if (qS.type === "fromVersamenti")
      this.props.history.push(
        "/admin/versamenti/details/" +
          this.props.match.params.aziendaId +
          maintainSearch
      );
    else if (qS.type === "fromModificaRichieste")
      this.props.history.push(
        "/admin/richiedenti/modificarichieste/" +
          this.props.match.params.aziendaId +
          "/" +
          qS.typeId +
          maintainSearch
      );
    else if (qS.type === "fromRichieste")
      this.props.history.push(
        "/admin/richiedenti/modificarichieste/" +
          this.props.match.params.aziendaId +
          "/" +
          qS.typeId +
          maintainSearch +
          "&type=" +
          qS.type
      );
    else if (qS.type === "fromConsulenti")
      this.props.history.push(
        "/admin/consulenti/" +
          this.props.match.params.aziendaId +
          maintainSearch
      );
    else if (qS.type === "fromAmministratori")
      this.props.history.push(
        "/admin/amministratori/" +
          this.props.match.params.aziendaId +
          maintainSearch
      );
    else if (qS.type === "fromDipendenti")
      this.props.history.push(
        "/admin/dipendenti/" +
          this.props.match.params.aziendaId +
          maintainSearch
      );
  }

  render() {
    return (
      <>
        <div ref={this.btnSearchRef} />
        <AziendaHeader
          aziendaId={this.props.match.params.aziendaId}
          {...this.props}
        />
        {this.state.visibleLoader && <LoaderSpinner fullHeight={true} />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Corrispondenza
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <CustomButtons
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </CustomButtons>
                </Tooltip>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  size="small"
                  aria-label="contained primary button group"
                  className="float-right"
                  style={{ verticalAlign: "center", margin: "0.5%" }}
                >
                  <ButtonsMUI onClick={this.downloadFiles}>
                    Esporta files
                  </ButtonsMUI>
                </ButtonGroup>

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Alla pagina precedente"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.backButtonClick}
                  >
                    <ArrowBackIcon />
                  </Button>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    header="Seleziona"
                    body={this.templateCheckboxGrid}
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="anno"
                    header="Anno"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="prot"
                    header="Prot."
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="tipo"
                    header="Tipo"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data"
                    header="Data"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="causale.codice"
                    header="Causale"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    header="Allegati"
                    field="haAllegati"
                    body={(row) => <>{row.haAllegati && <AttachFileIcon />}</>}
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    style={{ width: "90px", textAlign: "center" }}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="oggetto"
                    header="Oggetto"
                    headerStyle={{ width: "400px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.selectedCorrisp && (
          <ModificaCorrispondenzaXVersamenti
            {...this.props}
            dataItem={this.state.selectedCorrisp}
            onAnnullaClick={this.onAnnullaClick}
            onSaveClick={this.HandleSaveClick}
            disable={this.state.disabled}
            azienda={this.state.azienda[0]}
            people={{
              cons: this.state.people[0],
              dips: this.state.people[1],
              adms: this.state.people[2]
            }}
            HeaderDate={this.state.HeaderDate}
            ufficio={this.state.ufficio}
          />
        )}
      </>
    );
  }
}

export default Corrispondenza;
