import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { locale, addLocale } from "primereact/api";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// pick a date util library
import MomentUtils from "@date-io/moment";

// Components
import Routes from "components/Routes/Routes.component";

// Providers
import { AuthProvider } from "providers";

// import 'primeflex/primeflex.css';

function GetUrlRefferer() {
  addLocale("it", {
    accept: "Si",
    reject: "No",
    choose: "Scegli",
    upload: "Carica",
    cancel: "Annulla",
    dayNames: [
      "Domenica",
      "Lunedì",
      "Martedì",
      "Mercoledì",
      "Giovedì",
      "Venerdì",
      "Sabato"
    ],
    dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
    dayNamesMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
    monthNames: [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre"
    ],
    monthNamesShort: [
      "Gen",
      "Feb",
      "Mar",
      "Apr",
      "Mag",
      "Giu",
      "Lug",
      "Ago",
      "Set",
      "Ott",
      "Nov",
      "Dic"
    ],
    today: "Oggi",
    clear: "Chiaro",
    weekHeader: "Sett.",
    firstDayOfWeek: 1,
    dateFormat: "dd/mm/yy",
    weak: "Debole",
    medium: "Medio",
    strong: "Forte",
    passwordPrompt: "Inserisci la password"
  });
  locale("it");
  //http://localhost:3000/index.html?referrer=/admin/scarichi
  let url = window.location.href;
  if (url.indexOf("referrer") > 0) {
    return url.substr(url.indexOf("referrer=") + 9);
  } else return "/admin/dashboard";
}
String.prototype.titleCase = function () {
  return this.split(" ").map(word => word[0].toUpperCase() + word.slice(1).toLowerCase()).join(" ");
}
GetUrlRefferer();
ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <AuthProvider>
      <Routes GetUrlRefferer={GetUrlRefferer} />
    </AuthProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById("root")
);
