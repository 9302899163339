import Utils from "./Utils";
import * as AppConstants from "../helpers/AppConstants";

const baseURL = AppConstants.SiteUrl + "coasco/rdl";

const RDLAPI = {
  getByEmployeeId: async function (id) {
    const response = await Utils.SendPostRequestTo(
      `${baseURL}/getbyiddip?iddip=${id}`
    );
    return response.result;
  }
};
export default RDLAPI;
