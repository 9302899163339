import React, {Component} from "react";
import clone from "clone";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";

import LoaderSpinner from "../../Layout/LoaderSpinner.js";
import SedeComuneProvAPI from "API/SedeComuneProvAPI";
import ConsulentiAPI from "API/ConsulentiAPI";
import {Dropdown} from "primereact/dropdown";
import UndoIcon from "@material-ui/icons/Undo";
import ExtraFieldsModifica from "components/Views/Aziende/ExtraFieldsModifica.js";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import helpers from "helpers/Helpers";

export class ModificaConsulenteXAzienda extends Component {
  state = {
    RowItem: null,
    ModificaTitle: "Nuovo",
    groupClass: "",
    disabledFields: false,
    ProvinciaList: [],
    selectedProvincia: null,

    selectedComune: null,
    notFilteredComune: [],
    filteredComune: [],

    //VALIDATIONS
    fieldValidations: {},
    validationRules: {
      indirizzo: "",
      nominativo: "",
      cf: "CFPIVA",
      piva: "EQ-11-optional"
    }
  };

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.SetText = this.SetText.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.OnInputBlur = this.OnInputBlur.bind(this);
    this.ChangeButtons = this.ChangeButtons.bind(this);
    this.ref = React.createRef();
  }

  async isValidForm(showAlert = false) {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    if (this.props.dataItem.id !== null) {
      // For update, skip validation for some fields
      validationRules["cf"] = "";
    }

    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);
    let isValid = Object.values(fieldValidations).every((x) => x === "success");
    this.setState({
      fieldValidations: fieldValidations
    });
    if (fieldValidations["cf"] === "error") {
      this.props.addAlert.current.addAlert(
        "warning",
        "Il Codice Fiscale non e' sintatticamente corretto: CCCCCCNNCNNCNNNC o NNNNNNNNNNN"
      );
      return false;
    }

    return isValid;
  }

  SetText(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;
    this.setState({ RowItem: newRowItem });
  }

  async OnInputBlur(newCAP) {
    let rowItem = this.state.RowItem;
    let selectedComune = this.state.notFilteredComune.filter(
      (row) => row.cap === newCAP
    )[0];
    if (selectedComune !== null) {
      let comuneFiltered = this.state.notFilteredComune.filter(
        (row) => row.prov.sigla === selectedComune.prov.sigla
      );
      let selectedProvincia = this.state.ProvinciaList.filter(
        (row) => row.sigla === selectedComune.prov.sigla
      )[0];

      rowItem["comune"] = selectedComune.nome;
      rowItem["prov"] = selectedProvincia.sigla;

      this.setState({
        RowItem: rowItem,
        filteredComune: comuneFiltered,
        selectedComune: selectedComune.nome,
        selectedProvincia: selectedProvincia.sigla
      });
    } else {
      rowItem["comune"] = null;
      rowItem["prov"] = null;

      this.setState({
        RowItem: rowItem,
        filteredComune: [],
        selectedComune: null,
        selectedProvincia: null
      });
    }
  }

  HandleSaveClick = async () => {
    const res = await this.isValidForm();
    if (res) {
      var sd = await this.props.onSaveClick(this.state.RowItem);
      return sd;
    } else return;
  };

  HandleDropDownChange(e, val, propName) {
    var rowItem = this.state.RowItem;

    if (e === "selectedComune") {
      rowItem[propName] = val;
    } else if (e === "selectedProvincia") {
      rowItem[propName] = val;
      var sigla = helpers.ReturnIdFromListByType(
        this.state.ProvinciaList,
        "ProvinciaSigla",
        val
      );

      var _comunebyprov = helpers.ReturnIdFromListByType(
        this.state.notFilteredComune,
        "ComuneByProvList",
        sigla
      );

      this.setState({
        filteredComune: _comunebyprov,
        selectedComune: null
      });
    } else rowItem[propName] = val.id;

    this.setState({
      [e]: val,
      RowItem: rowItem
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.dataItem !== null) {
      if (
        this.props.dataItem.id !== prevProps.dataItem.id ||
        this.props.dataItem.tipo !== prevProps.dataItem.tipo
      ) {
        let selectedProvincia = this.state.ProvinciaList.filter(
          (row) => row.sigla === this.props.dataItem.prov
        )[0]?.sigla;
        let comuneFiltered = [];
        if (selectedProvincia)
          comuneFiltered = this.state.notFilteredComune.filter(
            (row) => row.prov.sigla === selectedProvincia
          );

        this.setState({
          RowItem: this.props.dataItem.tipo
            ? this.props.dataItem[0]
            : this.props.dataItem,
          ModificaTitle: this.props.dataItem.id > 0 ? "Modifica" : "Nuovo",
          disabledFields: this.props.dataItem.id > 0 ? true : false,
          groupClass: this.props.dataItem.id > 0 ? "" : "hide",
          filteredComune: comuneFiltered,
          selectedComune: this.props.dataItem.comune, //helpers.ReturnIdFromListByType(this.state.filteredComune, "Comune", this.props.dataItem.comune),
          selectedProvincia: selectedProvincia //((this.props.dataItem.id > 0) && (this.props.dataItem.prov !== null) && (this.props.dataItem.prov.length > 0)) ? helpers.ReturnIdFromListByType(this.state.ProvinciaList, "Provincia", this.props.dataItem.prov) : null,
        });
      }
    }
  }

  async componentDidMount() {
    var allComune = await SedeComuneProvAPI.GetAllComune();
    var allProvincie = await SedeComuneProvAPI.GetAllProvincieProvincie();

    if (this.props.dataItem !== null) {
      this.setState({
        RowItem: this.props.dataItem,
        disabledFields: this.props.dataItem.id > 0 ? true : false,
        ModificaTitle: this.props.dataItem.id > 0 ? "Modifica" : "Nuovo",
        groupClass: this.props.dataItem.id > 0 ? "" : "hide",
        ProvinciaList: allProvincie,
        filteredComune: allComune,
        notFilteredComune: allComune
      });

      let selectedProvincia = allProvincie.filter(
        (row) => row.sigla === this.props.dataItem.prov
      )[0]?.sigla;
      let comuneFiltered = [];
      if (selectedProvincia)
        comuneFiltered = allComune.filter(
          (row) => row.prov.sigla === selectedProvincia
        );

      this.setState({
        filteredComune: comuneFiltered,
        selectedComune: this.props.dataItem.comune, //helpers.ReturnIdFromListByType(this.state.filteredComune, "Comune", this.props.dataItem.comune),
        selectedProvincia: selectedProvincia //((this.props.dataItem.id > 0) && (this.props.dataItem.prov !== null) && (this.props.dataItem.prov.length > 0)) ? helpers.ReturnIdFromListByType(this.state.ProvinciaList, "Provincia", this.props.dataItem.prov) : null,
      });
    }
    try {
      this.ref.current.scrollIntoView();
    } catch(err){}
  }

  OnInputBlurCF = async () => {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);

    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);
    Object.values(fieldValidations).every((x) => x === "success");

    if (fieldValidations["cf"] === "error") {
      this.props.addAlert.current.addAlert(
        "warning",
        "Il Codice Fiscale non e' sintatticamente corretto: CCCCCCNNCNNCNNNC o NNNNNNNNNNN!"
      );
      return false;
    }

    let existentConsulenti = [];
    if (
      this.state.RowItem.cf !== null &&
      this.state.RowItem.cf.length === 16 &&
      this.props.dataItem.id === null
    ) {
      existentConsulenti = await ConsulentiAPI.GetCfConsAmm(
        this.state.RowItem.cf
      );
      if (existentConsulenti.length !== 0) {
        this.ChangeButtons(existentConsulenti);
        this.props.addAlert.current.addAlert(
          "warning",
          "Codice Fiscale Trovato"
        );
      }
    }
    return;
  };

  ChangeButtons = async (existentConsulenti) => {
    return await this.props.ChangeButtons(existentConsulenti);
  };

  render() {
    if (this.state.RowItem === null)
      return (
        <>
          {" "}
          <LoaderSpinner fullHeight={false} />{" "}
        </>
      );
    else
      return (
        <div ref={this.ref}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.state.ModificaTitle} Consulente
                  </h4>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Annulla"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.props.onAnnullaClick}
                    >
                      <UndoIcon />
                    </Button>
                  </Tooltip>

                  {this.props.children}
                </CardHeader>

                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Id"
                        id="id-disabled"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          onChange: (event) =>
                            this.SetText("id", event.target.value),
                          value: this.state.RowItem.id || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Ultimo Aggiornamento"
                        id="datault"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.data_ult_agg || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Tipo Ultimo Aggiornamento"
                        id="ultimoagg2"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.tipo_ult_agg || "",
                          maxLength: 12
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Utente Ultimo Aggiornamento"
                        success={
                          this.state.fieldValidations.utente &&
                          this.state.fieldValidations.utente === "success"
                        }
                        error={
                          this.state.fieldValidations.utente &&
                          this.state.fieldValidations.utente === "error"
                        }
                        id="ultimoagg3"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.utente || "",
                          maxLength: 30
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.nominativo &&
                          this.state.fieldValidations.nominativo === "success"
                        }
                        error={
                          this.state.fieldValidations.nominativo &&
                          this.state.fieldValidations.nominativo === "error"
                        }
                        labelText="Nominativo *"
                        id="nominativo"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("nominativo", event.target.value),
                          value: this.state.RowItem.nominativo || "",
                          maxLength: 100
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.cf &&
                          this.state.fieldValidations.cf === "success"
                        }
                        error={
                          this.state.fieldValidations.cf &&
                          this.state.fieldValidations.cf === "error"
                        }
                        labelText="Codice Fiscale *"
                        id="cf"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disabledFields,
                          onChange: (event) =>
                            this.SetText("cf", event.target.value),
                          onBlur: (event) =>
                            this.OnInputBlurCF(event.target.value),
                          value: this.state.RowItem.cf || "",
                          maxLength: 16
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.piva &&
                          this.state.fieldValidations.piva === "success"
                        }
                        error={
                          this.state.fieldValidations.piva &&
                          this.state.fieldValidations.piva === "error"
                        }
                        helperText={
                          this.state.fieldValidations.piva === "error" &&
                          "La Partita IVA deve essere di 11 caratteri"
                        }
                        labelText="Partita IVA"
                        id="piva"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("piva", event.target.value),
                          value: this.state.RowItem.piva || "",
                          maxLength: 11
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.indirizzo &&
                          this.state.fieldValidations.indirizzo === "success"
                        }
                        error={
                          this.state.fieldValidations.indirizzo &&
                          this.state.fieldValidations.indirizzo === "error"
                        }
                        labelText="Indirizzo *"
                        id="indirizzo"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("indirizzo", event.target.value),
                          value: this.state.RowItem.indirizzo || "",
                          maxLength: 50
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="CAP"
                        id="cap"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("cap", event.target.value),
                          onBlur: (event) =>
                            this.OnInputBlur(event.target.value),
                          value: this.state.RowItem.cap || "",
                          maxLength: 5
                        }}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="lblNoMargin label">
                        <small>Comune</small>
                      </p>
                      <Dropdown
                        value={this.state.selectedComune}
                        resetFilterOnHide filter
                        className="dropdownCustomisedForMUI"
                        options={helpers.comuniView(this.state.filteredComune)}
                        onChange={(e) =>
                          this.HandleDropDownChange(
                            "selectedComune",
                            e.target.value,
                            "comune"
                          )
                        }
                      />{" "}
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="lblNoMargin label">
                        <small>Provincia</small>
                      </p>
                      <Dropdown
                        value={this.state.selectedProvincia}
                        optionValue="sigla"
                        resetFilterOnHide
                        className="dropdownCustomisedForMUI"
                        options={this.state.ProvinciaList}
                        onChange={(e) =>
                          this.HandleDropDownChange(
                            "selectedProvincia",
                            e.target.value,
                            "prov"
                          )
                        }
                        optionLabel="sigla"
                        filter
                        filterBy="sigla"
                      />{" "}
                    </GridItem>


                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Telefono"
                        id="telefono"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("tel", event.target.value),
                          value: this.state.RowItem.tel || "",
                          maxLength: 20
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Fax"
                        id="fax"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("fax", event.target.value),
                          value: this.state.RowItem.fax || "",
                          maxLength: 20
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Cellulare"
                        id="cellulare"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("cell", event.target.value),
                          value: this.state.RowItem.cell || "",
                          maxLength: 15
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="E-mail"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("email", event.target.value),
                          value: this.state.RowItem.email || "",
                          maxLength: 60
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="E-mail Alternativa"
                        id="emailalt"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("email2", event.target.value),
                          value: this.state.RowItem.email2 || "",
                          maxLength: 60
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="PEC"
                        id="pec"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("pec", event.target.value),
                          value: this.state.RowItem.pec || "",
                          maxLength: 60
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Note"
                        id="note"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("note", event.target.value),
                          value: this.state.RowItem.note || "",
                          maxLength: 1000
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <ExtraFieldsModifica
                    dataItem={this.props.dataItem}
                    SetText={this.SetText}
                    provincias={this.state.ProvinciaList}
                    comunes={this.state.notFilteredComune}
                  />
                </CardBody>

                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}
