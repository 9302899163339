import Utils from "./Utils";

const BASEURL = "coasco/dipuni/";

const DipUniAPI = {
  GetById: async function (dipuniID) {
    let result = await Utils.CreatePostRequest(
      BASEURL + "getbyid?id=" + dipuniID
    );
    return result.result;
  },

  GetByIdAziendaCodQAnno: async function (aziendaId, codQ, anno) {
    let response = await Utils.CreatePostRequest(
      BASEURL +
        "getbyidaziendacodqanno?idazienda=" +
        aziendaId +
        "&codQ=" +
        codQ +
        "&anno=" +
        anno
    ).catch((err) => console.error(err));
    return response.result;
  },

  Insert: async function (dipuni) {
    return await Utils.CreatePutRequest(BASEURL + "insert", dipuni);
  },

  InsertNegatives: async function (dipuniIds) {
    let response = await Utils.CreatePostRequest(
      BASEURL + "insertnegative",
      dipuniIds
    );
    return response.result;
  },

  TransferQuotaCode: async function (dipuniIds) {
    return await Utils.CreatePostRequest(
      BASEURL + "transferquotacode",
      dipuniIds
    );
  },

  Delete: async function (dipuniId) {
    let response = await Utils.CreateDeleteRequest(
      `${BASEURL}delete?id=${dipuniId}`
    );
    return response.result;
  }
};

export default DipUniAPI;
