import React, { Component } from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Helpers from "../../../helpers/Helpers.js";
import { Button } from "primereact/button";
import CustomButton from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonsMUI from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import ModificaAzienda from "components/Views/Aziende/ModificaAzienda.js";
import AziendeAPI from "API/AziendeAPI";
import DipendentiAPI from "API/DipendentiAPI";

export class VersamentiHeader extends Component {
  state = {
    versamenti: {},
    openPopUp: false,
    hideHeader: true,
    rows: [],
    loadingGrid: false,
    selectedGridDipendenti: null
  };

  constructor(props) {
    super(props);

    this.RedirectButtonClick = this.RedirectButtonClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.AnnullaSelez = this.AnnullaSelez.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });
    var versamentiId = this.props.versamentiId;

    var rw = await DipendentiAPI.GetDipendentiForAzienda(versamentiId);
    var versamentiById = await AziendeAPI.GetAziendaById(versamentiId);
    this.setState({
      versamenti: versamentiById[0],
      rows: rw
    });

    this.setState({
      loadingGrid: false
    });
  }

  handleTableClick(e) {
    this.setState({
      selectedGridDipendenti: e.value
    });
  }

  HandleSaveClick = async (RowItem) => {
    if (RowItem.id === null) {
      var nuovaAzienda = await AziendeAPI.AddAzienda(RowItem);
      this.state.selectedAzienda = nuovaAzienda.result[0];
    } else {
      await AziendeAPI.UpdateAzienda(RowItem);
    }

    //this.props.addAlert.current.addAlert("success", "Dati salvati.");

    window.setTimeout(() => {
      this.setState({
        openPopUp: !this.state.openPopUp
      });
    }, 2200);

    return true;
  };

  onEditClick() {
    this.setState({
      openPopUp: true
    });
  }

  AnnullaSelez() {
    this.setState({
      openPopUp: !this.state.openPopUp
    });
  }

  RedirectButtonClick(param) {
    let maintainSearch = "?maintainSearch=1";
    if (param === "back")
      this.props.history.push(
        "/admin/versamenti/" + this.props.versamentiId + maintainSearch
      );
    else if (param === "dip")
      this.props.history.push(
        "/admin/DipendentiXAzienda/" +
          this.props.versamentiId +
          "?type=fromVersamenti"
      );
  }

  render() {
    return (
      <>
        <Dialog
          header="Modifica azienda"
          visible={this.state.openPopUp}
          style={{ width: "80%" }}
          onHide={this.AnnullaSelez}
        >
          <ModificaAzienda
            dataItem={this.state.versamenti}
            onSaveClick={this.HandleSaveClick}
            {...this.props}
            onAnnullaClick={this.AnnullaSelez}
            hideHeader={this.state.hideHeader}
          />
        </Dialog>
        <Card>
          <CardHeader color="ebinprofprimary">
            <Button
              icon="pi pi-arrow-left"
              onClick={(e) => this.RedirectButtonClick("back")}
              className="p-button-rounded p-button-primary p-button-sm float-left"
              style={{ verticalAlign: "bottom", marginRight: "2px" }}
            />

            <h4
              style={{
                lineHeight: "0",
                display: "inline",
                verticalAlign: "bottom"
              }}
            >
              Azienda
            </h4>
            <Tooltip
              arrow
              id="tooltip-top"
              title="Modifica"
              placement="top"
              classes={{ tooltip: "tooltip-me" }}
            >
              <CustomButton
                style={{
                  verticalAlign: "center",
                  lineHeight: "1",
                  margin: "0.1%"
                }}
                color="white"
                aria-label="edit"
                justIcon
                round
                className="float-right"
                onClick={this.onEditClick}
              >
                <EditIcon />
              </CustomButton>
            </Tooltip>
            <ButtonsMUI
              onClick={(e) => this.RedirectButtonClick("dip")}
              variant="contained"
              color="secondary"
              size="small"
              className="float-right"
              style={{ verticalAlign: "center", margin: "0.5%" }}
            >
              Dipendenti
            </ButtonsMUI>
          </CardHeader>

          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="Matricola"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: this.state.versamenti.matricola || ""
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={2}>
                <CustomInput
                  labelText="Sede"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: this.state.versamenti.sede || ""
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={7}>
                <CustomInput
                  labelText="Denominazione"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: this.state.versamenti.denominazione || ""
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="PVPuli"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: this.state.versamenti.primovers || ""
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="UVPuli"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: this.state.versamenti.ultimovers || ""
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="PVCust"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: this.state.versamenti.primoversw350 || ""
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                  labelText="UVCust"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    disabled: true,
                    value: this.state.versamenti.ultimoversw350 || ""
                  }}
                />
              </GridItem>
            </GridContainer>

            <GridContainer style={{ marginTop: "15px" }}>
              <GridItem xs={12} sm={12} md={12}>
                <DataTable
                  emptyMessage=""
                  scrollHeight={"180px"}
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  loading={this.state.loadingGrid}
                  selection={this.state.selectedGridDipendenti}
                  scrollable scrollDirection="horizontal"
                  style={{ width: "100%", height: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="nominativo"
                    header="Nominativo"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cf"
                    header="Codice fiscale"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                      header="Cod. Quota"
                      field="cod_quota"
                      headerStyle={{ width: "140px" }}
                      sortable={true}
                  />
                  <Column
                    field="data_inizio"
                    header="Inizio"
                    body={(rowData) =>
                      Helpers.DateTemplateWithHours(rowData, "data_inizio")
                    }
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data_fine"
                    header="Fine"
                    body={(rowData) =>
                      Helpers.DateTemplateWithHours(rowData, "data_fine")
                    }
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </>
    );
  }
}
