import Utils from "./Utils";

const aliquotaSpeseAPI = {

    getAll: async function () {
        const response = await Utils.CreatePostRequest("coasco/AliquotaSpese/getall");
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async update(aliquota) {
        const response = await Utils.CreatePutRequest("coasco/AliquotaSpese/update", aliquota);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async insert(aliquota) {
        const response = await Utils.CreatePostRequest("coasco/AliquotaSpese/insert", aliquota);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async delete(aliquota) {
        const response = await Utils.CreateDeleteRequest("coasco/AliquotaSpese/delete?id=" + aliquota.id);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    getNew() {
        return {
            aliquota: "",
            codice: "",
            id: 0,
            ripfn: "",
            ripin: ""
        };
    }

};

export default aliquotaSpeseAPI;