import React, { Component, useContext } from "react";
import clone from "clone";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Dropdown } from "primereact/dropdown";
// core components
import moment from "moment";
import LoaderSpinner from "../../Layout/LoaderSpinner.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import { Calendar } from "primereact/calendar";
import UndoIcon from "@material-ui/icons/Undo";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import MenuItem from "@material-ui/core/MenuItem";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Close from "@material-ui/icons/Close";
import { confirmPopup } from "primereact/confirmpopup";
import { FileUpload } from "primereact/fileupload";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import helpers from "helpers/Helpers";
import AziendeAPI from "API/AziendeAPI";
import StaticLists from "helpers/StaticLists";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import { dateTimeFmt } from "helpers/AppConstants";
import TipoInvioAPI from "API/TipoInvioAPI.js";
import SedeComuneProvAPI from "API/SedeComuneProvAPI.js";
import { TestoEmail } from "./TestoEmail.component";
import { TestoLettera } from "./TestoLettera.component";
import { AutoComplete } from "primereact/autocomplete";
import { FormControlLabel, FormGroup, FormLabel } from "@material-ui/core";
import { Checkbox } from "primereact/checkbox";
import { getToken } from "common/auth.common.js";
import DipendentiAPI from "API/DipendentiAPI.js";
import AmministratoriAPI from "API/AmministratoriAPI.js";
import ConsulentiAPI from "API/ConsulentiAPI.js";
import { AuthContext } from "providers";
import UtentiAPI from "API/Utenti.api.js";
import EmailUtenteAPI from "API/EmailUtenteAPI.js";
import { Select } from "shared/components/select/Select.component.jsx";
import { InputTextarea } from "primereact/inputtextarea";
import { thList } from "components/YearPicker/components/fontawesome/index.js";

export class ModificaProtocollo extends Component {
  static contextType = AuthContext;

  state = {
    protocolType: this.props.type === "Entrata" ? 1 : 2,
    protocolTypes: [
      { key: 1, value: "Entrata" },
      { key: 2, value: "Uscita" }
    ],
    RowItem: null,
    ModificaTitle: "Nuovo",
    groupClass: "",
    deletecolumns: [],
    downloadcolumns: [],
    tipoinvioList: [],
    Protocollo_List: [],
    Causale_List: [],
    W350_List: [],
    W300_List: [],
    Amm_List: [],
    Buchi_List: [],
    Cust_List: [],
    Puli_List: [],
    selectedTipo: 2,
    selectedW350: null,
    selectedW300: null,
    selectedAmm: null,
    selectedBuchi: null,
    selectedCust: null,
    selectedPuli: null,
    selectedProt: null,
    selectedCaus: null,
    isVisible: false,
    loadingGrid: false,
    files: [],
    isValid: true,
    altroDest: "",
    INPS_List: [],
    selectedSap: null,
    selectedINPS: null,
    senderEmail: "",
    emailText: "",
    showEmailText: false,
    showLetterText: false,
    showAdm: false,
    showCompany: false,
    showCons: false,
    showDip: false,
    lastInsertedProt: "",
    aziendasList: [],
    aziendaFilter: "",
    cons: null,
    dips: null,
    adms: null,
    selectedAdm: null,
    selectedCons: null,
    selectedDip: null,
    fieldValidations: {},
    validationRules: {
      RagioneSociale: "",
      Matricola: ""
    },
    listaSap: [],
    destMail: null
  };

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.handleSenderEmail = this.handleSenderEmail.bind(this);
    this.handleRcipientEmail = this.handleRcipientEmail.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.DeleteAttachment = this.DeleteAttachment.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.DownloadAttachmment = this.DownloadAttachmment.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.handleTextEmail = this.handleTextEmail.bind(this);
    this.searchMatricola = this.searchMatricola.bind(this);
    this.searchSap = this.searchSap.bind(this);
    this.monthNavigatorTemplate = this.monthNavigatorTemplate.bind(this);
    this.yearNavigatorTemplate = this.yearNavigatorTemplate.bind(this);
    this.setAziendasFields = this.setAziendasFields.bind(this);
    this.handleChangeTipoInvio = this.handleChangeTipoInvio.bind(this);
    this.handleChangeCausale = this.handleChangeCausale.bind(this);
    this.canSendEmail = this.canSendEmail.bind(this);
    this.refreshAttachmentTable = this.refreshAttachmentTable.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.getLastProt = this.getLastProt.bind(this);
    this.buildOggetto = this.buildOggetto.bind(this);
    this.freshmanHandler = this.freshmanHandler.bind(this);
    this.handleDownloadAttachment = this.handleDownloadAttachment.bind(this);
    this.protocolTypeHandler = this.protocolTypeHandler.bind(this);
    this.componentRef = React.createRef();
  }

  protocolTypeHandler(event) {
    const value = event.target.value;
    this.setState({ protocolType: value });
    this.setState({
      RowItem: {...this.state.RowItem, cfamm:null, cfdip:null, cfcons:null, dtlett: null},
      selectedAdm: null,
      selectedCons: null,
      selectedDip: null,
      showAdm: false,
      showCons: false,
      showDip: false
    });
  }

  monthNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }

  yearNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }

  async refreshAttachmentTable() {
    this.setState({ loadingGrid: true }, async () =>
      this.setState(
        {
          attachments: await CorrispondenzaAPI.GetCorrispondenzaPathAttach(
            this.props.dataItem?.id
          )
        },
        () => this.setState({ loadingGrid: false })
      )
    );
  }

  async onUpload(event) {
    this.setState({ files: event.files });
    this.props.addAlert.current.addAlert(
      "success",
      "File pronti al caricamento"
    );
  }

  sedeInpsTemplate(sede) {
    return <div>{sede.sap ? `${sede.sap} - ${sede.descrizione}` : ``}</div>;
  }

  sedeInpsValueTemplate(sede, props) {
    if (sede) return `${sede.sap} - ${sede.descrizione}`;
  }

  filterINPSBySap(sap) {
    this.setState({
      selectedINPS: this.state.INPS_List.find((sede) => sede.sap === sap)
    });
  }

  DeleteAttachment(rowData) {
    return (
      <Button
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData?.id}
        onClick={(event) => this.onDeleteClick(event, rowData?.id)}
      >
        <Close className="icon" />
      </Button>
    );
  }

  DownloadAttachmment(rowData) {
    return (
      <Button
        round
        color="primary"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData?.id}
        onClick={(event) => this.onDownloadClick(event, rowData?.id, rowData)}
      >
        <GetAppRoundedIcon className="icon" />
      </Button>
    );
  }

  async handleDeleteAttachment(id) {
    const response = await CorrispondenzaAPI.DeleteCorrispondenzaAttachment(id);
    if (response && response.result) {
      this.props.addAlert.current.addAlert("success", "Allegato eliminato");
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "Eliminazione non riuscita"
      );
    }
    await this.refreshAttachmentTable();
  }

  async handleDownloadAttachment(id, data) {
    const _data = data?.path?.split(".");
    await CorrispondenzaAPI.DownloadCorrispondenzaAttachment(
      id,
      _data[_data.length - 1]
    );
    await this.refreshAttachmentTable();
  }

  onDeleteClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        this.handleDeleteAttachment(id);
      },
      reject: () => {}
    });
  }

  onDownloadClick(event, id, data) {
    event.stopPropagation();
    event.preventDefault();
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi scaricarlo?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        this.handleDownloadAttachment(id, data);
      },
      reject: () => {}
    });
  }

  isValidForm = async () => {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);

    this.setState({
      fieldValidations: fieldValidations
    });

    return true;
  };

  HandleChange(field, newValue) {
    var newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;
    this.setState({ RowItem: newRowItem });
  }

  HandleSaveClick = async () => {
    let corr = this.state.RowItem;

    if (corr.data instanceof Date) {
      corr.data = moment(corr.data).format(dateTimeFmt);
    }

    if (this.isValidForm()) {
      let files = [...this.state.files];
      if (this.state.emailText) {
        corr.testomail = this.state.emailText;
      }
      const _corr = {
        ...corr,
        sede_inps: !!this.state.RowItem?.prot ? (!!this.state.selSap?.sap ? this.state.selSap?.sap : this.state.RowItem?.sede_inps) : this.state.selSap?.sap ,
        altroDest: this.state.altroDest,
        protocolType: this.state.protocolType === 1 ? "Entrata" : "Uscita"
      };
      const isEdit = this.state.ModificaTitle === "Modifica" ? true : false;
      var sd = await this.props.onSaveClick(_corr, files, isEdit);
      if (sd) {
        let lastProt = await CorrispondenzaAPI.GetLastInsertedProtocol(
          new Date().getFullYear(),
          this.state.protocolType === 1 ? "Entrata" : "Uscita"
        );
        this.setState({
          lastInsertedProt: lastProt.result[0]
        });
      }
      return sd;
    }
  };

  removeFile(event) {
    if (this.state?.files) {
      const files = this.state?.files?.filter((file) => file !== event.file);
      this.setState({ files: files });
    }
  }

  async componentDidUpdate(prevProp, prevState, snapshot) {
    if (prevProp.dataItem?.id !== this.props.dataItem?.id) {
      this.setState({ loadingGrid: true });
      const isChanging = this.props.dataItem?.id > 0;
      this.setState({
        ModificaTitle: isChanging ? "Modifica" : "Inserisci",
        RowItem: {...this.props.dataItem},
        selectedCaus: this.props.dataItem.causale?.codice,
        selectedTipo: this.props.dataItem.tipoinvio?.id,
        attachments: isChanging
          ? await CorrispondenzaAPI.GetCorrispondenzaPathAttach(
              this.props.dataItem?.id
            )
          : null
      });
      const type = this.state.protocolType === 1 ? "Entrata" : "Uscita";
      const sendType = this.props.dataItem.tipoinvio?.id;
      const disableAll = this.state.RowItem?.tipo?.toUpperCase() === "USCITA" && (sendType === 2 || sendType === 3);
      this.setState({ disableAll});
      this.setState({
        showLetterText: this.state.RowItem?.tipo?.toUpperCase() === "USCITA" && sendType == 1,
        emailText: this.state.RowItem?.testoMail
      });

      try {
        const company = await AziendeAPI.GetAziendaByMatr(this.props.dataItem.azienda);
        if (company){
          //QUI CHIAMIAMO API FACENDOCI RESTITUIRE LA LISTA DEGLI AMMINISTRATORI DI QUELL AZIENDA
          const administrators = await AmministratoriAPI.getAdministratorsByCompanyId(
            company.id
          );
          const _administrators = [];
          administrators.map((administrator) => {
            return _administrators.push(administrator.amministratore);
          });
          //QUI CHIAMIAMO API FACENDOCI RESTITUIRE LA LISTA DEGLI DIPENDENTI DI QUELL AZIENDA
          const employees = await DipendentiAPI.getEmployeesByCompanyId(company.id);
          const _employees = [];
          employees.map((employee) => {
            return _employees.push(employee.dipendente);
          });
          //QUI CHIAMIAMO API FACENDOCI RESTITUIRE LA LISTA DEI CONSULENTI DI QUELL AZIENDA
          const consultants = await ConsulentiAPI.getConsultantsByCompanyId(
            company.id
          );
          const _consultants = [];
          consultants.map((consultant) => {
            return _consultants.push(consultant.consulente);
          });
          
          //SETTO LE TRE LISTE NELLO STATE
          this.setState({
            adms: _administrators,
            dips: _employees,
            cons: _consultants
          });
        }
      } catch(err){}

      if (isChanging) {
        const selectedCons = this.props.dataItem.cfcons
          ? this.filterPersonByCF(
              this.state.cons,
              this.props.dataItem.cfcons
            )?.pop()
          : null;
        const selectedAdm = this.props.dataItem.cfamm
          ? this.filterPersonByCF(
              this.state.adms,
              this.props.dataItem.cfamm
            )?.pop()
          : null;
        const selectedDip = this.props.dataItem.cfdip
          ? this.filterPersonByCF(
              this.state.dips,
              this.props.dataItem.cfdip
            )?.pop()
          : null;

        this.setState({
          selectedEmployee: selectedDip,
          selectedAdm: selectedAdm,
          selectedCons: selectedCons,
          protocolType: this.props.type === "Entrata" ? 1 : 2,
          showAdm: !!selectedAdm,
          showDip: !!selectedDip,
          showCons: !!selectedCons
        });  

        if(isChanging){
          this.setState({
            destMail : this.props.dataItem?.destmail?.split(", "),
          })
        }

        if(isChanging && this.props.dataItem.azienda){
          const company = await AziendeAPI.GetAziendaByMatr(this.props.dataItem.azienda);
          if(company){
            this.setState({
              showCompany: this.state.destMail?.includes(company?.email)
            });
          }
        }

        try{
          if(isChanging && !!this.state.RowItem.sede_inps){
            let sap = await SedeComuneProvAPI.searchBySapFull(this.state.RowItem.sede_inps);
            if(sap && sap.length > 0){
              this.setState({
                RowItem: {...this.state.RowItem, provInps: sap[0].prov}
              })
            }
          }
        } catch(err){}
      }

      this.setState({ loadingGrid: false });
    }
  }

  filterPersonByCF(people, cf) {
    return people?.filter((p) => p.cf.toUpperCase() === cf.toUpperCase());
  }

  canSendEmail() {
    return (
      (this.state.azienda?.email ||
        this.state.selectedAdm?.email ||
        this.state.selectedCons?.email ||
        this.state.selectedDip?.email) !== undefined
    );
  }

  handleChangeTipoInvio(e) {
    this.HandleChange("tipoinvio", e.target.value);
    if (e.target.value === 2) {
      this.HandleChange("mittmail", null);
      this.setState({
        showEmailText: true,
        showLetterText: false
      });
    } else if (e.target.value === 1)
      this.setState({
        showEmailText: false,
        showLetterText: true
      });
    else
      this.setState({
        showEmailText: false,
        showLetterText: false
      });
    this.setState({ selectedTipo: e.target.value });
    this.props.onChangeTipoInvio(e.target.value);
    this.setState({
      RowItem: {...this.state.RowItem, cfamm:null, cfdip:null, cfcons:null, dtlett: null},
      selectedAdm: null,
      selectedCons: null,
      selectedDip: null,
      showAdm: false,
      showCons: false,
      showDip: false
    });
  }

  buildOggetto(causObj) {
    const prot = this.state.lastInsertedProt + 1;
    const year = this.props.dataItem.anno;
    const type = this.state.RowItem.tipo[0];
    const matr = this.state.azienda?.matricola;
    const name = this.state.azienda?.denominazione;

    const causSub = causObj.oggetto
      .replace("(nome ragione sociale)", name)
      .replace("(matricola INPS)", matr);

    return `${causSub}`;
  }

  handleChangeCausale(e) {
    const company = this.state?.RowItem?.azienda;
    this.HandleChange("causale", e.target.value);
    if (this.state.protocolType !== 1 && company) {
      const causObj = this.state.Causale_List.find(
        (item) => item.codice === e.target.value
      );
      if (
        causObj &&
        causObj.oggetto &&
        this.state.RowItem.tipo?.toUpperCase() === "USCITA"
      ) {
        this.HandleChange("oggetto", this.buildOggetto(causObj));
      }
      this.handleTextEmail(
        `Buongiorno,\n ${causObj.testomail || ""}\n--\nFondo Coasco`
      );
      this.setState({
        selectedCaus: e.target.value,
        emailText: `Buongiorno,\n ${causObj.testomail || ""}\n--\nFondo Coasco`
      });
    } else
      this.setState({
        selectedCaus: e.target.value
      });
  }

  async getLastProt(corr) {
    const year = moment(corr.data, dateTimeFmt).toDate().getFullYear();
    const type = corr.tipo?.toUpperCase() || "USCITA";
    const lastProt = await CorrispondenzaAPI.GetLastInsertedProtocol(year, type)
      .then((response) => response.result?.pop())
      .catch((error) => {
        console.error(error);
        this.props.addAlert.current.addAlert(
          "danger",
          "Si è verificato un errore nel resupero numero protocollo inserito"
        );
      });
    return lastProt;
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });

    const type = this.state.protocolType === 1 ? "Entrata" : "Uscita";
    const sendType = this.props.dataItem.tipoinvio?.id;
    const disableAll = type?.toUpperCase() === "USCITA" && (sendType === 2 || sendType === 3 );
    this.setState({disableAll});
    

    const allProvince = await SedeComuneProvAPI.GetAllProvincieProvincie();
    const allSedi = await SedeComuneProvAPI.GetAllSede();
    const isChanging = this.props.dataItem?.id > 0;
    const lastProt = await this.getLastProt(this.props.dataItem);

    const { profile } = this.context;

    const userRes = await UtentiAPI.getUserByUsername(profile?.username);

    const rowItem = this.state.RowItem;

    this.setState({
      user: userRes[0],
      RowItem: {
        ...rowItem
      }
    });

    const userEmails = await EmailUtenteAPI.GetByUtente(userRes[0]?.utente);
    this.setState({
      userEmails
    });

    const sendTypeRes = await TipoInvioAPI.GetAll();

    this.setState({
      deletecolumns: [
        {
          header: "",
          body: this.DeleteAttachment,
          icon: "pi pi-times",
          label: "Delete",
          className: "p-button-danger p-button-outlined colDelete"
        }
      ],
      downloadcolumns: [
        {
          header: "",
          body: this.DownloadAttachmment,
          icon: "pi pi-times",
          label: "Download",
          className: "p-button-danger p-button-outlined colDownload"
        }
      ],
      tipoinvioList: sendTypeRes,
      RowItem: isChanging ? { ...this.props.dataItem, user: userRes[0] } : { ...this.props.dataItem, user: userRes[0], tipoinvio: this.state.selectedTipo},
      lastInsertedProt: lastProt,
      selectedTipo: isChanging ? this.props.dataItem.tipoinvio?.id : 2,
      selectedCaus: isChanging ? this.props.dataItem.causale?.codice : "",
      aziendaFilter: this.state.azienda?.matricola,
      azienda: this.state.azienda,
      provices: allProvince,
      Protocollo_List: await StaticLists.Corrisp_TipoProtocollo(),
      Causale_List: await CorrispondenzaAPI.GetCausaliForCorrispondenza(),
      W350_List: await StaticLists.Corrisp_W350(),
      W300_List: await StaticLists.Corrisp_W300(),
      Amm_List: await StaticLists.Corrisp_Amm(),
      INPS_List: allSedi,
      isVisible: isChanging,
      Buchi_List: await StaticLists.Corrisp_Buchi(),
      Cust_List: await StaticLists.Corrisp_Cust(),
      Puli_List: await StaticLists.Corrisp_Puli(),
      ModificaTitle: isChanging ? "Modifica" : "Nuovo",
      groupClass: isChanging ? "" : "hide",

      attachments: isChanging
        ? await CorrispondenzaAPI.GetCorrispondenzaPathAttach(
            this.props.dataItem?.id
          )
        : null,

      cons: this.props.people.cons,
      adms: this.props.people.adms,
      dips: this.props.people.dips,

      showAdm: this.props.dataItem.cfamm !== null,
      showCons: this.props.dataItem.cfcons !== null,
      showDip: this.props.dataItem.cfdip !== null
    });

    if(isChanging){
      this.setState({
        destMail : this.props.dataItem?.destmail?.split(", "),
      })
    }

    if(isChanging && !!this.props.dataItem.azienda) {
      //QUI ABBIAMO IN MANO SOLO LA MATRICOLA,
      //QUINDI CHIAMIAMO API PER PRENDERE L'AZIENDA COMPLETA CHE CI SERVE NELLE RIGHE DOPO
      const company = await AziendeAPI.GetAziendaByMatr(this.props.dataItem.azienda);
      if (company){
        //QUI CHIAMIAMO API FACENDOCI RESTITUIRE LA LISTA DEGLI AMMINISTRATORI DI QUELL AZIENDA
        const administrators = await AmministratoriAPI.getAdministratorsByCompanyId(
          company.id
        );
        const _administrators = [];
        administrators.map((administrator) => {
          return _administrators.push(administrator.amministratore);
        });
        //QUI CHIAMIAMO API FACENDOCI RESTITUIRE LA LISTA DEGLI DIPENDENTI DI QUELL AZIENDA
        const employees = await DipendentiAPI.getEmployeesByCompanyId(company.id);
        const _employees = [];
        employees.map((employee) => {
          return _employees.push(employee.dipendente);
        });
        //QUI CHIAMIAMO API FACENDOCI RESTITUIRE LA LISTA DEI CONSULENTI DI QUELL AZIENDA
        const consultants = await ConsulentiAPI.getConsultantsByCompanyId(
          company.id
        );
        const _consultants = [];
        consultants.map((consultant) => {
          return _consultants.push(consultant.consulente);
        });
        
        //SETTO LE TRE LISTE NELLO STATE
        this.setState({
          adms: _administrators,
          dips: _employees,
          cons: _consultants
        });

        //FACCIO MATCH DEL CONSULENTE PER PRESELEZIONARLO
        const selectedCons = this.props.dataItem.cfcons
        ? this.filterPersonByCF(
            this.state.cons,
            this.props.dataItem.cfcons
          )?.pop()
        : null;
        //FACCIO MATCH DEL AMMINISTRATORE PER PRESELEZIONARLO
        const selectedAdm = this.props.dataItem.cfamm
          ? this.filterPersonByCF(
              this.state.adms,
              this.props.dataItem.cfamm
            )?.pop()
          : null;
        //FACCIO MATCH DEL DIPENDENTE PER PRESELEZIONARLO
        const selectedDip = this.props.dataItem.cfdip
          ? this.filterPersonByCF(
              this.state.dips,
              this.props.dataItem.cfdip
            )?.pop()
          : null;
        //SETTO NELLO STATE I VALORI APPENA RICAVATI
        this.setState({
          selectedEmployee: selectedDip,
          selectedAdm: selectedAdm,
          selectedCons: selectedCons
        });        
        //PRENDO L'AMMINISTRATORE SELEZIONATO E CREO LA SUA LISTA DI EMAIL POSSIBILI
        let _emails = [];
        if (
          this.state.selectedAdm?.email ||
          this.state.selectedAdm?.email2
        )
          _emails = [
            {
              key: this.state.selectedAdm?.email,
              value: this.state.selectedAdm?.email
            }
          ];
        this.setState({
          administratorEmails: _emails
        });
        //CONS
        _emails = [];
        if (
          this.state.selectedCons?.email ||
          this.state.selectedCons?.email2
        )
          _emails = [
            {
              key: this.state.selectedCons?.email,
              value: this.state.selectedCons?.email
            }
          ];
        this.setState({
          consultantEmails: _emails
        });
        //DIP
        _emails = [];
        if (
          this.state.selectedEmployee?.email ||
          this.state.selectedEmployee?.email2
        )
          _emails = [
            {
              key: this.state.selectedEmployee?.email,
              value: this.state.selectedEmployee?.email
            }
          ];
        this.setState({
          employeeEmails: _emails
        });

        this.setState({
          showCompany: this.state.destMail?.includes(company?.email)
        });
      }
    }

    this.setState({
      showEmailText: this.state.selectedTipo === 2,
      emailText: this.state.RowItem?.testoMail
    })

    try{
      if(isChanging && !!this.state.RowItem.sede_inps) {
        let sap = await SedeComuneProvAPI.searchBySapFull(this.state.RowItem.sede_inps);
        if(sap && sap.length > 0){
          this.setState({
            RowItem: {...this.state.RowItem, provInps: sap[0].prov}
          })
        }
      }
    } catch(err){}

    this.setState({
      showLetterText: this.state.RowItem?.tipo?.toUpperCase() === "USCITA" && sendType == 1
    });

    this.setState({
      loadingGrid: false
    });

    try {
      this.componentRef.current.scrollIntoView();
    } catch(err){}
  }

  handleSenderEmail(account) {
    this.HandleChange("mittmail", account);
    this.setState({
      senderEmail: account
    });
  }

  handleRcipientEmail(account) {
    this.HandleChange("mitt_dest", account);
    this.setState({
      recipientEmail: account
    });
  }

  handleTextEmail(text) {
    this.HandleChange("testomail", text);
    this.setState({
      emailText: text
    });
  }

  SetNum(field, newValue, maxLength) {
    if (newValue.toString().length <= maxLength) {
      var newRowItem = this.state.RowItem;
      newRowItem[field] = newValue;

      this.setState({ RowItem: newRowItem });
    }
  }

  async onSelectMatricola(e) {
    const rowItem = this.state.RowItem;
    const company = await AziendeAPI.GetAziendaByMatr(e.value);

    if (company) {
      this.HandleChange("mitt_dest", company.denominazione);
    }
    try {
      this.setState({
        companyEmail: company?.email,
        azienda: company,
        aziendaFilter: company?.matricola,
        selectedINPS: null,
        selectedSap: "",
        RowItem: {
          ...rowItem,
          denominazione: company?.denominazione,
          company
        }
      });
      this.HandleChange("azienda", company?.matricola);
    } catch (err) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Si è verificato un errore sul server"
      );
    }

    const administrators = await AmministratoriAPI.getAdministratorsByCompanyId(
      company?.id
    );
    const _administrators = [];
    administrators.map((administrator) => {
      return _administrators.push(administrator.amministratore);
    });
    const employees = await DipendentiAPI.getEmployeesByCompanyId(company?.id);
    const _employees = [];
    employees.map((employee) => {
      return _employees.push(employee.dipendente);
    });
    const consultants = await ConsulentiAPI.getConsultantsByCompanyId(
      company?.id
    );
    const _consultants = [];
    consultants.map((consultant) => {
      return _consultants.push(consultant.consulente);
    });

    this.setState({
      adms: _administrators,
      dips: _employees,
      cons: _consultants
    });
    this.freshmanHandlerSedeInps("");
  }

  handleDropdownChange(value, propLabel) {
    this.HandleChange(propLabel, value.toUpperCase());
    if (propLabel === "cfamm") {
      const administrator = this.state.administrators.find(
        (adm) => adm?.amministratore?.cf === value
      );
      this.setState({
        selectedAdm: administrator?.amministratore
      });
    }
  }

  async OnInputBlurCheck(valToCheck, type) {
    let validationRules = null;

    type === "matricola"
      ? (validationRules = {
          matricola: "EQ-10"
        })
      : (validationRules = {
          iban: "EQ-27"
        });
    let fieldValidations = helpers.ValidateForm(
      this.state.RowItem,
      validationRules
    );

    this.setState({
      fieldValidations: fieldValidations
    });

    let existentMatricola = [];
    if (
      this.state.RowItem?.matricola?.length > 0 &&
      this.props.dataItem?.id === null &&
      type === "matricola"
    ) {
      existentMatricola = await AziendeAPI.GetAllMatricola(
        this.state.RowItem?.matricola
      );
      if (existentMatricola.length > 0) {
        this.props.addAlert.current.addAlert("warning", "Matricola esistente");
      }
    }
  }

  setAziendasFields(event) {
    this.setState({
      aziendaFilter: event.value
    });
    this.onSelectMatricola(event);
  }

  searchMatricola(event) {
    setTimeout(async () => {
      var list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByMatr(event.query)
          : [];
      this.setState({
        aziendasList: list
      });
    }, 250);
  }

  searchSap(event) {
    setTimeout(async () => {
      var list =
        event.query.trim().length >= 3
          ? await SedeComuneProvAPI.searchBySapFull(event.query)
          : [];
      let saplist = list.map((item) => item.sap);
      this.setState({
        sapList: saplist,
        listaSap: list
      });
    }, 250);
  }

  freshmanHandler(value) {
    if (value.trim().length === 0) {
      const rowItem = this.state.RowItem;
      this.setState({
        RowItem: {
          ...rowItem,
          mitt_dest: "",
          sap_matri: null,
          azienda : null,
          prov : null
        },
        azienda: null
      });
    }
    this.setState({ aziendaFilter: value });
  }

  freshmanHandlerSedeInps(value) {
    if (value.trim().length === 0) {
      this.setState({
        RowItem:{
          ...this.state.RowItem,
          sede_inps : null,
          provInps : null 
        },
        codsap : null,
        selSap : null
      })
    }else{
      let selSap = this.state.listaSap?.map((item) => {
        if (item.sap === value) return item;
      })[0];
      this.setState({
        codsap: value,
        selSap: selSap
      });
      this.freshmanHandler("");
    }
  }

  async handleTableClick(rowData) {
    const _rowData = rowData?.value;
    const paths = _rowData?.path?.split(".");
    const extension = paths[paths.length - 1];
    if (extension === "pdf") {
      const _data = _rowData.path?.split(".");
      await CorrispondenzaAPI.openPdf(_rowData?.id, _data[_data.length - 1]);
    }
  }

  render() {
    const dynamicDeleteColumns = this.state.deletecolumns?.map((col) => {
      return (
        <Column
          key={col.body}
          body={col.body}
          icon={col.icon}
          label={col.label}
          className={col.className}
          header={col.header}
        />
      );
    });

    const dynamicDownloadColumns = this.state.downloadcolumns?.map((col) => {
      return (
        <Column
          key={col.body}
          body={col.body}
          icon={col.icon}
          label={col.label}
          className={col.className}
          header={col.header}
        />
      );
    });
    const destinatariEmail = 
    <div>
      <br/>
      <label>Destinatari Email</label>
      <GridContainer>
      {this.state.destMail?.map(emailD => 
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: "-1vh" }}>
            <CustomInput id={emailD} formControlProps={{fullWidth: true}} inputProps={{disabled: true, value:emailD}}/>
          </GridItem>
      )}
      </GridContainer>
    </div>;

    const SelectRowComponent = ({
      label,
      propLabel,
      options,
      optionPropLabel,
      selectedNameProp
    }) => (
      <GridContainer>
        <GridItem xs={12} sm={12} md={4} className="select">
          {this.state.RowItem.cfamm}
          <CustomSelect
            labelText={label}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: this.props.disable,
              onChange: (event) =>
                this.handleDropdownChange(event.target.value, propLabel),
              value: this.state.RowItem.cfamm || ""
            }}
          >
            {options?.map((option) => (
              <MenuItem value={option[optionPropLabel]?.cf} key={option?.id}>
                {option[optionPropLabel]?.cf?.toUpperCase()}
              </MenuItem>
            ))}
          </CustomSelect>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Nominativo"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              value: this.state[selectedNameProp]?.nominativo || ""
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Email"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              value: this.state[selectedNameProp]?.email || ""
            }}
          />
        </GridItem>
      </GridContainer>
    );

    if (this.state.RowItem === null)
      return <LoaderSpinner fullHeight={false} />;
    else
      return (
        <div ref={this.componentRef}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.state.ModificaTitle} Protocollo
                  </h4>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Annulla"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.props.onAnnullaClick}
                    >
                      <UndoIcon />
                    </Button>
                  </Tooltip>
                  {this.props.children}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Anno"
                        id="anno"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value:
                            this.state.RowItem.anno || new Date().getFullYear()
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "4px" }}
                    >
                      <p
                        className="calendarParagraph"
                        style={{ color: "#565656" }}
                      >
                        <small>Data Prot.</small>
                      </p>
                      <Calendar
                        className="calendarCustomedForMUI"
                        id="dataprot"
                        view="date"
                        value={
                          moment(
                            this.state.RowItem.data,
                            dateTimeFmt
                          ).toDate() || new Date()
                        }
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          this.HandleChange(
                            "data",
                            moment(e.target.value).format(dateTimeFmt)
                          )
                        }
                        disabled={true} //!this.state.ModificaTitle !== "Nuovo"
                        style={{ background: "#eaea7b", color: "#000000" }}
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Prot"
                        id="prot"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.prot || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} className="select">
                      <Select
                        disabled={this.state.RowItem.prot}
                        label="Tipo Protocollo"
                        onChange={this.protocolTypeHandler}
                        value={this.state.protocolType}
                        options={this.state.protocolTypes}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText="Id"
                        id="id"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem?.id || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                      <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="lblNoMargin">
                        <small>Matricola</small>
                      </p>
                      <AutoComplete
                        value={this.state.aziendaFilter || this.state.RowItem?.azienda}
                        suggestions={this.state.aziendasList}
                        placeholder="Cerca per Matricola"
                        completeMethod={this.searchMatricola}
                        disabled={this.state.disableAll}
                        onChange={(e) => {
                          this.freshmanHandler(e.value);
                        }}
                        className="matricolaAutocomplete"
                        type="number"
                        onSelect={(e) => this.onSelectMatricola(e)}
                        style={{
                          background: this.state.RowItem.prot && "#eaea7b",
                          color: this.state.RowItem.prot && "#000000"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Ragione Sociale"
                        id="ragione"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.HandleChange("mitt_dest", event.target.value),
                          disabled: this.state.disableAll,
                          value: this.state.RowItem.mitt_dest || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText="SAP_MATRI"
                        id="sap_matri"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.HandleChange("sap_matri", event.target.value),
                          value:
                            this.state.azienda?.sede ||
                            this.state.RowItem?.sap_matri?.sap ||
                            "",
                          disabled: true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText="Prov"
                        id="Prov"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.HandleChange("prov", event.target.value),
                          value:
                            this.state.azienda?.prov ||
                            this.state.RowItem.prov ||
                            "",
                          disabled: true
                        }}
                      />
                    </GridItem>
                    {this.state.RowItem.mittmail && this.state.selectedTipo == 2 && (
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Mittente"
                          id="mitt"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.RowItem.mittmail || ""
                          }}
                        />
                      </GridItem>
                    )}
                  </GridContainer>
                  <GridContainer>
                    
                      <GridItem xs={12} sm={12} md={3}>
                        <p className="lblNoMargin">
                          <small>SAP Sede INPS</small>
                        </p>
                        <AutoComplete
                          value={this.state.codsap || this.state.RowItem?.sede_inps }
                          suggestions={this.state.sapList}
                          placeholder="Cerca per SAP"
                          completeMethod={this.searchSap}
                          onChange={(e) => {this.freshmanHandlerSedeInps(e.value)}
                          }
                           
                          className="matricolaAutocomplete"
                          type="number"
                          disabled={this.state.disableAll}
                          style={{
                            background: this.state.RowItem.prot && "#eaea7b",
                            color: this.state.RowItem.prot && "#000000"
                          }}
                        />
                      </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Prov. Sede INPS"
                        id="sedeCity"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value:
                            this.state.selSap?.prov ||
                            this.state.RowItem?.provInps ||
                            ""
                        }}
                      />
                    </GridItem>
                    {this.state.selectedTipo == 2 && this.state.protocolType == 2 && (
                        <GridItem xs={12} sm={12} md={6}>
                          <FormLabel>Indirizzata a</FormLabel>
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled = {this.state.ModificaTitle == "Modifica"}
                                  color="primary"
                                  checked={this.state.showCompany}
                                  onChange={(e) => {
                                    const rowItem = this.state.RowItem;
                                    this.setState({
                                      showCompany: e.checked,
                                      RowItem: {
                                        ...rowItem,
                                        sendCompanyEmail: e.checked
                                      }
                                    });
                                  }}
                                />
                              }
                              label="Azienda"
                              className="labelCheckbox"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={this.state.ModificaTitle=="Modifica"}
                                  color="primary"
                                  checked={this.state.showAdm}
                                  onChange={(e) => {
                                    this.setState({ showAdm: e.checked })
                                    if(!e.checked){
                                      const rowItem = this.state.RowItem;
                                      const _rowItem = {
                                        ...rowItem,
                                        administratorEmail : null,
                                        cfamm: null
                                      };
                                      this.setState({
                                        RowItem : _rowItem,
                                        selectedAdm : null 
                                      });
                                    }
                                  }}
                                />
                              }
                              label="Amministratore"
                              className="labelCheckbox"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled = {this.state.ModificaTitle == "Modifica"}
                                  color="primary"
                                  checked={this.state.showCons}
                                  onChange={(e) => {
                                    this.setState({ showCons: e.checked })
                                    if(!e.checked){
                                      const rowItem = this.state.RowItem;
                                      const _rowItem = {
                                        ...rowItem,
                                        consultantEmail : null,
                                        cfcons: null
                                      };
                                      this.setState({
                                        RowItem : _rowItem,
                                        selectedCons : null 
                                      });
                                    }
                                  }}
                                />
                              }
                              label="Consulente"
                              className="labelCheckbox"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled = {this.state.ModificaTitle == "Modifica"}
                                  color="primary"
                                  checked={this.state.showDip}
                                  onChange={(e) => {
                                    this.setState({ showDip: e.checked })
                                    if(!e.checked){
                                      const rowItem = this.state.RowItem;
                                      const _rowItem = {
                                        ...rowItem,
                                        employeeEmail : null,
                                        cfdip: null
                                      };
                                      this.setState({
                                        RowItem : _rowItem,
                                        selectedEmployee : null 
                                      });
                                    }
                                  }}
                                />
                              }
                              label="Dipendente"
                              className="labelCheckbox"
                            />
                          </FormGroup>
                        </GridItem>
                      )}
                  </GridContainer>
                  {this.state.showAdm && this.state.selectedTipo != 1 && this.state.protocolType == 2 &&(
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4} className="select">
                        <CustomSelect
                          labelText="Amministratore"
                          id="ammList"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: this.props.disable,
                            onChange: (e) => {
                              this.HandleChange(
                                "cfamm",
                                e.target.value.toUpperCase()
                              );
                              const selectedAdministrator =
                                this.state.adms.find(
                                  (adm) => adm.cf === e.target.value
                                );
                              let _emails = [];
                              if (
                                selectedAdministrator?.email ||
                                selectedAdministrator?.email2
                              )
                                _emails = [
                                  {
                                    key: selectedAdministrator?.email,
                                    value: selectedAdministrator?.email
                                  },
                                  {
                                    key: selectedAdministrator?.email2,
                                    value: selectedAdministrator?.email2
                                  }
                                ];
                              const rowItem = this.state.RowItem;
                              const _rowItem = {
                                ...rowItem,
                                administratorEmail: selectedAdministrator?.email
                              };
                              this.setState({
                                administratorEmails: _emails,
                                selectedAdm: selectedAdministrator,
                                RowItem: _rowItem
                              });
                            },
                            value: this.state.selectedAdm?.cf || ""
                          }}
                        >
                          {this.state.adms?.map((adm) => (
                            <MenuItem value={adm.cf} key={adm?.id}>
                              {adm?.cf?.toUpperCase()}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          id="admName"
                          labelText="Nominativo"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.selectedAdm?.nominativo || ""
                          }}
                        />
                      </GridItem>
                      {this.state.administratorEmails &&
                        this.state.administratorEmails.length > 0 && (
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            style={{ marginTop: "-1vh" }}
                          >
                            <CustomSelect
                              labelText="Email"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: this.props.disable,
                                onChange: (e) => {
                                  this.HandleChange(
                                    "administratorEmail",
                                    e.target.value
                                  );
                                  const rowItem = this.state.RowItem;
                                  this.setState({
                                    RowItem: {
                                      ...rowItem,
                                      administratorEmail: e.target.value
                                    }
                                  });
                                },
                                value:
                                  this.state.selectedAdm?.email || ""
                              }}
                            >
                              {this.state.administratorEmails?.map((email) => (
                                <MenuItem value={email.value} key={email.key}>
                                  {email?.value}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                          </GridItem>
                        )}
                    </GridContainer>
                  )}
                  {this.state.showCons && this.state.selectedTipo != 1 && this.state.protocolType == 2 &&(
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4} className="select">
                        <CustomSelect
                          labelText="Consulente"
                          id="consList"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: this.props.disable,
                            onChange: (e) => {
                              this.HandleChange(
                                "cfcons",
                                e.target.value.toUpperCase()
                              );
                              const selectedConsultant = this.state.cons.find(
                                (con) => con.cf === e.target.value
                              );
                              let _emails = [];
                              if (
                                selectedConsultant?.email ||
                                selectedConsultant?.email2
                              )
                                _emails = [
                                  {
                                    key: selectedConsultant?.email,
                                    value: selectedConsultant?.email
                                  },
                                  {
                                    key: selectedConsultant?.email2,
                                    value: selectedConsultant?.email2
                                  }
                                ];
                              const rowItem = this.state.RowItem;
                              const _rowItem = {
                                ...rowItem,
                                consultantEmail: selectedConsultant?.email
                              };
                              this.setState({
                                consultantEmails: _emails,
                                selectedCons: selectedConsultant,
                                RowItem: _rowItem
                              });
                            },
                            value: this.state.selectedCons?.cf || ""
                          }}
                        >
                          {this.state.cons?.map((cons) => (
                            <MenuItem value={cons.cf} key={cons?.id}>
                              {cons?.cf?.toUpperCase()}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          id="consName"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.selectedCons?.nominativo || ""
                          }}
                        />
                      </GridItem>
                      {this.state.consultantEmails &&
                        this.state.consultantEmails.length > 0 && (
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            style={{ marginTop: "-1vh" }}
                          >
                            <CustomSelect
                              labelText="Email"
                              formControlProps={{
                                fullWidth: true
                              }}
                              style={{ marginTop: "-3vh" }}
                              inputProps={{
                                disabled: this.props.disable,
                                onChange: (e) => {
                                  this.HandleChange(
                                    "consultantEmail",
                                    e.target.value
                                  );
                                  const rowItem = this.state.RowItem;
                                  this.setState({
                                    RowItem: {
                                      ...rowItem,
                                      consultantEmail: e.target.value
                                    }
                                  });
                                },
                                value: this.state.selectedCons?.email || ""
                              }}
                            >
                              {this.state.consultantEmails?.map((email) => (
                                <MenuItem value={email.value} key={email.key}>
                                  {email?.value}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                          </GridItem>
                        )}
                    </GridContainer>
                  )}
                  {this.state.showDip && this.state.selectedTipo != 1 && this.state.protocolType == 2 &&(
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4} className="select">
                        <CustomSelect
                          labelText="Dipendente"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: this.props.disable,
                            onChange: (e) => {
                              this.HandleChange(
                                "cfdip",
                                e.target.value.toUpperCase()
                              );
                              const selectedEmployee = this.state.dips.find(
                                (con) => con.cf === e.target.value
                              );
                              let _emails = [];
                              if (
                                selectedEmployee?.email ||
                                selectedEmployee?.email2
                              )
                                _emails = [
                                  {
                                    key: selectedEmployee?.email,
                                    value: selectedEmployee?.email
                                  },
                                  {
                                    key: selectedEmployee?.email2,
                                    value: selectedEmployee?.email2
                                  }
                                ];
                              const rowItem = this.state.RowItem;
                              const _rowItem = {
                                ...rowItem,
                                employeeEmail: selectedEmployee?.email
                              };
                              this.setState({
                                employeeEmails: _emails,
                                selectedEmployee: selectedEmployee,
                                RowItem: _rowItem
                              });
                            },
                            value: this.state.selectedEmployee?.cf || ""
                          }}
                        >
                          {this.state.dips?.map((dip) => (
                            <MenuItem value={dip.cf} key={dip?.id}>
                              {dip?.cf?.toUpperCase()}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.selectedEmployee?.nominativo || ""
                          }}
                        />
                      </GridItem>
                      {this.state.employeeEmails &&
                        this.state.employeeEmails.length > 0 && (
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            style={{ marginTop: "-1vh" }}
                          >
                            <CustomSelect
                              labelText="Email"
                              formControlProps={{
                                fullWidth: true
                              }}
                              style={{ marginTop: "-3vh" }}
                              inputProps={{
                                disabled: this.props.disable,
                                onChange: (e) => {
                                  this.HandleChange(
                                    "employeeEmail",
                                    e.target.value
                                  );
                                  const rowItem = this.state.RowItem;
                                  this.setState({
                                    RowItem: {
                                      ...rowItem,
                                      employeeEmail: e.target.value
                                    }
                                  });
                                },
                                value: this.state?.selectedEmployee?.email || ""
                              }}
                            >
                              {this.state.employeeEmails?.map((email) => (
                                <MenuItem value={email.value} key={email.key}>
                                  {email?.value}
                                </MenuItem>
                              ))}
                            </CustomSelect>
                          </GridItem>
                        )}
                    </GridContainer>
                  )}
                  {this.state.destMail && destinatariEmail}
                  <GridContainer>
                   
                    <GridItem xs={12} sm={12} md={6} className="select">
                      <CustomSelect                        
                        labelText="Causale"
                        id="caus"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled : this.state.disableAll,
                          onChange: this.handleChangeCausale,
                          value: this.state.selectedCaus || ""
                        }}
                      >
                        {this.state.Causale_List?.map((item) => (
                          <MenuItem value={item.codice} key={item.codice}>
                            {item.codice + " - " + item.descrizione}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                    {this.state.selectedTipo == 1 && (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "4px" }}
                    >
                      <p
                        className="calendarParagraph"
                        style={{ color: "#565656" }}
                      >
                        <small>Data Lettera</small>
                      </p>
                      <Calendar
                        className="calendarCustomedForMUI"
                        id="dataconst"
                        view="date"
                        value={moment(
                          this.state.RowItem.dtlett,
                          dateTimeFmt
                        ).toDate()}
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          this.HandleChange(
                            "dtlett",
                            moment(e.target.value).format(dateTimeFmt)
                          )
                        }
                        style={{
                          background:
                            !this.state.disableAll
                              ? "#ffffff"
                              : "#eaea7b",
                          color: "#000000"
                        }}
                        disabled={
                          this.state.disableAll
                        }
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                      />
                    </GridItem>
                    )}
                    <GridItem xs={12} sm={12} md={3} className="select">
                      <CustomSelect
                        labelText="Tipo Invio"
                        id="tipoinvioList"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disableAll,
                          onChange: this.handleChangeTipoInvio,
                          value: this.state.selectedTipo
                        }}
                      >
                        {this.state?.tipoinvioList?.map((item) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item?.id} - {item?.tipoinvio}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={10}>
                      <CustomInput
                        labelText="Oggetto"
                        id="ogg"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.HandleChange("oggetto", event.target.value),
                          value: this.state.RowItem.oggetto || "",
                          disabled:
                            this.state.disableAll
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Tabulati"
                        id="tab"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled : this.state.disableAll,
                          type: "number",
                          onChange: (event) =>
                            this.HandleChange("numtab", event.target.value),
                          value: this.state.RowItem.numtab || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Preavvisi"
                        id="pre"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled : this.state.disableAll,
                          type: "number",
                          onChange: (event) =>
                            this.HandleChange("numpreav", event.target.value),
                          value: this.state.RowItem.numpreav || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2} className="select">
                      <CustomSelect
                        labelText="W300"
                        id="w300"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled : this.state.disableAll,
                          onChange: (event) =>
                            this.HandleChange("w300", event.target.value),
                          value: this.state.RowItem.w300 || ""
                        }}
                      >
                        {this.state.W300_List?.map((item, i) => (
                          <MenuItem value={item?.id} key={i}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2} className="select">
                      <CustomSelect
                        labelText="Puli"
                        id="Puli"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled : this.state.disableAll,
                          onChange: (event) =>
                            this.HandleChange("puli", event.target.value),
                          value: this.state.RowItem.puli || ""
                        }}
                      >
                        {this.state.Puli_List?.map((item) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2} className="select">
                      <CustomSelect
                        labelText="Amm"
                        id="Amm"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled : this.state.disableAll,
                          onChange: (event) =>
                            this.HandleChange("amm", event.target.value),
                          value: this.state.RowItem.amm || ""
                        }}
                      >
                        {this.state.Amm_List?.map((item) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Fatture"
                        id="fa"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled : this.state.disableAll,
                          type: "number",
                          onChange: (event) =>
                            this.HandleChange("numfat", event.target.value),
                          value: this.state.RowItem.numfat || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Contabili"
                        id="co"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled : this.state.disableAll,
                          type: "number",
                          onChange: (event) =>
                            this.HandleChange(
                              "ncontabbanc",
                              event.target.value
                            ),
                          value: this.state.RowItem.ncontabbanc || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2} className="select">
                      <CustomSelect
                        labelText="W350"
                        id="W350"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled : this.state.disableAll,
                          onChange: (e) =>
                            this.HandleChange("w350", e.target.value),
                          value: this.state.RowItem.w350 || ""
                        }}
                      >
                        {this.state.W350_List?.map((item) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2} className="select">
                      <CustomSelect
                        labelText="Cust"
                        id="Cust"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled : this.state.disableAll,
                          onChange: (e) =>
                            this.HandleChange("cust", e.target.value),
                          value: this.state.RowItem.cust || ""
                        }}
                      >
                        {this.state.Cust_List?.map((item) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} className="select">
                      <CustomSelect
                        labelText="Pr. Buchi Contr."
                        id="Buchi"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled : this.state.disableAll,
                          onChange: (e) =>
                            this.HandleChange("prbuchicont", e.target.value),
                          value: this.state.RowItem.prbuchicont || ""
                        }}
                      >
                        {this.state.Buchi_List?.map((item) => (
                          <MenuItem value={item?.id} key={item?.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>
                  {this.state.ModificaTitle !== "Nuovo" && this.state.RowItem?.tipo === "USCITA" 
                    && this.state.RowItem?.tipoinvio?.id === 2 && (
                      <GridContainer style={{ marginBottom: "2vh", marginTop : "2vh" }}>
                        <GridItem xs={12} sm={12} md={6}>              
                          <label htmlFor="testoMail">Testo Email</label>
                          <InputTextarea id="testoMail"
                            disabled={true}
                            autoResize
                            value={this.props.dataItem?.testoMail || ""} 
                            />                   
                        </GridItem>
                    </GridContainer>
                  )}
                </CardBody>


                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={
                      this.state.protocolType !== 1 &&
                      (this.state.showEmailText || this.state.showLetterText)
                        ? 6
                        : 11
                    }
                    className="upload"
                  >
                    {!this.state.disableAll && (
                      <Card>
                        <CardHeader color="info">
                          <h4
                            style={{
                              lineHeight: "1",
                              display: "inline",
                              verticalAlign: "bottom"
                            }}
                          >
                            Selezione Allegato
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <FileUpload
                            url=""
                            name="attachs"
                            customUpload
                            uploadLabel={"Aggiungi"}
                            uploadHandler={this.onUpload}
                            onRemove={this.removeFile}
                            accept=".txt,.pdf,.csv,.xlsx,.docx,.doc"
                            maxFileSize={100_000_000}
                            onClear={() => this.setState({ files: [] })}
                            emptyTemplate={
                              <p className="p-m-0">Seleziona file</p>
                            }
                          />
                        </CardBody>
                      </Card>
                    )}
                  </GridItem>

                  {this.state.showLetterText && this.state.protocolType !== 1 && (
                      <GridItem xs={12} sm={12} md={6}>
                        <TestoLettera
                          accounts={StaticLists.Corrisp_Account()}
                          setAccount={this.handleSenderEmail}
                          selectedAccount={this.state.senderEmail}
                          text={this.state.emailText}
                          setEmailText={this.handleTextEmail}
                          companyFreshman={this.state.RowItem?.azienda}
                          recipientEmail={this.state.recipientEmail}
                          setRecipientEmail={this.handleRcipientEmail}
                          userEmails={this.state.userEmails}
                        />
                      </GridItem>
                    )}

                  {this.state.showEmailText && this.state.protocolType !== 1 && this.state.ModificaTitle !== 'Modifica' && (
                    <GridItem xs={12} sm={12} md={6}>
                      <TestoEmail
                        accounts={StaticLists.Corrisp_Account()}
                        setAccount={this.handleSenderEmail}
                        selectedAccount={this.state.senderEmail}
                        text={this.state.emailText}
                        setEmailText={this.handleTextEmail}
                        companyFreshman={this.state.RowItem?.azienda}
                        recipientEmail={this.state.recipientEmail}
                        setRecipientEmail={this.handleRcipientEmail}
                        userEmails={this.state.userEmails}
                        altroDest={this.state.altroDest ?? ""}
                        setAltroDest={(val) =>
                          this.setState({ altroDest: val })
                        }
                      />
                    </GridItem>
                  )}
                </GridContainer>

                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm table"
                  value={this.state.attachments?.result}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="path"
                    header="Allegati"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterPlaceholder=""
                  />
                  {!this.state.disableAll && dynamicDeleteColumns}
                  {dynamicDownloadColumns}
                </DataTable>
                {!(this.state.ModificaTitle =="Modifica"  && this.state.disableAll) && (
                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                </CardFooter>
                )}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}
