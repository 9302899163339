import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";

// Components
import Button from "components/CustomButtons/Button.js";

// Helpers
import { rowsPerPageOptions } from "../helpers/AppConstants";

class Table extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      emptyMessage,
      tableData,
      firstRowToDisplay,
      setFirstRowToDisplay,
      rowsForPage,
      setRowsForPage,
      isLoading,
      columns,
      downloadHandler
    } = this.props;
    const tableClassName = "p-datatable-striped p-datatable-sm";
    const downloadBtnClassName =
      "actionButton actionButtonRound btnRound float-right";
    const paginatorTemplate =
      "CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown";
    const currentPageReportTemplate =
      "Visualizzati {first} - {last} di {totalRecords}";

    function pageHandler(event) {
      setFirstRowToDisplay(event.first);
      setRowsForPage(event.rows);
    }

    const downloadColumn = (rowData) => {
      return (
        rowData?.stato !== "IN CORSO" && (
          <Button
            round
            color="primary"
            className={downloadBtnClassName}
            onClick={() => downloadHandler(rowData)}
          >
            <GetAppRoundedIcon className="icon" />
          </Button>
        )
      );
    };

    return (
      <DataTable
        dataKey="id"
        emptyMessage={emptyMessage}
        className={tableClassName}
        value={tableData}
        onPage={pageHandler}
        first={firstRowToDisplay}
        paginator
        paginatorTemplate={paginatorTemplate}
        currentPageReportTemplate={currentPageReportTemplate}
        rows={rowsForPage}
        rowsPerPageOptions={rowsPerPageOptions}
        loading={isLoading}
        paginatorLeft={this.paginatorLeft}
        paginatorRight={this.paginatorRight}
      >
        {columns?.map((column) => (
          <Column
            key={column?.field}
            field={column?.field}
            header={column?.header}
          />
        ))}
        <Column body={downloadColumn} />
      </DataTable>
    );
  }
}

export default Table;
