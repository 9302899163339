import Utils from "./Utils";
import * as AppConstants from "../helpers/AppConstants";

const baseURL = AppConstants.SiteUrl + "ebinprof/TipoEsito";

const TipoBorseAPI = {
  GetAll: async function () {
    const response = await Utils.SendPostRequestTo(`${baseURL}/getall`);
    return response.result;
  }
};
export default TipoBorseAPI;
