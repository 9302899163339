import Utils from "./Utils";
import * as AppConstants from "../helpers/AppConstants";
import moment from "moment";

const partialURL = "ebinprof/RichiedBS";
const baseURL = AppConstants.SiteUrl + partialURL;

const RichiedentiAPI = {
  getByCF: async function (cf) {
    const response = await Utils.SendPostRequestTo(
      `${baseURL}/getByCf?cf=${cf}`
    );
    return response.result;
  },

  GetRichiedenti: async function () {
    return await Utils.SendPostRequestTo(`${baseURL}/getall`);
  },

  GetRichiedentiForCorrispondenza: async function (richiedentiId) {
    return { message: "not implemented yet", result: [] };
  },

  async GetRichiedentiBorseStudio(richiedentiId) {
    const response = await Utils.SendPostRequestTo(
      `${baseURL}/getById?codice=${richiedentiId}`
    );
    return response.result?.pop();
  },

  getByFilter: async function (start, size, sortField, sortOrder, filters) {
    let _filters;
    if (filters && typeof filters !== "undefined") _filters = filters;
    else _filters = null;
    let res;
    if (typeof sortField === "undefined" || !sortField) {
      res = await Utils.SendPostRequestTo(
        `${baseURL}/getByFilter?start=${start}&size=${size}${
          filters ? `&${_filters}` : ""
        }`
      );
    } else {
      res = await Utils.SendPostRequestTo(
        `${baseURL}/getByFilter?start=${start}&size=${size}&sortfield=${sortField}&sortorder=${
          sortOrder === 1 ? "ASC" : "DESC"
        }${filters ? `&${_filters}` : ""}`
      );
    }

    return res;
  },

  async GetNuovoRichiedentiBorseStudio() {
    return {
      cap: "",
      cell: "",
      cfRich: "",
      cognome: "",
      comune: "",
      dataAgg: moment().format(AppConstants.dateTimeFmt),
      dataIns: moment().format(AppConstants.dateTimeFmt),
      dataNasc: null,
      disabile: null,
      email: "",
      idRichied: null,
      indirizzo: "",
      localita: " ",
      luogoNasc: "",
      nazione: "",
      nome: "",
      orfano: null,
      privacy: null,
      prov: "",
      reg: null,
      sesso: null,
      studLav: null,
      tel: "",
      utente: 3
    };
  },

  AddRichiedenti: async function (richiedentiObj) {
    return await Utils.SendPostRequestTo(baseURL + "/insert", richiedentiObj);
  },

  UpdateRichiedenti: async function (richiedentiObj) {
    return await Utils.SendPutRequestTo(baseURL + "/update", richiedentiObj);
  },

  DeleteRichiedenti: async function (richiedentiId) {
    return await Utils.CreateDeleteRequest(
      `${partialURL}/delete?id=${richiedentiId}`
    );
  },

  StampaPDF: async function (richiedentiId, fileName) {
    return await Utils.SendPostRequestToNoJson(
      `${baseURL}/stampaPdf?id=${richiedentiId}`,
      null,
      fileName
    );
  }
};
export default RichiedentiAPI;
