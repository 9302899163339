import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import Button from "components/CustomButtons/Button.js";
import Switch from "@material-ui/core/Switch";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CustomButtons from "components/CustomButtons/Button.js";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import AddIcon from "@material-ui/icons/Add";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import queryString from "query-string";
import Helpers from "helpers/Helpers.js";
import { Dropdown } from "primereact/dropdown";
import StaticLists from "helpers/StaticLists";
import TabulatiAPI from "API/TabulatiAPI";
import SedeComuneProvAPI from "API/SedeComuneProvAPI";
import LoaderSpinner from "../../../src/components/Layout/LoaderSpinner.js";
import moment from "moment";
import { dateTimeFmt, rowsPerPageOptions } from "helpers/AppConstants.js";
import { dateFmt } from "helpers/AppConstants.js";

class ElenchiTabulati extends React.Component {
  state = {
    rowsCodiceSedeAnno: [],
    rowsDettagli: [],

    //grid
    selectedGrid: null,
    selectedGridDettagli: null,
    selectedGridCodiceSedeAnno: null,
    sedeFromSelectedGrid: null,
    loadingGrid: false,
    loadingGridDettagli: false,
    loadingGridCodiceSedeAnno: false,
    //  filterGrid:
    visibleLoader: false,

    filterBySedeCode: "",
    Sede: [],
    year: null,
    selectedSede: null,
    CodiceQuota: [],

    //footer
    erroreFooter: "",
    diffaccrFooter: "",
    nettodaacrrFooter: "",
    accreditatoFooter: "",
    difffattFooter: "",
    fatturatoFooter: "",
    speseFooter: "",
    bolloFooter: "",
    importoFooter: "",
    codiceFromSelectedGrid: null,
    righeFooter: 0,

    idSede: 0,
    idCodice: 0,

    initialRows: [],
    filteredRows: [], //renamed

    firstRowToDisplay: 0,
    rowsPerPage: 5
  };

  constructor(props) {
    super(props);
    this.HandleChange = this.HandleChange.bind(this);
    this.btnSearchRef = React.createRef();
    this.handleTableClick = this.handleTableClick.bind(this);
    this.DocActionsGridTemplate = this.DocActionsGridTemplate.bind(this);
    this.onRedirectClick = this.onRedirectClick.bind(this);
    this.handleTableClickCodiceSedeAnno =
      this.handleTableClickCodiceSedeAnno.bind(this);
    this.handleTableClickDettagli = this.handleTableClickDettagli.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.ChangeFilterGridDettagli = this.ChangeFilterGridDettagli.bind(this);
    this.ChangeFilterGridCodiceSedeAnno =
      this.ChangeFilterGridCodiceSedeAnno.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.getFilteredRows = this.getFilteredRows.bind(this);
    this.getTableFooter = this.getTableFooter.bind(this);
  }

  DocActionsGridTemplate(rowData) {
    return (
      <>
        <Button
          round
          color="info"
          className="actionButton actionButtonRound btnRound  "
          key={rowData.Id}
          onClick={(e) => this.onRedirectClick(e, rowData)}
        >
          <VisibilityIcon className="icon" />
        </Button>
      </>
    );
  }

  onNuovoClick() {
    var maintainSearch = "&maintainSearch=1";
    this.props.history.push(
      "/admin/tabulati/" +
        "0" +
        "?c=" +
        this.state.idCodice +
        "&s=" +
        this.state.idSede +
        "&a=" +
        this.state.rowsCodiceSedeAnno[0].riparto +
        maintainSearch
    );
  }

  onRedirectClick(e, rowData) {
    e.stopPropagation();
    var maintainSearch = "?maintainSearch=1";
    localStorage.setItem(
      "elenchiTabulati:selectedSede",
      JSON.stringify(this.state.selectedSede)
    );
    localStorage.setItem(
      "elenchiTabulati:selectedGrid",
      JSON.stringify(this.state.selectedGrid)
    );
    localStorage.setItem(
      "elenchiTabulati:selectedGridCodiceSedeAnno",
      JSON.stringify(this.state.selectedGridCodiceSedeAnno)
    );
    localStorage.setItem(
      "elenchiTabulati:firstRowToDisplay",
      JSON.stringify(this.state.firstRowToDisplay)
    );
    this.props.history.push(
      "/admin/tabulati/" +
        rowData.id +
        maintainSearch +
        "/" +
        rowData.codice +
        "/" +
        rowData.sede +
        "/" +
        rowData.riparto +
        "/" +
        this.state.selectedGridCodiceSedeAnno.chiuso
    );
  }

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });

    if (val !== null) {
      this.setState({
        filterBySedeCode: val.cod
      });
    }
  }

  async componentDidMount() {
    var allSedi = await SedeComuneProvAPI.GetAllSede();

    this.setState({
      visibleLoader: true,
      Sede: allSedi.map((x) => {
        return { sap: x.sap, descrizione: x.sap + " - " + x.descrizione };
      }),
      CodiceQuota: StaticLists.CodiceQuota()
    });

    var initialRows = await TabulatiAPI.GetTabulatiPerSedeAnno();
    let filterBySedeCode = this.state.filterBySedeCode;

    var queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      var savedFilters = Helpers.GetSearchFilters("TabulatiList");
      if (savedFilters !== null) {
        filterBySedeCode = savedFilters.filterBySedeCode;
      }
    } else Helpers.SaveSearchFilters(null, "TabulatiList");

    this.setState({
      loadingGrid: true
    });

    this.setState(
      {
        initialRows: initialRows,

        filterBySedeCode: filterBySedeCode
      },
      async () => {
        let filteredRows = this.getFilteredRows();

        var selectedRow = null;
        var firstRowToDisplay = 0;

        var tabulatiId = this.props.match.params.tabulatiId;
        if (tabulatiId > 0) {
          selectedRow = Helpers.ReturnRowFromListById(filteredRows, tabulatiId);
          firstRowToDisplay = Helpers.GetFirstElementOfPageIncludingId(
            tabulatiId,
            filteredRows,
            10
          );
        }

        this.setState(
          {
            firstRowToDisplay: firstRowToDisplay,
            filteredRows: filteredRows
          },
          async () => await this.getTableFooter()
        );

        if (selectedRow)
          await this.handleTableClick({
            value: selectedRow
          });
      }
    );

    const selectedGrid = JSON.parse(
        localStorage.getItem("elenchiTabulati:selectedGrid")
      ),
      selectedGridCodiceSedeAnno = JSON.parse(
        localStorage.getItem("elenchiTabulati:selectedGridCodiceSedeAnno")
      ),
      selectedSede = JSON.parse(
        localStorage.getItem("elenchiTabulati:selectedSede")
      ),
      firstRowToDisplay = JSON.parse(
        localStorage.getItem("elenchiTabulati:firstRowToDisplay")
      );

    if (selectedGrid && selectedGridCodiceSedeAnno) {
      if (selectedSede) {
        this.HandleChange("selectedSede", selectedSede);
        await this.handleSearchClick();
        localStorage.removeItem("elenchiTabulati:selectedSede");
      }

      await this.handleTableClick({ value: selectedGrid });
      await this.handleTableClickCodiceSedeAnno({
        value: selectedGridCodiceSedeAnno
      });

      this.setState({ firstRowToDisplay: firstRowToDisplay });

      localStorage.removeItem("elenchiTabulati:selectedGrid");
      localStorage.removeItem("elenchiTabulati:selectedGridCodiceSedeAnno");
      localStorage.removeItem("elenchiTabulati:firstRowToDisplay");
    }

    this.setState({
      visibleLoader: false,
      loadingGrid: false
    });
  }

  async getTableFooter() {
    if (this.state.filteredRows.length > 0) {
      var i,
        righe = 0,
        errore = 0,
        diffaccr = 0,
        nettodaacrr = 0,
        accreditato = 0,
        difffatt = 0,
        fatturato = 0,
        spese = 0,
        bollo = 0,
        importo = 0;
      for (i = 0; i < this.state.filteredRows.length; i++) {
        righe += this.state.filteredRows[i].righe;
        errore += this.state.filteredRows[i].errore;
        diffaccr += this.state.filteredRows[i].diffaccr;
        nettodaacrr += this.state.filteredRows[i].nettodaacrr;
        accreditato += this.state.filteredRows[i].accreditato;
        difffatt += this.state.filteredRows[i].difffatt;
        fatturato += this.state.filteredRows[i].fatturato;
        spese += this.state.filteredRows[i].spese;
        bollo += this.state.filteredRows[i].bollo;
        importo += this.state.filteredRows[i].importo;
      }

      this.setState({
        erroreFooter: Helpers.DecimalInputTemplate(errore),
        diffaccrFooter: Helpers.DecimalInputTemplate(diffaccr),
        nettodaacrrFooter: Helpers.DecimalInputTemplate(nettodaacrr),
        accreditatoFooter: Helpers.DecimalInputTemplate(accreditato),
        difffattFooter: Helpers.DecimalInputTemplate(difffatt),
        fatturatoFooter: Helpers.DecimalInputTemplate(fatturato),
        speseFooter: Helpers.DecimalInputTemplate(spese),
        bolloFooter: Helpers.DecimalInputTemplate(bollo),
        importoFooter: Helpers.DecimalInputTemplate(importo),
        righeFooter: righe
      });
    }
  }

  getFilteredRows() {
    var filteredRows = this.state.initialRows;

    if (this.state.filterBySedeCode)
      filteredRows = filteredRows.filter(
        (row) =>
          row.sede
            .toLowerCase()
            .indexOf(this.state.filterBySedeCode.toString().toLowerCase()) >= 0
      );

    return filteredRows;
  }

  ChangeFilterGrid(e) {
    this.setState({
      filterGrid: e.target.checked
    });
  }

  ChangeFilterGridDettagli(e) {
    this.setState({
      filterGridDettagli: e.target.checked
    });
  }

  ChangeFilterGridCodiceSedeAnno(e) {
    this.setState({
      filterGridCodiceSedeAnno: e.target.checked
    });
  }

  handleSearchClick = async () => {
    Helpers.SaveSearchFilters(
      {
        filterBySedeCode: this.state.filterBySedeCode
      },
      "TabulatiList"
    );

    this.setState(
      {
        selectedGrid: null,
        selectedGridCodiceSedeAnno: null,
        filteredRows: await TabulatiAPI.GetTabulatiPerSedeAnnoSede(
          this.state.selectedSede
        )
      },
      async () => await this.getTableFooter()
    );
  };

  async handleClearClick() {
    Helpers.SaveSearchFilters(null, "TabulatiList");
    this.setState({
      selectedSede: null,
      loadingGrid: true
    });
    this.setState(
      {
        selectedGrid: null,
        filteredRows: await TabulatiAPI.GetTabulatiPerSedeAnno(),
        selectedGridDettagli: null,
        filterBySedeCode: ""
      },
      async () => await this.getTableFooter()
    );
    this.setState({
      loadingGrid: false
    });
  }

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: null,
      selectedGridCodiceSedeAnno: null,
      selectedGridDettagli: null,
      loadingGridCodiceSedeAnno: true
    });
    const response = await TabulatiAPI.GetTabulatiPerCodiceSedeAnno(
      null,
      e.value.sede,
      e.value.anno
    );
    if (response && response.result) {
      this.setState({
        selectedGrid: e.value,
        year: e.value.anno,
        sedeFromSelectedGrid: e.value.sede,
        rowsCodiceSedeAnno: response.result
      });
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "Errore nella ricezione dei dati. Riprovare più tardi."
      );
    }

    this.setState({
      loadingGridCodiceSedeAnno: false
    });
  };

  handleTableClickCodiceSedeAnno = async (e) => {
    this.setState({
      loadingGridDettagli: true
    });
    this.setState({
      selectedGridCodiceSedeAnno: e.value,
      codiceFromSelectedGrid: e.value.codice,
      rowsDettagli: await TabulatiAPI.GetDettagliTabulati(
        null,
        e.value.codiceQ,
        this.state.sedeFromSelectedGrid,
        e.value.anno
      )
    });
    this.setState({
      loadingGridDettagli: false
    });

    if (
      this.state.rowsCodiceSedeAnno !== null &&
      this.state.rowsCodiceSedeAnno.length > 0
    ) {
      this.setState({
        idSede: Helpers.ReturnIdFromListByType(
          this.state.Sede,
          "Sede",
          this.state.rowsCodiceSedeAnno[0].sede
        ),
        idCodice: Helpers.ReturnIdFromListByType(
          this.state.CodiceQuota,
          "Codice",
          this.state.rowsCodiceSedeAnno[0].codice
        )
      });
    }
  };

  handleTableClickDettagli = async (e) => {
    this.setState({
      selectedGridDettagli: e.value
    });
  };

  render() {
    return (
      <>
        {this.state.visibleLoader && <LoaderSpinner fullHeight={true} />}
        <div ref={this.btnSearchRef} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Tabulati
                </h4>
              </CardHeader>
              <CardBody style={{ paddingTop: "4px", paddingBottom: "8px" }}>
                <GridContainer>
                  <GridItem xs={3}>
                    <p className="lblNoMargin">Sede</p>
                    <Dropdown
                      autofocus
                      emptyMessage="Sede non trovata"
                      emptyFilterMessage="Sede non trovata"
                      display="chip"
                      value={this.state.selectedSede}
                      required={true}
                      optionValue="sap"
                      resetFilterOnHide
                      options={this.state.Sede}
                      onChange={(e) =>
                        this.HandleChange("selectedSede", e.value)
                      }
                      optionLabel="descrizione"
                      filter
                      filterBy="descrizione"
                    />
                  </GridItem>
                  <GridItem xs={2} style={{ marginTop: "18px" }}>
                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Cerca"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <Button
                        ref={this.btnSearchRef}
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%",
                          marginLeft: "0",
                          marginTop: "0px",
                          marginRight: "4px"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        onClick={this.handleSearchClick}
                      >
                        <Search />
                      </Button>
                    </Tooltip>

                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Reimposta"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <Button
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%",
                          marginRight: "0"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        onClick={this.handleClearClick}
                      >
                        <Clear />
                      </Button>
                    </Tooltip>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofsuccess">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Tabulati per Sede-Anno{" "}
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.filteredRows}
                  dataKey="key"
                  first={this.state.firstRowToDisplay}
                  onPage={(e) => this.setState({ firstRowToDisplay: e.first, rowsPerPage: e.rows })}
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage} rowsPerPageOptions={rowsPerPageOptions}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="sede"
                    header="Sede"
                    headerStyle={{ width: "40px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="anno"
                    header="Anno"
                    headerStyle={{ width: "40px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="righe"
                    header="Righe"
                    footer={this.state.righeFooter}
                    headerStyle={{ width: "40px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importo"
                    header="Importo"
                    footer={this.state.importoFooter}
                    headerStyle={{ width: "80px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "importo")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="bollo"
                    header="Bollo"
                    footer={this.state.bolloFooter}
                    headerStyle={{ width: "80px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "bollo")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="spese"
                    header="Spese"
                    footer={this.state.speseFooter}
                    headerStyle={{ width: "80px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "spese")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="fatturato"
                    header="Fatturato"
                    footer={this.state.fatturatoFooter}
                    headerStyle={{ width: "80px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "fatturato")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="diffFatt"
                    header="DiffFatt"
                    footer={this.state.difffattFooter}
                    headerStyle={{ width: "80px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "difffatt")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="nettoDaAcrr"
                    header="NettoDaAcrr"
                    footer={this.state.nettodaacrrFooter}
                    headerStyle={{ width: "80px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "nettodaacrr")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="accreditato"
                    header="Accreditato"
                    footer={this.state.accreditatoFooter}
                    headerStyle={{ width: "80px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "accreditato")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="diffAccr"
                    header="DiffAccr"
                    footer={this.state.diffaccrFooter}
                    headerStyle={{ width: "80px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "diffaccr")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="errore"
                    header="Errore"
                    footer={this.state.erroreFooter}
                    headerStyle={{ width: "60px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "errore")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.selectedGrid && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="ebinprofsuccess">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    Tabulati per Codice-Sede-Anno{" "}
                  </h4>
                  <FormControlLabel
                    className="float-right"
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%",
                      color: "white"
                    }}
                    control={
                      <Switch
                        checked={this.state.filterGridCodiceSedeAnno}
                        onChange={this.ChangeFilterGridCodiceSedeAnno}
                        name="chkFilterGrid"
                        color="default"
                      />
                    }
                    label={"Attiva Filtri"}
                  />
                </CardHeader>
                <CardBody>
                  <DataTable
                    className="p-datatable-striped p-datatable-sm"
                    value={this.state.rowsCodiceSedeAnno}
                    dataKey="key"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                    rows={5}
                    loading={this.state.loadingGridCodiceSedeAnno}
                    paginatorLeft={this.paginatorLeft}
                    paginatorRight={this.paginatorRight}
                    selection={this.state.selectedGridCodiceSedeAnno}
                    scrollable
                    style={{ width: "100%" }}
                    onSelectionChange={this.handleTableClickCodiceSedeAnno}
                    selectionMode="single"
                  >
                    <Column
                      field="sede"
                      header="Sede"
                      headerStyle={{ width: "50px" }}
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="anno"
                      header="Anno"
                      headerStyle={{ width: "50px" }}
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="righe"
                      header="Righe"
                      headerStyle={{ width: "50px" }}
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="codiceQ"
                      header="Cod"
                      headerStyle={{ width: "50px" }}
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="importo"
                      header="Importo"
                      headerStyle={{ width: "90px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "importo")
                      }
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="bollo"
                      header="Bollo"
                      headerStyle={{ width: "50px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "bollo")
                      }
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="spese"
                      header="Spese"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "spese")
                      }
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="fatturato"
                      header="Fatturato"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "fatturato")
                      }
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="diffFatt"
                      header="DiffFatt"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "diffFatt")
                      }
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="nettoDaAccr"
                      header="NettoDaAcrr"
                      headerStyle={{ width: "90px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "nettoDaAccr")
                      }
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="accreditato"
                      header="Accreditato"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "accreditato")
                      }
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="diffAccr"
                      header="DiffAccr"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "diffAccr")
                      }
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="errore"
                      header="Errore"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "errore")
                      }
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="chiuso"
                      header="Chiuso"
                      headerStyle={{ width: "30px" }}
                      sortable={true}
                      filter={this.state.filterGridCodiceSedeAnno}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                  </DataTable>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}

        {this.state.selectedGridCodiceSedeAnno && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="ebinprofsuccess">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    Dettaglio Tabulati: Sulla base della selezione precedente
                  </h4>
                  <FormControlLabel
                    className="float-right"
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%",
                      color: "white"
                    }}
                    control={
                      <Switch
                        checked={this.state.filterGridDettagli}
                        onChange={this.ChangeFilterGridDettagli}
                        name="chkFilterGrid"
                        color="default"
                      />
                    }
                    label={"Attiva Filtri"}
                  />
                </CardHeader>
                <CardBody>
                  <DataTable
                    emptyMessage=""
                    className="p-datatable-striped p-datatable-sm"
                    value={this.state.rowsDettagli}
                    dataKey="id"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                    rows={12}
                    loading={this.state.loadingGridDettagli}
                    paginatorLeft={this.paginatorLeft}
                    paginatorRight={this.paginatorRight}
                    selection={this.state.selectedGridDettagli}
                    scrollable
                    style={{ width: "100%" }}
                    onSelectionChange={this.handleTableClickDettagli}
                    selectionMode="single"
                  >
                    <Column
                      header=""
                      headerStyle={{ width: "30px" }}
                      body={this.DocActionsGridTemplate}
                      className="colDocActions"
                    />
                    <Column
                      field="codice"
                      header="Cod"
                      headerStyle={{ width: "50px" }}
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="sede"
                      header="Sede"
                      headerStyle={{ width: "50px" }}
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="riparto"
                      header="Rip."
                      headerStyle={{ width: "60px" }}
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="righe"
                      header="Righe"
                      headerStyle={{ width: "40px" }}
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="importo"
                      header="Importo"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "importo")
                      }
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="tariff"
                      header="Tariff"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "tariff")
                      }
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="bollo"
                      header="Bollo"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "bollo")
                      }
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="spese"
                      header="Spese"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "spese")
                      }
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="fatturato"
                      header="Fatturato"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "fatturato")
                      }
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="difffatt"
                      header="DiffFatt"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "difffatt")
                      }
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="nettodaacrr"
                      header="NettoDaAcrr"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "nettodaacrr")
                      }
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="accreditato"
                      header="Accreditato"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "accreditato")
                      }
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="diffaccr"
                      header="Differenza"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "diffaccr")
                      }
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="errore"
                      header="Errore"
                      headerStyle={{ width: "80px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "errore")
                      }
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="dataarr"
                      header="DataArr"
                      headerStyle={{ width: "80px" }}
                      body={(row) =>
                        moment(row.dataultimo, dateTimeFmt).format(dateFmt)
                      }
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="tipoultimoagg"
                      header="TipoUltimoAgg"
                      headerStyle={{ width: "80px" }}
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="dataultimo"
                      header="DataUltimo"
                      headerStyle={{ width: "80px" }}
                      body={(row) =>
                        moment(row.dataultimo, dateTimeFmt).format(dateFmt)
                      }
                      sortable={true}
                      filter={this.state.filterGridDettagli}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                  </DataTable>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </>
    );
  }
}

export default ElenchiTabulati;
