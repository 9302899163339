import Utils from "./Utils";

const DocsAPI = {
  getDocumentsByAcademicYearScholarshipTypeId: async function (
    scholarshipTypeId,
    academicYear
  ) {
    return await Utils.CreatePostRequest(
      `ebinprof/Doc/getByTipoBsAnno?tipoBS=${scholarshipTypeId}&annoBS=${academicYear}`
    );
  },

  addDocument: async function (data) {
    return await Utils.CreatePostRequest(`ebinprof/Doc/insert`, data);
  },

  getAll: async function () {
    return await Utils.CreatePostRequest(`ebinprof/Doc/getall`);
  },

  getById: async function (id) {
    return await Utils.CreatePostRequest(
      `ebinprof/DocRichies/getByIdRichies?idRichies=${id}`
    );
  },

  update: async function (data) {
    return await Utils.CreatePutRequest(`ebinprof/DocRichies/update`, data);
  },

  NuovoDocumentoForBorse: function () {
    return [{ id: 0, name: "" }];
  },

  ChangeGetDocsPresentatiStato: async function (docId) {
    return true;
  },

  AddDocs: async function (docsobj) {
    return 1;
  },

  UpdateDocs: async function (docsobj) {
    return 1;
  },

  AddDocForBorse: async function (docobj) {
    return 1;
  },
  UpdateDocForBorse: async function (docobj) {
    return 1;
  },

  checkAllDocuments: async function (documents) {
    return await Utils.CreatePutRequest(
      `ebinprof/DocRichies/updateAll`,
      documents
    );
  }
};
export default DocsAPI;
