import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Button } from "primereact/button";
import queryString from "query-string";
import AziendeAPI from "../../../API/AziendeAPI";
import SedeComuneProvAPI from "../../../API/SedeComuneProvAPI";
export class AnomalieAziendaHeader extends Component {
  state = {
    azienda: {},
    provincia: {},
    sedeInps: {},
    tipoCessazioneAzienda: {}
  };

  constructor(props) {
    super(props);
    this.onBackClick = this.onBackClick.bind(this);
  }

  async componentDidMount() {
    const azienda = (await AziendeAPI.GetAziendaById(this.props.aziendaId))[0];
    const provincia =
      azienda.prov !== null && azienda.prov !== ""
        ? (await SedeComuneProvAPI.GetProvByCode(azienda.prov))[0]
        : {};
    const sedeInps = (await SedeComuneProvAPI.GetSedeBySap(azienda.sede))[0];
    const tipoCessazioneAzienda =
      azienda.tipo_cessazione !== null
        ? (await AziendeAPI.GetTipoCessAziendeById(azienda.tipo_cessazione))[0]
        : {};

    this.setState({
      azienda: azienda,
      provincia: provincia,
      sedeInps: sedeInps,
      tipoCessazioneAzienda: tipoCessazioneAzienda
    });
  }

  onBackClick() {
    let qS = queryString.parse(this.props.location.search);
    let maintainSearch = "?maintainSearch=1";

    if (this.props.isVisible) {
      if (!qS.type)
        this.props.history.push(
          "/admin/aziende/" + this.props.aziendaId + maintainSearch
        );
      else if (qS.type === "fromVersamenti")
        this.props.history.push(
          "/admin/versamenti/details/" + this.props.aziendaId
        );
      else if (qS.type === "fromFiltroAnomalie")
        this.props.history.push(
          "/admin/filtroanomalie/" +
            maintainSearch +
            "&typeId=" +
            this.props.aziendaId
        );
    } else {
      if (!qS.type)
        this.props.history.push(
          "/admin/AnomalieXAzienda/" + this.props.aziendaId + maintainSearch
        );
      else if (qS.type === "fromVersamenti")
        this.props.history.push(
          "/admin/AnomalieXAzienda/" +
            this.props.aziendaId +
            "?type=fromVersamenti"
        );
      else if (qS.type === "fromFiltroAnomalie")
        this.props.history.push(
          "/admin/AnomalieXAzienda/" +
            this.props.aziendaId +
            "?type=fromFiltroAnomalie"
        );
    }
  }

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="primary"
                className={this.props.hideAnomalieHeader}
              >
                <Button
                  icon="pi pi-arrow-left"
                  onClick={this.onBackClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Azienda
                </h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Matricola"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.matricola ?? ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Ragione Sociale"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.denominazione ?? ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Codice Fiscale"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.codice_fiscale ?? ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="SAP"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.sede
                          ? this.state.azienda.sede +
                            " - " +
                            this.state.sedeInps.descrizione
                          : ""
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Indirizzo"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.indirizzo ?? ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="CAP"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.cap ?? ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Comune"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.comune ?? ""
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Provincia"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          this.state.provincia?.nome ?? this.state.azienda.prov
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={4}>
                    <CustomInput
                      labelText="Data Costituzione"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.data_costituzione ?? ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={4}>
                    <CustomInput
                      labelText="Data Cessazione"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.data_cessazione ?? ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={4}>
                    <CustomInput
                      labelText="Tipo Cessazione"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.tipoCessazioneAzienda
                          ? this.state.tipoCessazioneAzienda.descrizione
                          : ""
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={2}>
                    <CustomInput
                      labelText="PV PULI"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.primovers ?? ""
                      }}
                    />
                  </GridItem>
                  <GridItem xs={2}>
                    <CustomInput
                      labelText="UV PULI"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.ultimovers ?? ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={2}>
                    <CustomInput
                      labelText="PV CUST"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.primoversw350 ?? ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={2}>
                    <CustomInput
                      labelText="UV CUST"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.ultimoversw350 ?? ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={2}>
                    <CustomInput
                      labelText="Data Ultimo Agg."
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.dataultimoagg ?? ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={2}>
                    <CustomInput
                      labelText="Utente Ultimo Agg."
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.utente ?? ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
