import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Button } from "primereact/button";
import queryString from "query-string";
import DipendentiAPI from "API/DipendentiAPI";
export class StoricoRDLHeader extends Component {
  state = {
    azienda: {},
    dipendenti: {},
    aziendaDipendenti: {}
  };

  constructor(props) {
    super(props);

    this.backButtonClick = this.backButtonClick.bind(this);
  }

  async componentDidMount() {
    let idRdl = this.props.location.search.split("=")[1];
    let dep = await DipendentiAPI.GetRDLById(idRdl);
    this.setState({
      aziendaDipendenti: dep[0]
    });
  }

  backButtonClick() {
    let qS = queryString.parse(this.props.history.location.search);
    let aziendaId = this.props.match.params.aziendaId;
    let dipendentiId = this.props.match.params.dipendenteId;
    if (qS.type == "fromUniemens"){
        this.props.history.push(
          "/admin/RDL/" +
            aziendaId +
            "/" +
            dipendentiId +
            "?type=" +
            qS.type +
            "&typeId=" +
            qS.typeId
        );
      } else if (aziendaId != "0") {
      this.props.history.push("/admin/aziende/" + aziendaId);
    } else if (aziendaId == "0") this.props.history.push("/admin/dipendenti/0");
    else if (qS.type === "fromVersamenti")
      this.props.history.push(
        "/admin/RDL/" +
          aziendaId +
          "/" +
          dipendentiId +
          "?type=" +
          qS.type +
          "&typeId=" +
          qS.typeId
      );
    else if (qS.type === "fromModificaRichieste")
      this.props.history.push(
        "/admin/RDL/" +
          aziendaId +
          "/" +
          dipendentiId +
          "?type=" +
          qS.type +
          "&typeId=" +
          qS.typeId
      );
    else if (qS.type === "fromRichieste")
      this.props.history.push(
        "/admin/RDL/" +
          aziendaId +
          "/" +
          dipendentiId +
          "?type=" +
          qS.type +
          "&typeId=" +
          qS.typeId
      );
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Button
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />

                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  RDL Del Lavoratore
                </h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Codice Fiscale"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.aziendaDipendenti.cf
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Matricola"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.aziendaDipendenti.matricola
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Ragione Sociale"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.aziendaDipendenti.denominazione
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Nominativo"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.aziendaDipendenti.nominativo
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
