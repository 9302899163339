import Utils from "./Utils";

const causaliCorrispondenzaAPI = {

    async getAll() {
        const response = await Utils.CreatePostRequest("coasco/caucorr/getall");
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async update(cauCorr) {
        const response = await Utils.CreatePutRequest("coasco/caucorr/update", cauCorr);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async insert(cauCorr) {
        const response = await Utils.CreatePostRequest("coasco/caucorr/insert", cauCorr);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async delete(cauCorr) {
        const response = await Utils.CreateDeleteRequest("coasco/caucorr/delete?id=" + cauCorr.codice);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    getNew() {
        return {
            codice: "",
            descrizione: "",
            testomail: "",
            oggetto: "" // TODO deve essere aggiunto lato BE nel DTO
        };
    }

};

export default causaliCorrispondenzaAPI;