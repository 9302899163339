import Utils from "./Utils";
import * as AppConstants from "../helpers/AppConstants";

const baseURL = AppConstants.SiteUrl + "ebinprof/anniacc";

const AnniAccademiciAPI = {
  deleteAcademicYear: async function (id) {
    const url = `ebinprof/anniacc/delete?id=${id}`;

    const res = await Utils.CreateDeleteRequest(url);
    return res?.result;
  },

  AnnoAccademiciForBorse: async function () {
    const response = await Utils.SendPostRequestTo(`${baseURL}/getall`);
    return response.result;
  },

  getById: async function (id) {
    const response = await Utils.SendPostRequestTo(
      `${baseURL}/getById?codice=${id}`
    );
    return response.result;
  },

  NuovoAnnoAccademiciForBorse: function () {
    let rows = [{ id: "", name: "" }];
    return rows;
  },

  AddAnnoAccademici: async function (descr) {
    const data = {
      id: "",
      descr
    };
    const res = await Utils.SendPostRequestTo(`${baseURL}/insert`, data);
    return res.result;
  },

  UpdateAnnoAccademici: async function (data) {
    const _data = {
      idAnno: data.id,
      descr: data.name
    };

    const res = await Utils.SendPutRequestTo(`${baseURL}/update`, _data);
    return res.result;
  }
};
export default AnniAccademiciAPI;
