import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { AziendaHeader } from "components/Views/Aziende/AziendaHeader.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Helpers from "helpers/Helpers.js";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import YearPicker from "components/YearPicker/index";
import CustodiPulitoriAPI from "API/CustodiPulitoriAPI";
import {rowsPerPageOptionsMensile} from "../../helpers/AppConstants";
class VersamentiUniemens extends Component {
  state = {
    rowsCustodi: [],
    rowsPulitori: [],
    selectedGriCustodi: null,
    selectedGridPulitori: null,
    filterCustodi: null,
    filterPulitori: null,
    isChecked: false,
    filterLabelTextCustodi: "Attiva Filtri",
    filterLabelTextPulitori: "Attiva Filtri",
    competenzaAnno: new Date().getFullYear(),
    loadingGrid: false,
    loadingGridPulitori: false
  };

  constructor(props) {
    super(props);

    this.ChangeFilterPulitori = this.ChangeFilterPulitori.bind(this);
    this.ChangeFilterCustodi = this.ChangeFilterCustodi.bind(this);
    this.handleTableClickCustodi = this.handleTableClickCustodi.bind(this);
    this.handleTableClickPulitori = this.handleTableClickPulitori.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.IncreaseYear = this.IncreaseYear.bind(this);
    this.DecreaseYear = this.DecreaseYear.bind(this);
  }

  async componentDidMount() {
    this.setState({
      competenzaAnno: localStorage.getItem("year") || new Date().getFullYear()
    }, async () => await this.refreshGrid());
  }

  async handleDateChange(dateParam) {
    this.setState(
      {
        competenzaAnno: dateParam
      },
      async () => await this.refreshGrid()
    );
  }

  async ChangeFilterPulitori(e) {
    this.setState({ filterPulitori: e.target.checked });
  }

  async IncreaseYear() {
    this.setState(
      {
        competenzaAnno: parseInt(this.state.competenzaAnno) + 1
      },
      async () => await this.refreshGrid()
    );
  }

  async DecreaseYear() {
    this.setState(
      {
        competenzaAnno: parseInt(this.state.competenzaAnno) - 1
      },
      async () => await this.refreshGrid()
    );
  }

  async ChangeFilterCustodi(e) {
    this.setState({ filterCustodi: e.target.checked });
  }

  handleChange(e) {
    this.setState({ isChecked: e.target.checked });
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true,
      loadingGridPulitori: true
    });

    let aziendaId = this.props.match.params.versamentiId;
    this.setState({
      rowsCustodi:
        await CustodiPulitoriAPI.GetCustodiPulitoriVersamentiForAzienda(
          "CUST",
          aziendaId,
          this.state.competenzaAnno
        ),
      rowsPulitori:
        await CustodiPulitoriAPI.GetCustodiPulitoriVersamentiForAzienda(
          "PULI",
          aziendaId,
          this.state.competenzaAnno
        )
    });

    this.setState({
      loadingGrid: false,
      loadingGridPulitori: false
    });
  }

  async onSearchClick() {
    await this.refreshGrid();
  }

  async handleTableClickCustodi(e) {
    this.setState({
      selectedGridCustodi: e.value
    });
  }

  async handleTableClickPulitori(e) {
    this.setState({
      selectedGridPulitori: e.value
    });
  }

  render() {
    return (
      <div>
        <AziendaHeader
          aziendaId={this.props.match.params.versamentiId}
          {...this.props}
          year={this.state.competenzaAnno}
        />

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                  className="float-left"
                >
                  Custodi
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterCustodi}
                      onChange={this.ChangeFilterCustodi}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={this.state.filterLabelTextCustodi}
                />

                <div
                  className="float-left date"
                  style={{
                    verticalAlign: "top",
                    lineHeight: "1",
                    marginRight: "5px",
                    display: "inline-flex",
                    marginTop: "10px"
                  }}
                >
                  <SkipPreviousIcon
                    onClick={this.DecreaseYear}
                    className="slide"
                  />
                  <YearPicker
                    onChange={this.handleDateChange}
                    value={this.state.competenzaAnno}
                    className="date"
                  />
                  <SkipNextIcon onClick={this.IncreaseYear} className="slide" />
                </div>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  value={this.state.rowsCustodi}
                  className="p-datatable-striped p-datatable-sm"
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={12} rowsPerPageOptions={rowsPerPageOptionsMensile}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  loading={this.state.loadingGrid}
                  selection={this.state.selectedGridCustodi}
                  scrollable scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClickCustodi}
                  selectionMode="multiple"
                  metaKeySelection={false}
                >
                  <Column
                    field="matricola"
                    header="Matricola"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="competenza"
                    header="Competenza"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cod_quota"
                    header="Cod. Quota"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="quotaVersata"
                    header="Quota Vers."
                    headerStyle={{ width: "140px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "quotaVersata")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="quotaUNIEM"
                    header="Quota Uniem."
                    headerStyle={{ width: "150px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "quotaUNIEM")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="err"
                    header="Errore"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Pulitori
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterPulitori}
                      onChange={this.ChangeFilterPulitori}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={this.state.filterLabelTextPulitori}
                />
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  value={this.state.rowsPulitori}
                  className="p-datatable-striped p-datatable-sm"
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={12} rowsPerPageOptions={rowsPerPageOptionsMensile}
                  paginatorLeft={this.paginatorLeft}
                  loading={this.state.loadingGridPulitori}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGridPulitori}
                  scrollable scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClickPulitori}
                  selectionMode="multiple"
                  metaKeySelection={false}
                >
                  <Column
                    field="matricola"
                    header="Matricola"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="competenza"
                    header="Competenza"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cod_quota"
                    header="Cod. Quota"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="quotaVersata"
                    header="Quota Vers."
                    headerStyle={{ width: "140px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "quotaVersata")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="quotaUNIEM"
                    header="Quota Uniem."
                    headerStyle={{ width: "150px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "quotaUNIEM")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="err"
                    header="Errore"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default VersamentiUniemens;
