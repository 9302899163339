import helpers from "../helpers/Helpers";
import Utils from "./Utils";

const DipendentiAPI = {
  getEmployeesByCompanyId: async function (companyId) {
    let result = await Utils.CreatePostRequest(
      `coasco/rdl/getbyidazienda?idazienda=${companyId}`
    );
    if (result === null) result = [];
    return result.result;
  },

  GetAll: async function () {
    let result = await Utils.CreatePostRequest("coasco/dipendenti/getall", {});
    if (result === null) result = [];
    return result.result;
  },

  SearchPageSort: async function (
    nominativo, cf,
    start, size,
    orderBy, orderAsc
  ) {
    const url =
      "coasco/dipendenti/searchpagesort?nominativo=" + nominativo +
      "&cf=" + cf +
      "&start=" + start +
      "&size=" + size +
      "&sortfield=" + orderBy +
      "&sortorder=" + (orderAsc === 1 ? "asc" : "desc");

    const result = await Utils.CreatePostRequest(url);
    if (result !== null) return result;
    else return [];
  },

  GetDipendentiForAzienda: async function (aziendaId) {
    const resultObj = await Utils.CreatePostRequest(
      "coasco/rdl/getbyidazienda?idazienda=" + aziendaId
    );

    const result = resultObj?.result.map((item) => ({
      ...item.dipendente,
      cod_quota: item.cod_quota,
      data_inizio: item.data_inizio,
      data_fine: item.data_fine
    }));
    return result;
  },

  GetCFEMatRDL: async function (aziendaId, dipendenteId) {
    const obj = {
      cf: null,
      matricola: null
    };

    if (aziendaId != 0) {
      const resultObjMat = await Utils.CreatePostRequest(
        "coasco/aziende1/getbyid?id=" + aziendaId
      );
      obj.matricola = resultObjMat.result[0].matricola;
    }

    const resultObjCf = await Utils.CreatePostRequest(
      "coasco/dipendenti/getbyid?id=" + dipendenteId
    );

    obj.cf = resultObjCf.result[0].cf;
    return obj;
  },

  GetDipendentiById: async function (dipendentiId) {
    let result = await Utils.CreatePostRequest(
      "coasco/dipendenti/getbyid?id=" + dipendentiId
    );

    if (result === null) result = {};
    return result.result;
  },

  GetNuovoDipendentiById: async function () {
    return {
      id: null,
      nominativo: null,
      cf: null,
      indirizzo: null,
      comunex: null,
      provx: null,
      sesso: null,
      nazionalita: null,
      telefono: null,
      cell: null,
      fax: null,
      pec: null,
      email: null,
      data_ult_agg: null,
      tipo_ult_agg: null,
      indirizzoxposta: null,
      comunexposta: null,
      utente: null
    };
  },

  GetAllCf: async function (cf) {
    let result = await Utils.CreatePostRequest(
      "coasco/dipendenti/getbycf?cf=" + cf + "&maxResults=" + 100
    );
    if (result === null) result = [];
    return result.result;
  },

  SearchByCf: async function (cf) {
    const result = await Utils.CreatePostRequest(
      "coasco/dipendenti/searchbycf?cf=" + cf
    );

    if (result === null) return [];
    else return result.result.map((item) => item.cf);
  },

  GetByCF: async function (cf) {
    return await Utils.CreatePostRequest(
      "coasco/dipendenti/searchbycf?cf=" + cf
    );
  },

  SearchByNominativo: async function (nominativo) {
    let result = await Utils.CreatePostRequest(
      "coasco/dipendenti/getbycf?cf=" + nominativo
    );
    if (result === null) result = [];
    return result.result;
  },

  GetRDLByDipendenteId: async function (dipendentiId) {
    let result = await Utils.CreatePostRequest(
      "coasco/rdl/getbyiddip?iddip=" + dipendentiId
    );

    return result.result.map((item) => ({
      ...item.azienda,
      cf: item.dipendente.cf,
      cod_quota: item.cod_quota,
      data_fine_mostra: item.data_fine,
      data_inizio_mostra: item.data_inizio,
      data_fine: helpers.convertToEnglishDayAndMonthFromString(item.data_fine),
      data_inizio: helpers.convertToEnglishDayAndMonthFromString(
        item.data_inizio
      ),
      id: item.id,
      utente: item.utente
    }));
  },

  GetRDLByDipendenteIdAziendaId: async function (dipendentiId, aziendaId) {
    let result = await Utils.CreatePostRequest(
      "coasco/rdl/getbyiddipendenteidazienda?iddipendente=" +
        dipendentiId +
        "&idazienda=" +
        aziendaId
    );
    result = result.result.map((item) => ({
      ...item.azienda,
      cf: item.dipendente.cf,
      cod_quota: item.cod_quota,
      data_fine_mostra: item.data_fine,
      data_inizio_mostra: item.data_inizio,
      data_fine: helpers.convertToEnglishDayAndMonthFromString(item.data_fine),
      data_inizio: helpers.convertToEnglishDayAndMonthFromString(
        item.data_inizio
      ),
      id: item.id
    }));
    return result;
  },

  GetRDLById: async function (rdlId) {
    const resultObj = await Utils.CreatePostRequest(
      "coasco/rdl/getbyid?id=" + rdlId
    );

    const result = resultObj.result.map((item) => ({
      ...item.azienda,
      ...item.dipendente,
      q1: item.q1?.codice,
      q2: item.q2?.codice,
      q3: item.q3?.codice,
      tipo_contr: item.tipo_contr?.codice,
      tipo_den: item.tipo_den?.codice,
      tipo_lav: item.tipo_lav?.codice,
      tipo_profilo: item.tipo_profilo?.codice,
      data_fine_mostra: item.data_fine,
      data_inizio_mostra: item.data_inizio,
      data_fine: helpers.convertToEnglishDayAndMonthFromString(item.data_fine),
      data_inizio: helpers.convertToEnglishDayAndMonthFromString(
        item.data_inizio
      ),
      cod_quota: item.cod_quota,
      imponibile: item.imponibile,
      tipo_caric: item.tipo_caric,
      utente_caric: item.utente
    }));
    return result;
  },

  GetNuovoRDLById: async function () {
    return {
      id: null,
      q1: null,
      q2: null,
      q3: null,
      tipo_contr: null,
      tipo_den: null,
      tipo_profilo: null,
      tipo_lav: null,
      data_fine: "",
      data_inizio: "",
      cod_quota: "",
      imponibile: "",
      cf: "",
      matricola: ""
    };
  },

  GetNuovoDipendenteForAzienda: async function () {
    return {
      id: null,
      nominativo: null,
      cf: null,
      indirizzo: null,
      comunex: null,
      provx: null,
      capx: null,
      sesso: null,
      nazionalita: null,
      telefono: null,
      cell: null,
      fax: null,
      email: null,
      email2: null,
      pec: null,
      note: null,
      data_ult_agg: null,
      tipo_ult_agg: null,
      indirizzoxposta: null,
      comunexposta: null,
      utente: null
    };
  },

  Dipendente: function () {
    return [
      {id: 0, name: "-"},
      {id: 1, name: "Dip"},
      {id: 2, name: "Dip2"},
      {id: 3, name: "Dip3"}
    ];
  },

  AddDipendenti: async function (dipobj, user) {
    dipobj.utente = user;
    return await Utils.CreatePutRequest("coasco/dipendenti/update", dipobj);
  },

  UpdateDipendenti: async function (dipobj, user) {
    dipobj.utente = user;
    return await Utils.CreatePutRequest("coasco/dipendenti/update", dipobj);
  },

  DeleteDipendenti: async function (dipId) {
    return await Utils.CreateDeleteRequest(
      "coasco/dipendenti/delete?id=" + dipId
    );
  },

  AddDipendentiForAzienda: async function (dipobj, id_azienda) {
    dipobj.utente = "Claudio";
    await Utils.CreatePutRequest(
      "coasco/dipendenti_aziende/update?id_azienda=" + id_azienda,
      dipobj
    );
  },

  UpdateDipendentiForAzienda: async function (dipobj, id_azienda) {
    dipobj.utente = "Claudio";
    return await Utils.CreatePutRequest(
      "coasco/dipendenti_aziende/update?id_azienda=" + id_azienda,
      dipobj
    );
  },

  UpdateDipendentiForAziendaRDLNuovo: async function (dipobj, id_azienda) {
    dipobj.utente = "Claudio";
    const obj = await Utils.CreatePutRequest(
      "coasco/dipendenti_aziende/update?id_azienda=" + id_azienda,
      dipobj
    );
    return obj.result.dipendente.id;
  },

  AddRDLUpdate: async function (dipobj, id_rdl) {
    let rdl = {
      cf: dipobj.cf,
      cod_quota: dipobj.cod_quota,
      data_caric: dipobj.data_caric,
      data_fine: helpers.ConvertDateAsArrayWithSlash(dipobj?.data_fine),
      data_inizio: helpers.ConvertDateAsArrayWithSlash(dipobj?.data_inizio),
      id: dipobj.id,
      imponibile: dipobj.imponibile,
      matricola: dipobj.matricola,
      q1: dipobj.q1,
      q2: dipobj.q2,
      q3: dipobj.q3,
      tipo_caric: dipobj.tipo_caric,
      tipo_contr: dipobj.tipo_contr,
      tipo_den: dipobj.tipo_den,
      tipo_lav: dipobj.tipo_lav,
      tipo_profilo: dipobj.tipo_profilo,
      utente: dipobj.utente
    };

    const obj = await Utils.CreatePutRequest(
      "coasco/rdl/update?id_rdl=" + id_rdl,
      rdl
    );
    return obj;
  },

  AddRDLInsert: async function (dipobj) {
    let rdl = {
      cf: dipobj.cf,
      cod_quota: dipobj.cod_quota,
      data_caric: dipobj.data_caric,
      data_fine: helpers.ConvertDateAsArrayWithSlash(dipobj?.data_fine),
      data_inizio: helpers.ConvertDateAsArrayWithSlash(dipobj?.data_inizio),
      id: dipobj.id,
      imponibile: dipobj.imponibile,
      matricola: dipobj.matricola,
      q1: dipobj.q1,
      q2: dipobj.q2,
      q3: dipobj.q3,
      tipo_caric: dipobj.tipo_caric,
      tipo_contr: dipobj.tipo_contr,
      tipo_den: dipobj.tipo_den,
      tipo_lav: dipobj.tipo_lav,
      tipo_profilo: dipobj.tipo_profilo,
      utente: dipobj.utente
    };

    dipobj.utente = "Claudio";

    const obj = await Utils.CreatePutRequest(
      "coasco/rdl/insert?id_azienda=" + 0,
      rdl
    );
    return obj;
  },

  DeleteRdl: async function (rdlId) {
    const obj = await Utils.CreateDeleteRequest("coasco/rdl/delete?id=" + rdlId);
    return obj;
  },

  GetForFiltroPerPosta: async function (
    selectedCodQ,
    selectedCaratteristiche,
    selectedProvincia,
    selectedRegione
  ) {
    const cod_quota =
      "?cod_quota=" + (selectedCodQ?.map((codQ) => codQ.name) ?? "");
    const caratteristiche =
      "&caratteristiche=" +
      (selectedCaratteristiche?.map((car) => car.cod) ?? "");
    const provincia = "&provincia=" + (selectedProvincia?.sigla ?? "");
    const regione =
      "&regione=" + (selectedRegione?.map((reg) => reg.nome) ?? "");
    const url =
      "coasco/dipendenti/getForFiltroPerPosta" +
      cod_quota +
      caratteristiche +
      provincia +
      regione;
    const response = await Utils.CreatePostRequest(url);
    if (response?.message?.includes("200")) {
      return response.result.map((row) => {
        return { nominativo: row[0], cf: row[1], id: row[2] };
      });
    } else return null;
  }
};
export default DipendentiAPI;
