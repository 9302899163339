import Utils from "./Utils";

const tipoContrattoAPI = {

    getAll: async function () {
        const response = await Utils.CreatePostRequest("coasco/unitipocontratto/getall");
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async update(tipoContratto) {
        const response = await Utils.CreatePutRequest("coasco/unitipocontratto/update", tipoContratto);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async insert(tipoContratto) {
        const response = await Utils.CreatePostRequest("coasco/unitipocontratto/insert", tipoContratto);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async delete(tipoContratto) {
        const response = await Utils.CreateDeleteRequest("coasco/unitipocontratto/delete?id=" + tipoContratto.codice);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    getNew() {
        return {
            codice: "",
            descrizione: ""
        };
    }

};

export default tipoContrattoAPI;