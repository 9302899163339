import Utils from "./Utils";

const AziendeAPI = {
  getCompaniesByEmployeeId: async function (employeeId) {
    const res = await Utils.CreatePostRequest(
      `coasco/rdl/getbyiddip?iddip=${employeeId}`,
      {}
    );
    return res;
  },

  getCompaniesByConsultantId: async function (consultantId) {
    const res = await Utils.CreatePostRequest(
      `coasco/consulenti_aziende/getbyidconsulente?idconsulente=${consultantId}`,
      {}
    );
    return res;
  },

  getCompaniesByAdministratorId: async function (administratorId) {
    const res = await Utils.CreatePostRequest(
      `coasco/amministratori_aziende/getbyidamministratore?idamministratore=${administratorId}`,
      {}
    );
    return res;
  },

  GetAll: async function () {
    let result = await Utils.CreatePostRequest("coasco/aziende1/getall", {});
    return result.result;
  },

  GetAllIdMatr: async function () {
    let result = await Utils.CreatePostRequest(
      "coasco/aziende1/getallidmatr",
      {}
    );
    if (result === null) result = [];
    return result.result;
  },

  GetAllMatricola: async function (matricola) {
    let result = await Utils.CreatePostRequest(
      "coasco/aziende1/searchbymatr?matr=" + matricola + "&maxResults=" + 100
    );
    if (result === null) result = [];
    return result.result;
  },

  GetAziendaById: async function (aziendaId) {
    let result = await Utils.CreatePostRequest(
      "coasco/aziende1/getbyid?id=" + aziendaId
    );
    return result.result;
  },

  GetAllTipoAziende: async function () {
    let result = await Utils.CreatePostRequest("coasco/tipoaziende/getall", {});
    return result.result;
  },

  GetAllTipoCessAziende: async function () {
    let result = await Utils.CreatePostRequest(
      "coasco/tipostatoazienda/getall",
      {}
    );
    return result.result;
  },

  GetTipoCessAziendeById: async function (id) {
    const result = await Utils.CreatePostRequest(
      "coasco/tipostatoazienda/getbyid?id=" + id
    );
    return result.result;
  },

  GetTipoAziendaById: async function (aziendaId) {
    let result = await Utils.CreatePostRequest(
      "coasco/tipoaziende/getbyid?id=" + aziendaId
    );
    return result.result;
  },

  GetAziendaByMatr: async function (matricola) {
    const response = await Utils.CreatePostRequest(
      "coasco/aziende1/getbymatr?matr=" + matricola + "&start=0&size=100"
    );
    return response.result ? response.result.pop() : null;
  },

  SearchByMatr: async function (matricola) {
    let result = (
      await Utils.CreatePostRequest(
        "coasco/aziende1/searchbymatrdenwithpage?matr=" +
          matricola +
          "&start=0&size=100"
      )
    ).result;
    if (result === null) result = [];
    else result = result.map((item) => item.matricola);

    return result;
  },

  SearchByMatrInCoasco: async function (matricola, ragione, cfiva) {
    let result = (
      await Utils.CreatePostRequest(
        "coasco/aziende1/searchpagesort?matr=" +
          matricola +
          "&cfiva=" +
          cfiva +
          "&start=0&size=100"
      )
    ).result;
    if (result === null) result = [];
    else result = result.map((item) => item.matricola);

    return result;
  },

  SearchByRagione: async function (denominazione) {
    let result = (
      await Utils.CreatePostRequest(
        "coasco/aziende1/searchbymatrdenwithpage?denom=" +
          denominazione +
          "&start=0&size=100"
      )
    ).result;
    if (result === null) result = [];
    else result = result.map((item) => item.denominazione);

    return result;
  },

  SearchByCfPiva: async function (cfiva) {
    let result = (
      await Utils.CreatePostRequest(
        "coasco/aziende1/searchpagesort?cfiva=" + cfiva + "&start=0&size=100"
      )
    ).result;
    if (result === null) result = [];
    else result = result.map((item) => item.partita_iva);

    return result;
  },

  SearchByMatrAndDen: async function (
    matricola,
    ragione,
    currentPage,
    orderBy,
    orderAsc
  ) {
    let url =
      "coasco/aziende1/searchbymatrdenwithpage?matr=" +
      matricola +
      "&denom=" +
      ragione +
      "&start=" +
      currentPage * 10 +
      "&size=10";

    let result = await Utils.CreatePostRequest(url);
    if (result !== null) return result;
    else return [];
  },

  SearchByMatrAndDenAndCfPiva: async function (
    matricola,
    ragione,
    cfiva,
    currentPage,
    orderBy,
    orderAsc
  ) {
    let codFisPiva = cfiva !== "" ? "&cfiva=" + cfiva : "";
    let url =
      "coasco/aziende1/searchpagesort?matr=" +
      matricola +
      "&denom=" +
      ragione +
      codFisPiva +
      "&start=" +
      currentPage * 10 +
      "&size=10" +
      "&sortfield=" +
      orderBy +
      "&sortorder=" +
      orderAsc;

    let result = await Utils.CreatePostRequest(url);
    if (result !== null) return result;
    else return [];
  },

  SearchPageSort: async function (
    matricola, ragione,
    first, size,
    orderBy, orderAsc
  ) {
    let url =
      "coasco/aziende1/searchpagesort?matr=" +
      matricola +
      "&denom=" +
      ragione +
      "&start=" + first +
      "&size=" + size +
      "&sortfield=" +
      orderBy +
      "&sortorder=" +
      (orderAsc === 1 ? "asc" : "desc");

    let result = await Utils.CreatePostRequest(url);
    if (result !== null) return result;
    else return [];
  },

  AddAzienda: async function (aziendaobj) {
    aziendaobj.sede = aziendaobj.sede === "" ? null : aziendaobj.sede;
    return await Utils.CreatePutRequest("coasco/aziende1/update", aziendaobj);
  },
  UpdateAzienda: async function (aziendaobj) {
    aziendaobj.sede = aziendaobj.sede === "" ? null : aziendaobj.sede;

    return await Utils.CreatePutRequest("coasco/aziende1/update", aziendaobj);
  },

  GetNuovoAziendaById: async function (aziendaId) {
    return {
      id: null,
      matricola: null,
      sede: null,
      classificazione: null,
      denominazione: null,
      settore_di_attivita_economica: null,
      csc: null,
      indirizzo: null,
      cap: null,
      comune: null,
      prov: null,
      codice_fiscale: null,
      partita_iva: null,
      data_costituzione: null,
      data_cessazione: null,
      dataultimoagg: null,
      tipoultimoagg: null,
      annoprimovers: null,
      annoultimovers: null,
      meseprimovers: null,
      meseultimovers: null,
      primovers: null,
      ultimovers: null,
      primoversw350: null,
      ultimoversw350: null,
      iban: null,
      indirizzoxposta: null,
      capxposta: null,
      comunexposta: null,
      provxposta: null,
      email: null,
      note: null,
      tipo_azienda: "A1",
      tipo_cessazione: 0,
      utente: null
    };
  },

  GetForFiltroPerPosta: async function (selectedCodQ, selectedCaratteristiche, selectedProvincia, selectedRegione) {
    const cod_quota = '?cod_quota=' + (selectedCodQ?.map(codQ => codQ.name) ?? '');
    const caratteristiche = '&caratteristiche=' + (selectedCaratteristiche?.map(car => car.cod) ?? '');
    const provincia = '&provincia=' + (selectedProvincia?.sigla ?? '');
    const regione = '&regione=' + (selectedRegione?.map(reg => reg.nome) ?? '');
    const url = 'coasco/aziende1/getForFiltroPerPosta' + cod_quota + caratteristiche + provincia + regione;
    const response = await Utils.CreatePostRequest(url);
    if (response?.message?.includes('200')) {
      return response.result.map(row => {return {matricola: row[0], denominazione: row[1], id: row[2]}});
    } else return null;
  }
};
export default AziendeAPI;
