import Utils from "./Utils";

const TipoInvioAPI = {
  GetAll: async function () {
    const response = await Utils.CreatePostRequest(
      'coasco/tipoinvio/getall'
    );
    return response.result;
  }
};

export default TipoInvioAPI;
