import React, { Component } from "react";
import clone from "clone";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import MenuItem from "@material-ui/core/MenuItem";
import CardFooter from "components/Card/CardFooter.js";
import { Dropdown } from "primereact/dropdown";
import queryString from "query-string";
import LoaderSpinner from "components/Layout/LoaderSpinner.js";
import SedeComuneProvAPI from "API/SedeComuneProvAPI";
import DipendentiAPI from "API/DipendentiAPI";
import NazionalitaAPI from "API/NazionalitaAPI";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import StaticLists from "helpers/StaticLists";
import ExtraFieldsModifica from "components/Views/Aziende/ExtraFieldsModifica.js";
import helpers from "helpers/Helpers";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ButtonsMUI from "@material-ui/core/Button";
import {Button as BackButton} from "primereact/button";

export default class ModificaDipendente extends Component {
  state = {
    RowItem: null,
    ModificaTitle: "Nuovo",
    groupClass: "",
    Nazionalita: null,
    Sesso: null,
    disabledFields: false,
    NazionalitaList: [],
    SessoList: [],

    ProvinciaList: [],
    selectedProvincia: null,

    selectedComune: null,
    notFilteredComune: [],
    filteredComune: [],

    fieldValidations: {},
    validationRules: {
      cf: "CFPIVA",
      nominativo: ""
    }
  };

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.ChangeButtons = this.ChangeButtons.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.SetText = this.SetText.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.OnInputBlur = this.OnInputBlur.bind(this);
    this.save = this.save.bind(this);
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
  }

  async isValidForm() {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    if (this.state.dataItem.id !== null) {
      validationRules["cf"] = "";
    }

    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);
    let isValid = Object.values(fieldValidations).every((x) => x === "success");
    this.setState({
      fieldValidations: fieldValidations
    });

    if (fieldValidations["cf"] === "error") {
      this.props.addAlert.current.addAlert(
        "warning",
        "Il Codice Fiscale non e' sintatticamente corretto: CCCCCCNNCNNCNNNC o NNNNNNNNNNN!"
      );
      return false;
    }

    return isValid;
  }

  HandleDropDownChange(e, val, propName) {
    let rowItem = this.state.RowItem;

    if (e === "selectedComune") {
      rowItem[propName] = val;
    } else if (e === "selectedProvincia") {
      rowItem[propName] = val;
      let sigla = helpers.ReturnIdFromListByType(
        this.state.ProvinciaList,
        "ProvinciaSigla",
        val
      );

      let _comunebyprov = helpers.ReturnIdFromListByType(
        this.state.notFilteredComune,
        "ComuneByProvList",
        sigla
      );

      this.setState({
        filteredComune: _comunebyprov,
        selectedComune: null
      });
    } else if (e === "Nazionalita") {
      rowItem[propName] = val;
    } else if (e === "Sesso") {
      rowItem[propName] = val;
    } else rowItem[propName] = val.id;

    this.setState({
      [e]: val,
      RowItem: rowItem
    });
  }

  async OnInputBlur(newCAP) {
    let rowItem = this.state.RowItem;
    let selectedComune = this.state.notFilteredComune.filter(
      (row) => row.cap === newCAP
    )[0];
    if (selectedComune !== null) {
      let comuneFiltered = this.state.notFilteredComune.filter(
        (row) => row.prov.sigla === selectedComune.prov.sigla
      );
      let selectedProvincia = this.state.ProvinciaList.filter(
        (row) => row.sigla === selectedComune.prov.sigla
      )[0];

      rowItem["comunex"] = selectedComune.nome;
      rowItem["provx"] = selectedProvincia.sigla;

      this.setState({
        RowItem: rowItem,
        filteredComune: comuneFiltered,
        selectedComune: selectedComune.nome,
        selectedProvincia: selectedProvincia.sigla
      });
    } else {
      rowItem["comunex"] = null;
      rowItem["provx"] = null;

      this.setState({
        RowItem: rowItem,
        filteredComune: [],
        selectedComune: null,
        selectedProvincia: null
      });
    }
  }

  SetText(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
  }

  HandleCFChange(newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem["cf"] = newValue;

    let re =
      /^([a-zA-Z]{3})([a-zA-Z]{3})(\d{2}[a-zA-Z])(\d{2})([a-zA-Z]\d{3})([a-zA-Z])$/;
    let CFGroups = re.exec(newValue);
    if (CFGroups !== null) {
      let nazionalitaCode = CFGroups[5];
      let foundNazionalita = this.state.NazionalitaList.find(
        (x) => x.cod === nazionalitaCode
      );
      if (foundNazionalita !== undefined) {
        this.HandleDropDownChange(
          "Nazionalita",
          foundNazionalita.cod,
          "nazionalita"
        );
      }

      let sesso = parseInt(CFGroups[4], 10);
      if (sesso > 40) {
        sesso = "F";
      } else sesso = "M";
      let foundSesso = this.state.SessoList.find((x) => x.name === sesso);
      if (foundSesso !== undefined) {
        this.HandleDropDownChange("Sesso", foundSesso.name, "sesso");
      }
    }

    this.SetText("cf", newValue);
  }

  HandleChange(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
  }

  HandleSaveClick = async () => {
    if (await this.isValidForm()) {
      return await this.save(this.state.RowItem);
    }
  };

  OnInputBlurCF = async () => {
    let RowItem = this.state.RowItem;
    let validationRules = {
      cf: "CFPIVA"
    };

    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);
    Object.values(fieldValidations).every((x) => x === "success");

    if (fieldValidations["cf"] === "error") {
      this.props.addAlert.current.addAlert(
        "warning",
        "Il Codice Fiscale non e' sintatticamente corretto: CCCCCCNNCNNCNNNC o NNNNNNNNNNN!"
      );
      return false;
    }

    let existentDipendenti = [];
    if (
      this.state.RowItem.cf !== null &&
      this.state.RowItem.cf.length === 16 &&
      this.state.dataItem.id === null
    ) {
      existentDipendenti = await DipendentiAPI.GetAllCf(this.state.RowItem.cf);
      if (existentDipendenti.length !== 0) {
        this.ChangeButtons(true, existentDipendenti);
        this.props.addAlert.current.addAlert(
          "warning",
          "Codice Fiscale Trovato"
        );
      }
    }
  };

  ChangeButtons = async (boolean, existentDipendenti) => {
    return await this.props.ChangeButtons(boolean, existentDipendenti);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    let selectedProvincia = this.state.ProvinciaList.filter(
      (row) => row.sigla === this.state.dataItem.provx
    )[0]?.sigla;
    let comuneFiltered = [];
    if (selectedProvincia)
      comuneFiltered = this.state.notFilteredComune.filter(
        (row) => row.prov.sigla === selectedProvincia
      );
  }

  async componentDidMount() {
    let allComune = await SedeComuneProvAPI.GetAllComune();
    let allProvincie = await SedeComuneProvAPI.GetAllProvincieProvincie();
    let allNazionalita = await NazionalitaAPI.GetAll();

    const employeeId = this.props.match.params.dipendenteId;
    const resEmployee = await DipendentiAPI.GetDipendentiById(employeeId);

    this.setState({
      RowItem: resEmployee[0],
      dataItem: resEmployee[0]
    });

    if (this.state.dataItem !== null) {
      this.setState({
        RowItem: this.state.dataItem,
        ModificaTitle: this.state.dataItem.id > 0 ? "Modifica" : "Nuovo",
        disabledFields: this.state.dataItem.id > 0,
        groupClass: this.state.dataItem.id > 0 ? "" : "hide",
        NazionalitaList: allNazionalita.map((x) => {
          return { cod: x.codice, name: x.codice + " - " + x.nazione };
        }),
        SessoList: StaticLists.Sesso(),
        ProvinciaList: allProvincie,
        filteredComune: allComune,
        notFilteredComune: allComune
      });

      let selectedProvincia = allProvincie.filter(
        (row) => row.sigla === this.state.dataItem.provx
      )[0]?.sigla;
      let comuneFiltered = [];
      if (selectedProvincia)
        comuneFiltered = allComune.filter(
          (row) => row.prov.sigla === selectedProvincia
        );

      this.setState({
        Sesso: this.state.SessoList.find(
          (x) => x.name === this.state.dataItem.sesso
        )?.name,
        filteredComune: comuneFiltered,
        selectedComune: this.state.dataItem.comunex,
        selectedProvincia: selectedProvincia,

        Nazionalita: this.state.NazionalitaList.find(
          (x) => x.cod === this.state.dataItem.nazionalita
        )?.cod
      });
    }
  }

  save = async (RowItem) => {
    if (RowItem.id === null) {
      let dipendenteNuovo = await DipendentiAPI.AddDipendenti(RowItem);
      this.state.selectedDipendente = dipendenteNuovo.result[0];
      this.state.viewRDLNuovoDip = true;
    } else {
      await DipendentiAPI.UpdateDipendenti(RowItem);
    }

    this.props.addAlert.current.addAlert("success", "Dati salvati.");

    let filterByCodice = this.state.filterByCodice;
    let filterByNominativo = this.state.filterByNominativo;

    let sortField = "cf";
    let sortOrder = 1;
    let pageToView = 0;
    let queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      let savedFilters = helpers.GetSearchFilters("DipendentiList");
      if (savedFilters !== null) {
        filterByCodice = savedFilters.filterByCodice;
        filterByNominativo = savedFilters.filterByNominativo;
      }
      let savedOrders = helpers.GetSearchFilters("DipendenteListOrder");
      if (savedOrders !== null) {
        sortField = savedOrders.sortField;
        sortOrder = savedOrders.sortOrder;
      }

      pageToView = helpers.GetLastPage("DipendenteListPage");
    } else {
      helpers.SaveSearchFilters(null, "DipendenteListOrder");
      helpers.SaveSearchFilters(null, "DipendentiList");
    }

    let responseObject = await DipendentiAPI.SearchPageSort(
      filterByNominativo,
      filterByCodice,
      pageToView,
      sortField,
      sortOrder
    );
    let filteredRowsInitial = responseObject.result;

    this.setState(
      {
        filteredRows: filteredRowsInitial,
        totalRecords: responseObject.tot,
        filterByNominativo: filterByNominativo,
        filterByCodice: filterByCodice,
        lastPage: pageToView,
        sortField: sortField,
        sortOrder: sortOrder
      },
      async () => {
        let filteredRows = filteredRowsInitial;

        let selectedRow = null;

        let dipendenteId = this.props.match.params.dipendenteId;
        if (dipendenteId > 0) {
          selectedRow = helpers.ReturnRowFromListById(
            filteredRows,
            dipendenteId
          );
        }

        this.setState({
          firstRowToDisplay: pageToView * 10,
          filteredRows: filteredRows
        });

        if (selectedRow)
          await this.handleTableClick({
            value: selectedRow
          });
      }
    );

    this.setState({
      loadingGrid: false
    });

    return true;
  };

  onAnnullaClick() {
    const {richiesId, richiedId} = helpers.GetSearchFilters("ModificaRichiesteXRichiedenti");
    this.props.history.push(`/admin/richiedenti/modificarichieste/${richiedId}/${richiesId}${this.props.location.search}`);
  }

  RedirectBtn(buttonName) {
    if (buttonName === "Storico")
      this.props.history.push(
          "/admin/StoricoXDipendente/" +
          "0" +
          "/" +
          this.props.match.params.dipendenteId +
          "?type=fromModificaDipendente"
      );
    else if (buttonName === "RDL")
      this.props.history.push(
          "/admin/RDL/" + 0 + "/" + this.props.match.params.dipendenteId + "?type=fromModificaDipendente"
      );
  }

  render() {
    if (this.state.RowItem === null)
      return (
        <>
          {" "}
          <LoaderSpinner fullHeight={false} />{" "}
        </>
      );
    else
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="borseinfo">
                  <BackButton
                    icon="pi pi-arrow-left"
                    onClick={this.onAnnullaClick}
                    className="p-button-rounded p-button-primary p-button-sm float-left"
                    style={{ verticalAlign: "bottom", marginRight: "2px" }}
                  />
                  <h4 className="d-inline ml-2 pt-1">{this.state.ModificaTitle} Dipendente</h4>
                  <ButtonGroup
                    variant="contained"
                    color="borseprimary"
                    size="small"
                    aria-label="contained primary button group"
                    className="float-right"
                    style={{ verticalAlign: "center", margin: "0.5%" }}
                  >
                    <ButtonsMUI onClick={() => this.RedirectBtn("Storico")}>
                      Storico Dip.
                    </ButtonsMUI>
                    <ButtonsMUI onClick={() => this.RedirectBtn("RDL")}>
                      RDL
                    </ButtonsMUI>
                  </ButtonGroup>
                  {this.props.children}
                </CardHeader>

                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Id"
                        id="id-disabled"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.id || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Ultimo Aggiornamento"
                        id="datault"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.data_ult_agg || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Tipo Ultimo Aggiornamento"
                        id="ultimoagg2"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.tipo_ult_agg || "",
                          maxLength: 10
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Utente Ultimo Aggiornamento"
                        id="ultimoagg3"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.utente || "",
                          maxLength: 30
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.nominativo &&
                          this.state.fieldValidations.nominativo === "success"
                        }
                        error={
                          this.state.fieldValidations.nominativo &&
                          this.state.fieldValidations.nominativo === "error"
                        }
                        labelText="Nominativo *"
                        id="nominativo"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("nominativo", event.target.value),
                          value: this.state.RowItem.nominativo || "",
                          maxLength: 100
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.cf &&
                          this.state.fieldValidations.cf === "success"
                        }
                        error={
                          this.state.fieldValidations.cf &&
                          this.state.fieldValidations.cf === "error"
                        }
                        labelText="Codice Fiscale *"
                        id="codice"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disabledFields,
                          onChange: (event) =>
                            this.HandleCFChange(event.target.value),
                          onBlur: (event) =>
                            this.OnInputBlurCF(event.target.value),
                          value: this.state.RowItem.cf || "",
                          maxLength: 16
                        }}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="lblNoMargin" style={{ color: 565656 }}>
                        <small>Nazionalità</small>
                      </p>
                      <Dropdown
                        value={this.state.Nazionalita}
                        optionValue="cod"
                        optionLabel="name"
                        className="dropdownCustomisedForMUI"
                        options={this.state.NazionalitaList}
                        onChange={(e) =>
                          this.HandleDropDownChange(
                            "Nazionalita",
                            e.target.value,
                            "nazionalita"
                          )
                        }
                        filter
                        filterBy="name"
                        resetFilterOnHide
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3} className="select">
                      <CustomSelect
                        labelText="Sesso"
                        id="SESSO"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleDropDownChange(
                              "Sesso",
                              event.target.value,
                              "sesso"
                            ),
                          value: this.state.Sesso || ""
                        }}
                      >
                        {this.state.SessoList.map(item => (
                          <MenuItem value={item.name} key={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Indirizzo "
                        id="indirizzo"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("indirizzo", event.target.value),
                          value: this.state.RowItem.indirizzo || "",
                          maxLength: 255
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="CAP"
                        id="cap"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("capx", event.target.value),
                          onBlur: (event) =>
                            this.OnInputBlur(event.target.value),
                          value: this.state.RowItem.capx || "",
                          maxLength: 5
                        }}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="lblNoMargin" style={{ color: 565656 }}>
                        <small>Comune</small>
                      </p>
                      <Dropdown
                        value={this.state.selectedComune}
                        optionValue="nome"
                        resetFilterOnHide
                        className="dropdownCustomisedForMUI"
                        options={this.state.filteredComune}
                        onChange={(e) =>
                          this.HandleDropDownChange(
                            "selectedComune",
                            e.target.value,
                            "comunex"
                          )
                        }
                        optionLabel="nome"
                        filter
                        filterBy="nome"
                      />{" "}
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="lblNoMargin" style={{ color: 565656 }}>
                        <small>Provincia</small>
                      </p>
                      <Dropdown
                        value={this.state.selectedProvincia}
                        optionValue="sigla"
                        resetFilterOnHide
                        className="dropdownCustomisedForMUI"
                        options={this.state.ProvinciaList}
                        onChange={(e) =>
                          this.HandleDropDownChange(
                            "selectedProvincia",
                            e.target.value,
                            "provx"
                          )
                        }
                        optionLabel="sigla"
                        filter
                        filterBy="sigla"
                      />{" "}
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Telefono"
                        id="telefono"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("telefono", event.target.value),
                          value: this.state.RowItem.telefono || "",
                          maxLength: 50
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Fax"
                        id="fax"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("fax", event.target.value),
                          value: this.state.RowItem.fax || "",
                          maxLength: 50
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Cellulare"
                        id="cellulare"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("cell", event.target.value),
                          value: this.state.RowItem.cell || "",
                          maxLength: 50
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="E-mail"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("email", event.target.value),
                          value: this.state.RowItem.email || "",
                          maxLength: 60
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Seconda E-mail"
                        id="email2"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("email2", event.target.value),
                          value: this.state.RowItem.email2 || "",
                          maxLength: 60
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="PEC"
                        id="pec"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("pec", event.target.value),
                          value: this.state.RowItem.pec || "",
                          maxLength: 60
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Note"
                        id="note"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("note", event.target.value),
                          value: this.state.RowItem.note || "",
                          maxLength: 1000
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <ExtraFieldsModifica
                    dataItem={this.state.dataItem}
                    SetText={this.SetText}
                    provincias={this.state.ProvinciaList}
                    comunes={this.state.notFilteredComune}
                  />
                </CardBody>

                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}
