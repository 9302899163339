import Utils from "./Utils";
import moment from "moment";
import {dateTimeFmt} from "../helpers/AppConstants";

const CaricamentiAPI = {
  getAllCp: async function () {
    const response = await Utils.CreatePostRequest('coasco/carserver/getallcp');
    if (response && response.message && response.message.includes('200')) {
      response.result.forEach(row => {
        row.di = moment(row.di, dateTimeFmt);
        row.df = moment(row.df, dateTimeFmt);
      });
      return response.result;
    } else return null;
  },

  getAllDM10: async function () {
    const response = await Utils.CreatePostRequest('coasco/carserver/getalldm10');
    if (response?.message?.includes('200')) {
      response.result.forEach(row => {
        row.di = moment(row.di, dateTimeFmt);
        row.df = moment(row.df, dateTimeFmt);
      });
      return response.result;
    } else return null;
  }
};

export default CaricamentiAPI;
