import * as AppConstants from "../helpers/AppConstants";
import Utils from "./Utils";

const baseURL = AppConstants.SiteUrl + "coasco/carserveruni";

const CaricamentiAPI = {
  getAll: async function () {
    const res = await Utils.SendPostRequestTo(`${baseURL}/getall`);
    return res.result;
  }
};
export default CaricamentiAPI;
