import Utils from "./Utils";

const indSediInpsAPI = {

    async update(indSedeInps) {
        const response = await Utils.CreatePutRequest("coasco/indsedi/update", indSedeInps);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async insert(indSedeInps) {
        const response = await Utils.CreatePostRequest("coasco/indsedi/insert", indSedeInps);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    getNew() {
        return {
            cap: "",
            comune: "",
            denominazione: "",
            id: 0,
            indirizzo: "",
            presso: "",
            prov: "",
            regione: "",
            sede: "",
            titolo: ""
        };
    },

    async delete(indSedeInps) {
        const response = await Utils.CreateDeleteRequest("coasco/indsedi/delete?id=" + indSedeInps.id);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    }
};

export default indSediInpsAPI;