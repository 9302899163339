import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GestioneTabelleAPI from "API/GestioneTabelleAPI";
import qualifica3API from "../../API/Qualifica3API";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";
import Button from "../../components/CustomButtons/Button";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import {confirmPopup} from "primereact/confirmpopup";
import Close from "@material-ui/icons/Close";
import UndoIcon from "@material-ui/icons/Undo";
import {InputText} from "primereact/inputtext";
import CardFooter from "../../components/Card/CardFooter";
import ButtonSaveWithLoading from "../../components/CustomButtons/ButtonSaveWithLoading";

class Qualifica3 extends Component {

    state = {
        rows: [],
        loadingGrid: true,
        title: "",
        loadingPage: false,
        selectedGrid: null
    };

    constructor(props){
        super(props);
        this.handleTableClick = this.handleTableClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
    }

    async refreshGrid() {
        this.setState({loadingGrid: true});
        this.setState({
            rows: (await GestioneTabelleAPI.GetAllQualifica3()).result
        }, () => this.setState({loadingGrid: false}));
    }

    handleTableClick(e) {
        this.setState({
            selectedGrid: e.value,
            title: "Modifica"
        });
    }

    onChange(e, field) {
        this.state.selectedGrid[field] = e.target.value;
        this.setState({selectedGrid: this.state.selectedGrid});
    }

    async onClick() {

        let result = null;

        if (this.state.title === "Inserisci") {
            result = await qualifica3API.insert(this.state.selectedGrid);
        } else if (this.state.title === "Modifica") {
            result = await qualifica3API.update(this.state.selectedGrid);
        }

        if (result) {
            this.props.addAlert.current.addAlert("success", "Dati della qualifica salvati correttamente");
            this.setState({title: "Modifica"});
        } else {
            this.props.addAlert.current.addAlert("danger", "Non è stato possibile salvare i dati della qualifica");
        }

        await this.refreshGrid();
        return true;
    }

    async componentDidMount() {
        await this.refreshGrid();
    }

    render() {
        return (
            <>
                {this.state.loadingPage && <LoaderSpinner />}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                        <CardHeader color="ebinprofsuccess">
                            <h4 className={"d-inline"}>Qualifica 3</h4>
                            <Tooltip arrow title="Inserisci" placement="top">
                                <Button
                                    style={{margin: "0.4%"}}
                                    color="white"
                                    aria-label="edit"
                                    justIcon round
                                    className="float-right"
                                    onClick={() => {
                                        this.setState({
                                            selectedGrid: qualifica3API.getNew(),
                                            title: "Inserisci"
                                        });
                                    }}
                                >
                                    <AddIcon />
                                </Button>
                            </Tooltip>
                        </CardHeader>

                        <CardBody>
                            <DataTable
                                emptyMessage=""
                                className="p-datatable-striped p-datatable-sm"
                                value={this.state.rows}
                                dataKey="id"
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                                rows={10}
                                paginatorLeft={this.paginatorLeft}
                                paginatorRight={this.paginatorRight}
                                selection={this.state.selectedGrid}
                                loading={this.state.loadingGrid}
                                scrollable
                                onSelectionChange={this.handleTableClick}
                                selectionMode="single"
                            >
                                <Column
                                    field="codice"
                                    header="codice"
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterMatchMode="contains"
                                    filterPlaceholder=""
                                    style={{width: "10%"}}
                                />
                                <Column
                                    field="descrizione"
                                    header="descrizione"
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterMatchMode="contains"
                                    filterPlaceholder=""
                                />
                                <Column
                                    style={{width: "25px"}}
                                    body={(row) => {
                                        return (
                                            <Button
                                                round color="danger"
                                                className="actionButton actionButtonRound btnRound float-right"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    confirmPopup({
                                                        target: e.currentTarget,
                                                        message: "Vuoi eliminare questo record?",
                                                        header: "Confirmation",
                                                        icon: "pi pi-info-circle",
                                                        acceptClassName: "p-button-danger",
                                                        accept: async () => {
                                                            this.setState({loadingPage: true});
                                                            const result = await qualifica3API.delete(row);
                                                            this.setState({loadingPage: false});
                                                            if (result) {
                                                                this.props.addAlert.current.addAlert("success", "Qualifica eliminata correttamente");
                                                                this.setState({selectedGrid: null});
                                                            } else {
                                                                this.props.addAlert.current.addAlert("danger", "Non è stato possibile eliminare la qualifica");
                                                            }
                                                            await this.refreshGrid();
                                                        },
                                                        reject: () => {}
                                                    });
                                                }}
                                            >
                                                <Close className="icon" />
                                            </Button>
                                        );
                                    }}
                                />
                            </DataTable>
                        </CardBody>
                        </Card>
                    </GridItem>
                    {this.state.selectedGrid && (
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color={"ebinprofinfo"}>
                                    <h4 className={"d-inline"}>{this.state.title} la qualifica</h4>
                                    <Tooltip arrow title="Annulla" placement="top">
                                        <Button
                                            style={{margin: "0.4%"}}
                                            color="white"
                                            aria-label="edit"
                                            justIcon round
                                            className="float-right"
                                            onClick={() => this.setState({selectedGrid: null})}
                                        >
                                            <UndoIcon />
                                        </Button>
                                    </Tooltip>
                                </CardHeader>
                                <CardBody>
                                    <GridContainer className={"mb-2"}>
                                        <GridItem xs={12} sm={12} md={1}>
                                            Codice
                                            <InputText value={this.state.selectedGrid.codice} onChange={e => this.onChange(e, "codice")} />
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={11}>
                                            Descrizione
                                            <InputText value={this.state.selectedGrid.descrizione ?? ""} onChange={e => this.onChange(e, "descrizione")} />
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                                <CardFooter>
                                    <ButtonSaveWithLoading
                                        onClick={this.onClick}
                                        isValidForm={() => true}
                                        text={"SALVA"}
                                    />
                                </CardFooter>
                            </Card>
                        </GridItem>
                    )}
                </GridContainer>
            </>
        );
    }
}
export default Qualifica3;