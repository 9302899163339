import Utils from "./Utils";

const GeneraLettereAPI = {
  GeneraLettere: async function (
    anno,
    dataProt,
    oggetto,
    emailText,
    senderEmail,
    tipoInvio,
    selectedCaus,
    files,
    richiedenti
  ) {
    const res =  await Utils.httpPostWithStatus(
        "coasco/generalettere" +
        "?anno=" + anno +
        "&dataProt=" + dataProt +
        "&oggetto=" + oggetto +
        "&emailText=" + emailText +
        "&senderEmail=" + senderEmail +
        "&tipoInvio=" + tipoInvio +
        "&selectedCaus=" + selectedCaus,
        files,
        richiedenti,
        "richiedenti"
      );
    return res;
  }
};
export default GeneraLettereAPI;
