import React, { Component } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import { Button } from "primereact/button";
import queryString from "query-string";
import AziendeAPI from "API/AziendeAPI";
export class AziendaHeader extends Component {
  state = {
    azienda: { matricola: "", denominazione: "" }
  };

  constructor(props) {
    super(props);

    this.backButtonClick = this.backButtonClick.bind(this);
  }

  async componentDidMount() {
    const aziendaById = await AziendeAPI.GetAziendaById(this.props.aziendaId);
    if (aziendaById && aziendaById.length > 0) {
      this.setState({
        azienda: aziendaById[0]
      });
    }
  }

  backButtonClick() {
    let qS = queryString.parse(this.props.location.search);
    let maintainSearch = "?maintainSearch=1";
    if (!qS.type)
      this.props.history.push('/admin/aziende/' + this.props.aziendaId + maintainSearch);
    else if (qS.type === "fromVersamenti")
      this.props.history.push('/admin/versamenti/details/' + this.props.aziendaId + maintainSearch);
    else if (qS.type === "fromModificaRichieste")
      this.props.history.push('/admin/richiedenti/modificarichieste/' + this.props.aziendaId + '/' + qS.typeId + maintainSearch);
    else if (qS.type === "fromRichieste")
      this.props.history.push('/admin/richiedenti/modificarichieste/' + this.props.aziendaId + '/' + qS.typeId + maintainSearch + '&type=' + qS.type);
    else if (qS.type === "fromConsulenti")
      this.props.history.push('/admin/consulenti/' + this.props.aziendaId + maintainSearch);
    else if (qS.type === "fromAmministratori")
      this.props.history.push('/admin/amministratori/' + this.props.aziendaId + maintainSearch);
    else if (qS.type === "fromDipendenti")
      this.props.history.push('/admin/dipendenti/' + this.props.aziendaId + maintainSearch);
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Button
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />

                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Azienda
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CustomInput
                      labelText="Matricola"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.matricola || ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={5} md={5}>
                    <CustomInput
                      labelText="Ragione Sociale"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda.denominazione || ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
