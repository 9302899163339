import React, { Component } from "react";
import YearPicker from "components/YearPicker/index";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import ButtonsMUI from "@material-ui/core/Button";
import { RadioButton } from "primereact/radiobutton";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {ExportAnagraficheAPI} from "../../API/ExportAnagraficheAPI";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";

const List = [
  { id: 1, name: "Aziende" },
  { id: 2, name: "Dipendenti" },
  { id: 3, name: "Amministratori" },
  { id: 4, name: "Consulenti" }
];

const Formato = [
  { id: 1, name: "TXT" },
  { id: 2, name: "XLSX" }
];

class ExportAnagrafiche extends Component {

  state = {
    annoInizio: new Date().getFullYear(),
    annoFine: new Date().getFullYear(),
    visibleDates: false,
    radioChecked: null,
    selectedRadioButton: null,
    selectedRadioButtonFormato: null,
    radioCheckedFormato: null,
    load: false
  };

  constructor(props) {
    super(props);
    this.inizioIncreaseYear = this.inizioIncreaseYear.bind(this);
    this.fineIncreaseYear = this.fineIncreaseYear.bind(this);
    this.fineDecreaseYear = this.fineDecreaseYear.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.inizioDecreaseYear = this.inizioDecreaseYear.bind(this);
    this.RadioHandleChange = this.RadioHandleChange.bind(this);
    this.avvia = this.avvia.bind(this);
  }

  async avvia() {

    if (!this.state.selectedRadioButton) {
      this.props.addAlert.current.addAlert("danger", "Selezionare l'oggetto da esportare");
      return;
    }

    if (!this.state.selectedRadioButtonFormato) {
      this.props.addAlert.current.addAlert("danger", "Selezionare il formato di esportazione");
      return;
    }

    if ((this.state.radioChecked === 1 || this.state.radioChecked === 2) &&
        (this.state.annoInizio > this.state.annoFine || this.state.annoInizio < 2013 || this.state.annoFine < 2013 ||
            this.state.annoInizio > new Date().getFullYear() || this.state.annoFine > new Date().getFullYear())) {
      this.props.addAlert.current.addAlert("danger", "Selezionare gli anni di inizio e fine in modo corretto");
      return;
    }

    this.setState({load: true});
    await ExportAnagraficheAPI.avvia(this.state.selectedRadioButton, this.state.selectedRadioButtonFormato, this.state.annoInizio, this.state.annoFine);
    this.setState({load: false});
  }

  inizioIncreaseYear() {
    this.setState({
      annoInizio: this.state.annoInizio + 1
    });
  }

  fineIncreaseYear() {
    this.setState({
      annoFine: this.state.annoFine + 1
    });
  }

  inizioDecreaseYear() {
    this.setState({
      annoInizio: this.state.annoInizio - 1
    });
  }

  fineDecreaseYear() {
    this.setState({
      annoFine: this.state.annoFine - 1
    });
  }

  RadioHandleChange(e, val) {
    this.setState({
      [e]: val
    });

    if (val !== null)
      if (e === "selectedRadioButton") {
        this.setState({
          radioChecked: val.id
        });
        if (val.id === 1 || val.id === 2)
          this.setState({
            visibleDates: true
          });
        else
          this.setState({
            visibleDates: false
          });
      } else if (e === "selectedRadioButtonFormato")
        this.setState({
          radioCheckedFormato: val.id
        });
  }

  async handleDateChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.state.load && (<LoaderSpinner report={true} />)}
            <Card>
              <CardHeader color="primary">
                <h4
                  style={{
                    lineHeight: "1",
                    verticalAlign: "bottom"
                  }}
                >
                  Export Anagrafiche
                </h4>
              </CardHeader>
              <CardBody style={{ paddingTop: "0px", paddingBottom: "5px" }}>
                <GridContainer style={{marginTop: "15px"}}>
                  <GridItem xs={12} sm={12} md={5}>
                    <div className="float-left" style={{ marginTop: "2px" }}>
                      <p className="lblNoMargin">Oggetto export</p>
                      {List.map((type) => {
                        return (
                          <div
                            key={type.id}
                            className="p-field-radiobutton"
                            style={{
                              display: "inline-block",
                              marginRight: "10px"
                            }}
                          >
                            <RadioButton
                              inputId={type.name}
                              name="category"
                              value={type}
                              onChange={(e) =>
                                this.RadioHandleChange(
                                  "selectedRadioButton",
                                  e.value
                                )
                              }
                              checked={this.state.radioChecked === type.id}
                            />
                            <label className="labelCheckbox" htmlFor={type.id}>
                              &nbsp;{type.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <div className="float-left" style={{ marginTop: "2px" }}>
                      {" "}
                      <p className="lblNoMargin">Formato</p>
                      {Formato.map((type) => {
                        return (
                          <div
                            key={type.id}
                            className="p-field-radiobutton"
                            style={{
                              display: "inline-block",
                              marginRight: "10px"
                            }}
                          >
                            <RadioButton
                              inputId={type.name}
                              name="category"
                              value={type}
                              onChange={(e) =>
                                this.RadioHandleChange(
                                  "selectedRadioButtonFormato",
                                  e.value
                                )
                              }
                              checked={
                                this.state.radioCheckedFormato === type.id
                              }
                            />
                            <label className="labelCheckbox" htmlFor={type.id}>
                              &nbsp;{type.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </GridItem>
                  {this.state.visibleDates && (
                    <GridItem xs={12} sm={12} md={2}>
                      <p className="lblNoMarginExport">Anno Inizio</p>
                      <div className="date"
                        style={{
                          verticalAlign: "top",
                          lineHeight: "1",
                          marginRight: "5px",
                          display: "inline-flex"
                        }}
                      >
                        <SkipPreviousIcon
                          onClick={this.inizioDecreaseYear}
                          className="slide"
                        />
                        <YearPicker
                          onChange={this.handleDateChange}
                          value={this.state.annoInizio}
                          className="date"
                        />
                        <SkipNextIcon
                          onClick={this.inizioIncreaseYear}
                          className="slide"
                        />
                      </div>
                    </GridItem>
                  )}

                  {this.state.visibleDates && (
                    <GridItem xs={12} sm={12} md={2}>
                      <p className="lblNoMarginExport">Anno Fine</p>
                      <div
                        className="date"
                        style={{
                          verticalAlign: "top",
                          lineHeight: "0",
                          marginRight: "5px",
                          display: "inline-flex"
                        }}
                      >
                        <SkipPreviousIcon
                          onClick={this.fineDecreaseYear}
                          className="slide"
                        />
                        <YearPicker
                          onChange={this.handleDateChange}
                          value={this.state.annoFine}
                          className="date"
                        />
                        <SkipNextIcon
                          onClick={this.fineIncreaseYear}
                          className="slide"
                        />
                      </div>
                    </GridItem>
                  )}

                  <ButtonGroup
                    variant="contained"
                    color="primary"
                    size="small"
                    aria-label="contained primary button group"
                    className="float-right"
                    style={{
                      verticalAlign: "center",
                      marginTop: "12px",
                      marginBottom: "8px"
                    }}
                  >
                    <ButtonsMUI onClick={this.avvia}>Avvia</ButtonsMUI>
                  </ButtonGroup>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default ExportAnagrafiche;
