import * as AppConstants from "../helpers/AppConstants";
import Utils from "./Utils";

const defaultUrl = "ebinprof/PuntRichies";

const baseURL = AppConstants.SiteUrl + "ebinprof/PuntRichies";
const PunteggiAPI = {
  consolidates: async function (requestId) {
    const url = `${defaultUrl}/consolida?idrichies=${requestId}`;
    const res = await Utils.CreatePostRequest(url);
    return res;
  },

  createScore: async function (data) {
    const url = `${defaultUrl}/insert`;
    const res = await Utils.CreatePostRequest(url, data);
    return res;
  },

  updateScore: async function (data) {
    const url = `${defaultUrl}/update`;
    const res = await Utils.CreatePutRequest(url, data);
    return res;
  },

  getByIdRichies: async function (idRichies) {
    const res = await Utils.SendPostRequestTo(
      `${baseURL}/getByIdRichies?codice=${idRichies}`
    );
    return res;
  },

  PunteggiConseguiti: async function (requestId) {
    return await Utils.SendPostRequestTo(
      `${baseURL}/getbyRequestId?id=${requestId}`
    );
  },

  GetNuovoPunteggiConseguiti(punteggiId) {
    let rows = [
      {
        id: 1,
        idoneita: "NO",
        punteggio: "",
        cfurich: "",
        cfucons: "",
        descr: "",
        voto: ""
      },
      {
        id: 2,
        idoneita: "SI",
        punteggio: "",
        cfurich: "",
        cfucons: "",
        descr: "",
        voto: ""
      }
    ];
    return rows;
  },

  GetPunteggi: async function (punteggiId) {
    return {
      Id: "",
      Votazione: "1000",
      CfuRich: "0",
      CfuCons: "0",
      Cfu: "",
      Media: "",
      MediaPond: ""
    };
  },

  GetPunteggiConseguiti: async function (punteggiId) {
    return [
      {
        id: 1,
        idoneita: "SI",
        punteggio: 2,
        cfurich: 10,
        cfucons: 22,
        descrizione: "descript"
      },
      {
        id: 2,
        idoneita: "NO",
        punteggio: 3,
        cfurich: 11,
        cfucons: 23,
        descrizione: "des"
      },
      {
        id: 3,
        idoneita: "SI",
        punteggio: 4,
        cfurich: 12,
        cfucons: 23,
        descrizione: "descript"
      },
      {
        id: 4,
        idoneita: "NO",
        punteggio: 5,
        cfurich: 13,
        cfucons: 12,
        descrizione: "descript"
      },
      {
        id: 5,
        idoneita: "SI",
        punteggio: 6,
        cfurich: 15,
        cfucons: 34,
        descrizione: "descript"
      },
      {
        id: 6,
        idoneita: "NO",
        punteggio: 7,
        cfurich: 18,
        cfucons: 52,
        descrizione: "descript"
      }
    ];
  },

  AddPunteggi: async function (punteggiobj) {
    return await Utils.SendPostRequestTo(`${baseURL}/insert`, punteggiobj);
  },

  UpdatePunteggi: async function (punteggiobj) {
    return await Utils.SendPutRequestTo(`${baseURL}/update`, punteggiobj);
  },

  DeletePunteggi: async function (gradeId) {
    return await Utils.SendDeleteRequestTo(`${baseURL}/delete?id=${gradeId}`);
  }
};
export default PunteggiAPI;
