import React, { useContext, useEffect } from "react";
import customHistory from "../../customHistory";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";

// Components
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute.component";

// Contexts
import { AuthContext } from "providers";

const Routes = ({ GetUrlRefferer }) => {
  const { isAuthenticated, getProfile } = useContext(AuthContext);

  async function getProfileHandler() {
    getProfile();
  }

  useEffect(() => {
    isAuthenticated && getProfileHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Router history={customHistory}>
      <Switch>
        <Route path="/auth" component={AuthLayout} />
        <ProtectedRoute path="/admin" component={AdminLayout} />
        <Redirect from="/" to={GetUrlRefferer()} />
      </Switch>
    </Router>
  );
};

export default Routes;
