import Utils from "./Utils";
import * as AppConstants from "../helpers/AppConstants";

const partialURL = "ebinprof/RichiesBS";
const exportPartialUrl = "ebinprof/RichiesBSResult";
const baseURL = AppConstants.SiteUrl + partialURL;

const RichiestiAPI = {
  getRequestsByYearTypeProvince: async function () {
    const url = `${exportPartialUrl}/getRichiesteAnnoTipoProvincia`;
    const res = await Utils.CreatePostRequest(url);
    if (res?.message?.includes("200")) {
      return res?.result;
    } else return null;
  },

  getRequestsByYearTypeSex: async function () {
    const url = `${exportPartialUrl}/getRichiesteAnnoTipoSesso`;
    const res = await Utils.CreatePostRequest(url);
    if (res?.message?.includes("200")) {
      return res?.result;
    } else return null;
  },

  getGeneralReceivedRequests: async function () {
    const url = `${exportPartialUrl}/getRichiesteAnnoTipoPervenute`;
    const res = await Utils.CreatePostRequest(url);
    if (res?.message?.includes("200")) {
      return res?.result;
    } else return null;
  },

  multipleStateChange: async function (data) {
    const url = `${partialURL}/cambiostato`;
    return await Utils.CreatePostRequest(url, data);
  },

  printLabels: async function (requestIds, width = 100, height = 42) {
    if (width && height) {
      const url = `${partialURL}/stampaEtichette?ids=${requestIds}&larghezza=${width}&altezza=${height}`;
      return await Utils.CreatePostRequestWithFile(url);
    } else {
      const url = `${partialURL}/stampaEtichette?ids=${requestIds}`;
      return await Utils.CreatePostRequestWithFile(url);
    }
  },

  printRequestedCards: async function (requestIds) {
    const url = `${partialURL}/stampaSchedeRichieste?ids=${requestIds}`;
    return await Utils.CreatePostRequestWithFile(url);
  },

  printList: async function (arrayId, filters) {
    const res = await Utils.httpPostWithStatusDownload(
      "ebinprof/RichiesBS/stampaElenco?" + filters,
      arrayId,
      "ids"
    );
    return res;
  },

  ChangeState: async function (data) {
    return await Utils.SendPostRequestTo(
      `${baseURL}/cambiostato`,
      data
    );
  },

  GetRichiestePresentate: async function (cfRich) {
    const response = await Utils.SendPostRequestTo(
      `${baseURL}/getByCFRich?cFRich=${cfRich}`
    );
    return response ? response.result : null;
  },

  GetRequestById: async function (reqId) {
    const response = await Utils.SendPostRequestTo(
      `${baseURL}/getById?codice=${reqId}`
    );
    return response.result;
  },

  GetRichiesti: async function (limit) {
    const response = await Utils.SendPostRequestTo(
      limit ? `${baseURL}/getAll` : `${baseURL}/getFirst100`
    );
    return response ? response.result : null;
  },

  GetAllBorse: function (borseId, anno) {},

  GetPagamenti: async function (pagamentiId) {},

  GetNotesForRichiesti: async function (notesId) {},

  EsitoRichiesta_Stato: function () {},

  EsitoRichiesta_Esito: function () {},

  getByFilter: async function (start, size, sortField, sortOrder, filters) {
    let _filters;
    if (filters && typeof filters !== "undefined") _filters = filters;
    else _filters = null;
    let res;
    if (typeof sortField === "undefined" || !sortField) {
      res = await Utils.SendPostRequestTo(
        `${baseURL}/getByFilter?start=${start}&size=${size}${
          filters ? `&${_filters}` : ""
        }`
      );
    } else {
      res = await Utils.SendPostRequestTo(
        `${baseURL}/getByFilter?start=${start}&size=${size}&sortfield=${sortField}&sortorder=${
          sortOrder === 1 ? "ASC" : "DESC"
        }${filters ? `&${_filters}` : ""}`
      );
    }

    return res;
  },

  EsitoRichiesta_Motivazione: function () {},

  Ordina_Richieste: function () {},

  AddRichiesta: async function (richiestaObj) {
    return await Utils.SendPostRequestTo(`${baseURL}/insert`, richiestaObj);
  },

  UpdateRichiesta: async function (richiestaObj) {
    return Utils.SendPutRequestTo(`${baseURL}/update`, richiestaObj);
  },

  DeleteRichiesta: async function (richiestaId) {
    return Utils.SendDeleteRequestTo(`${baseURL}/delete?id=${richiestaId}`);
  }
};
export default RichiestiAPI;
