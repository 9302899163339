import * as AppConstants from "helpers/AppConstants";
import Utils from "./Utils";

const defaultUrl = AppConstants.SiteUrl + "coasco/auth";

const authApi = {
  login: async function (username, password) {
    const url = `${defaultUrl}/login`;
    return await Utils.SendPostRequestTo(url, {username, password});
  },

  getProfile: async function () {
    const url = `${defaultUrl}/verify`;
    return await Utils.SendPostRequestTo(url);
  }
};

export default authApi;
