import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CaricamentiServerAPI from "API/CaricamentiServerAPI";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Helpers from "helpers/Helpers.js";
import {dateFmt} from "../../helpers/AppConstants";

class VerificaCaricamentiDM10 extends React.Component {

  state = {
    rows: [],
    loadingGrid: false,
    selectedGrid: null
  };

  constructor(props) {
    super(props);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
  }

  handleTableClick(e) {
    this.setState({
      selectedGrid: e.value
    });
  }

  ChangeFilterGrid(e) {
    this.setState({
      filterGrid: e.target.checked
    });
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true,
      rows: await CaricamentiServerAPI.getAllDM10()
    },
        () => {
          this.setState({loadingGrid: false})
        }
    );
  }

  render() {
    return (
      <>
        {" "}
        <div ref={this.btnSearchRef} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Caricamenti DM10
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10}
                  //rowsPerPageOptions={[10, 20, 30]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  loading={this.state.loadingGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="di"
                    header="Data Inizio"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    body={(row) => {
                        return row.di.format(dateFmt);
                    }}
                  />
                  <Column
                    field="df"
                    header="Data fine"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    body={(row) => {
                        return row.df.format(dateFmt);
                    }}
                  />
                  <Column
                    field="cq"
                    header="Codice Quota"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="nr"
                    header="NumRighe"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="ir"
                    header="Importo"
                    body={(rowData) => Helpers.DecimalDTTemplate(rowData, "ir")}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default VerificaCaricamentiDM10;
