import React from "react";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import MenuItem from "@material-ui/core/MenuItem";
import GridItem from "components/Grid/GridItem.js";
import { Calendar } from "primereact/calendar";
import GridContainer from "components/Grid/GridContainer";
import StaticLists from "helpers/StaticLists.js";
import moment from "moment";

const compToSendFmt = "YYYY/MM";

export default class TrasferisciUniemens extends React.Component {
  state = {
    selectedCodice: [],
    CodiceQuota: StaticLists.CodiceQuota(),
    DataCompDa: Date.now()
  };

  constructor(props) {
    super(props);
  }

  HandleChange(field, newValue) {
    var newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem, ...this.state });
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={1} sm={1} md={1}>
          <p className="lblNoMargin">Storna da:</p>
        </GridItem>

        <GridItem xs={4} className="select">
          <CustomSelect
            labelText="Codice quota"
            id="codq"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: false,
              onChange: (e) => {
                this.setState({ selectedCodice: e.target.value });
                this.props.result.cod_quota_trasf = e.target.value;
              },
              value: this.state.selectedCodice || ""
            }}
          >
            {this.state.CodiceQuota.map((item) => (
              <MenuItem value={item.name} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </GridItem>
        <GridItem xs={12} sm={12} md={2} className="customdate">
          <p className="lblNoMargin">Competenze Da</p>
          <Calendar
            id="icon"
            value={moment(this.props.result.data_trasf, compToSendFmt).toDate()}
            view="month"
            dateFormat="yy/mm"
            onChange={(e) => (this.props.result.data_trasf = moment(e.target.value).format(compToSendFmt))
            }
            showIcon
            locale="it"
          />
        </GridItem>
      </GridContainer>
    );
  }
}
