const regExs = {
  fiscalCode:
    /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z0-9]{1}[a-zA-Z]{1}$/,
  email:
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  iban: /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i,
  piva: /^[0-9]{11}$/
};

export function validateRequired(field) {
  const _field = `${field}`;
  if (!_field || _field.trim() === "") return { isValid: false };
  else return { isValid: true };
}

export function validateFreshman(freshman, isRequired = false) {
  if (!isRequired && freshman === null) return { isValid: true };
  else if (
    (!isRequired && typeof freshman === "undefined") ||
    freshman.trim() === ""
  )
    return { isValid: true };
  else if (parseInt(freshman) < 0)
    return { isValid: false, errorMessage: "Matricola Errata" };
  else if (freshman.length < 10)
    return { isValid: false, errorMessage: "Caratteri Minimi: 10" };
  else return { isValid: true };
}

export function validateCap(cap, isRequired = false) {
  if (!isRequired && cap === null) return { isValid: true };
  else if ((!isRequired && typeof cap === "undefined") || cap.trim() === "")
    return { isValid: true };
  else if (parseInt(cap) < 0)
    return { isValid: false, errorMessage: "CAP Errato" };
  else if (cap.length < 5)
    return { isValid: false, errorMessage: "Caratteri Minimi: 5" };
  else return { isValid: true };
}

export function validateFiscalCode(fiscalCode, isRequired = false) {
  if (!isRequired && fiscalCode === null) return { isValid: true };
  else if (
    (!isRequired && typeof fiscalCode === "undefined") ||
    fiscalCode.trim() === ""
  )
    return { isValid: true };
  else if (fiscalCode.length < 16)
    return { isValid: false, errorMessage: "Caratteri Minimi: 16" };
  else if (fiscalCode.search(regExs.fiscalCode) !== 0) {
    return { isValid: false, errorMessage: "Codice Fiscale Errato" };
  } else return { isValid: true };
}

export function validatePiva(piva, isRequired = false) {
  if (!isRequired && piva === null) return { isValid: true };
  else if ((!isRequired && typeof piva === "undefined") || piva.trim() === "")
    return { isValid: true };
  if (isRequired && piva.trim() === "") return { isValid: false };
  else if (piva.length < 11)
    return { isValid: false, errorMessage: "Caratteri Minimi: 11" };
  else if (piva.search(regExs.piva) !== 0) {
    return { isValid: false, errorMessage: "Partita IVA Errata" };
  } else return { isValid: true };
}

export function validateEmail(email, isRequired = false) {
  if (!isRequired && email === null) return { isValid: true };
  else if ((!isRequired && typeof email === "undefined") || email.trim() === "")
    return { isValid: true };
  else if (email.search(regExs.email) !== 0) {
    return { isValid: false, errorMessage: "E-Mail Errata" };
  } else return { isValid: true };
}

export function validateIban(iban, isRequired = false) {
  if (!isRequired && iban === null) return { isValid: true };
  else if ((!isRequired && typeof iban === "undefined") || iban.trim() === "")
    return { isValid: true };
  else if (iban.search(regExs.iban) !== 0) {
    return { isValid: false, errorMessage: "IBAN Errato" };
  } else return { isValid: true };
}

export function validatePhone(phone, isRequired = false) {
  if (!isRequired && phone === null) return { isValid: true };
  else if ((!isRequired && typeof phone === "undefined") || phone.trim() === "")
    return { isValid: true };
  else if (phone.length < 10 || parseInt(phone) < 0) {
    return { isValid: false, errorMessage: "Numero Errato" };
  } else return { isValid: true };
}

export function validateFax(fax, isRequired = false) {
  if (!isRequired && fax === null) return { isValid: true };
  else if ((!isRequired && typeof fax === "undefined") || fax.trim() === "")
    return { isValid: true };
  else if (parseInt(fax) < 0)
    return { isValid: false, errorMessage: "Fax Errato" };
  else return { isValid: true };
}
