import Utils from "./Utils";

const StatisticheAPI = {

    get: async function (queryName) {
        const response = await Utils.CreatePostRequest("coasco/statistiche/get?queryName=" + queryName);
        if (response?.message?.includes('200')) {
            return response?.result;
        } else return null;
    },

    getAnno: async function (queryName, anno) {
        const response = await Utils.CreatePostRequest("coasco/statistiche/getAnno?queryName=" + queryName + "&anno=" + anno);
        if (response?.message?.includes('200')) {
            return response?.result;
        } else return null;
    },

    getDettaglio: async function (queryName, params) {
        const response = await Utils.CreatePostRequest("coasco/statistiche/getDettaglio?queryName=" + queryName + "&params=" + params);
        if (response?.message?.includes('200')) {
            Utils.ExportExcel(response.result);
        }
    }
}

export default StatisticheAPI;