import React, { useContext, useState } from "react";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";
import UndoIcon from "@material-ui/icons/Undo";
import CardFooter from "components/Card/CardFooter.js";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import PunteggiAPI from "API/PunteggiAPI";
import { currentDateTime2 } from "assets/constants";
import { AuthContext } from "providers";

const NuovoPunteggio = ({
  requestId,
  score = null,
  refreshTable,
  newScoreHandler,
  ...props
}) => {
  const { onAnnullaClick: cancelHandler } = props;
  const [state, setState] = useState(score);
  const { createScore } = PunteggiAPI;
  const addAlert = props?.addAlert?.current?.addAlert;
  const { user } = useContext(AuthContext);

  const title = `${score ? "Modifica" : "Nuovo"} Punteggio`;

  const cardHeaderComponent = (
    <CardHeader color="borseinfo">
      <h4
        style={{
          lineHeight: "0",
          display: "inline",
          verticalAlign: "bottom"
        }}
      >
        {title}
      </h4>
      <Tooltip
        arrow
        id="tooltip-top"
        title="Annulla"
        placement="top"
        classes={{ tooltip: "tooltip-me" }}
      >
        <Button
          style={{
            verticalAlign: "center",
            lineHeight: "1",
            margin: "0.1%",
            marginLeft: "0",
            marginRight: "0"
          }}
          color="white"
          aria-label="edit"
          justIcon
          round
          className="float-right"
          onClick={cancelHandler}
        >
          <UndoIcon />
        </Button>
      </Tooltip>
    </CardHeader>
  );

  function fieldHandler(propLabel, value) {
    const newState = {
      ...state,
      [propLabel]: value
    };
    setState(newState);
  }

  function buildJson() {
    return {
      cfuCons: state?.deliveredCfu,
      cfuRich: state?.requestedCfu,
      dataAgg: currentDateTime2,
      descr: state?.description,
      idPunt: state?.id,
      idRichiesBS: requestId,
      idoneita: state?.eligibility ? 1 : 0,
      punteggio: state?.score,
      utente: user?.codice
    };
  }

  async function onSave() {
    try {
      const data = buildJson();
      const res = await createScore(data);
      if (res && res.result) {
        addAlert("success", "Punteggio inserito correttamente");
        newScoreHandler();
        newScoreHandler();
        refreshTable();
      } else {
        addAlert("danger", "Il punteggio non è stato inserito correttamente");
      }
    } catch (error) {
    } finally {
      return true;
    }
  }

  const cardBodyComponent = (
    <CardBody>
      <GridContainer>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Id *"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              value: state?.id
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2} className="mt-3">
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={state?.eligibility}
                onChange={(event, value) => fieldHandler("eligibility", value)}
              />
            }
            label="Idoneità"
            className="labelCheckbox"
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <CustomInput
            labelText="Voto"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (event) => fieldHandler("score", event.target.value),
              value: state?.score,
              type: "number"
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            labelText="CFU Richiesti"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (event) =>
                fieldHandler("requestedCfu", event.target.value),
              value: state?.requestedCfu,
              type: "number"
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            labelText="CFU Conseguiti"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (event) =>
                fieldHandler("deliveredCfu", event.target.value),
              value: state?.deliveredCfu,
              type: "number"
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <TextField
            style={{
              backgroundColor: "#fff",
              borderColor: "primary"
            }}
            id="filled-multiline-flexible"
            label="Descrizione"
            onChange={(e) => fieldHandler("description", e.target.value)}
            multiline
            rows={3}
            value={state?.description}
            variant="filled"
          />
        </GridItem>
      </GridContainer>
    </CardBody>
  );

  const cardFooterComponent = (
    <CardFooter>
      <ButtonSaveWithLoading
        isValidForm={() => {
          return true;
        }}
        onClick={onSave}
        text="Salva"
      />
    </CardFooter>
  );

  const cardComponent = (
    <Card>
      {cardHeaderComponent}
      {cardBodyComponent}
      {cardFooterComponent}
    </Card>
  );

  return (
    <GridContainer>
      <GridItem xs={12} md={12} xm={12}>
        {cardComponent}
      </GridItem>
    </GridContainer>
  );
};

export default NuovoPunteggio;
