import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import LoaderSpinner from "components/Layout/LoaderSpinner.js";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import queryString from "query-string";
import CustomButtons from "components/CustomButtons/Button.js";
import { ModificaCorrispondenzaXRichieste } from "components/Views/Corrispondenza/ModificaCorrispondenzaXRichieste";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import helpers from "helpers/Helpers";
import RichiestiAPI from "API/RichiestiAPI";
import { Button } from "primereact/button";
import { rowsPerPageOptions } from "../../helpers/AppConstants";
import DipendentiAPI from "API/DipendentiAPI";
import RichiedentiAPI from "API/RichiedentiAPI";

class CorrispondenzaXVersamenti extends React.Component {
  state = {
    rows: [],
    selectedCorrisp: null,
    selectedGrid: null,
    filterGrid: false,
    loadingGrid: false,
    visibleLoader: false,
    name: "",
    disabled: false,
    visAField: true,
    visCField: true,
    visDField: true,
    radioChecked: 3,
    attachments: [],
    selectedAttach: new Set(),
    tipo: "",
    Anno: new Date().getFullYear(),
    HeaderDate: null,
    prot: "",
    headerDate: "",
    causale: "",
    sede: "",
    provSap: "",
    sapMatricola: "",
    daMatricola: "",
    aMatricola: "",
    initialRows: []
  };

  constructor(props) {
    super(props);

    this.handleTableClick = this.handleTableClick.bind(this);
    this.templateCheckboxGrid = this.templateCheckboxGrid.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.RadioHandleChange = this.RadioHandleChange.bind(this);
    this.btnSearchRef = React.createRef();
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.HandleYearPickerChange = this.HandleYearPickerChange.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
    this.getApplicant = this.getApplicant.bind(this);
    this.buildJson = this.buildJson.bind(this);
  }

  backButtonClick() {
    this.props.history.goBack();
  }

  templateCheckboxGrid(rowData) {
    return (
      <div className="p-field-checkbox">
        <Checkbox
          inputId="binary"
          checked={rowData.allegati}
          onChange={(e) => {
            e.checked
              ? this.state.selectedAttach.add(rowData.id)
              : this.state.selectedAttach.delete(rowData.id);
            let list = this.state.rows;
            let el = list.find((x) => x.id === rowData.id);
            el.allegati = e.checked;

            this.setState({ rows: list, selectedCorrisp: null });
          }}
        />
      </div>
    );
  }

  async RadioHandleChange(e, val) {
    this.setState({
      [e]: val
    });

    if (val !== null) {
      this.setState({
        radioChecked: val.id,
        name: val.name,
        tipo: val.name,
        rows: await this.filterMails(val.name)
      });
    }
  }

  async filterMails(type) {
    const rows = this.state.initialRows;
    switch (type) {
      case "Entrata":
        return rows.filter((row) => row.tipo === type.toUpperCase());
      case "Uscita":
        return rows.filter((mail) => mail.tipo === type?.toUpperCase());
      case "Tutta":
        return rows;
    }
  }

  async onNuovoClick() {

    this.setState({
      visibleLoader: true,
      
    });
    this.setState({

      selectedCorrisp:  null
    });
    setTimeout(() => {
      let qS = queryString.parse(this.props.history.location.search);
      let nuovocorrispondenza =  CorrispondenzaAPI.GetNuovoCorrispondenza(
        qS.typeId
      );
  
      this.setState({
        selectedCorrisp: nuovocorrispondenza,
        selectedGrid: null,
        disabled: false,
        visAField: false,
        visCField: false,
        visDField: false
      });
      this.setState({
        visibleLoader: false
      });

      
    }, 200);
    
   
  }

  onAnnullaClick() {
    this.setState({
      selectedCorrisp: null,
      disabled: false
    });
  }

  onCloseGridClick() {
    this.setState({
      selectedGrid: null,
      selectedCorrisp: null
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true
    });

    this.setState({
      rows: await CorrispondenzaAPI.GetCorrispondenzaEbinBy(0, 10, {
        tipo: this.state.name === "Tutta" ? null : this.state.name,
        anno: this.state.Anno,
        prot: this.state.prot,
        data: this.state.HeaderDate,
        causale: this.state.causale,
        codsap: this.state.sede,
        sapFilter: this.state.provSap,
        sap_matri: this.state.sapMatricola,
        fromMatr: this.state.daMatricola,
        toMatr: this.state.aMatricola,
        idrichies: this.props.match.params.richiesteId
      }).result
    });
    this.setState({
      loadingGrid: false
    });
  }

  async ChangeFilterGrid(e) {
    this.setState({
      filterGrid: e.target.checked
    });
  }

  handleTableClick = async (e) => {
      this.setState({
        visibleLoader: true,
        selectedCorrisp: null
      });
      const corrisp = await CorrispondenzaAPI.getEbinCorrespondenceById(
        e.value.id
      );
      this.setState({
        selectedCorrisp: {
          ...corrisp,
          dtlett: corrisp?.dtlett
            ? helpers.GetDateObjectFromStringDate(corrisp.dtlett)
            : "",
          tipoinvio: corrisp?.tipoinvio?.id,
          tipo: corrisp?.tipo === "ENTRATA" ? 1 : 2,
          causale: corrisp?.causale?.codice
        },
        selectedGrid: e.value,
        disabled: true,
        visAField: true,
        visCField: true,
        visDField: true,
        visibleLoader: false
      });
  };

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  async getApplicant(applicantFiscalCode) {
    const applicantRes = await RichiedentiAPI.getByCF(applicantFiscalCode);
    this.setState({
      applicantEmail: applicantRes?.pop()?.email?.toLowerCase()
    });
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });

    let rows = await CorrispondenzaAPI.getByIdRichies(
      this.props.match.params.richiesteId
    );

    const applicantFiscalCode = rows?.result[0]?.cfrichied;
    await this.getApplicant(applicantFiscalCode);

    this.setState({
      initialRows: rows.result,
      rows: rows.result
    });

    this.setState({
      name: "Tutta",
      loadingGrid: false
    });
  }

  formatDate(date) {
    const year = date?.getFullYear();
    const month = date?.getMonth() + 1;
    const _month = month > 10 ? month : `0${month}`;
    const day = date?.getDate();
    const _day = day > 10 ? day : `0${day}`;
    return `${_day}/${_month}/${year} 00:00:00`;
  }
  
  buildJson(data, request) {
    const requestId = this.props.match.params.richiesteId;
    const applicantFiscalCode = request.cfRich?.cfRich;
    const applicantSurname = request?.cfRich?.cognome;
    const applicantName = request?.cfRich?.nome;
    let letterDate = !!data?.dtlett ? data.dtlett : null;
    if(!!letterDate){
      //letterDate instanceof Date true quando in modifica non si cambia la data
      letterDate = letterDate instanceof Date ? this.formatDate(letterDate) : letterDate;
    }
    return {
      id: data?.id,
      idrichies: requestId,
      anno: data?.anno,
      prot: data?.prot,
      tipo: data?.tipo === 1 ? "ENTRATA" : "USCITA",
      tipoinvio: data?.tipoinvio,
      causale: data?.causal,
      oggetto: data?.oggetto,
      testomail: data?.testomail,
      mittmail: data?.mittmail,
      cfrichied: applicantFiscalCode,
      mitt_dest: `${applicantSurname} ${applicantName}`,
      dtlett: letterDate,
      data: data?.data
    };
  }

  HandleSaveClick = async (data, user, files) => {
    const res = await RichiestiAPI.GetRequestById(
      this.props.match.params.richiesteId
    );
    const request = res[0];
    this.setState({ loadingGrid: true, selectedCorrisp: null });
    const senderId = user[0]?.codice;
    const object = data?.oggetto;

    const json = this.buildJson(data, request);

    // Recipients E-mail
    const recipientEmails = [];
    const otherRecipient = data?.otherRecipient?.toLowerCase();
    const applicantEmail = this.state.applicantEmail?.toLowerCase();
    otherRecipient && recipientEmails.push(otherRecipient);
    applicantEmail && recipientEmails.push(applicantEmail);

    if (data.id) {
      const res = await CorrispondenzaAPI.UpdateCorrispondenzaEbin1(
        senderId,
        recipientEmails,
        object,
        json,
        files
      );
      this.setState({ loadingGrid: false, selectedCorrisp: {} });
    } else {
      const res = await CorrispondenzaAPI.AddCorrispondenzaEbin(
        senderId,
        recipientEmails,
        object,
        json,
        files
      );
      if (json?.tipo?.toUpperCase() === 'USCITA' && json?.tipoinvio === 1) {
        const ris = new Blob([res.data]);
        let url = window.URL.createObjectURL(ris);
        let a = document.createElement("a");
        a.href = url;
        a.download = "lettera.pdf";
        a.click();
        a.remove();
        this.setState({
          selectedCorrisp: null,
          selectedGrid: null
        });
      }
    }
    const resCorrespondence = await CorrispondenzaAPI.getByIdRichies(
      this.props.match.params.richiesteId
    );
    this.setState({
      rows: resCorrespondence.result,
      loadingGrid: false,
      selectedCorrisp: {}
    });
  };

  handleClearClick() {
    this.setState({
      loadingGrid: true
    });
    let initialRows = this.state.initialRows;
    this.setState({
      Anno: new Date().getFullYear(),
      HeaderDate: null,
      rows: initialRows
    });
    this.setState({
      loadingGrid: false
    });
  }

  async handleSearchClick(tipo, anno, prot, causale) {
    this.setState(
      {
        tipo: tipo,
        anno: anno,
        prot: prot,
        causale: causale
      },
      async () => await this.refreshGrid()
    );
  }

  HandleYearPickerChange(e) {
    this.setState({
      Anno: e
    });
  }

  render() {
    return (
      <>
        <div ref={this.btnSearchRef} />
        {this.state.visibleLoader && <LoaderSpinner fullHeight={true} />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <Button
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />

                <h4 className="d-inline">Corrispondenza</h4>
                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <CustomButtons
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </CustomButtons>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10}
                  rowsPerPageOptions={rowsPerPageOptions}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedCorrisp}
                  scrollable
                  scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="anno"
                    header="Anno"
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="prot"
                    header="Prot."
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="tipo"
                    header="Tipo"
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data"
                    header="Data"
                    body={(rowData) =>
                      helpers.DateTemplateWithHours(rowData, "data")
                    }
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="causale.descrizione"
                    header="Causale"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cfrichied"
                    header="C. F. Richiedente"
                    headerStyle={{ width: "170px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="mitt_dest"
                    header="Mitt. / Dest."
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="oggetto"
                    header="Oggetto"
                    headerStyle={{ width: "180px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.selectedCorrisp && (
          <ModificaCorrispondenzaXRichieste
            {...this.props}
            activeSnackbar={this.props.addAlert.current.addAlert}
            type={this.state.name}
            dataItem={this.state.selectedCorrisp}
            onAnnullaClick={this.onAnnullaClick}
            onClick={this.HandleClick}
            disable={this.state.disabled}
            request={this.state.request}
            HeaderDate={this.state.HeaderDate}
            onSaveClick={this.HandleSaveClick}
          />
        )}
      </>
    );
  }
}

export default CorrispondenzaXVersamenti;
