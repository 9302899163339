import React, {
  useEffect,
  useState,
  Fragment as Container,
  useRef,
  useContext
} from "react";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import helpers from "helpers/Helpers";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Button from "components/CustomButtons/Button.js";
import MUIButton from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Checkbox } from "primereact/checkbox";
import { ModificaCorrispondenzaAmministratore as Modifica } from "components/Views/Corrispondenza/Amministratore/ModificaCorrispondenzaAmministratore.component";
import { HeaderCorrispondenza as Header } from "components/Views/Corrispondenza/HeaderCorrispondenza.component";
import AmministratoriAPI from "API/AmministratoriAPI";
import { AuthContext } from "../../providers";
import { rowsPerPageOptions } from "../../helpers/AppConstants";

const Corrispondenza = ({ ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState("ENTRATA");
  const [protocolType, setProtocolType] = useState(1);
  const [correspondences, setCorrespondences] = useState([]);
  const [correspondence, setCorrespondence] = useState(null);
  const [administrator, setAdministrator] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const selectedAttach = new Set();
  const administratorId = props?.match?.params?.administratorId;
  const headerRef = useRef(null);
  const {
    getByAdministratorId: getCorrespondence,
    getByAdministratorIdEbin: getCorrespondenceEbin,
    GetExportedFiles: downloadCorrespondence
  } = CorrispondenzaAPI;
  const { getAdministratorById: getAdministrator } = AmministratoriAPI;
  const [ufficio, setUfficio] = useState(null);
  const { profile } = useContext(AuthContext);

  useEffect(() => {
    profile && setUfficio(profile.ufficio.toUpperCase());
  }, [profile]);

  async function getCorrespondencesHandler(value) {
    const _filter = value || filter;
    setIsLoading(true);
    try {
      const res =
        profile.ufficio === "EBINPROF"
          ? await getCorrespondenceEbin(administratorId)
          : await getCorrespondence(administratorId);
      if (_filter === "TUTTA") {
        setCorrespondences(res?.result);
      } else {
        const _res = res?.result?.filter(
          (x) => x?.tipo.toUpperCase().trim() === _filter.toUpperCase()
        );
        setCorrespondences(_res);
      }
    } catch (error) {
      return props?.addAlert?.current?.addAlert(
        "danger",
        "La corrispondenza non è stata caricata correttamente"
      );
    } finally {
      setIsLoading(false);
    }
  }

  async function getAdministratorHandler() {
    setIsLoading(true);
    try {
      const res = await getAdministrator(administratorId);
      setAdministrator(res?.result[0]);
    } catch (error) {
      return props?.addAlert?.current?.addAlert(
        "danger",
        "L'amministratore non è stato caricato correttamente"
      );
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCorrespondencesHandler();
    getAdministratorHandler();
  }, []);

  function addCorrespondence() {
    setCorrespondence(null)
    setTimeout(() => {
      setCorrespondence({});
    }, 200);
  }

  async function downloadCorrespondenceHandler() {
    const _correspondences = correspondences.filter(
      (x) => x.pathattach === true
    );
    if (_correspondences.length === 0)
      props.addAlert.current.addAlert(
        "warning",
        "Selezionare almeno una corrispondenza"
      );
    else {
      const ids = [];
      _correspondences.map((x) => {
        return ids.push(x.id);
      });
      await downloadCorrespondence(ids, "Corrispondenza Amministratore");
    }
  }

  async function filterHandler(value) {
    setCorrespondence(null)
    setFilter(value);
    await getCorrespondencesHandler(value);
  }

  const headerComponent = (
    <div ref={headerRef}>
      <Header filter={filter} filterHandler={filterHandler} {...props} />
    </div>
  );

  const tableHeaderComponent = (
    <CardHeader color="success">
      <h4
        style={{
          lineHeight: "0",
          display: "inline",
          verticalAlign: "bottom"
        }}
      >
        {filter}
      </h4>
      {filter != "TUTTA" && (
        <Tooltip
          arrow
          id="tooltip-top"
          title="Inserisci"
          placement="top"
          classes={{ tooltip: "tooltip-me" }}
        >
          <Button
            style={{
              verticalAlign: "center",
              lineHeight: "1",
              margin: "0.1%"
            }}
            color="white"
            aria-label="edit"
            justIcon
            round
            className="float-right"
            onClick={addCorrespondence}
          >
            <AddIcon />
          </Button>
        </Tooltip>
      )}
      <ButtonGroup
      variant="contained"
      color="primary"
      size="small"
      aria-label="contained primary button group"
      className="float-right"
      style={{ verticalAlign: "center", margin: "0.5%" }}
    >
      <MUIButton onClick={downloadCorrespondenceHandler}>
        Esporta files
      </MUIButton>
    </ButtonGroup>
    </CardHeader>
  );

  function checkboxHandler(event, rowData) {
    event.preventDefault();
    event.checked
      ? selectedAttach.add(rowData.id)
      : selectedAttach.delete(rowData.id);
    const _correspondences = correspondences;
    const element = _correspondences.find((x) => x.id === rowData.id);
    element.pathattach = event.checked;
    const filteredCorrespondences = _correspondences.filter(
      (x) => x.id !== rowData.id
    );
    filteredCorrespondences.unshift(element);
    setCorrespondences(filteredCorrespondences);
  }

  const checkboxComponent = (rowData) => (
    <div className="p-field-checkbox">
      <Checkbox
        inputId="binary"
        checked={rowData.pathattach}
        onChange={(event) => checkboxHandler(event, rowData)}
      />
    </div>
  );

  function tableRowHandler(rowData) {
    const row = rowData.value;
    setCorrespondence(row);
  }

  function cancelHandler() {
    setCorrespondence(null);
    try {
      headerRef.current.scrollIntoView();
    } catch(err){}
  }

  function buildJson(rowItem) {
    return {
      utente: rowItem?.user?.utente,
      azienda: rowItem?.azienda,
      codsap: rowItem?.codsap,
      tipo: filter,
      prot: rowItem?.prot,
      anno: new Date().getFullYear(),
      data: rowItem?.data,
      causale: rowItem?.causale,
      note: rowItem?.note,
      flmodo: rowItem?.flmodo,
      fldocele: rowItem?.fldocele,
      dtlett: rowItem?.dtlett,
      mitt_dest: rowItem?.mitt_dest,
      oggetto: rowItem?.oggetto,
      mailtext: rowItem?.mailtext || "",
      id: rowItem?.id,
      prov: rowItem?.prov || rowItem?.company?.prov || null,
      numtab: isNaN(parseInt(rowItem?.numtab))
        ? null
        : parseInt(rowItem?.numtab),
      numfat: isNaN(parseInt(rowItem?.numfat))
        ? null
        : parseInt(rowItem?.numfat),
      numpreav: isNaN(parseInt(rowItem?.numpreav))
        ? null
        : parseInt(rowItem?.numpreav),
      w350: rowItem?.w350,
      w300: rowItem?.w300,
      ncontabbanc: isNaN(parseInt(rowItem?.ncontabbanc))
        ? null
        : parseInt(rowItem?.ncontabbanc),
      prbuchicont: rowItem?.prbuchicont,
      pathattach: rowItem?.pathattach,
      fldoppi: rowItem?.fldoppi,
      sede_inps: rowItem?.sede_inps || "",
      ninvio: rowItem?.ninvio,
      flamm: 1,
      flaz: rowItem?.flaz,
      flcons: rowItem?.flcons,
      fldip: rowItem?.fldip,
      puli: rowItem?.puli,
      cust: rowItem?.cust,
      amm:rowItem?.amm,
      tipoinvio: rowItem?.tipoinvio,
      mittmail: rowItem?.mittmail?.toLowerCase(),
      destmail: rowItem?.destmail?.toLowerCase(),
      cfcons: rowItem?.cfcons,
      cfamm: administrator?.cf,
      cfdip: rowItem?.cfdip,
      haAllegati: null,
      testomail: rowItem?.testomail || ""
    };
  }

  async function addCorrespondenceHandler(
    RowItem,
    files,
    isEdit,
    senderEmail,
    administratorEmail
  ) {
    const rowItem = RowItem;
    const senderId = rowItem?.user?.codice;
    let recipientEmails = [];
    rowItem?.administratorEmail &&
      recipientEmails.push(rowItem?.administratorEmail?.toLowerCase());
    rowItem?.consultantEmail &&
      recipientEmails.push(rowItem?.consultantEmail?.toLowerCase());
    rowItem?.employeeEmail &&
      recipientEmails.push(rowItem?.employeeEmail?.toLowerCase());
    rowItem?.sendCompanyEmail &&
      recipientEmails.push(rowItem?.company?.email?.toLowerCase());
    rowItem?.altroDest &&
      recipientEmails.push(rowItem.altroDest?.toLowerCase());
    administratorEmail && recipientEmails.push(administratorEmail);

    const object = rowItem?.oggetto;
    const data = buildJson(rowItem);

    if (!isEdit) {
      if (RowItem?.oggetto) {
        if (RowItem?.causale) {
          let res;
          if (ufficio === "EBINPROF") {
            res = await CorrispondenzaAPI.AddCorrispondenzaEbin(
              senderId,
              recipientEmails,
              object,
              data,
              files
            );
          } else {
            res = await CorrispondenzaAPI.AddCorrispondenza(
              senderId,
              recipientEmails,
              object,
              data,
              files
            );
          }
          if (res !== undefined && res.status === 200) {
            if (filter.toUpperCase() === "USCITA" && data.tipoinvio === 2) {
              props.addAlert.current.addAlert(
                "success",
                "Numero protocollo inserito: " + res?.data?.result[0]?.prot,
                86_400_000 // 24h.
              );
              await getCorrespondencesHandler();
              setCorrespondence(null);
              addCorrespondence();
            } else {
              if (filter.toUpperCase() === "ENTRATA") {
                const resLastProtocol = ufficio === "EBINPROF" ? 
                  await CorrispondenzaAPI.GetLastInsertedProtocolEbin(new Date().getFullYear(), filter) :
                  await CorrispondenzaAPI.GetLastInsertedProtocol(new Date().getFullYear(), filter);
                const protocol = resLastProtocol?.result[0];
                props.addAlert.current.addAlert(
                  "success",
                  `Numero protocollo inserito: ${protocol}`,
                  86_400_000 // 24h.
                );
                await getCorrespondencesHandler();
                setCorrespondence(null);
                addCorrespondence();
              } else {
                const ris = new Blob([res.data]);
                let url = window.URL.createObjectURL(ris);
                let a = document.createElement("a");
                a.href = url;
                a.download = "lettera.pdf";
                document.body.appendChild(a);
                a.click();
                a.remove();
                const resLastProtocol = ufficio === "EBINPROF" ? 
                  await CorrispondenzaAPI.GetLastInsertedProtocolEbin(new Date().getFullYear(), filter) :
                  await CorrispondenzaAPI.GetLastInsertedProtocol(new Date().getFullYear(), filter);
                const protocol = resLastProtocol?.result[0];
                props.addAlert.current.addAlert(
                  "success",
                  `Numero protocollo inserito: ${protocol}`,
                  86_400_000 // 24h.
                );
                await getCorrespondencesHandler();
                setCorrespondence(null);
                addCorrespondence();
              }
            }
          } else
            props.addAlert.current.addAlert(
              "danger",
              "Errore nell'inserimento"
            );
        } else
          props.addAlert.current.addAlert("warning", "Inserire la causale");
      } else props.addAlert.current.addAlert("warning", "Inserire l'oggetto");
    } else {
        const res = profile.ufficio === "EBINPROF" ?
          await CorrispondenzaAPI.UpdateCorrispondenzaEbin(null, null, null, data, files) : 
          await CorrispondenzaAPI.UpdateCorrispondenza(data, files);
        if (res) {
          props.addAlert.current.addAlert(
            "success",
            `Protocollo aggiornato correttamente`
          );
          await getCorrespondencesHandler();
          setCorrespondence(null);
          addCorrespondence();
        }
    }
    return true;
  }

  function protocolTypeHandler(value) {
    setProtocolType(value === 1 ? "Lettera" : "Email");
  }

  return (
    <Container>
      {headerComponent}
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            {tableHeaderComponent}
            <CardBody>
              <DataTable
                emptyMessage="Nessun Risultato"
                className="p-datatable-striped p-datatable-sm"
                value={correspondences}
                dataKey="id"
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                rows={rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                loading={isLoading}
                scrollable
                style={{ width: "100%" }}
                onSelectionChange={tableRowHandler}
                selectionMode="single"
              >
                <Column
                  header="Seleziona"
                  body={checkboxComponent}
                  headerStyle={{ width: "100px" }}
                  sortable={true}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />

                <Column
                  field="anno"
                  header="Anno"
                  headerStyle={{ width: "80px" }}
                  sortable={true}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  field="prot"
                  header="Prot."
                  headerStyle={{ width: "80px" }}
                  sortable={true}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  field="tipo"
                  header="Tipo"
                  headerStyle={{ width: "80px" }}
                  sortable={true}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  field="data"
                  header="Data"
                  body={(rowData) =>
                    helpers.DateTemplateWithHours(rowData, "data")
                  }
                  headerStyle={{ width: "80px" }}
                  sortable={true}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  field="causale.descrizione"
                  header="Causale"
                  headerStyle={{ width: "100px" }}
                  sortable={true}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  header="Allegati"
                  field="haAllegati"
                  style={{ width: "90px", textAlign: "center" }}
                  body={(row) => <>{row.haAllegati && <AttachFileIcon />}</>}
                />
                <Column
                  field="oggetto"
                  header="Oggetto"
                  headerStyle={{ width: "400px" }}
                  sortable={true}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
              </DataTable>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {correspondence && filter != "TUTTA" && (
        <Modifica
          {...props}
          administrator={administrator}
          administratorId={administratorId}
          type={filter}
          dataItem={correspondence}
          onAnnullaClick={cancelHandler}
          onSaveClick={addCorrespondenceHandler}
          onChangeTipoInvio={protocolTypeHandler}
        />
      )}
    </Container>
  );
};

export default Corrispondenza;
