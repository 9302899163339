import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import LoaderSpinner from "components/Layout/LoaderSpinner.js";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import CustomButtons from "components/CustomButtons/Button.js";
import { ModificaProtocollo as Modifica } from "components/Views/Protocollo/ModificaProtocollo.component";
import { Header } from "components/Views/Corrispondenza/Header.component";
import ButtonsMUI from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import helpers from "helpers/Helpers";
import AmministratoriAPI from "API/AmministratoriAPI";
import ConsulentiAPI from "API/ConsulentiAPI";
import DipendentiAPI from "API/DipendentiAPI";

const Options = [
  { id: 1, name: "Entrata" },
  { id: 2, name: "Uscita" },
  { id: 3, name: "Tutta" }
];

class CorrispondenzaXVersamenti extends React.Component {
  state = {
    rows: [],
    selectedCorrisp: null,
    selectedGrid: null,
    filterGrid: false,
    loadingGrid: false,
    visibleLoader: false,
    name: "",
    disabled: true,
    radioChecked: 1,
    attachments: [],
    selectedAttach: new Set(),
    firstRowToDisplay: 0,
    tipo: "",
    Anno: new Date().getFullYear(),
    HeaderDate: null,

    prot: "",
    headerDate: "",
    causale: "",
    sede: "",
    provSap: "",
    sapMatricola: "",
    daMatricola: "",
    aMatricola: "",

    initialRows: [],
    azienda: null,
    people: [],
    selectedTipo: 2,
    sortField: "prot",
    sortOrder: 1,
    currentPage: 0
  };

  constructor(props) {
    super(props);

    this.handleTableClick = this.handleTableClick.bind(this);
    this.templateCheckboxGrid = this.templateCheckboxGrid.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.RadioHandleChange = this.RadioHandleChange.bind(this);
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.HandleYearPickerChange = this.HandleYearPickerChange.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.LoadElementsInGrid = this.LoadElementsInGrid.bind(this);
    this.setSelectedTipo = this.setSelectedTipo.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.buildJson = this.buildJson.bind(this);
    this.headerRef = React.createRef();
  }

  setSelectedTipo(n) {
    this.setState({
      selectedTipo: parseInt(n)
    });
  }

  templateCheckboxGrid(rowData) {
    return (
      <div className="p-field-checkbox">
        <Checkbox
          inputId="binary"
          checked={rowData.pathattach}
          onChange={(e) => {
            e.checked
              ? this.state.selectedAttach.add(rowData.id)
              : this.state.selectedAttach.delete(rowData.id);
            var list = this.state.rows;
            var el = list.find((x) => x.id === rowData.id);
            el.pathattach = e.checked;
            this.setState({ rows: list, selectedCorrisp: null });
          }}
        />
      </div>
    );
  }

  async RadioHandleChange(e, val) {
    this.setState({
      [e]: val
    });

    const res = await this.filterMails(val.name);

    if (val !== null) {
      this.setState({
        radioChecked: val.id,
        name: val.name,
        tipo: val.name,
        rows: res?.array,
        totalRecords: res?.totalRecords
      });
    }
  }

  async filterMails(type) {
    const _date = this.state.data;
    const month = _date?.getMonth() + 1;
    const day = _date?.getDate();
    const _month = parseInt(month) > 10 ? month : `0${month}`;
    const _day = parseInt(day) > 10 ? day : `0${day}`;

    const date = `${_day}/${_month}/${_date?.getFullYear()}`;

    switch (type) {
      case "Entrata":
        const res = await CorrispondenzaAPI.GetCorrispondenzaBy(0, 10, {
          tipo: type,
          anno: _date ? null : this.state.Anno,
          prot: this.state.prot,
          data: _date ? date : null,
          causale: this.state.causale,
          sapFilter: this.state.sede,
          provsap: this.state.provSap,
          sap_matri: this.state.sapMatricola,
          fromMatr: this.state.daMatricola,
          toMatr: this.state.aMatricola
        });
        return { array: res?.result, totalRecords: res?.tot };
      case "Uscita":
        const res2 = await CorrispondenzaAPI.GetCorrispondenzaBy(0, 10, {
          tipo: type,
          anno: _date ? null : this.state.Anno,
          prot: this.state.prot,
          data: _date ? date : null,
          causale: this.state.causale,
          sapFilter: this.state.sede,
          provsap: this.state.provSap,
          sap_matri: this.state.sapMatricola,
          fromMatr: this.state.daMatricola,
          toMatr: this.state.aMatricola
        });
        return { array: res2?.result, totalRecords: res2?.tot };
      case "Tutta":
        const res3 = await CorrispondenzaAPI.GetCorrispondenzaBy(0, 10, {
          anno: _date ? null : this.state.Anno,
          prot: this.state.prot,
          data: _date ? date : null,
          causale: this.state.causale,
          sapFilter: this.state.sede,
          provsap: this.state.provSap,
          sap_matri: this.state.sapMatricola,
          fromMatr: this.state.daMatricola,
          toMatr: this.state.aMatricola
        });
        return { array: res3?.result, totalRecords: res3?.tot };
    }
  }

  async getPeople(aziendaId) {
    const adms = await AmministratoriAPI.GetAmministratoriForAziendaByAziendaId(
      aziendaId
    );
    const cons = await ConsulentiAPI.GetConsulentiForAziendaByAziendaId(
      aziendaId
    );
    const dips = await DipendentiAPI.GetDipendentiForAzienda(aziendaId);

    return [cons, dips, adms];
  }

  async onNuovoClick() {
    this.setState({
      visibleLoader: true,
      selectedCorrisp:null
    });
    setTimeout(() => {
      this.setState({
        selectedCorrisp: CorrispondenzaAPI.GetNuovoCorrispondenza(this.state.azienda?.matricola),
        selectedGrid: null,
        disabled: false
      });
      this.setState({
        visibleLoader: false
      });
    }, 200);
  }

  onAnnullaClick() {
    this.setState({
      selectedCorrisp: null,
      disabled: false
    });
    try {
      this.headerRef.current.scrollIntoView();
    } catch(err){}
  }

  onCloseGridClick() {
    this.setState({
      selectedGrid: null,
      selectedCorrisp: null
    });
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true
    });

    const _date = this.state.data;
    const month = _date?.getMonth() + 1;
    const day = _date?.getDate();
    const _month = parseInt(month) > 10 ? month : `0${month}`;
    const _day = parseInt(day) > 10 ? day : `0${day}`;

    const date = `${_day}/${_month}/${_date?.getFullYear()}`;

    const res = await CorrispondenzaAPI.GetCorrispondenzaBy(0, 10, {
      tipo: this.state.name,
      anno: _date ? null : this.state.Anno,
      prot: this.state.prot,
      data: _date ? date : null,
      causale: this.state.causale,
      sapFilter: this.state.sede,
      provsap: this.state.provSap,
      sap_matri: this.state.sapMatricola,
      fromMatr: this.state.daMatricola,
      toMatr: this.state.aMatricola,
      mitt: this.state.mittente
    });

    this.setState({
      rows: res.result,
      totalRecords: res?.tot
    });
    this.setState({
      loadingGrid: false
    });
  }

  async ChangeFilterGrid(e) {
    this.setState({
      filterGrid: e.target.checked
    });
  }

  handleTableClick = async (e) => {
    const attachs = await CorrispondenzaAPI.GetCorrispondenzaAttachments(
      e.value.id
    );
    try {
      this.setState({
        attachments: attachs.result,
        selectedCorrisp: e.value,
        selectedGrid: e.value,
        disabled: true
      });
    } catch (err) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Errore nel recupero degli allegati"
      );
    }
  };

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });
    try {
      let rw = await CorrispondenzaAPI.GetCorrispondenzaBy(0, 10, {
        tipo: "Entrata",
        sort: this.state.sortField,
        order: this.state.sortOrder === 1 ? "DESC" : "ASC",
        anno: new Date().getFullYear()
      });

      this.setState({
        rows: rw.result,
        initialRows: rw,
        totalRecords: rw?.tot
      });
    } catch (err) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Si è verificato un errore"
      );
    }
    this.setState({
      name: "Entrata",
      loadingGrid: false
    });
  }

  downloadFiles() {
    if (this.state.selectedCorrisp?.id) {
      const response = CorrispondenzaAPI.GetExportedFiles(
        [this.state.selectedCorrisp.id],
        "Corrispondenza Protocollo"
      );
      response.then({});
    } else
      this.props.addAlert.current.addAlert(
        "warning",
        "Selezionare una corrispondenza prima"
      );
  }

  buildJson = (rowItem) => {
    return {
      utente: rowItem?.user?.username,
      azienda: rowItem?.azienda,
      codsap: rowItem?.codsap,
      tipo: rowItem?.protocolType,
      prot: rowItem?.prot,
      anno: new Date().getFullYear(),
      data: rowItem?.data,
      causale: rowItem?.causale,
      note: rowItem?.note,
      dtlett: rowItem?.dtlett,
      mitt_dest: rowItem?.mitt_dest,
      oggetto: rowItem?.oggetto,
      mailtext: rowItem?.mailtext || "",
      id: rowItem?.id,
      prov: rowItem?.prov || rowItem?.company?.prov || null,
      numtab: isNaN(parseInt(rowItem?.numtab))
        ? null
        : parseInt(rowItem?.numtab),
      numfat: isNaN(parseInt(rowItem?.numfat))
        ? null
        : parseInt(rowItem?.numfat),
      numpreav: isNaN(parseInt(rowItem?.numpreav))
        ? null
        : parseInt(rowItem?.numpreav),
      w350: rowItem?.w350,
      w300: rowItem?.w300,
      amm: rowItem?.amm,
      ncontabbanc: isNaN(parseInt(rowItem?.ncontabbanc))
        ? null
        : parseInt(rowItem?.ncontabbanc),
      prbuchicont: rowItem?.prbuchicont,
      pathattach: rowItem?.pathattach,
      fldoppi: rowItem?.fldoppi,
      sede_inps: rowItem?.sede_inps,
      ninvio: rowItem?.ninvio,
      flamm: rowItem?.flamm,
      flaz: rowItem?.flaz,
      flcons: rowItem?.flcons,
      fldip: rowItem?.fldip,
      fldocele: rowItem?.fldocele,
      flmodo: rowItem?.flmodo,
      puli: rowItem?.puli,
      cust: rowItem?.cust,
      tipoinvio: rowItem?.tipoinvio,
      mittmail: rowItem?.mittmail?.toLowerCase(),
      destmail: rowItem?.destmail?.toLowerCase(),
      cfcons: rowItem?.cfcons,
      cfamm: rowItem?.cfamm,
      cfdip: rowItem?.cfdip,
      haAllegati: null,
      testomail: rowItem?.testomail || ""
    };
  };

  HandleSaveClick = async (RowItem, files, isEdit) => {
    const rowItem = RowItem;
    const senderId = rowItem?.user?.codice;
    let recipientEmails = [];
    rowItem?.administratorEmail &&
      recipientEmails.push(rowItem?.administratorEmail);
    rowItem?.consultantEmail && recipientEmails.push(rowItem?.consultantEmail);
    rowItem?.employeeEmail && recipientEmails.push(rowItem?.employeeEmail);
    rowItem?.sendCompanyEmail && recipientEmails.push(rowItem?.company?.email);
    rowItem?.recipientEmail && recipientEmails.push(rowItem?.recipientEmail);
    rowItem?.altroDest && recipientEmails.push(rowItem.altroDest);
    const object = rowItem?.oggetto;
    const data = this.buildJson(rowItem);

    if (!isEdit) {
      if (RowItem?.oggetto) {
        if (RowItem?.causale) {
          const res = await CorrispondenzaAPI.AddCorrispondenza(
            senderId,
            recipientEmails,
            object,
            data,
            files
          );
          if (res !== undefined && res.status === 200) {
            if (this.state.selectedTipo === 2) {
              this.props.addAlert.current.addAlert(
                "success",
                "Numero protocollo inserito: " + res?.data?.result[0]?.prot,
                86_400_000 // 24h.
              );
              this.setState({
                selectedCorrisp: null,
                selectedGrid: null
              });
              this.onNuovoClick();
            } else {
              if (data.tipo?.toUpperCase() === "ENTRATA") {
                const resLastProtocol =
                  await CorrispondenzaAPI.GetLastInsertedProtocol(
                    data?.anno,
                    data?.tipo
                  );
                const protocol = resLastProtocol?.result[0];
                this.props.addAlert.current.addAlert(
                  "success",
                  `Numero protocollo inserito: ${protocol}`,
                  86_400_000 // 24h.
                );
                this.setState({
                  selectedCorrisp: null,
                  selectedGrid: null
                });
                this.onNuovoClick();
              } else {
                const ris = new Blob([res.data]);
                let url = window.URL.createObjectURL(ris);
                let a = document.createElement("a");
                a.href = url;
                a.download = "lettera.pdf";
                a.click();
                a.remove();
                const resLastProtocol =
                  await CorrispondenzaAPI.GetLastInsertedProtocol(
                    data?.anno,
                    data?.tipo
                  );
                const protocol = resLastProtocol?.result[0];
                this.props.addAlert.current.addAlert(
                  "success",
                  `Numero protocollo inserito: ${protocol}`,
                  86_400_000 // 24h.
                );
                this.setState({
                  selectedCorrisp: null,
                  selectedGrid: null
                });
                this.onNuovoClick();
              }
            }
          } else
            this.props.addAlert.current.addAlert(
              "danger",
              "Errore nell'inserimento"
            );
        } else
          this.props.addAlert.current.addAlert(
            "warning",
            "Inserire la causale"
          );
      } else
        this.props.addAlert.current.addAlert("warning", "Inserire l'oggetto");
    } else {
        const res = await CorrispondenzaAPI.UpdateCorrispondenza(data, files);
        if (res) {
          this.props.addAlert.current.addAlert(
            "success",
            `Protocollo aggiornato correttamente`
          );
          this.setState({
            selectedCorrisp: null,
            selectedGrid: null
          });
          this.onNuovoClick();
        }
    }
    this.refreshGrid();
    return true;
  };

  handleClearClick() {
    this.setState({
      loadingGrid: true
    });
    var initialRows = this.state.initialRows;
    this.setState({
      Anno: new Date().getFullYear(),
      HeaderDate: null,
      rows: initialRows
    });
    this.setState({
      loadingGrid: false
    });
  }

  async handleSearchClick(
    tipo,
    anno,
    data,
    prot,
    causale,
    sede,
    provSap,
    sapMatricola,
    daMatricola,
    aMatricola,
    mittente
  ) {
    this.setState(
      {
        tipo: tipo,
        anno: anno,
        data: data,
        prot: prot,
        causale: causale,
        sede: sede,
        provSap: provSap,
        sapMatricola: sapMatricola,
        daMatricola: daMatricola,
        aMatricola: aMatricola,
        mittente: mittente
      },
      async () => await this.refreshGrid()
    );
  }

  HandleYearPickerChange(e) {
    this.setState({
      Anno: e
    });
  }

  async LoadElementsInGrid(newPage = 0, sortField, sortOrder) {
    let page = newPage;
    if (page === undefined || page === null) page = this.state.lastPage;

    const _date = this.state.data;
    const month = _date?.getMonth() + 1;
    const day = _date?.getDate();
    const _month = parseInt(month) > 10 ? month : `0${month}`;
    const _day = parseInt(day) > 10 ? day : `0${day}`;

    const date = `${_day}/${_month}/${_date?.getFullYear()}`;

    helpers.SaveLastPage(page, "ProtocolliListPage");
    var responseObject = await CorrispondenzaAPI.GetCorrispondenzaBy(
      page * 10,
      10,
      {
        tipo: this.state.name,
        anno: _date ? null : this.state.Anno,
        prot: this.state.prot,
        data: _date ? date : null,
        causale: this.state.causale,
        sapFilter: this.state.sede,
        provsap: this.state.provSap,
        sap_matri: this.state.sapMatricola,
        fromMatr: this.state.daMatricola,
        toMatr: this.state.aMatricola,
        sort: sortField,
        order: sortOrder === 1 ? "DESC" : "ASC",
        mitt: this.state.mittente
      }
    );

    this.setState({
      rows: responseObject.result,
      totalRecords: responseObject.tot,
      loadingGrid: false
    });

    if (newPage)
      this.setState({
        lastPage: newPage
      });
  }

  async onPageChange(e) {
    this.setState({
      currentPage: e.page,
      firstRowToDisplay: e.first,
      loadingGrid: true
    });
    await this.LoadElementsInGrid(
      e.page,
      this.state.sortField,
      this.state.sortOrder
    );
  }

  async onSortChange(e) {
    this.setState(
      {
        sortField: e.sortField,
        sortOrder: e.sortOrder,
        loadingGrid: true
      },
      async () => {
        await this.LoadElementsInGrid(
          this.state.currentPage,
          e.sortField,
          e.sortOrder
        );
      }
    );
  }

  render() {
    return (
      <>
        <div ref={this.headerRef} />
        <Header
          {...this.props}
          disabledRadioButtons={this.state.disabled}
          Anno={this.state.Anno}
          RadioHandleChange={this.RadioHandleChange}
          OnSearch={this.handleSearchClick}
          HandleYearPickerChange={this.HandleYearPickerChange}
          HeaderDate={this.state.HeaderDate}
          OnClear={this.handleClearClick}
          HandleChange={this.HandleChange}
          radioChecked={this.state.radioChecked}
          visibleBack={true}
          lineHeight="0"
          Options={Options}
        />
        {this.state.visibleLoader && <LoaderSpinner fullHeight={true} />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  {this.state.name}{" "}
                </h4>
                {this.state.radioChecked !== 3 && (
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Inserisci"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <CustomButtons
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.onNuovoClick}
                    >
                      <AddIcon />
                    </CustomButtons>
                  </Tooltip>
                )}
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  size="small"
                  aria-label="contained primary button group"
                  className="float-right"
                  style={{ verticalAlign: "center", margin: "0.5%" }}
                >
                  <ButtonsMUI onClick={this.downloadFiles}>
                    Esporta files
                  </ButtonsMUI>
                </ButtonGroup>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  first={this.state.firstRowToDisplay}
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10}
                  onPage={this.onPageChange}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedCorrisp}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  lazy
                  onSort={this.onSortChange}
                  sortField={this.state.sortField}
                  sortOrder={this.state.sortOrder}
                  totalRecords={this.state.totalRecords}
                  selectionMode="single"
                >
                  <Column
                    header="Seleziona"
                    body={this.templateCheckboxGrid}
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="anno"
                    header="Anno"
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="prot"
                    header="Prot"
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="tipo"
                    header="Tipo"
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="azienda"
                    header="Matricola"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="mitt_dest"
                    header="Mittente"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data"
                    header="Data"
                    body={(rowData) =>
                      helpers.DateTemplateWithHours(rowData, "data")
                    }
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="causale.codice"
                    header="Causale"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    header="Allegati"
                    field="haAllegati"
                    style={{ width: "90px", textAlign: "center" }}
                    body={(row) => <>{row.haAllegati && <AttachFileIcon />}</>}
                  />
                  <Column
                    field="oggetto"
                    header="Oggetto"
                    headerStyle={{ width: "400px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.selectedCorrisp && this.state.radioChecked !== 3 && (
          <Modifica
            {...this.props}
            type={
              this.state.radioChecked === 1
                ? "Entrata"
                : this.state.radioChecked === 2 && "Uscita"
            }
            dataItem={this.state.selectedCorrisp}
            onAnnullaClick={this.onAnnullaClick}
            onSaveClick={this.HandleSaveClick}
            onChangeTipoInvio={this.setSelectedTipo}
            disable={this.state.disabled}
            azienda={this.state.azienda}
            people={{
              cons: this.state.people[0],
              dips: this.state.people[1],
              adms: this.state.people[2]
            }}
            HeaderDate={this.state.HeaderDate}
          />
        )}
      </>
    );
  }
}

export default CorrispondenzaXVersamenti;
