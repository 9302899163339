import React from "react";
import clone from "clone";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import MenuItem from "@material-ui/core/MenuItem";
import { Dropdown } from "primereact/dropdown";
import LoaderSpinner from "../../Layout/LoaderSpinner.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import ButtonsMUI from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import UndoIcon from "@material-ui/icons/Undo";
import Tooltip from "@material-ui/core/Tooltip";
import { Calendar } from "primereact/calendar";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import ExtraFieldsModifica from "components/Views/Aziende/ExtraFieldsModifica.js";
import helpers from "helpers/Helpers";
import AziendeAPI from "API/AziendeAPI";
import SedeComuneProvAPI from "API/SedeComuneProvAPI";
import { dateTimeFmt } from "helpers/AppConstants.js";
import { dateFmt } from "helpers/AppConstants.js";
import moment from "moment";
import { AuthContext } from "providers/auth.provider.js";
import UtentiAPI from "API/Utenti.api.js";

export default class ModificaAzienda extends React.Component {
  static contextType = AuthContext;
  state = {
    RowItem: null,
    ModificaTitle: "Nuova",
    groupClass: "",
    visibleHeader: "",
    SedeInpsList: [],
    TipoAzienda: [],
    TipoCess: [],
    selectedCess: null,
    sede: null,
    selectedTipoAzienda: null,
    disabledFields: false,
    dataConstituz: "",

    selectedComune: null,
    notFilteredComune: [],
    filteredComune: [],

    convertedDataConst: new Date(),
    convertedDataCessaz: new Date(),
    test: new Date(),
    //VALIDATIONS
    fieldValidations: {},
    validationRules: {
      matricola: "EQ-10",
      denominazione: "",
      iban: "EQ-27-optional",
      codice_fiscale: "GTEQ-11-optional",
      partita_iva: "EQ-11-optional"
    },
    ProvinciaList: [],
    selectedProvincia: null,
    isValid: true
  };

  constructor(props) {
    super(props);

    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.SetText = this.SetText.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.OnRedirectClick = this.OnRedirectClick.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.HandleArrayDatesChange = this.HandleArrayDatesChange.bind(this);
    this.OnInputBlur = this.OnInputBlur.bind(this);
    this.OnInputBlurCheck = this.OnInputBlurCheck.bind(this);

    this.monthNavigatorTemplate = this.monthNavigatorTemplate.bind(this);
    this.yearNavigatorTemplate = this.yearNavigatorTemplate.bind(this);

    this.changeRef = React.createRef();
  }

  monthNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }

  yearNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }

  SetText(field, newValue) {
    if (field === "partita_iva" && newValue === "") {
      newValue = null;
    }
    var newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
  }

  SetNum(field, newValue, maxLength) {
    if (field === "partita_iva" && newValue === "") {
      newValue = null;
    }
    let test = newValue.toString();

    if (test.length <= maxLength) {
      var newRowItem = this.state.RowItem;
      newRowItem[field] = newValue;

      this.setState({ RowItem: newRowItem });
    }
  }

  async OnInputBlur(newCAP) {
    let rowItem = this.state.RowItem;
    let selectedComune = this.state.notFilteredComune.filter(
      (row) => row.cap === newCAP
    )[0];
    if (selectedComune !== null) {
      try {
        let comuneFiltered = this.state.notFilteredComune.filter(
          (row) => row.prov.sigla === selectedComune.prov.sigla
        );
        let selectedProvincia = this.state.ProvinciaList.filter(
          (row) => row.sigla === selectedComune.prov.sigla
        )[0];

        rowItem["comune"] = selectedComune.nome;
        rowItem["prov"] = selectedProvincia.sigla;

        this.setState({
          RowItem: rowItem,
          filteredComune: comuneFiltered,
          selectedComune: selectedComune.nome,
          selectedProvincia: selectedProvincia.sigla
        });
      } catch (e) {
        //CAP NON PRESENTE SUL DB
      }
    } else {
      const comuneCandidates = await SedeComuneProvAPI.GetComuneByCap(newCAP);
      if (comuneCandidates?.length === 1) {
        rowItem["comune"] = comuneCandidates[0].nome;
        rowItem["prov"] = comuneCandidates[0].prov;
      } else {
        rowItem["comune"] = null;
        rowItem["prov"] = null;
      }

      this.setState({
        RowItem: rowItem,
        filteredComune: comuneCandidates,
        selectedComune: rowItem["comune"],
        selectedProvincia: rowItem["prov"]
      });
    }
  }

  async OnInputBlurCheck(valToCheck, type) {
    //let validationRules = {};

    let fieldValidations = helpers.ValidateForm(
      this.state.RowItem,
      this.state.validationRules
    );

    Object.values(fieldValidations).every((x) => x === "success");

    this.setState({
      isValid: Object.values(fieldValidations).every((x) => x === "success")
    });

    this.setState({
      fieldValidations: fieldValidations
    });

    let existentMatricola = [];
    if (
      this.state.RowItem.matricola?.length > 0 &&
      this.props.dataItem.id === null &&
      type === "matricola"
    ) {
      existentMatricola = await AziendeAPI.GetAllMatricola(
        this.state.RowItem.matricola
      );
      if (existentMatricola.length > 0) {
        this.props.addAlert.current.addAlert("warning", "Matricola esistente");
        this.setState({
          RowItem: existentMatricola[0]
        });
      }
    }
  }

  HandleChange(field, newValue) {
    var newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
  }

  HandleDropDownChange(e, val, propName) {
    var rowItem = this.state.RowItem;

    if (e === "selectedTipoAzienda" || e === "sede" || e === "selectedComune") {
      rowItem[propName] = val;
    } else if (e === "selectedProvincia") {
      rowItem[propName] = val;
      var sigla = helpers.ReturnIdFromListByType(
        this.state.ProvinciaList,
        "ProvinciaSigla",
        val
      );
      var _comunebyprov = helpers.ReturnIdFromListByType(
        this.state.notFilteredComune,
        "ComuneByProvList",
        sigla
      );

      this.setState({
        filteredComune: _comunebyprov,
        selectedComune: null
      });
    } else if (e === "selectedCess") {
      rowItem[propName] = val;
    } else rowItem[propName] = val.id;

    this.setState({
      [e]: val,
      RowItem: rowItem
    });
  }

  HandleArrayDatesChange(e, val, propName) {
    var newRowItem = this.state.RowItem;
    newRowItem[propName] = helpers.ConvertDateAsArray(val);

    this.setState({
      [e]: val,
      RowItem: newRowItem
    });
  }

  OnRedirectClick(param) {
    let id = this.props.dataItem.id || this.state.RowItem.id;
    if (param === "dipe")
      this.props.history.push("/admin/DipendentiXAzienda/" + id);
    else if (param === "anom")
      this.props.history.push("/admin/AnomalieXAzienda/" + id);
    else if (param === "stor")
      this.props.history.push("/admin/StoricoXAzienda/" + id);
    else if (param === "cons")
      this.props.history.push("/admin/ConsulentiXAzienda/" + id);
    else if (param === "vers")
      this.props.history.push("/admin/VersamentiXAzienda/" + id);
    else if (param === "congr")
      this.props.history.push("/admin/CongruitaVersamenti/" + id);
    else if (param === "ammi")
      this.props.history.push("/admin/AmministratoriXAzienda/" + id);
    else if (param === "unie")
      this.props.history.push("/admin/UniemensXAzienda/" + id);
    else if (param === "corri")
      this.props.history.push("/admin/Corrispondenza/" + id);
  }

  async isValidForm(showAlert = false) {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);

    let isValid = Object.values(fieldValidations).every((x) => x === "success");

    this.setState({
      fieldValidations: fieldValidations
    });

    let existentMatricola = [];
    if (
      this.state.RowItem.matricola?.length > 0 &&
      this.props.dataItem.id === null
    ) {
      existentMatricola = await AziendeAPI.GetAllMatricola(
        this.state.RowItem.matricola
      );
      if (existentMatricola.length > 0 && showAlert) {
        this.props.addAlert.current.addAlert("warning", "Matricola esistente");
      }
    }

    return isValid && existentMatricola.length === 0;
  }

  HandleSaveClick = async () => {
    const { profile } = this.context;

    const { username } = profile;

    const res = await UtentiAPI.getUserByUsername(username);

    const user = res[0].utente;
    let rowItem = this.state.RowItem;
    rowItem.utente = user;

    this.setState({
      RowItem: rowItem,
      selectedCess: this.state.selectedCess - 1 //PERCHE' PRIMA VIENE INCREMENTATO DI UNO
    });
    if (await this.isValidForm()) {
      var sd = await this.props.onSaveClick(this.state.RowItem);
      this.setState({
        selectedCess: this.state.selectedCess + 1 //DOPO IL SALVATAGGIO BISOGNA INCREMENTARLO
      });
      return sd;
    } else return;
  };

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.dataItem !== null) {
      if (this.props.dataItem.id !== prevProps.dataItem.id) {
        let selectedProvincia = this.state.ProvinciaList.filter(
          (row) => row.sigla === this.props.dataItem.prov
        )[0]?.sigla;

        let comuneFiltered = [];
        if (selectedProvincia)
          comuneFiltered = await SedeComuneProvAPI.GetComuneByProv(
            this.props.dataItem.prov
          );

        this.setState({
          RowItem: this.props.dataItem,

          sede:
            this.props.dataItem.id > 0 &&
            this.props.dataItem.sede !== null &&
            this.props.dataItem.sede.length > 0
              ? helpers.ReturnIdFromListByType(
                  this.state.SedeInpsList,
                  "Sede",
                  this.props.dataItem.sede
                )
              : null,

          selectedCess: helpers.ReturnIdFromListByType(
            this.state.TipoCess,
            "TipoCess",
            this.props.dataItem.tipo_cessazione
          ),

          filteredComune: comuneFiltered,
          selectedProvincia: selectedProvincia, // helpers.ReturnIdFromListByType(this.state.ProvinciaList, "Provincia", this.props.dataItem.prov),//((this.props.dataItem.id > 0) && (this.props.dataItem.prov !== null) && (this.props.dataItem.prov.length > 0)) ? helpers.ReturnIdFromListByType(this.state.ProvinciaList, "Provincia", this.props.dataItem.prov) : null,
          selectedComune: this.props.dataItem.comune, // helpers.ReturnIdFromListByType(this.state.filteredComune, "Comune", this.props.dataItem.comune),

          selectedTipoAzienda: helpers.ReturnIdFromListByType(
            this.state.TipoAzienda,
            "TipoAzienda",
            this.props.dataItem.tipo_azienda
          ),
          convertedDataCessaz:
            this.props.dataItem.id > 0
              ? helpers.GetDateObjectFromStringDate(
                  this.props.dataItem.data_cessazione
                )
              : null,
          convertedDataConst:
            this.props.dataItem.id > 0
              ? helpers.GetDateObjectFromStringDate(
                  this.props.dataItem.data_costituzione
                )
              : null,

          ModificaTitle: this.props.dataItem.id > 0 ? "Modifica" : "Nuova",
          disabledFields: this.props.dataItem.id > 0 ? true : false,
          groupClass: this.props.dataItem.id > 0 ? "" : "hide"
        });
      }
    }
  }

  async componentDidMount() {
    const allProvincie = await SedeComuneProvAPI.GetAllProvincieProvincie();
    const allSedi = await SedeComuneProvAPI.GetAllSede();
    if (this.props.dataItem !== null && this.props.dataItem.id > 0) {
      const allComune = await SedeComuneProvAPI.GetAllComune();
      let listCess = await AziendeAPI.GetAllTipoCessAziende();
      listCess = listCess.map((x) => {
        return {
          codice: x.codice + 1,
          note: x.note,
          descrizione: x.descrizione
        };
      });
      this.setState({
        RowItem: this.props.dataItem,
        ModificaTitle: "Modifica",
        groupClass: "",
        visibleHeader: this.props.hideHeader ? "" : "hide",
        disabledFields: true,
        convertedDataCessaz: helpers.GetDateObjectFromStringDate(
          this.props.dataItem.data_cessazione
        ),
        convertedDataConst: helpers.GetDateObjectFromStringDate(
          this.props.dataItem.data_costituzione
        ),
        SedeInpsList: allSedi.map((x) => {
          return { sap: x.sap, descrizione: x.sap + " - " + x.descrizione };
        }),
        TipoAzienda: await AziendeAPI.GetAllTipoAziende(),
        TipoCess: listCess,
        ProvinciaList: allProvincie,
        filteredComune: await SedeComuneProvAPI.GetComuneByProv(
          this.props.dataItem.prov
        ),
        notFilteredComune: allComune
      });

      let selectedProvincia = this.props.dataItem.prov
        ? this.props.dataItem.prov
        : "";
      let comuneFiltered = [];
      if (selectedProvincia)
        comuneFiltered = allComune.filter(
          (row) => row.prov.sigla === selectedProvincia
        );

      this.setState({
        sede:
          this.props.dataItem.sede !== null &&
          this.props.dataItem.sede.length > 0
            ? helpers.ReturnIdFromListByType(
                this.state.SedeInpsList,
                "Sede",
                this.props.dataItem.sede
              )
            : null,

        filteredComune: comuneFiltered,
        selectedComune: this.props.dataItem.comune, //helpers.ReturnIdFromListByType(this.state.filteredComune, "Comune", this.props.dataItem.comune),
        selectedProvincia: selectedProvincia, //((this.props.dataItem.id > 0) && (this.props.dataItem.prov !== null) && (this.props.dataItem.prov.length > 0)) ? helpers.ReturnIdFromListByType(this.state.ProvinciaList, "Provincia", this.props.dataItem.prov) : null,
        selectedCap: this.props.dataItem.cap,
        selectedCess: helpers.ReturnIdFromListByType(
          this.state.TipoCess,
          "TipoCess",
          this.props.dataItem.tipo_cessazione
        ),

        selectedTipoAzienda: helpers.ReturnIdFromListByType(
          this.state.TipoAzienda,
          "TipoAzienda",
          this.props.dataItem.tipo_azienda
        )
      });
    } else {
      var allComune = await SedeComuneProvAPI.GetAllComune();
      let listCess = await AziendeAPI.GetAllTipoCessAziende();
      listCess = listCess.map((x) => {
        return {
          codice: x.codice + 1,
          note: x.note,
          descrizione: x.descrizione
        };
      });

      this.setState({
        RowItem: this.props.dataItem || {},
        ModificaTitle: "Nuova",
        groupClass: "hide",
        visibleHeader: this.props.hideHeader ? "" : "hide",
        disabledFields: false,

        convertedDataCessaz: null,
        convertedDataConst: null,
        SedeInpsList: allSedi.map((x) => {
          return { sap: x.sap, descrizione: x.sap + " - " + x.descrizione };
        }),
        TipoAzienda: await AziendeAPI.GetAllTipoAziende(),
        TipoCess: listCess,
        ProvinciaList: allProvincie,
        filteredComune: allComune,
        notFilteredComune: allComune
      });
    }
    try {
      this.changeRef.current.scrollIntoView();
    } catch(err){}
  }

  render() {
    if (this.state.RowItem === null)
      return (
        <>
          <LoaderSpinner fullHeight={false} />
        </>
      );
    else
      return (
        <div ref={this.changeRef}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                {this.state.visibleHeader && (
                  <CardHeader color="info">
                    <h4
                      style={{
                        lineHeight: "0",
                        display: "inline",
                        verticalAlign: "bottom"
                      }}
                    >
                      {" "}
                      {this.state.ModificaTitle} Azienda
                    </h4>

                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Annulla"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <Button
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%",
                          marginLeft: "0",
                          marginRight: "0"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="float-right"
                        onClick={this.props.onAnnullaClick}
                      >
                        <UndoIcon />
                      </Button>
                    </Tooltip>

                    {this.state.RowItem.id && (
                      <ButtonGroup
                        variant="contained"
                        color="primary"
                        size="small"
                        aria-label="contained primary button group"
                        className={"float-right " + this.state.groupClass}
                        style={{
                          verticalAlign: "center",
                          margin: "0.5%"
                        }}
                      >
                        <ButtonsMUI
                          onClick={() => this.OnRedirectClick("ammi")}
                        >
                          Amministr.
                        </ButtonsMUI>
                        <ButtonsMUI
                          onClick={() => this.OnRedirectClick("cons")}
                        >
                          Consulenti
                        </ButtonsMUI>
                        <ButtonsMUI
                          onClick={() => this.OnRedirectClick("dipe")}
                        >
                          Dipendenti
                        </ButtonsMUI>
                        <ButtonsMUI
                          onClick={() => this.OnRedirectClick("stor")}
                        >
                          Storico Az.
                        </ButtonsMUI>
                        <ButtonsMUI
                          onClick={() => this.OnRedirectClick("vers")}
                        >
                          Versamenti
                        </ButtonsMUI>
                        <ButtonsMUI
                          onClick={() => this.OnRedirectClick("unie")}
                        >
                          UNIEMENS
                        </ButtonsMUI>
                        <ButtonsMUI
                          onClick={() => this.OnRedirectClick("congr")}
                        >
                          Congr.Vers.
                        </ButtonsMUI>
                        <ButtonsMUI
                          onClick={() => this.OnRedirectClick("corri")}
                        >
                          {" "}
                          Corrisp.
                        </ButtonsMUI>
                        <ButtonsMUI
                          onClick={() => this.OnRedirectClick("anom")}
                        >
                          Anomalie
                        </ButtonsMUI>
                      </ButtonGroup>
                    )}
                  </CardHeader>
                )}
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Id"
                        id="id-disabled"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.id || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Ultimo Aggiornamento"
                        id="datault"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.dataultimoagg
                            ? moment(
                                this.state.RowItem.dataultimoagg,
                                dateTimeFmt
                              ).format(dateFmt)
                            : ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Tipo Ultimo Aggiornamento"
                        id="aggiorolddb"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.tipoultimoagg || "",
                          maxLength: 12
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Utente Ultimo Aggiornamento"
                        id="aggiorsistema"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.utente || "",
                          maxLength: 30
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.matricola &&
                          this.state.fieldValidations.matricola === "success"
                        }
                        error={
                          this.state.fieldValidations.matricola &&
                          this.state.fieldValidations.matricola === "error"
                        }
                        labelText="Matricola *"
                        id="matricola"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          className: "matricolaAutocomplete",
                          type: "number",
                          disabled: this.state.disabledFields,
                          onChange: (event) =>
                            this.SetNum("matricola", event.target.value, 10),
                          onBlur: (event) =>
                            this.OnInputBlurCheck(
                              event.target.value,
                              "matricola"
                            ),
                          value: this.state.RowItem.matricola || "",
                          maxLength: 10
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={9}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.denominazione ===
                          "success"
                        }
                        error={
                          this.state.fieldValidations.denominazione === "error"
                        }
                        labelText="Ragione Sociale *"
                        id="ragionesociale"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("denominazione", event.target.value),
                          onBlur: (event) =>
                            this.OnInputBlurCheck(
                              event.target.value,
                              "denominazione"
                            ),
                          value: this.state.RowItem.denominazione || "",
                          maxLength: 255
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Indirizzo "
                        id="indirizzo"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("indirizzo", event.target.value),
                          value: this.state.RowItem.indirizzo || "",
                          maxLength: 255
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="CAP"
                        id="cap"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("cap", event.target.value),
                          onBlur: (event) =>
                            this.OnInputBlur(event.target.value),
                          value: this.state.RowItem.cap || "",
                          maxLength: 5
                        }}
                      />
                    </GridItem>


                    <GridItem
                      xs={12}
                      sm={12}
                      md={4}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="lblNoMargin label">
                        <small>Comune</small>
                      </p>
                      <Dropdown
                        value={this.state.selectedComune}
                        className="dropdownCustomisedForMUI"
                        options={helpers.comuniView(this.state.filteredComune)}
                        resetFilterOnHide
                        onChange={(e) =>
                          this.HandleDropDownChange(
                            "selectedComune",
                            e.target.value,
                            "comune"
                          )
                        }
                        filter
                      />{" "}
                    </GridItem>
                    
                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="lblNoMargin label">
                        <small>Provincia</small>
                      </p>
                      <Dropdown
                        value={this.state.selectedProvincia}
                        optionValue="sigla"
                        className="dropdownCustomisedForMUI"
                        options={this.state.ProvinciaList}
                        resetFilterOnHide
                        onChange={(e) =>
                          this.HandleDropDownChange(
                            "selectedProvincia",
                            e.target.value,
                            "prov"
                          )
                        }
                        optionLabel="sigla"
                        filter
                        filterBy="sigla"
                      />{" "}
                    </GridItem>

                  </GridContainer>

                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      style={{ marginTop: "4px" }}
                    >
                      <p
                        className="calendarParagraph"
                        style={{
                          color:
                            this.state.fieldValidations["sede"] === "error"
                              ? "#f44336"
                              : "#565656"
                        }}
                      >
                        <small>Sede INPS</small>
                      </p>
                      <Dropdown
                        id="sede"
                        success={
                          this.state.fieldValidations.sede &&
                          this.state.fieldValidations.sede === "success"
                        }
                        error={
                          this.state.fieldValidations.sede &&
                          this.state.fieldValidations.sede === "error"
                        }
                        value={this.state.RowItem.sede}
                        // success={this.state.fieldValidations.sede === "success"}
                        // error={this.state.fieldValidations.sede === "error"}
                        optionValue="sap"
                        className="dropdownCustomisedForMUI"
                        resetFilterOnHide
                        options={this.state.SedeInpsList}
                        onChange={(e) =>
                          this.HandleDropDownChange(
                            "sede",
                            e.target.value,
                            "sede"
                          )
                        }
                        onBlur={(event) =>
                          this.OnInputBlurCheck(event.target.value, "sede")
                        }
                        optionLabel="descrizione"
                        filter
                        filterBy="descrizione"
                        //itemTemplate={SedeTemplates.sedeOptionTemplate}
                        //valueTemplate={SedeTemplates.sedeSelectedTemplate}
                      />{" "}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Classificazione"
                        id="classificazione"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("classificazione", event.target.value),
                          value: this.state.RowItem.classificazione || "",
                          maxLength: 255
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="PV PULI"
                        id="pvpuli"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          onChange: (event) =>
                            this.SetText("primovers", event.target.value),
                          value: this.state.RowItem.primovers || "",
                          maxLength: 7
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="UV PULI"
                        id="uvpuli"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          onChange: (event) =>
                            this.SetText("ultimovers", event.target.value),
                          value: this.state.RowItem.ultimovers || "",
                          maxLength: 7
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="PV CUST"
                        id="pvcust"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          onChange: (event) =>
                            this.SetText("primoversw350", event.target.value),
                          value: this.state.RowItem.primoversw350 || "",
                          maxLength: 7
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="UV CUST"
                        id="uvcust"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          onChange: (event) =>
                            this.SetText("ultimoversw350", event.target.value),
                          value: this.state.RowItem.ultimoversw350 || "",
                          maxLength: 7
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.codice_fiscale &&
                          this.state.fieldValidations.codice_fiscale ===
                            "success"
                        }
                        error={
                          this.state.fieldValidations.codice_fiscale &&
                          this.state.fieldValidations.codice_fiscale === "error"
                        }
                        helperText={
                            this.state.fieldValidations.codice_fiscale === "error" &&
                            "la lunghezza deve essere almeno di 11 caratteri"
                        }
                        labelText="Codice Fiscale"
                        id="cf"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("codice_fiscale", event.target.value),
                          value: this.state.RowItem.codice_fiscale || "",
                          maxLength: 16
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.partita_iva &&
                          this.state.fieldValidations.partita_iva === "success"
                        }
                        error={
                          this.state.fieldValidations.partita_iva &&
                          this.state.fieldValidations.partita_iva === "error"
                        }
                        helperText={
                          this.state.fieldValidations.partita_iva === "error" &&
                          "la lunghezza deve essere di 11 caratteri"
                        }
                        labelText="Partita IVA"
                        id="piva"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("partita_iva", event.target.value),
                          value: this.state.RowItem.partita_iva || "",
                          maxLength: 11
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Settore"
                        id="settore"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText(
                              "settore_di_attivita_economica",
                              event.target.value
                            ),
                          value:
                            this.state.RowItem.settore_di_attivita_economica ||
                            "",
                          maxLength: 255
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="CSC"
                        id="csc"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("csc", event.target.value),
                          value: this.state.RowItem.csc || "",
                          maxLength: 5
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="E-mail Azienda"
                        id="emailcon"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("email", event.target.value),
                          value: this.state.RowItem.email || "",
                          maxLength: 255
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.iban &&
                          this.state.fieldValidations.iban === "success"
                        }
                        error={
                          this.state.fieldValidations.iban &&
                          this.state.fieldValidations.iban === "error"
                        }
                        labelText="IBAN"
                        id="iban"
                        formControlProps={{
                          fullWidth: true
                        }}
                        helperText={
                          this.state.fieldValidations.iban &&
                          this.state.fieldValidations.iban === "error" &&
                          "lunghezza iban errata"
                        }
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("iban", event.target.value),
                          onBlur: (event) =>
                            this.OnInputBlurCheck(event.target.value, "iban"),
                          value: this.state.RowItem.iban || "",
                          maxLength: 27
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      style={{ marginTop: "4px" }}
                    >
                      <p
                        className="calendarParagraph label"
                        style={{
                          color:
                            this.state.fieldValidations["data_costituzione"] ===
                            "error"
                              ? "#f44336"
                              : "#565656"
                        }}
                      >
                        <small>Data Costituzione</small>
                      </p>

                      <Calendar
                        className="calendarCustomedForMUI"
                        id="datacostituzione"
                        value={this.state.convertedDataConst}
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          this.HandleArrayDatesChange(
                            this.state.convertedDataConst,
                            e.target.value,
                            "data_costituzione"
                          )
                        }
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="calendarParagraph label">
                        <small>Data Cessazione</small>
                      </p>

                      <Calendar
                        className="calendarCustomedForMUI"
                        id="dataultimoagg"
                        value={this.state.convertedDataCessaz}
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          this.HandleArrayDatesChange(
                            this.state.convertedDataCessaz,
                            e.target.value,
                            "data_cessazione"
                          )
                        }
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} className="select">
                      <CustomSelect
                        labelText="Stato Azienda"
                        id="cess"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (e) =>
                            this.HandleDropDownChange(
                              "selectedCess",
                              e.target.value,
                              "tipo_cessazione"
                            ),
                          value: this.state.selectedCess || ""
                        }}
                      >
                        {this.state.TipoCess.map((item) => (
                          <MenuItem value={item.codice} key={item.codice}>
                            {item.descrizione} - {item.note}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} className="select">
                      <CustomSelect
                        labelText="Tipo Azienda"
                        id="azienda"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (e) =>
                            this.HandleDropDownChange(
                              "selectedTipoAzienda",
                              e.target.value,
                              "tipo_azienda"
                            ),
                          value: this.state.selectedTipoAzienda || ""
                        }}
                      >
                        {this.state.TipoAzienda.map((item) => (
                          <MenuItem value={item.codice} key={item.codice}>
                            {item.codice} - {item.descrizione}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Note"
                        id="note"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("note", event.target.value),
                          value: this.state.RowItem.note || "",
                          maxLength: 1000
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <ExtraFieldsModifica
                    dataItem={this.props.dataItem}
                    SetText={this.SetText}
                    provincias={this.state.ProvinciaList}
                    comunes={this.state.notFilteredComune}
                  />
                </CardBody>
                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    isValidForm={this.isValidForm}
                    disabled={!this.state.isValid}
                    text={"SALVA"}
                  />
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}
