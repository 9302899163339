import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import SedeComuneProvAPI from "API/SedeComuneProvAPI";
import Button from "../../components/CustomButtons/Button";
import UndoIcon from "@material-ui/icons/Undo";
import Tooltip from "@material-ui/core/Tooltip";
import {InputText} from "primereact/inputtext";
import CardFooter from "../../components/Card/CardFooter";
import ButtonSaveWithLoading from "../../components/CustomButtons/ButtonSaveWithLoading";
import AddIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import {confirmPopup} from "primereact/confirmpopup";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";

class SediInps extends Component {

    state = {
        rows: [],
        loadingGrid: true,
        selectedGrid: null,
        title: "",
        loadingPage: false
    };

    constructor(props){
        super(props);
        this.handleTableClick = this.handleTableClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
    }

    async componentDidMount() {
        await this.refreshGrid();
    }

    async refreshGrid() {
        this.setState({loadingGrid: true});
        this.setState({
            rows: await SedeComuneProvAPI.GetAllSede()
        }, () => this.setState({loadingGrid: false}));
    }

    handleTableClick(e) {
        this.setState({
            selectedGrid: e.value,
            title: "Modifica"
        });
    }

    onChange(e, field) {
        this.state.selectedGrid[field] = e.target.value;
        this.setState({selectedGrid: this.state.selectedGrid});
    }

    async onClick() {

        let result = null;

        if (this.state.title === "Inserisci") {
            result = await SedeComuneProvAPI.InsertSedeInps(this.state.selectedGrid);
        } else if (this.state.title === "Modifica") {
            result = await SedeComuneProvAPI.UpdateSedeInps(this.state.selectedGrid);
        }

        if (result) {
            this.props.addAlert.current.addAlert("success", "Dati della sede inps salvati correttamente");
            this.setState({title: "Modifica"});
        } else {
            this.props.addAlert.current.addAlert("danger", "Non è stato possibile salvare i dati della sede inps");
        }

        await this.refreshGrid();
        return true;
    }

    render() {
        return (
            <>
            {this.state.loadingPage && <LoaderSpinner />}
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="ebinprofsuccess">
                            <h4 className={"d-inline"}>Sedi INPS</h4>
                            <Tooltip arrow title="Inserisci" placement="top">
                                <Button
                                    style={{margin: "0.4%"}}
                                    color="white"
                                    aria-label="edit"
                                    justIcon round
                                    className="float-right"
                                    onClick={() => {
                                        this.setState({
                                            selectedGrid: SedeComuneProvAPI.GetNewSedeInps(),
                                            title: "Inserisci"
                                        });
                                    }}
                                >
                                    <AddIcon />
                                </Button>
                            </Tooltip>
                        </CardHeader>

                        <CardBody>
                            <DataTable
                                emptyMessage=""
                                className="p-datatable-striped p-datatable-sm"
                                value={this.state.rows}
                                dataKey="sap"
                                paginator
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                                rows={10}
                                paginatorLeft={this.paginatorLeft}
                                paginatorRight={this.paginatorRight}
                                selection={this.state.selectedGrid}
                                loading={this.state.loadingGrid}
                                scrollable
                                onSelectionChange={this.handleTableClick}
                                selectionMode="single"
                            >
                                <Column
                                    field="sap"
                                    header="SAP"
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterMatchMode="contains"
                                    filterPlaceholder=""
                                />
                                <Column
                                    field="descrizione"
                                    header="Descri"
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterMatchMode="contains"
                                    filterPlaceholder=""
                                />
                                <Column
                                    field="indirizzo"
                                    header="indSap"
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterMatchMode="contains"
                                    filterPlaceholder=""
                                />
                                <Column
                                    field="cap"
                                    header="capSap"
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterMatchMode="contains"
                                    filterPlaceholder=""
                                />
                                <Column
                                    field="comune"
                                    header="locSap"
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterMatchMode="contains"
                                    filterPlaceholder=""
                                />
                                <Column
                                    field="prov"
                                    header="PrSap"
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterMatchMode="contains"
                                    filterPlaceholder=""
                                />
                                <Column
                                    field="sede"
                                    header="sede"
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterMatchMode="contains"
                                    filterPlaceholder=""
                                />
                                <Column
                                    field="path_archivio"
                                    header="pathArchivio"
                                    sortable={true}
                                    filter={this.state.filterGrid}
                                    filterMatchMode="contains"
                                    filterPlaceholder=""
                                />
                                <Column
                                    body={(row) => {
                                        return (
                                            <Button
                                                round color="danger"
                                                className="actionButton actionButtonRound btnRound float-right"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    confirmPopup({
                                                        target: e.currentTarget,
                                                        message: "Vuoi eliminare questo record?",
                                                        header: "Confirmation",
                                                        icon: "pi pi-info-circle",
                                                        acceptClassName: "p-button-danger",
                                                        accept: async () => {
                                                            this.setState({loadingPage: true});
                                                            const result = await SedeComuneProvAPI.DeleteSedeInps(row);
                                                            this.setState({loadingPage: false});
                                                            if (result) {
                                                                this.props.addAlert.current.addAlert("success", "Sede inps eliminata correttamente");
                                                                this.setState({selectedGrid: null});
                                                            } else {
                                                                this.props.addAlert.current.addAlert("danger", "Non è stato possibile eliminare la sede inps");
                                                            }
                                                            await this.refreshGrid();
                                                        },
                                                        reject: () => {}
                                                    });
                                                }}
                                            >
                                                <Close className="icon" />
                                            </Button>
                                        );
                                    }}
                                />
                            </DataTable>
                        </CardBody>
                    </Card>
                </GridItem>
                {this.state.selectedGrid && (
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color={"ebinprofinfo"}>
                                <h4 className={"d-inline"}>{this.state.title} sede INPS</h4>
                                <Tooltip arrow title="Annulla" placement="top">
                                    <Button
                                        style={{margin: "0.4%"}}
                                        color="white"
                                        aria-label="edit"
                                        justIcon round
                                        className="float-right"
                                        onClick={() => this.setState({selectedGrid: null})}
                                    >
                                        <UndoIcon />
                                    </Button>
                                </Tooltip>
                            </CardHeader>
                            <CardBody>
                                <GridContainer className={"mb-3"}>
                                    <GridItem xs={12} sm={12} md={3}>
                                        SAP
                                        <InputText value={this.state.selectedGrid.sap} onChange={e => this.onChange(e, "sap")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        Descrizione
                                        <InputText value={this.state.selectedGrid.descrizione} onChange={e => this.onChange(e, "descrizione")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        Indirizzo
                                        <InputText value={this.state.selectedGrid.indirizzo} onChange={e => this.onChange(e, "indirizzo")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        CAP
                                        <InputText value={this.state.selectedGrid.cap} onChange={e => this.onChange(e, "cap")} />
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={3}>
                                        Località
                                        <InputText value={this.state.selectedGrid.comune} onChange={e => this.onChange(e, "comune")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        Provincia
                                        <InputText value={this.state.selectedGrid.prov} onChange={e => this.onChange(e, "prov")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        Sede
                                        <InputText value={this.state.selectedGrid.sede} onChange={e => this.onChange(e, "sede")} />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={3}>
                                        Paht Archivio
                                        <InputText value={this.state.selectedGrid.path_archivio} onChange={e => this.onChange(e, "path_archivio")} />
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <ButtonSaveWithLoading
                                    onClick={this.onClick}
                                    isValidForm={() => true}
                                    text={"SALVA"}
                                />
                            </CardFooter>
                        </Card>
                    </GridItem>
                )}
            </GridContainer>
        </>
        );
    }
}
export default SediInps;