import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ButtonsMUI from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import StaticLists from "helpers/StaticLists.js";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import Accordion from "components/Accordion/Accordion.js";
import ReportAPI from "../../API/ReportAPI";
import SedeComuneProvAPI from "../../API/SedeComuneProvAPI";
import Utils from "../../API/Utils";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";

class Report extends Component {
  state = {
    selectedSede: null,
    selectedCodice: null,
    selectedCausale: null,
    banca: "",
    MesseCont: "",
    MesseContInizio: "",
    MesseContFine: "",
    annoFineScarichi: "",
    annoInizioScarichi: "",
    dataFineScarichi: new Date(),
    dataInizioScarichi: new Date(),
    competenzaFine: "",
    competenzaInizio: "",
    selectedCodiceSecondPanel: null,
    annoInizioThirdPanel: "",
    annoFineThirdPanel: "",
    selectedCodiceThirdPanel: null,
    dataInizioFourthPanel: new Date(),
    dataFineFourthPanel: new Date(),
    bancaFourthPanel: "",
    selectedSedeFifthPanel: null,
    selectedCodiceFifthPanel: null,
    selectedCausaleFifthPanel: null,
    CodQuota: [],
    SedeInps: [],
    Causale: [],
    load: false
  };

  constructor(props) {
    super(props);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.riepilogoXAnno = this.riepilogoXAnno.bind(this);
    this.caspo = this.caspo.bind(this);
    this.qIncassiXProvincia = this.qIncassiXProvincia.bind(this);
    this.situazioneXSede = this.situazioneXSede.bind(this);
    this.situazioneXProvincia = this.situazioneXProvincia.bind(this);
    this.incassiRiepilogo = this.incassiRiepilogo.bind(this);
    this.resocontoCorrisp = this.resocontoCorrisp.bind(this);
    this.registroProtocollo = this.registroProtocollo.bind(this);
    this.compXAnno95_10 = this.compXAnno95_10.bind(this);
    this.compXAnno10_20 = this.compXAnno10_20.bind(this);
    this.compXProv95_10 = this.compXProv95_10.bind(this);
    this.compXProv10_20 = this.compXProv10_20.bind(this);
    this.incassiDettaglio = this.incassiDettaglio.bind(this);
    this.fattureDettaglio = this.fattureDettaglio.bind(this);
    this.incassiScarichiRiepilogo = this.incassiScarichiRiepilogo.bind(this);
    this.incassiScarichiDettaglio = this.incassiScarichiDettaglio.bind(this);
    this.fattureScarichiDettaglio = this.fattureScarichiDettaglio.bind(this);
    this.periodiContributivi = this.periodiContributivi.bind(this);
  }

  async periodiContributivi() {
    if (!this.state.selectedSedeFifthPanel) {
      this.props.addAlert.current.addAlert("danger", "Selezionare una sede");
      return;
    }

    if (!this.state.selectedCodiceFifthPanel) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un codice quota"
      );
      return;
    }

    if (!this.state.selectedCausaleFifthPanel) {
      this.props.addAlert.current.addAlert("danger", "Selezionare una causale");
      return;
    }

    if (
      !this.state.competenzaInizio ||
      this.state.competenzaInizio.length !== 7
    ) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare la competenza d'inizio"
      );
      return;
    }

    if (!this.state.competenzaFine || this.state.competenzaFine.length !== 7) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare la competenza di fine"
      );
      return;
    }

    this.setState({ load: true });

    await ReportAPI.periodiContributivi(
      this.state.selectedSedeFifthPanel,
      this.state.selectedCodiceFifthPanel,
      this.state.selectedCausaleFifthPanel,
      this.state.competenzaInizio,
      this.state.competenzaFine
    );

    this.setState({ load: false });
  }

  async fattureScarichiDettaglio() {
    if (!this.state.dataInizioScarichi) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare la data di inizio"
      );
      return;
    }

    if (!this.state.dataFineScarichi) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare la data di fine"
      );
      return;
    }

    this.setState({ load: true });
    await ReportAPI.fattureScarichiDettaglio(
      this.state.dataInizioScarichi,
      this.state.dataFineScarichi
    );
    this.setState({ load: false });
  }

  async fattureDettaglio() {
    if (!this.state.dataInizioFourthPanel) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare la data di inizio"
      );
      return;
    }

    if (!this.state.dataFineFourthPanel) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare la data di fine"
      );
      return;
    }

    this.setState({ load: true });
    await ReportAPI.fattureDettaglio(
      this.state.dataInizioFourthPanel,
      this.state.dataFineFourthPanel
    );
    this.setState({ load: false });
  }

  async incassiDettaglio() {
    if (!this.state.dataInizioFourthPanel) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare la data di inizio"
      );
      return;
    }

    if (!this.state.dataFineFourthPanel) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare la data di fine"
      );
      return;
    }

    if (!this.state.bancaFourthPanel) {
      this.props.addAlert.current.addAlert("danger", "Selezionare la banca");
      return;
    }

    this.setState({ load: true });
    await ReportAPI.incassiDettaglio(
      this.state.dataInizioFourthPanel,
      this.state.dataFineFourthPanel,
      this.state.bancaFourthPanel
    );
    this.setState({ load: false });
  }

  async incassiScarichiDettaglio() {
    if (!this.state.dataInizioScarichi) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare la data di inizio"
      );
      return;
    }

    if (!this.state.dataFineScarichi) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare la data di fine"
      );
      return;
    }

    if (!this.state.banca) {
      this.props.addAlert.current.addAlert("danger", "Selezionare la banca");
      return;
    }

    this.setState({ load: true });
    await ReportAPI.incassiScarichiDettaglio(
      this.state.dataInizioScarichi,
      this.state.dataFineScarichi,
      this.state.banca
    );
    this.setState({ load: false });
  }

  async compXProv95_10() {
    if (!this.state.selectedCodiceThirdPanel) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un codice quota"
      );
      return;
    }

    this.setState({ load: true });
    await ReportAPI.compXProv95_10(this.state.selectedCodiceThirdPanel.name);
    this.setState({ load: false });
  }

  async compXProv10_20() {
    if (!this.state.selectedCodiceThirdPanel) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un codice quota"
      );
      return;
    }

    this.setState({ load: true });
    await ReportAPI.compXProv10_20(this.state.selectedCodiceThirdPanel.name);
    this.setState({ load: false });
  }

  async compXAnno10_20() {
    if (!this.state.selectedCodiceThirdPanel) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un codice quota"
      );
      return;
    }

    this.setState({ load: true });
    await ReportAPI.compXAnno10_20(this.state.selectedCodiceThirdPanel.name);
    this.setState({ load: false });
  }

  async compXAnno95_10() {
    if (!this.state.selectedCodiceThirdPanel) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un codice quota"
      );
      return;
    }

    this.setState({ load: true });
    await ReportAPI.compXAnno95_10(this.state.selectedCodiceThirdPanel.name);
    this.setState({ load: false });
  }

  async registroProtocollo() {
    if (
      !this.state.annoInizioThirdPanel ||
      this.state.annoInizioThirdPanel.length !== 4
    ) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un anno di inizio"
      );
      return;
    }

    this.setState({ load: true });
    await ReportAPI.GetRegistroProtocollo(this.state.annoInizioThirdPanel);
    this.setState({ load: false });
  }

  async resocontoCorrisp() {
    if (
      !this.state.annoInizioThirdPanel ||
      this.state.annoInizioThirdPanel.length !== 4
    ) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un anno di inizio"
      );
      return;
    }

    this.setState({ load: true });
    await ReportAPI.GetResocontoCorrisp(this.state.annoInizioThirdPanel);
    this.setState({ load: false });
  }

  async incassiRiepilogo() {
    if (
      !this.state.annoInizioThirdPanel ||
      this.state.annoInizioThirdPanel.length !== 4
    ) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un anno di inizio"
      );
      return;
    }

    if (
      !this.state.annoFineThirdPanel ||
      this.state.annoFineThirdPanel.length !== 4
    ) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un anno di fine"
      );
      return;
    }

    this.setState({ load: true });
    await ReportAPI.GetIncassiRiepilogo(
      this.state.annoInizioThirdPanel,
      this.state.annoFineThirdPanel
    );
    this.setState({ load: false });
  }

  async incassiScarichiRiepilogo() {
    if (
      !this.state.annoInizioScarichi ||
      this.state.annoInizioScarichi.length !== 4
    ) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un anno di inizio"
      );
      return;
    }

    if (
      !this.state.annoFineScarichi ||
      this.state.annoFineScarichi.length !== 4
    ) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un anno di fine"
      );
      return;
    }

    this.setState({ load: true });
    await ReportAPI.GetIncassiScarichiRiepilogo(
      this.state.annoInizioScarichi,
      this.state.annoFineScarichi
    );
    this.setState({ load: false });
  }

  validSituazioneX() {
    if (!this.state.selectedCodiceSecondPanel) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un codice quota"
      );
      return false;
    }

    if (!this.state.MesseContInizio) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un anno/mese di inizio"
      );
      return false;
    }

    if (!this.state.MesseContFine) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un anno/mese di fine"
      );
      return false;
    }
    return true;
  }

  async situazioneXProvincia() {
    if (!this.validSituazioneX()) return;

    this.setState({ load: true });
    await ReportAPI.GetSituazioneXProvincia(
      this.state.selectedCodiceSecondPanel,
      this.state.MesseContInizio,
      this.state.MesseContFine
    );
    this.setState({ load: false });
  }

  async situazioneXSede() {
    if (!this.validSituazioneX()) return;

    this.setState({ load: true });
    await ReportAPI.GetSituazioneXSede(
      this.state.selectedCodiceSecondPanel,
      this.state.MesseContInizio,
      this.state.MesseContFine
    );
    this.setState({ load: false });
  }

  async qIncassiXProvincia() {
    if (!this.state.MesseCont) {
      this.props.addAlert.current.addAlert("danger", "Selezionare un anno");
      return;
    } else if (this.state.MesseCont.length !== 4) {
      this.props.addAlert.current.addAlert("danger", "Selezionare solo l'anno");
      return;
    }

    this.setState({ load: true });
    const result = await ReportAPI.GetQIncassiXProvincia(this.state.MesseCont);
    Utils.ExportExcel(result);
    this.setState({ load: false });
  }

  async riepilogoXAnno() {
    if (!this.state.selectedSede) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare una sede inps"
      );
      return;
    }

    if (!this.state.selectedCodice) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un codice quota"
      );
      return;
    }

    if (!this.state.MesseCont) {
      this.props.addAlert.current.addAlert("danger", "Selezionare un anno");
      return;
    } else if (this.state.MesseCont.length !== 4) {
      this.props.addAlert.current.addAlert("danger", "Selezionare solo l'anno");
      return;
    }

    this.setState({ load: true });
    await ReportAPI.GetRiepilogoXAnno(
      this.state.selectedSede.sap,
      this.state.selectedCodice,
      this.state.MesseCont
    );
    this.setState({ load: false });
  }

  async caspo() {
    if (!this.state.selectedSede) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare una sede inps"
      );
      return;
    }

    if (!this.state.selectedCodice) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un codice quota"
      );
      return;
    }

    if (!this.state.MesseCont) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare un anno/mese"
      );
      return;
    }

    this.setState({ load: true });
    await ReportAPI.GetCASPO(
      this.state.selectedSede.sap,
      this.state.selectedCodice,
      this.state.MesseCont
    );
    this.setState({ load: false });
  }

  async componentDidMount() {
    this.setState({
      SedeInps: [{ sap: "*", descrizione: "*" }].concat(
        await SedeComuneProvAPI.GetAllSede()
      ),
      CodQuota: [
        { id: 0, name: "*" },
        { id: 1, name: "CUST" },
        { id: 2, name: "PULI" },
        { id: 3, name: "W300" },
        { id: 4, name: "W350" },
        { id: 5, name: "Custodi" },
        { id: 6, name: "Pulitori" }
      ],
      Causale: await StaticLists.GetCausaleUpdate()
    });
  }

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  HandleDropDownChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <h4
                  style={{
                    lineHeight: "1",
                    verticalAlign: "bottom"
                  }}
                >
                  Report
                </h4>
              </CardHeader>

              <CardBody>
                {this.state.load && <LoaderSpinner report={true} />}
                <Accordion
                  active={0}
                  collapses={[
                    {
                      title: "Sede, Codice Quota e Mese Cont",
                      content: (
                        <GridContainer
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          <GridItem xs={12} sm={12} md={3}>
                            <p className="lblNoMargin">Sede</p>
                            <Dropdown
                              display="chip"
                              optionLabel="descrizione"
                              itemTemplate={(option) => (
                                <>
                                  {option.sap} - {option.descrizione}
                                </>
                              )}
                              maxSelectedLabels={1}
                              value={this.state.selectedSede}
                              options={this.state.SedeInps}
                              filter
                              filterBy="sap"
                              onChange={(e) =>
                                this.HandleChange("selectedSede", e.value)
                              }
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={2}>
                            <p className="lblNoMargin">Codice Quota</p>
                            <Dropdown
                              display="chip"
                              optionLabel="name"
                              maxSelectedLabels={1}
                              value={this.state.selectedCodice}
                              options={this.state.CodQuota}
                              onChange={(e) =>
                                this.HandleChange("selectedCodice", e.value)
                              }
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={2}>
                            <p className="lblNoMargin">Mese Cont</p>
                            <InputText
                              value={this.state.MesseCont}
                              placeholder="aaaa/mm"
                              onChange={(e) =>
                                this.HandleChange("MesseCont", e.target.value)
                              }
                            />
                          </GridItem>

                          <GridItem xs={1} />
                          <ButtonGroup
                            variant="contained"
                            color="secondary"
                            size="small"
                            aria-label="contained secondary button group"
                            className="float-right"
                            style={{
                              verticalAlign: "center",
                              marginTop: "20px",
                              marginBottom: "20px"
                            }}
                          >
                            <ButtonsMUI onClick={this.caspo}>CASPO</ButtonsMUI>
                            <ButtonsMUI onClick={this.riepilogoXAnno}>
                              Riepilogo X Anno
                            </ButtonsMUI>
                            <ButtonsMUI onClick={this.qIncassiXProvincia}>
                              Q Incassi X Provincia
                            </ButtonsMUI>
                          </ButtonGroup>
                        </GridContainer>
                      )
                    },
                    {
                      title: "Codice Quota e Mese Cont",
                      content: (
                        <GridContainer
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          <GridItem
                            xs={12}
                            sm={12}
                            md={2}
                            className="customdate"
                          >
                            <p className="lblNoMargin">Mese Cont Inizio</p>
                            <InputText
                              value={this.state.MesseContInizio}
                              onChange={(e) =>
                                this.HandleChange(
                                  "MesseContInizio",
                                  e.target.value
                                )
                              }
                              placeholder="aaaa/mm"
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            sm={12}
                            md={2}
                            className="customdate"
                          >
                            <p className="lblNoMargin">Mese Cont Fine</p>
                            <InputText
                              value={this.state.MesseContFine}
                              onChange={(e) =>
                                this.HandleChange(
                                  "MesseContFine",
                                  e.target.value
                                )
                              }
                              placeholder="aaaa/mm"
                            />
                          </GridItem>

                          <GridItem xs={3}>
                            <p className="lblNoMargin">Codice Quota</p>
                            <Dropdown
                              display="chip"
                              optionLabel="name"
                              maxSelectedLabels={1}
                              value={this.state.selectedCodiceSecondPanel}
                              options={this.state.CodQuota}
                              onChange={(e) =>
                                this.HandleChange(
                                  "selectedCodiceSecondPanel",
                                  e.value
                                )
                              }
                            />
                          </GridItem>

                          <GridItem xs={1} />
                          <ButtonGroup
                            variant="contained"
                            color="secondary"
                            size="small"
                            aria-label="contained secondary button group"
                            className="float-right"
                            style={{
                              verticalAlign: "center",
                              marginTop: "20px",
                              marginBottom: "20px"
                            }}
                          >
                            <ButtonsMUI onClick={this.situazioneXSede}>
                              Situazione X Sede
                            </ButtonsMUI>
                            <ButtonsMUI onClick={this.situazioneXProvincia}>
                              Situazione X Provincia
                            </ButtonsMUI>
                          </ButtonGroup>
                        </GridContainer>
                      )
                    },
                    {
                      title: "Codice Quota e Anno",
                      content: (
                        <GridContainer
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          <GridItem
                            xs={12}
                            sm={12}
                            md={2}
                            className="customdate"
                          >
                            <p className="lblNoMargin">Anno Inizio</p>
                            <InputText
                              value={this.state.annoInizioThirdPanel}
                              onChange={(e) =>
                                this.HandleChange(
                                  "annoInizioThirdPanel",
                                  e.target.value
                                )
                              }
                              placeholder="aaaa"
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            sm={12}
                            md={2}
                            className="customdate"
                          >
                            <p className="lblNoMargin">Anno Fine</p>
                            <InputText
                              value={this.state.annoFineThirdPanel}
                              onChange={(e) =>
                                this.HandleChange(
                                  "annoFineThirdPanel",
                                  e.target.value
                                )
                              }
                              placeholder="aaaa"
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={2}>
                            <p className="lblNoMargin">Codice Quota</p>
                            <Dropdown
                              display="chip"
                              optionLabel="name"
                              maxSelectedLabels={1}
                              value={this.state.selectedCodiceThirdPanel}
                              options={this.state.CodQuota}
                              onChange={(e) =>
                                this.HandleChange(
                                  "selectedCodiceThirdPanel",
                                  e.value
                                )
                              }
                            />
                          </GridItem>
                          <GridItem xs={6} />

                          <GridItem xs={12} sm={12} md={12}>
                            <ButtonGroup
                              variant="contained"
                              color="secondary"
                              size="small"
                              aria-label="contained secondary button group"
                              className="float-right"
                              style={{
                                verticalAlign: "center",
                                marginTop: "20px",
                                marginLeft: "2.5%",
                                marginRight: "2.5%"
                              }}
                            >
                              <ButtonsMUI onClick={this.incassiRiepilogo}>
                                Incassi Riepilogo
                              </ButtonsMUI>
                              <ButtonsMUI onClick={this.resocontoCorrisp}>
                                Resoconto Corrisp.
                              </ButtonsMUI>
                              <ButtonsMUI onClick={this.registroProtocollo}>
                                Registro Protocollo
                              </ButtonsMUI>
                              <ButtonsMUI onClick={this.compXAnno95_10}>
                                Comp X Anno('95-'09)
                              </ButtonsMUI>
                              <ButtonsMUI onClick={this.compXAnno10_20}>
                                Comp X Anno('10-'20)
                              </ButtonsMUI>
                              <ButtonsMUI onClick={this.compXProv95_10}>
                                Comp X Prov('95-'09)
                              </ButtonsMUI>
                              <ButtonsMUI onClick={this.compXProv10_20}>
                                Comp X Prov('10-'20)
                              </ButtonsMUI>
                            </ButtonGroup>
                          </GridItem>
                        </GridContainer>
                      )
                    },

                    {
                      title: "Data inizio, Data fine e Banca",
                      content: (
                        <GridContainer
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          <GridItem
                            xs={12}
                            sm={12}
                            md={2}
                            className="customdate"
                          >
                            <p className="lblNoMargin">Data inizio</p>
                            <Calendar
                              id="icon"
                              value={this.state.dataInizioFourthPanel}
                              dateFormat="dd/mm/yy"
                              onChange={(e) =>
                                this.HandleChange(
                                  "dataInizioFourthPanel",
                                  e.target.value
                                )
                              }
                              showIcon
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            sm={12}
                            md={2}
                            className="customdate"
                          >
                            <p className="lblNoMargin">Data fine</p>
                            <Calendar
                              id="icon"
                              value={this.state.dataFineFourthPanel}
                              dateFormat="dd/mm/yy"
                              onChange={(e) =>
                                this.HandleChange(
                                  "dataFineFourthPanel",
                                  e.target.value
                                )
                              }
                              showIcon
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={4}>
                            <p className="lblNoMargin">Banca</p>
                            <InputText
                              id="icon"
                              value={this.state.bancaFourthPanel}
                              onChange={(e) =>
                                this.HandleChange(
                                  "bancaFourthPanel",
                                  e.target.value
                                )
                              }
                              showIcon
                            />
                          </GridItem>

                          <ButtonGroup
                            variant="contained"
                            color="secondary"
                            size="small"
                            aria-label="contained secondary button group"
                            className="float-right"
                            style={{
                              verticalAlign: "center",
                              marginTop: "20px",
                              marginLeft: "50px",
                              marginBottom: "20px"
                            }}
                          >
                            <ButtonsMUI onClick={this.incassiDettaglio}>
                              Incassi Dettaglio
                            </ButtonsMUI>
                            <ButtonsMUI onClick={this.fattureDettaglio}>
                              Fatture Dettaglio
                            </ButtonsMUI>
                          </ButtonGroup>
                        </GridContainer>
                      )
                    },

                    {
                      title: "Sede, Codice Quota, Causale e Competenza",
                      content: (
                        <GridContainer
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          <GridItem xs={12} sm={12} md={2}>
                            <p className="lblNoMargin">Sede</p>
                            <Dropdown
                              display="chip"
                              optionLabel="descrizione"
                              itemTemplate={(option) => (
                                <>
                                  {option.sap} - {option.descrizione}
                                </>
                              )}
                              maxSelectedLabels={1}
                              value={this.state.selectedSedeFifthPanel}
                              options={this.state.SedeInps}
                              filter
                              filterBy="sap"
                              onChange={(e) =>
                                this.HandleChange(
                                  "selectedSedeFifthPanel",
                                  e.value
                                )
                              }
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={2}>
                            <p className="lblNoMargin">Codice Quota</p>
                            <Dropdown
                              display="chip"
                              optionLabel="name"
                              maxSelectedLabels={1}
                              value={this.state.selectedCodiceFifthPanel}
                              options={this.state.CodQuota}
                              onChange={(e) =>
                                this.HandleChange(
                                  "selectedCodiceFifthPanel",
                                  e.value
                                )
                              }
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={2}>
                            <p className="lblNoMargin">Causale</p>
                            <Dropdown
                              display="chip"
                              itemTemplate={(option) => (
                                <>
                                  {option.codice} - {option.descrizione}
                                </>
                              )}
                              optionLabel="descrizione"
                              maxSelectedLabels={1}
                              value={this.state.selectedCausaleFifthPanel}
                              options={this.state.Causale}
                              filter
                              filterBy="codice"
                              onChange={(e) =>
                                this.HandleChange(
                                  "selectedCausaleFifthPanel",
                                  e.value
                                )
                              }
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            sm={12}
                            md={2}
                            className="customdate"
                          >
                            <p className="lblNoMargin">Competenza Inizio</p>
                            <InputText
                              value={this.state.competenzaInizio}
                              onChange={(e) =>
                                this.HandleChange(
                                  "competenzaInizio",
                                  e.target.value
                                )
                              }
                              placeholder="aaaa/mm"
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            sm={12}
                            md={2}
                            className="customdate"
                          >
                            <p className="lblNoMargin">Competenza Fine</p>
                            <InputText
                              value={this.state.competenzaFine}
                              onChange={(e) =>
                                this.HandleChange(
                                  "competenzaFine",
                                  e.target.value
                                )
                              }
                              placeholder="aaaa/mm"
                            />
                          </GridItem>
                          <ButtonGroup
                            variant="contained"
                            color="secondary"
                            size="small"
                            aria-label="contained secondary button group"
                            className="float-right"
                            style={{
                              verticalAlign: "center",
                              marginTop: "20px",
                              marginBottom: "20px"
                            }}
                          >
                            <ButtonsMUI onClick={this.periodiContributivi}>
                              Periodi Contributivi
                            </ButtonsMUI>
                          </ButtonGroup>
                        </GridContainer>
                      )
                    },

                    {
                      title: "Scarichi",
                      content: (
                        <GridContainer
                          style={{ marginLeft: "5px", marginRight: "5px" }}
                        >
                          <GridItem xs={12} sm={12} md={2}>
                            <p className="lblNoMargin">Anno Inizio</p>
                            <InputText
                              value={this.state.annoInizioScarichi}
                              onChange={(e) =>
                                this.HandleChange(
                                  "annoInizioScarichi",
                                  e.target.value
                                )
                              }
                              placeholder="aaaa"
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={2}>
                            <p className="lblNoMargin">Anno Fine</p>
                            <InputText
                              value={this.state.annoFineScarichi}
                              onChange={(e) =>
                                this.HandleChange(
                                  "annoFineScarichi",
                                  e.target.value
                                )
                              }
                              placeholder="aaaa"
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={4}>
                            <p className="lblNoMargin">Banca</p>
                            <InputText
                              id="icon"
                              value={this.state.banca}
                              onChange={(e) =>
                                this.HandleChange("banca", e.target.value)
                              }
                              showIcon
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            sm={12}
                            md={2}
                            className="customdate"
                          >
                            <p className="lblNoMargin">Data inizio</p>
                            <Calendar
                              id="icon"
                              value={this.state.dataInizioScarichi}
                              dateFormat="dd/mm/yy"
                              onChange={(e) =>
                                this.HandleChange(
                                  "dataInizioScarichi",
                                  e.target.value
                                )
                              }
                              showIcon
                            />
                          </GridItem>

                          <GridItem
                            xs={12}
                            sm={12}
                            md={2}
                            className="customdate"
                          >
                            <p className="lblNoMargin">Data fine</p>
                            <Calendar
                              id="icon"
                              value={this.state.dataFineScarichi}
                              dateFormat="dd/mm/yy"
                              onChange={(e) =>
                                this.HandleChange(
                                  "dataFineScarichi",
                                  e.target.value
                                )
                              }
                              showIcon
                            />
                          </GridItem>

                          <ButtonGroup
                            variant="contained"
                            color="secondary"
                            size="small"
                            aria-label="contained secondary button group"
                            className="float-right"
                            style={{
                              verticalAlign: "center",
                              marginTop: "10px",
                              marginLeft: "12px",
                              marginBottom: "10px"
                            }}
                          >
                            <ButtonsMUI onClick={this.incassiScarichiRiepilogo}>
                              Incassi Scarichi Riepilogo
                            </ButtonsMUI>
                            <ButtonsMUI onClick={this.incassiScarichiDettaglio}>
                              Incassi Scarichi Dettaglio
                            </ButtonsMUI>
                            <ButtonsMUI onClick={this.fattureScarichiDettaglio}>
                              Fatture Scarichi Dettaglio
                            </ButtonsMUI>
                          </ButtonGroup>
                        </GridContainer>
                      )
                    }
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default Report;
