export function getToken() {
  return localStorage.getItem("COASCO-token");
}

export function setToken(token) {
  localStorage.setItem("COASCO-token", token);
}

export function removeToken() {
  localStorage.removeItem("COASCO-token");
}

export function getRole() {
  return localStorage.getItem("COASCO-role");
}

export function setRole(role) {
  localStorage.setItem("COASCO-role", role);
}

export function removeRole() {
  localStorage.removeItem("COASCO-role");
}
