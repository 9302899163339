import React, { Component } from "react";
import clone from "clone";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import { Calendar } from "primereact/calendar";
import CardBody from "components/Card/CardBody.js";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import MenuItem from "@material-ui/core/MenuItem";
import LoaderSpinner from "../../Layout/LoaderSpinner.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import UndoIcon from "@material-ui/icons/Undo";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Close from "@material-ui/icons/Close";
import { confirmPopup } from "primereact/confirmpopup";
import { FileUpload } from "primereact/fileupload";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import StaticLists from "helpers/StaticLists";
import helpers from "helpers/Helpers";
import RichiestiAPI from "API/RichiestiAPI";
import moment from "moment";
import { dateTimeFmt } from "helpers/AppConstants.js";
import { dateFmt } from "helpers/AppConstants.js";
import CorrispondenzaAPI from "API/CorrispondenzaAPI.js";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import CardFooter from "components/Card/CardFooter.js";
import { TestoEmail } from "../Protocollo/TestoEmail.component.js";
import EmailUtenteAPI from "API/EmailUtenteAPI.js";
import UtentiAPI from "API/Utenti.api.js";
import { AuthContext } from "providers/auth.provider.js";
import { TestoLettera } from "../Protocollo/TestoLettera.component.js";
import { InputTextarea } from "primereact/inputtextarea";

export class ModificaCorrispXRichiedente extends Component {
  static contextType = AuthContext;
  state = {
    causaleList: [],
    applicant: null,
    request: null,
    RowItem: {...this.props.dataItem},
    ModificaTitle: "Nuovo",
    deletecolumns: [],
    downloadcolumns: [],
    visibleItems: false,
    groupClass: "",
    selectedCausale: "01",
    loadingGrid: false,
    isLetterDateChanged: false,
    senderEmail: "",
    emailText: "",
    attachments: [],
    showEmailText: false,

    protocolTypes: [
      { id: 1, name: "Entrata" },
      { id: 2, name: "Uscita" }
    ],

    sendTypes: [
      { id: 1, name: "Lettera" },
      { id: 2, name: "E-mail" }
    ],

    files: [],
    //VALIDATIONS
    fieldValidations: {},
    validationRules: {
      tipoinvio: "",
      tipo: "",
      oggetto: ""
    },
    destMail: null,
  };

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.DeleteAttachment = this.DeleteAttachment.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.DownloadAttachmment = this.DownloadAttachmment.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.SetText = this.SetText.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.setNewCorrispondenza = this.setNewCorrispondenza.bind(this);
    this.setChangeCorrispondenza = this.setChangeCorrispondenza.bind(this);
    this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this);
    this.refreshAttachmentTable = this.refreshAttachmentTable.bind(this);
    this.handleDownloadAttachment = this.handleDownloadAttachment.bind(this);
  }

  HandleDropDownChange(propLabel, value) {
    const rowItem = this.state.RowItem;
    rowItem[propLabel] = value;
    this.setState({
      RowItem: rowItem
    });
  }

  SetText(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({
      RowItem: newRowItem
    });
    
    if(field === "testomail"){
      this.setState({
        emailText: newValue
      });
    }
  }

  async isValidForm() {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);

    let isValid = Object.values(fieldValidations).every((x) => x === "success");

    this.setState({
      fieldValidations: fieldValidations
    });

    return isValid;
  }

  HandleChange(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
  }

  HandleSaveClick = async () => {
    const isFormValid = await this.isValidForm();

    if (isFormValid) {
      let corr = this.state.RowItem;
      corr.testomail = this.state.emailText;
      let files = [...this.state.files];
      return await this.props.onSaveClick(
        corr,
        this.state.user,
        files,
        this.state.otherRecipient,
        this.state.senderEmail,
        this.state?.isLetterDateChanged
      );
    }
  };

  handleTextEmail(text) {
    this.setState({
      emailText: text
    });
  }

  setNewCorrispondenza() {
    this.setState({
      selectedCausale: null,
      ModificaTitle: "Nuovo",
      visibleItems: false,
      groupClass: "hide",
      showEmailText: true
    });
  }

  async setChangeCorrispondenza() {
    const request = await RichiestiAPI.GetRequestById(this.props.dataItem.id);
    this.setState({
      deletecolumns: [
        {
          header: "",
          body: this.DeleteAttachment,
          icon: "pi pi-times",
          label: "Delete",
          className: "p-button-danger p-button-outlined colDelete"
        }
      ],
      downloadcolumns: [
        {
          header: "",
          body: this.DownloadAttachmment,
          icon: "pi pi-times",
          label: "Download",
          className: "p-button-danger p-button-outlined colDownload"
        }
      ],
      applicant: request && request.length > 0 && request[0]?.cfRich, // contains an object
      request: request && request.length > 0 && request[0],
      ModificaTitle: "Modifica",
      selectedCausale: this.props.dataItem?.causale?.codice,
      visibleItems: this.props.title !== "Protocollo",
      groupClass: "",
      attachments: await CorrispondenzaAPI.GetCorrispondenzaEbinPathAttach(
        this.props.dataItem.id
      )
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.dataItem.id !== prevProps.dataItem.id){
      this.setState({
        RowItem: {...this.props.dataItem},
        ModificaTitle: this.props.dataItem.Id > 0 ? "Modifica" : "Nuovo",
        visibleItems:
        this.props.title === "Protocollo"
        ? false
        : this.props.dataItem.Id > 0,
        groupClass: this.props.dataItem.Id > 0 ? "" : "hide",
        selectedCausale: helpers.ReturnIdFromList(
          this.state.causaleList,
          this.props.dataItem.causale
          )
        });
        
        const disableAll = this.props.dataItem?.tipo === "USCITA" && (this.state.RowItem?.tipoinvio === 2 || this.state.RowItem?.tipoinvio === 3); //Uscita      
        this.setState({disableAll});

        this.setState({
          emailText: this.state.RowItem?.testoMail
        });
        if(this.props.dataItem.id > 0){
          this.setState({
            destMail: this.props.dataItem?.destmail?.split(",")
          });
        }
    }
  }
  
  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });
    if (this.props.dataItem?.id > 0) {
      await this.setChangeCorrispondenza();
    } else {
      this.setNewCorrispondenza();
    }

    const { profile } = this.context;
    const userRes = await UtentiAPI.getUserByUsername(profile?.username);

    const userEmails = await EmailUtenteAPI.GetByUtente(userRes[0]?.utente);

    this.setState({
      deletecolumns: [
        {
          header: "",
          body: this.DeleteAttachment,
          icon: "pi pi-times",
          label: "Delete",
          className: "p-button-danger p-button-outlined colDelete"
        }
      ],
      downloadcolumns: [
        {
          header: "",
          body: this.DownloadAttachmment,
          icon: "pi pi-times",
          label: "Download",
          className: "p-button-danger p-button-outlined colDownload"
        }
      ],
      causaleList: await CorrispondenzaAPI.GetCausaliEbinForCorrispondenza(),
      RowItem: {
        ...this.props.dataItem,
        causale: this.props.dataItem?.causale?.codice,
        tipoinvio: this.props.dataItem?.tipoinvio?.id,
        tipo: this.props.dataItem?.tipo === "ENTRATA" ? 1 : 2,
        anno: new Date().getFullYear(),
        data: moment(new Date()).format(dateFmt)
      },
      user: userRes,
      userEmails
      
    });

    if(!this.state.RowItem?.id || this.state.RowItem?.id <= 0) {
      this.setState({
        RowItem: {
          ...this.state.RowItem,
          tipoinvio:2
        }
      });
    }
    const disableAll = this.props.dataItem?.tipo === "USCITA" && (this.state.RowItem?.tipoinvio === 2 || this.state.RowItem?.tipoinvio === 3); //Uscita
    this.setState({disableAll})

    this.setState({
      emailText: this.state.RowItem?.testoMail
    });
    if(this.props.dataItem.id > 0){
      this.setState({
        destMail: this.props.dataItem?.destmail?.split(",")
      });
    }
    this.setState({
      loadingGrid: false
    });   
  }

  async refreshAttachmentTable() {
    this.setState({ loadingGrid: true }, async () =>
      this.setState(
        {
          attachments: await CorrispondenzaAPI.GetCorrispondenzaEbinPathAttach(
            this.props.dataItem.id
          )
        },
        () => this.setState({ loadingGrid: false })
      )
    );
  }

  async onUpload(event) {
    this.setState({ files: event.files });
    this.props.activeSnackbar("success", "File pronti al caricamento");
  }

  deleteHandler(event, rowData) {
    event.preventDefault();
    event.stopPropagation();
    this.onDeleteClick(event, rowData?.id);
  }

  DeleteAttachment(rowData) {
    return (
      <Button
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData?.id}
        onClick={(event) => this.deleteHandler(event, rowData)}
      >
        <Close className="icon" />
      </Button>
    );
  }

  DownloadAttachmment(rowData) {
    const paths = rowData?.path?.split(".");
    const extension = paths[paths.length - 1];
    return (
      <Button
        round
        color="primary"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData?.id}
        onClick={(event) => {
          this.onDownloadClick(event, rowData?.id, extension);
        }}
      >
        <GetAppRoundedIcon className="icon" />
      </Button>
    );
  }

  async handleDeleteAttachment(id) {
    const response = await CorrispondenzaAPI.DeleteCorrispondenzaEbinAttachment(
      id
    );
    if (response && response.result) {
      this.props.activeSnackbar("success", "Allegato eliminato");
      const res = await CorrispondenzaAPI.GetCorrispondenzaEbinPathAttach(
        this.props.dataItem.id
      );
      this.setState({ attachments: res });
    } else {
      this.props.activeSnackbar("danger", "Eliminazione non riuscita");
    }
    await this.refreshAttachmentTable();
  }

  onDeleteClick(event, id) {
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        this.handleDeleteAttachment(id);
      },
      reject: () => {}
    });
  }

  async handleDownloadAttachment(id, extension) {
    await CorrispondenzaAPI.downloadCorrespondenceAttachmentEbin(id, extension);
    await this.refreshAttachmentTable();
  }

  onDownloadClick(event, id, extension) {
    event.preventDefault();
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi scaricarlo?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        this.handleDownloadAttachment(id, extension);
      },
      reject: () => {}
    });
  }

  removeFile(event) {
    if (this.state?.files) {
      const files = this.state?.files?.filter((file) => file !== event.file);
      this.setState({ files: files });
    }
  }

  async handleTableClick(rowData) {
    const _rowData = rowData?.value;
    const paths = _rowData?.path?.split(".");
    const extension = paths[paths.length - 1];
    if (extension === "pdf") {
      const _data = _rowData.path?.split(".");
      await CorrispondenzaAPI.openPdfEbin(
        _rowData?.id,
        _data[_data.length - 1]
      );
    }
  }

  render() {
    const dynamicDeleteColumns = this.state.deletecolumns.map((col) => {
      return (
        <Column
          key={col.body}
          body={col.body}
          icon={col.icon}
          label={col.label}
          className={col.className}
          header={col.header}
        />
      );
    });

    const dynamicDownloadColumns = this.state.downloadcolumns.map((col) => {
      return (
        <Column
          key={col.body}
          body={col.body}
          icon={col.icon}
          label={col.label}
          className={col.className}
          header={col.header}
        />
      );
    });

    const destinatariEmail = 
    <div>
      <br/>
      <label>Destinatari Email</label>
      <GridContainer>
      {this.state.destMail?.map(el => 
          <GridItem xs={12} sm={12} md={4} style={{ marginTop: "-1vh" }}>
            <CustomInput id={el} formControlProps={{fullWidth: true}} inputProps={{disabled: true, value:el}}/>
          </GridItem>
      )}
      </GridContainer>
    </div>;

    if (this.state.RowItem === null)
      return (
        <>
          {" "}
          <LoaderSpinner fullHeight={false} />{" "}
        </>
      );
    else
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="borseinfo">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.state.ModificaTitle} {this.props.title}
                  </h4>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Annulla"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.props.onAnnullaClick}
                    >
                      <UndoIcon />
                    </Button>
                  </Tooltip>

                  {this.props.children}
                </CardHeader>

                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.Id &&
                          this.state.fieldValidations.Id === "success"
                        }
                        error={
                          this.state.fieldValidations.Id &&
                          this.state.fieldValidations.Id === "error"
                        }
                        labelText="ID"
                        id="id-disabled"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          onChange: (event) =>
                            this.HandleChange("id", event.target.value),
                          value: this.state.RowItem.id || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} className="select">
                      <CustomSelect
                        labelText="Tipo Protocollo"
                        success={
                          this.state.fieldValidations.tipo &&
                          this.state.fieldValidations.tipo === "success"
                        }
                        error={
                          this.state.fieldValidations.tipo &&
                          this.state.fieldValidations.tipo === "error"
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.props.dataItem?.id || this.state.disableAll,
                          onChange: (e) =>
                            this.HandleDropDownChange("tipo", e.target.value),
                          value: this.state.RowItem.tipo || ""
                        }}
                      >
                        {this.state.protocolTypes.map((protocolType) => (
                          <MenuItem
                            value={protocolType.id}
                            key={protocolType.id}
                          >
                            {protocolType.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText="Anno"
                        id="anno"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.anno || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText="Prot."
                        id="Prot"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.prot || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} className="select">
                      <CustomSelect
                        success={
                          this.state.fieldValidations.tipoinvio &&
                          this.state.fieldValidations.tipoinvio === "success"
                        }
                        error={
                          this.state.fieldValidations.tipoinvio &&
                          this.state.fieldValidations.tipoinvio === "error"
                        }
                        labelText="Tipo Invio"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disableAll, // this.props.dataItem?.id || 
                          onChange: (e) =>
                            this.HandleDropDownChange(
                              "tipoinvio",
                              e.target.value
                            ),
                            value: parseInt(this.state.RowItem.tipoinvio)
                        }}
                      >
                        {this.state.sendTypes.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.id} - {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Data Protocollo"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.data || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8} className="select">
                      <CustomSelect
                        labelText="Causale"
                        success={
                          this.state.fieldValidations.causale &&
                          this.state.fieldValidations.causale === "success"
                        }
                        error={
                          this.state.fieldValidations.causale &&
                          this.state.fieldValidations.causale === "error"
                        }
                        id="caus"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disableAll,
                          onChange: (e) =>
                            this.HandleDropDownChange(
                              "causale",
                              e.target.value
                            ),
                          value: this.state.RowItem.causale || ""
                        }}
                      >
                        {this.state.causaleList.map((causale) => (
                          <MenuItem
                            value={causale?.codice}
                            key={causale?.codice}
                          >
                            {causale?.codice} - {causale.descrizione}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                    {this.state.RowItem.tipoinvio === 1 && (
                      <GridItem xs={12} sm={12} md={4}>
                        <p
                          className="calendarParagraph"
                          style={{
                            color:
                              this.state.fieldValidations["dtlett"] === "error"
                                ? "#f44336"
                                : "#565656"
                          }}
                        >
                          <small>Data Lettera</small>
                        </p>
                        <Calendar
                          className="calendarCustomedForMUI"
                          view="date"
                          id="dataconst"
                          value={this.state.RowItem.dtlett || ""}
                          dateFormat="dd/mm/yy"
                          monthNavigator
                          yearNavigator
                          yearRange="1900:2999"
                          onChange={(e) => {
                            this.HandleChange(
                              "dtlett",
                              moment(e.target.value).format(dateTimeFmt)
                            );
                            this.setState({ isLetterDateChanged: true });
                          }}
                        />
                      </GridItem>
                    )}
                  </GridContainer>
                  
                  {this.state.destMail && destinatariEmail}
                  
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Oggetto"
                        id="Oggetto"
                        success={
                          this.state.fieldValidations.oggetto &&
                          this.state.fieldValidations.oggetto === "success"
                        }
                        error={
                          this.state.fieldValidations.oggetto &&
                          this.state.fieldValidations.oggetto === "error"
                        }
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disableAll,
                          onChange: (event) =>
                            this.SetText("oggetto", event.target.value),
                          value: this.state.RowItem.oggetto || ""
                        }}
                      />
                    </GridItem>
                    {this.state.ModificaTitle !== "Nuovo" && this.state.RowItem?.tipo === 2 
                      && this.state.RowItem?.tipoinvio === 2 && (
                        <GridItem xs={12} sm={12} md={7} style = {{marginTop : "1vh"}}>
                          <label htmlFor="testoMail">Testo Email</label>
                          <InputTextarea id="testoMail"
                            disabled={true}
                            autoResize
                            value={this.state.RowItem?.testoMail || ""}                            
                          />
                        </GridItem>
                      )}
                  </GridContainer>

                
                  <GridContainer>
                  {!this.state.disableAll &&(
                    <GridItem xs={12} sm={12} md={6} className="upload mt-3">
                      <Card>
                        <CardHeader color="info">
                          <h4
                            style={{
                              lineHeight: "1",
                              display: "inline",
                              verticalAlign: "bottom"
                            }}
                          >
                            Selezione Allegato
                          </h4>
                        </CardHeader>
                        <CardBody>
                          <FileUpload
                            url=""
                            name="attachs"
                            customUpload
                            uploadLabel={"Aggiungi"}
                            uploadHandler={this.onUpload}
                            onRemove={this.removeFile}
                            accept=".txt,.pdf,.csv,.xlsx,.docx,.doc"
                            maxFileSize={100_000_000}
                            onClear={() => this.setState({ files: [] })}
                            emptyTemplate={
                              <p className="p-m-0">Seleziona file</p>
                            }
                          />
                        </CardBody>
                      </Card>
                    </GridItem>
                  )}
                  {this.state.RowItem.tipo === 2 &&
                    this.state.RowItem.tipoinvio === 1 && (
                      <GridItem xs={12} sm={12} md={6} className="mt-3">
                        <TestoLettera
                          text={this.state.emailText}
                          setEmailText={(value) => {
                            this.SetText("testomail", value);
                            this.setState({emailText: value});
                          }}
                        />
                      </GridItem>
                    )}
                    {this.state.RowItem.tipo === 2 &&
                      this.state.RowItem.tipoinvio === 2 &&
                      this.state.ModificaTitle === "Nuovo" && (
                        <GridItem xs={12} sm={12} md={6} className="mt-3">
                          <TestoEmail
                            accounts={StaticLists.Corrisp_Account_ebin()}
                            setAccount={(value) => {
                              this.setState({ senderEmail: value });
                            }}
                            selectedAccount={this.state.senderEmail}
                            text={this.state.RowItem.testomail}
                            setEmailText={(value) => {
                                this.SetText("testomail", value);
                                this.setState({emailText: value});
                              }
                            }
                            altroDest={this.state.otherRecipient}
                            setAltroDest={(value) => {
                              this.setState({
                                otherRecipient: value
                              });
                            }}
                            companyFreshman={this.state.RowItem?.azienda}
                            recipientEmail={this.state.recipientEmail}
                            setRecipientEmail={this.handleRcipientEmail}
                            userEmails={this.state.userEmails}
                          />
                        </GridItem>
                      )}
                  </GridContainer>
                

                  {this.state.ModificaTitle !== "Nuovo" && (
                    <GridContainer style={{ marginTop: "20px" }}>
                      <DataTable
                        emptyMessage=""
                        className="p-datatable-striped p-datatable-sm table"
                        value={this.state.attachments?.result}
                        dataKey="id"
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                        rows={10}
                        loading={this.state.loadingGrid}
                        paginatorLeft={this.paginatorLeft}
                        paginatorRight={this.paginatorRight}
                        selection={this.state.selectedGrid}
                        scrollable
                        style={{ width: "100%" }}
                        onSelectionChange={this.handleTableClick}
                        selectionMode="single"
                      >
                        <Column
                          field="path"
                          header="Allegati"
                          sortable={true}
                          filter={this.state.filterGrid}
                          filterPlaceholder=""
                        />
                        {this.state.visibleItems &&  !this.state.disableAll && dynamicDeleteColumns}
                        {this.state.visibleItems && dynamicDownloadColumns}
                      </DataTable>
                    </GridContainer>
                  )}
                </CardBody>
                {!this.state.disableAll && (    
                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    isValidForm={this.isValidForm}
                    text={"SALVA"}
                  />
                </CardFooter>
                )}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}
