import React, { Component } from "react";
import clone from "clone";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import LoaderSpinner from "../../Layout/LoaderSpinner.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import { Calendar } from "primereact/calendar";
import UndoIcon from "@material-ui/icons/Undo";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import MenuItem from "@material-ui/core/MenuItem";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Close from "@material-ui/icons/Close";
import { confirmPopup } from "primereact/confirmpopup";
import { FileUpload } from "primereact/fileupload";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import helpers from "helpers/Helpers";
import AziendeAPI from "API/AziendeAPI";
import StaticLists from "helpers/StaticLists";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import { dateTimeFmt } from "helpers/AppConstants";
import TipoInvioAPI from "API/TipoInvioAPI.js";
import SedeComuneProvAPI from "API/SedeComuneProvAPI.js";
import { CorrispondenzaText } from "./CorrispondenzaText.js";
import { AutoComplete } from "primereact/autocomplete";
import { FormControlLabel, FormGroup, FormLabel } from "@material-ui/core";
import { Checkbox } from "primereact/checkbox";
import { TestoLettera } from "./TestoLettera.component";
import { InputTextarea } from "primereact/inputtextarea";

export class ModificaCorrispondenzaXVersamenti extends Component {
  state = {
    RowItem: null,
    ModificaTitle: "Nuovo",
    groupClass: "",
    deletecolumns: [],
    downloadcolumns: [],
    tipoinvioList: 0,
    Protocollo_List: [],
    Causale_List: [],
    W350_List: [],
    W300_List: [],
    Amm_List: [],
    Buchi_List: [],
    Cust_List: [],
    Puli_List: [],
    selectedTipo: 2,
    selectedW350: null,
    selectedW300: null,
    selectedAmm: null,
    selectedBuchi: null,
    selectedCust: null,
    selectedPuli: null,
    selectedProt: null,
    selectedCaus: null,
    isVisible: false,
    loadingGrid: false,
    files: [],
    isValid: true,
    altroDest: "",

    INPS_List: [],
    selectedSap: null,
    selectedINPS: null,

    senderEmail: "",
    emailText: "",
    showEmailText: false,
    showLetterText: false,
    showAdm: false,
    showCons: false,
    showDip: false,
    lastInsertedProt: "",

    aziendasList: [],
    aziendaFilter: "",

    cons: null,
    dips: null,
    adms: null,
    selectedAdm: null,
    selectedCons: null,
    selectedDip: null,
    destMail: null,

    //VALIDATIONS
    fieldValidations: {},
    validationRules: {
      RagioneSociale: "",
      Matricola: ""
    }
  };

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.handleSenderEmail = this.handleSenderEmail.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.DeleteAttachment = this.DeleteAttachment.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.DownloadAttachmment = this.DownloadAttachmment.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.handleTextEmail = this.handleTextEmail.bind(this);
    this.searchMatricola = this.searchMatricola.bind(this);
    this.monthNavigatorTemplate = this.monthNavigatorTemplate.bind(this);
    this.yearNavigatorTemplate = this.yearNavigatorTemplate.bind(this);
    this.setAziendasFields = this.setAziendasFields.bind(this);
    this.handleChangeTipoInvio = this.handleChangeTipoInvio.bind(this);
    this.handleChangeCausale = this.handleChangeCausale.bind(this);
    this.canSendEmail = this.canSendEmail.bind(this);
    this.refreshAttachmentTable = this.refreshAttachmentTable.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.buildOggetto = this.buildOggetto.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.newProto = React.createRef();
  }

  async handleTableClick(rowData) {
    const _rowData = rowData?.value;
    const paths = _rowData?.path?.split(".");
    const extension = paths[paths.length - 1];
    if (extension === "pdf") {
      const _data = _rowData.path?.split(".");
      this.props.ufficio === "EBINPROF" ? 
      await CorrispondenzaAPI.openPdfEbin(_rowData?.id, _data[_data.length - 1]) :
      await CorrispondenzaAPI.openPdf(_rowData?.id, _data[_data.length - 1]);
    }
  }

  monthNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }

  yearNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }

  async refreshAttachmentTable() {
    this.setState({ loadingGrid: true }, async () =>
      this.setState(
        {
          attachments:
            await CorrispondenzaAPI.GetCorrispondenzaPathAttachFromUfficio(
              this.props.ufficio,
              this.props.dataItem.id
            )
        },
        () => this.setState({ loadingGrid: false })
      )
    );
  }

  async onUpload(event) {
    this.setState({ files: event.files });
    this.props.addAlert.current.addAlert(
      "success",
      "File pronti al caricamento"
    );
  }

  sedeInpsTemplate(sede) {
    return <div>{sede.sap ? `${sede.sap} - ${sede.descrizione}` : ``}</div>;
  }

  sedeInpsValueTemplate(sede, props) {
    if (sede) return `${sede.sap} - ${sede.descrizione}`;
  }

  filterINPSBySap(sap) {
    this.setState({
      selectedINPS: this.state.INPS_List.find((sede) => sede.sap === sap)
    });
  }

  deleteHandler(event, rowData) {
    event.preventDefault();
    event.stopPropagation();
    this.onDeleteClick(event, rowData?.id);
  }

  DeleteAttachment(rowData) {
    return (
      <Button
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData?.id}
        onClick={(event) => this.deleteHandler(event, rowData)}
      >
        <Close className="icon" />
      </Button>
    );
  }
  DownloadAttachmment(rowData) {
    const paths = rowData?.path?.split(".");
    const extension = paths[paths.length - 1];
    return (
      <Button
        round
        color="primary"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData?.id}
        onClick={(event) => {
          this.onDownloadClick(event, rowData?.id, extension);
        }}
      >
        <GetAppRoundedIcon className="icon" />
      </Button>
    );
  }

  async handleDeleteAttachment(id) {
    const response = this.props.ufficio === "EBINPROF" ? 
      await CorrispondenzaAPI.DeleteCorrispondenzaEbinAttachment(id) :
      await CorrispondenzaAPI.DeleteCorrispondenzaAttachment(id);
      if (response && response.result) {
      this.props.addAlert.current.addAlert("success", "Allegato eliminato");
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "Eliminazione non riuscita"
      );
    }
    await this.refreshAttachmentTable();
  }

  async handleDownloadAttachment(id, extension) {
    this.props.ufficio === "EBINPROF"
      ? await CorrispondenzaAPI.downloadCorrespondenceAttachmentEbin(id, extension)
      : await CorrispondenzaAPI.DownloadCorrispondenzaAttachment(id, extension);
    await this.refreshAttachmentTable();
  }

  onDeleteClick(event, id) {
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        this.handleDeleteAttachment(id);
      },
      reject: () => {}
    });
  }

  onDownloadClick(event, id, extension) {
    event.preventDefault();
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi scaricarlo?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        this.handleDownloadAttachment(id, extension);
      },
      reject: () => {}
    });
  }

  isValidForm = async () => {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);

    this.setState({
      fieldValidations: fieldValidations
    });

    return true;
  };

  HandleChange(field, newValue) {
    var newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;
    this.setState({ RowItem: newRowItem });
  }

  HandleSaveClick = async () => {
    let corr = this.state.RowItem;

    corr.tipoinvio = this.state.selectedTipo;
    
    if (!corr.data) {
      corr.data = moment(new Date()).format(dateTimeFmt);
    } else if (corr.data instanceof Date) {
      corr.data = moment(corr.data).format(dateTimeFmt);
    }

    if (this.isValidForm()) {
      let files = [...this.state.files];
      if (this.state.emailText) {
        corr.testomail = this.state.emailText;
      }
      this.state.altroDest && (corr.altroDest = this.state.altroDest);
      const isEdit = this.state.ModificaTitle === "Modifica";
      return await this.props.onSaveClick(
        corr,
        files,
        isEdit,
        this.state.selectedAdm?.email,
        this.state.selectedCons?.email,
        this.state.selectedDip?.email,
        this.props.azienda?.email,
        this.state?.senderEmail
      );
    }
  };

  removeFile(event) {
    if (this.state.files) {
      const files = this.state.files.filter((file) => file !== event.file);
      this.setState({ files: files });
    }
  }

  async componentDidUpdate(prevProp, prevState, snapshot) {
    if (prevProp.dataItem.id !== this.props.dataItem.id) {
      this.setState({ loadingGrid: true });
      const isChanging = this.props.dataItem.id > 0;
      this.setState({
        ModificaTitle: isChanging ? "Modifica" : "Inserisci",
        RowItem: {...this.props.dataItem},
        selectedCaus: this.props.dataItem.causale?.codice,
        selectedTipo: this.props.dataItem.tipoinvio?.id,
        attachments: isChanging
          ? await CorrispondenzaAPI.GetCorrispondenzaPathAttachFromUfficio(
              this.props.ufficio,
              this.props.dataItem.id
            )
          : null
      });
      const type = this.props.type;
      const sendType = this.props.dataItem?.tipoinvio?.id;
      const disableAll = this.state.RowItem?.tipo?.toUpperCase() === "USCITA" && (sendType === 2 || sendType == 3); //Uscita
      this.setState({disableAll})
      
      this.setState({
        showLetterText: this.state.RowItem.tipo?.toUpperCase() === "USCITA" && sendType === 1
      });

      if (isChanging) {
        const selectedCons = this.props.dataItem.cfcons
          ? this.filterPersonByCF(
              this.state.cons,
              this.props.dataItem.cfcons
            )?.pop()
          : null;
        const selectedAdm = this.props.dataItem.cfamm
          ? this.filterPersonByCF(
              this.state.adms,
              this.props.dataItem.cfamm
            )?.pop()
          : null;
        const selectedDip = this.props.dataItem.cfdip
          ? this.filterPersonByCF(
              this.state.dips,
              this.props.dataItem.cfdip
            )?.pop()
          : null;

        this.setState({
          showAdm: this.props.dataItem.cfamm !== null,
          showCons: this.props.dataItem.cfcons !== null,
          showDip: this.props.dataItem.cfdip !== null,
          selectedDip: selectedDip,
          selectedAdm: selectedAdm,
          selectedCons: selectedCons
        });
        this.setState({emailText: this.state.RowItem?.testoMail});
        if(isChanging){
          this.setState({
            destMail: this.props.dataItem?.destmail?.split(",")
          });
        }
      }
      this.setState({ loadingGrid: false });
    }
  }

  filterPersonByCF(people, cf) {
    return people.filter((p) => p.cf.toUpperCase() === cf.toUpperCase());
  }

  canSendEmail() {
    return (
      (this.props.azienda.email ||
        this.state.selectedAdm?.email ||
        this.state.selectedCons?.email ||
        this.state.selectedDip?.email) !== undefined
    );
  }

  handleChangeTipoInvio(e) {
    const tipoObj = this.state.tipoinvioList.find(
      (item) => item.id === e.target.value
    );
    const isEmail = tipoObj.tipoinvio?.trim().toLowerCase() === "email";
    if (isEmail && !this.canSendEmail()) {
      this.props.addAlert.current.addAlert(
        "warning",
        "L'azienda selezionata non ha un indirizzo email dove spedire"
      );
    }
    this.HandleChange("tipoinvio", e.target.value);
    if (!isEmail) this.HandleChange("mittmail", null);
    this.setState({
      selectedTipo: e.target.value,
      showEmailText: isEmail
    });
    this.setState({
      showLetterText: !isEmail
    });
    this.setState({
      RowItem: {...this.state.RowItem, cfamm:null, cfdip:null, cfcons:null, dtlett: null},
      selectedAdm: null,
      selectedCons: null,
      selectedDip: null,
      showAdm: false,
      showCons: false,
      showDip: false
    });
  }

  buildOggetto(causObj) {
    const matr = this.props.azienda?.matricola;
    const name = this.props.azienda.denominazione;

    const causSub = causObj.oggetto
      .replace("(nome ragione sociale)", name)
      .replace("(matricola INPS)", matr);

    return `${causSub}`;
  }

  handleChangeCausale(e) {
    this.HandleChange("causale", e.target.value);
    const causObj = this.state.Causale_List.find(
      (item) => item.codice === e.target.value
    );
    if (
      causObj &&
      causObj.oggetto &&
      this.state.RowItem.tipo?.toUpperCase() === "USCITA"
    )
      this.HandleChange("oggetto", this.buildOggetto(causObj));

    this.setState({
      selectedCaus: e.target.value,
      emailText:
        this.props.ufficio !== "EBINPROF"
          ? `Buongiorno,\n ${causObj.testomail || ""}\n--\nFondo Coasco`
          : ""
    });
  }

  async componentDidMount() {

    const type = this.props.type;
    const sendType = this.props.dataItem?.tipoinvio?.id;
    this.setState({ loadingGrid: true });

    const allProvince = await SedeComuneProvAPI.GetAllProvincieProvincie();
    const allSedi = await SedeComuneProvAPI.GetAllSede();

    const isChanging = this.props.dataItem.id > 0;
    this.setState({
      deletecolumns: [
        {
          header: "",
          body: this.DeleteAttachment,
          icon: "pi pi-times",
          label: "Delete",
          className: "p-button-danger p-button-outlined colDelete"
        }
      ],
      downloadcolumns: [
        {
          header: "",
          body: this.DownloadAttachmment,
          icon: "pi pi-times",
          label: "Download",
          className: "p-button-danger p-button-outlined colDownload"
        }
      ],
      tipoinvioList: await TipoInvioAPI.GetAll(),
      RowItem: isChanging ? {...this.props.dataItem} : {...this.props.dataItem, tipoinvio: this.state.selectedTipo},

      // lastInsertedProt: lastProt,
      selectedTipo: isChanging ? this.props.dataItem.tipoinvio.id : 2,
      selectedCaus: isChanging ? this.props.dataItem.causale.codice : "",
      aziendaFilter: this.props.azienda?.matricola,
      azienda: this.props.azienda,
      provices: allProvince,
      Protocollo_List: await StaticLists.Corrisp_TipoProtocollo(),
      Causale_List: await CorrispondenzaAPI.getCausaliFromTipoUfficio({
        descrizione_ufficio: this.props.ufficio
      }),
      W350_List: await StaticLists.Corrisp_W350(),
      W300_List: await StaticLists.Corrisp_W300(),
      Amm_List: await StaticLists.Corrisp_Amm(),
      INPS_List: allSedi,
      isVisible: isChanging,
      Buchi_List: await StaticLists.Corrisp_Buchi(),
      Cust_List: await StaticLists.Corrisp_Cust(),
      Puli_List: await StaticLists.Corrisp_Puli(),
      ModificaTitle: isChanging ? "Modifica" : "Nuovo",
      groupClass: isChanging ? "" : "hide",

      attachments: isChanging
        ? await CorrispondenzaAPI.GetCorrispondenzaPathAttachFromUfficio(
            this.props.ufficio,
            this.props.dataItem.id
          )
        : null,

      cons: this.props.people.cons,
      adms: this.props.people.adms,
      dips: this.props.people.dips,

      showAdm: this.props.dataItem.cfamm !== null,
      showCons: this.props.dataItem.cfcons !== null,
      showDip: this.props.dataItem.cfdip !== null
    });

    try{
      if(isChanging){
        const selectedAdministrator = this.state.adms.find(
          (adm) => adm.cf === this.props.dataItem.cfamm
        );
        const selectedConsultant = this.state.cons.find(
          (cons) => cons.cf === this.props.dataItem.cfcons
        );
        const selectedEmployee= this.state.dips.find(
          (dip) => dip.cf === this.props.dataItem.cfdip
        );
    
        this.setState({
          selectedAdm: selectedAdministrator,
          selectedCons: selectedConsultant,
          selectedDip: selectedEmployee
        })
      }
    } catch(err){}

    const disableAll = this.state.RowItem?.tipo?.toUpperCase() === "USCITA" && (sendType === 2 || sendType === 3); //Uscita
    this.setState({disableAll})
    
    

    this.setState({
      showEmailText: this.state.RowItem.tipo?.toUpperCase() === "USCITA" &&  this.state.selectedTipo === 2,
      showLetterText: this.state.RowItem.tipo?.toUpperCase() === "USCITA" && this.state.selectedTipo === 1,
      emailText: this.state.RowItem?.testoMail
    });

    if(isChanging){
      this.setState({
        destMail: this.props.dataItem?.destmail?.split(",")
      });
    }

    this.setState({
      loadingGrid: false
    });
   
  }

  handleSenderEmail(account) {
    this.HandleChange("mittmail", account);
    this.setState({
      senderEmail: account
    });
  }

  handleTextEmail(text) {
    this.HandleChange("testomail", text);
    this.setState({
      emailText: text
    });
  }

  SetNum(field, newValue, maxLength) {
    if (newValue.toString().length <= maxLength) {
      var newRowItem = this.state.RowItem;
      newRowItem[field] = newValue;

      this.setState({ RowItem: newRowItem });
    }
  }

  async onSelectMatricola(e) {
    const azienda = await AziendeAPI.GetAziendaByMatr(e.value);
    try {
      this.setState({
        azienda: azienda,
        aziendaFilter: azienda?.matricola,
        selectedINPS: null,
        selectedSap: ""
      });
    } catch (err) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Si è verificato un errore sul server"
      );
    }
}
  

  async OnInputBlurCheck(valToCheck, type) {
    let validationRules = null;

    type === "matricola"
      ? (validationRules = {
          matricola: "EQ-10"
        })
      : (validationRules = {
          iban: "EQ-27"
        });
    let fieldValidations = helpers.ValidateForm(
      this.state.RowItem,
      validationRules
    );

    this.setState({
      fieldValidations: fieldValidations
    });

    let existentMatricola = [];
    if (
      this.state.RowItem?.matricola?.length > 0 &&
      this.props.dataItem.id === null &&
      type === "matricola"
    ) {
      existentMatricola = await AziendeAPI.GetAllMatricola(
        this.state.RowItem?.matricola
      );
      if (existentMatricola.length > 0) {
        this.props.addAlert.current.addAlert("warning", "Matricola esistente");
      }
    }
  }

  setAziendasFields(event) {
    this.setState({
      aziendaFilter: event.value
    });
    this.onSelectMatricola(event);
  }

  searchMatricola(event) {
    setTimeout(async () => {
      var list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByMatr(event.query)
          : [];
      this.setState({
        aziendasList: list
      });
    }, 250);
  }

  render() {
    const dynamicDeleteColumns = this.state.deletecolumns.map((col) => {
      return (
        <Column
          key={col.body}
          body={col.body}
          icon={col.icon}
          label={col.label}
          className={col.className}
          header={col.header}
        />
      );
    });

    const dynamicDownloadColumns = this.state.downloadcolumns.map((col) => {
      return (
        <Column
          key={col.body}
          body={col.body}
          icon={col.icon}
          label={col.label}
          className={col.className}
          header={col.header}
        />
      );
    });

    const destinatariEmail = 
    <div>
      <br/>
      <label>Destinatari Email</label>
      <GridContainer>
      {this.state.destMail?.map(el => 
          <GridItem xs={12} sm={12} md={4} style={{ marginTop: "-1vh" }}>
            <CustomInput id={el} formControlProps={{fullWidth: true}} inputProps={{disabled: true, value:el}}/>
          </GridItem>
      )}
      </GridContainer>
    </div>;

    if (this.state.RowItem === null)
      return (
        <>
          {" "}
          <LoaderSpinner fullHeight={false} />{" "}
        </>
      );
    else
      return (
        <div ref={this.newProto}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.state.ModificaTitle} Protocollo
                  </h4>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Annulla"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.props.onAnnullaClick}
                    >
                      <UndoIcon />
                    </Button>
                  </Tooltip>
                  {this.props.children}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Anno"
                        id="anno"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value:
                            this.state.RowItem.anno || new Date().getFullYear()
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="calendarParagraph label">
                        <small>Data Protocollo</small>
                      </p>
                      <Calendar
                        className="calendarCustomedForMUI"
                        id="dataprot"
                        view="date"
                        disabled={true}
                        value={
                          moment(
                            this.state.RowItem.data,
                            dateTimeFmt
                          ).toDate() || new Date()
                        }
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          this.HandleChange(
                            "data",
                            moment(e.target.value).format(dateTimeFmt)
                          )
                        }
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Protocollo"
                        id="prot"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.prot || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} className="select">
                      <CustomSelect
                        labelText="Tipo Protocollo"
                        id="prot"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.props.disable,
                          onChange: (e) => {
                            this.HandleChange("tipo", e.target.value);
                            this.setState({
                              RowItem: {...this.state.RowItem, cfamm:null, cfdip:null, cfcons:null, dtlett: null},
                              selectedAdm: null,
                              selectedCons: null,
                              selectedDip: null,
                              showAdm: false,
                              showCons: false,
                              showDip: false
                            });
                          },
                          value: this.state.RowItem.tipo.titleCase() || ""
                        }}
                      >
                        {this.state.Protocollo_List.map((item) => (
                          <MenuItem value={item.name} key={item.id}>
                            {item.id} - {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText="Id"
                        id="id"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.id || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      style={{ marginTop: "4px" }}
                      >
                      <p className="lblNoMargin label">
                        <small>Matricola *</small>
                      </p>
                      <AutoComplete
                        value={this.state.aziendaFilter}
                        suggestions={this.state.aziendasList}
                        placeholder="Cerca per Matricola"
                        completeMethod={this.searchMatricola}
                        disabled={true}
                        onChange={(e) =>
                          this.setState({ aziendaFilter: e.value })
                        }
                        className="matricolaAutocomplete"
                        type="number"
                        onSelect={(e) => this.onSelectMatricola(e)}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Ragione Sociale *"
                        id="ragione"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          onChange: (event) =>
                            this.HandleChange(
                              "denominazione",
                              event.target.value
                            ),
                          value: this.state.azienda?.denominazione || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText="SAP Matri."
                        id="sap"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          onChange: (event) =>
                            this.HandleChange("codsap", event.target.value),
                          value: this.state.azienda?.sede || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText="Provincia"
                        id="Prov"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          onChange: (event) =>
                            this.HandleChange("prov", event.target.value),
                          value: this.state.azienda?.prov || ""
                        }}
                      />
                    </GridItem>
                    {this.state.RowItem.mittmail && this.state.selectedTipo == 2 &&(
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          labelText="Mittente"
                          id="mitt"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.RowItem.mittmail || ""
                          }}
                        />
                      </GridItem>
                    )}
                  </GridContainer>

                    <GridContainer>
                      {this.state.selectedTipo == 2  && this.state.RowItem?.tipo?.toUpperCase() == "USCITA" && ( 
                      <GridItem xs={12} sm={12} md={6}>
                        <FormLabel className="label mb-0">
                          Indirizzata a
                        </FormLabel>
                        <FormGroup
                          row
                          style={{ position: "relative", left: "10px" }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={this.state.disableAll}
                                color="primary"
                                checked={this.state.showAdm}
                                onChange={(e) => {
                                  this.setState({ 
                                      showAdm: e.checked,
                                      selectedAdm: !e.checked ? null : this.state.selectedAdm
                                    });
                                    this.HandleChange("cfamm", e.checked ? this.state.RowItem?.cfamm : null);
                                    this.HandleChange("administratorEmail", !e.checked ? null : this.state.selectedAdm?.email);
                                }}
                              />
                            }
                            label="Amministratore"
                            className="labelCheckbox"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={this.state.disableAll}
                                color="primary"
                                checked={this.state.showCons}
                                onChange={(e) => {
                                  this.setState({ 
                                    showCons: e.checked,
                                    selectedCons: !e.checked ? null : this.state.selectedCons
                                  });
                                  this.HandleChange("cfcons", e.checked ? this.state.RowItem?.cfcons : null);
                                  this.HandleChange("consultantEmail", !e.checked ? null : this.state.selectedCons?.email);
                                }}
                              />
                            }
                            label="Consulente"
                            className="labelCheckbox"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                disabled={this.state.disableAll}
                                color="primary"
                                checked={this.state.showDip}
                                onChange={(e) => {
                                  this.setState({ 
                                    showDip: e.checked,
                                    selectedDip: !e.checked ? null : this.state.selectedDip 
                                  });
                                  this.HandleChange("cfdip", e.checked ? this.state.RowItem?.cfdip : null);
                                  this.HandleChange("employeeEmail", !e.checked ? null : this.state.selectedDip?.email);
                                }}
                              />
                            }
                            label="Dipendente"
                            className="labelCheckbox"
                          />
                        </FormGroup>
                      </GridItem>
                      )}
                    </GridContainer>

                  {this.state.showAdm && this.state.selectedTipo != 1 && this.state.RowItem?.tipo?.toUpperCase() == "USCITA" &&( 
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4} className="select">
                        <CustomSelect
                          labelText="Amministratore"
                          id="ammList"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: this.state.disableAll,
                            onChange: (e) => {
                              this.HandleChange(
                                "cfamm",
                                e.target.value.toUpperCase()
                              );
                              const selectedAdministrator =
                                this.state.adms.find(
                                  (adm) => adm.cf === e.target.value
                                );
                              this.HandleChange(
                                "administratorEmail",
                                selectedAdministrator?.email
                              );
                              this.setState({
                                selectedAdm: selectedAdministrator
                              });
                            },
                            value: this.state.selectedAdm?.cf|| ""
                          }}
                        >
                          {this.state.adms.map((adm) => (
                            <MenuItem value={adm.cf} key={adm.id}>
                              {adm.cf.toUpperCase()}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          id="admName"
                          labelText="Nominativo"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.selectedAdm?.nominativo || ""
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          id="admEmail"
                          labelText="Email"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.selectedAdm?.email || ""
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  )}

                  {this.state.showCons && this.state.selectedTipo != 1 && this.state.RowItem?.tipo?.toUpperCase() == "USCITA" &&(
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4} className="select">
                        <CustomSelect
                          labelText="Consulente"
                          id="consList"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: this.state.disableAll,
                            onChange: (e) => {
                              this.HandleChange(
                                "cfcons",
                                e.target.value.toUpperCase()
                              );
                              this.setState({
                                selectedCons: this.state.cons.find(
                                  (cons) => cons.cf === e.target.value
                                )
                              });
                            },
                            value: this.state.selectedCons?.cf || ""
                          }}
                        >
                          {this.state.cons.map((cons) => (
                            <MenuItem value={cons.cf} key={cons.id}>
                              {cons.cf.toUpperCase()}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          id="consName"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.selectedCons?.nominativo || ""
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          id="consEmail"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.selectedCons?.email || ""
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  )}

                  {this.state.showDip && this.state.selectedTipo != 1 && this.state.RowItem?.tipo?.toUpperCase() == "USCITA" &&(
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4} className="select">
                        <CustomSelect
                          labelText="Dipendente"
                          id="dipList"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: this.state.disableAll,
                            onChange: (e) => {
                              this.HandleChange(
                                "cfdip",
                                e.target.value.toUpperCase()
                              );
                              this.setState({
                                selectedDip: this.state.dips.find(
                                  (dip) => dip.cf === e.target.value
                                )
                              });
                            },
                            value: this.state.selectedDip?.cf || ""
                          }}
                        >
                          {this.state.dips.map((dip) => (
                            <MenuItem value={dip.cf} key={dip.id}>
                              {dip.cf.toUpperCase()}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          id="dipName"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.selectedDip?.nominativo || ""
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                          id="dipEmail"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.selectedDip?.email || ""
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  )}

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6} className="select">
                      <CustomSelect
                        labelText="Causale"
                        id="caus"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled:
                            this.state.disableAll,
                          onChange: this.handleChangeCausale,
                          value: this.state.selectedCaus || ""
                        }}
                      >
                        {this.state.Causale_List.map((item) => (
                          <MenuItem value={item.codice} key={item.codice}>
                            {item.codice + " - " + item.descrizione}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                    {this.state.selectedTipo == 1 && (
                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="calendarParagraph">
                        <small>Data Lettera</small>
                      </p>
                      <Calendar
                        className="calendarCustomedForMUI"
                        id="dataconst"
                        view="date"
                        disabled={
                          this.state.disableAll
                        }
                        value={moment(
                          this.state.RowItem.dtlett,
                          dateTimeFmt
                        ).toDate()}
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          this.HandleChange(
                            "dtlett",
                            moment(e.target.value).format(dateTimeFmt)
                          )
                        }
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                      />
                    </GridItem>
                    )}
                    <GridItem xs={12} sm={12} md={3} className="select">
                      <CustomSelect
                        labelText="Tipo Invio"
                        id="tipoinvioList"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disableAll,
                          onChange: this.handleChangeTipoInvio,
                          value: this.state.selectedTipo
                        }}
                      >
                        {this.state.tipoinvioList.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.id} - {item.tipoinvio}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>

                  {this.state.destMail && destinatariEmail}

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={10}>
                      <CustomInput
                        labelText="Oggetto"
                        id="ogg"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled:
                            this.state.disableAll,
                          onChange: (event) =>
                            this.HandleChange("oggetto", event.target.value),
                          value: this.state.RowItem.oggetto || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  {this.props.ufficio.toUpperCase() !== "EBINPROF" && (
                    <>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                          <CustomInput
                            labelText="Tabulati"
                            id="tab"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled:  this.state.disableAll ,
                              onChange: (event) =>
                                this.HandleChange("numtab", event.target.value),
                              value: this.state.RowItem.numtab || ""
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2}>
                          <CustomInput
                            labelText="Preavvisi"
                            id="pre"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled:  this.state.disableAll ,
                              onChange: (event) =>
                                this.HandleChange(
                                  "numpreav",
                                  event.target.value
                                ),
                              value: this.state.RowItem.numpreav || ""
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} className="select">
                          <CustomSelect
                            labelText="W300"
                            id="w300"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled:  this.state.disableAll ,
                              onChange: (event) =>
                                this.HandleChange("w300", event.target.value),
                              value: this.state.RowItem.w300 || ""
                            }}
                          >
                            {this.state.W300_List.map((item, i) => (
                              <MenuItem value={item.id} key={i}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} className="select">
                          <CustomSelect
                            labelText="Puli"
                            id="Puli"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled:  this.state.disableAll ,
                              onChange: (event) =>
                                this.HandleChange("puli", event.target.value),
                              value: this.state.RowItem.puli || ""
                            }}
                          >
                            {this.state.Puli_List.map((item) => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} className="select">
                          <CustomSelect
                            labelText="Amm"
                            id="Amm"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled:  this.state.disableAll ,
                              onChange: (event) =>
                                this.HandleChange("amm", event.target.value),
                              value: this.state.RowItem.amm || ""
                            }}
                          >
                            {this.state.Amm_List.map((item) => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </GridItem>
                      </GridContainer>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={2}>
                          <CustomInput
                            labelText="Fatture"
                            id="fa"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled:  this.state.disableAll ,
                              onChange: (event) =>
                                this.HandleChange("numfat", event.target.value),
                              value: this.state.RowItem.numfat || ""
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2}>
                          <CustomInput
                            labelText="Contabili"
                            id="co"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled:  this.state.disableAll ,
                              onChange: (event) =>
                                this.HandleChange(
                                  "ncontabbanc",
                                  event.target.value
                                ),
                              value: this.state.RowItem.ncontabbanc || ""
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} className="select">
                          <CustomSelect
                            labelText="W350"
                            id="W350"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: this.state.disableAll,
                              onChange: (e) =>
                                this.HandleChange("w350", e.target.value),
                              value: this.state.RowItem.w350 || ""
                            }}
                          >
                            {this.state.W350_List.map((item) => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} className="select">
                          <CustomSelect
                            labelText="Cust"
                            id="Cust"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled:  this.state.disableAll ,
                              onChange: (e) =>
                                this.HandleChange("cust", e.target.value),
                              value: this.state.RowItem.cust || ""
                            }}
                          >
                            {this.state.Cust_List.map((item) => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={2} className="select">
                          <CustomSelect
                            labelText="Pr. Buchi Contr."
                            id="Buchi"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: this.state.disableAll ,
                              onChange: (e) =>
                                this.HandleChange(
                                  "prbuchicont",
                                  e.target.value
                                ),
                              value: this.state.RowItem.prbuchicont || ""
                            }}
                          >
                            {this.state.Buchi_List.map((item) => (
                              <MenuItem value={item.id} key={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </GridItem>
                      </GridContainer>
                    </>
                  )}
                  {this.state.ModificaTitle !== "Nuovo" && this.state.RowItem?.tipo === "USCITA" 
                    && this.state.RowItem?.tipoinvio?.id === 2 && (
                      <GridContainer style={{ marginBottom: "2vh", marginTop : "2vh" }}>
                      <GridItem xs={12} sm={12} md={6}>              
                      <label htmlFor="testoMail">Testo Email</label>
                      <InputTextarea id="testoMail"
                        disabled={true}
                        autoResize
                        value={this.props.dataItem?.testoMail || ""} 
                        />                   
                      </GridItem>
                    </GridContainer>
                  )}
                  <GridContainer className="pt-3">
                    {!this.state.disableAll && (
                      <GridItem
                        xs={12}
                        sm={12}
                        md={this.state.showEmailText || this.state.showLetterText ? 6 : 12}
                        className="upload"
                      >
                        <Card>
                          <CardHeader color="info">
                            <h4
                              style={{
                                lineHeight: "1",
                                display: "inline",
                                verticalAlign: "bottom"
                              }}
                            >
                              Selezione Allegato
                            </h4>
                          </CardHeader>
                          <CardBody>
                            <FileUpload
                              url=""
                              auto={false}
                              name="attachs"
                              customUpload
                              uploadLabel={
                                this.state.ModificaTitle === "Modifica"
                                  ? "Invia"
                                  : "Aggiungi"
                              }
                              uploadHandler={this.onUpload}
                              onRemove={this.removeFile}
                              accept=".txt,.pdf,.csv,.xlsx,.docx,.doc"
                              maxFileSize={100_000_000}
                              onClear={() => this.setState({ files: [] })}
                              emptyTemplate={
                                <p className="p-m-0">Seleziona file</p>
                              }
                            />
                          </CardBody>
                        </Card>
                      </GridItem>
                    )}

                    {this.state.showEmailText && this.state.ModificaTitle !== 'Modifica' && this.state.RowItem?.tipo !== 'Entrata' && (
                      <GridItem xs={12} sm={12} md={6}>
                        <CorrispondenzaText
                          showAltroDest={true}
                          accounts={CorrispondenzaAPI.getAccountEmailFromTipoUfficio(
                            { descrizione_ufficio: this.props.ufficio }
                          )}
                          setAccount={this.handleSenderEmail}
                          selectedAccount={this.state.senderEmail}
                          text={this.state.emailText}
                          setEmailText={this.handleTextEmail}
                          altroDest={this.state.altroDest ?? ""}
                          setAltroDest={(val) =>
                            this.setState({ altroDest: val })
                          }
                        />
                      </GridItem>
                    )}
                    {this.state.showLetterText && this.state.RowItem?.tipo?.toUpperCase() !== 'ENTRATA' && (
                      <GridItem xs={12} sm={12} md={6}>
                        <TestoLettera
                          text={this.state.emailText}
                          setEmailText={this.handleTextEmail}
                        />
                      </GridItem>
                    )}
                  </GridContainer>
                  <DataTable
                    emptyMessage=""
                    className="p-datatable-striped p-datatable-sm table"
                    value={this.state.attachments?.result}
                    dataKey="id"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                    rows={10}
                    loading={this.state.loadingGrid}
                    paginatorLeft={this.paginatorLeft}
                    paginatorRight={this.paginatorRight}
                    selection={this.state.selectedGrid}
                    scrollable
                    style={{ width: "100%" }}
                    onSelectionChange={this.handleTableClick}
                    selectionMode="single"
                  >
                    <Column
                      field="path"
                      header="Allegati"
                      sortable={true}
                      filter={this.state.filterGrid}
                      filterPlaceholder=""
                    />
                    {this.props.disable && !this.state.disableAll && dynamicDeleteColumns}
                    {this.props.disable && dynamicDownloadColumns}
                  </DataTable>
                </CardBody>
                {!(this.state.ModificaTitle =="Modifica"  && this.state.disableAll) && (
                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                </CardFooter>
                )}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}
