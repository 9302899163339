import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ButtonsMUI from "@material-ui/core/Button";
import { Checkbox } from "primereact/checkbox";
import Clear from "@material-ui/icons/Clear";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Search from "@material-ui/icons/Search";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ElencoAPI from "API/ElencoAPI";
import Utils from "../../API/Utils";
import moment from "moment";

class ElencoLavoratiXStatistiche extends Component {

  state = {
    rows: [],
    filterGrid: null,
    selectedGrid: null,
    loadingGrid: false,
    checkedVersamenti: false,
    checkedTutti: false,
    checkedDipendenti: false,
    checkedConsulenti: false,
    checkedAziende: false,
    checkedRDL: false,
    checkedUniemens: false,
    checkedAnomalie: false,
    checkedAmministratori: false,
    checkedCorrispondenza: false,

    versamentiRows: [],
    dipendentiRows: [],
    consulentiRows: [],
    corrispondenzaRows: [],
    amministratoriRows: [],
    anomalieRows: [],
    uniemensRows: [],
    rdlRows: [],
    aziendeRows: []
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  async handleSearchClick() {
    this.setState({
      selectedGrid: null
    });
    await this.refreshGrid();
  }

  async handleClearClick() {
    this.setState({
      rows: [],
      selectedGrid: null,
      checkedVersamenti: false,
      checkedTutti: false,
      checkedDipendenti: false,
      checkedConsulenti: false,
      checkedAziende: false,
      checkedRDL: false,
      checkedUniemens: false,
      checkedAnomalie: false,
      checkedAmministratori: false,
      checkedCorrispondenza: false,

      versamentiRows: [],
      dipendentiRows: [],
      consulentiRows: [],
      corrispondenzaRows: [],
      amministratoriRows: [],
      anomalieRows: [],
      uniemensRows: [],
      rdlRows: [],
      aziendeRows: []
    });
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  HandleDropDownChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  async HandleChange(e, val) {
    this.setState({
      [e]: val
    });

    if (e === "checkedTutti") {
      if (val === true) {
        this.setState({
          checkedVersamenti: true,
          checkedDipendenti: true,
          selectedGrid: null,
          checkedConsulenti: true,
          checkedAziende: true,
          checkedRDL: true,
          checkedUniemens: true,
          checkedAnomalie: true,
          checkedAmministratori: true,
          checkedCorrispondenza: true
        });
      } else {
        this.setState({
          checkedVersamenti: false,
          checkedDipendenti: false,
          checkedConsulenti: false,
          selectedGrid: null,
          checkedAziende: false,
          checkedRDL: false,
          checkedUniemens: false,
          checkedAnomalie: false,
          checkedAmministratori: false,
          checkedCorrispondenza: false
        });
      }
    }
  }

  async refreshGrid() {

    let rows = [];
    this.setState({loadingGrid: true});

    if (this.state.checkedVersamenti) {
      this.setState({
        versamentiRows: await ElencoAPI.GetElencoMovimentazioni("Versamenti")
      });
    } else
      this.setState({
        versamentiRows: []
      });

    if (this.state.checkedDipendenti) {
      this.setState({
        dipendentiRows: await ElencoAPI.GetElencoMovimentazioni("Dipendenti")
      });
    } else
      this.setState({
        dipendentiRows: []
      });

    if (this.state.checkedConsulenti) {
      this.setState({
        consulentiRows: await ElencoAPI.GetElencoMovimentazioni("Consulenti")
      });
    } else
      this.setState({
        consulentiRows: []
      });

    if (this.state.checkedAziende) {
      this.setState({
        aziendeRows: await ElencoAPI.GetElencoMovimentazioni("Aziende")
      });
    } else
      this.setState({
        aziendeRows: []
      });

    if (this.state.checkedRDL) {
      this.setState({
        rdlRows: await ElencoAPI.GetElencoMovimentazioni("RDL")
      });
    } else
      this.setState({
        rdlRows: []
      });

    if (this.state.checkedUniemens) {
      this.setState({
        uniemensRows: await ElencoAPI.GetElencoMovimentazioni("Uniemens")
      });
    } else
      this.setState({
        uniemensRows: []
      });

    if (this.state.checkedAnomalie) {
      this.setState({
        anomalieRows: await ElencoAPI.GetElencoMovimentazioni("Anomalie")
      });
    } else
      this.setState({
        anomalieRows: []
      });

    if (this.state.checkedAmministratori) {
      this.setState({
        amministratoriRows: await ElencoAPI.GetElencoMovimentazioni(
          "Amministratori"
        )
      });
    } else
      this.setState({
        amministratoriRows: []
      });

    if (this.state.checkedCorrispondenza) {
      this.setState({
        corrispondenzaRows: await ElencoAPI.GetElencoMovimentazioni(
          "Corrispondenza"
        )
      });
    } else
      this.setState({
        corrispondenzaRows: []
      });

    rows = rows.concat(
      this.state.versamentiRows,
      this.state.dipendentiRows,
      this.state.consulentiRows,
      this.state.corrispondenzaRows,
      this.state.amministratoriRows,
      this.state.anomalieRows,
      this.state.uniemensRows,
      this.state.rdlRows,
      this.state.aziendeRows
    );

    this.setState({
      rows: rows
    }, () => {
      this.setState({
        loadingGrid: false
      });
    });
  }

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: e.value
    });
  };

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4
                  style={{
                    lineHeight: "1",
                    verticalAlign: "bottom"
                  }}
                >
                  Elenco Movimentazioni Manuali
                </h4>
              </CardHeader>
              <CardBody
                style={{
                  paddingTop: "1px",
                  paddingBottom: "1px"
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={9} style={{ marginTop: "18px" }}
                  >
                    <FormControlLabel
                      style={{
                        margin: "0px",
                        marginRight: "8px"
                      }}
                      control={
                        <Checkbox
                          className="statistichecheckbox"
                          color="primary"
                          checked={this.state.checkedTutti}
                          onChange={(e) =>
                            this.HandleChange("checkedTutti", e.target.checked)
                          }
                        />
                      }
                      label="&nbsp;Tutti"
                      className="labelCheckbox statistichelabel"
                    />

                    <FormControlLabel
                      style={{
                        margin: "0px",
                        marginRight: "8px"
                      }}
                      control={
                        <Checkbox
                          className="statistichecheckbox"
                          color="primary"
                          checked={this.state.checkedVersamenti}
                          onChange={(e) =>
                            this.HandleChange(
                              "checkedVersamenti",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="&nbsp;Versamenti"
                      className="labelCheckbox statistichelabel"
                    />

                    <FormControlLabel
                      style={{
                        margin: "0px",
                        marginRight: "8px"
                      }}
                      control={
                        <Checkbox
                          className="statistichecheckbox"
                          color="primary"
                          checked={this.state.checkedDipendenti}
                          onChange={(e) =>
                            this.HandleChange(
                              "checkedDipendenti",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="&nbsp;Dipendenti"
                      className="labelCheckbox statistichelabel"
                    />

                    <FormControlLabel
                      style={{
                        margin: "0px",
                        marginRight: "8px"
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          className="statistichecheckbox"
                          checked={this.state.checkedCorrispondenza}
                          onChange={(e) =>
                            this.HandleChange(
                              "checkedCorrispondenza",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="&nbsp;Corrispondenza"
                      className="labelCheckbox statistichelabel"
                    />

                    <FormControlLabel
                      style={{
                        margin: "0px",
                        marginRight: "8px"
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          className="statistichecheckbox"
                          checked={this.state.checkedAmministratori}
                          onChange={(e) =>
                            this.HandleChange(
                              "checkedAmministratori",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="&nbsp;Amministratori"
                      className="labelCheckbox statistichelabel"
                    />

                    <FormControlLabel
                      style={{
                        margin: "0px",
                        marginRight: "8px"
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          className="statistichecheckbox"
                          checked={this.state.checkedAnomalie}
                          onChange={(e) =>
                            this.HandleChange(
                              "checkedAnomalie",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="&nbsp;Anomalie"
                      className="labelCheckbox statistichelabel"
                    />

                    <FormControlLabel
                      style={{
                        margin: "0px",
                        marginRight: "8px"
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          className="statistichecheckbox"
                          checked={this.state.checkedUniemens}
                          onChange={(e) =>
                            this.HandleChange(
                              "checkedUniemens",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="&nbsp;Uniemens"
                      className="labelCheckbox statistichelabel"
                    />

                    <FormControlLabel
                      style={{
                        margin: "0px",
                        marginRight: "8px"
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          className="statistichecheckbox"
                          checked={this.state.checkedRDL}
                          onChange={(e) =>
                            this.HandleChange("checkedRDL", e.target.checked)
                          }
                        />
                      }
                      label="&nbsp;RDL"
                      className="labelCheckbox statistichelabel"
                    />

                    <FormControlLabel
                      style={{
                        margin: "0px",
                        marginRight: "8px"
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          className="statistichecheckbox"
                          checked={this.state.checkedAziende}
                          onChange={(e) =>
                            this.HandleChange(
                              "checkedAziende",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="&nbsp;Aziende"
                      className="labelCheckbox statistichelabel"
                    />

                    <FormControlLabel
                      style={{
                        margin: "0px",
                        marginRight: "8px"
                      }}
                      control={
                        <Checkbox
                          color="primary"
                          className="statistichecheckbox"
                          checked={this.state.checkedConsulenti}
                          onChange={(e) =>
                            this.HandleChange(
                              "checkedConsulenti",
                              e.target.checked
                            )
                          }
                        />
                      }
                      label="&nbsp;Consulenti"
                      className="labelCheckbox statistichelabel"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} style={{ marginTop: "10px" }}>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Cerca"
                    placement="top"
                    style={{ marginTop: "4px" }}
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleSearchClick}
                    >
                      <Search />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    arrow
                    style={{ marginLeft: "4px", marginTop: "4px" }}
                    id="tooltip-top"
                    title="Reimposta"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleClearClick}
                    >
                      <Clear />
                    </Button>
                  </Tooltip>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Risultati
                </h4>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  size="small"
                  aria-label="contained primary button group"
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px"
                  }}
                >
                  <ButtonsMUI onClick={() => Utils.ExportExcel(this.state.rows)}>Export</ButtonsMUI>
                </ButtonGroup>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="mese"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={5}
                  loading={this.state.loadingGrid}
                  rowsPerPageOptions={[5, 10, 15, 100]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                      field="tipo"
                      header="Tipo Record"
                      sortable={true}
                  />
                  <Column
                      field="mese"
                      header="Mese"
                      sortable={true}
                      body={row => row.mese ? <>{moment(row.mese).format('MM/YYYY')}</> : ''}
                  />
                  <Column
                      field="numero"
                      header="Numero"
                      sortable={true}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default ElencoLavoratiXStatistiche;
