import React, { Component } from "react";
import clone from "clone";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import UndoIcon from "@material-ui/icons/Undo";
import { DataTable } from "primereact/datatable";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import { Column } from "primereact/column";
import Tooltip from "@material-ui/core/Tooltip";
import CustomButtons from "components/CustomButtons/Button.js";
import AddIcon from "@material-ui/icons/Add";
import ParentelaAPI from "API/ParentelaAPI";
import helpers from "helpers/Helpers";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import { confirmPopup } from "primereact/confirmpopup";
import {rowsPerPageOptions} from "../../helpers/AppConstants";

class TipoParentela extends Component {
  state = {
    ModificaTitle: "Inserisci",
    selectedGrid: null,
    loadingGrid: false,
    filterGrid: null,
    rows: [],
    Parentela: [],
    isVisible: false,
    parentela: null,

    fieldValidations: {},
    validationRules: {
      name: ""
    }
  };
  constructor(props) {
    super(props);

    this.btnSearchRef = React.createRef();
    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.SetText = this.SetText.bind(this);
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.DeleteColumn = this.DeleteColumn.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.DeleteRecord = this.DeleteRecord.bind(this);
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  SetText(field, newValue) {
    var newRowItem = this.state.parentela;
    newRowItem[field] = newValue;

    this.setState({ parentela: newRowItem });
  }

  async onNuovoClick() {
    this.setState({
      parentela: await ParentelaAPI.NuovoTipoParentela(null),
      selectedGrid: null,
      ModificaTitle: "Inserisci",
      isVisible: true
    });
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true
    });
    this.setState({
      rows: await ParentelaAPI.GetAllTipoParentela(null)
    });
    this.setState({
      loadingGrid: false
    });
  }

  async isValidForm() {
    let RowItem = this.state.parentela;
    let validationRules = clone(this.state.validationRules);
    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);

    let isValid = Object.values(fieldValidations).every((x) => x === "success");

    this.setState({
      fieldValidations: fieldValidations
    });

    return isValid;
  }

  HandleSaveClick = async () => {
    if (await this.isValidForm()) {
      if (this.state.parentela.id) {
        await ParentelaAPI.update(this.state.parentela);
      } else await ParentelaAPI.add(this.state.parentela);
      this.refreshGrid();
      window.setTimeout(() => {
        this.onAnnullaClick();
        this.props.addAlert.current.addAlert("success", "Dati salvati.");
      }, 2200);
      return true;
    } else return;
  };

  ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  onAnnullaClick() {
    this.setState({
      selectedGrid: null,
      isVisible: false
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  async handleTableClick(e) {
    var parentela = await ParentelaAPI.GetTipoParentela(e.value.id);
    this.setState({
      parentela: {
        id: parentela.result[0].id,
        name: parentela.result[0].descr
      },
      isVisible: true,
      selectedGrid: e.value,
      ModificaTitle: "Modifica"
    });
  }

  DeleteColumn(rowData) {
    return (
      <Button
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData.Id}
        onClick={(e) => this.onDeleteClick(e, rowData)}
      >
        <Close className="icon" />
      </Button>
    );
  }

  onDeleteClick(event, rowData) {
    const id = rowData?.id;

    event.stopPropagation();

    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => this.DeleteRecord(id),
      reject: () => {}
    });
  }

  async DeleteRecord(id) {
    const res = await ParentelaAPI.deleteRelationshipType(id);
    if (res) {
      this.props.addAlert.current.addAlert(
        "success",
        "Eliminazione riuscita correttamente"
      );
      this.refreshGrid();
    } else
      this.props.addAlert.current.addAlert(
        "danger",
        "Eliminazione non riuscita"
      );
  }

  render() {
    return (
      <>
        <div ref={this.btnSearchRef} />

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Tipi di Parentela
                </h4>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borsesuccess">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Tipi di Parentela Presenti
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    marginRight: "6px",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <CustomButtons
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </CustomButtons>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10} rowsPerPageOptions={rowsPerPageOptions}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="descr"
                    header="Descrizione"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    header=""
                    body={this.DeleteColumn}
                    icon="pi pi-times"
                    label="Delete"
                    className="p-button-danger p-button-outlined colDelete"
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.isVisible && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="borseinfo">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.state.ModificaTitle} Parentela
                  </h4>

                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Annulla"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <CustomButtons
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%",
                        marginLeft: "0",
                        marginRight: "0"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.onAnnullaClick}
                    >
                      <UndoIcon />
                    </CustomButtons>
                  </Tooltip>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Id"
                        id="id"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          onChange: (event) =>
                            this.SetText("id", event.target.value),
                          value: this.state.parentela.id || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.name &&
                          this.state.fieldValidations.name === "success"
                        }
                        error={
                          this.state.fieldValidations.name &&
                          this.state.fieldValidations.name === "error"
                        }
                        labelText="Descrizione *"
                        id="descr"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (e) => this.SetText("name", e.target.value),
                          value: this.state.parentela.name || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </>
    );
  }
}
export default TipoParentela;
