import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import LoaderSpinner from "components/Layout/LoaderSpinner.js";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import CustomButtons from "components/CustomButtons/Button.js";
import { ModificaCorrispondenzaXVersamenti } from "components/Views/Corrispondenza/ModificaCorrispondenzaXVersamenti.js";
import { CorrispondenzaXVersamentiHeader } from "components/Views/Corrispondenza/CorrispondenzaXVersamentiHeader.js";
import ButtonsMUI from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import AziendeAPI from "API/AziendeAPI";
import helpers from "helpers/Helpers";
import AmministratoriAPI from "API/AmministratoriAPI";
import ConsulentiAPI from "API/ConsulentiAPI";
import DipendentiAPI from "API/DipendentiAPI";
import UtentiAPI from "API/Utenti.api";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import { AuthContext } from "providers";

const Options = [
  { id: 1, name: "Entrata" },
  { id: 2, name: "Uscita" },
  { id: 3, name: "Tutta" }
];

class CorrispondenzaXVersamenti extends React.Component {
  static contextType = AuthContext;

  state = {
    rows: [],
    selectedCorrisp: null,
    selectedGrid: null,
    loadingGrid: false,
    visibleLoader: false,
    name: "Tutta",
    disabled: true,
    radioChecked: 3,
    attachments: [],
    selectedAttach: new Set(),
    ufficio: null,

    tipo: "",
    Anno: new Date().getFullYear(),
    HeaderDate: null,

    prot: "",
    headerDate: "",
    causale: "",
    sede: "",
    provSap: "",
    sapMatricola: "",
    daMatricola: "",
    aMatricola: "",

    initialRows: [],
    azienda: null,
    people: [],
    totalRecords: 0,
    firstRowToDisplay: 0
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.templateCheckboxGrid = this.templateCheckboxGrid.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.RadioHandleChange = this.RadioHandleChange.bind(this);
    this.btnSearchRef = React.createRef();
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.HandleYearPickerChange = this.HandleYearPickerChange.bind(this);
    this.downloadFiles = this.downloadFiles.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.buildJson = this.buildJson.bind(this);
  }

  templateCheckboxGrid(rowData) {
    return (
      <div className="p-field-checkbox">
        <Checkbox
          inputId="binary"
          checked={rowData.pathattach}
          onChange={(e) => {
            e.checked
              ? this.state.selectedAttach.add(rowData.id)
              : this.state.selectedAttach.delete(rowData.id);
            let list = this.state.rows;
            let el = list.find((x) => x.id === rowData.id);
            el.pathattach = e.checked;
            this.setState({ rows: list, selectedCorrisp: null });
          }}
        />
      </div>
    );
  }

  async RadioHandleChange(e, val) {
    if (val !== null) {
      this.setState(
        {
          radioChecked: val.id,
          name: val.name,
          tipo: val.name
        },
        async () => await this.refreshGrid()
      );
    }
  }

  async getPeople(aziendaId) {
    const adms = await AmministratoriAPI.GetAmministratoriForAziendaByAziendaId(
      aziendaId
    );
    const cons = await ConsulentiAPI.GetConsulentiForAziendaByAziendaId(
      aziendaId
    );
    const dips = await DipendentiAPI.GetDipendentiForAzienda(aziendaId);

    return [cons, dips, adms];
  }

  async onNuovoClick() {
    this.setState({
      selectedCorrisp:null
    });

    setTimeout(() => {
      this.setState({
        selectedCorrisp: CorrispondenzaAPI.GetNuovoCorrispondenza(this.state?.azienda?.matricola),
        selectedGrid: null,
        disabled: false
      });
    }, 200);
  }

  onAnnullaClick() {
    this.setState({
      selectedCorrisp: null,
      disabled: false
    });
  }

  onCloseGridClick() {
    this.setState({
      selectedGrid: null,
      selectedCorrisp: null
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true
    });

    const res = await CorrispondenzaAPI.GetCorrispondenzaBy(0, 2 ** 31 - 1, {
      tipo: this.state.name,
      anno: this.state.Anno,
      prot: this.state.prot,
      data: this.state.data,
      causale: this.state.causale,
      codsap: this.state.sede,
      sapFilter: this.state.sapMatricola,
      provsap: this.state.provSap,
      fromMatr: this.state?.azienda?.matricola,
      toMatr: this.state?.azienda?.matricola
    });
    const correspondences = res?.result;
    const _correspondences = [];
    for (let i = 0; i < correspondences?.length; i++) {
      _correspondences.push({
        id: correspondences[i]?.id,
        emailText: correspondences[i]?.testoMail
      });
    }

    this.setState({
      rows: res.result,
      correspondences: _correspondences,
      loadingGrid: false
    });
  }

  handleTableClick = async (e) => {
    const attachs = await CorrispondenzaAPI.GetCorrispondenzaAttachments(
      e.value.id
    );
    const correspondence = this.state?.correspondences?.find(
      (x) => x?.id === e.value.id
    );
    const emailText = correspondence?.emailText;
    try {
      this.setState({
        attachments: attachs.result,
        selectedCorrisp: { ...e.value, emailText: emailText },
        selectedGrid: e.value,
        disabled: true
      });
    } catch (err) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Errore nel recupero degli allegati"
      );
    }
  };

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });
    try {
      const azienda = await AziendeAPI.GetAziendaById(
        this.props.match.params.aziendaId
      );
      const { profile } = this.context;
      const { username } = profile;
      const res = await UtentiAPI.getUserByUsername(username);
      const anno = localStorage.getItem("annoCorrisp");
      localStorage.removeItem("annoCorrisp");
      this.setState({
        azienda: azienda[0],
        people: await this.getPeople(azienda[0].id),
        utente: res[0].utente,
        Anno: anno,
        ufficio: res[0].descrizione_ufficio
      });
    } catch (err) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Si è verificato un errore"
      );
    }

    await this.refreshGrid();
    this.setState({
      loadingGrid: false
    });
  }

  async downloadFiles() {
    if (this.state.selectedAttach && this.state.selectedAttach.size > 0) {
      await CorrispondenzaAPI.GetExportedFiles(
        [...this.state.selectedAttach],
        "Corrispondenza Versamenti"
      );
    } else
      this.props.addAlert.current.addAlert(
        "warning",
        "Selezionare una corrispondenza prima"
      );
  }

  buildJson = (rowItem) => {
    return {
      utente: this.state.utente,
      azienda: rowItem?.azienda,
      codsap: rowItem?.codsap,
      tipo: rowItem?.tipo.toUpperCase(),
      prot: rowItem?.prot,
      anno: rowItem?.anno,
      data: rowItem?.data,
      causale: rowItem?.causale,
      note: rowItem?.note,
      dtlett: rowItem?.dtlett,
      mitt_dest: rowItem?.mitt_dest,
      oggetto: rowItem?.oggetto,
      mailtext: rowItem?.mailtext || "",
      id: rowItem?.id,
      prov: rowItem?.prov,
      numtab: isNaN(parseInt(rowItem?.numtab))
        ? null
        : parseInt(rowItem?.numtab),
      numfat: isNaN(parseInt(rowItem?.numfat))
        ? null
        : parseInt(rowItem?.numfat),
      numpreav: isNaN(parseInt(rowItem?.numpreav))
        ? null
        : parseInt(rowItem?.numpreav),
      w350: rowItem?.w350,
      w300: rowItem?.w300,
      amm: rowItem?.amm,
      ncontabbanc: isNaN(parseInt(rowItem?.ncontabbanc))
        ? null
        : parseInt(rowItem?.ncontabbanc),
      prbuchicont: rowItem?.prbuchicont,
      pathattach: rowItem?.pathattach,
      fldoppi: rowItem?.fldoppi,
      sede_inps: rowItem?.sede_inps || "",
      ninvio: rowItem?.ninvio,
      flamm: rowItem?.flamm,
      flaz: rowItem?.flaz,
      flcons: rowItem?.flcons,
      fldip: rowItem?.fldip,
      fldocele: rowItem?.fldocele,
      flmodo: rowItem?.flmodo,
      puli: rowItem?.puli,
      cust: rowItem?.cust,
      tipoinvio: rowItem?.tipoinvio,
      mittmail: rowItem?.mittmail?.toLowerCase(),
      destmail: rowItem?.destmail?.toLowerCase(),
      cfcons: rowItem?.cfcons,
      cfamm: rowItem?.cfamm,
      cfdip: rowItem?.cfdip,
      haAllegati: null,
      testomail: rowItem?.testomail || ""
    };
  };

  HandleSaveClick = async (
    RowItem,
    files,
    isEdit,
    administratorEmail,
    consultantEmail,
    employeeEmail
  ) => {
    const rowItem = RowItem;
    let recipientEmails = [];
    rowItem?.administratorEmail &&
      recipientEmails.push(rowItem?.administratorEmail);
    rowItem?.consultantEmail && recipientEmails.push(rowItem?.consultantEmail);
    rowItem?.employeeEmail && recipientEmails.push(rowItem?.employeeEmail);
    rowItem?.altroDest && recipientEmails.push(rowItem?.altroDest);
    this.state?.azienda?.email &&
      recipientEmails.push(this.state?.azienda?.email);
    administratorEmail && recipientEmails.push(administratorEmail);
    consultantEmail && recipientEmails.push(consultantEmail);
    employeeEmail && recipientEmails.push(employeeEmail);
    const object = rowItem?.oggetto;
    const data = this.buildJson(rowItem);

    if (!isEdit) {
      if (RowItem?.oggetto) {
        if (RowItem?.causale) {
          const res = await CorrispondenzaAPI.AddCorrispondenza(
            null,
            recipientEmails,
            object,
            data,
            files
          );
          if (res !== undefined && res.status === 200) {
            if (rowItem.tipoinvio === 2) {
              this.props.addAlert.current.addAlert(
                "success",
                "Numero protocollo inserito: " + res?.data?.result[0]?.prot,
                86_400_000 // 24h.
              );
              this.setState({
                selectedCorrisp: null,
                selectedGrid: null
              });
              this.onNuovoClick();
            } else {
              if (rowItem?.tipo?.toUpperCase() === 'ENTRATA') {
                const resLastProtocol =
                  await CorrispondenzaAPI.GetLastInsertedProtocol(
                    data?.anno,
                    data?.tipo
                  );
                const protocol = resLastProtocol?.result[0];
                this.props.addAlert.current.addAlert(
                  "success",
                  `Numero protocollo inserito: ${protocol}`,
                  86_400_000 // 24h.
                );
                this.setState({
                  selectedCorrisp: null,
                  selectedGrid: null
                });
                await this.onNuovoClick();
              } else {
                const ris = new Blob([res.data]);
                const url = window.URL.createObjectURL(ris);
                const a = document.createElement("a");
                a.href = url;
                a.download = "lettera.pdf";
                document.body.appendChild(a);
                a.click();
                a.remove();
                const resLastProtocol =
                  await CorrispondenzaAPI.GetLastInsertedProtocol(
                    data?.anno,
                    data?.tipo
                  );
                const protocol = resLastProtocol?.result[0];
                this.props.addAlert.current.addAlert(
                  "success",
                  `Numero protocollo inserito: ${protocol}`,
                  86_400_000 // 24h.
                );
                this.setState({
                  selectedCorrisp: null,
                  selectedGrid: null
                });
                await this.onNuovoClick();
              }
            }
          } else
            this.props.addAlert.current.addAlert(
              "danger",
              "Errore nell'inserimento"
            );
        } else
          this.props.addAlert.current.addAlert(
            "warning",
            "Inserire la causale"
          );
      } else
        this.props.addAlert.current.addAlert("warning", "Inserire l'oggetto");
    } else {
        const res = await CorrispondenzaAPI.UpdateCorrispondenza(data, files);
        if (res) {
          this.props.addAlert.current.addAlert(
            "success",
            `Protocollo aggiornato correttamente`
          );
          this.setState({
            selectedCorrisp: null,
            selectedGrid: null
          });
          await this.onNuovoClick();
        }
    }
    this.refreshGrid();
    return true;
  };

  async handleClearClick() {
    this.setState(
      {
        Anno: new Date().getFullYear(),
        HeaderDate: null
      },
      async () => await this.refreshGrid()
    );
  }

  async handleSearchClick(
    tipo,
    anno,
    data,
    prot,
    causale,
    sede,
    provSap,
    sapMatricola,
    daMatricola,
    aMatricola
  ) {
    this.setState(
      {
        tipo: tipo,
        anno: anno,
        data: data,
        prot: prot,
        causale: causale,
        sede: sede,
        provSap: provSap,
        sapMatricola: sapMatricola,
        daMatricola: daMatricola,
        aMatricola: aMatricola
      },
      async () => await this.refreshGrid()
    );
  }

  HandleYearPickerChange(e) {
    this.setState({
      Anno: e
    });
  }

  render() {
    return (
      <>
        <div ref={this.btnSearchRef} />
        <CorrispondenzaXVersamentiHeader
          {...this.props}
          disabledRadioButtons={this.state.disabled}
          Anno={this.state.Anno}
          RadioHandleChange={this.RadioHandleChange}
          OnSearch={this.handleSearchClick}
          HandleYearPickerChange={this.HandleYearPickerChange}
          HeaderDate={this.state.HeaderDate}
          OnClear={this.handleClearClick}
          HandleChange={this.HandleChange}
          radioChecked={this.state.radioChecked}
          visibleBack={true}
          lineHeight="0"
          Options={Options}
          matricola={this.state?.azienda?.matricola}
        />
        {this.state.visibleLoader && <LoaderSpinner fullHeight={true} />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className="d-inline">{this.state.name}</h4>
                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <CustomButtons
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </CustomButtons>
                </Tooltip>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  size="small"
                  aria-label="contained primary button group"
                  className="float-right"
                  style={{ verticalAlign: "center", margin: "0.5%" }}
                >
                  <ButtonsMUI onClick={this.downloadFiles}>
                    Esporta files
                  </ButtonsMUI>
                </ButtonGroup>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedCorrisp}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    header="Seleziona"
                    body={this.templateCheckboxGrid}
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                  />
                  <Column
                    field="anno"
                    header="Anno"
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                  />
                  <Column
                    field="prot"
                    header="Prot"
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                  />
                  <Column
                    field="tipo"
                    header="Tipo"
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                  />
                  <Column
                    field="data"
                    header="Data"
                    body={(rowData) =>
                      helpers.DateTemplateWithHours(rowData, "data")
                    }
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                  />
                  <Column
                    field="causale.codice"
                    header="Causale"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                  />
                  <Column
                    header="Allegati"
                    field="haAllegati"
                    style={{ width: "90px", textAlign: "center" }}
                    body={(row) => <>{row.haAllegati && <AttachFileIcon />}</>}
                  />
                  <Column
                    field="oggetto"
                    header="Oggetto"
                    headerStyle={{ width: "400px" }}
                    sortable={true}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.selectedCorrisp && (
          <ModificaCorrispondenzaXVersamenti
            {...this.props}
            dataItem={this.state.selectedCorrisp}
            onAnnullaClick={this.onAnnullaClick}
            onSaveClick={this.HandleSaveClick}
            disable={this.state.disabled}
            azienda={this.state.azienda}
            people={{
              cons: this.state.people[0],
              dips: this.state.people[1],
              adms: this.state.people[2]
            }}
            HeaderDate={this.state.HeaderDate}
            ufficio={this.state.ufficio}
          />
        )}
      </>
    );
  }
}

export default CorrispondenzaXVersamenti;
