import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import CardFooter from "components/Card/CardFooter.js";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { DataTable } from "primereact/datatable";
import CheckboxMUI from "@material-ui/core/Checkbox";
import { Checkbox } from "primereact/checkbox";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import ButtonsMUI from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import CustomButton from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import TextField from "@material-ui/core/TextField";
import DipendentiAPI from "API/DipendentiAPI";
import DocsAPI from "API/DocsAPI";
import RichiestiAPI from "API/RichiestiAPI";
import RichiedentiAPI from "API/RichiedentiAPI";
import StatoRichiestaAPI from "API/StatoRichiestaAPI";
import TipoEsitoAPI from "API/TipoEsitoAPI";
import TipoMotivoAPI from "API/TipoMotivoAPI";
import queryString from "query-string";
import { dateTimeFmt } from "helpers/AppConstants";
import moment from "moment";
import { dateFmt } from "helpers/AppConstants";
import { MenuItem } from "@material-ui/core";
import { Dropdown } from "primereact/dropdown";
import helpers from "helpers/Helpers";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";
import { confirmDialog } from "primereact/confirmdialog";
import ParentelaAPI from "../../API/ParentelaAPI";

class ModificaRichiesteXRichiedenti extends Component {
  state = {
    applicant: {},
    request: {},
    loadingPage: false,
    employee: {},
    checkedVersamenti: false,
    checkedLode: false,
    selectedStatopratica: null,
    selectedEsito: null,
    selectedMotivazione: null,
    visiblePunteggiStUniFields: false,
    visiblePunteggiStMedFields: false,
    visiblePunteggiStLaurFields: false,
    loadingGrid: false,
    tipoRifScolastico: "",
    modified: false,

    fieldValidations: {},
    validationRules: {
      Id: ""
    }
  };

  pathStudies = [
    "Triennale",
    "Magistrale Biennale",
    "Magistrale Quadriennale",
    "Magistrale 5 anni",
    "Magistrale 6 anni"
  ];

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.btnSearchRef = React.createRef();
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.acceptFunc = this.acceptFunc.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.templateCheckboxGrid = this.templateCheckboxGrid.bind(this);
    this.onRedirectHandler = this.onRedirectHandler.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.printLabel = this.printLabel.bind(this);
    this.printCard = this.printCard.bind(this);
    this.selectAllDocuments = this.documentsHandler.bind(this);
  }

  onRedirectHandler(param) {
    if (this.state.modified) {
      confirmDialog({
        message:
          "Sono state apportate delle modifiche alla richiesta, vuoi procedere SENZA salvarle?",
        header: "Modifiche non salvate",
        icon: "pi pi-exclamation-triangle",
        accept: () => this.acceptFunc(param),
        reject: () => {}
      });
    } else {
      this.acceptFunc(param);
    }
  }

  acceptFunc(param) {
    let qS = queryString.parse(this.props.history.location.search);
    if (param === "Back") {
      if (!qS.type)
        this.props.history.push(
          "/admin/richiedenti/modificainserisci/" +
            this.props.match.params.richiedentiId +
            "?typeId=" +
            this.props.match.params.richiesteId
        );
      else if (qS.type === "fromRichieste")
        this.props.history.push(
          "/admin/richieste/" +
            this.props.match.params.richiesteId +
            "?maintainSearch=1"
        );
    } else if (param === "Dip") {
      helpers.SaveSearchFilters(
        {
          richiesId: this.props.match.params.richiesteId,
          richiedId: this.props.match.params.richiedentiId
        },
        "ModificaRichiesteXRichiedenti"
      );
      this.props.history.push(
        `/admin/ModificaDipendente/${this.state.employee.id}${this.props.location.search}`
      );
    } else if (param === "Stato") {
      this.props.history.push(
        "/admin/richiedenti/verificacontributiva/" +
          this.props.match.params.richiedentiId +
          "/" +
          this.props.match.params.richiesteId +
          "?type=" +
          qS.type
      );
    } else if (param === "Corrisp") {
      if (qS.type === null || "undefined")
        this.props.history.push(
          "/admin/richieste/corrispxrichieste/" +
            this.props.match.params.richiedentiId +
            "/" +
            this.props.match.params.richiesteId
        );
      else if (qS.type === "fromRichieste")
        this.props.history.push(
          "/admin/richieste/corrispxrichieste/" +
            this.props.match.params.richiedentiId +
            "/" +
            this.props.match.params.richiesteId +
            "?type=" +
            qS.type
        );
    } else if (param === "Punt") {
      if (!qS.type)
        this.props.history.push(
          "/admin/richiedenti/punteggi/" +
            this.props.match.params.richiedentiId +
            "/" +
            this.props.match.params.richiesteId
        );
      else if (qS.type === "fromRichieste")
        this.props.history.push(
          "/admin/richiedenti/punteggi/" +
            this.props.match.params.richiedentiId +
            "/" +
            this.props.match.params.richiesteId +
            "?type=" +
            qS.type
        );
    }
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });

    const request = (
      await RichiestiAPI.GetRequestById(this.props.match.params.richiesteId)
    )?.pop();
    const employee = (await DipendentiAPI.GetByCF(request.cfDip)).result?.pop();
    const docRequest = await DocsAPI.getById(
      this.props.match.params.richiesteId
    );
    const resStatesArray = await StatoRichiestaAPI.GetAllStato();
    const resEsitTypesArray = await TipoEsitoAPI.GetAll();
    const resMotiviArray = await TipoMotivoAPI.GetAll();

    if (!request)
      this.props.addAlert.current.addAlert("danger", `Richiesta non trovata`);

    if (!employee)
      this.props.addAlert.current.addAlert(
        "danger",
        `Dipendente con cf ${request.cfDip} non trovato`
      );

    if (!docRequest) {
      this.props.addAlert.current.addAlert("danger", `Richiesta non trovata`);
    }

    this.setState(
      {
        request: {
          ...request,
          institute: request?.scuola,
          course: request?.corso,
          faculty: request?.facolta,
          address: request?.indirScuola
        },
        applicant: request?.cfRich,
        employee: employee,
        TipoBorsa: request?.idBorsa?.tipoBorsa?.descrBr,
        selectedEsito: request?.esito,
        selectedMotivazione: request?.motivo,
        selectedStatopratica: request?.statoLavor,
        documents: docRequest?.result,
        statesArray: resStatesArray,
        esitTypesArray: resEsitTypesArray,
        motiviArray: resMotiviArray,
        parentela: request?.tipoParent
          ? (await ParentelaAPI.GetTipoParentela(request.tipoParent)).result[0]
          : ""
      },
      () => {
        if (this.state.TipoBorsa !== null) {
          switch (this.state.TipoBorsa) {
            case "StUni":
            case "SUDis":
              this.setState({
                visiblePunteggiStUniFields: true,
                visiblePunteggiStMedFields: false
              });
              break;

            case "L4000":
            case "Laur":
              this.setState({
                visiblePunteggiStUniFields: true,
                visiblePunteggiStMedFields: true
              });
              break;

            case "StMSD":
            case "StMS":
              this.setState({
                visiblePunteggiStUniFields: false,
                visiblePunteggiStMedFields: true
              });
              break;
          }
        }

        this.tipoBorsa =
          this.state.TipoBorsa === "Laur" || this.state.TipoBorsa === "L4000"
            ? "Laurea"
            : this.state.TipoBorsa === "StUni"
            ? "Universitaria"
            : this.state.TipoBorsa === "StMS"
            ? "MedieSuperiori"
            : this.state.TipoBorsa === "StMSD"
            ? "MedieSuperioriDisabili"
            : this.state.TipoBorsa === "SUDis" && "UniversitariDisabili";

        this.setState({ scholarshipType: this.tipoBorsa });
        this.cfuRichiesti = this.state.request.cfuRic ?? "";
        this.cfuPercentuale = this.state.request.cfuPerc ?? "";
        this.mediaPond = this.state.request.mediaPes?.toFixed(2) ?? "";
        this.media = this.state.request.media ?? "";

        this.setState({
          loadingGrid: false
        });
      }
    );
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  handleRequestChange(field, value) {
    let newReq = this.state.request;
    newReq[field] = value;
    this.setState({ request: { ...newReq }, modified: true });
  }

  HandleDropDownChange(e, val) {
    if (e === "selectedStatopratica") {
      if (val?.idStato === 1 || val?.idStato === 2 || val?.idStato === 3)
        this.setState({
          isResultDisabled: true,
          isReasonDisabled: true,
          selectedEsito: null
        });
      else if (val?.idStato === 4)
        this.setState({
          isResultDisabled: true,
          isReasonDisabled: false,
          selectedEsito: {
            idEsito: 3,
            descr: "Escluso"
          }
        });
      else
        this.setState({
          isResultDisabled: false,
          isReasonDisabled: false,
          selectedEsito: null
        });
    }
    this.setState({
      [e]: val,
      modified: true
    });
  }

  onSaveClick() {
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  async isValidForm() {
    return this.state.request.idRichies;
  }

  HandleSaveClick = async () => {
    const request = this.state.request;
    const data = {
      cfDip: request.cfDip,
      cfRich: request.cfRich.cfRich,
      cfuCons: request.cfuCons,
      cfuPerc: request.cfuPerc,
      cfuRic: request.cfuRic,
      corso: request.course,
      dataAgg: request.dataAgg,
      dataArr: request.dataArr,
      dataIns: request.dataIns,
      dataVerifVers: request.dataVerifVers,
      disabile: request.disabile,
      esito: this.state.selectedEsito?.idEsito,
      facolta: request.faculty,
      idBorsa: request.idBorsa.idBorsa,
      idRichies: request.idRichies,
      idformando: request.idformando,
      indirScuola: request.address,
      lode: request.lode,
      media: request.media,
      mediaPes: request.mediaPes,
      motivo: this.state.selectedMotivazione?.idMotivo,
      nota: request.nota,
      noteVerifVers: request.noteVerifVers,
      orfano: request.orfano,
      privacy: request.privacy,
      protRichies: request.protRichies,
      scuola: request.institute,
      sottostato: request.sottostato,
      statoLavor: this.state.selectedStatopratica?.idStato,
      studLav: request.studLav,
      tipoParent: request.tipoParent,
      utente: request.utente,
      verifVers: request.verifVers,
      voto: request.voto,
      tesi: request.tesi,
      percorsostudi: request.percorsostudi
    };
    let richiestaObj = {};

    if (await this.isValidForm()) {
      let response;
      if (this.props.match.params.richiesteId === 0) {
        response = await RichiedentiAPI.AddRichiedenti(richiestaObj);
      } else {
        response = await RichiestiAPI.UpdateRichiesta(data);
      }

      if (response?.message?.includes("200")) {
        this.props.addAlert.current.addAlert("success", "Dati salvati.");
        this.setState({ modified: false });
      } else
        this.props.addAlert.current.addAlert("danger", "Dati NON salvati.");

      return true;
    }
  };

  disableLodeField() {}

  templateCheckboxGrid(rowData) {
    return (
      <div className="p-field-checkbox">
        <Checkbox
          inputId="binary"
          checked={rowData.presente === 1}
          onChange={async (e) => {
            this.setState({ loadingGrid: true });
            const data = {
              dataAgg: null,
              idRichiesBS: this.state.request.idRichies,
              idBs: this.state.request.idBorsa.idBorsa,
              idDoc: rowData.idDoc,
              idTipoDoc: rowData.idTipoDoc.idTipoDoc,
              presente: e.checked ? 1 : 0
            };
            if (await DocsAPI.update(data)) {
              let list = this.state.documents;
              let el = list.find((x) => x.idDoc === rowData.idDoc);
              el.presente = e.checked ? 1 : 0;
              this.setState({ documents: list });
            }
            this.setState({ loadingGrid: false });
          }}
        />
      </div>
    );
  }

  async printLabel() {
    const requestId = this.state.request.idRichies;
    const res = await RichiestiAPI.printLabels(requestId, 100, 42);
    helpers.generatePDF(res, `Etichetta ${requestId}`);
  }

  async printCard() {
    this.setState({ loadingPage: true });
    const res = await RichiestiAPI.printRequestedCards(
      this.props.match.params.richiesteId
    );
    helpers.generatePDF(res, `Scheda ${this.props.match.params.richiesteId}`);
    this.setState({ loadingPage: false });
  }

  async documentsHandler(toCheck) {
    try {
      this.setState({ loadingGrid: true });
      const requestId = this.state?.request?.idRichies;
      const scholarshipId = this.state?.request?.idBorsa?.idBorsa;
      const documents = [];
      this.state.documents.map((document) => {
        const data = {
          dataAgg: null,
          idRichiesBS: requestId,
          idBs: scholarshipId,
          idDoc: document?.idDoc,
          idTipoDoc: document?.idTipoDoc.idTipoDoc,
          presente: toCheck ? 1 : 0
        };
        documents.push(data);
      });
      const res = await DocsAPI.checkAllDocuments(documents);
      this.setState({ documents: res?.result });
    } catch (error) {
      console.error("🚀 -> error", error);
      this.props.addAlert.current.addAlert(
        "danger",
        "Errore nel cambio di documentazione"
      );
    } finally {
      this.setState({ loadingGrid: false });
    }
  }

  render() {
    const punteggiLaurea = (
      <GridContainer>
        <GridItem xs={12} sm={12} md={10}>
          <CustomInput
            labelText="Votazione"
            id="votaz"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (event) =>
                this.handleRequestChange("voto", event.target.value),
              value: this.state.request.voto || ""
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          {(this.tipoBorsa === "Laurea" ||
            this.tipoBorsa === "Universitaria" ||
            this.tipoBorsa === "UniversitariDisabili") && (
            <FormControlLabel
              control={
                <CheckboxMUI
                  color="primary"
                  checked={
                    !(this.state.request.lode === 0 || !this.state.request.lode)
                  }
                  onChange={(e) =>
                    this.handleRequestChange("lode", e.target.checked ? 1 : 0)
                  }
                />
              }
              label="Lode"
              className="labelCheckbox mt-3"
            />
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="CFU Richiesti"
            id="cfuRic"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("cfuRic", event.target.value),
              value: this.state.request.cfuRic
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="CFU Conseguiti"
            id="cfuCons"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("cfuCons", event.target.value),
              value: this.state.request.cfuCons
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="%CFU"
            id="cfuPerc"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("cfuPerc", event.target.value),
              value: this.state.request.cfuPerc
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomSelect
            labelText="Percorso di Studi"
            id="percorsoStudi"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: false,
              onChange: (e) =>
                this.handleRequestChange("percorsostudi", e.target.value),
              value: this.state.request.percorsostudi || "",
              className: "mt-0"
            }}
          >
            {this.pathStudies?.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </CustomSelect>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Media Ponderata"
            id="mediaPes"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("mediaPes", event.target.value),
              value: this.state.request.mediaPes
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Media"
            id="media"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("media", event.target.value),
              value: this.state.request.media
            }}
          />
        </GridItem>
        {this.state.TipoBorsa === "L4000" && (
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="Titolo Tesi"
              id="media"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: (event) =>
                  this.handleRequestChange("tesi", event.target.value),
                value: this.state.request.tesi
              }}
            />
          </GridItem>
        )}
      </GridContainer>
    );

    const punteggiUniversita = (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="CFU Richiesti"
            id="cfuRichiesti"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("cfuRichiesti", event.target.value),
              value: this.cfuRichiesti
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="CFU Conseguiti"
            id="cfuCons"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("cfuCons", event.target.value),
              value: this.state.request.cfuCons
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="%CFU"
            id="cfuPercentuale"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("cfuPercentuale", event.target.value),
              value: this.cfuPercentuale
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomSelect
            labelText="Percorso di Studi"
            id="percorsoStudi"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: false,
              onChange: (e) =>
                this.handleRequestChange("percorsostudi", e.target.value),
              value: this.state.request.percorsostudi || "",
              className: "mt-0"
            }}
          >
            {this.pathStudies?.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </CustomSelect>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Media Ponderata"
            id="mediaPond"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("mediaPond", event.target.value),
              value: this.mediaPond
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Media"
            id="media"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("media", event.target.value),
              value: this.media
            }}
          />
        </GridItem>
      </GridContainer>
    );

    const punteggiMedieSuperiori = (
      <GridContainer>
        <GridItem xs={12} sm={12} md={10}>
          <CustomInput
            labelText="Votazione"
            id="votaz"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (event) =>
                this.handleRequestChange("voto", event.target.value),
              value: this.state.request.voto || ""
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2} style={{ marginTop: "20px" }}>
          <FormControlLabel
            control={
              <CheckboxMUI
                color="primary"
                checked={
                  !(this.state.request.lode === 0 || !this.state.request.lode)
                }
                onChange={(e) =>
                  this.handleRequestChange("lode", e.target.checked ? 1 : 0)
                }
              />
            }
            label="Lode"
            className="labelCheckbox"
          />
        </GridItem>
      </GridContainer>
    );

    const punteggiMedieSuperioriDisabili = (
      <GridContainer>
        <GridItem xs={12} sm={12} md={10}>
          <CustomInput
            labelText="Votazione"
            id="votaz"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              onChange: (event) =>
                this.handleRequestChange("voto", event.target.value),
              value: this.state.request.voto || ""
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2} style={{ marginTop: "20px" }}>
          <FormControlLabel
            control={
              <CheckboxMUI
                color="primary"
                checked={
                  !(this.state.request.lode === 0 || !this.state.request.lode)
                }
                onChange={(e) =>
                  this.handleRequestChange("lode", e.target.checked ? 1 : 0)
                }
              />
            }
            label="Lode"
            className="labelCheckbox"
          />
        </GridItem>
      </GridContainer>
    );

    const punteggiUniversitariDisabili = (
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="CFU Richiesti"
            id="cfuRichiesti"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("cfuRichiesti", event.target.value),
              value: this.cfuRichiesti
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="CFU Conseguiti"
            id="cfuConsegnati"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("cfuConsegnati", event.target.value),
              value: this.cfuConsegnati
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="%CFU"
            id="cfuPercentuale"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("cfuPercentuale", event.target.value),
              value: this.cfuPercentuale
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomSelect
            labelText="Percorso di Studi"
            id="percorsoStudi"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: false,
              onChange: (e) =>
                this.handleRequestChange("percorsostudi", e.target.value),
              value: this.state.request.percorsostudi || "",
              className: "mt-0"
            }}
          >
            {this.pathStudies?.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </CustomSelect>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Media Ponderata"
            id="mediaPond"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("mediaPond", event.target.value),
              value: this.mediaPond
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Media"
            id="media"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              disabled: true,
              onChange: (event) =>
                this.handleRequestChange("media", event.target.value),
              value: this.media
            }}
          />
        </GridItem>
      </GridContainer>
    );

    return (
      <>
        {this.state.loadingPage && <LoaderSpinner />}
        <div ref={this.btnSearchRef} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseinfo">
                <h4
                  className="ml-2"
                  style={{
                    lineHeight: "2",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Modifica Richiesta
                </h4>

                <Button
                  icon="pi pi-arrow-left"
                  onClick={() => this.onRedirectHandler("Back")}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{
                    verticalAlign: "center",
                    marginRight: "2px",
                    marginTop: "6px"
                  }}
                />

                <ButtonGroup
                  variant="contained"
                  color="default"
                  size="small"
                  aria-label="contained default button group"
                  className="float-right"
                  style={{ verticalAlign: "center", margin: "0.5%" }}
                >
                  <ButtonsMUI onClick={() => this.onRedirectHandler("Corrisp")}>
                    Corrisp.
                  </ButtonsMUI>
                  <ButtonsMUI onClick={this.printCard}>
                    Stampa Scheda
                  </ButtonsMUI>
                  <ButtonsMUI onClick={this.printLabel}>
                    Stampa Etichetta
                  </ButtonsMUI>
                </ButtonGroup>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Card>
                      <CardHeader color="borsesuccess">
                        <h4
                          style={{
                            lineHeight: "1",
                            display: "inline",
                            verticalAlign: "bottom"
                          }}
                        >
                          Richiesta
                        </h4>
                      </CardHeader>
                      <CardBody>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={2}>
                            <CustomInput
                              success={
                                this.state.fieldValidations.Id &&
                                this.state.fieldValidations.Id === "success"
                              }
                              error={
                                this.state.fieldValidations.Id &&
                                this.state.fieldValidations.Id === "error"
                              }
                              labelText="Id *"
                              id="Id"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: true,
                                value: this.state.request.idRichies || ""
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={4}>
                            <CustomInput
                              labelText="Protocollo"
                              id="Protocollo"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: true,
                                value: this.state.request.protRichies || ""
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="Ultimo Aggiornamento"
                              id="UltimoAgg"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: true,
                                value: this.state.request.dataAgg
                                  ? moment(
                                      this.state.request.dataAgg,
                                      dateTimeFmt
                                    ).format(dateFmt)
                                  : ""
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="Operatore Inserimento"
                              id="user"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: true,
                                value: this.state.request.utente || ""
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="Data Inserimento"
                              id="DataInser"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: true,
                                value: this.state.request.dataIns || ""
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="Data Arrivo"
                              id="DataArrivo"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: true,
                                value: this.state.request.dataArr || ""
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="Tipo"
                              id="Tipo"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: true,
                                value:
                                  this.state.request.idBorsa?.tipoBorsa
                                    ?.descr || ""
                              }}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            <CustomInput
                              labelText="Anno"
                              id="Anno"
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                disabled: true,
                                value:
                                  this.state.request.idBorsa?.annoAcc?.descr ??
                                  ""
                              }}
                            />
                          </GridItem>
                        </GridContainer>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="borsesuccess">
                            <h4
                              style={{
                                lineHeight: "1",
                                display: "inline",
                                verticalAlign: "bottom"
                              }}
                            >
                              Richiedente
                            </h4>
                          </CardHeader>
                          <CardBody>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="Cognome"
                                  id="Cognome"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    disabled: true,
                                    value: this.state.applicant.cognome || ""
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="Nome"
                                  id="nome"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    disabled: true,
                                    value: this.state.applicant.nome || ""
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="Codice Fiscale"
                                  id="codfis"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    disabled: true,
                                    value: this.state.applicant.cfRich || ""
                                  }}
                                />
                              </GridItem>
                              <GridItem
                                xs={12}
                                sm={12}
                                md={6}
                                style={{ marginTop: "2px" }}
                              >
                                <p
                                  className="calendarParagraph"
                                  style={{ color: "#565656" }}
                                >
                                  <small>Data Nascita</small>
                                </p>
                                <Calendar
                                  disabled
                                  className="calendarCustomedForMUI"
                                  view="date"
                                  id="dataconst"
                                  value={moment(
                                    this.state.applicant.dataNasc,
                                    dateTimeFmt
                                  ).toDate()}
                                  dateFormat="dd/mm/yy"
                                  style={{
                                    backgroundColor: "rgb(233 231 40)",
                                    color: "black"
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="borsesuccess">
                            <h4
                              style={{
                                lineHeight: "0",
                                display: "inline",
                                verticalAlign: "bottom"
                              }}
                            >
                              Dipendente
                            </h4>
                            <Tooltip
                              arrow
                              id="tooltip-top"
                              title="Vai a Dipendente"
                              placement="top"
                              classes={{ tooltip: "tooltip-me" }}
                            >
                              <CustomButton
                                style={{
                                  verticalAlign: "center",
                                  lineHeight: "1",
                                  margin: "0.1%"
                                }}
                                color="white"
                                aria-label="edit"
                                justIcon
                                round
                                className="float-right"
                                onClick={() => this.onRedirectHandler("Dip")}
                              >
                                <EditIcon />
                              </CustomButton>
                            </Tooltip>
                          </CardHeader>
                          <CardBody>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <CustomInput
                                  labelText="Nominativo"
                                  id="nomi"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    disabled: true,
                                    value: this.state.employee?.nominativo || ""
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="Codice Fiscale"
                                  id="codice"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    disabled: true,
                                    value: this.state.employee?.cf ?? ""
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                  labelText="Parentela"
                                  id="Parentela"
                                  formControlProps={{
                                    fullWidth: true
                                  }}
                                  inputProps={{
                                    disabled: true,
                                    value: this.state.parentela?.descr ?? ""
                                  }}
                                />
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={12}>
                        <Card>
                          <CardHeader color="borseinfo">
                            <h4
                              style={{
                                lineHeight: "0",
                                display: "inline",
                                verticalAlign: "bottom"
                              }}
                            >
                              Stato Pagamenti
                            </h4>
                            <Tooltip
                              arrow
                              id="tooltip-top"
                              title="Vai a stato pagamenti"
                              placement="top"
                              classes={{ tooltip: "tooltip-me" }}
                            >
                              <CustomButton
                                style={{
                                  verticalAlign: "center",
                                  lineHeight: "1",
                                  margin: "0.1%"
                                }}
                                color="white"
                                aria-label="edit"
                                justIcon
                                round
                                className="float-right"
                                onClick={() => this.onRedirectHandler("Stato")}
                              >
                                <EditIcon />
                              </CustomButton>
                            </Tooltip>
                          </CardHeader>
                          <CardBody>
                            <GridContainer>
                              <GridItem
                                xs={12}
                                sm={12}
                                md={5}
                                style={{ marginTop: "20px" }}
                              >
                                <FormControlLabel
                                  control={
                                    <CheckboxMUI
                                      color="primary"
                                      checked={
                                        !(
                                          this.state.request.verifVers === 0 ||
                                          !this.state.request.verifVers
                                        )
                                      }
                                      onChange={(e) => {
                                        this.handleRequestChange(
                                          "verifVers",
                                          e.target.checked ? 1 : 0
                                        );
                                      }}
                                    />
                                  }
                                  label="Versamenti Ok"
                                  className="labelCheckbox"
                                />
                              </GridItem>
                              <GridItem
                                xs={12}
                                sm={12}
                                md={7}
                                style={{ marginTop: "7px" }}
                              >
                                <p
                                  className="calendarParagraph"
                                  style={{ color: "#565656" }}
                                >
                                  <small>Data Verifica</small>
                                </p>
                                <Calendar
                                  className="calendarCustomedForMUI"
                                  view="date"
                                  id="dataVerifVers"
                                  value={moment(
                                    this.state.request.dataVerifVers,
                                    dateFmt
                                  ).toDate()}
                                  dateFormat="dd/mm/yy"
                                  monthNavigator
                                  yearNavigator
                                  yearRange="1900:2999"
                                  onChange={(e) => {
                                    this.handleRequestChange(
                                      "dataVerifVers",
                                      moment(
                                        e.target.value,
                                        dateTimeFmt
                                      ).format(dateTimeFmt)
                                    );
                                  }}
                                />
                              </GridItem>
                              <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                  style={{
                                    backgroundColor: "#fff",
                                    borderColor: "primary"
                                  }}
                                  id="filled-multiline-flexible"
                                  label="Note stato pagamenti"
                                  onChange={(e) =>
                                    this.handleRequestChange(
                                      "noteVerifVers",
                                      e.target.value
                                    )
                                  }
                                  multiline
                                  rows={5}
                                  defaultValue={
                                    this.state.request.noteVerifVers
                                  }
                                  variant="filled"
                                />
                              </GridItem>
                            </GridContainer>
                          </CardBody>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={12}>
                        <Card>
                          <CardHeader color="borseinfo">
                            <h4 className="d-inline">Punteggi</h4>
                            {this.state.TipoBorsa !== "StMS" &&
                              this.state.TipoBorsa !== "StMSD" && (
                                <Tooltip
                                  arrow
                                  id="tooltip-top"
                                  title="Vai a punteggi"
                                  placement="top"
                                  classes={{ tooltip: "tooltip-me" }}
                                >
                                  <CustomButton
                                    style={{
                                      verticalAlign: "center",
                                      lineHeight: "1",
                                      margin: "0.1%"
                                    }}
                                    color="white"
                                    aria-label="edit"
                                    justIcon
                                    round
                                    className="float-right"
                                    onClick={() =>
                                      this.onRedirectHandler("Punt")
                                    }
                                  >
                                    <EditIcon />
                                  </CustomButton>
                                </Tooltip>
                              )}
                          </CardHeader>
                          <CardBody>
                            {}
                            {this.tipoBorsa === "Laurea" && punteggiLaurea}
                            {this.tipoBorsa === "Universitaria" &&
                              punteggiUniversita}
                            {this.tipoBorsa === "MedieSuperiori" &&
                              punteggiMedieSuperiori}
                            {this.tipoBorsa === "MedieSuperioriDisabili" &&
                              punteggiMedieSuperioriDisabili}
                            {this.tipoBorsa === "UniversitariDisabili" &&
                              punteggiUniversitariDisabili}
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="borsesuccess">
                            <h4
                              style={{
                                lineHeight: "1",
                                display: "inline",
                                verticalAlign: "bottom"
                              }}
                            >
                              Riferimento Scolastico / Universitario
                            </h4>
                          </CardHeader>
                          <CardBody>
                            {this.state.TipoBorsa === "L4000" ||
                            this.state.TipoBorsa === "StUni" ||
                            this.state.TipoBorsa === "Laur" ||
                            this.state.TipoBorsa === "SUDis" ? (
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText="Università"
                                    id="istituto"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      onChange: (event) =>
                                        this.handleRequestChange(
                                          "institute",
                                          event.target.value
                                        ),
                                      value: this.state.request.institute || ""
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText="Facoltà"
                                    id="facolta"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      onChange: (event) =>
                                        this.handleRequestChange(
                                          "faculty",
                                          event.target.value
                                        ),
                                      value: this.state.request.faculty || ""
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText="Corso"
                                    id="corso"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      onChange: (event) =>
                                        this.handleRequestChange(
                                          "course",
                                          event.target.value
                                        ),
                                      value: this.state.request.course || ""
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText="Recapito"
                                    id="rec"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      onChange: (event) =>
                                        this.handleRequestChange(
                                          "address",
                                          event.target.value
                                        ),
                                      value: this.state.request.address || ""
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            ) : this.state.TipoBorsa === "StMS" ||
                              this.state.TipoBorsa === "StMSD" ? (
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText="Istituto/Università"
                                    id="istituto"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      onChange: (event) =>
                                        this.handleRequestChange(
                                          "institute",
                                          event.target.value
                                        ),
                                      value: this.state.request.institute || ""
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText="Corso"
                                    id="corso"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      onChange: (event) =>
                                        this.handleRequestChange(
                                          "course",
                                          event.target.value
                                        ),
                                      value: this.state.request.course || ""
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={6}>
                                  <CustomInput
                                    labelText="Recapito"
                                    id="rec"
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      onChange: (event) =>
                                        this.handleRequestChange(
                                          "address",
                                          event.target.value
                                        ),
                                      value: this.state.request.address || ""
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            ) : (
                              <></>
                            )}
                          </CardBody>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <Card>
                          <CardHeader color="borsesuccess">
                            <h4
                              style={{
                                lineHeight: "0",
                                display: "inline",
                                verticalAlign: "bottom"
                              }}
                            >
                              Documenti Presentati
                            </h4>

                            <FormControlLabel
                              className="float-right"
                              style={{
                                verticalAlign: "center",
                                lineHeight: "1",
                                margin: "0.1%",
                                color: "white"
                              }}
                              control={
                                <Switch
                                  checked={this.state.filterGrid}
                                  onChange={this.ChangeFilterGrid}
                                  name="chkFilterGrid"
                                  color="default"
                                />
                              }
                              label={"Attiva Filtri"}
                            />
                          </CardHeader>
                          <CardBody>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: "2vh",
                                width: "40%",
                                justifyContent: "space-between"
                              }}
                            >
                              <Button
                                disabled={this.state.loadingGrid}
                                onClick={() => {
                                  const toCheck = true;
                                  this.documentsHandler(toCheck);
                                }}
                              >
                                Seleziona Tutti
                              </Button>
                              <Button
                                disabled={this.state.loadingGrid}
                                onClick={() => {
                                  const toCheck = false;
                                  this.documentsHandler(toCheck);
                                }}
                              >
                                Deseleziona Tutti
                              </Button>
                            </div>
                            <DataTable
                              emptyMessage=""
                              className="p-datatable-striped p-datatable-sm"
                              value={this.state.documents}
                              dataKey="id"
                              currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                              rows={20}
                              loading={this.state.loadingGrid}
                              scrollable
                              style={{ width: "100%" }}
                              sortField="idTipoDoc.descr"
                              sortOrder={1}
                            >
                              <Column
                                field="idTipoDoc.descr"
                                header="Titolo"
                                sortable={true}
                                filter={this.state.filterGrid}
                                filterMatchMode="contains"
                                filterPlaceholder=""
                              />
                              <Column
                                header=""
                                body={this.templateCheckboxGrid}
                                headerStyle={{ width: "50px" }}
                              />
                            </DataTable>
                          </CardBody>
                        </Card>
                      </GridItem>
                      <GridItem xs={12} sm={6} md={12}>
                        <Card>
                          <CardHeader color="borseinfo">
                            <h4
                              style={{
                                lineHeight: "1",
                                display: "inline",
                                verticalAlign: "bottom"
                              }}
                            >
                              Esito Richiesta
                            </h4>
                          </CardHeader>

                          <CardBody>
                            <div style={{ width: "50%", float: "left" }}>
                              <GridContainer>
                                <GridItem xs={12}>
                                  Stato Pratica
                                  <Dropdown
                                    optionLabel="descr"
                                    value={this.state.selectedStatopratica}
                                    options={this.state.statesArray}
                                    onChange={(e) =>
                                      this.HandleDropDownChange(
                                        "selectedStatopratica",
                                        e.target.value,
                                        "stato"
                                      )
                                    }
                                  />
                                </GridItem>
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={12}>
                                  Esito
                                  <Dropdown
                                    disabled={this.state.isResultDisabled}
                                    optionLabel="descr"
                                    value={this.state.selectedEsito}
                                    options={this.state.esitTypesArray}
                                    onChange={(e) =>
                                      this.HandleDropDownChange(
                                        "selectedEsito",
                                        e.target.value,
                                        "stato"
                                      )
                                    }
                                  />
                                </GridItem>{" "}
                              </GridContainer>
                              <GridContainer>
                                <GridItem xs={12}>
                                  Motivazione Esito
                                  <Dropdown
                                    disabled={this.state.isReasonDisabled}
                                    optionLabel="descr"
                                    value={this.state.selectedMotivazione}
                                    options={this.state.motiviArray}
                                    onChange={(e) =>
                                      this.HandleDropDownChange(
                                        "selectedMotivazione",
                                        e.target.value,
                                        "stato"
                                      )
                                    }
                                  />{" "}
                                </GridItem>
                              </GridContainer>
                            </div>
                            <div
                              style={{
                                width: "50%",
                                float: "right",
                                marginTop: "25px",
                                paddingLeft: "10px"
                              }}
                            >
                              <GridContainer>
                                <GridItem style={{ width: "100%" }}>
                                  <TextField
                                    id="nota"
                                    style={{ backgroundColor: "#fff" }}
                                    label="Note"
                                    onChange={(e) =>
                                      this.handleRequestChange(
                                        "nota",
                                        e.target.value
                                      )
                                    }
                                    multiline
                                    rows={6}
                                    defaultValue={this.state.request.nota}
                                    variant="filled"
                                    fullWidth={true}
                                  />
                                </GridItem>
                              </GridContainer>
                            </div>
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </CardBody>

              <CardFooter>
                <ButtonSaveWithLoading
                  onClick={this.HandleSaveClick}
                  text={"SALVA"}
                  isValidForm={this.isValidForm}
                />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default ModificaRichiesteXRichiedenti;
