import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { RDLHeader } from "components/Views/RDL/RDLHeader.js";
import { ModificaLavoroXDipendenti } from "components/Views/Lavoro/ModificaLavoroXDipendenti.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import helpers from "helpers/Helpers";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import Close from "@material-ui/icons/Close";
import { confirmPopup } from "primereact/confirmpopup";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LoaderSpinner from "../../../src/components/Layout/LoaderSpinner.js";
import queryString from "query-string";
import DipendentiAPI from "API/DipendentiAPI";
import {rowsPerPageOptions} from "../../helpers/AppConstants";
class LavoroXAzienda extends Component {
  state = {
    cf: null,
    matricola: null,
    rows: null,
    selectedRow: null,
    selectedGridLavoro: null,
    rowsPerPage: 10,
    visibleLoader: false,

    filterGrid: false,
    loadingGrid: false,
    firstRowToDisplay: 0
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.btnSearchRef = React.createRef();
    this.DeleteGridTemplate = this.DeleteGridTemplate.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });

    let rw;
    if (this.props.match.params.aziendaId !== "0")
      rw = await DipendentiAPI.GetRDLByDipendenteIdAziendaId(
        this.props.match.params.dipendenteId,
        this.props.match.params.aziendaId
      );
    else
      rw = await DipendentiAPI.GetRDLByDipendenteId(
        this.props.match.params.dipendenteId
      );

    let selectedRow;
    var firstRowToDisplay = 0;
    var qS = queryString.parse(this.props.history.location.search);
    if (qS.typeId > 0) {
      selectedRow = helpers.ReturnRowFromListById(rw, qS.typeId);
      firstRowToDisplay = helpers.GetFirstElementOfPageIncludingId(
        qS.typeId,
        rw,
        10
      );
    }

    this.setState({
      firstRowToDisplay: firstRowToDisplay,
      rows: rw
    });
    if (selectedRow)
      await this.handleTableClick({
        value: selectedRow
      });

    this.setState({
      loadingGrid: false
    });
    if (rw.length === 0 || qS?.type === 'fromUniemens') {
      this.onNuovoClick();
    }
  }

  DeleteGridTemplate(rowData) {
    /*if (
      rowData.utente !== "Sistema" &&
      this.props.match.params.aziendaId === 0
    ) {*/
      return (
        <Button
          round
          color="danger"
          className="actionButton actionButtonRound btnRound float-right"
          key={rowData.Id}
          onClick={(e) => {this.onDeleteClick(e, rowData.id)}}
        >
          <Close className="icon" />
        </Button>
      );
    /*}*/
  }

  onDeleteClick(event, id) {
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        this.DeleteRecord(id);
      },
      reject: () => {}
    });
  }

  async DeleteRecord(id) {
    let response;

    response = await DipendentiAPI.DeleteRdl(id);

    if (response.result === null) {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("warning", response.message);
      }, 1200);
    } else {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("success", "Dati eliminati.");
      }, 1200);
    }

    this.setState({
      loadingGrid: true
    });

    let rw;
    if (this.props.match.params.aziendaId !== 0) {
      rw = await DipendentiAPI.GetRDLByDipendenteIdAziendaId(
        this.props.match.params.dipendenteId,
        this.props.match.params.aziendaId
      );
    } else {
      rw = await DipendentiAPI.GetRDLByDipendenteId(
        this.props.match.params.dipendenteId
      );
    }

    let selectedRow;
    var firstRowToDisplay = 0;
    var qS = queryString.parse(this.props.history.location.search);

    if (qS.typeId > 0) {
      selectedRow = helpers.ReturnRowFromListById(rw, qS.typeId);
      firstRowToDisplay = helpers.GetFirstElementOfPageIncludingId(
        qS.typeId,
        rw,
        10
      );
    }

    this.setState({
      firstRowToDisplay: firstRowToDisplay,
      rows: rw
    });

    this.setState({
      loadingGrid: false,
      selectedRow: null
    });
    if (rw.length === 0) {
      this.onNuovoClick();
    }
  }

  onCloseGridClick() {
    this.setState({
      selectedGridLavoro: null,
      selectedRow: null
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  async onNuovoClick() {
    this.setState({
      visibleLoader: true
    });

    var codEMat = await DipendentiAPI.GetCFEMatRDL(
      this.props.match.params.aziendaId,
      this.props.match.params.dipendenteId
    );

    var nuovo = await DipendentiAPI.GetNuovoRDLById(0);
    nuovo.matricola = codEMat.matricola;
    nuovo.cf = codEMat.cf;
    this.setState({
      selectedGridLavoro: null,
      selectedRow: nuovo
    });

    this.setState({
      visibleLoader: false
    });
  }
  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  async handleTableClick(e) {
    this.setState({
      visibleLoader: true
    });

    var dep = await DipendentiAPI.GetRDLById(e.value.id);

    dep[0].id = e.value.id;

    this.setState({
      selectedGridLavoro: e.value,
      selectedRow: dep[0]
    });
    this.state.selectedRow.id = e.value.id;

    this.setState({
      visibleLoader: false
    });
  }

  HandleSaveClick = async (RowItem) => {
    let response;

    if (!!RowItem.data_fine) {
      let dataIn = helpers.ConvertDateAsArrayWithSlash(RowItem.data_inizio);
      let dataFin = helpers.ConvertDateAsArrayWithSlash(RowItem.data_fine);

      if (RowItem.id === null) {
        if (helpers.compareStringDates(dataIn, dataFin)) {
          response = await DipendentiAPI.AddRDLInsert(
            RowItem,
            this.props.match.params.aziendaId
          );
        } else {
          window.setTimeout(() => {
            this.props.addAlert.current.addAlert(
              "warning",
              "La Data Fine deve essere maggiore della data inizio."
            );
          }, 1200);
        }
      } else {
        if (helpers.compareStringDates(dataIn, dataFin)) {
          response = await DipendentiAPI.AddRDLUpdate(
            RowItem,
            this.state.selectedRow.id
          );
        } else {
          window.setTimeout(() => {
            this.props.addAlert.current.addAlert(
              "warning",
              "La Data Fine deve essere maggiore della data inizio."
            );
          }, 1200);
        }
      }
    } else {
      if (RowItem.id === null) {
        response = await DipendentiAPI.AddRDLInsert(
          RowItem,
          this.props.match.params.aziendaId
        );
      } else {
        response = await DipendentiAPI.AddRDLUpdate(
          RowItem,
          this.state.selectedRow.id
        );
      }
    }

    if (!!response && response?.result === null) {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("danger", response.message);
      }, 1300);
    } else if (!!response) {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("success", "Dati salvati.");
        try{
          let qS = queryString.parse(this.props.history.location.search);
          if(qS.type === 'fromUniemens') {
            this.backButtonClick();
          }
        } catch(err){}
      }, 1200);
    }
    this.componentDidMount();
    return true;
  };

  backButtonClick = () => {
    let aziendaId = this.props.match.params.aziendaId;

    let qS = queryString.parse(this.props.history.location.search);
    let maintainSearch = "?maintainSearch=1";
    if (!qS.type) {
      if (aziendaId > 0)
        this.props.history.push(
          "/admin/DipendentiXAzienda/" +
            aziendaId +
            "?typeId=" +
            this.props.match.params.dipendenteId
        );
      else if (aziendaId == 0)
        this.props.history.push(
          "/admin/dipendenti/" +
            this.props.match.params.dipendenteId +
            maintainSearch
        );
    } else if (qS.type === "fromVersamenti")
      this.props.history.push(
        "/admin/DipendentiXAzienda/" +
          aziendaId +
          "?type=" +
          qS.type +
          "&typeId=" +
          this.props.match.params.dipendenteId
      );
    else if (qS.type === "fromModificaRichieste")
      this.props.history.push(
        "/admin/DipendentiXAzienda/" +
          aziendaId +
          "?type=" +
          qS.type +
          "&typeId=" +
          qS.typeId
      );
    else if (qS.type === "fromRichieste")
      this.props.history.push(
        "/admin/DipendentiXAzienda/" +
          aziendaId +
          "?type=" +
          qS.type +
          "&typeId=" +
          qS.typeId
      );
    else if (qS.type === "fromUniemens")
      this.props.history.push(
        `/admin/UniemensXAzienda/${aziendaId}?type=fromVersamenti&open=1`
      );

    else if (qS.type === "fromModificaDipendente")
      this.props.history.push("/admin/ModificaDipendente/" + this.props.match.params.dipendenteId);
  };

  render() {
    return (
      <>
        {this.state.visibleLoader && <LoaderSpinner />}

        <div ref={this.btnSearchRef} />
        <RDLHeader {...this.props} />

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Rapporti di Lavoro
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>
                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Alla pagina precedente"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.backButtonClick}
                  >
                    <ArrowBackIcon />
                  </Button>
                </Tooltip>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage} rowsPerPageOptions={rowsPerPageOptions}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  loading={this.state.loadingGrid}
                  selection={this.state.selectedGridLavoro}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="matricola"
                    header="Matricola"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="denominazione"
                    header="Ragione Sociale"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cod_quota"
                    header="Codice Quota"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data_inizio_mostra"
                    header="Data inizio"
                    body={(rowData) =>
                      helpers.DateTemplateWithHours(
                        rowData,
                        "data_inizio_mostra"
                      )
                    }
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data_fine_mostra"
                    header="Data fine"
                    body={(rowData) =>
                      helpers.DateTemplateWithHours(rowData, "data_fine_mostra")
                    }
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    header=""
                    body={this.DeleteGridTemplate}
                    icon="pi pi-times"
                    label="Delete"
                    className="p-button-danger p-button-outlined colDelete"
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.selectedRow && (
          <ModificaLavoroXDipendenti
            {...this.props}
            dataItem={this.state.selectedRow}
            onAnnullaClick={this.onCloseGridClick}
            onSaveClick={this.HandleSaveClick}
          />
        )}
      </>
    );
  }
}
export default LavoroXAzienda;
