import React, { useEffect, useState } from "react";

// Api
import AnomalieAPI from "API/AnomalieAPI";

// Components
import Table from "sharedComponents/Table.component";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const ReportPostaAnomalie = () => {
  const [tableData, setTableData] = useState([]);
  const [firstRowToDisplay, setFirstRowToDisplay] = useState(0);
  const [rowsForPage, setRowsForPage] = useState(20);
  const [isLoading, setIsLoading] = useState(false);

  async function getData() {
    try {
      setIsLoading(true);
      const anomaliesReports = await AnomalieAPI.getAnomaliesReport();
      const _anomaliesReports = [];
      for (let i = 0; i < anomaliesReports?.length; i++) {
        _anomaliesReports.push({
          ...anomaliesReports[i],
          stato:
            anomaliesReports[i]?.stato === "IN_CORSO"
              ? "IN CORSO"
              : anomaliesReports[i]?.stato
        });
      }
      setTableData(_anomaliesReports);
    } catch (error) {
      console.error("🚀 -> error", error);
      alert("Errore nel caricamento");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const title = (
    <h4
      style={{
        lineHeight: "0",
        display: "inline",
        verticalAlign: "bottom",
        lineHeight: "1"
      }}
    >
      Report Posta Anomalie
    </h4>
  );

  const cardHeader = <CardHeader color="success">{title}</CardHeader>;

  const tableColumns = [
    { field: "id", header: "ID" },
    { field: "nomeZip", header: "Nome ZIP" },
    {
      field: "stato",
      header: "Stato"
    },
    { field: "dataInizio", header: "Data Inizio" },
    { field: "dataFine", header: "Data Fine" }
  ];

  async function downloadAnomalyReport(id) {
    try {
      setIsLoading(true);
      await AnomalieAPI.downloadAnomalyReport(id);
    } catch (error) {
      console.error("🚀 -> error", error);
      alert("Errore nel download del file");
    } finally {
      setIsLoading(false);
    }
  }

  function downloadHandler(rowData) {
    const id = rowData?.id;
    downloadAnomalyReport(id);
  }

  const cardBody = (
    <CardBody>
      <Table
        tableData={tableData}
        firstRowToDisplay={firstRowToDisplay}
        setFirstRowToDisplay={setFirstRowToDisplay}
        rowsForPage={rowsForPage}
        setRowsForPage={setRowsForPage}
        emptyMessage="Nessun Report Da Visualizzare"
        isLoading={isLoading}
        columns={tableColumns}
        downloadHandler={downloadHandler}
      />
    </CardBody>
  );

  const card = (
    <Card>
      {cardHeader}
      {cardBody}
    </Card>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {card}
      </GridItem>
    </GridContainer>
  );
};

export default ReportPostaAnomalie;
