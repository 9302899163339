import React, { Component } from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import { Button } from "primereact/button";
import ConsulentiAPI from "API/ConsulentiAPI";

export class DitteXConsulenteHeader extends Component {
  state = {
    azienda: {},
    consulenti: {}
  };

  constructor(props) {
    super(props);

    this.backButtonClick = this.backButtonClick.bind(this);
  }

  async componentDidMount() {
    let consulentiAziendeById = await ConsulentiAPI.GetConsulentiAziende(
      this.props.location.search.split("=")[1]
    );

    this.setState({
      consulenti: consulentiAziendeById[0].consulente,
      azienda: consulentiAziendeById[0].azienda
    });
  }

  backButtonClick() {

    if (this.props.match.params.aziendaId != "0")
      this.props.history.push('/admin/aziende/' + this.props.match.params.aziendaId)
    else if (this.props.match.params.aziendaId == "0")
      this.props.history.push('/admin/consulenti/' + this.props.match.params.consulenteId);

  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Button
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />

                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Aziende
                </h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Codice Fiscale"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.consulenti.cf || ""
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Matricola"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda?.matricola || ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Ragione Sociale"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.azienda?.denominazione || ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Nominativo"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.consulenti.nominativo || ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
