import Utils from "./Utils";

export const ExportAnagraficheAPI = {
    avvia: async function (oggetto, formato, annoInizio, annoFine) {
        await Utils.CreatePostRequestDownloadReport(
            'coasco/exportAnagrafiche/getByOggettoFormato?oggetto=' +
            oggetto.name + '&formato=' + formato.name +
            ((oggetto.name === 'Aziende' || oggetto.name === 'Dipendenti') ? ('&annoInizio=' + annoInizio + '&annoFine=' + annoFine) : '')
        );
    }
}