import React, { Component } from "react";
import clone from "clone";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import UndoIcon from "@material-ui/icons/Undo";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import MenuItem from "@material-ui/core/MenuItem";
import { DataTable } from "primereact/datatable";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import { Column } from "primereact/column";
import Tooltip from "@material-ui/core/Tooltip";
import CustomButtons from "components/CustomButtons/Button.js";
import AddIcon from "@material-ui/icons/Add";
import helpers from "helpers/Helpers";
import BorseAPI from "API/BorseAPI";
import AnniAccademiciAPI from "API/AnniAccademiciAPI";
import TipoBorseAPI from "API/TipoBorsaAPI";
import moment from "moment";
import {dateTimeFmt, rowsPerPageOptions} from "helpers/AppConstants";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import { confirmPopup } from "primereact/confirmpopup";

class BorseDiStudio extends Component {
  state = {
    ModificaTitle: "Inserisci",
    selectedBorsa: null,
    borsa: null,
    loadingGrid: false,
    filterGrid: null,
    rows: [],
    isVisible: false,
    Borse: [],
    rowsPerPage: 10,
    years: null,
    selectedYear: null,
    types: null,
    selectedType: null,

    fieldValidations: {},
    validationRules: {
      contributo: "",
      nborse: "",
      tipoBorsa: "",
      annoAcc: ""
    }
  };

  constructor(props) {
    super(props);

    this.btnSearchRef = React.createRef();
    this.changeBorsaRef = React.createRef();

    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.SetText = this.SetText.bind(this);
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.DeleteColumn = this.DeleteColumn.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.DeleteRecord = this.DeleteRecord.bind(this);
  }

  async componentDidMount() {
    this.setState({ loadingGrid: true });
    try {
      const years = await AnniAccademiciAPI.AnnoAccademiciForBorse();
      const response = await TipoBorseAPI.GetAllTipoBorse();
      const allBorse = await BorseAPI.AllBorse();

      this.setState(
        {
          years: years,
          types: response.result,
          Borse: allBorse
        },
        () => this.setState({ loadingGrid: false })
      );
    } catch (err) {
      this.setState({ loadingGrid: false }, () =>
        this.props.addAlert.current.addAlert(
          "danger",
          "Si è verificato un errore nella richiesta dei dati"
        )
      );
    }
  }

  SetText(field, newValue) {
    let newRowItem = this.state.borsa;
    newRowItem[field] = newValue;
    this.setState({ borsa: newRowItem });
  }

  async onNuovoClick() {
    this.setState({
      borsa: {},
      ModificaTitle: "Inserisci",
      isVisible: true
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.borsa !== prevState.borsa && this.state.isVisible) {
      try {
        this.changeBorsaRef.current.scrollIntoView();
      } catch(err){}
    }
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true
    });
    const allBorse = await BorseAPI.AllBorse();
    try {
      this.setState(
        {
          Borse: allBorse
        },
        function () {
          this.setState({
            loadingGrid: false
          });
        }
      );
    } catch (err) {
      this.setState(
        {
          loadingGrid: false
        },
        () =>
          this.props.addAlert.current.addAlert(
            "danger",
            "Si è verificato un errore nel caricamento dei dati"
          )
      );
      console.error("BorseDiStudio err", err);
    }
  }

  HandleDropDownChange(e, val) {
    let borsa = this.state.borsa;
    borsa[e] = val;
    this.setState({
      borsa: borsa
    });
  }

  isValidForm() {
    let RowItem = this.state.borsa;
    let validationRules = clone(this.state.validationRules);
    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);

    let isValid = Object.values(fieldValidations).every((x) => x === "success");

    this.setState({
      fieldValidations: fieldValidations
    });

    return isValid;
  }

  HandleSaveClick = async () => {
    if (this.isValidForm()) {
      this.state.borsa.nborse = parseInt(this.state.borsa.nborse);
      this.state.borsa.contributo = parseFloat(this.state.borsa.contributo);

      if (this.state.borsa.idBorsa) {
        const data = {
          idBorsa: this.state.borsa.idBorsa,
          annoAcc: this.state.borsa.annoAcc.idAnno,
          contributo: this.state.borsa.contributo,
          nborse: this.state.borsa.nborse,
          tipoBorsa: this.state.borsa.tipoBorsa.idTipoBS
        };
        await BorseAPI.UpdateBorsa(data);
        try {
          this.props.addAlert.current.addAlert("success", "Dati salvati");
        } catch (err) {
          this.props.addAlert.current.addAlert(
            "danger",
            "Si è verificato un errore durante il salvataggio dei dati"
          );
        }
      } else {
        const data = {
          annoAcc: this.state.borsa.annoAcc,
          contributo: this.state.borsa.contributo,
          dataAgg: moment(new Date(), dateTimeFmt),
          nborse: this.state.borsa.nborse,
          tipoBorsa: this.state.borsa.tipoBorsa,
          idBorsa: null,
          progrBorsa: null,
          utente: null
        };
        await BorseAPI.AddBorsa(data);
        try {
          this.props.addAlert.current.addAlert("success", "Dati aggiornati");
        } catch (err) {
          this.props.addAlert.current.addAlert(
            "danger",
            "Si è verificato un errore durante l'aggiornamento dei dati"
          );
        }
      }
      this.onAnnullaClick();
      this.refreshGrid();
      return true;
    }
  };

  ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  onAnnullaClick() {
    this.setState({
      borsa: null,
      selectedType: null,
      selectedYear: null,
      isVisible: false
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  async handleTableClick(e) {
    this.setState({
      borsa: e.value,
      selectedYear: e.value.annoAcc,
      selectedType: e.value.tipoBorsa,
      ModificaTitle: "Modifica",
      isVisible: true
    });
  }

  DeleteColumn(rowData) {
    return (
      <Button
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData.Id}
        onClick={(e) => this.onDeleteClick(e, rowData)}
      >
        <Close className="icon" />
      </Button>
    );
  }

  onDeleteClick(event, rowData) {
    const id = rowData?.idBorsa;

    event.stopPropagation();

    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => this.DeleteRecord(id),
      reject: () => {}
    });
  }

  async DeleteRecord(id) {
    const res = await BorseAPI.deleteScholarship(id);
    if (res) {
      this.props.addAlert.current.addAlert(
        "success",
        "Eliminazione riuscita correttamente"
      );
      this.refreshGrid();
    } else
      this.props.addAlert.current.addAlert(
        "danger",
        "Eliminazione non riuscita"
      );
  }
  render() {
    return (
      <>
        <div ref={this.btnSearchRef} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Borse di Studio
                </h4>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borsesuccess">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Tipologie Borse di Studio Presenti per Annualità
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    marginRight: "6px",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <CustomButtons
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </CustomButtons>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.Borse}
                  dataKey="idBorsa"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage} rowsPerPageOptions={rowsPerPageOptions}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.borsa}
                  scrollable scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="annoAcc.descr"
                    header="Annualità"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="tipoBorsa.descr"
                    header="Categoria"
                    headerStyle={{ width: "170px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="tipoBorsa.descrBr"
                    header="Sigla B.S."
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="nborse"
                    header="Numero B.S."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="contributo"
                    header="Importo"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    header=""
                    body={this.DeleteColumn}
                    icon="pi pi-times"
                    label="Delete"
                    className="p-button-danger p-button-outlined colDelete"
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <div ref={this.changeBorsaRef}>
          {this.state.isVisible && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="borseinfo">
                    <h4
                      style={{
                        lineHeight: "0",
                        display: "inline",
                        verticalAlign: "bottom"
                      }}
                    >
                      {" "}
                      {this.state.ModificaTitle} Borse di Studio
                    </h4>
                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Annulla"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <CustomButtons
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%",
                          marginLeft: "0",
                          marginRight: "0"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="float-right"
                        onClick={this.onAnnullaClick}
                      >
                        <UndoIcon />
                      </CustomButtons>
                    </Tooltip>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={1}
                        style={{ marginTop: "0px" }}
                      >
                        <CustomInput
                          labelText="Id"
                          id="id"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.borsa.idBorsa || ""
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2} className="select">
                        <CustomSelect
                          success={
                            this.state.fieldValidations.annoAcc &&
                            this.state.fieldValidations.annoAcc === "success"
                          }
                          error={
                            this.state.fieldValidations.annoAcc &&
                            this.state.fieldValidations.annoAcc === "error"
                          }
                          labelText="Anni Accademici *"
                          id="anno"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: this.state.borsa.idBorsa > 0,
                            onChange: (e) => {
                              this.HandleDropDownChange(
                                "annoAcc",
                                e.target.value
                              );
                              this.setState({
                                selectedYear: this.state.years.find(
                                  (year) => year.idAnno === e.target.value
                                )
                              });
                            },
                            value: this.state.selectedYear?.idAnno || ""
                          }}
                        >
                          {this.state.years.map((year) => (
                            <MenuItem value={year.idAnno} key={year.idAnno}>
                              {year.descr}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3} className="select">
                        <CustomSelect
                          success={
                            this.state.fieldValidations.tipoBorsa &&
                            this.state.fieldValidations.tipoBorsa === "success"
                          }
                          error={
                            this.state.fieldValidations.tipoBorsa &&
                            this.state.fieldValidations.tipoBorsa === "error"
                          }
                          labelText="Descrizione *"
                          id="descr"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: this.state.borsa.idBorsa > 0,
                            onChange: (e) => {
                              this.HandleDropDownChange(
                                "tipoBorsa",
                                e.target.value
                              );
                              this.setState({
                                selectedType: this.state.types.find(
                                  (type) => type.idTipoBS === e.target.value
                                )
                              });
                            },
                            value: this.state.selectedType?.idTipoBS || ""
                          }}
                        >
                          {this.state.types.map((type) => (
                            <MenuItem value={type.idTipoBS} key={type.idTipoBS}>
                              {type.descr} - {type.descrBr}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          success={
                            this.state.fieldValidations.nborse &&
                            this.state.fieldValidations.nborse === "success"
                          }
                          error={
                            this.state.fieldValidations.nborse &&
                            this.state.fieldValidations.nborse === "error"
                          }
                          labelText="Num. Borse Disponibili *"
                          id="num"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (e) =>
                              this.SetText("nborse", e.target.value),
                            value: this.state.borsa.nborse || "",
                            type: "number"
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={3}>
                        <CustomInput
                          success={
                            this.state.fieldValidations.contributo &&
                            this.state.fieldValidations.contributo === "success"
                          }
                          error={
                            this.state.fieldValidations.contributo &&
                            this.state.fieldValidations.contributo === "error"
                          }
                          labelText="Valore Della Borsa di Studio *"
                          id="val"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (e) =>
                              this.SetText("contributo", e.target.value),
                            value: this.state.borsa.contributo || "",
                            type: "number"
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <ButtonSaveWithLoading
                      onClick={this.HandleSaveClick}
                      text={"SALVA"}
                      isValidForm={this.isValidForm}
                    />
                  </CardFooter>
                </Card>
              </GridItem>
            </GridContainer>
          )}
        </div>
      </>
    );
  }
}
export default BorseDiStudio;
