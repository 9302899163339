import React from "react";
import GridItem from "../../components/Grid/GridItem";
import Card from "../../components/Card/Card";
import CardHeader from "../../components/Card/CardHeader";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "../../components/CustomButtons/Button";
import AddIcon from "@material-ui/icons/Add";
import CardBody from "../../components/Card/CardBody";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import GridContainer from "../../components/Grid/GridContainer";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";
import authApi from "../../API/auth.api";
import EmailUtenteAPI from "../../API/EmailUtenteAPI";
import Close from "@material-ui/icons/Close";
import {confirmPopup} from "primereact/confirmpopup";
import ModificaEmail from "../../components/Views/GestioneEmail/ModificaEmail";

class GestioneEmail extends React.Component {

    state = {
        filterGrid: false,
        loadingGrid: false,
        emails: null,
        visibleLoader: false,
        utente: null,
        selectedGrid: null,
        selectedEmailUtente: null
    }

    constructor(props) {
        super(props);
        this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
        this.onNuovoClick = this.onNuovoClick.bind(this);
        this.DeleteGridTemplate = this.DeleteGridTemplate.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.DeleteRecord = this.DeleteRecord.bind(this);
        this.refreshGrid = this.refreshGrid.bind(this);
        this.handleTableClick = this.handleTableClick.bind(this);
        this.onAnnullaClick = this.onAnnullaClick.bind(this);
        this.onSaveClick = this.onSaveClick.bind(this);
    }

    onAnnullaClick() {
        this.setState({
            selectedGrid: null,
            selectedEmailUtente: null
        });
    }

    async onSaveClick(selectedEmailUtente) {
        if (!selectedEmailUtente.email) {
            this.props.addAlert.current.addAlert("danger", 'Inserire la mail');
        } else if (selectedEmailUtente.id === 0) {
            const result = await EmailUtenteAPI.Insert(selectedEmailUtente);
            if (result) {
                this.props.addAlert.current.addAlert("success", 'Dati salvati.');
                await this.refreshGrid();
            } else {
                this.props.addAlert.current.addAlert('error', 'Impossibile salvare i dati.');
            }
        } else {
            const result = await EmailUtenteAPI.Update(selectedEmailUtente);
            if (result) {
                this.props.addAlert.current.addAlert("success", 'Dati salvati.');
                await this.refreshGrid();
            } else {
                this.props.addAlert.current.addAlert('error', 'Impossibile salvare i dati.');
            }
        }

        return true;
    }

    handleTableClick(event) {

        this.setState({
            visibleLoader: true
        });

        this.setState({
            selectedGrid: event.value,
            selectedEmailUtente: event.value
        });

        this.setState({
            visibleLoader: false
        });
    }

    onDeleteClick(event, id) {
        event.stopPropagation();
        confirmPopup({
            target: event.currentTarget,
            message: "Vuoi eliminare questo record?",
            header: "Confirmation",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => this.DeleteRecord(id),
            reject: () => {}
        });
    }

    async DeleteRecord(id) {
        const result = await EmailUtenteAPI.Delete(id);
        if (result?.every(x => x)) {
            await this.refreshGrid();
            this.props.addAlert.current.addAlert("success", "Email correttamente rimossa.");
            if (this.state.selectedEmailUtente?.id === id) {
                this.setState({
                    selectedGrid: null,
                    selectedEmailUtente: null
                });
            }
        } else {
            this.props.addAlert.current.addAlert("danger", "Errore durante la rimozione dell'email.");
        }
    }

    DeleteGridTemplate(row) {
        return (
            <Button
                round color="danger" key={row.id}
                className="actionButton actionButtonRound btnRound float-right"
                onClick={e => this.onDeleteClick(e, row.id)}
            >
                <Close className="icon" />
            </Button>
        );
    }

    ChangeFilterGrid(e) {
        this.setState({ filterGrid: e.target.checked });
    }

    async onNuovoClick() {

        this.setState({
            visibleLoader: true
        });

        this.setState({
            selectedGrid: null,
            selectedEmailUtente: EmailUtenteAPI.NewEmailUtente(this.state.utente)
        });

        this.setState({
            visibleLoader: false
        });
    }

    async refreshGrid() {

        this.setState({
            loadingGrid: true
        });

        const response = await authApi.getProfile();
        if (response?.message?.includes('200')) {
            const utente = response.result[0].username;
            this.setState({
                utente: utente,
                emails: await EmailUtenteAPI.GetByUtente(utente),
            });
        }

        this.setState({
            loadingGrid: false
        });
    }

    async componentDidMount() {
        await this.refreshGrid();
    }

    render() {
        return (
            <>
                {this.state.visibleLoader && <LoaderSpinner />}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="primary">
                                <h4 style={{ lineHeight: "0", display: "inline", verticalAlign: "bottom" }}>
                                    Gestione Email
                                </h4>

                                <FormControlLabel
                                    className="float-right" label={"Attiva Filtri"}
                                    style={{ verticalAlign: "center", lineHeight: "1", margin: "0.1%", color: "white" }}
                                    control={
                                        <Switch
                                            checked={this.state.filterGrid}
                                            onChange={this.ChangeFilterGrid}
                                            name="chkFilterGrid"
                                            color="default"
                                        />
                                    }
                                />

                                <Tooltip classes={{ tooltip: "tooltip-me" }} arrow
                                         id="tooltip-top" title="Inserisci" placement="top"
                                >
                                    <Button
                                        style={{ verticalAlign: "center", lineHeight: "1", margin: "0.1%" }}
                                        color="white" aria-label="edit" justIcon round
                                        className="float-right" onClick={this.onNuovoClick}
                                    >
                                        <AddIcon />
                                    </Button>
                                </Tooltip>
                            </CardHeader>
                            <CardBody>
                                <DataTable
                                    emptyMessage=""
                                    className="p-datatable-striped p-datatable-sm"
                                    value={this.state.emails}
                                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                    currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                                    loading={this.state.loadingGrid}
                                    paginatorLeft={this.paginatorLeft}
                                    paginatorRight={this.paginatorRight}
                                    selection={this.state.selectedGrid}
                                    scrollable paginator rows={10}
                                    style={{ width: "100%" }} dataKey="id"
                                    onSelectionChange={this.handleTableClick}
                                    selectionMode="single"
                                >
                                    <Column field="utente" header="Utente"/>
                                    <Column
                                        field="email"
                                        header="Email"
                                        sortable={true}
                                        filter={this.state.filterGrid}
                                        filterPlaceholder=""
                                    />
                                    <Column header="Password" field="password" body={row => <>{'•'.repeat(row.password?.length)}</> }/>
                                    <Column
                                        header="" icon="pi pi-times" label="Delete"
                                        body={this.DeleteGridTemplate}
                                        className="p-button-danger p-button-outlined colDelete"
                                    />
                                </DataTable>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                {this.state.selectedEmailUtente && (
                    <ModificaEmail
                        {...this.props}
                        selectedEmailUtente={{...this.state.selectedEmailUtente}}
                        onSaveClick={this.onSaveClick}
                        onAnnullaClick={this.onAnnullaClick}
                    />
                )}
            </>
        );
    }
}

export default GestioneEmail;