import React from "react";
import CustomInput from "components/CustomInput/CustomInput.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Dropdown } from "primereact/dropdown";
import Switch from "@material-ui/core/Switch";
import SedeComuneProvAPI from "API/SedeComuneProvAPI";

export default class ExtraFieldsModifica extends React.Component {
  state = {
    Text: "Indirizzo postalizzazione",
    isVisible: false,
    RowItem: null,
    ProvinciaList: [],
    selectedProvincia: null,
    selectedComune: null,
    notFilteredComune: [],
    filteredComune: []
  };

  constructor(props) {
    super(props);
    this.SetText = this.SetText.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.OnInputBlur = this.OnInputBlur.bind(this);
  }

  SetText(field, newValue) {
    const newItem = this.state.RowItem;
    newItem[field] = newValue;
    this.setState({ RowItem: newItem });
  }

  async OnInputBlur(newCAP) {
    if(!newCAP || newCAP.length < 5)
      return;
    var rowItem = this.state.RowItem;
    let selectedComune = this.state.notFilteredComune?.filter(
      (row) => row.cap === newCAP
    )[0];
    if (selectedComune !== null) {
      let comuneFiltered = this.state.notFilteredComune?.filter(
        (row) => row?.prov?.sigla === selectedComune?.prov?.sigla
      );
      let selectedProvincia = this.state.ProvinciaList?.filter(
        (row) => row?.sigla === selectedComune?.prov?.sigla
      )[0];
      this.setState({
        filteredComune: comuneFiltered,
        selectedComune: selectedComune?.nome,
        selectedProvincia: selectedProvincia?.sigla
      });

      rowItem["comunexposta"] = selectedComune?.nome;
      rowItem["provxposta"] = selectedProvincia?.sigla;
    } else {
      const comuneCandidates = await SedeComuneProvAPI.GetComuneByCap(newCAP);
      if (comuneCandidates?.length === 1) {
        rowItem["comunexposta"] = comuneCandidates[0].nome;
        rowItem["provxposta"] = comuneCandidates[0].prov;
      } else {
        rowItem["comunexposta"] = null;
        rowItem["provxposta"] = null;
      }

      this.setState({
        RowItem: rowItem,
        filteredComune: comuneCandidates,
        selectedComune: rowItem["comunexposta"],
        selectedProvincia: rowItem["provxposta"]
      });
    }
  }

  async HandleDropDownChange(e, val, propName) {
    var rowItem = this.state.RowItem;
    if (e === "selectedComune") {
      rowItem[propName] = val;
      this.setState({
        selectedComune: val
      });
      let cap = this.state.notFilteredComune?.filter(
        (row) => row?.nome === val
      )[0]?.cap;
      rowItem["capxposta"] = cap;
    } else if (e === "selectedProvincia") {
      rowItem["capxposta"]=null;
      rowItem[propName] = val;

      const comunebyprov = await SedeComuneProvAPI.GetComuneByProv(val);
      this.setState({
        filteredComune: comunebyprov,
        selectedProvincia: val,
        selectedComune: null
      });
    } else rowItem[propName] = val.id;

    this.setState({
      [e]: val,
      RowItem: rowItem
    });
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.dataItem.id !== prevProps.dataItem.id ||
      this.props.dataItem.indirizzoxposta !==
        prevProps.dataItem.indirizzoxposta ||
      this.props.dataItem.comunexposta !== prevProps.dataItem.comunexposta ||
      this.props.dataItem.capxposta !== prevProps.dataItem.capxposta ||
      this.props.dataItem.provxposta !== prevProps.dataItem.provxposta ||
      this.props.provincias !== prevProps.provincias ||
      this.props.comunes !== prevProps.comunes
    ) {
      const allComune = this.props.comunes
        ? this.props.comunes
        : await SedeComuneProvAPI.GetAllComune();
      const allProvincie = this.props.provincias
        ? this.props.provincias
        : await SedeComuneProvAPI.GetAllProvincieProvincie();

      let selectedProvincia =
        this.state.ProvinciaList !== null
          ? this.state.ProvinciaList?.filter(
              (row) => row.sigla === this.props.dataItem.provxposta
            )[0]?.sigla
          : [];

      let comuneFiltered = allComune?.filter(
        (c) => c.prov.sigla === this.props.dataItem.provxposta
      );
      if (selectedProvincia)
        comuneFiltered = allComune?.filter(
          (c) => c.prov.sigla === selectedProvincia
        );

      this.setState({
        ProvinciaList: allProvincie,
        RowItem: this.props.dataItem,
        filteredComune: comuneFiltered,
        notFilteredComune: allComune,
        selectedComune: this.props.dataItem.comunexposta,
        selectedProvincia: this.props.dataItem.provxposta
      });
    }
  }

  async componentDidMount() {
    if (this.props.dataItem !== null) {
      let comunebyprov = this.props.comunes;
      if(!!this.props.dataItem.provxposta){
        comunebyprov = await SedeComuneProvAPI.GetComuneByProv(this.props.dataItem.provxposta);
      }
      this.setState({
        RowItem: this.props.dataItem,
        ProvinciaList: this.props.provincias,
        filteredComune: comunebyprov,
        notFilteredComune: this.props.comunes,
        selectedComune: this.props.dataItem.comunexposta,
        selectedProvincia: this.props.dataItem.provxposta
      });
    }
  }

  render() {
    return (
      <div>
        <GridContainer>
          <FormControlLabel
            className="float-right"
            style={{
              verticalAlign: "center",
              lineHeight: "1",
              margin: "0.1%",
              color: "black"
            }}
            control={
              <Switch
                checked={this.state.isVisible}
                onChange={(e) => this.setState({ isVisible: e.target.checked })}
                name="chkFilterGrid"
                color="default"
              />
            }
            label={this.state.Text}
          />
        </GridContainer>

        {this.state.isVisible && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <CustomInput
                labelText="Indirizzo"
                id="indirizzo"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: false,
                  onChange: (event) =>
                    this.SetText("indirizzoxposta", event.target.value),
                  value: this.state.RowItem.indirizzoxposta || ""
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              <CustomInput
                labelText="CAP"
                id="cap"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  disabled: false,
                  onChange: (event) =>
                    this.SetText("capxposta", event.target.value),
                  onBlur: (event) => this.OnInputBlur(event.target.value),
                  value: this.state.RowItem.capxposta || ""
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={4} style={{ marginTop: "4px" }}>
              <p className="lblNoMargin label">
                <small>Comune</small>
              </p>
              <Dropdown
                  value={this.state.RowItem.comunexposta}
                  optionValue="nome"
                  className="dropdownCustomisedForMUI"
                  options={this.state.filteredComune}
                  resetFilterOnHide
                  onChange={(e) =>
                      this.HandleDropDownChange(
                          "selectedComune",
                          e.target.value,
                          "comunexposta"
                      )
                  }
                  optionLabel="nome"
                  filter
                  filterBy="nome"
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2} style={{ marginTop: "4px" }}>
              <p className="lblNoMargin label">
                <small>Provincia</small>
              </p>
              <Dropdown
                value={this.state.selectedProvincia}
                optionValue="sigla"
                className="dropdownCustomisedForMUI"
                resetFilterOnHide
                options={this.state.ProvinciaList}
                onChange={(e) =>
                  this.HandleDropDownChange(
                    "selectedProvincia",
                    e.target.value,
                    "provxposta"
                  )
                }
                optionLabel="sigla"
                filter
                filterBy="sigla"
              />
            </GridItem>
          </GridContainer>
        )}
      </div>
    );
  }
}
