import Utils from "../API/Utils";

const StaticLists = {
  GetCausaleInsert: async function () {
    const response = await Utils.CreatePostRequest("coasco/causali/getmax89");
    if (response === null) return [];
    return response.result;
  },

  GetCausaleUpdate: async function () {
    const response = await Utils.CreatePostRequest("coasco/causali/getall");
    if (response === null) return [];
    return response.result;
  },

  Corrisp_W350: function () {
    return [
      { id: "X", name: "Si" },
      { id: "N", name: "No" }
    ];
  },

  Corrisp_W300: function () {
    return [
      { id: "X", name: "Si" },
      { id: "N", name: "No" }
    ];
  },

  Corrisp_Amm: function () {
    return [
      { id: "X", name: "Si" },
      { id: "N", name: "No" }
    ];
  },

  Corrisp_Buchi: function () {
    return [
      { id: "X", name: "Si" },
      { id: "N", name: "No" }
    ];
  },

  Corrisp_Cust: function () {
    return [
      { id: "X", name: "Si" },
      { id: "N", name: "No" }
    ];
  },

  Corrisp_Account: function () {
    return [
      {
        id: 1,
        email: "fondocoasco@fondocoasco.it"
      },
      {
        id: 2,
        email: "claudia.cusano@fondocoasco.it"
      },
      {
        id: 3,
        email: "amedeo.cetorelli@fondocoasco.it"
      },
      {
        id: 4,
        email: "fondocoasco@registerpec.it"
      }
    ];
  },

  Corrisp_Account_ebin: function () {
    return [
      {
        id: 2,
        email: "info@ebinprof.it"
      },
      {
        id: 3,
        email: "presidenza@ebinprof.it"
      },
      {
        id: 4,
        email: "daniele.mazziotta@ebinprof.it"
      },
      {
        id: 5,
        email: "mg.didonato@ebinprof.it"
      },
      {
        id: 6,
        email: "privacy@ebinprof.it"
      },
      {
        id: 7,
        email: "ebinprof@pec.ebinprof.it"
      }
    ];
  },

  Corrisp_Puli: function () {
    return [
      { id: "X", name: "Si" },
      { id: "N", name: "No" }
    ];
  },

  Corrisp_TipoProtocollo: function () {
    return [
      { id: 1, name: "Entrata" },
      { id: 2, name: "Uscita" }
    ];
  },

  Sesso: function () {
    return [
      { id: 1, name: "F" },
      { id: 2, name: "M" }
    ];
  },

  CodiceQuota: function () {
    return [
      { id: 1, name: "CUST" },
      { id: 2, name: "PULI" },
      { id: 3, name: "W300" },
      { id: 4, name: "W350" }
    ];
  },

  PeriodoPunteggi: function () {
    return [
      { id: 1, name: "Due anni" },
      { id: 2, name: "Tre anni" },
      { id: 3, name: "Quattro anni" },
      { id: 5, name: "Cinque anni" },
      { id: 6, name: "Sei anni" }
    ];
  },

  GiaScritto: function () {
    return [
      { id: 1, name: "Si" },
      { id: 2, name: "No" }
    ];
  },

  EmailAziende: function () {
    return [
      { id: 1, name: "Amm" },
      { id: 2, name: "Cons" }
    ];
  },

  Caratteristiche_Destinatario: function () {
    return [
      { id: 1, name: "Destinatari Attivi", cod: "AT" },
      { id: 2, name: "Destinatari con E-mail presente", cod: "EM" }
    ];
  },

  TipoDestinatario: function () {
    return [
      { name: "Aziende", code: "AZ" },
      { name: "Amministratori", code: "AM" },
      { name: "Consulenti", code: "CO" },
      { name: "Dipendenti", code: "DI" }
    ];
  }
};

export default StaticLists;
