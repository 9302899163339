import React, { Component } from "react";
import clone from "clone";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import ExtraFieldsModifica from "components/Views/Aziende/ExtraFieldsModifica.js";
import { Calendar } from "primereact/calendar";
import Switch from "@material-ui/core/Switch";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import CustomButtons from "components/CustomButtons/Button.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import MenuItem from "@material-ui/core/MenuItem";
import queryString from "query-string";
import Close from "@material-ui/icons/Close";
import { Button } from "primereact/button";
import CardFooter from "components/Card/CardFooter.js";
import ButtonsMUI from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { Dialog } from "primereact/dialog";
import { ModificaRichiesti } from "components/Views/Richiesti/ModificaRichiesti.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LoaderSpinner from "components/Layout/LoaderSpinner.js";
import { confirmPopup } from "primereact/confirmpopup";
import StaticLists from "helpers/StaticLists.js";
import helpers from "helpers/Helpers";
import RichiedentiAPI from "API/RichiedentiAPI";
import RichiestiAPI from "API/RichiestiAPI";
import SedeComuneProvAPI from "API/SedeComuneProvAPI";
import NazionalitaAPI from "API/NazionalitaAPI";
import CustomButton from "components/CustomButtons/Button.js";
import UndoIcon from "@material-ui/icons/Undo";
import RegioniAPI from "API/RegioniAPI.js";
import { dateTimeFmt, rowsPerPageOptions } from "helpers/AppConstants.js";
import { dateFmt } from "helpers/AppConstants.js";
import moment from "moment";
import { validateFiscalCode } from "utils";
import { AutoComplete } from "primereact/autocomplete";
import ParentelaAPI from "API/ParentelaAPI";

class InserisciModificaRichiedenti extends Component {
  state = {
    RowItem: null,
    ModificaTitle: "Nuovo",

    rows: [],
    selectedGrid: null,
    loadingGrid: false,
    filterGrid: false,
    groupClass: "",
    openPopUp: false,
    visibleHeader: false,
    RichiestiItem: [],
    visibleButtons: false,
    selectedRegione: null,
    selectedNazionalita: null,
    selectedSesso: null,
    sesso: [],
    showAddress: false,
    nazione: [],
    firstRowToDisplay: 0,
    loadingPage: false,
    rowsPerPage: 5,
    newRichiedente: true,
    reg: [],

    //VALIDATIONS
    fieldValidations: {},
    validationRules: {
      nome: "",
      cognome: "",
      cfRich: "CF",
      sesso: "",
      luogoNasc: "",
      dataNasc: "",
      nazione: "",
      indirizzo: "",
      cap: "",
      comune: "",
      prov: "",
      reg: ""
    }
  };

  constructor(props) {
    super(props);
    this.onBackClick = this.onBackClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.btnSearchRef = React.createRef();
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.onCorrispClick = this.onCorrispClick.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.DocActionsGridTemplate = this.DocActionsGridTemplate.bind(this);
    this.onRedirectClick = this.onRedirectClick.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.onSaveRichiesteClick = this.onSaveRichiesteClick.bind(this);
    this.DeleteButtonTemplate = this.DeleteButtonTemplate.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.handleDeleteRequest = this.handleDeleteRequest.bind(this);
    this.refreshRequestsTable = this.refreshRequestsTable.bind(this);
    this.searchProvince = this.searchProvince.bind(this);
    this.getRelationshipTypes = this.getRelationshipTypes.bind(this);
    this.OnInputBlur = this.OnInputBlur.bind(this);
  }

  async OnInputBlur(newCAP) {
    if(!newCAP || newCAP.length < 5)
      return;
    let rowItem = this.state.RowItem;
    let selectedComune = this.state?.notFilteredComune.filter(
      (row) => row?.cap === newCAP
    )[0];
    if (selectedComune !== null) {
      let comuneFiltered = this.state?.notFilteredComune.filter(
        (row) => row?.prov?.sigla === selectedComune?.prov?.sigla
      );
      let selectedProvincia = this.state.ProvinciaList.filter(
        (row) => row?.sigla === selectedComune?.prov?.sigla
      )[0];

      const selectedRegion = this.state?.reg.filter((x) => {
        return x.id === selectedProvincia?.regione?.id;
      })[0];

      rowItem["comune"] = selectedComune?.nome;
      rowItem["prov"] = selectedProvincia?.sigla;
      rowItem["reg"] = selectedRegion?.id;

      this.setState({
        RowItem: rowItem,
        filteredComune: comuneFiltered,
        selectedComune: selectedComune.nome,
        selectedRegione: selectedRegion?.id
      });
    } else {
      rowItem["comune"] = null;
      rowItem["prov"] = null;
      rowItem["reg"] = null;

      this.setState({
        RowItem: rowItem,
        filteredComune: [],
        selectedComune: null,
        selectedProvincia: null,
        selectedRegione: null
      });
    }
  }

  //#region DELETE
  DeleteButtonTemplate(rowData) {
    return (
      <CustomButtons
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData.idRichies}
        onClick={(e) => {
          this.onDeleteClick(e, rowData.idRichies);
        }}
      >
        <Close className="icon" />
      </CustomButtons>
    );
  }

  async handleDeleteRequest(reqId) {
    this.setState({ loadingGrid: true });
    const response = await RichiestiAPI.DeleteRichiesta(reqId);
    if (response && response?.result && response?.result?.length > 0) {
      this.props.addAlert.current.addAlert("success", "Richiesta eliminata");
      this.refreshRequestsTable();
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "Errore nell'eliminazione della richiesta"
      );
    }
  }

  async refreshRequestsTable() {
    this.setState({ loadingGrid: true });
    const submittedReqs = await RichiestiAPI.GetRichiestePresentate(
      this.state.RowItem.cfRich
    );
    if (submittedReqs && submittedReqs?.length > 0)
      this.setState({ rows: submittedReqs });
    else {
      this.setState({ rows: [] });
      this.props.addAlert.current.addAlert(
        "warning",
        `Il richiedente ${this.state.RowItem.cfRich} non ha ulteriori richieste`
      );
    }

    this.setState({ loadingGrid: false });
  }

  onDeleteClick(event, id) {
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => this.handleDeleteRequest(id),
      reject: () => {}
    });
  }

  isValidForm() {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    if (this.state.RowItem.id !== null) {
      // For update, skip validation for some fields
      validationRules["cfRich"] = "";
    }

    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);
    let isValid = Object.values(fieldValidations).every((x) => x === "success");
    this.setState({
      fieldValidations: fieldValidations
    });
    return isValid;
  }

  async onNuovoClick() {
    this.setState({
      openPopUp: true
    });
  }

  HandleDropDownChange(e, val, propName) {
    let rowItem = this.state.RowItem;
    rowItem[propName] = val;

    this.setState({
      [e]: val,
      RowItem: rowItem
    });
  }

  onClose() {
    this.setState({
      openPopUp: false
    });
  }

  onCorrispClick() {
    if (!this.state.newRichiedente)
      this.props.history.push(
        "/admin/richiedenti/corrisp/" + this.props.match.params.richiedentiId
      );
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: e.value
    });
  };

  OnInputBlurCF = async (fiscalCode) => {
    const isFiscalCodeValid = validateFiscalCode(fiscalCode, true);
    if (!isFiscalCodeValid?.isValid)
      this.props.addAlert.current.addAlert(
        "warning",
        isFiscalCodeValid?.errorMessage
      );
    else {
      const res = await RichiedentiAPI.getByCF(fiscalCode);
      res &&
        res[0] &&
        this.props.history.push(
          `/admin/richiedenti/modificainserisci/${res[0]?.idRichied}`
        );

      const nationalityCode = fiscalCode.substr(11, 4).toUpperCase();
      const resNationality = await NazionalitaAPI.GetNazionalitaByCode(
        nationalityCode
      );
      const rowItem = this.state.RowItem;
      if (resNationality && resNationality.length > 0) {
        const nationality = resNationality[0];
        rowItem.nazione = nationality?.codice;
        this.setState({
          selectedNazionalita: nationality?.codice,
          RowItem: rowItem
        });
      } else {
        rowItem.nazione = "I000";
        this.setState({
          selectedNazionalita: "I000",
          RowItem: rowItem
        });
      }
    }
  };

  ChangeButtons = async (existentConsulenti) => {
    this.setState({
      selectedGrid: null,
      RowItem: existentConsulenti,
      viewButtons: false,
      viewNuovoConsulente: false
    });
  };

  async getRelationshipTypes() {
    const res = await ParentelaAPI.GetAllTipoParentela();
    this.setState({ relationshipTypes: res });
  }

  async componentDidMount() {
    if (!this.props.match) {
      const allComune = await SedeComuneProvAPI.GetAllComune();
      const allProvincie = await SedeComuneProvAPI.GetAllProvincieProvincie();
      const allNazionalita = await NazionalitaAPI.GetAll();
      const allRegions = await RegioniAPI.GetRegioni();
      this.getRelationshipTypes();

      this.setState({
        newRichiedente: true,
        nazione: allNazionalita,
        sesso: await StaticLists.Sesso(),
        RowItem: await RichiedentiAPI.GetNuovoRichiedentiBorseStudio(),
        ModificaTitle: "Nuovo",
        visibleButtons: false,
        groupClass: "hide",
        isVisible: false,
        ProvinciaList: allProvincie,
        filteredComune: allComune,
        notFilteredComune: allComune,
        provinces: allProvincie,
        filteredProvinces: allProvincie,
        reg: allRegions,
        selectedSesso: null
      });
      try {
        this.btnSearchRef.current.scrollIntoView();
      } catch(err){}
    } else {
      this.setState({
        loadingGrid: true
      });

      const id = this.props.match.params.richiedentiId;
      let selectedRow = null;
      let firstRowToDisplay = 0;
      const richiedente = await RichiedentiAPI.GetRichiedentiBorseStudio(id);
      if (!richiedente) {
        this.props.addAlert.current.addAlert(
          "danger",
          `richiedente non trovato`
        );
        return;
      }

      let qS = queryString.parse(this.props.history.location.search);
      if (qS.typeId > 0) {
        selectedRow = richiedente;
        firstRowToDisplay = richiedente.idRichied;
      }

      if (selectedRow)
        await this.handleTableClick({
          value: selectedRow
        });
      const allComune = await SedeComuneProvAPI.GetAllComune();
      const filteredComune = await SedeComuneProvAPI.GetComuneByProv(
        richiedente?.prov
      );
      const allProvincie = await SedeComuneProvAPI.GetAllProvincieProvincie();
      const allNazionalita = await NazionalitaAPI.GetAll();
      const allRegions = await RegioniAPI.GetRegioni();
      const submittedReqs = await RichiestiAPI.GetRichiestePresentate(
        richiedente.cfRich
      );

      this.setState({
        newRichiedente: false,
        firstRowToDisplay: firstRowToDisplay,
        rows: submittedReqs ? submittedReqs : [],
        nazione: allNazionalita,
        sesso: await StaticLists.Sesso(),
        reg: allRegions,
        RowItem: richiedente,
        ModificaTitle: "Modifica",
        visibleButtons: true,
        groupClass: "",
        isVisible: true,
        selectedProvincia: richiedente?.prov,
        filteredComune: filteredComune,
        notFilteredComune: allComune,
        selectedSesso: richiedente.sesso,
        ProvinciaList: allProvincie,
        provinces: allProvincie,
        filteredProvinces: allProvincie,
      });
      this.getRelationshipTypes();
      this.setState({
        loadingGrid: false
      });
    }

    if (this.state.RowItem !== null) {
      this.setState({
        selectedRegione: helpers.ReturnIdFromList(
          this.state.reg,
          this.state.RowItem.reg
        ),
        selectedNazionalita: this.state.RowItem.nazione
      });
    }
  }

  searchProvince(event) {
    setTimeout(() => {
      let _filteredProvinces;
      if (!event.query.trim().length) {
        _filteredProvinces = [...this.state?.provinces];
      } else {
        _filteredProvinces = this.state?.provinces.filter((province) => {
          return province.nome
            .toLowerCase()
            .startsWith(event.query.toLowerCase());
        });
      }

      this.setState({
        filteredProvinces: _filteredProvinces
      });
    }, 250);
  }

  DocActionsGridTemplate(rowData) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <CustomButton
          round
          color="info"
          className="actionButton actionButtonRound btnRound  "
          key={rowData.Id}
          onClick={(e) => this.onRedirectClick(e, rowData)}
        >
          <VisibilityIcon className="icon" />
        </CustomButton>
      </div>
    );
  }

  onRedirectClick(e, rowData) {
    e.stopPropagation();
    this.props.history.push(
      `/admin/richiedenti/modificarichieste/${this.props.match.params.richiedentiId}/${rowData.idRichies}`
    );
  }

  SetText(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
  }

  HandleChange(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
  }

  onBackClick() {
    let maintainSearch = "?maintainSearch=1";
    this.props.history.push(
      "/admin/richiedenti/" +
        this.props.match.params.richiedentiId +
        maintainSearch
    );
  }

  async onSaveRichiesteClick(richiestaObj) {
    const data = {
      ...richiestaObj,
      dataArr: moment(richiestaObj.dataArr).format(dateTimeFmt)
    };
    const response = await RichiestiAPI.AddRichiesta(data);
    if (response && response?.result && response?.result?.length > 0) {
      this.props.addAlert.current.addAlert(
        "success",
        "la richiesta è stato aggiunta."
      );
      this.props.history.push(
        `/admin/richiedenti/modificarichieste/${this.props.match.params.richiedentiId}/${response.result[0].idRichies}`
      );
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "errore nel salvataggio della richiesta"
      );
    }
    this.setState({ openPopUp: false });
  }

  HandleSaveClick = async () => {
    if (this.state.newRichiedente) {
      const response = await RichiedentiAPI.AddRichiedenti({
        ...this.state.RowItem,
        prov: this.state.selectedProvincia?.sigla || this.state.selectedProvincia
      });
      if (response && response?.result && response?.result?.length > 0) {
        this.props.addAlert.current.addAlert(
          "success",
          `Dati salvati con successo`
        );
        // this.props.
      } else {
        this.props.addAlert.current.addAlert(
          "danger",
          "errore nel salvataggio"
        );
      }
    } else {
      const response = await RichiedentiAPI.UpdateRichiedenti({
        ...this.state.RowItem,
        prov: this.state.selectedProvincia?.sigla || this.state.selectedProvincia
      });
      if (response && response?.result && response?.result?.length > 0) {
        this.props.addAlert.current.addAlert(
          "success",
          `Il richiedente è stato aggiornato.`
        );
      } else {
        this.props.addAlert.current.addAlert(
          "danger",
          "errore nel salvataggio"
        );
      }
    }
    // true makes save button to stop any animation
    return true;
  };

  stampaPDF = async (id) => {
    this.setState({ loadingPage: true });
    await RichiedentiAPI.StampaPDF(id, "Scheda Richiedente");
    this.setState({ loadingPage: false });
  };

  render() {
    const richiestePresentate = (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="borsesuccess">
              <h4
                style={{
                  lineHeight: "0",
                  display: "inline",
                  verticalAlign: "bottom"
                }}
              >
                Richieste presentate
              </h4>
              <FormControlLabel
                className="float-right"
                style={{
                  verticalAlign: "center",
                  lineHeight: "1",
                  margin: "0.1%",
                  marginRight: "6px",
                  color: "white"
                }}
                control={
                  <Switch
                    checked={this.state.filterGrid}
                    onChange={this.ChangeFilterGrid}
                    name="chkFilterGrid"
                    color="default"
                  />
                }
                label={"Attiva Filtri"}
              />
              <Tooltip
                arrow
                id="tooltip-top"
                title="Inserisci"
                placement="top"
                classes={{ tooltip: "tooltip-me" }}
              >
                <CustomButtons
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%"
                  }}
                  color="white"
                  aria-label="edit"
                  justIcon
                  round
                  className="float-right"
                  onClick={this.onNuovoClick}
                >
                  <AddIcon />
                </CustomButtons>
              </Tooltip>
            </CardHeader>

            <CardBody>
              <DataTable
                emptyMessage=""
                className="p-datatable-striped p-datatable-sm"
                value={this.state.rows}
                dataKey="idRichies"
                paginator
                first={this.state.firstRowToDisplay}
                onPage={(e) =>
                  this.setState({
                    firstRowToDisplay: e.first,
                    rowsPerPage: e.rows
                  })
                }
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                rows={this.state.rowsPerPage}
                rowsPerPageOptions={rowsPerPageOptions}
                loading={this.state.loadingGrid}
                paginatorLeft={this.paginatorLeft}
                paginatorRight={this.paginatorRight}
                selection={this.state.selectedGrid}
                scrollable
                scrollDirection="horizontal"
                style={{ width: "100%" }}
                onSelectionChange={this.handleTableClick}
                selectionMode="single"
              >
                <Column
                  header=""
                  headerStyle={{
                    width: "60px",
                    textAlign: "center"
                  }}
                  body={this.DocActionsGridTemplate}
                  className="colDocActions"
                />
                <Column
                  field="protRichies"
                  header="Prot. Richiesta"
                  headerStyle={{ width: "160px" }}
                  sortable={true}
                  filter={this.state.filterGrid}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  field="idBorsa.annoAcc.descr"
                  header="Anno"
                  headerStyle={{ width: "90px" }}
                  sortable={true}
                  filter={this.state.filterGrid}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  field="idBorsa.tipoBorsa.descrBr"
                  header="Tipo"
                  headerStyle={{ width: "90px" }}
                  sortable={true}
                  filter={this.state.filterGrid}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  field="statoLavor"
                  header="Stato"
                  headerStyle={{ width: "100px" }}
                  body={(row) => row.statoLavor?.descr || ""}
                  sortable={true}
                  filter={this.state.filterGrid}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  field="esito"
                  header="Esito"
                  headerStyle={{ width: "100px" }}
                  body={(row) => row.esito?.descr || ""}
                  sortable={true}
                  filter={this.state.filterGrid}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  field="voto"
                  header="Voto"
                  headerStyle={{ width: "90px" }}
                  sortable={true}
                  filter={this.state.filterGrid}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  field="lode"
                  header="Lode"
                  headerStyle={{ width: "80px" }}
                  sortable={true}
                  body={(row) => (row.lode === -1 ? "Si" : "No")}
                  filter={this.state.filterGrid}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  field="mediaPes"
                  header="Media Pesata"
                  headerStyle={{ width: "140px" }}
                  sortable={true}
                  filter={this.state.filterGrid}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  field="cfDip"
                  header="Dipendente"
                  headerStyle={{ width: "150px" }}
                  sortable={true}
                  filter={this.state.filterGrid}
                  filterMatchMode="contains"
                  filterPlaceholder=""
                />
                <Column
                  header=""
                  headerStyle={{ width: "35px" }}
                  body={this.DeleteButtonTemplate}
                  icon="pi pi-times"
                  label="Delete"
                  className="p-button-danger p-button-outlined colDelete"
                />
              </DataTable>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );

    if (this.state.RowItem === null)
      return (
        <>
          {" "}
          <LoaderSpinner fullHeight={false} />{" "}
        </>
      );
    else
      return (
        <>
          {this.state.loadingPage && <LoaderSpinner />}
          <Dialog
            header="Nuova Richiesta"
            visible={this.state.openPopUp}
            style={{ width: "50vw" }}
            onHide={this.onClose}
          >
            <ModificaRichiesti
              candidate={this.state.RowItem}
              onSaveClick={this.onSaveRichiesteClick}
              onBackClick={this.AnnullaSelez}
              hideHeader={this.state.visibleHeader}
            />
          </Dialog>

          <div ref={this.btnSearchRef} />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="borseinfo">
                  {!this.state.newRichiedente && (
                    <Button
                      icon="pi pi-arrow-left"
                      onClick={this.onBackClick}
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      style={{ verticalAlign: "bottom", marginRight: "2px" }}
                    />
                  )}

                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.state.ModificaTitle} Richiedente
                  </h4>

                  {this.state.visibleButtons && (
                    <ButtonGroup
                      variant="contained"
                      color="default"
                      size="small"
                      aria-label="contained default button group"
                      className="float-right"
                      style={{ verticalAlign: "center", margin: "0.5%" }}
                    >
                      <ButtonsMUI
                        onClick={() =>
                          this.stampaPDF(this.state.RowItem.idRichied)
                        }
                      >
                        Stampa Scheda
                      </ButtonsMUI>
                      <ButtonsMUI
                        onClick={() =>
                          this.props.history.push(
                            `/admin/richiedenti/corrisp/${this.state.RowItem.idRichied}`
                          )
                        }
                      >
                        Corrispondenza
                      </ButtonsMUI>
                    </ButtonGroup>
                  )}

                  {this.state.newRichiedente && (
                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Annulla"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <CustomButtons
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="float-right"
                        onClick={this.props.onAnnullaClick}
                      >
                        <UndoIcon />
                      </CustomButtons>
                    </Tooltip>
                  )}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        labelText="Id"
                        id="id-disabled"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.idRichied || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.cfRich &&
                          this.state.fieldValidations.cfRich === "success"
                        }
                        error={
                          this.state.fieldValidations.cfRich &&
                          this.state.fieldValidations.cfRich === "error"
                        }
                        labelText="Codice Fiscale *"
                        id="codfis"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.visibleButtons,
                          onChange: (event) =>
                            this.HandleChange("cfRich", event.target.value),
                          onBlur: (event) =>
                            this.OnInputBlurCF(event.target.value),
                          value: this.state.RowItem.cfRich || "",
                          maxLength: 16
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Data Inserimento"
                        id="DataInser"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value:
                            moment(
                              this.state.RowItem.dataIns,
                              dateTimeFmt
                            ).format(dateFmt) || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Ultimo Aggiornamento"
                        id="UltimoAgg"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value:
                            moment(
                              this.state.RowItem.dataAgg,
                              dateTimeFmt
                            ).format(dateFmt) || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={2} md={3} className="select">
                      <CustomSelect
                        success={
                          this.state.fieldValidations.sesso &&
                          this.state.fieldValidations.sesso === "success"
                        }
                        error={
                          this.state.fieldValidations.sesso &&
                          this.state.fieldValidations.sesso === "error"
                        }
                        labelText="Sesso *"
                        id="sesso"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (e) =>
                            this.HandleDropDownChange(
                              "selectedSesso",
                              e.target.value,
                              "sesso"
                            ),
                          value: this.state.selectedSesso || ""
                        }}
                      >
                        {this.state.sesso?.map((item) => (
                          <MenuItem value={item.name} key={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={5}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.cognome &&
                          this.state.fieldValidations.cognome === "success"
                        }
                        error={
                          this.state.fieldValidations.cognome &&
                          this.state.fieldValidations.cognome === "error"
                        }
                        labelText="Cognome *"
                        id="cognome"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.visibleButtons,
                          onChange: (event) =>
                            this.HandleChange("cognome", event.target.value),
                          value: this.state.RowItem.cognome || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={7}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.nome &&
                          this.state.fieldValidations.nome === "success"
                        }
                        error={
                          this.state.fieldValidations.nome &&
                          this.state.fieldValidations.nome === "error"
                        }
                        labelText="Nome *"
                        id="nome"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.visibleButtons,
                          onChange: (event) =>
                            this.HandleChange("nome", event.target.value),
                          value: this.state.RowItem.nome || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.luogoNasc &&
                          this.state.fieldValidations.luogoNasc === "success"
                        }
                        error={
                          this.state.fieldValidations.luogoNasc &&
                          this.state.fieldValidations.luogoNasc === "error"
                        }
                        labelText="Nato a *"
                        id="nato"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleChange("luogoNasc", event.target.value),
                          value: this.state.RowItem.luogoNasc || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <p className="calendarParagraph">
                        <small>Provincia</small>
                      </p>
                      <AutoComplete
                        style={{ height: "26px" }}
                        field="nome"
                        value={this.state.selectedProvincia || ""}
                        suggestions={this.state.filteredProvinces}
                        completeMethod={this.searchProvince}
                        onChange={(e) => {
                          this.HandleDropDownChange(
                            "selectedProvincia",
                            e.target.value,
                            "prov"
                          );
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={6}
                      md={3}
                      style={{ marginTop: "2px" }}
                    >
                      <p
                        className="calendarParagraph"
                        style={{
                          color:
                            this.state.fieldValidations["dataNasc"] === "error"
                              ? "#f44336"
                              : "#565656",
                          position: "relative",
                          top: "5px"
                        }}
                      >
                        <small>Data Nascita *</small>
                      </p>
                      <Calendar
                        required={true}
                        success={
                          this.state.fieldValidations.dataNasc &&
                          this.state.fieldValidations.dataNasc === "success"
                        }
                        error={
                          this.state.fieldValidations.dataNasc &&
                          this.state.fieldValidations.dataNasc === "error"
                        }
                        className="calendarCustomedForMUI"
                        view="date"
                        id="dataconst"
                        value={moment(
                          this.state.RowItem.dataNasc,
                          dateTimeFmt
                        ).toDate()}
                        dateFormat="dd/mm/yy"
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        onChange={(e) =>
                          this.HandleChange(
                            "dataNasc",
                            moment(e.target.value).format(dateTimeFmt)
                          )
                        }
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={3} md={3} className="select">
                      <CustomSelect
                        success={
                          this.state.fieldValidations.nazione &&
                          this.state.fieldValidations.nazione === "success"
                        }
                        error={
                          this.state.fieldValidations.nazione &&
                          this.state.fieldValidations.nazione === "error"
                        }
                        labelText="Nazionalità *"
                        id="nat"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (e) =>
                            this.HandleDropDownChange(
                              "selectedNazionalita",
                              e.target.value,
                              "nazione"
                            ),
                          value: this.state.selectedNazionalita || ""
                        }}
                      >
                        {this.state.nazione?.map((item) => (
                          <MenuItem value={item.codice} key={item.codice}>
                            {item.codice} - {item.nazione}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.localita &&
                          this.state.fieldValidations.localita === "success"
                        }
                        error={
                          this.state.fieldValidations.localita &&
                          this.state.fieldValidations.localita === "error"
                        }
                        labelText="Località Residenza"
                        id="localita"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleChange("localita", event.target.value),
                          value: this.state.RowItem.localita || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.comune &&
                          this.state.fieldValidations.comune === "success"
                        }
                        error={
                          this.state.fieldValidations.comune &&
                          this.state.fieldValidations.comune === "error"
                        }
                        labelText="Comune Residenza *"
                        id="comuneres"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleChange("comune", event.target.value),
                          value: this.state.RowItem.comune || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.indirizzo &&
                          this.state.fieldValidations.indirizzo === "success"
                        }
                        error={
                          this.state.fieldValidations.indirizzo &&
                          this.state.fieldValidations.indirizzo === "error"
                        }
                        labelText="Indirizzo Residenza *"
                        id="residenza"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleChange("indirizzo", event.target.value),
                          value: this.state.RowItem.indirizzo || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.cap &&
                          this.state.fieldValidations.cap === "success"
                        }
                        error={
                          this.state.fieldValidations.cap &&
                          this.state.fieldValidations.cap === "error"
                        }
                        labelText="CAP *"
                        id="cap"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleChange("cap", event.target.value),
                          onBlur: (event) =>
                            this.OnInputBlur(event.target.value),
                          value: this.state.RowItem.cap || "",
                          maxLength: 5
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={1}>
                      <CustomInput
                        success={
                          this.state.fieldValidations?.prov &&
                          this.state.fieldValidations?.prov === "success"
                        }
                        error={
                          this.state.fieldValidations?.prov &&
                          this.state.fieldValidations?.prov === "error"
                        }
                        labelText="Prov. *"
                        id="prov"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleChange("provNasc", event.target.value),
                          value:
                            this.state.RowItem?.provNasc?.sigla ||
                            this.state.RowItem?.provNasc ||
                            ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} className="select">
                      <CustomSelect
                        success={
                          this.state.fieldValidations.reg &&
                          this.state.fieldValidations.reg === "success"
                        }
                        error={
                          this.state.fieldValidations.reg &&
                          this.state.fieldValidations.reg === "error"
                        }
                        labelText="Regione *"
                        id="reg"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (e) =>
                            this.HandleDropDownChange(
                              "selectedRegione",
                              e.target.value,
                              "reg"
                            ),
                          value: this.state.selectedRegione || ""
                        }}
                      >
                        {this.state.reg?.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.nome}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Telefono"
                        id="telefono"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleChange("tel", event.target.value),
                          value: this.state.RowItem.tel || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Cellulare"
                        id="cellulare"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleChange("cell", event.target.value),
                          value: this.state.RowItem.cell || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="E-mail"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleChange("email", event.target.value),
                          value: this.state.RowItem.email || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="PEC"
                        id="pec"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("pec", event.target.value),
                          value: this.state.RowItem.pec || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="IBAN del Richiedente "
                        id="ibanRichiedente"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) => {
                            if (event.target.value.length <= 27)
                              this.SetText("iban_rich", event.target.value);
                          },
                          onBlur: (event) => {
                            if (
                              event.target.value.length < 27 &&
                              event.target.value?.trim() !== ""
                            )
                              this.props.addAlert.current.addAlert(
                                "warning",
                                "IBAN troppo corto"
                              );
                          },
                          value: this.state.RowItem.iban_rich || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="IBAN Intestato a Terzi"
                        id="ibanTerzi"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) => {
                            if (event.target.value.length <= 27)
                              this.SetText("iban_delegato", event.target.value);
                          },
                          onBlur: (event) => {
                            if (
                              event.target.value.length < 27 &&
                              event.target.value?.trim() !== ""
                            )
                              this.props.addAlert.current.addAlert(
                                "warning",
                                "IBAN troppo corto"
                              );
                          },
                          value: this.state.RowItem.iban_delegato || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Nominativo Delegato"
                        id="iban"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText(
                              "iban_delegato_nominativo",
                              event.target.value
                            ),
                          value:
                            this.state.RowItem.iban_delegato_nominativo || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} className="select">
                      <CustomSelect
                        labelText="Rapporto Familiare Delegato"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (e) =>
                            this.HandleDropDownChange(
                              "rapporto_familiare_delegato",
                              e.target.value,
                              "rapporto_familiare_delegato"
                            ),
                          value:
                            this.state?.RowItem?.rapporto_familiare_delegato
                        }}
                      >
                        {this.state.relationshipTypes?.map(
                          (relationshipType) => (
                            <MenuItem
                              value={relationshipType.descr}
                              key={relationshipType.id}
                            >
                              {relationshipType.descr}
                            </MenuItem>
                          )
                        )}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Note"
                        id="note"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("note", event.target.value),
                          value: this.state.RowItem.note || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <ExtraFieldsModifica
                    dataItem={ this.state.RowItem}
                    provincias={this.state.ProvinciaList}
                    comunes={this.state.notFilteredComune}
                  />
                </CardBody>

                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                    addAlert={this.props.addAlert}
                  />
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>

          {!this.state.newRichiedente && richiestePresentate}
        </>
      );
  }
}
export default InserisciModificaRichiedenti;
