import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import { FileUpload } from "primereact/fileupload";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { InputText } from "primereact/inputtext";
import CaricamentiAPI from "API/CaricamentiAPI";
import { SiteUrl } from "helpers/AppConstants";
import { Calendar } from "primereact/calendar";
import { getToken } from "../../common";
import { Dropdown } from "primereact/dropdown";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";

class CaricamentoF24 extends React.Component {
  state = {
    Progressivo: new Date().getMonth() + 1,
    Progressivi: [
      { label: 1, value: 1 },
      { label: 2, value: 2 },
      { label: 3, value: 3 },
      { label: 4, value: 4 },
      { label: 5, value: 5 },
      { label: 6, value: 6 },
      { label: 7, value: 7 },
      { label: 8, value: 8 },
      { label: 9, value: 9 },
      { label: 10, value: 10 },
      { label: 11, value: 11 },
      { label: 12, value: 12 }
    ],
    datain: new Date(),
    datafn: new Date(),
    anno: new Date().getFullYear().toString(),
    optionsAnno: CaricamentiAPI.getYearsFromNow(),
    disabled: true,
    fileUpload: null,
    loaderSpinner: false
  };

  constructor(props) {
    super(props);
    this.HandleChange = this.HandleChange.bind(this);
    this.onError = this.onError.bind(this);
    this.btnSearchRef = React.createRef();
    this.onSelect = this.onSelect.bind(this);
    this.onUpload = this.onUpload.bind(this);
  }

  onUpload(e) {
    const response = JSON.parse(e?.xhr?.response);
    if (response?.message?.includes("200")) {
      this.props.addAlert.current.addAlert("success", "File preso in carica");
    } else {
      this.props.addAlert.current.addAlert("danger", response?.message);
    }
    this.setState({loaderSpinner: false});
  }

  onSelect(e) {
    if (e.files.length > 0) {
      for (const file of e.files) {
        if (
          !file.name.toUpperCase().startsWith("CUSTF24AZIENDE") &&
          !file.name.toUpperCase().startsWith("PULIF24AZIENDE")
        ) {
          this.props.addAlert.current.addAlert(
            "danger",
            "Uno o più file non rispetta il formato del nome"
          );
          this.fileUpload.clear();
        }
      }
    }
  }

  HandleChange(e, val) {
    this.setState(
      {
        [e]: val
      },
      () => {
        if (
          !this.state.Progressivo ||
          !this.state.endDate ||
          !this.state.startDate
        )
          this.setState({ disabled: true });
        else this.setState({ disabled: false });
      }
    );
  }

  dateHandleChange(e, date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const _day = day < 10 ? `0${day}` : day;
    const _month = month < 10 ? `0${month}` : month;
    const _date = `${_day}/${_month}/${year}`;

    this.setState(
      {
        [e]: _date
      },
      () => {
        if (
          !this.state.Progressivo ||
          !this.state.endDate ||
          !this.state.startDate
        )
          this.setState({ disabled: true });
        else this.setState({ disabled: false });
      }
    );
  }

  onError(e) {
    const response = JSON.parse(e?.xhr?.response);
    if (response?.message?.includes("200")) {
      this.props.addAlert.current.addAlert("success", "File preso in carica");
    } else {
      this.props.addAlert.current.addAlert("danger", response?.message);
    }
    this.setState({loaderSpinner: false});
  }

  render() {
    return (
      <>
        {this.state.loaderSpinner && <LoaderSpinner />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Funzionalita di supporto: Carica i dati degli F24
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2} className="customdate">
                    <p className="lblNoMargin">Anno *</p>
                    <Dropdown
                      value={this.state.anno}
                      options={this.state.optionsAnno}
                      onChange={(e) => this.HandleChange("anno", e.value)}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2}>
                    <p className="lblNoMargin">Mese *</p>
                    <Dropdown
                      value={this.state.Progressivo}
                      options={this.state.Progressivi}
                      onChange={(e) =>
                        this.HandleChange("Progressivo", e.target.value)
                      }
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2} className="customdate">
                    <p className="lblNoMargin">Data inizio *</p>
                    <Calendar
                      id="icon"
                      value={this.state.startDate}
                      dateFormat="dd/mm/yy"
                      onChange={(e) =>
                        this.dateHandleChange("startDate", e.value)
                      }
                      showIcon
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={2} className="customdate">
                    <p className="lblNoMargin">Data fine *</p>
                    <Calendar
                      id="icon"
                      value={this.state.endDate}
                      dateFormat="dd/mm/yy"
                      onChange={(e) =>
                        this.dateHandleChange("endDate", e.value)
                      }
                      showIcon
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <p className="lblNoMargin">Tipo di Scarico *</p>
                    <InputText id="icon" value="F24" disabled={true} />
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ marginTop: "10px" }}>
                  <GridItem xs={12} sm={12} md={12}>
                    <FileUpload
                      ref={(e) => (this.fileUpload = e)}
                      disabled={this.state.disabled}
                      onSelect={this.onSelect}
                      chooseLabel="Scegli"
                      uploadLabel="Carica"
                      cancelLabel="Annulla"
                      name="file"
                      onUpload={this.onUpload}
                      onError={this.onError}
                      url={`${SiteUrl}coasco/caricamenti/insert?anno=${this.state.anno}&progr=${this.state.Progressivo}&dataI=${this.state.startDate}&dataF=${this.state.endDate}&tipoScarico=F24`}
                      onBeforeSend={(e) => {
                        e.xhr.setRequestHeader(
                          "Authorization",
                          `Bearer ${getToken()}`
                        );
                        this.setState({loaderSpinner: true});
                        return e;
                      }}
                      multiple
                      accept=".txt"
                      emptyTemplate={<p className="p-m-0">Seleziona file</p>}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default CaricamentoF24;
