import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Switch from "@material-ui/core/Switch";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomInput from "components/CustomInput/CustomInput.js";
import Helpers from "helpers/Helpers.js";
import CardFooter from "components/Card/CardFooter.js";
import ButtonsMUI from "@material-ui/core/Button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import TabulatiAPI from "API/TabulatiAPI";
import moment from "moment";
import {dateTimeFmt, rowsPerPageOptions} from "helpers/AppConstants";
import { dateFmt } from "helpers/AppConstants";

const Creditore = [
  { id: 1, name: "SISPI" },
  { id: 2, name: "INPS" }
];
class AllineaSpese extends React.Component {
  state = {
    rows: [],
    selectedGrid: null,
    loadingGrid: false,

    datafattura: new Date(),
    selectedCreditore: Creditore.filter((item) => item.name === "INPS")?.pop(),
    totalRows: ""
  };

  constructor(props) {
    super(props);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
  }

  componentDidMount() {
    this.refreshGrid();
  }

  async refreshGrid() {
    this.setState({
      selectedGrid: null,
      loadingGrid: true
    });
    const resp = await TabulatiAPI.GetAssociaSpese(
      this.state.selectedCreditore.name
    );
    if (!resp || !resp.result)
      this.props.addAlert.current.addAlert(
        "danger",
        "errore nella richiesta dati"
      );
    else {
      this.setState({
        rows: resp.result.map((item) => {
          item["inserisci"] = false;
          item.data_ultima_modifica = moment(
            item.data_ultima_modifica,
            dateTimeFmt
          );
          return item;
        })
      });
      this.setState({
        loadingGrid: false
      });
    }
  }

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  handleTableClick = async (e) => {
    var i;
    var total = 0;
    var d = "";
    for (i = 0; i < e.value.length; i++) {
      total += e.value[i].diff;
      d =
        Helpers.DecimalInputTemplate(total).props.children[0] +
        Helpers.DecimalInputTemplate(total).props.children[1];
    }

    this.setState({
      selectedGrid: e.value,
      totalRows: d
    });
  };

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  upload = async () => {
    if (
      this.state.selectedGrid !== null &&
      this.state.selectedCreditore !== null &&
      this.state.datafattura !== null
    ) {
      this.setState({
        loadingGrid:true
      });
      const res = await TabulatiAPI.AddSpese(
        this.state.selectedGrid,
        this.state.selectedCreditore,
        this.state.datafattura
      );
      this.setState({
        loadingGrid:false
      });
      if(res?.message === "200 OK") {
        this.props.addAlert.current.addAlert(
          "success",
          "Caricamento avvenuto con successo!");
      } else {
        this.props.addAlert.current.addAlert(
          "danger", 
          "Qualcosa è andato storto. Rirpovare più tardi.");
      }
      await this.refreshGrid();
    } else {
      this.props.addAlert.current.addAlert(
        "warning",
        "Assicurarsi di aver selezionato i campi necessari"
      );
    }
  };

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Associazione Spese per F24 a Tabulati
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <p className="lblNoMargin">Creditore</p>
                    <Dropdown
                      value={this.state.selectedCreditore}
                      options={Creditore}
                      onChange={(event) => {
                        this.setState(
                          { selectedCreditore: event.target.value },
                          this.refreshGrid
                        );
                      }}
                      optionLabel="name"
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <p className="lblNoMargin">Data fattura</p>
                    <Calendar
                      id="icon"
                      value={this.state.datafattura}
                      dateFormat="dd/mm/yy"
                      onChange={(e) =>
                        this.HandleChange("datafattura", e.value)
                      }
                      showIcon
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "12px" }}>
                    <ButtonsMUI
                      className="float-right"
                      variant="contained"
                      onClick={this.upload}
                      color="secondary"
                    >
                      <PublishRoundedIcon />
                      Carica in spese
                    </ButtonsMUI>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofsuccess">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Allinea Spese Per Tabulati
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="idTab"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10} rowsPerPageOptions={rowsPerPageOptions}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  loading={this.state.loadingGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="multiple"
                  metaKeySelection={false}
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "50px" }}
                  />
                  <Column
                    field="data_ultima_modifica"
                    header="Data Caric. "
                    headerStyle={{ width: "140px" }}
                    body={(row) =>
                      moment(row.data_ultima_modifica, dateTimeFmt).format(
                        dateFmt
                      )
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cod_quota"
                    header="Cod. Quota"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="sede"
                    header="Sede"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="riparto"
                    header="Riparto"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="numrigins"
                    header="Num. Righe"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="impins"
                    header="Importo"
                    headerStyle={{ width: "120px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "impins")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="tariffa"
                    header="Tariffa"
                    headerStyle={{ width: "100px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "tariffa")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="spesecalc"
                    header="Spese Calc."
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    body={r => Helpers.DecimalDTTemplate(r, "spesecalc")}
                  />
                  <Column
                    field="fatt"
                    header="Fatturato"
                    headerStyle={{ width: "120px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "fatt")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="diff"
                    headerStyle={{ width: "120px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "diff")
                    }
                    header="Differenza"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>

              <CardFooter>
                <GridContainer>
                  <GridItem xs={10} />
                  <GridItem xs={2}>
                    <CustomInput
                      labelText="Totale selezionato"
                      id="total"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.totalRows
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default AllineaSpese;
