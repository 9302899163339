import React, { Component } from "react";
import { ModificaAmministratoriXAzienda } from "components/Views/Amministratori/ModificaAmministratoriXAzienda.js";
import { AziendaHeader } from "components/Views/Aziende/AziendaHeader.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Helpers from "helpers/Helpers.js";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ButtonsMUI from "@material-ui/core/Button";
import queryString from "query-string";
import helpers from "helpers/Helpers";
import AmministratoriAPI from "API/AmministratoriAPI";
import {rowsPerPageOptions} from "../../helpers/AppConstants";

export class AmministratoriXAzienda extends Component {
  state = {
    rows: null,
    isVisible: false,
    selectedRow: null,
    selectedGridAmministratori: null,
    filterGrid: false,
    loadingGrid: false,
    firstRowToDisplay: 0,
    viewButtons: false,
    viewAziendaNuovoAmm: false,
    rowsPerPage: 10
  };

  constructor(props) {
    super(props);

    this.handleTableClick = this.handleTableClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.onRedirectClick = this.onRedirectClick.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.btnSearchRef = React.createRef();
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true
    });
    let aziendaId = this.props.match.params.aziendaId;
    let rw = await AmministratoriAPI.GetAmministratoriForAziendaByAziendaId(
      aziendaId
    );
    let qS = queryString.parse(this.props.history.location.search);

    let selectedRow = null;
    let firstRowToDisplay = 0;
    if (qS.typeId > 0) {
      selectedRow = helpers.ReturnRowFromListById(rw, qS.typeId);
      firstRowToDisplay = helpers.GetFirstElementOfPageIncludingId(
        qS.typeId, rw, 10
      );
    }

    this.setState({
      firstRowToDisplay: firstRowToDisplay,
      rows: rw
    });

    if (selectedRow)
      await this.handleTableClick({
        value: selectedRow
      });
    this.setState({
      loadingGrid: false
    });
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  onRedirectClick(param) {
    if (param === "storico") {
      this.props.history.push(
        "/admin/StoricoXAmministratori/" +
          this.props.match.params.aziendaId +
          "/" +
          this.state.selectedGridAmministratori.id
      );
    } else if (param === "ditte") {
      this.props.history.push(
        "/admin/DitteXAmministratore/" +
          this.props.match.params.aziendaId +
          "/" +
          this.state.selectedGridAmministratori.id
      );
    }
  }

  onCloseGridClick() {
    this.setState({
      selectedGridAmministratori: null,
      selectedRow: null
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  async onNuovoClick() {
    let nuovo = await AmministratoriAPI.GetNuovoAmministratoriForAziendaById();
    this.setState({
      selectedGridAmministratori: null,
      selectedRow: nuovo,
      viewButtons: false,
      viewAziendaNuovoAmm: false
    });
  }

  async handleTableClick(e) {
    let dep = await AmministratoriAPI.GetAmministratoriById(e.value.id);

    this.setState({
      nuovoTableVisible: false,
      selectedGridAmministratori: e.value,
      selectedRow: dep[0],
      viewButtons: true,
      viewAziendaNuovoAmm: false
    });
  }

  HandleSaveClick = async (RowItem) => {
    if (RowItem.id === null) {
      let amministratoreNuovo = await AmministratoriAPI.UpdateAmministratori(
        RowItem
      );
      this.state.selectedRow = amministratoreNuovo.result[0];
      this.state.selectedGridAmministratori = this.state.selectedRow;
      this.state.viewAziendaNuovoAmm = true;
    } else {
      await AmministratoriAPI.UpdateAmministratori(RowItem);
    }

    this.props.addAlert.current.addAlert("success", "Dati salvati.");
    await this.refreshGrid();

    return true;
  };

  ChangeButtons = async (existentAmministratore) => {
    if (existentAmministratore.tipo === "amministratore") {
      await this.handleTableClick({
        value: existentAmministratore[0]
      });
    } else if (existentAmministratore.tipo === "consulente") {
      this.setState({
        selectedGridAmministratori: null,
        selectedRow: existentAmministratore,
        viewButtons: false,
        viewAziendaNuovoAmm: false
      });
    }
  };

  backButtonClick = () => {
    let qS = queryString.parse(this.props.location.search);
    let maintainSearch = "?maintainSearch=1";
    if (!qS.type)
      this.props.history.push(
        "/admin/aziende/" + this.props.match.params.aziendaId + maintainSearch
      );
    else if (qS.type === "fromVersamenti")
      this.props.history.push(
        "/admin/versamenti/details/" +
          this.props.match.params.aziendaId +
          maintainSearch
      );
    else if (qS.type === "fromModificaRichieste")
      this.props.history.push(
        "/admin/richiedenti/modificarichieste/" +
          this.props.match.params.aziendaId +
          "/" +
          qS.typeId +
          maintainSearch
      );
    else if (qS.type === "fromRichieste")
      this.props.history.push(
        "/admin/richiedenti/modificarichieste/" +
          this.props.match.params.aziendaId +
          "/" +
          qS.typeId +
          maintainSearch +
          "&type=" +
          qS.type
      );
    else if (qS.type === "fromConsulenti")
      this.props.history.push(
        "/admin/consulenti/" +
          this.props.match.params.aziendaId +
          maintainSearch
      );
    else if (qS.type === "fromAmministratori")
      this.props.history.push(
        "/admin/amministratori/" +
          this.props.match.params.aziendaId +
          maintainSearch
      );
    else if (qS.type === "fromDipendenti")
      this.props.history.push(
        "/admin/dipendenti/" +
          this.props.match.params.aziendaId +
          maintainSearch
      );
  };

  render() {
    return (
      <div>
        <div ref={this.btnSearchRef} />
        <AziendaHeader
          aziendaId={this.props.match.params.aziendaId}
          {...this.props}
        />

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Amministratori
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Alla pagina precedente"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.backButtonClick}
                  >
                    <ArrowBackIcon />
                  </Button>
                </Tooltip>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id_rel"
                  paginator
                  first={this.state.firstRowToDisplay}
                  onPage={(e) => this.setState({ firstRowToDisplay: e.first, rowsPerPage: e.rows })}
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage} rowsPerPageOptions={rowsPerPageOptions}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  loading={this.state.loadingGrid}
                  selection={this.state.selectedGridAmministratori}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="nominativo"
                    header="Nominativo"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cf"
                    header="Codice Fiscale"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="indirizzo"
                    header="Indirizzo"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="comunex"
                    header="Comune"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data_inizio"
                    header="Inizio"
                    body={(rowData) =>
                      Helpers.DateTemplateWithHours(rowData, "data_inizio")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data_fine"
                    header="Fine"
                    body={(rowData) =>
                      Helpers.DateTemplateWithHours(rowData, "data_fine")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.selectedRow && (
          <ModificaAmministratoriXAzienda
            dataItem={this.state.selectedRow}
            onAnnullaClick={this.onCloseGridClick}
            onSaveClick={this.HandleSaveClick}
            addAlert={this.props.addAlert}
            ChangeButtons={this.ChangeButtons}
          >
            {this.state.viewButtons && (
              <ButtonGroup
                variant="contained"
                color="primary"
                size="small"
                aria-label="contained primary button group"
                className={"float-right " + this.state.groupClass}
                style={{ verticalAlign: "center", margin: "0.5%" }}
              >
                <ButtonsMUI onClick={() => this.onRedirectClick("storico")}>
                  Storico Amm.
                </ButtonsMUI>
                <ButtonsMUI onClick={() => this.onRedirectClick("ditte")}>
                  Aziende
                </ButtonsMUI>
              </ButtonGroup>
            )}
            {this.state.viewAziendaNuovoAmm && (
              <ButtonGroup
                variant="contained"
                color="primary"
                size="small"
                aria-label="contained primary button group"
                className={"float-right " + this.state.groupClass}
                style={{ verticalAlign: "center", margin: "0.5%" }}
              >
                <ButtonsMUI onClick={() => this.onRedirectClick("ditte")}>
                  Aziende
                </ButtonsMUI>
              </ButtonGroup>
            )}
          </ModificaAmministratoriXAzienda>
        )}
      </div>
    );
  }
}
export default AmministratoriXAzienda;
