import React from "react";
import clone from "clone";
import CustomInput from "components/CustomInput/CustomInput.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";

import LoaderSpinner from "../../Layout/LoaderSpinner.js";
import Button from "components/CustomButtons/Button.js";
import UndoIcon from "@material-ui/icons/Undo";
import CardFooter from "components/Card/CardFooter.js";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import helpers from "helpers/Helpers";
import PunteggiAPI from "API/PunteggiAPI.js";
export default class ModificaPunteggi extends React.Component {
  state = {
    RowItem: null,
    checkedidoneita: false,
    idoneita: "",
    newId: 0,

    //VALIDATIONS
    fieldValidations: {},
    validationRules: {}
  };

  constructor(props) {
    super(props);

    this.HandleChange = this.HandleChange.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.HandleCheckBox = this.HandleCheckBox.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
  }

  componentDid(prevProps, prevState, snapshot) {
    if (this.props.dataItem !== null) {
      if (this.props.dataItem.id !== prevProps.dataItem.id)
        this.setState({
          RowItem: this.props.dataItem,
          checkedidoneita: this.props.dataItem.idoneita === "SI"
        });

      if (this.state.checkedidoneita) this.props.dataItem.idoneita = 1;
      else this.props.dataItem.idoneita = 0;

      if (this.props.newid) this.props.dataItem.id = this.state.newId;
    }
  }

  async isValidForm() {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);

    let isValid = Object.values(fieldValidations).every((x) => x === "success");

    this.setState({
      fieldValidations: fieldValidations
    });

    return isValid;
  }

  HandleCheckBox(e) {
    this.setState({
      checkedidoneita: e.target.checked
    });
    const rowItem = {
      ...this.state.RowItem,
      idoneita: e.target.checked ? 1 : 0
    };
    this.setState({
      RowItem: rowItem
    });
  }

  async componentDidMount() {
    if (this.props.dataItem !== null)
      this.setState({
        RowItem: {
          cfuCons: this.props.dataItem?.cfuCons,
          cfuRich: this.props.dataItem?.cfuRich,
          dataAgg: this.props.dataItem?.dataAgg,
          descr: this.props.dataItem?.descr,
          idPunt: this.props.dataItem?.idPunt,
          idRichiesBS: this.props.dataItem?.idRichiesBS,
          idoneita: this.props.dataItem?.idoneita,
          punteggio: this.props.dataItem?.punteggio,
          utente: this.props.dataItem?.utente
        },
        newId: this.props.newid ? this.props.punteggiLength + 1 : 0,
        checkedidoneita: this.props.dataItem.idoneita === 1
      });
  }

  HandleSaveClick = async () => {
    if (await this.isValidForm()) {
      var sd = await this.props.onSaveClick(this.state.RowItem);
      return sd;
    }
  };

  HandleChange(field, newValue) {
    var newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
  }

  buildJson = () => {
    const rowItem = {
      ...this.state.RowItem,
      cfuCons: parseInt(this.state.RowItem.cfuCons),
      cfuRich: parseInt(this.state.RowItem.cfuRich)
    };

    return rowItem;
  };

  onSave = async () => {
    try {
      const data = this.buildJson();
      const res = await PunteggiAPI.updateScore(data);
      if (res && res.result) {
        if (res.result.length > 0) {
          this.props.addAlert("success", "Punteggio aggiornato correttamente");
          this.props.refreshTable();
        }
      } else {
        this.props.addAlert(
          "danger",
          "Il punteggio non è stato inserito correttamente"
        );
      }
      this.props.updateScoreHandler();
    } catch (error) {
    } finally {
      return true;
    }
  };

  render() {
    if (this.state.RowItem === null)
      return (
        <>
          <LoaderSpinner fullHeight={false} />
        </>
      );
    else
      return (
        <>
          <GridContainer>
            <GridItem xs={12} md={12} xm={12}>
              <Card>
                <CardHeader color="borseinfo">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.props.nuovoModificaTitle || "Nuovo"} Punteggio
                  </h4>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Annulla"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%",
                        marginLeft: "0",
                        marginRight: "0"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.props.onAnnullaClick}
                    >
                      <UndoIcon />
                    </Button>
                  </Tooltip>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Id *"
                        id="id-disabled"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          onChange: (event) =>
                            this.HandleChange("id", event.target.value),
                          value: this.state.RowItem.idPunt || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} className="mt-3">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={this.state.checkedidoneita}
                            onChange={this.HandleCheckBox}
                          />
                        }
                        label="Idoneità"
                        className="labelCheckbox"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Voto"
                        id="voto"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleChange("punteggio", event.target.value),
                          value: this.state.RowItem.punteggio || "",
                          type: "number"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="CFU Richiesti"
                        id="cfuRich"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleChange("cfuRich", event.target.value),
                          value: this.state.RowItem.cfuRich || "",
                          type: "number"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="CFU Conseguiti"
                        id="cfucons"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleChange("cfuCons", event.target.value),
                          value: this.state.RowItem.cfuCons || "",
                          type: "number"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer className="mt-2">
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        style={{
                          backgroundColor: "#fff",
                          borderColor: "primary"
                        }}
                        id="filled-multiline-flexible"
                        label="Descrizione"
                        onChange={(e) =>
                          this.HandleChange("descr", e.target.value)
                        }
                        multiline
                        rows={3}
                        value={this.state.RowItem.descr}
                        variant="filled"
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.onSave}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </>
      );
  }
}
