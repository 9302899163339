import helpers from "../helpers/Helpers";
import Utils from "./Utils";

const TabulatiAPI = {
  GetTabulatiPerSedeAnno: async function () {
    let result = await Utils.CreatePostRequest("coasco/tabulati/getTot");
    if (result !== null) {
      result = result.result.map(item => ({
        accreditato: item.accreditato,
        anno: item.anno,
        bollo: item.bollo,
        codiceQ: item.codiceQ,
        diffaccr: item.diffAccr,
        difffatt: item.diffFatt,
        errore: item.errore,
        fatturato: item.fatturato,
        importo: item.importo,
        key: item.key,
        nettodaacrr: item.nettoDaAccr,
        righe: item.righe,
        riparto: item.riparto,
        sede: item.sede,
        spese: item.spese
      }));
      helpers.formatCurrency(result, "accreditato", "bollo", "diffaccr", "difffatt", "errore", "nettodaacrr", "importo", "fatturato", "spese");
    }

    return result;
  },

  GetTabulatiPerSedeAnnoSede: async function (sede) {
    let result = await Utils.CreatePostRequest(
      "coasco/tabulati/getTotXSede" + "?sede=" + sede
    );
    if (result !== null) {
      result = result.result.map(item => ({
        accreditato: item.accreditato,
        anno: item.anno,
        bollo: item.bollo,
        codiceQ: item.codiceQ,
        diffaccr: item.diffAccr,
        difffatt: item.diffFatt,
        errore: item.errore,
        fatturato: item.fatturato,
        importo: item.importo,
        key: item.key,
        nettodaacrr: item.nettoDaAccr,
        righe: item.righe,
        riparto: item.riparto,
        sede: item.sede,
        spese: item.spese
      }));
      helpers.formatCurrency(result, "accreditato", "bollo", "diffaccr", "difffatt", "errore", "nettodaacrr", "importo", "fatturato", "spese");
    }

    return result;
  },

  GetTabulatiPerCodiceSedeAnnoDaChiusura: async function (codice, sede, anno) {
    const response = await Utils.CreatePostRequest(
      "coasco/tabannuale/search" +
        "?start=0" +
        "&size=1" +
        "&anno=" +
        anno +
        "&sede=" +
        sede +
        "&codq=" +
        codice
    );

    return response.result;
  },

  CloseTabulato: async function (obj) {
    return await Utils.CreatePostRequest("coasco/tabannuale/close", obj);
  },

  OpenTabulato: async function (id) {
    return await Utils.CreatePostRequest(
      "coasco/tabannuale/open" + "?id=" + id
    );
  },

  GetTabulatiPerCodiceSedeAnno: async function (tabulatiId, sede, anno) {
    let response = await Utils.CreatePostRequest(
      "coasco/tabulati/getTotXSedeAnno" + "?sede=" + sede + "&anno=" + anno
    );
    if (response?.result)
       helpers.formatCurrency(response.result, "importo", "bollo", "spese", "fatturato", "diffFatt", "nettoDaAccr", "accreditato", "diffAccr", "errore");
    return response;
  },

  GetDettagliTabulati: async function (tabulatiId, codice, sede, anno) {
    var result = await Utils.CreatePostRequest(
      "coasco/tabulati/getDettaglio" +
        "?sede=" +
        sede +
        "&anno=" +
        anno +
        "&quota=" +
        codice
    );
    if (result !== null) {
      result = result.result.map(item => ({
        id: item.id,
        accreditato: item.accreditato,
        anno: item.anno,
        bollo: item.bollo,
        codice: item.codiceQ,
        diffaccr: item.diffAccr,
        difffatt: item.diffFatt,
        errore: item.errore,
        fatturato: item.fatturato,
        importo: item.importo,
        nettodaacrr: item.nettoDaAccr,
        righe: item.righe,
        riparto: item.riparto,
        sede: item.sede,
        spese: item.spese,
        dataarr: item.dataArrTab,
        tariff: item.tariffa,
        dataultimo: item.dataUltMod,
        tipoultimoagg: item.tipoUltMod
      }));
      helpers.formatCurrency(result, "accreditato", "bollo", "diffaccr", "difffatt", "errore", "fatturato", "importo", "nettodaacrr", "spese", "tariff");
    }

    return result;
  },

  GetDettagliTabulatiFiltrato: async function (codice, sede, anno, riparto) {
    let result = await Utils.CreatePostRequest(
      "coasco/tabulati/getDettaglio" +
        "?sede=" +
        sede +
        "&anno=" +
        anno +
        "&quota=" +
        codice
    );
    if (result !== null) {
      result = result.result.map((item) => ({
        id: item.id,
        accreditato: item.accreditato,
        anno: item.anno,
        bollo: item.bollo,
        codice: item.codiceQ,
        diffaccr: item.diffAccr,
        difffatt: item.diffFatt,
        errore: item.errore,
        fatturato: item.fatturato,
        importo: item.importo,
        nettodaacrr: item.nettoDaAccr,
        righe: item.righe,
        riparto: item.riparto,
        sede: item.sede,
        spese: item.spese,
        dataarr: item.dataArrTab,
        tariff: item.tariffa,
        dataultimo: item.dataUltMod,
        tipoultimoagg: item.tipoUltMod
      }));
      helpers.formatCurrency(result, "accreditato", "bollo", "diffaccr", "difffatt", "errore", "fatturato", "importo", "nettodaacrr", "spese", "tariff");
    }

    return result?.filter((row) => row.riparto === riparto);
  },

  GetTabulatiMultipleTable: async function (
    tabulatiId,
    noqscarichi,
    noqtabulati,
    noqaccrediti,
    noqfatture
  ) {
    return {
      id: 1,
      qscarichi: noqscarichi,
      qtabulati: noqtabulati,
      qaccrediti: noqaccrediti,
      qfatture: noqfatture,
      impscarichi: 2344,
      imptabulati: 23345,
      impnetto: 334,
      impaccrediti: 32,
      impdiff: 20,
      imperrore: 22,
      tariffascarichi: 234,
      tariffatab: 12,
      bolloscarichi: 0,
      bollotab: 20,
      dafattscarichi: 0,
      dafatttabulati: 0,
      dafattfatture: 1000,
      dafatdiff: 1000,
      datatab: "16/07/2019"
    };
  },

  GetFatture: async function (sede, periodo, codice) {
    let result = await Utils.CreatePostRequest(
      "coasco/fatture/getBySedePeriodoQuota" +
        "?sede=" +
        sede +
        "&periodo=" +
        periodo +
        "&quota=" +
        codice
    );
    if (result !== null)
      result = result.result.map((item) => ({
        id: item.id,
        fatturate: item.fatturate,
        datafat: item.data_fattura
      }));
    helpers.formatCurrency(result, "fatturate");
    return result;
  },

  insertFatture: async function (obj) {
    return await Utils.CreatePostRequest("coasco/fatture/insert", obj);
  },

  updateFatture: async function (obj) {
    return await Utils.CreatePutRequest("coasco/fatture/update", obj);
  },

  GetEmptyFatture: async function () {
    return { id: 0, fatturate: null, datafat: null };
  },

  GetChiusuraTabulati: async function () {
    return await Utils.CreatePostRequest(
      "coasco/tabannuale/getAll" + "?start=" + 1 + "&size=" + 10000
    );
  },

  GetChiusuraTabulatiSearch: async function (anno, sede, codice) {
    let result = await Utils.CreatePostRequest(
      "coasco/tabannuale/search" +
        "?start=" +
        1 +
        "&size=" +
        100000 +
        "&anno=" +
        anno +
        "&sede=" +
        sede +
        "&codq=" +
        codice
    );

    if (result !== null)
      result = result.result.map(item => ({
        id: item.id,
        sede: item.sede,
        anno: item.anno,
        codice: item.cod_quota,
        dataop: item.data_op,
        note: item.note,
        chiuso: item.chiuso
      }));

    return result;
  },

  GetAssociaSpese: async function (creditore) {
    const response = await Utils.CreatePostRequest(
      "coasco/allineaspese/getall?creditore=" + creditore
    );
    helpers.formatCurrency(response?.result, "impins", "tariffa", "spesecalc", "fatt", "diff");
    return response;
  },

  delete: async function (id) {
    return await Utils.CreateDeleteRequest("coasco/fatture/delete?id=" + id);
  },

  GetTabulatiBySedeRipartoQuota: async function (sede, periodo, codice) {
    let result = await Utils.CreatePostRequest(
      "coasco/tabulati/getBySedeRipartoQuota" +
        "?sede=" +
        sede +
        "&riparto=" +
        periodo +
        "&quota=" +
        codice
    );
    result = result.result.map(item => ({
      competenza: item.competenza,
      righe: item.n_righe,
      riparto: item.riparto,
      sede: item.sedeInps.sap,
      id: item.id,
      codice: item.cod_quota,
      importo: item.importo,
      dataultimo: item.data_ultima_modifica,
      tipoultimoagg: item.tipo_ultima_modifica
    }));

    return result;
  },

  AddSpese: async function (array, creditore, dataSpese) {
    const object = array.map((x) => {
      return {
        ...x,
        data_ultima_modifica: helpers.convertToItalianDateTime(
          x.data_ultima_modifica
        )
      };
    });

    let data = helpers.ConvertToItalianDateForPrime(dataSpese);
    let appoggio = data.split("/");
    data = appoggio[1] + "/" + appoggio[0] + "/" + appoggio[2];

    return await Utils.CreatePostRequest(
      "coasco/allineaspese/loadonspese" +
        "?creditore=" +
        creditore.name +
        "&dataFatt=" +
        data,
      object
    );
  },

  getTariffaScarichi: async function (riparto) {
    const url =
      "coasco/AliquotaSpese/getByCodRip?aliquota=spesetotf24&riparto=1970/01";
    const response = await Utils.CreatePostRequest(url);
    if (response?.message?.includes("200")) {
      return response?.result[0].aliquota.toPrecision(2);
    } else return null;
  },

  getTariffaTabulati: async function (riparto) {
    let rip = "";

    if (riparto <= "2014/12") rip = "1970/01";
    else if (riparto <= "2016/01") rip = "2015/01";
    else if (riparto >= "2017/01") rip = "2017/01";

    const url = `coasco/AliquotaSpese/getByCodRip?aliquota=speseinpsf24&riparto=${rip}`;
    const response = await Utils.CreatePostRequest(url);

    if (response?.message?.includes("200")) {
      return response?.result[0].aliquota.toPrecision(2);
    } else return null;
  }
};
export default TabulatiAPI;
