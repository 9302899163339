import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";

export const TestoLettera = (props) => {

  return (
    <Card>
      <CardHeader color="info">
        <h4
          style={{
            lineHeight: "1",
            display: "inline",
            verticalAlign: "bottom"
          }}
        >
          Testo Lettera
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            className="input"
            style={{ marginTop: "5px" }}
          >
            <InputTextarea
              autoResize
              value={props.text}
              onChange={(e) => props.setEmailText(e.target.value)}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

TestoLettera.propTypes = {
  selectedAccount: PropTypes.string,
  setAccount: PropTypes.func,
  text: PropTypes.string,
  accounts: PropTypes.array,
  setEmailText: PropTypes.func
};