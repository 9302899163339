import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";

export const HeaderCorrispondenza = (props) => {

  const {filter = "ENTRATA", filterHandler = () => {}} = props;

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <Button
              icon="pi pi-arrow-left"
              onClick={() => {
                if (props.match.params.administratorId)
                  props.history.push("/admin/amministratori/" + props.match.params.administratorId);
                else if (props.match.params.consultantId)
                  props.history.push("/admin/consulenti/" + props.match.params.consultantId);
                else if (props.match.params.employeeId)
                  props.history.push("/admin/dipendenti/" + props.match.params.employeeId);
              }}
              className="p-button-rounded p-button-primary p-button-sm float-left"
              style={{ verticalAlign: "bottom", marginRight: "2px" }}
            />

            <h4
              style={{
                display: "inline",
                verticalAlign: "bottom"
              }}
            >
              Corrispondenza
            </h4>
          </CardHeader>

          <CardBody style={{ paddingTop: "3vh" }}>
            <GridItem xs={12} sm={12} md={12}>
              <GridContainer>
                <GridItem>
                  <div
                    className="p-field-radiobutton"
                    style={{ display: "inline", marginRight: "8px" }}
                  >
                    <RadioButton
                      name="category"
                      value="ENTRATA"
                      onChange={(e) => filterHandler(e.value)}
                      checked={filter === "ENTRATA"}
                    />
                    <label className="labelCheckbox" htmlFor="Entrata">
                      Entrata
                    </label>
                  </div>
                </GridItem>
                <GridItem>
                  <div
                    className="p-field-radiobutton"
                    style={{ display: "inline", marginRight: "8px" }}
                  >
                    <RadioButton
                      name="category"
                      value="USCITA"
                      onChange={(e) => filterHandler(e.value)}
                      checked={filter === "USCITA"}
                    />
                    <label className="labelCheckbox" htmlFor="Uscita">
                      Uscita
                    </label>
                  </div>
                </GridItem>
                <GridItem>
                  <div
                    className="p-field-radiobutton"
                    style={{ display: "inline", marginRight: "8px" }}
                  >
                    <RadioButton
                      name="category"
                      value="TUTTA"
                      onChange={(e) => filterHandler(e.value)}
                      checked={filter === "TUTTA"}
                    />
                    <label className="labelCheckbox" htmlFor="Tutta">
                      Tutta
                    </label>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
