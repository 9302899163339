import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { VersamentiHeader } from "components/Views/Versamenti/VersamentiHeader.js";
import Button from "components/CustomButtons/Button.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ButtonsMUI from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import GridM from "@material-ui/core/Grid";
import { PulitoriCustodiXAziendaEdit } from "components/Views/Versamenti/PulitoriCustodiXAziendaEdit.js";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import helpers from "helpers/Helpers";
import { confirmPopup } from "primereact/confirmpopup";
import YearPicker from "components/YearPicker/index";
import queryString from "query-string";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import CustodiPulitoriAPI from "API/CustodiPulitoriAPI";
import VersamentiAPI from "API/VersamentiAPI";
import AziendeAPI from "API/AziendeAPI";
import moment from "moment";
import {
  dateTimeFmt,
  rowsPerPageOptions,
  rowsPerPageOptionsMensile
} from "helpers/AppConstants";
import { dateFmt } from "helpers/AppConstants";
import VisibilityIcon from "@material-ui/icons/Visibility";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

class VersamentiDetails extends Component {
  state = {
    selectedGridVersamenti: null,
    selectedGridCorrispondenza: null,
    selectedGridPulitoriCustodi: null,
    filterVersamenti: false,
    filterCorrispondenza: false,
    filterVersamentiTable: false,
    isChecked: false,
    filterLabelTextVersamenti: "Attiva Filtri",
    filterLabelTextCorrispondenza: "Attiva Filtri",
    competenzaAnno: new Date().getFullYear(),
    loadingGrid: false,
    loadingGridCorrispondenza: false,
    rows: [],
    rowsCorrispondenza: [],
    visibleColumn: false,
    NuovoModificaTitle: "Inserimento",
    gridStyle: {},
    VisibleColumns: false,
    filterDm10: false,
    columns: [],
    firstRowToDisplay: 0,
    rowsPerPageCorr: 5,
    loadPage: false,
    openModalTestoMail: false,
    testoMailModal: null
  };

  constructor(props) {
    super(props);
    this.ChangefilterCorrispondenza =
      this.ChangefilterCorrispondenza.bind(this);
    this.onAddCorrispondenzaClick = this.onAddCorrispondenzaClick.bind(this);
    this.ChangefilterVersamenti = this.ChangefilterVersamenti.bind(this);
    this.handleTableClickVersamenti =
      this.handleTableClickVersamenti.bind(this);
    this.handleTableClickCorrispondenza =
      this.handleTableClickCorrispondenza.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.ChangeFilterVersamentiTable =
      this.ChangeFilterVersamentiTable.bind(this);
    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.btnSearchRef = React.createRef();
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.DeleteRecord = this.DeleteRecord.bind(this);
    this.IncreaseYear = this.IncreaseYear.bind(this);
    this.DecreaseYear = this.DecreaseYear.bind(this);
    this.DocActionsGridTemplate = this.DocActionsGridTemplate.bind(this);
    this.onRedirectHandlerClick = this.onRedirectHandlerClick.bind(this);
    this.ChangefilterDm10 = this.ChangefilterDm10.bind(this);
    this.onSave = this.onSave.bind(this);
    this.openModalMailInviata = this.openModalMailInviata.bind(this);
    this.templateMailInviata = this.templateMailInviata.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loadingGridCorrispondenza: true
    });

    const companyId = this.props.match.params.versamentiId;

    const correspondencesRes =
      await CorrispondenzaAPI.GetCorrispondenzaForAziendaForVersamenti(
        companyId
      );

    const correspondences = [];
    if (correspondencesRes && correspondencesRes?.length > 0) {
      for (let i = 0; i < correspondencesRes?.length; i++) {
        const object = correspondencesRes[i];
        object.year = correspondencesRes[i]?.data?.split("/")[2]?.substr(0, 4);
        correspondences.push(object);
      }
    }

    this.setState({
      rowsCorrispondenza: correspondences,
      azienda: await AziendeAPI.GetAziendaById(companyId),
      columns: [
        {
          field: "data_di_versamento",
          header: "Data di Versamento",
          headerStyle: { width: "200px" }
        },
        {
          field: "num_operai",
          header: "Num. Operai",
          headerStyle: { width: "150px" }
        },
        {
          field: "retr_operai",
          header: "Retr. Operai",
          headerStyle: { width: "140px" }
        },
        {
          field: "num_impiegati",
          header: "Num. Impiegati",
          headerStyle: { width: "170px" }
        },
        {
          field: "retr_impiegati",
          header: "Retr. Impiegati",
          headerStyle: { width: "170px" },
          body: (row) => helpers.DecimalDTTemplate(row, "retr_impiegati")
        },
        {
          field: "num_dirigenti",
          header: "Num. Dirigenti",
          headerStyle: { width: "170px" }
        },
        {
          field: "retr_dirigenti",
          header: "Retr. Dirigenti",
          headerStyle: { width: "170px" },
          body: (row) => helpers.DecimalDTTemplate(row, "retr_dirigenti")
        },
        {
          field: "num_apprendisti",
          header: "Num. Apprendisti",
          headerStyle: { width: "180px" }
        },
        {
          field: "retr_apprendisti",
          header: "Retr. Apprendisti",
          headerStyle: { width: "180px" },
          body: (row) => helpers.DecimalDTTemplate(row, "retr_apprendisti")
        },
        {
          field: "num_parttime",
          header: "Num. Parttii",
          headerStyle: { width: "180px" }
        },
        {
          field: "retr_parttime",
          header: "Retr. Parttime",
          headerStyle: { width: "180px" }
        },
        {
          field: "retr_formlav",
          header: "Retr. Formulav",
          headerStyle: { width: "180px" }
        }
      ]
    });

    let selectedRow = null;
    let qS = queryString.parse(this.props.history.location.search);
    const year = localStorage.getItem("year");
    if (year) this.setState({ competenzaAnno: year });
    if (qS.typeId > 0) {
      selectedRow = helpers.ReturnRowFromListById(
        this.state.rowsCorrispondenza,
        qS.typeId
      );
      helpers.GetFirstElementOfPageIncludingId(
        qS.typeId,
        this.state.rowsCorrispondenza,
        10
      );
    }

    if (selectedRow)
      await this.handleTableClickCorrispondenza({
        value: selectedRow
      });

    this.setState({
      filterVersamentiTable: localStorage.getItem("puliOrCust") === "PULI",
      loadingGridCorrispondenza: false
    });

    await this.refreshGrid();
  }

  onAddCorrispondenzaClick() {
    localStorage.setItem("year", this.state.competenzaAnno);
    localStorage.setItem(
      "puliOrCust",
      this.state.filterVersamentiTable ? "PULI" : "CUST"
    );
    this.props.history.push(
      "/admin/CorrispondenzaXVersamenti/" +
        this.props.match.params.versamentiId +
        "?typeId=" +
        0
    );
  }

  async handleDateChange(dateParam) {
    this.setState(
      {
        competenzaAnno: dateParam
      },
      async () => await this.refreshGrid()
    );
  }

  ChangefilterDm10(e) {
    this.setState({
      filterDm10: e.target.checked,
      VisibleColumns: !!e.target.checked
    });
  }

  onRedirectHandlerClick(param) {
    localStorage.setItem("year", this.state.competenzaAnno);
    localStorage.setItem(
      "puliOrCust",
      this.state.filterVersamentiTable ? "PULI" : "CUST"
    );

    const qs = `${this.props.match.params.versamentiId}?type=fromVersamenti`;
    if (param === "Uni")
      this.props.history.push("/admin/UniemensXAzienda/" + qs);
    else if (param === "Ano")
      this.props.history.push("/admin/AnomalieXAzienda/" + qs);
    if (param === "Congr")
      this.props.history.push("/admin/CongruitaVersamenti/" + qs);
    if (param === "Vers")
      this.props.history.push("/admin/VersamentiUniemens/" + qs);
    if (param === "Trasf")
      this.props.history.push(
        "/admin/VersamentiTrasferiti/" +
          this.props.match.params.versamentiId +
          "/" +
          this.state.competenzaAnno +
          "/" +
          (this.state.filterVersamentiTable ? "PULI" : "CUST") +
          "?type=fromVersamenti"
      );
  }

  onCloseGridClick() {
    this.setState({
      selectedGridVersamenti: null,
      selectedGridPulitoriCustodi: null,
      gridStyle: {}
    });
  }

  async ChangefilterCorrispondenza(e) {
    this.setState({ filterCorrispondenza: e.target.checked });
  }

  async ChangeFilterVersamentiTable(e) {
    this.setState(
      {
        filterVersamentiTable: e.target.checked
      },
      async () => await this.refreshGrid()
    );
  }

  async IncreaseYear() {
    this.setState(
      {
        competenzaAnno: parseInt(this.state.competenzaAnno) + 1
      },
      async () => await this.refreshGrid()
    );
  }

  async DecreaseYear() {
    this.setState(
      {
        competenzaAnno: parseInt(this.state.competenzaAnno - 1)
      },
      async () => await this.refreshGrid()
    );
  }

  onDetailsClick() {
    this.setState({
      visibleColumn: true
    });
  }

  onAddClick() {
    let aziendaId = this.props.match.params.versamentiId;
    let pc =
      CustodiPulitoriAPI.GetNuovoPulitoriCustodiByAziendaIdNuovo(aziendaId);
    this.setState({
      selectedGridPulitoriCustodi: pc,
      NuovoModificaTitle: "Inserimento",
      gridStyle: { width: "50%", float: "left" }
    });
  }

  onDeleteClick(event) {
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {},
      reject: () => {}
    });
  }

  async DeleteRecord(id) {
    const response = await CustodiPulitoriAPI.DeleteVersamenti(id);
    if (!response || !response.result) return;
    await this.refreshGrid();
    this.onCloseGridClick();
    this.onAddClick();
  }

  onEditClick(e, rowData) {
    e.stopPropagation();
    localStorage.setItem("year", this.state.competenzaAnno);
    localStorage.setItem(
      "puliOrCust",
      this.state.filterVersamentiTable ? "PULI" : "CUST"
    );
    localStorage.setItem("annoCorrisp", rowData.year);
    this.props.history.push(
      "/admin/CorrispondenzaXVersamenti/" +
        this.props.match.params.versamentiId +
        "?typeId=" +
        rowData.id
    );
  }

  openModalMailInviata(e,rowData) {
    e.stopPropagation();
    if(rowData.destmail && rowData.testomail){
      this.setState({testoMailModal:rowData.testomail});
      this.setState({openModalTestoMail:true})
    }
  }

  DocActionsGridTemplate(rowData) {
    return (
      <>
        <Button
          round
          color="success"
          className="actionButton actionButtonRound btnRound float-left"
          key={rowData.Id}
          onClick={(e) => this.onEditClick(e, rowData)}
        >
          <VisibilityIcon className="icon" />
        </Button>
      </>
    );
  }

  templateMailInviata(rowData) {
    let testo=rowData.destmail ? "SI":"NO";
    return (
      <>
        <Button
          disabled={!(rowData.destmail && rowData.testomail)}
          color="primary"
          className="center"
          key={rowData.Id}
          onClick={(e) => this.openModalMailInviata(e, rowData)}
        >
          {testo}
        </Button>
      </>
    );
  }

  async onSave(RowItem) {
    if (RowItem.id !== null) {
      let result = await VersamentiAPI.UpdateVersamenti(RowItem);
      if(result.message === '200 OK'){
        this.props.addAlert.current.addAlert("success", "Dati salvati.");
      } else {
        this.props.addAlert.current.addAlert("danger", result.message);
      }
      
      await this.refreshGrid();
      this.onCloseGridClick();
      this.onAddClick();
      return true;
    }

    let i = parseInt(RowItem.meseInizCompDest),
      f = parseInt(RowItem.meseFineCompDest),
      competenze = this.state.rows.map((r) => r.competenza);

    while (i <= f) {
      let competenza = RowItem.annoComp + "/" + i.toString().padStart(2, "0");
      if (competenze.includes(competenza)) {
        const res = window.confirm(
          "Periodo di competenza " +
            competenza +
            " già presente: continuare con l'inserimento? "
        );
        if (res) {
          RowItem.meseInizCompDest = i.toString().padStart(2, "0");
          RowItem.meseFineCompDest = i.toString().padStart(2, "0");
          await VersamentiAPI.AddVersamenti(RowItem);
        }
      } else {
        RowItem.meseInizCompDest = i.toString().padStart(2, "0");
        RowItem.meseFineCompDest = i.toString().padStart(2, "0");
        await VersamentiAPI.AddVersamenti(RowItem);
      }
      i++;
    }
    this.props.addAlert.current.addAlert("success", "Dati salvati.");
    await this.refreshGrid();
    this.onCloseGridClick();
    this.onAddClick();
    return true;
  }

  HandleSaveClick = async (RowItem, causal) => {
    const originalCompetence = RowItem?.competenzaOrig;

    if (causal === "30") {
      if (!originalCompetence) {
        this.props.addAlert.current.addAlert(
          "warning",
          "La competenza originale deve avere il seguente formato: YYYY/MM"
        );
        return true;
      }
      if (originalCompetence.length !== 7) {
        this.props.addAlert.current.addAlert(
          "warning",
          "La competenza originale deve avere il seguente formato: YYYY/MM"
        );
        return true;
      }
      const isSlashPresent = originalCompetence?.search("/");
      if (isSlashPresent !== 4) {
        this.props.addAlert.current.addAlert(
          "warning",
          "La competenza originale deve avere il seguente formato: YYYY/MM"
        );
        return true;
      }
      const _originalCompetence = originalCompetence?.split("/");
      const year = parseInt(_originalCompetence[0]);
      const month = parseInt(_originalCompetence[1]);
      if (year > new Date().getFullYear() || year < 1900) {
        this.props.addAlert.current.addAlert(
          "warning",
          "L'anno inserito non è corretto"
        );
        return true;
      }
      if (month < 1 || month > 12) {
        this.props.addAlert.current.addAlert(
          "warning",
          "Il mese inserito non è corretto"
        );
        return true;
      }
      this.onSave(RowItem);
    }
    if (causal !== "30") {
      this.onSave(RowItem);
    }
  };

  async refreshGrid() {
    this.setState({
      loadingGrid: true
    });

    let type = this.state.filterVersamentiTable ? "PULI" : "CUST";

    let aziendaId = this.props.match.params.versamentiId;

    let rows = await CustodiPulitoriAPI.GetPulitoriCustodiByAziendaId(
      aziendaId,
      this.state.competenzaAnno,
      type
    );
    this.setState({
      rows: rows,
      loadingGrid: false
    });
  }

  async ChangefilterVersamenti(e) {
    this.setState({ filterVersamenti: e.target.checked });
  }

  handleChange(e) {
    this.setState({ isChecked: e.target.checked });
  }

  async handleTableClickVersamenti(e) {
    let pc = await CustodiPulitoriAPI.GetPulitoriCustodiById(e.value.id);
    pc[0].matricola = pc[0].azienda;

    let cod = pc[0].caus_ins.codice;
    if (
      e.value.tipo_ultimo_agg !== "Manuale" &&
      (cod === "30" || cod === "91" || cod === "92")
    ) {
      return;
    }

    if (e.value.tipo_ultimo_agg === "Manuale") {
      this.setState({
        selectedGridVersamenti: e.value,
        selectedGridPulitoriCustodi: pc[0],
        NuovoModificaTitle: "Modifica",
        gridStyle: { width: "50%", float: "left" }
      });
    } else
      this.setState({
        selectedGridVersamenti: e.value,
        selectedGridPulitoriCustodi: pc[0], //null,
        NuovoModificaTitle: "Modifica",
        gridStyle: { width: "50%", float: "left" }
      });
  }

  async handleTableClickCorrispondenza(e) {
    this.setState({
      selectedGridCorrispondenza: e.value
    });
    const rowData = e.value;
    if (!rowData) return;
    const extension = rowData.path?.split(".")?.at(-1);
    if (extension?.toLowerCase() === "pdf") {
      this.setState({ loadPage: true });
      await CorrispondenzaAPI.openPdf(rowData.id_allegato, "pdf");
      this.setState({ loadPage: false });
    } else {
      await CorrispondenzaAPI.DownloadCorrispondenzaAttachment(rowData.id_allegato, extension)
    }
  }

  dateTemplateBody(corrisp) {
    return moment(corrisp.data, dateTimeFmt).format(dateFmt);
  }

  attachTemplateBody(corrisp) {
    if (corrisp.path) {
      return <i className="pi pi-paperclip"></i>;
    }
  }

  render() {
    const dynamicColumns = this.state.columns.map((col) => {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          headerStyle={col.headerStyle}
          sortable={true}
          filter={this.state.filterGrid}
        />
      );
    });

    return (
      <div ref={this.btnSearchRef}>
        {this.state.loadPage && <LoaderSpinner />}
        <Dialog
            open={this.state.openModalTestoMail}
            aria-labelledby="small-modal-slide-title"
            aria-describedby="small-modal-slide-description"
          >
            <DialogContent id="small-modal-slide-description">
              <h5>
                {this.state.testoMailModal}
              </h5>
            </DialogContent>
            <DialogActions>
            <Button onClick={() => this.setState({openModalTestoMail:false, testoMailModal: null})} color="primary">
              Chiudi
            </Button>
          </DialogActions>
          </Dialog>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <VersamentiHeader
              versamentiId={this.props.match.params.versamentiId}
              history={this.props.history}
              {...this.props}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Card>
              <CardHeader color="ebinprofsuccess">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Corrispondenza
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterCorrispondenza}
                      onChange={this.ChangefilterCorrispondenza}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={this.state.filterLabelTextCorrispondenza}
                />

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onAddCorrispondenzaClick}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rowsCorrispondenza}
                  dataKey="id"
                  first={this.state.firstRowToDisplay}
                  onPage={(e) =>
                    this.setState({
                      firstRowToDisplay: e.first,
                      rowsPerPageCorr: e.rows
                    })
                  }
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPageCorr}
                  rowsPerPageOptions={rowsPerPageOptions}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGridCorrispondenza}
                  loading={this.state.loadingGridCorrispondenza}
                  scrollable
                  onSelectionChange={this.handleTableClickCorrispondenza}
                  selectionMode="single"
                >
                  <Column
                    header=""
                    headerStyle={{ width: "50px" }}
                    body={this.DocActionsGridTemplate}
                    className="colDocActions"
                  />
                  <Column
                    field="matricola"
                    header="Matricola"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterCorrispondenza}
                    filterMatchMode="contains"
                  />
                  <Column
                    field="tipo"
                    header="Tipo"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    filter={this.state.filterCorrispondenza}
                    filterMatchMode="contains"
                  />
                  <Column
                    field="prot"
                    header="Prot"
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    filter={this.state.filterCorrispondenza}
                    filterMatchMode="contains"
                  />
                  <Column
                    field="data"
                    header="Data"
                    body={this.dateTemplateBody}
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    filter={this.state.filterCorrispondenza}
                    filterMatchMode="contains"
                    sortFunction={(event) => {
                      this.state.rowsCorrispondenza.sort((a, b) => {
                        let date1 = moment(a.data, dateTimeFmt),
                          date2 = moment(b.data, dateTimeFmt);
                        date2 = !isNaN(date2.unix()) ? date2.unix() : 0;
                        date1 = !isNaN(date1.unix()) ? date1.unix() : 0;
                        return event.order > 0 ? date1 - date2 : date2 - date1;
                      });
                      return this.state.rowsCorrispondenza;
                    }}
                  />
                  <Column
                    field="causale"
                    header="Caus."
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                    filter={this.state.filterCorrispondenza}
                    filterMatchMode="contains"
                  />
                  <Column
                    field="path"
                    header="Doc."
                    headerStyle={{ width: "50px" }}
                    sortable={true}
                    filter={this.state.filterCorrispondenza}
                    filterMatchMode="contains"
                    body={this.attachTemplateBody}
                  />
                  <Column
                    header="MAIL INVIATA"
                    headerStyle={{ width: "70px" }}
                    body={this.templateMailInviata}
                    className="colDocActions"
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={this.state.selectedGridPulitoriCustodi ? 6 : 12}
          >
            <Card>
              <CardHeader color="ebinprofsuccess">
                <h4
                  className="float-left"
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Versamenti
                </h4>

                <GridM
                  component="label"
                  container
                  className="float-left switch"
                  style={{
                    verticalAlign: "center",
                    margin: "0.1%",
                    width: "250px"
                  }}
                >
                  <GridM
                    style={{
                      color: "white",
                      verticalAlign: "center",
                      marginLeft: "20px",
                      marginTop: "10px"
                    }}
                  >
                    Custodi
                  </GridM>
                  <Switch
                    checked={this.state.filterVersamentiTable}
                    onChange={this.ChangeFilterVersamentiTable}
                    name="chkFilterGrid"
                    color="default"
                  />
                  <GridM
                    item
                    style={{
                      color: "white",
                      verticalAlign: "center",
                      marginLeft: "5px"
                    }}
                  >
                    Pulitori
                  </GridM>
                </GridM>

                <div
                  className="float-left date"
                  style={{
                    verticalAlign: "top",
                    lineHeight: "1",
                    marginRight: "5px",
                    display: "inline-flex"
                  }}
                >
                  <SkipPreviousIcon
                    onClick={this.DecreaseYear}
                    className="slide"
                  />
                  <YearPicker
                    onChange={this.handleDateChange}
                    value={this.state.competenzaAnno}
                    className="date"
                  />
                  <SkipNextIcon onClick={this.IncreaseYear} className="slide" />
                </div>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterVersamenti}
                      onChange={this.ChangefilterVersamenti}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={this.state.filterLabelTextVersamenti}
                />

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onAddClick}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>

                <ButtonGroup
                  variant="contained"
                  //color="default" // For Borse
                  //color="primary" // For Shared
                  color="secondary" // For Ebinprof
                  size="small"
                  aria-label="contained secondary button group"
                  className="float-right "
                  style={{ verticalAlign: "center", margin: "0.5%" }}
                >
                  <ButtonsMUI
                    onClick={() => this.onRedirectHandlerClick("Ano")}
                  >
                    Anomalie
                  </ButtonsMUI>
                  <ButtonsMUI
                    onClick={() => this.onRedirectHandlerClick("Congr")}
                  >
                    Congruità Versamenti
                  </ButtonsMUI>
                  <ButtonsMUI
                    onClick={() => this.onRedirectHandlerClick("Vers")}
                  >
                    Vers-Uni
                  </ButtonsMUI>
                  <ButtonsMUI
                    onClick={() => this.onRedirectHandlerClick("Uni")}
                  >
                    Uniemens
                  </ButtonsMUI>
                  <ButtonsMUI
                    onClick={() => this.onRedirectHandlerClick("Trasf")}
                  >
                    Trasf
                  </ButtonsMUI>
                </ButtonGroup>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterDm10}
                      onChange={this.ChangefilterDm10}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label="DM10"
                />
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={12}
                  rowsPerPageOptions={rowsPerPageOptionsMensile}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGridVersamenti}
                  loading={this.state.loadingGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClickVersamenti}
                  selectionMode="single"
                  options={{
                    rowStyle: {
                      fontSize: 1
                    }
                  }}
                >
                  <Column
                    field="azienda"
                    header="Matricola"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterVersamenti}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="codice_quota"
                    header="Cod. Quota"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterVersamenti}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="competenza"
                    header="Comp."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    filter={this.state.filterVersamenti}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="ripartizione"
                    header="Ripartizione"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterVersamenti}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importo_quota"
                    header="Importo"
                    headerStyle={{ width: "130px" }}
                    body={(row) =>
                      helpers.DecimalDTTemplate(row, "importo_quota")
                    }
                    sortable={true}
                    filter={this.state.filterVersamenti}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="caus_ins.codice"
                    header="Cod. Causale"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterVersamenti}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="caus_ins.descrizione"
                    header="Causale"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    filter={this.state.filterVersamenti}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="tipo_ultimo_agg"
                    header="Tipo Ultimo Agg."
                    headerStyle={{ width: "170px" }}
                    sortable={true}
                    filter={this.state.filterVersamenti}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="totale_dip"
                    header="Dipen."
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    filter={this.state.filterVersamenti}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="retribuzioni"
                    header="Retrib. Dip."
                    headerStyle={{ width: "140px" }}
                    body={(row) =>
                      helpers.DecimalDTTemplate(row, "retribuzioni")
                    }
                    sortable={true}
                    filter={this.state.filterVersamenti}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data_inser"
                    header="Data Inser."
                    headerStyle={{ width: "130px" }}
                    body={(row) =>
                      moment(row.data_inser, dateTimeFmt).format(dateFmt)
                    }
                    sortable={true}
                    filter={this.state.filterVersamenti}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />

                  {this.state.VisibleColumns && dynamicColumns}
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>

          {this.state.selectedGridPulitoriCustodi && (
            <GridItem xs={12} sm={12} md={6}>
              <PulitoriCustodiXAziendaEdit
                dataItem={this.state.selectedGridPulitoriCustodi}
                onAnnullaClick={this.onCloseGridClick}
                onInserisiciClick={this.onAddClick}
                onSaveClick={this.HandleSaveClick}
                onDeleteClick={this.DeleteRecord}
                nuovoModificaTitle={this.state.NuovoModificaTitle}
                versamentiId={this.props.match.params.versamentiId}
                anno={this.state.competenzaAnno}
                codQuota={this.state.filterVersamentiTable}
                addAlert={this.props.addAlert.current.addAlert}
              />
            </GridItem>
          )}
        </GridContainer>
      </div>
    );
  }
}
export default VersamentiDetails;
