import React from "react";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import UndoIcon from "@material-ui/icons/Undo";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import TrasferisciUniemens from "./TrasferimentoUniemens.js";
import { Checkbox } from "primereact/checkbox";
import StaticLists from "helpers/StaticLists";
import { Calendar } from "primereact/calendar";
import DipUniAPI from "API/DipUniAPI";
import AliquotaAPI from "API/AliquotaAPI";

// Dictionary where keys are error messages from server and values are messages to
// be shown to the user
const errors = {
  "error.rdl.notFound": "RDL non trovato per il dipendente selezionato"
};

const compOnDBFmt = "YYYY/MM";
const compToSendFmt = "YYYY/MM";

export default class InserimentoUniemens extends React.Component {
  state = {
    uniemens: null,
    checkedTrasferisci: false,
    checkedAggiornamentoAutomatico: false,
    selectedCodice: null,
    codFis: [],
    selectedCf: null,
    rdlNotExists: false,
    CodiceQuota: StaticLists.CodiceQuota(),
    //VALIDATIONS
    fieldValidations: {},
    validationRules: {
      Imponibile: "NUMBER"
    },

    Title: "Inserimento",
    aliquote: []
  };

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.computeImporto = this.computeImporto.bind(this);
    this.deleteUniemens = this.deleteUniemens.bind(this);
    this.redirectToRDLSearch = this.redirectToRDLSearch.bind(this);
    this.scrollRef = React.createRef();
  }

  async deleteUniemens() {
    const response = await DipUniAPI.Delete(this.props.selectedUniemens.id);

    if (response && response.length > 0) {
      this.props.onAnnullaClick();
      this.props.addAlert.current.addAlert("success", `Uniemens eliminato`);
    } else {
      this.props.onAnnullaClick();
      this.props.addAlert.current.addAlert(
        "danger",
        "Eliminazione non riuscita"
      );
    }

    return await this.props.onDelete();
  }

  async isValidForm() {
    const keysToCheck = Object.keys(this.state.uniemens).filter(
      (key) => key !== "matricola"
    );
    return keysToCheck.length > 0
      ? keysToCheck.every(
          (key) =>
            !(
              this.state.uniemens[key] === null ||
              this.state.uniemens[key] === undefined
            )
        )
      : false;
  }

  HandleChange(field, newValue) {
    let newresult = this.state.uniemens;
    newresult[field] = newValue;

    this.setState({ result: newresult });
  }

  async HandleSaveClick() {
    const json = {...this.state.uniemens};
    if (this.props.selectedUniemens?.id)
      json.id = this.props.selectedUniemens.id;
    const response = await DipUniAPI.Insert(json);
    if (response.result && response.result.length > 0) {
      this.props.onAnnullaClick();
      this.props.addAlert.current.addAlert(
        "success",
        `Aggiunti ${response.result.length} Uniemens`
      );
      this.setState({ rdlNotExists: false });
    } else {
      if (response.message === "error.rdl.notFound")
        this.setState({ rdlNotExists: true });

      let message = errors[response.message]
        ? errors[response.message]
        : response.message;
      this.props.addAlert.current.addAlert(
        "warning",
        `salvataggio non riuscito: ${message}`
      );
      this.props.showInserisciRDL(json);
    }
    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedUniemens && this.props.selectedUniemens) {
      if (prevProps.selectedUniemens.id !== this.props.selectedUniemens.id) {
        this.modifyUniemens();
      }
    }
    if (!prevProps.selectedUniemens && this.props.selectedUniemens) {
      this.modifyUniemens();
    }
    if (prevProps.selectedUniemens && !this.props.selectedUniemens) {
      this.newUniemens();
    }
  }

  newUniemens() {
    this.setState({
      uniemens: {
        matricola: this.props.azienda.matricola,
        data_comp_da: null,
        data_comp_a: null,
        cod_quota: this.props.codiceQuota
      },
      Title: "Inserimento"
    });
  }

  modifyUniemens() {
    const comp = moment(this.props.selectedUniemens.comp, compOnDBFmt).format(
      compToSendFmt
    );

    this.setState({
      uniemens: {
        cod_quota: this.props.selectedUniemens.cod_quota,
        data_comp_da: comp,
        data_comp_a: comp,
        imponibile: this.props.selectedUniemens.imponibile,
        importo: this.props.selectedUniemens.importo,
        cf: this.props.selectedUniemens.dipendente.cf,
        matricola: this.props.azienda.matricola
      },
      selectedCf: this.props.selectedUniemens.dipendente.cf,
      Title: "Modifica"
    });
  }

  async componentDidMount() {
    const uniemens = JSON.parse(
      localStorage.getItem("InserimentoUniemens:uniemens")
    );
    if (this.props.selectedUniemens !== null) {
      this.modifyUniemens();

      const filteredCodice = this.state.CodiceQuota.filter(
        (codice) => codice.name === this.props.selectedUniemens.cod_quota
      );
      this.setState({
        selectedCodice: filteredCodice[0].id
      });
    } else if (uniemens) {
      this.setState({ uniemens: uniemens });
      localStorage.removeItem("InserimentoUniemens:uniemens");
    } else this.newUniemens();

    this.setState({
      codFis: this.props.employees.map((e) => e.cf),
      CodiceQuota: await StaticLists.CodiceQuota(),
      aliquote: await this.fetchAliquote()
    });
    try {
      this.scrollRef.current.scrollIntoView();
    } catch(err){}
  }

  async fetchAliquote() {
    return await AliquotaAPI.GetAll().catch((e) => {
      this.props.onAnnullaClick();
      this.props.addAlert.current.addAlert("danger", e);
    });
  }

  computeImponibile(importo) {
    if(!importo){
      this.HandleChange("importo", "");
      this.HandleChange("imponibile", "");      
      return;
    }
    const value = Number(importo);

    if (!this.state.checkedAggiornamentoAutomatico) {
      const aliquotas = this.state.aliquote.filter(
        (a) => a.cod_quota === this.state.uniemens.cod_quota
      );

      const aliquota =
        aliquotas.length > 0 ? 1 / (aliquotas[0].aliquota * 0.01) : 0; // 0.01 because on the db is in percent

      this.HandleChange(
        "imponibile",
        isNaN(value) ? null : 1 * (value * aliquota).toFixed(2)
      );
    }

    this.HandleChange("importo", isNaN(value) ? null : value);
  }

  computeImporto(imponibile) {
    if(!imponibile){
      this.HandleChange("importo", "");
      this.HandleChange("imponibile", "");      
      return;
    }

    const value = Number(imponibile);

    if (!this.state.checkedAggiornamentoAutomatico) {
      const aliquotas = this.state.aliquote.filter(
        (a) => a.cod_quota === this.state.uniemens.cod_quota
      );

      const aliquota = aliquotas.length > 0 ? aliquotas[0].aliquota * 0.01 : 1; // 0.01 because on the db is in percent

      this.HandleChange(
        "importo",
        isNaN(value) ? null : 1 * (value * aliquota).toFixed(2)
      );
    }

    this.HandleChange("imponibile", isNaN(value) ? null : value);
  }

  redirectToRDLSearch() {
    localStorage.setItem(
      "InserimentoUniemens:uniemens",
      JSON.stringify(this.state.uniemens)
    );

    const employee = this.props.employees.find(
      (e) => e.cf === this.state.uniemens.cf
    );

    this.props.history.push(
      `/admin/RDL/${this.props.match.params.aziendaId}/${employee.id}?type=fromUniemens`
    );
  }

  render() {
    return (
      <div ref={this.scrollRef}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="info">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  {this.state.Title} Uniemens
                </h4>

                {this.state.rdlNotExists && (
                  <Button
                    onClick={this.redirectToRDLSearch}
                    color="warning"
                    aria-label="edit"
                    className="float-center"
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%",
                      marginLeft: "100px",
                      marginRight: "0"
                    }}
                  >
                    Inserisci RDL
                  </Button>
                )}

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Annulla"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%",
                      marginLeft: "0",
                      marginRight: "0"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.props.onAnnullaClick}
                  >
                    <UndoIcon />
                  </Button>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Matricola"
                      id="matricola"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.props.azienda.matricola || ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={6} md={6} className="select">
                    <CustomSelect
                      labelText="CF dip"
                      id="codf"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: false,
                        onChange: (e) =>
                          this.HandleChange("cf", e.target.value),
                        value: this.state.uniemens?.cf || ""
                      }}
                    >
                      {this.state.codFis.map((cf) => (
                        <MenuItem value={cf} key={cf}>
                          {cf.toUpperCase()}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </GridItem>

                  <GridItem xs={4} sm={4} md={4} className="select">
                    <CustomSelect
                      labelText="Codice quota"
                      id="codq"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: false,
                        onChange: (e) => {
                          this.HandleChange("cod_quota", e.target.value);
                          this.computeImporto(this.state.uniemens.imponibile);
                        },
                        value: this.state.uniemens?.cod_quota || ""
                      }}
                    >
                      {this.state.CodiceQuota.map((item) => (
                        <MenuItem value={item.name} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </GridItem>

                  <GridItem xs={6} sm={6} md={6} className="select">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={this.state.checkedAggiornamentoAutomatico}
                          onChange={(e) =>{
                            this.HandleChange("imponibile", "");
                            this.HandleChange("importo", "");
                            this.setState({
                              checkedAggiornamentoAutomatico: e.target.checked,
                            })
                          }}
                        />
                      }
                      label="&nbsp;Aggiornamento manuale (per editare manualmente i valori)"
                      className="lblNoMargin"
                      style={{
                        position: "relative",
                        top: "25px"
                      }}
                    />
                  </GridItem>
                </GridContainer>

                <GridContainer>
                  <GridItem xs={12} sm={6} md={2} className="customdate">
                    <p className="lblNoMargin">Competenze Da</p>
                    <Calendar
                      required
                      id="icon"
                      value={moment(
                        this.state.uniemens?.data_comp_da,
                        compToSendFmt
                      ).toDate()}
                      view="month"
                      dateFormat="yy/mm"
                      onChange={(e) => {
                        this.HandleChange(
                          "data_comp_da",
                          moment(e.target.value).format(compToSendFmt)
                        );
                        this.HandleChange(
                          "data_comp_a",
                          moment(e.target.value).format(compToSendFmt)
                        );
                      }}
                      showIcon
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} md={2} className="customdate">
                    <p className="lblNoMargin">Competenze A</p>
                    <Calendar
                      id="icon"
                      value={moment(
                        this.state.uniemens?.data_comp_a,
                        compToSendFmt
                      ).toDate()}
                      view="month"
                      dateFormat="yy/mm"
                      onChange={(e) =>
                        this.HandleChange(
                          "data_comp_a",
                          moment(e.target.value).format(compToSendFmt)
                        )
                      }
                      showIcon
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      success={true}
                      error={false}
                      labelText="Imponibile *"
                      id="imponibile"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        onChange: (event) =>
                          this.computeImporto(event.target.value),
                        value: this.state.uniemens?.imponibile
                      }}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Importo"
                      id="importo"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        value: this.state.uniemens?.importo,
                        onChange: (e) => this.computeImponibile(e.target.value)
                      }}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={1}
                    style={{ marginTop: "30px" }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={this.state.checkedTrasferisci}
                          onChange={(e) =>
                            this.setState({
                              checkedTrasferisci: e.target.checked
                            })
                          }
                        />
                      }
                      label="&nbsp;Trasferisci"
                      className="lblNoMargin"
                    />
                  </GridItem>
                </GridContainer>
                {this.state.checkedTrasferisci && (
                  <TrasferisciUniemens result={this.state.uniemens} />
                )}
              </CardBody>
              <CardFooter>
                <ButtonSaveWithLoading
                  onClick={this.HandleSaveClick}
                  text={"SALVA"}
                  isValidForm={this.isValidForm}
                />
                {this.props.selectedUniemens && (
                  <Button onClick={this.deleteUniemens}>Elimina</Button>
                )}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
