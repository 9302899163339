import Utils from "./Utils";

const tipoLavoratoreAPI = {

    getAll: async function () {
        const response = await Utils.CreatePostRequest("coasco/unitipolav/getall");
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async update(tipoLavoratore) {
        const response = await Utils.CreatePutRequest("coasco/unitipolav/update", tipoLavoratore);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async insert(tipoLavoratore) {
        const response = await Utils.CreatePostRequest("coasco/unitipolav/insert", tipoLavoratore);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async delete(tipoLavoratore) {
        const response = await Utils.CreateDeleteRequest("coasco/unitipolav/delete?id=" + tipoLavoratore.codice);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    getNew() {
        return {
            codice: "",
            descrizione: ""
        };
    }

};

export default tipoLavoratoreAPI;