import Utils from "./Utils";
import helpers from "../helpers/Helpers";

const ScarichiAPI = {

  GetTotScarichiF24: async function () {
    let result = await Utils.CreatePostRequest("coasco/scarichif24/gettot");
    if (result !== null) {
      helpers.formatCurrency(result.result, "totspese", "pagato", "impTot", "impMese2", "impMese1");
      return result.result;
    } else return null;
  },

  GetScarichiF24AnnoXCodice: async function (scarichiId, year) {
    let result = await Utils.CreatePostRequest(
      "coasco/scarichif24/gebyannoxcodquota?anno=" + year
    );
    if (result !== null) {
      result = result.result.map((item) => ({
        anno: item.anno,
        key: item.key,
        codi: item.cod_quota,
        totlm1: item.impMese1 !== 0 ? item.impMese1 : 0,
        totrm: item.righeM1,
        totlm2: item.impMese2 !== 0 ? item.impMese2 : 0,
        totrm2: item.righeM2,
        totr: item.righeTot,
        totl: item.impTot !== 0 ? item.impTot : 0,
        accr: item.pagato !== 0 ? item.pagato : 0,
        spese: item.totspese !== 0 ? item.totspese : 0
      }));
      helpers.formatCurrency(result, "totlm1", "totlm2", "totl", "accr", "spese");
      return result?.filter((row) => row.anno === year);
    } else return null;
  },

  GetScarichiF24AnnoXCodiceDettaglio: async function (scarichiId, year, type) {
    let result = await Utils.CreatePostRequest(
      "coasco/scarichif24/gebyannoxcodquotadettaglio" +
        "?codQ=" +
        type +
        "&anno=" +
        year
    );

    if (result !== null) {
      result = result.result.map((item) => ({
        id: item.id,
        anno: item.anno,
        progr: item.progr,
        codi: item.cod_quota,
        totlm1: item.totim1 !== 0 ? item.totim1 : 0,
        totrm: item.totrm1,
        totlm2: item.totim2 !== 0 ? item.totim2 : 0,
        totrm2: item.totrm2,
        totr: item.totr,
        totl: item.toti !== 0 ? item.toti : 0,
        accr: item.accr !== 0 ? item.accr : 0,
        spese: item.spese !== 0 ? item.spese : 0,
        datain: item.datai,
        datafn: item.dataf,
        chius: item.chiuso
      }));
      helpers.formatCurrency(result, "totlm1", "totlm2", "totl", "accr", "spese");
      return result?.filter((row) => row.anno === year);
    } else return null;
  },

  GetEmptyScarichiF24Pagamento: async function (codice, anno, prog) {
    return {
      id: 0,
      anno: parseInt(anno),
      codi: codice,
      importo: 0,
      datapag: null,
      banca: "",
      prog: prog
    };
  },

  GetEmptyScarichiF24Spese: async function (codice, anno, prog) {
    return {
      id: 0,
      anno: parseInt(anno),
      codi: codice,
      importo: 0,
      datafat: null,
      prog: prog,
      creditore: ""
    };
  },

  GetScarichiF24Pagamenti: async function (progrscar, year, type) {
    let result = await Utils.CreatePostRequest(
      "coasco/scarf24cassa/getAnnoCodQuotaPrg" +
        "?anno=" +
        year +
        "&cod_quota=" +
        type +
        "&progrscar=" +
        progrscar
    );

    if (result !== null) {
      result = result.result.map((item) => ({
        id: item.id,
        anno: item.anno,
        codi: item.cod_quota,
        importo: item.importo,
        datapag: item.datapag,
        banca: item.banca?.cod_banca,
        prog: item.progrscar
      }));
      helpers.formatCurrency(result, "importo");
      return result;
    } else return null;
  },

  GetScarichiTabulatiF24: async function (sede, quota, ripartizione) {
    var result = await Utils.CreatePostRequest(
      "coasco/scarichif24/getbyversamenti" +
        "?sede=" +
        sede +
        "&quota=" +
        quota +
        "&ripartizione=" +
        ripartizione
    );

    if (result !== null) {
      result = result.result.map((item) => ({
        key: item.key,
        comp: item.comp,
        codice: item.codice_quota,
        impquota: item.importo,
        numero: item.numero
      }));
      helpers.formatCurrency(result, "impquota");
    } else {
      result = [];
    }

    return result;
  },

  GetScarichiF24Spese: async function (progrscar, year, type) {
    let result = await Utils.CreatePostRequest(
      "coasco/scarf24spese/getAnnoCodQuotaPrg" +
        "?anno=" +
        year +
        "&cod_quota=" +
        type +
        "&progrscar=" +
        progrscar
    );

    if (result !== null) {
      result = result.result.map((item) => ({
        id: item.id,
        anno: item.anno,
        codi: item.cod_quota,
        importo: item.importo,
        datafat: item.data_fattura,
        creditore: item.creditore,
        prog: item.progrscar
      }));
      helpers.formatCurrency(result, "importo");
      return result;
    } else return null;
  },

  ChangeGetScarichiF24AnnoXCodiceDettaglioChiuso: async function (id, value) {
    const _value = value ? "SI" : "NO";
    return await Utils.CreatePostRequest(
        `coasco/scarichif24/close?id=${id}&value=${_value}`
    );
  },

  speseF24Insert: async function (objInsert) {
    return await Utils.CreatePostRequest(
        "coasco/scarf24spese/insert",
        objInsert
    );
  },

  cassaF24Insert: async function (objInsert) {
    return await Utils.CreatePostRequest(
        "coasco/scarf24cassa/insert",
        objInsert
    );
  },

  banche: async function () {
    const result = await Utils.CreatePostRequest(
      "coasco/banche/getall?start=1&size=100"
    );

    return result.result;
  },

  speseF24Update: async function (objUpdate) {
    return await Utils.CreatePutRequest(
      "coasco/scarf24spese/update",
      objUpdate
    );
  },

  cassaF24Update: async function (objUpdate) {
    return await Utils.CreatePutRequest(
      "coasco/scarf24cassa/update",
      objUpdate
    );
  },

  deleteSpese: async function (id) {
    return  await Utils.CreateDeleteRequest(
      "coasco/scarf24spese/delete?id=" + id
    );
  },

  deleteCassa: async function (id) {
    return await Utils.CreateDeleteRequest(
      "coasco/scarf24cassa/delete?id=" + id
    );
  },

  AddScarichi: async function () {
    return 1;
  },
  UpdateScarichi: async function () {
    return 1;
  }
};
export default ScarichiAPI;
