import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import helpers from "helpers/Helpers";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Search from "@material-ui/icons/Search";
import { AutoComplete } from "primereact/autocomplete";
import Clear from "@material-ui/icons/Clear";
import queryString from "query-string";
import AziendeAPI from "../../API/AziendeAPI.js";
import {rowsPerPageOptions} from "../../helpers/AppConstants";

class Versamenti extends Component {
  state = {
    selectedGrid: null,
    isChecked: false,
    visibleColumn: false,
    versamentiId: null,

    initialRows: [],
    filteredRows: [], //renamed
    rowsPerPage: 10,

    filterGrid: false,
    loadingGrid: false,
    hideHeader: false,
    firstRowToDisplay: 0,

    //renamed
    matricolaFullList: [],
    ragioneFullList: [],
    cfPivaFullList: [],
    filteredMatricolaListByInput: null,
    filteredRagioneListByInput: null,
    filteredCfPivaListByInput: null,
    filterByCfPiva: "",
    filterByRagioneSociale: "",
    filterByMatricola: "",

    totalRecords: 0,
    lastPage: 0,
    sortField: "matricola",
    sortOrder: 1
  };
  constructor(props) {
    super(props);
    this.Changefilter = this.Changefilter.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.btnSearchRef = React.createRef();
    this.DocActionsGridTemplate = this.DocActionsGridTemplate.bind(this);
    this.searchMatricola = this.searchMatricola.bind(this);
    this.onRedirectClick = this.onRedirectClick.bind(this);
    this.searchCfPiva = this.searchCfPiva.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.searchRagione = this.searchRagione.bind(this);

    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.LoadElementsInGrid = this.LoadElementsInGrid.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });

    // get filtered values object
    let filterByRagioneSociale = this.state.filterByRagioneSociale;
    let filterByMatricola = this.state.filterByMatricola;

    var queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      var savedFilters = helpers.GetSearchFilters("AziendeList");
      if (savedFilters !== null) {
        filterByRagioneSociale = savedFilters.filterByRagioneSociale;
        filterByMatricola = savedFilters.filterByMatricola;
      }
    } else {
      helpers.SaveSearchFilters(null, "AziendeListOrder");
      helpers.SaveSearchFilters(null, "AziendeList");
    }
    //get all rows
    var responseObject = await AziendeAPI.SearchByMatrAndDen(
      filterByMatricola,
      filterByRagioneSociale,
      0,
      "",
      ""
    );
    var filteredRowsInitial = responseObject.result;

    this.setState(
      {
        filteredRows: filteredRowsInitial,
        totalRecords: responseObject.tot,
        filterByMatricola: filterByMatricola,
        filterByRagioneSociale: filterByRagioneSociale
      },
      async () => {
        let filteredRows = filteredRowsInitial;

        var selectedRow = null;
        var firstRowToDisplay = 0;

        var aziendaId = this.props.match.params.aziendaId;
        if (aziendaId > 0) {
          selectedRow = helpers.ReturnRowFromListById(filteredRows, aziendaId);
          firstRowToDisplay = helpers.GetFirstElementOfPageIncludingId(
            aziendaId,
            filteredRows,
            10
          );
        }

        this.setState({
          firstRowToDisplay: firstRowToDisplay,
          filteredRows: filteredRows
        });

        if (selectedRow)
          await this.handleTableClick({
            value: selectedRow
          });
      }
    );

    this.setState({
      loadingGrid: false
    });
  }

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  searchCfPiva(event) {
    setTimeout(async () => {
      var list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByCfPiva(event.query)
          : [];

      this.setState({
        filteredCfPivaListByInput: list
      });
    }, 250);
  }

  searchRagione(event) {
    setTimeout(async () => {
      var list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByRagione(event.query)
          : [];

      this.setState({
        filteredRagioneListByInput: list
      });
    }, 250);
  }

  searchMatricola(event) {
    setTimeout(async () => {
      var list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByMatr(event.query)
          : [];
      this.setState({
        filteredMatricolaListByInput: list
      });
    }, 250);
  }

  onRedirectClick(e, rowData) {
    this.props.history.push("/admin/versamenti/details/" + rowData.id);
  }

  handleSearchClick = async () => {
    helpers.SaveSearchFilters(
      {
        filterByRagioneSociale: this.state.filterByRagioneSociale,
        filterByMatricola: this.state.filterByMatricola,
        filterByCfPiva: this.state.filterByCfPiva
      },
      "AziendeList"
    );

    this.setState({
      selectedGrid: null,
      firstRowToDisplay: 0
    });

    await this.LoadElementsInGrid(0);
  };

  async handleClearClick() {
    helpers.SaveSearchFilters(null, "AziendeList");

    this.setState({
      loadingGrid: true
    });

    this.setState(
      {
        selectedGrid: null,
        filteredMatricolaListByInput: null,
        filteredRagioneListByInput: null,
        filteredCfPivaListByInput: null,
        filterByCfPiva: "",
        filterByMatricola: "",
        filterByRagioneSociale: ""
      },
      async () => {
        await this.LoadElementsInGrid(0);
      }
    );
  }

  async Changefilter(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  onDetailsClick() {
    this.setState({
      visibleColumn: true
    });
  }

  DocActionsGridTemplate(rowData) {
    return (
      <>
        <Button
          round
          color="info"
          className="actionButton actionButtonRound btnRound  "
          key={rowData.Id}
          onClick={(e) => this.onRedirectClick(e, rowData)}
        >
          <VisibilityIcon className="icon" />
        </Button>
      </>
    );
  }

  async handleTableClick(e) {
    this.setState({
      selectedGrid: e.value
    });

    if (e.value !== null)
      this.setState({
        versamentiId: e.value.id
      });
  }

  async LoadElementsInGrid(newPage) {
    let page = newPage;

    if (page === undefined || page === "" || page === null)
      page = this.state.lastPage;
    var responseObject = await AziendeAPI.SearchByMatrAndDenAndCfPiva(
      this.state.filterByMatricola,
      this.state.filterByRagioneSociale,
      this.state.filterByCfPiva,
      page,
      this.state.sortField,
      this.state.sortOrder === 1 ? "desc" : "asc"
    );

    this.setState({
      filteredRows: responseObject.result,
      totalRecords: responseObject.tot,
      loadingGrid: false
    });

    if (newPage)
      this.setState({
        lastPage: newPage
      });
  }

  async onSelectMatricola(e) {
    const response = await AziendeAPI.GetAziendaByMatr(e.value);
    this.onRedirectClick(e.originalEvent, response);
  }

  async onSortChange(e) {
    helpers.SaveSearchFilters(
      {
        sortField: e.sortField,
        sortOrder: e.sortOrder
      },
      "AziendeListOrder"
    );

    this.setState(
      {
        sortField: e.sortField,
        sortOrder: e.sortOrder
      },
      () => this.LoadElementsInGrid()
    );
  }

  async onPageChange(e) {
    this.setState({
      firstRowToDisplay: e.first,
      loadingGrid: true,
      rowsPerPage: e.rows
    });
    await this.LoadElementsInGrid(e.page);
  }

  render() {
    return (
      <>
        <div ref={this.btnSearchRef} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <h4 style={{ lineHeight: "1" }}>
                  Impostazione Ricerca: Inserire Matricola o Ragione Sociale
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={3} className="headerinput">
                    <AutoComplete
                      value={this.state.filterByMatricola}
                      suggestions={this.state.filteredMatricolaListByInput}
                      placeholder="Cerca per Matricola"
                      completeMethod={this.searchMatricola}
                      onChange={(e) =>
                        this.HandleChange("filterByMatricola", e.value)
                      }
                      className="matricolaAutocomplete"
                      type="number"
                      onSelect={(e) => this.onSelectMatricola(e)}
                    />
                  </GridItem>

                  <GridItem xs={3} className="headerinput">
                    <AutoComplete
                      value={this.state.filterByRagioneSociale}
                      placeholder="Cerca per Ragione"
                      suggestions={this.state.filteredRagioneListByInput}
                      completeMethod={this.searchRagione}
                      onChange={(e) =>
                        this.HandleChange("filterByRagioneSociale", e.value)
                      }
                    />
                  </GridItem>

                  <GridItem xs={3} className="headerinput">
                    <AutoComplete
                      value={this.state.filterByCfPiva}
                      placeholder="Cerca per Cf/Piva"
                      suggestions={this.state.filteredCfPivaListByInput}
                      completeMethod={this.searchCfPiva}
                      onChange={(e) =>
                        this.HandleChange("filterByCfPiva", e.value)
                      }
                    />
                  </GridItem>

                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Cerca"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      ref={this.btnSearchRef}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleSearchClick}
                    >
                      <Search />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Reimposta"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{ marginLeft: "4px" }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleClearClick}
                    >
                      <Clear />
                    </Button>
                  </Tooltip>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofsuccess">
                <h4 style={{ lineHeight: "1" }}>Azienda</h4>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.filteredRows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage} rowsPerPageOptions={rowsPerPageOptions}
                  first={this.state.firstRowToDisplay}
                  onPage={this.onPageChange}
                  onSort={this.onSortChange}
                  sortField={this.state.sortField}
                  sortOrder={this.state.sortOrder}
                  lazy
                  totalRecords={this.state.totalRecords}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  loading={this.state.loadingGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    header=""
                    headerStyle={{ width: "50px" }}
                    body={this.DocActionsGridTemplate}
                    className="colDocActions"
                  />
                  <Column
                    field="matricola"
                    header="Matricola"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="denominazione"
                    header="Ragione Sociale"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default Versamenti;
