import Utils from "./Utils";
import * as AppConstants from "../helpers/AppConstants";

const baseURL = AppConstants.SiteUrl + "ebinprof/TipoBS";

const TipoBorseAPI = {
  deleteScholarshipType: async function (id) {
    const url = `ebinprof/TipoBS/delete?id=${id}`;

    const res = await Utils.CreateDeleteRequest(url);
    return res?.result;
  },


  GetAllTipoBorse: async function () {
    const response = await Utils.SendPostRequestTo(`${baseURL}/getall`);
    return response;
  },

  GetTipoBorse: async function (id) {
    const response = await Utils.SendPostRequestTo(
      `${baseURL}/getById?codice=${id}`
    );
    return response;
  },

  NuovoAnnoAccademiciForBorse: function (annoId) {
    return null;
  },

  AddTipoBorse: async function (tipoBorsaObj) {
    const response = await Utils.SendPostRequestTo(
      `${baseURL}/insert`,
      tipoBorsaObj
    );
    return response;
  },

  UpdateTipoBorse: async function (tipoBorsaObj) {
    const response = await Utils.SendPutRequestTo(
      `${baseURL}/update`,
      tipoBorsaObj
    );
    return response;
  }
};
export default TipoBorseAPI;
