import React, { Component } from "react";
import clone from "clone";
import { confirmPopup } from "primereact/confirmpopup";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import MenuItem from "@material-ui/core/MenuItem";
import CustomInput from "components/CustomInput/CustomInput.js";
import UndoIcon from "@material-ui/icons/Undo";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import helpers from "helpers/Helpers";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ButtonsMUI from "@material-ui/core/Button";
import StaticLists from "helpers/StaticLists";
import AziendeAPI from "../../../API/AziendeAPI";
import SedeComuneProvAPI from "../../../API/SedeComuneProvAPI";
import CustodiPulitoriAPI from "API/CustodiPulitoriAPI";

export class PulitoriCustodiXAziendaEdit extends Component {
  state = {
    RowItem: [],
    CodiceQuota: [],
    selectedCodice: null,
    Causale: [],
    Sede: [],
    selectedCaus: null,
    selectedSede: null,

    filteredMatricolaListByInput: null,
    statoReport: null,
    versamenti: null,
    //VALIDATIONS
    fieldValidations: {},
    validationRules: {
      matricolaOrig: "",
      causale: "GTEQ-1",
      codQuotaOrig: "EQ-4",
      importoQuota: "GTEQ-1",
      meseFineCompDest: "GTEQ-1",
      meseInizCompDest: "GTEQ-1"
    },
    riempimentoRiga: null
  };

  codiciCausaliAZero = [
    "02",
    "04",
    "05",
    "07",
    "08",
    "09",
    "11",
    "15",
    "16",
    "17",
    "19",
    "21"
  ];

  constructor(props) {
    super(props);
    this.HandleChange = this.HandleChange.bind(this);
    this.SetText = this.SetText.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.searchMatricola = this.searchMatricola.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  SetText(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
    if (field === "meseInizCompDest") {
      newRowItem["meseFineCompDest"] = newValue;
    }
  }

  HandleChange(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
  }

  HandleDropDownChange(e, val, propName) {
    let rowItem = this.state.RowItem;
    rowItem[propName] = val;

    if (propName === "causale" && this.codiciCausaliAZero.includes(val)) {
      rowItem.importoQuota = "0";
    }

    if (propName === "causale" && val.slice(0, 2) === "43") {
      rowItem.codQuotaDest = this.getCodiceQuota()[1];
    }

    this.setState({
      [e]: val,
      RowItem: rowItem
    });
  }

  appearFunction(e, val, propName) {
    this.HandleDropDownChange(e, val, propName);
    this.setState({
      statoReport: val
    });
  }

  async componentDidMount() {
    let pc = CustodiPulitoriAPI.GetNuovoPulitoriCustodiByAziendaIdNuovo(null);

    if (this.props.dataItem.id !== null) {
      pc.annoComp = this.props.dataItem.competenza_anno;
      pc.causale =
        this.props.dataItem.caus_ins?.codice +
        " - " +
        this.props.dataItem.caus_ins?.descrizione;
      pc.codQuotaOrig = this.props.dataItem.codice_quota;
      pc.competenzaOrig = this.props.dataItem.competenza;
      pc.id = this.props.dataItem.id;
      pc.importoQuota = this.props.dataItem.importo_quota;
      pc.matricolaOrig = this.props.dataItem.azienda;
      pc.meseFineCompDest = this.props.dataItem.competenza_mese;
      pc.meseInizCompDest = this.props.dataItem.competenza_mese;
      pc.sede = this.props.dataItem.sede;
    } else {
      pc = this.props.dataItem;
      pc.codQuotaOrig = this.getCodiceQuota()[0];
      pc.codQuotaDest = this.getCodiceQuota()[1];
    }

    let versamentiId = this.props.versamentiId;

    let versamentiById = await AziendeAPI.GetAziendaById(versamentiId);
    this.setState({
      versamenti: versamentiById[0]
    });

    let sedeList = await SedeComuneProvAPI.GetAllSede();
    let causaleList =
      this.props.nuovoModificaTitle === "Inserimento"
        ? await StaticLists.GetCausaleInsert()
        : await StaticLists.GetCausaleUpdate();
    let codiceQuotaList = StaticLists.CodiceQuota();

    if (this.props.dataItem.sede === null) {
      this.props.dataItem.sede = this.state.versamenti.sede;
      this.props.dataItem.matricolaOrig = this.state.versamenti.matricola;
      this.props.dataItem.annoComp = this.props.anno;
    }

    this.setState({
      RowItem: pc,
      CodiceQuota: codiceQuotaList,
      Causale: causaleList,
      Sede: sedeList,
      riempimentoRiga: pc
    });

    this.setState({
      selectedCodice: this.props.dataItem.codice_quota || "",
      selectedCaus: this.props.dataItem.caus_ins?.codice,
      selectedSede: this.state.versamenti.sede
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let pc = {
      annoComp: this.props.anno,
      causale: null,
      codQuotaOrig: null,
      competenzaOrig: null,
      id: null,
      importoQuota: null,
      matricolaDest: null,
      matricolaOrig: this.state.versamenti?.matricola,
      meseFineCompDest: null,
      meseInizCompDest: null,
      sede: this.state.versamenti?.sede
    };

    if (this.props.dataItem.id !== null) {
      pc.annoComp = this.props.dataItem.competenza_anno;
      pc.causale =
        this.props.dataItem.caus_ins?.codice +
        " - " +
        this.props.dataItem.caus_ins?.descrizione;
      pc.codQuotaOrig = this.props.dataItem.codice_quota;
      pc.competenzaOrig = this.props.dataItem.competenza;
      pc.id = this.props.dataItem.id;
      pc.importoQuota = this.props.dataItem.importo_quota;
      pc.matricolaOrig = this.props.dataItem.azienda;
      pc.meseFineCompDest = this.props.dataItem.competenza_mese;
      pc.meseInizCompDest = this.props.dataItem.competenza_mese;
      pc.sede = this.props.dataItem.sede;
    }

    if (this.props.dataItem.id !== prevProps.dataItem.id) {
      pc.codQuotaOrig = this.getCodiceQuota()[0];
      this.setState({
        RowItem: pc,
        selectedCodice: pc.codQuotaOrig,
        selectedCaus: this.props.dataItem.caus_ins?.codice,
        selectedSede: this.state.versamenti.sede
      });
    }
  }

  async isValidForm() {
    this.state.RowItem.importoQuota =
      this.state.RowItem.importoQuota?.toString();
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);

    let isValid = Object.values(fieldValidations).every((x) => x === "success");

    this.setState({
      fieldValidations: fieldValidations
    });

    return isValid;
  }

  HandleSaveClick = async () => {
    this.state.RowItem.annoComp = this.props.anno;
    const data = {
      ...this.state.RowItem,
      importoQuota: parseFloat(
        this.state.RowItem.importoQuota.replace(",", ".")
      )
    };
    if (await this.isValidForm()) {
      return await this.props.onSaveClick(
        this.state.RowItem,
        this.state.statoReport
      );
    }
  };

  searchMatricola(event) {
    setTimeout(async () => {
      let list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByMatr(event.query)
          : [];
      this.setState({
        filteredMatricolaListByInput: list
      });
    }, 250);
  }

  onDeleteClick(event) {
    event.stopPropagation();
    const causale = this.state.RowItem.causale;
    if (
      causale.includes("31") ||
      causale.includes("42") ||
      causale.includes("44")
    ) {
      this.props.addAlert(
        "danger",
        "Eliminare il trasferimento con causale 30, 41 o 43"
      );
      return;
    }

    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        this.DeleteRecord(this.props.dataItem.id);
      },
      reject: () => {}
    });
  }

  async DeleteRecord() {
    return await this.props.onDeleteClick(this.state.RowItem);
  }

  getCodiceQuota() {
    let ris = [];
    if (this.props.anno < 2014) {
      ris.push(this.props.codQuota ? "W350" : "W300");
      ris.push(this.props.codQuota ? "W300" : "W350");
    } else {
      ris.push(this.props.codQuota ? "PULI" : "CUST");
      ris.push(this.props.codQuota ? "CUST" : "PULI");
    }
    return ris;
  }

  getDisabledForImporto(codiceCausale) {
    return codiceCausale
      ? this.codiciCausaliAZero.includes(codiceCausale.slice(0, 2))
      : false;
  }

  render() {
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofinfo">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  {this.props.nuovoModificaTitle} Versamenti
                </h4>
                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Annulla"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%",
                      marginLeft: "0",
                      marginRight: "0"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.props.onAnnullaClick}
                  >
                    <UndoIcon />
                  </Button>
                </Tooltip>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6} className="select">
                    <CustomSelect
                      success={
                        this.state.fieldValidations.causale &&
                        this.state.fieldValidations.causale === "success"
                      }
                      error={
                        this.state.fieldValidations.causale &&
                        this.state.fieldValidations.causale === "error"
                      }
                      labelText="Causale *"
                      id="caus"
                      formControlProps={{
                        fullWidth: true,
                        className: "CustomHeightSelect"
                      }}
                      inputProps={{
                        onChange: (e) =>
                          this.appearFunction(
                            "selectedCaus",
                            e.target.value,
                            "causale"
                          ),
                        value: this.state.selectedCaus || ""
                      }}
                    >
                      {this.state.Causale.map((item) => (
                        <MenuItem
                          value={item.codice.toString()}
                          key={item.codice}
                        >
                          {item.codice} - {item.descrizione}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6} style={{ marginTop: "6px" }}>
                    <CustomInput
                      id="Sede"
                      labelText="Sede *"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,

                        value: this.state.RowItem.sede || ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={this.state.statoReport === "43" ? 4 : 6}
                    style={{ marginTop: "6px" }}
                  >
                    <CustomInput
                      id="Matricola"
                      labelText="Matricola Originale *"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.RowItem.matricolaOrig || ""
                      }}
                    />
                  </GridItem>
                  {this.state.statoReport === "41" &&
                    this.props.nuovoModificaTitle === "Inserimento" && (
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ marginTop: "6px" }}
                      >
                        <CustomInput
                          id="MatricolaDest"
                          labelText="Matricola Destinazione *"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) =>
                              this.SetText("matricolaDest", event.target.value),
                            value: this.state.RowItem.matricolaDest || ""
                          }}
                        />
                      </GridItem>
                    )}
                  <GridItem
                    xs={12}
                    sm={12}
                    md={this.state.statoReport === "43" ? 4 : 6}
                    className="select"
                    style={{ marginTop: "6px" }}
                  >
                    <CustomSelect
                      labelText={
                        this.state.statoReport === "41" ||
                        this.state.statoReport === "43"
                          ? "Codice Quota di Origine *"
                          : "Codice Quota *"
                      }
                      id="codq"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: false,
                        onChange: (e) =>
                          this.HandleDropDownChange(
                            "selectedCodice",
                            e.target.value,
                            "codQuotaOrig"
                          ),
                        value: this.state.RowItem.codQuotaOrig || ""
                      }}
                    >
                      {this.state.CodiceQuota.map((item) => (
                        <MenuItem value={item.name} key={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </CustomSelect>
                  </GridItem>
                  {(this.state.statoReport === "41" ||
                    this.state.statoReport === "43") &&
                    this.props.nuovoModificaTitle === "Inserimento" && (
                      <GridItem
                        xs={12}
                        sm={12}
                        md={this.state.statoReport === "43" ? 4 : 6}
                        className="select"
                        style={{ marginTop: "6px" }}
                      >
                        <CustomSelect
                          labelText="Codice Quota Destinazione *"
                          id="CodQuotaDest"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: false,
                            onChange: (event) =>
                              this.SetText("codQuotaDest", event.target.value),
                            value:
                              this.state.RowItem.codQuotaDest ||
                              this.getCodiceQuota()[1]
                          }}
                        >
                          {this.state.CodiceQuota.map((item) => (
                            <MenuItem value={item.name} key={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </GridItem>
                    )}
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      success={
                        this.state.fieldValidations.importoQuota &&
                        this.state.fieldValidations.importoQuota === "success"
                      }
                      error={
                        this.state.fieldValidations.importoQuota &&
                        this.state.fieldValidations.importoQuota === "error"
                      }
                      id="ImportoQ"
                      labelText="Importo Quota *"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        // TODO: find a way to format this number in the correct way to do so
                        disabled: this.getDisabledForImporto(
                          this.state.RowItem.causale
                        ),
                        onChange: (event) => {
                          this.SetText("importoQuota", event.target.value);
                        },
                        value: this.state.RowItem.importoQuota,
                        type: "number"
                      }}
                    />
                  </GridItem>

                  {this.props.nuovoModificaTitle === "Inserimento" ? (
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="AnnoComp"
                        labelText="Anno Competenza *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.props.anno || ""
                        }}
                      />
                    </GridItem>
                  ) : (
                    <label>{}</label>
                  )}
                </GridContainer>
                <GridContainer>
                  {this.props.nuovoModificaTitle === "Inserimento" ? (
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.meseInizCompDest &&
                          this.state.fieldValidations.meseInizCompDest ===
                            "success"
                        }
                        error={
                          this.state.fieldValidations.meseInizCompDest &&
                          this.state.fieldValidations.meseInizCompDest ===
                            "error"
                        }
                        id="MeseIniz"
                        labelText="Mese Iniziale *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          type: "number",
                          onChange: (event) =>
                            this.SetText(
                              "meseInizCompDest",
                              event.target.value
                            ),
                          value: this.state.RowItem.meseInizCompDest || ""
                        }}
                      />
                    </GridItem>
                  ) : (
                    <label>{}</label>
                  )}

                  {this.props.nuovoModificaTitle === "Inserimento" ? (
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.meseFineCompDest &&
                          this.state.fieldValidations.meseFineCompDest ===
                            "success"
                        }
                        error={
                          this.state.fieldValidations.meseFineCompDest &&
                          this.state.fieldValidations.meseFineCompDest ===
                            "error"
                        }
                        id="MeseFine"
                        labelText="Mese Finale *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "number",
                          disabled: false,
                          onChange: (event) =>
                            this.SetText(
                              "meseFineCompDest",
                              event.target.value
                            ),
                          value: this.state.RowItem.meseFineCompDest || ""
                        }}
                      />
                    </GridItem>
                  ) : (
                    <label>{}</label>
                  )}

                  {(this.props.nuovoModificaTitle === "Inserimento" &&
                    this.state.statoReport === "30") ||
                  this.state.statoReport === "41" ||
                  this.state.statoReport === "43" ? (
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="CompetenzaOrig"
                        labelText="Competenza Originale *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) => {
                            if (event.target.value.length > 7) return;
                            this.SetText("competenzaOrig", event.target.value);
                          },
                          value: this.state.RowItem.competenzaOrig || ""
                        }}
                        placeholder="YYYY/MM"
                      />
                    </GridItem>
                  ) : (
                    <label>{}</label>
                  )}

                  {this.props.nuovoModificaTitle === "Modifica" ? (
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        id="Competenza"
                        labelText="Competenza *"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          onChange: (event) =>
                            this.SetText("competenzaOrig", event.target.value),
                          value: this.state.RowItem.competenzaOrig || ""
                        }}
                      />
                    </GridItem>
                  ) : (
                    <label>{}</label>
                  )}
                </GridContainer>
              </CardBody>

              <CardFooter>
                {this.props.nuovoModificaTitle === "Inserimento" && (
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                )}

                {this.props.dataItem.tipo_ultimo_agg?.toLowerCase() ===
                  "manuale" && (
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                )}

                {this.props.nuovoModificaTitle === "Modifica" &&
                  this.props.dataItem.tipo_ultimo_agg?.toLowerCase() ===
                    "manuale" && (
                    <ButtonsMUI
                      onClick={this.onDeleteClick}
                      variant="contained"
                      color="secondary"
                      size="small"
                      className="float-right"
                      style={{
                        verticalAlign: "center",
                        margin: "0.5%",
                        height: "36px"
                      }}
                    >
                      <DeleteForeverIcon />
                      Elimina
                    </ButtonsMUI>
                  )}
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
