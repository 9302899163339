const AutocompleteAPI = {
  GetMatricolaForAutocomplete: async function (matricola) {
    let rows = [
      "0100046762",
      "0100046763",
      "0100046764",
      "0100046765",
      "0100046766",
      "0100046767",
      "0100046768",
      "0100046769",
      "246678900"
    ];
    return rows;
  },

  GetCognomeForAutocomplete: async function (nominativo) {
    let rows = [
      "Albanese Simona",
      "Albano Antonietta",
      "Albano Gabriele",
      "Albero Maurizio"
    ];
    return rows;
  },

  GetCodFisForAutocomplete: async function (codfis) {
    let rows = [
      "LBNSMN91A50A662",
      "LBNSMN91A50A69X",
      "LBNSMN91A50A69T",
      "LBNSMN91A50A69Y",
      "LBNSMN91A50A663"
    ];
    return rows;
  },

  GetProtocolloForAutocomplete: async function (protocollo) {
    let rows = ["012-2012", "013-2013", "019-2019", "018-2018", "011-2011"];
    return rows;
  },

  AddAutocomplete: async function (obj) {
    return 1;
  },

  UpdateAutocomplete: async function (obj) {
    return 1;
  }
};
export default AutocompleteAPI;
