import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

// Contexts
import { AuthContext } from "providers";

const ProtectedRouteComponent = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        const { location } = props;
        if (isAuthenticated) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/auth",
                state: {
                  from: location
                }
              }}
            />
          );
        }
      }}
    />
  );
};

export default ProtectedRouteComponent;
