import { MenuItem } from "@material-ui/core";
import React from "react";

// Components
import CSelect from "components/CustomInput/CustomSelect";

export const Select = ({ disabled, label, onChange, value, options }) => {
  return (
    <CSelect
      disabled={disabled}
      labelText={label}
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        onChange,
        value
      }}
    >
      {options?.map((item) => (
        <MenuItem value={item.key} key={item.key}>
          {item.value}
        </MenuItem>
      ))}
    </CSelect>
  );
};
