import Utils from "./Utils";

const BancheAPI = {
    GetAll: async function () {
        return (await Utils.CreatePostRequest("coasco/banche/getall?start=0&size=100", {})).result;
    },
    GetByCodiceQuota: async function(codiceQuota) {
        return (await this.GetAll()).find((x) => x.descrizione === codiceQuota);
    }
};

export default BancheAPI;