import Utils from "./Utils";
import * as AppConstants from "../helpers/AppConstants";

const baseURL = AppConstants.SiteUrl + "ebinprof/StatoRichies";

const TipoBorseAPI = {
  GetAllStato: async function () {
    const response = await Utils.SendPostRequestTo(`${baseURL}/getall`);
    return response.result;
  },

  GetStatoById: async function (id) {
    const response = await Utils.SendPostRequestTo(
      `${baseURL}/getByID?codice=${id}`
    );
    return response.result;
  },

  NewStato: function (annoId) {
    return null;
  },

  AddStato: async function (anniobj) {
    return 1;
  },

  UpdateStato: async function (anniobj) {
    return 1;
  }
};
export default TipoBorseAPI;
