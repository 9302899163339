import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import GestioneTabelleAPI from "API/GestioneTabelleAPI";

class AgenziePostali extends Component {
  state = {
    rows: [],
    totalRecords: 0,
    firstRowToDisplay: 0,
    loadingGrid: true
  };
  constructor(props) {
    super(props);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
  }

  async componentDidMount() {
    let ris = await GestioneTabelleAPI.GetAllAgenziePostali();

    this.setState({
      rows: ris.result,
      totalRecords: ris.tot,
      loadingGrid: false
    });
  }

  onPageChange(e) {
    this.setState({
      firstRowToDisplay: e.first,
      currentPage: e.page
    });
  }

  onSortChange(e) {
    this.setState({
      sortField: e.sortField,
      sortOrder: e.sortOrder
    });
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofsuccess">
                <h4 style={{ lineHeight: "1" }}>Agenzie Postali</h4>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  first={this.state.firstRowToDisplay}
                  onPage={(e) => this.setState({ firstRowToDisplay: e.first })}
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  scrollable
                  selectionMode="single"
                >
                  <Column
                    field="codice"
                    header="Titolo"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="comune"
                    header="Città"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="agenzia"
                    header="Agenzia"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cap"
                    header="cap"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default AgenziePostali;
