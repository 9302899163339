import React, { Component } from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import { Button } from "primereact/button";

import queryString from "query-string";
import AmministratoriAPI from "API/AmministratoriAPI";
export class DitteXAmministratoreHeader extends Component {
  state = {
    amministratori: [],
    amministratoreAzienda: []
  };

  constructor(props) {
    super(props);

    this.backButtonClick = this.backButtonClick.bind(this);
  }

  async componentDidMount() {
    let id;

    if (this.props.location.search.split("=")[2] !== undefined) {
      id = this.props.location.search.split("=")[2];
    } else {
      id = this.props.location.search.split("=")[1];
    }

    let amministratoriAziende =
      await AmministratoriAPI.GetAmministratoriForAziendaById(id);
    this.setState({
      amministratori: amministratoriAziende[0].amministratore,
      amministratoreAzienda: amministratoriAziende[0].azienda
    });
  }

  backButtonClick() {
    let qS = queryString.parse(this.props.history.location.search);
    if (!qS.type)
      this.props.history.push(
        "/admin/aziende/" + this.props.match.params.aziendaId
      );
    else if (qS.type === "fromAmministratori")
      this.props.history.push(
        "/admin/amministratori/" + this.props.match.params.amministratoreId
      );
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Button
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />

                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Amministratori
                </h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Codice Fiscale"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.amministratori.cf || ""
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Matricola"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.amministratoreAzienda.matricola || ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Ragione Sociale"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value:
                          this.state.amministratoreAzienda.denominazione || ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Nominativo"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.amministratori.nominativo || ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
