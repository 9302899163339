import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ButtonsMUI from "@material-ui/core/Button";
import Clear from "@material-ui/icons/Clear";
import { Dropdown } from "primereact/dropdown";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Search from "@material-ui/icons/Search";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AnniAccademiciAPI from "API/AnniAccademiciAPI";
import StatisticheRichiesteBorseAPI from "API/StatisticheRichiesteBorseAPI";
import StatisticheBorseAPI from "API/StatisticheBorseAPI";
import RichiestiAPI from "API/RichiestiAPI";
import Utils from "API/Utils";
import {rowsPerPageOptions} from "../../helpers/AppConstants";

const Options = [
  { id: 1, name: "Riepilogo Generale Richieste Pervenute" },
  { id: 2, name: "Richieste perv. per Anno, Tipo, Sesso" },
  { id: 3, name: "Richieste perv. per Anno, Tipo, Provincia" },
  { id: 4, name: "Borse Assegnate per Anno, Tipo, Sesso" },
  { id: 5, name: "Borse Assegnate per Anno, Tipo, Provincia" }
];

class Statistiche extends Component {
  state = {
    rows: [],
    columns: [],
    AnniAccademici: [],
    selectedAnno: null,
    filterGrid: null,
    selectedGrid: null,
    selectedStat: null,
    visible: false,
    loadingGrid: false
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.exportHandler = this.exportHandler.bind(this);
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  async handleSearchClick() {
    const type = this.state.selectedStat?.id;
    if (this.state.selectedStat) {
      if (type === 1) {
        const res = await RichiestiAPI.getGeneralReceivedRequests();
        this.setState({
          tableData: res,
          columns: [
            { field: "annoAcc", header: "Anno Acc." },
            { field: "totali", header: "Totali" },
            { field: "donne", header: "Donne" },
            { field: "uomini", header: "Uomini" },
            { field: "laur", header: "Laur" },
            { field: "stuni", header: "StUni" },
            { field: "stms", header: "StMS" },
            { field: "l4000", header: "L4000" },
            { field: "stmsd", header: "StMSD" },
            { field: "sudis", header: "SUDis" }
          ]
        });
      } else if (type === 2) {
        const res = await RichiestiAPI.getRequestsByYearTypeSex();
        this.setState({
          tableData: res,
          columns: [
            { field: "annoAcc", header: "Anno Accademico" },
            { field: "tipoBS", header: "Tipo Borsa" },
            { field: "nrich", header: "Tipo Pervenute" },
            { field: "sesso", header: "Sesso" }
          ]
        });
      } else if (type === 3) {
        const res = await RichiestiAPI.getRequestsByYearTypeProvince();
        this.setState({
          tableData: res,
          columns: [
            { field: "annoAcc", header: "Anno Accademico" },
            { field: "tipoBS", header: "Tipo Borsa" },
            { field: "nrich", header: "Tipo Pervenute" },
            { field: "prov", header: "Provincia" }
          ]
        });
      } else if (type === 4) {
        const resScholarshipsYearTypeSex =
          await StatisticheBorseAPI.getBorseAssegAnnoTipoSesso();
        this.setState({
          tableData: resScholarshipsYearTypeSex.result,
          columns: [
            { field: "anno", header: "Anno Accademico" },
            { field: "descrborsa", header: "Tipo Borsa" },
            { field: "nrich", header: "NumRich" },
            { field: "sesso", header: "Sesso" }
          ]
        });
      } else if (type === 5) {
        const resScholarshipsYearTypeSex =
          await StatisticheBorseAPI.getAnnoTipoProvincia();
        this.setState({
          tableData: resScholarshipsYearTypeSex.result,
          columns: [
            { field: "anno", header: "Anno Accademico" },
            { field: "descrborsa", header: "Tipo Borsa" },
            { field: "nrich", header: "NumRich" },
            { field: "prov", header: "Provincia" }
          ]
        });
      }
      this.setState({
        isVisible: true
      });
      await this.refreshGrid();
    }
  }

  async handleClearClick() {
    this.setState({
      selectedStat: null,
      selectedAnno: null,
      isVisible: false,
      selectedGrid: null
    });

    await this.refreshGrid();
  }

  async componentDidMount() {
    const anniAccademici = await AnniAccademiciAPI.AnnoAccademiciForBorse(null);
    let newAnniAccademici = anniAccademici.map((aa) => {
      return { id: aa.idAnno, name: aa.descr };
    });

    this.setState({
      AnniAccademici: newAnniAccademici
    });
    await this.refreshGrid();
  }

  HandleDropDownChange(e, val) {
    this.setState({ [e]: val });
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true
    });

    if (this.state.selectedStat !== null && this.state.selectedAnno !== null) {
      const year = this.state.selectedAnno.name;
      switch (this.state.selectedStat.id) {
        case 1:
          const resYearTypePervenute =
            await StatisticheRichiesteBorseAPI.getRichiesteAnnoTipoPervenute(
              year
            );
          this.setState({
            rows: resYearTypePervenute.result,
            columns: [
              { field: "anno", header: "Anno Accademico" },
              { field: "tipoBorsa", header: "Tipo Borsa" },
              { field: "campo", header: "Tipo Pervenute" }
            ]
          });
          break;
        case 2:
          const resYearTypeSex =
            await StatisticheRichiesteBorseAPI.getRichiesteAnnoTipoSesso(year);
          this.setState({
            rows: resYearTypeSex.result,
            columns: [
              { field: "anno", header: "Anno Accademico" },
              { field: "tipoBorsa", header: "Tipo Borsa" },
              { field: "campo", header: "Sesso" }
            ]
          });
          break;

        case 3:
          const resYearTypeProvince =
            await StatisticheRichiesteBorseAPI.getRichiesteAnnoTipoProvincia(
              year
            );
          this.setState({
            rows: resYearTypeProvince.result,
            columns: [
              { field: "anno", header: "Anno Accademico" },
              { field: "tipoBorsa", header: "Tipo Borsa" },
              { field: "campo", header: "Provincia" }
            ]
          });
          break;

        case 4:
          const resScholarshipsYearTypeSex =
            await StatisticheBorseAPI.getBorseAssegAnnoTipoSesso(year);
          this.setState({
            rows: resScholarshipsYearTypeSex.result,
            columns: [
              { field: "anno", header: "Anno Accademico" },
              { field: "descrborsa", header: "Tipo Borsa" },
              { field: "nrich", header: "NumRich" },
              { field: "prov", header: "Provincia" },
              { field: "sesso", header: "Sesso" }
            ]
          });
          break;
        case 5:
          const resScholarshipsYearTypeProvince =
            await StatisticheBorseAPI.getAnnoTipoProvincia(year);
          this.setState({
            rows: resScholarshipsYearTypeProvince.result,
            columns: [
              { field: "descrtipo", header: "Anno Accademico" },
              { field: "descrborsa", header: "Tipo Borsa" },
              { field: "campo", header: "Provincia" }
            ]
          });
          break;

        default:
          this.props.addAlert.current.addAlert(
            "warning",
            "Non sono stati trovati dati con i filtri selezionati."
          );
      }
    }
    this.setState({
      loadingGrid: false
    });
  }

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: e.value
    });
  };

  exportHandler() {
    Utils.ExportExcel(this.state.tableData);
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Statistiche
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={5}>
                    <Dropdown
                      display="chip"
                      placeholder="Ricerca per tipo"
                      optionLabel="name"
                      value={this.state.selectedStat}
                      options={Options}
                      onChange={(e) =>
                        this.HandleDropDownChange(
                          "selectedStat",
                          e.target.value
                        )
                      }
                    />
                  </GridItem>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Cerca"
                    placement="top"
                    style={{ marginTop: "0px" }}
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleSearchClick}
                    >
                      <Search />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    arrow
                    style={{ marginLeft: "4px", marginTop: "0px" }}
                    id="tooltip-top"
                    title="Reimposta"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleClearClick}
                    >
                      <Clear />
                    </Button>
                  </Tooltip>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.isVisible && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="borsesuccess">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    Elaborazioni Statistiche Borse di Studio
                  </h4>

                  <FormControlLabel
                    className="float-right"
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%",
                      marginRight: "6px",
                      color: "white"
                    }}
                    control={
                      <Switch
                        checked={this.state.filterGrid}
                        onChange={this.ChangeFilterGrid}
                        name="chkFilterGrid"
                        color="default"
                      />
                    }
                    label={"Attiva Filtri"}
                  />

                  <ButtonGroup
                    variant="contained"
                    color="default"
                    size="small"
                    aria-label="contained default button group"
                    className="float-right"
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%",
                      marginTop: "6px"
                    }}
                  >
                    <ButtonsMUI onClick={this.exportHandler}>Export</ButtonsMUI>
                  </ButtonGroup>
                </CardHeader>

                <CardBody>
                  <DataTable
                    emptyMessage=""
                    className="p-datatable-striped p-datatable-sm"
                    value={this.state.tableData}
                    dataKey="id"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                    rows={5} rowsPerPageOptions={rowsPerPageOptions}
                    loading={this.state.loadingGrid}
                    paginatorLeft={this.paginatorLeft}
                    paginatorRight={this.paginatorRight}
                    selection={this.state.selectedGrid}
                    scrollable
                    style={{ width: "100%" }}
                    selectionMode="single"
                  >
                    {this.state.columns?.map((column) => {
                      return (
                        <Column
                          key={column.field}
                          field={column.field}
                          header={column.header}
                          sortable={true}
                          filter={this.state.filterGrid}
                        />
                      );
                    })}
                  </DataTable>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </>
    );
  }
}
export default Statistiche;
