const date = new Date();
const year = date.getFullYear();
const month = date.getMonth();
const day = date.getDate();
const _month = month < 10 ? `0${month}` : month;
const _day = day < 10 ? `0${day}` : day;
const hours = date.getHours();
const minutes = date.getMinutes();
const seconds = date.getSeconds();
const milliseconds = date.getMilliseconds();

export const currentDate = `${_day}/${_month}/${year}`;
export const currentDateTime = `${year}-${_month}-${_day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
export const currentDateTime2 = `${_day}/${_month}/${year} ${hours}:${minutes}:${seconds}`;
