import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import LoaderSpinner from "components/Layout/LoaderSpinner.js";
import { AnomalieAziendaHeader } from "components/Views/Aziende/AnomalieAziendaHeader.js";
import { ModificaAnomalie } from "components/Views/Anomalie/ModificaAnomalie.js";
import AnomalieAPI from "API/AnomalieAPI";
import helpers from "../../helpers/Helpers";
import Helpers from "../../helpers/Helpers";
import AziendeAPI from "../../API/AziendeAPI";
import {Tooltip} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
class AnomalieRegolarizzateXAzienda extends Component {

  state = {
    rowsCustodi: [],
    rowsPulitori: [],
    selectedGridCustodi: null,
    selectedGridPulitori: null,
    isChecked: false,
    loadingGridCustodi: true,
    loadingGridPulitori: true,
    isVisible: false,
    viewDetails: false,
    visibleLoader: false,
    tipoAnomalie: null,
    rowsPerPageCust: 12,
    rowsPerPagePuli: 12,
    azienda: null,
    tipoOkAnomalia: AnomalieAPI.tipoOkAnomalie
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.handleTableClickPulitori = this.handleTableClickPulitori.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
  }

  backButtonClick() {
    let url = '/admin/AnomalieXAzienda/' + this.props.match.params.aziendaId;
    if(window.location.href.includes("fromVersamenti")){
      url += "?type=fromVersamenti";
    }
    this.props.history.push(url);
  }

  async componentDidMount() {
    this.setState({
      loadingGridCustodi: true,
      loadingGridPulitori: true
    });

    let aziendaId = this.props.match.params.aziendaId;
    this.setState({
      rowsCustodi: await AnomalieAPI.GetRegolarizzateByAziendaId(
        aziendaId,
        "CUST"
      ),
      rowsPulitori: await AnomalieAPI.GetRegolarizzateByAziendaId(
        aziendaId,
        "PULI"
      ),
      tipoAnomalie: await AnomalieAPI.GetAnomalieType(),
      azienda: (await AziendeAPI.GetAziendaById(aziendaId))[0]
    });

    this.setState({
      loadingGridCustodi: false,
      loadingGridPulitori: false
    });
  }

  onAnnullaClick() {
    this.setState({
      viewDetails: false,
      selectedGridCustodi: null,
      selectedGridPulitori: null,
    });
  }

  handleChange(e) {
    this.setState({ isChecked: e.target.checked });
  }

  async handleTableClick(e) {
    this.setState({
      visibleLoader: true
    });
    this.setState({
      selectedGridCustodi: e.value,
      viewDetails: true
    });
    this.setState({
      visibleLoader: false
    });
  }

  async handleTableClickPulitori(e) {
    this.setState({
      visibleLoader: true
    });
    this.setState({
      selectedGridPulitori: e.value,
      viewDetails: true
    });
    this.setState({
      visibleLoader: false
    });
  }

  render() {
    return (
      <>
        <AnomalieAziendaHeader
          style={{ zIndex: "2" }}
          isVisible={this.state.isVisible}
          aziendaId={this.props.match.params.aziendaId}
          {...this.props}
        />
        {this.state.visibleLoader && <LoaderSpinner fullHeight={true} />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="success"
                style={{ zIndex: "2", position: "relative", height: "51.09px" }}
                className={"float-right "}
              >
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Anomalie Regolarizzate Custodi
                </h4>
                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Alla pagina precedente"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.backButtonClick}
                  >
                    <ArrowBackIcon />
                  </Button>
                </Tooltip>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rowsCustodi}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPageCust} rowsPerPageOptions={[12, 24, 36, 48]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  loading={this.state.loadingGridCustodi}
                  selection={this.state.selectedGridCustodi}
                  scrollable scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="cod_anomalia"
                    header="Tipo"
                    headerStyle={{ width: "175px" }}
                    sortable={true}
                    body={row => {
                      return <>{row.cod_anomalia + ' - ' + this.state.tipoAnomalie.find(x => x.codice === row.cod_anomalia)?.notazione}</>;
                    }}
                  />
                  <Column
                    field="codQuota"
                    header="Cod. Quota"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                  />
                  <Column
                    field="comp"
                    header="Comp."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                  />
                  <Column
                    field="importoDov"
                    header="Dov."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    body={(row) => helpers.DecimalDTTemplate(row, "importoDov")}
                  />
                  <Column
                    field="importoDic"
                    header="Dich."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    body={(row) => helpers.DecimalDTTemplate(row, "importoDic")}
                  />
                  <Column
                    field="dataRilevaz"
                    header="Data Rilev."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={row => Helpers.dateToString(row, 'dataRilevaz')}
                  />
                  <Column
                    field="dataComunic"
                    header="Data Comun."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={row => Helpers.dateToString(row, 'dataComunic')}
                  />
                  <Column
                    field="numComun"
                    header="N. Com."
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                  />
                  <Column
                    field="dataOk"
                    header="Data Sistem."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={row => helpers.dateToString(row, 'dataOk')}
                  />
                  <Column
                    field="tipo_ok"
                    header="Stato"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    body={row => row.tipo_ok ? row.tipo_ok + ' - ' + this.state.tipoOkAnomalia[row.tipo_ok] : ""}
                  />
                  <Column
                    field="dataAnnul"
                    header="Data Annul."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={row => Helpers.dateToString(row, 'dataAnnul')}
                  />
                  <Column
                    field="utente"
                    header="Utente"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                  />
                  <Column
                    field="note"
                    header="Note"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="success"
                style={{ zIndex: "2", position: "relative", height: "51.09px" }}
                className={"float-right "}
              >
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Anomalie Regolarizzate Pulitori
                </h4>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rowsPulitori}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPagePuli} rowsPerPageOptions={[12, 24, 36, 48]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  loading={this.state.loadingGridPulitori}
                  selection={this.state.selectedGridPulitori}
                  scrollable scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClickPulitori}
                  selectionMode="single"
                >
                  <Column
                    field="cod_anomalia"
                    header="Tipo"
                    headerStyle={{ width: "175px" }}
                    sortable={true}
                    body={row => {
                      return <>{row.cod_anomalia + ' - ' + this.state.tipoAnomalie.find(x => x.codice === row.cod_anomalia)?.notazione}</>;
                    }}
                  />
                  <Column
                    field="codQuota"
                    header="Cod. Quota"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                  />
                  <Column
                    field="comp"
                    header="Comp."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                  />
                  <Column
                    field="importoDov"
                    header="Dov."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    body={(row) => helpers.DecimalDTTemplate(row, "importoDov")}
                  />
                  <Column
                    field="importoDic"
                    header="Dich."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    body={(row) => helpers.DecimalDTTemplate(row, "importoDic")}
                  />
                  <Column
                    field="dataRilevaz"
                    header="Data Rilev."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={row => Helpers.dateToString(row, 'dataRilevaz')}
                  />
                  <Column
                    field="dataComunic"
                    header="Data Comun."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={row => Helpers.dateToString(row, 'dataComunic')}
                  />
                  <Column
                    field="numComun"
                    header="N. Com."
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                  />
                  <Column
                    field="dataOk"
                    header="Data Sistem."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={row => helpers.dateToString(row, 'dataOk')}
                  />
                  <Column
                    field="tipo_ok"
                    header="Stato"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    body={row => row.tipo_ok ? row.tipo_ok + ' - ' + this.state.tipoOkAnomalia[row.tipo_ok] : ""}
                  />
                  <Column
                    field="dataAnnul"
                    header="Data Annul."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={row => Helpers.dateToString(row, 'dataAnnul')}
                  />
                  <Column
                    field="utente"
                    header="Utente"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                  />
                  <Column
                    field="note"
                    header="Note"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.viewDetails && (
          <ModificaAnomalie
            dataItem={this.state.selectedGridCustodi ? this.state.selectedGridCustodi : this.state.selectedGridPulitori ? this.state.selectedGridPulitori : null}
            onAnnullaClick={this.onAnnullaClick}
            azienda={this.state.azienda}
            tipoOkAnomalia={this.state.tipoOkAnomalia}
          />
        )}
      </>
    );
  }
}
export default AnomalieRegolarizzateXAzienda;
