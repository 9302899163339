import React, { Component } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// core components
import { AziendaHeader } from "components/Views/Aziende/AziendaHeader.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import YearPicker from "components/YearPicker/index";
import CustodiPulitoriAPI from "API/CustodiPulitoriAPI";
import AziendeAPI from "API/AziendeAPI";
import helpers from "../../helpers/Helpers";
import {rowsPerPageOptionsMensile} from "../../helpers/AppConstants";
import {Tooltip} from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {parse} from "query-string";

class CongruitaVersamenti extends Component {

  state = {
    rowsCustodi: [],
    rowsPulitori: [],
    selectedGriCustodi: null,
    selectedGridPulitori: null,
    loadingGridCustodi: false,
    loadingGridPulitori: false,
    filterCustodi: null,
    filterPulitori: null,
    isChecked: false,
    filterLabelTextCustodi: "Attiva Filtri",
    filterLabelTextPulitori: "Attiva Filtri",
    competenzaAnno: new Date().getFullYear(),
    rowsPerPage: 12
  };

  constructor(props) {
    super(props);
    this.ChangeFilterPulitori = this.ChangeFilterPulitori.bind(this);
    this.ChangeFilterCustodi = this.ChangeFilterCustodi.bind(this);
    this.handleTableClickCustodi = this.handleTableClickCustodi.bind(this);
    this.handleTableClickPulitori = this.handleTableClickPulitori.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.IncreaseYear = this.IncreaseYear.bind(this);
    this.DecreaseYear = this.DecreaseYear.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
  }

  async componentDidMount() {
    this.setState({
      competenzaAnno: localStorage.getItem("year") || new Date().getFullYear()
    });

    await this.refreshGrid();
  }

  async handleDateChange(dateParam) {
    this.setState(
      {
        competenzaAnno: dateParam
      },
      async () => await this.refreshGrid()
    );
  }

  async ChangeFilterPulitori(e) {
    this.setState({ filterPulitori: e.target.checked });
  }

  async IncreaseYear() {
    this.setState(
      {
        competenzaAnno: parseInt(this.state.competenzaAnno) + 1
      },
      async () => await this.refreshGrid()
    );
  }

  async DecreaseYear() {
    this.setState(
      {
        competenzaAnno: parseInt(this.state.competenzaAnno) - 1
      },
      async () => await this.refreshGrid()
    );
  }

  async ChangeFilterCustodi(e) {
    this.setState({ filterCustodi: e.target.checked });
  }

  handleChange(e) {
    this.setState({ isChecked: e.target.checked });
  }

  async refreshGrid() {
    let aziendaId = this.props.match.params.aziendaId;
    let azienda = await AziendeAPI.GetAziendaById(aziendaId);

    this.setState({
      loadingGridCustodi: true,
      loadingGridPulitori: true
    });

    let CUST =
      await CustodiPulitoriAPI.GetCustodiPulitoriCongruitaVersamentiForAzienda(
        "CUST",
        azienda[0].matricola,
        this.state.competenzaAnno
      );

    let PULI =
      await CustodiPulitoriAPI.GetCustodiPulitoriCongruitaVersamentiForAzienda(
        "PULI",
        azienda[0].matricola,
        this.state.competenzaAnno
      );

    helpers.formatCurrency(CUST.result, "importouni", "dovutocalc", "importof24", "aliquota", "imponibileuni");
    helpers.formatCurrency(PULI.result, "importouni", "dovutocalc", "importof24", "aliquota", "imponibileuni");

    this.setState({
      rowsCustodi: CUST.result,
      rowsPulitori: PULI.result,
      loadingGridCustodi: false,
      loadingGridPulitori: false
    });
  }

  async onSearchClick() {
    await this.refreshGrid();
  }

  async handleTableClickCustodi(e) {
    this.setState({
      selectedGridCustodi: e.value
    });
  }

  async handleTableClickPulitori(e) {
    this.setState({
      selectedGridPulitori: e.value
    });
  }

  backButtonClick() {
    let qS = parse(this.props.location.search);
    let maintainSearch = "?maintainSearch=1";
    if (!qS.type)
      this.props.history.push('/admin/aziende/' + this.props.match.params.aziendaId + maintainSearch);
    else if (qS.type === "fromVersamenti")
      this.props.history.push('/admin/versamenti/details/' + this.props.match.params.aziendaId + maintainSearch);
    else if (qS.type === "fromModificaRichieste")
      this.props.history.push('/admin/richiedenti/modificarichieste/' + this.props.match.params.aziendaId + '/' + qS.typeId + maintainSearch);
    else if (qS.type === "fromRichieste")
      this.props.history.push('/admin/richiedenti/modificarichieste/' + this.props.match.params.aziendaId + '/' + qS.typeId + maintainSearch + '&type=' + qS.type);
    else if (qS.type === "fromConsulenti")
      this.props.history.push('/admin/consulenti/' + this.props.match.params.aziendaId + maintainSearch);
    else if (qS.type === "fromAmministratori")
      this.props.history.push('/admin/amministratori/' + this.props.match.params.aziendaId + maintainSearch);
    else if (qS.type === "fromDipendenti")
      this.props.history.push('/admin/dipendenti/' + this.props.match.params.aziendaId + maintainSearch);
  }

  render() {
    return (
      <div>
        <AziendaHeader
          aziendaId={this.props.match.params.aziendaId}
          {...this.props}
        />

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                  className="float-left"
                >
                  Custodi
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterCustodi}
                      onChange={this.ChangeFilterCustodi}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={this.state.filterLabelTextCustodi}
                />

                <div
                  className="float-left date"
                  style={{
                    verticalAlign: "top",
                    lineHeight: "1",
                    marginRight: "5px",
                    display: "inline-flex",
                    marginTop: "10px"
                  }}
                >
                  <SkipPreviousIcon
                    onClick={this.DecreaseYear}
                    className="slide"
                  />
                  <YearPicker
                    onChange={this.handleDateChange}
                    value={this.state.competenzaAnno}
                    className="date"
                  />
                  <SkipNextIcon onClick={this.IncreaseYear} className="slide" />
                </div>

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Alla pagina precedente"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.backButtonClick}
                  >
                    <ArrowBackIcon />
                  </Button>
                </Tooltip>

              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  value={this.state.rowsCustodi}
                  dataKey="id"
                  className="p-datatable-striped p-datatable-sm"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage} rowsPerPageOptions={rowsPerPageOptionsMensile}
                  loading={this.state.loadingGridCustodi}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGridCustodi}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClickCustodi}
                  selectionMode="multiple"
                  metaKeySelection={false}
                >
                  <Column
                    field="comp"
                    header="Competenza"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="imponibileuni"
                    header="Imponibile"
                    body={(rowData) => helpers.DecimalDTTemplate(rowData, "imponibileuni")}
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="aliquota"
                    header="AliQuota"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importof24"
                    header="Versato"
                    body={(rowData) => helpers.DecimalDTTemplate(rowData, "importof24")}
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="dovutocalc"
                    body={(rowData) => helpers.DecimalDTTemplate(rowData, "dovutocalc")}
                    header="Calcolato"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importouni"
                    body={(rowData) => helpers.DecimalDTTemplate(rowData, "importouni")}
                    header="Importo Uni."
                    headerStyle={{ width: "145px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="f24_43"
                    header="F24_43"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="uni_42"
                    header="UNI_42"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="f24_UNI_41"
                    header="F24_UNI_41"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Pulitori
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterPulitori}
                      onChange={this.ChangeFilterPulitori}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={this.state.filterLabelTextPulitori}
                />
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  value={this.state.rowsPulitori}
                  className="p-datatable-striped p-datatable-sm"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={12} rowsPerPageOptions={rowsPerPageOptionsMensile}
                  loading={this.state.loadingGridPulitori}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGridPulitori}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClickPulitori}
                  selectionMode="multiple"
                  dataKey="id"
                  metaKeySelection={false}
                >
                  <Column
                    field="comp"
                    header="Competenza"
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="imponibileuni"
                    header="Imponibile"
                    body={(rowData) => helpers.DecimalDTTemplate(rowData, "imponibileuni")}
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="aliquota"
                    body={(rowData) => helpers.DecimalDTTemplate(rowData, "aliquota")}
                    header="AliQuota"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importof24"
                    body={(rowData) => helpers.DecimalDTTemplate(rowData, "importof24")}
                    header="Versato"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="dovutocalc"
                    body={(rowData) => helpers.DecimalDTTemplate(rowData, "dovutocalc")}
                    header="Calcolato"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importouni"
                    header="Importo Uni."
                    body={(rowData) => helpers.DecimalDTTemplate(rowData, "importouni")}
                    headerStyle={{ width: "145px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="f24_43"
                    header="F24_43"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="uni_42"
                    header="UNI_42"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="f24_UNI_41"
                    header="F24_UNI_41"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default CongruitaVersamenti;
