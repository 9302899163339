import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomButton from "components/CustomButtons/Button.js";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import queryString from "query-string";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import { AutoComplete } from "primereact/autocomplete";
import Close from "@material-ui/icons/Close";
import { confirmPopup } from "primereact/confirmpopup";
import helpers from "helpers/Helpers";
import AddIcon from "@material-ui/icons/Add";
import RichiedentiAPI from "API/RichiedentiAPI";
import moment from "moment";
import InserisciModificaRichiedenti from "views/Richiedenti/InserisciModificaRichiedenti.js";
import {dateFmt, rowsPerPageOptions} from "helpers/AppConstants";
import { dateTimeFmt } from "helpers/AppConstants";

class Richiedenti extends React.Component {
  state = {
    initialRows: [],
    filteredRows: [],
    selectedRichiedenti: null,
    selectedGridRichiedenti: null,
    loadingGrid: false,
    firstRowToDisplay: 0,
    pageSize: 10,

    //renamed
    CodfisFullList: [],
    CognomeFullList: [],
    filteredCodfisListByInput: null,
    filteredCognomeListByInput: null,

    filterByCodice: "",
    filterByNominativo: "",

    visibleNuovo: false
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.getFilteredRows = this.getFilteredRows.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.searchCognome = this.searchCognome.bind(this);
    this.searchCodFis = this.searchCodFis.bind(this);
    this.DocActionsGridTemplate = this.DocActionsGridTemplate.bind(this);
    this.onRedirectClick = this.onRedirectClick.bind(this);
    this.DeleteRichiedenti = this.DeleteRichiedenti.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.NuovoClickFunctions = this.NuovoClickFunctions.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.LoadElementsInGrid = this.LoadElementsInGrid.bind(this);
    this.btnSearchRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });

    this.setState({
      firstRowToDisplay: 0
    });

    const applicants = await RichiedentiAPI.getByFilter(0, 10);

    this.setState({
      initialRows: applicants?.result,
      filteredRows: applicants?.result,
      totalRecords: applicants?.tot
    });

    // get filtered values object
    let filterByCodice = this.state.filterByCodice;
    let filterByNominativo = this.state.filterByNominativo;

    let queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      let savedFilters = helpers.GetSearchFilters("RichiedentiList");
      if (savedFilters !== null) {
        filterByNominativo = savedFilters.filterByNominativo;
        filterByCodice = savedFilters.filterByCodice;
      }
    } else helpers.SaveSearchFilters(null, "RichiedentiList");

    let codfisFullList = [];

    this.setState(
      {
        filterByCodice: filterByCodice,
        filterByNominativo: filterByNominativo,
        CodfisFullList: codfisFullList
      },

      async () => {
        const filteredRows = this.getFilteredRows();
        let selectedRow = null;
        let firstRowToDisplay = 0;

        const richiedentiId = this.props.match.params.richiedentiId;
        if (richiedentiId > 0) {
          selectedRow = helpers.ReturnRowFromListById(
            filteredRows,
            richiedentiId
          );
          firstRowToDisplay = helpers.GetFirstElementOfPageIncludingId(
            richiedentiId,
            filteredRows,
            10
          );
        }

        this.setState({
          firstRowToDisplay: firstRowToDisplay,
          filteredRows: filteredRows
        });

        if (selectedRow) this.handleTableClick({ value: selectedRow });
      }
    );
    this.setState({
      loadingGrid: false
    });
  }

  searchCodFis(event) {
    setTimeout(() => {
      let filteredCodfisListByInput;
      if (!event.query.trim().length) {
        filteredCodfisListByInput = [...this.state.CodfisFullList];
      } else if (
        !this.state.CodfisFullList ||
        this.state.CodfisFullList.length
      ) {
      } else {
        filteredCodfisListByInput = this.state.CodfisFullList.filter((m) => {
          return m.toLowerCase().includes(event.query.toLowerCase());
        });
      }
      this.setState({ filteredCodfisListByInput: filteredCodfisListByInput });
    }, 250);
  }

  searchCognome(event) {
    setTimeout(() => {
      let filteredCognomeListByInput;
      if (!event.query.trim().length) {
        filteredCognomeListByInput = [...this.state.CognomeFullList];
      } else {
        filteredCognomeListByInput = this.state.CognomeFullList.filter((m) => {
          return m.toLowerCase().includes(event.query.toLowerCase());
        });
      }

      this.setState({ filteredCognomeListByInput: filteredCognomeListByInput });
    }, 250);
  }

  handleSearchClick = async () => {
    this.setState({
      loadingGrid: true
    });

    const res = await RichiedentiAPI.getByFilter(
      this.state.firstRowToDisplay,
      this.state.pageSize,
      null,
      null,
      `${
        this.state.filterByNominativo
          ? `cognome=${this.state.filterByNominativo}`
          : ""
      }${
        this.state.filterByCodice ? `&cfrich=${this.state.filterByCodice}` : ""
      }`
    );

    this.setState({
      selectedRichiedenti: null,
      selectedGridRichiedenti: null,
      filteredRows: res?.result,
      loadingGrid: false,
      totalRecords: res?.tot
    });
  };

  async handleClearClick() {
    helpers.SaveSearchFilters(null, "RichiedentiList");
    this.setState({
      loadingGrid: true
    });

    this.setState({
      selectedRichiedenti: null,
      filteredRows: this.state.initialRows,
      selectedGridRichiedenti: null,
      filteredCodfisListByInput: null,
      filteredCognomeListByInput: null,
      filterByCodice: "",
      filterByNominativo: ""
    }, async () => await this.LoadElementsInGrid());
  }

  async LoadElementsInGrid() {

    const res = await RichiedentiAPI.getByFilter(
      this.state.firstRowToDisplay,
      this.state.pageSize,
      this.state.sortField,
      this.state.sortOrder,
        `${
            this.state.filterByNominativo
                ? `cognome=${this.state.filterByNominativo}`
                : ""
        }${
            this.state.filterByCodice ? `&cfrich=${this.state.filterByCodice}` : ""
        }`
    );

    this.setState({
      filteredRows: res?.result,
      totalRecords: res?.tot,
      loadingGrid: false
    });
  }

  async onPageChange(e) {
    this.setState(
      {
        firstRowToDisplay: e.first,
        pageSize: e.rows,
        loadingGrid: true
      },
      async () => await this.LoadElementsInGrid()
    );
  }

  async onSortChange(e) {
    this.setState(
      {
        sortField: e.sortField,
        sortOrder: e.sortOrder,
        loadingGrid: true
      },
      async () => {
        await this.LoadElementsInGrid();
      }
    );
  }

  getFilteredRows() {
    let filteredRows = this.state.initialRows;

    if (this.state.filterByCodice)
      filteredRows = filteredRows.filter(
        (row) =>
          row.cfRich
            .toLowerCase()
            .indexOf(this.state.filterByCodice.toString().toLowerCase()) >= 0
      );

    if (this.state.filterByNominativo)
      filteredRows = filteredRows.filter(
        (row) =>
          row.cognome
            .toLowerCase()
            .indexOf(this.state.filterByNominativo.toLowerCase()) >= 0
      );
    return filteredRows;
  }

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  DeleteRichiedenti(rowData) {
    return (
      <Button
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData.idRichied}
        onClick={(e) => this.onDeleteClick(e, rowData.idRichied)}
      >
        <Close className="icon" />
      </Button>
    );
  }

  async handleDelete(id) {
    const response = await RichiedentiAPI.DeleteRichiedenti(id);
    if (response && response.result) {
      this.props.addAlert.current.addAlert("success", `richiedente eliminato`);
      this.componentDidMount();
    } else {
      if (response?.message)
        this.props.addAlert.current.addAlert("danger", response.message);
      else
        this.props.addAlert.current.addAlert(
          "danger",
          "Il richiedente non è stato eliminato"
        );
    }
  }

  onDeleteClick(event, id) {
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => this.handleDelete(id),
      reject: () => {}
    });
  }

  DocActionsGridTemplate(rowData) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center"
        }}
      >
        <CustomButton
          round
          color="info"
          className="actionButton actionButtonRound btnRound  "
          key={rowData.Id}
          onClick={(e) => this.onRedirectClick(e, rowData)}
        >
          <VisibilityIcon className="icon" />
        </CustomButton>
      </div>
    );
  }

  onRedirectClick(e, rowData) {
    e.stopPropagation();
    this.props.history.push(
      "/admin/richiedenti/modificainserisci/" +
        rowData.idRichied +
        "?maintainSearch=1"
    );
  }

  NuovoClickFunctions(param) {
    if (param === "open")
      this.setState({
        visibleNuovo: true
      });
    else if (param === "close") {
      this.setState({
        visibleNuovo: false
      });
      try {
        this.btnSearchRef.current.scrollIntoView();
      } catch(err){}
    }
  }

  handleTableClick = (e) => {
    this.setState({
      selectedGridRichiedenti: e.value
    });
  };

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <h4>Ricerca Richiedenti di Borse di Studio</h4>
              </CardHeader>
              <CardBody>
                <GridContainer style={{ display: "flex", alignItems: "center" }}>
                  <GridItem xs={3}>
                    <AutoComplete
                      value={this.state.filterByNominativo}
                      placeholder="Cerca per Cognome"
                      suggestions={this.state.filteredCognomeListByInput}
                      completeMethod={this.searchCognome}
                      onChange={(e) =>
                        this.HandleChange("filterByNominativo", e.value)
                      }
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <AutoComplete
                      value={this.state.filterByCodice}
                      placeholder="Cerca per Codfis"
                      suggestions={this.state.filteredCodfisListByInput}
                      completeMethod={this.searchCodFis}
                      onChange={(e) =>
                        this.HandleChange("filterByCodice", e.value)
                      }
                    />
                  </GridItem>

                  <GridItem xs={2}>
                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Cerca"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <Button
                        ref={this.btnSearchRef}
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%",
                          marginLeft: "0",
                          marginRight: "4%"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        onClick={this.handleSearchClick}
                      >
                        <Search />
                      </Button>
                    </Tooltip>

                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Reimposta"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <Button
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%",
                          marginRight: "0"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        onClick={this.handleClearClick}
                      >
                        <Clear />
                      </Button>
                    </Tooltip>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borsesuccess">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Richiedenti
                </h4>
                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={() => this.NuovoClickFunctions("open")}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.filteredRows}
                  first={this.state.firstRowToDisplay}
                  dataKey="idRichied"
                  paginator lazy
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.pageSize}
                  rowsPerPageOptions={rowsPerPageOptions}
                  onPage={this.onPageChange}
                  onSort={this.onSortChange}
                  sortField={this.state.sortField}
                  sortOrder={this.state.sortOrder}
                  totalRecords={this.state.totalRecords}
                  scrollable scrollDirection="horizontal"
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGridRichiedenti}
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    header=""
                    headerStyle={{
                      width: "80px",
                      textAlign: "center"
                    }}
                    body={this.DocActionsGridTemplate}
                    className="colDocActions"
                  />
                  <Column
                    field="cfRich"
                    header="Codice Fiscale"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                  />
                  <Column
                    field="nome"
                    header="Nominativo"
                    headerStyle={{ width: "140px" }}
                    body={(row) => `${row.nome} ${row.cognome}`}
                    sortable={true}
                  />
                  <Column
                    field="sesso"
                    header="Sesso"
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                  />
                  <Column
                    field="dataNasc"
                    header="Data Nascita"
                    body={(row) =>
                      moment(row.dataNasc, dateTimeFmt).format(dateFmt)
                    }
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                  />
                  <Column
                    field="comune"
                    header="Comune"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                  />
                  <Column
                    field="numeroRichieste"
                    header="Num. Richieste"
                    headerStyle={{ width: "160px" }}
                    sortable={true}
                  />
                  <Column
                    header=""
                    headerStyle={{ width: "40px" }}
                    body={this.DeleteRichiedenti}
                    icon="pi pi-times"
                    label="Delete"
                    className="p-button-danger p-button-outlined colDelete"
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.visibleNuovo && (
          <InserisciModificaRichiedenti
            history={this.props.history}
            item={this.state.selectedGridRichiedenti}
            onAnnullaClick={() => this.NuovoClickFunctions("close")}
            addAlert={this.props.addAlert}
            richiedentiIdForRedirectingFromNuovo={
              this.state.initialRows.length + 1
            }
          />
        )}
      </>
    );
  }
}
export default Richiedenti;
