import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import UndoIcon from "@material-ui/icons/Undo";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { DataTable } from "primereact/datatable";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import { Column } from "primereact/column";
import Tooltip from "@material-ui/core/Tooltip";
import CustomButtons from "components/CustomButtons/Button.js";
import AddIcon from "@material-ui/icons/Add";
import TipoBorsaAPI from "API/TipoBorsaAPI";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import { confirmPopup } from "primereact/confirmpopup";
import {rowsPerPageOptions} from "../../helpers/AppConstants";

class TipoBorse extends Component {
  state = {
    ModificaTitle: "Inserisci",
    selectedTipo: null,
    loadingGrid: false,
    filterGrid: null,
    rows: [],
    isVisible: false,
    tipoBorsa: null,
    Visibilita: [],
    rowsPerPage: 10,
    fieldValidations: {},
    validationRules: {
      borseId: "",
      visibId: ""
    }
  };
  constructor(props) {
    super(props);

    this.btnSearchRef = React.createRef();
    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.SetText = this.SetText.bind(this);
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.DeleteColumn = this.DeleteColumn.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.DeleteRecord = this.DeleteRecord.bind(this);
  }

  componentDidMount() {
    this.refreshGrid();
  }

  SetText(field, newValue) {
    let newRowItem = this.state.tipoBorsa;
    newRowItem[field] = newValue;

    this.setState({ tipoBorsa: newRowItem });
  }

  async onNuovoClick() {
    this.setState({
      tipoBorsa: { visib: null },
      ModificaTitle: "Inserisci",
      isVisible: true
    });
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true
    });
    const allTipo = await TipoBorsaAPI.GetAllTipoBorse();
    try {
      this.setState(
        {
          rows: allTipo.result
        },
        function () {
          this.setState({
            loadingGrid: false
          });
        }
      );
    } catch (err) {
      this.setState(
        {
          loadingGrid: false
        },
        () =>
          this.props.addAlert.current.addAlert(
            "danger",
            "Si è verificato un errore nel caricamento dei dati"
          )
      );
      console.error("TipoBorse err", err);
    }
  }

  async isValidForm() {
    return (
      this.state.tipoBorsa &&
      this.state.tipoBorsa.descr &&
      this.state.tipoBorsa.descrBr
    );
  }

  HandleSaveClick = async () => {
    if (this.isValidForm()) {
      if (this.state.tipoBorsa.idTipoBS === null) {
        const response = await TipoBorsaAPI.AddTipoBorse(this.state.tipoBorsa);
        try {
          this.props.addAlert.current.addAlert("success", `Dati salvati`);
        } catch (err) {
          this.props.addAlert.current.addAlert(
            "danger",
            "Si è verificato un errore durante il salvataggio dei dati"
          );
        }
      } else {
        const response = await TipoBorsaAPI.UpdateTipoBorse(
          this.state.tipoBorsa
        );
        try {
          this.props.addAlert.current.addAlert("success", `Dati aggiornati`);
        } catch (err) {
          this.props.addAlert.current.addAlert(
            "danger",
            "Si è verificato un errore durante l'aggiornamento dei dati"
          );
        }
      }
      this.onAnnullaClick();
      this.refreshGrid();
      return true;
    }
  };

  ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  onAnnullaClick() {
    this.setState({
      tipoBorsa: null,
      isVisible: false
    });

    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  HandleDropDownChange(e, val) {
    let tipoBorsa = this.state.tipoBorsa;
    tipoBorsa[e] = val;
    this.setState({
      tipoBorsa: tipoBorsa
    });
  }

  async handleTableClick(e) {
    this.setState({
      tipoBorsa: e.value,
      ModificaTitle: "Modifica",
      isVisible: true
    });
  }

  DeleteColumn(rowData) {
    return (
      <Button
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData.Id}
        onClick={(e) => this.onDeleteClick(e, rowData)}
      >
        <Close className="icon" />
      </Button>
    );
  }

  onDeleteClick(event, rowData) {
    const id = rowData?.idTipoBS;

    event.stopPropagation();

    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => this.DeleteRecord(id),
      reject: () => {}
    });
  }

  async DeleteRecord(id) {
    const res = await TipoBorsaAPI.deleteScholarshipType(id);
    if (res) {
      this.props.addAlert.current.addAlert(
        "success",
        "Eliminazione riuscita correttamente"
      );
      this.refreshGrid();
    } else
      this.props.addAlert.current.addAlert(
        "danger",
        "Eliminazione non riuscita"
      );
  }

  render() {
    return (
      <>
        <div ref={this.btnSearchRef} />

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Tipologie di Borse di Studio
                </h4>
              </CardHeader>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borsesuccess">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Tipi di Borse di Studio Presenti
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    marginRight: "6px",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <CustomButtons
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </CustomButtons>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="idTipoBS"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage} rowsPerPageOptions={rowsPerPageOptions}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.tipoBorsa}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="descr"
                    header="Categoria"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="descrBr"
                    header="Sigla Borsa"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    header=""
                    body={this.DeleteColumn}
                    icon="pi pi-times"
                    label="Delete"
                    className="p-button-danger p-button-outlined colDelete"
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.isVisible && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="borseinfo">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.state.ModificaTitle} Tipologia Di Borsa
                  </h4>

                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Annulla"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <CustomButtons
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%",
                        marginLeft: "0",
                        marginRight: "0"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.onAnnullaClick}
                    >
                      <UndoIcon />
                    </CustomButtons>
                  </Tooltip>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      style={{ marginTop: "8px" }}
                    >
                      <CustomInput
                        labelText="Id"
                        id="id"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          onChange: (e) =>
                            this.SetText("idTipoBS", e.target.value),
                          value: this.state.tipoBorsa.idTipoBS || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={this.state.tipoBorsa.descr}
                        error={!this.state.tipoBorsa.descr}
                        labelText="Descrizione *"
                        id="descr"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (e) =>
                            this.SetText("descr", e.target.value),
                          value: this.state.tipoBorsa.descr || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={3}>
                      <CustomInput
                        success={this.state.tipoBorsa.descrBr}
                        error={!this.state.tipoBorsa.descrBr}
                        labelText="Sigla Borsa *"
                        id="visib"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (e) =>
                            this.SetText("descrBr", e.target.value),
                          value: this.state.tipoBorsa.descrBr || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </>
    );
  }
}
export default TipoBorse;
