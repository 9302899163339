import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ButtonsMUI from "@material-ui/core/Button";
import StoricoAPI from "API/StoricoAPI";
import moment from "moment";
import {dateFmt, rowsPerPageOptions} from "../../helpers/AppConstants";
import Utils from "../../API/Utils";
import ButtonGroup from "@material-ui/core/ButtonGroup";

class StoricoBatchXAnomalie extends React.Component {

  state = {
    rows: [],
    rowsReg: [],
    rowsAnnu: [],
    loadingGrid: false,
    loadingRegGrid: false,
    loadingAnnuGrid: false,
    selectedGrid: null,
    selectedRegGrid: null,
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.handleRegTableClick = this.handleRegTableClick.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.exportExcel = this.exportExcel.bind(this);
    this.btnSearchRef = React.createRef();
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  async refreshGrid() {

    this.setState({
      loadingRegGrid: true,
      loadingAnnuGrid: true,
      loadingGrid: true
    });

    let rw = await StoricoAPI.GetStoricoCaricamenti();
    let rwr = await StoricoAPI.GetStoricoRegolarizzate();
    let rwrr = await StoricoAPI.GetStoricoAnnullate();

    rw.forEach(x => x.dtcar = moment(x.dtcar, dateFmt));
    rwr.forEach(x => x.dtreg = moment(x.dtreg, dateFmt));
    rwrr.forEach(x => x.dtannullo = moment(x.dtannullo, dateFmt));

    this.setState({
      rows: rw,
      rowsReg: rwr,
      rowsAnnu: rwrr,
      loadingGrid: false,
      loadingRegGrid: false,
      loadingAnnuGrid: false,
    });
  }

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: e.value
    });
  };

  handleRegTableClick = async (e) => {
    this.setState({
      selectedRegGrid: e.value
    });
  };

  exportExcel(tipo) {
    let rows;
    if (tipo === 'CARICAMENTI') {
      rows = this.state.rows.slice();
      rows.forEach(x => x.dtcar = x.dtcar.format(dateFmt));
    } else if (tipo === 'REGOLARIZZATE') {
      rows = this.state.rowsReg.slice();
      rows.forEach(x => x.dtreg = x.dtreg.format(dateFmt));
    } else if (tipo === "ANNULLATE") {
      rows = this.state.rowsAnnu.slice();
      rows.forEach(x => x.dtannullo = x.dtannullo.format(dateFmt));
    }
    Utils.ExportExcel(rows);
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className="d-inline">Storico Caricamenti</h4>
                <ButtonGroup
                    variant="contained"
                    color="primary"
                    size="small"
                    aria-label="contained primary button group"
                    className="float-right m-2"
                >
                  <ButtonsMUI onClick={() => this.exportExcel('CARICAMENTI')}>
                    Esporta
                  </ButtonsMUI>
                </ButtonGroup>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={5}
                  loading={this.state.loadingGrid}
                  rowsPerPageOptions={rowsPerPageOptions}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="dtcar"
                    header="Data Caric."
                    style={{ width: "140px" }}
                    sortable={true}
                    body={e => e.dtcar.format(dateFmt)}
                  />
                  <Column
                    field="n10"
                    header="n10"
                    sortable={true}
                    style={{width: "70px"}}
                  />
                  <Column
                    field="n20"
                    header="n20"
                    sortable={true}
                    style={{width: "80px"}}
                  />
                  <Column
                    field="n30"
                    header="n30"
                    sortable={true}
                    style={{width: "80px"}}
                  />
                  <Column
                    field="n41"
                    header="n41"
                    sortable={true}
                    style={{width: "70px"}}
                  />
                  <Column
                    field="n42"
                    header="n42"
                    sortable={true}
                    style={{width: "80px"}}
                  />
                  <Column
                    field="n43"
                    header="n43"
                    sortable={true}
                    style={{width: "80px"}}
                  />
                  <Column
                    field="n50"
                    header="n50"
                    sortable={true}
                    style={{width: "80px"}}
                  />
                  <Column
                    field="utente"
                    header="Utente"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className="d-inline">Storico Regolarizzazioni</h4>
                <ButtonGroup
                    variant="contained"
                    color="primary"
                    size="small"
                    aria-label="contained primary button group"
                    className="float-right m-2"
                >
                  <ButtonsMUI onClick={() => this.exportExcel('REGOLARIZZATE')}>
                    Esporta
                  </ButtonsMUI>
                </ButtonGroup>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rowsReg}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={5}
                  loading={this.state.loadingRegGrid}
                  rowsPerPageOptions={rowsPerPageOptions}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedRegGrid}
                  scrollable scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleRegTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="dtreg"
                    header="Data Reg."
                    style={{ width: "120px" }}
                    sortable={true}
                    body={e => e.dtreg.format(dateFmt)}
                  />
                  <Column
                    field="n10"
                    header="n10"
                    sortable={true}
                    style={{width: "70px"}}
                  />
                  <Column
                    field="n20"
                    header="n20"
                    sortable={true}
                    style={{width: "80px"}}
                  />
                  <Column
                    field="n30"
                    header="n30"
                    sortable={true}
                    style={{width: "80px"}}
                  />
                  <Column
                    field="n41"
                    header="n41"
                    sortable={true}
                    style={{width: "70px"}}
                  />
                  <Column
                    field="n42"
                    header="n42"
                    sortable={true}
                    style={{width: "80px"}}
                  />
                  <Column
                    field="n43"
                    header="n43"
                    sortable={true}
                    style={{width: "80px"}}
                  />
                  <Column
                    field="n50"
                    header="n50"
                    sortable={true}
                    style={{width: "80px"}}
                  />
                  <Column
                    field="utente"
                    header="Utente"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className="d-inline">Storico Annullamenti</h4>
                <ButtonGroup
                    variant="contained"
                    color="primary"
                    size="small"
                    aria-label="contained primary button group"
                    className="float-right m-2"
                >
                  <ButtonsMUI onClick={() => this.exportExcel('ANNULLATE')}>
                    Esporta
                  </ButtonsMUI>
                </ButtonGroup>
              </CardHeader>
              <CardBody>
                <DataTable
                    emptyMessage=""
                    className="p-datatable-striped p-datatable-sm"
                    value={this.state.rowsAnnu}
                    dataKey="id"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                    rows={5}
                    loading={this.state.loadingAnnuGrid}
                    rowsPerPageOptions={rowsPerPageOptions}
                    paginatorLeft={this.paginatorLeft}
                    paginatorRight={this.paginatorRight}
                    selection={this.state.selectedGrid}
                    scrollable scrollDirection="horizontal"
                    style={{ width: "100%" }}
                    onSelectionChange={this.handleTableClick}
                    selectionMode="single"
                >
                  <Column
                      field="dtannullo"
                      header="Data Annul."
                      style={{ width: "140px" }}
                      sortable={true}
                      body={e => e.dtannullo.format(dateFmt)}
                  />
                  <Column
                      field="n10"
                      header="n10"
                      sortable={true}
                      style={{width: "70px"}}
                  />
                  <Column
                      field="n20"
                      header="n20"
                      sortable={true}
                      style={{width: "80px"}}
                  />
                  <Column
                      field="n30"
                      header="n30"
                      sortable={true}
                      style={{width: "80px"}}
                  />
                  <Column
                      field="n41"
                      header="n41"
                      sortable={true}
                      style={{width: "70px"}}
                  />
                  <Column
                      field="n42"
                      header="n42"
                      sortable={true}
                      style={{width: "80px"}}
                  />
                  <Column
                      field="n43"
                      header="n43"
                      sortable={true}
                      style={{width: "80px"}}
                  />
                  <Column
                      field="n50"
                      header="n50"
                      sortable={true}
                      style={{width: "80px"}}
                  />
                  <Column
                      field="utente"
                      header="Utente"
                      headerStyle={{ width: "110px" }}
                      sortable={true}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>
      </>
    );
  }
}
export default StoricoBatchXAnomalie;
