/* Unicode mappings for FontAwesome Pro v5.6.3 */
var fa = function (i) {
  return fa[
    i.replace(/-./g, function (x) {
      return x.substr(1).toUpperCase();
    })
  ];
};
fa["500px"] = "\uf26e";
fa.abacus = "\uf640";
fa.accessibleIcon = "\uf368";
fa.accusoft = "\uf369";
fa.acorn = "\uf6ae";
fa.acquisitionsIncorporated = "\uf6af";
fa.ad = "\uf641";
fa.addressBook = "\uf2b9";
fa.addressCard = "\uf2bb";
fa.adjust = "\uf042";
fa.adn = "\uf170";
fa.adobe = "\uf778";
fa.adversal = "\uf36a";
fa.affiliatetheme = "\uf36b";
fa.airFreshener = "\uf5d0";
fa.alarmClock = "\uf34e";
fa.algolia = "\uf36c";
fa.alicorn = "\uf6b0";
fa.alignCenter = "\uf037";
fa.alignJustify = "\uf039";
fa.alignLeft = "\uf036";
fa.alignRight = "\uf038";
fa.alipay = "\uf642";
fa.allergies = "\uf461";
fa.amazon = "\uf270";
fa.amazonPay = "\uf42c";
fa.ambulance = "\uf0f9";
fa.americanSignLanguageInterpreting = "\uf2a3";
fa.amilia = "\uf36d";
fa.analytics = "\uf643";
fa.anchor = "\uf13d";
fa.android = "\uf17b";
fa.angel = "\uf779";
fa.angellist = "\uf209";
fa.angleDoubleDown = "\uf103";
fa.angleDoubleLeft = "\uf100";
fa.angleDoubleRight = "\uf101";
fa.angleDoubleUp = "\uf102";
fa.angleDown = "\uf107";
fa.angleLeft = "\uf104";
fa.angleRight = "\uf105";
fa.angleUp = "\uf106";
fa.angry = "\uf556";
fa.angrycreative = "\uf36e";
fa.angular = "\uf420";
fa.ankh = "\uf644";
fa.appStore = "\uf36f";
fa.appStoreIos = "\uf370";
fa.apper = "\uf371";
fa.apple = "\uf179";
fa.appleAlt = "\uf5d1";
fa.appleCrate = "\uf6b1";
fa.applePay = "\uf415";
fa.archive = "\uf187";
fa.archway = "\uf557";
fa.arrowAltCircleDown = "\uf358";
fa.arrowAltCircleLeft = "\uf359";
fa.arrowAltCircleRight = "\uf35a";
fa.arrowAltCircleUp = "\uf35b";
fa.arrowAltDown = "\uf354";
fa.arrowAltFromBottom = "\uf346";
fa.arrowAltFromLeft = "\uf347";
fa.arrowAltFromRight = "\uf348";
fa.arrowAltFromTop = "\uf349";
fa.arrowAltLeft = "\uf355";
fa.arrowAltRight = "\uf356";
fa.arrowAltSquareDown = "\uf350";
fa.arrowAltSquareLeft = "\uf351";
fa.arrowAltSquareRight = "\uf352";
fa.arrowAltSquareUp = "\uf353";
fa.arrowAltToBottom = "\uf34a";
fa.arrowAltToLeft = "\uf34b";
fa.arrowAltToRight = "\uf34c";
fa.arrowAltToTop = "\uf34d";
fa.arrowAltUp = "\uf357";
fa.arrowCircleDown = "\uf0ab";
fa.arrowCircleLeft = "\uf0a8";
fa.arrowCircleRight = "\uf0a9";
fa.arrowCircleUp = "\uf0aa";
fa.arrowDown = "\uf063";
fa.arrowFromBottom = "\uf342";
fa.arrowFromLeft = "\uf343";
fa.arrowFromRight = "\uf344";
fa.arrowFromTop = "\uf345";
fa.arrowLeft = "\uf060";
fa.arrowRight = "\uf061";
fa.arrowSquareDown = "\uf339";
fa.arrowSquareLeft = "\uf33a";
fa.arrowSquareRight = "\uf33b";
fa.arrowSquareUp = "\uf33c";
fa.arrowToBottom = "\uf33d";
fa.arrowToLeft = "\uf33e";
fa.arrowToRight = "\uf340";
fa.arrowToTop = "\uf341";
fa.arrowUp = "\uf062";
fa.arrows = "\uf047";
fa.arrowsAlt = "\uf0b2";
fa.arrowsAltH = "\uf337";
fa.arrowsAltV = "\uf338";
fa.arrowsH = "\uf07e";
fa.arrowsV = "\uf07d";
fa.artstation = "\uf77a";
fa.assistiveListeningSystems = "\uf2a2";
fa.asterisk = "\uf069";
fa.asymmetrik = "\uf372";
fa.at = "\uf1fa";
fa.atlas = "\uf558";
fa.atlassian = "\uf77b";
fa.atom = "\uf5d2";
fa.atomAlt = "\uf5d3";
fa.audible = "\uf373";
fa.audioDescription = "\uf29e";
fa.autoprefixer = "\uf41c";
fa.avianex = "\uf374";
fa.aviato = "\uf421";
fa.award = "\uf559";
fa.aws = "\uf375";
fa.axe = "\uf6b2";
fa.axeBattle = "\uf6b3";
fa.baby = "\uf77c";
fa.babyCarriage = "\uf77d";
fa.backpack = "\uf5d4";
fa.backspace = "\uf55a";
fa.backward = "\uf04a";
fa.badge = "\uf335";
fa.badgeCheck = "\uf336";
fa.badgeDollar = "\uf645";
fa.badgePercent = "\uf646";
fa.badgerHoney = "\uf6b4";
fa.balanceScale = "\uf24e";
fa.balanceScaleLeft = "\uf515";
fa.balanceScaleRight = "\uf516";
fa.ballPile = "\uf77e";
fa.ballot = "\uf732";
fa.ballotCheck = "\uf733";
fa.ban = "\uf05e";
fa.bandAid = "\uf462";
fa.bandcamp = "\uf2d5";
fa.barcode = "\uf02a";
fa.barcodeAlt = "\uf463";
fa.barcodeRead = "\uf464";
fa.barcodeScan = "\uf465";
fa.bars = "\uf0c9";
fa.baseball = "\uf432";
fa.baseballBall = "\uf433";
fa.basketballBall = "\uf434";
fa.basketballHoop = "\uf435";
fa.bat = "\uf6b5";
fa.bath = "\uf2cd";
fa.batteryBolt = "\uf376";
fa.batteryEmpty = "\uf244";
fa.batteryFull = "\uf240";
fa.batteryHalf = "\uf242";
fa.batteryQuarter = "\uf243";
fa.batterySlash = "\uf377";
fa.batteryThreeQuarters = "\uf241";
fa.bed = "\uf236";
fa.beer = "\uf0fc";
fa.behance = "\uf1b4";
fa.behanceSquare = "\uf1b5";
fa.bell = "\uf0f3";
fa.bellSchool = "\uf5d5";
fa.bellSchoolSlash = "\uf5d6";
fa.bellSlash = "\uf1f6";
fa.bells = "\uf77f";
fa.bezierCurve = "\uf55b";
fa.bible = "\uf647";
fa.bicycle = "\uf206";
fa.bimobject = "\uf378";
fa.binoculars = "\uf1e5";
fa.biohazard = "\uf780";
fa.birthdayCake = "\uf1fd";
fa.bitbucket = "\uf171";
fa.bitcoin = "\uf379";
fa.bity = "\uf37a";
fa.blackTie = "\uf27e";
fa.blackberry = "\uf37b";
fa.blanket = "\uf498";
fa.blender = "\uf517";
fa.blenderPhone = "\uf6b6";
fa.blind = "\uf29d";
fa.blog = "\uf781";
fa.blogger = "\uf37c";
fa.bloggerB = "\uf37d";
fa.bluetooth = "\uf293";
fa.bluetoothB = "\uf294";
fa.bold = "\uf032";
fa.bolt = "\uf0e7";
fa.bomb = "\uf1e2";
fa.bone = "\uf5d7";
fa.boneBreak = "\uf5d8";
fa.bong = "\uf55c";
fa.book = "\uf02d";
fa.bookAlt = "\uf5d9";
fa.bookDead = "\uf6b7";
fa.bookHeart = "\uf499";
fa.bookOpen = "\uf518";
fa.bookReader = "\uf5da";
fa.bookSpells = "\uf6b8";
fa.bookmark = "\uf02e";
fa.books = "\uf5db";
fa.boot = "\uf782";
fa.boothCurtain = "\uf734";
fa.bowArrow = "\uf6b9";
fa.bowlingBall = "\uf436";
fa.bowlingPins = "\uf437";
fa.box = "\uf466";
fa.boxAlt = "\uf49a";
fa.boxBallot = "\uf735";
fa.boxCheck = "\uf467";
fa.boxFragile = "\uf49b";
fa.boxFull = "\uf49c";
fa.boxHeart = "\uf49d";
fa.boxOpen = "\uf49e";
fa.boxUp = "\uf49f";
fa.boxUsd = "\uf4a0";
fa.boxes = "\uf468";
fa.boxesAlt = "\uf4a1";
fa.boxingGlove = "\uf438";
fa.braille = "\uf2a1";
fa.brain = "\uf5dc";
fa.briefcase = "\uf0b1";
fa.briefcaseMedical = "\uf469";
fa.broadcastTower = "\uf519";
fa.broom = "\uf51a";
fa.browser = "\uf37e";
fa.brush = "\uf55d";
fa.btc = "\uf15a";
fa.bug = "\uf188";
fa.building = "\uf1ad";
fa.bullhorn = "\uf0a1";
fa.bullseye = "\uf140";
fa.bullseyeArrow = "\uf648";
fa.bullseyePointer = "\uf649";
fa.burn = "\uf46a";
fa.buromobelexperte = "\uf37f";
fa.bus = "\uf207";
fa.busAlt = "\uf55e";
fa.busSchool = "\uf5dd";
fa.businessTime = "\uf64a";
fa.buysellads = "\uf20d";
fa.cabinetFiling = "\uf64b";
fa.calculator = "\uf1ec";
fa.calculatorAlt = "\uf64c";
fa.calendar = "\uf133";
fa.calendarAlt = "\uf073";
fa.calendarCheck = "\uf274";
fa.calendarDay = "\uf783";
fa.calendarEdit = "\uf333";
fa.calendarExclamation = "\uf334";
fa.calendarMinus = "\uf272";
fa.calendarPlus = "\uf271";
fa.calendarStar = "\uf736";
fa.calendarTimes = "\uf273";
fa.calendarWeek = "\uf784";
fa.camera = "\uf030";
fa.cameraAlt = "\uf332";
fa.cameraRetro = "\uf083";
fa.campfire = "\uf6ba";
fa.campground = "\uf6bb";
fa.canadianMapleLeaf = "\uf785";
fa.candleHolder = "\uf6bc";
fa.candyCane = "\uf786";
fa.candyCorn = "\uf6bd";
fa.cannabis = "\uf55f";
fa.capsules = "\uf46b";
fa.car = "\uf1b9";
fa.carAlt = "\uf5de";
fa.carBattery = "\uf5df";
fa.carBump = "\uf5e0";
fa.carCrash = "\uf5e1";
fa.carGarage = "\uf5e2";
fa.carMechanic = "\uf5e3";
fa.carSide = "\uf5e4";
fa.carTilt = "\uf5e5";
fa.carWash = "\uf5e6";
fa.caretCircleDown = "\uf32d";
fa.caretCircleLeft = "\uf32e";
fa.caretCircleRight = "\uf330";
fa.caretCircleUp = "\uf331";
fa.caretDown = "\uf0d7";
fa.caretLeft = "\uf0d9";
fa.caretRight = "\uf0da";
fa.caretSquareDown = "\uf150";
fa.caretSquareLeft = "\uf191";
fa.caretSquareRight = "\uf152";
fa.caretSquareUp = "\uf151";
fa.caretUp = "\uf0d8";
fa.carrot = "\uf787";
fa.cartArrowDown = "\uf218";
fa.cartPlus = "\uf217";
fa.cashRegister = "\uf788";
fa.cat = "\uf6be";
fa.cauldron = "\uf6bf";
fa.ccAmazonPay = "\uf42d";
fa.ccAmex = "\uf1f3";
fa.ccApplePay = "\uf416";
fa.ccDinersClub = "\uf24c";
fa.ccDiscover = "\uf1f2";
fa.ccJcb = "\uf24b";
fa.ccMastercard = "\uf1f1";
fa.ccPaypal = "\uf1f4";
fa.ccStripe = "\uf1f5";
fa.ccVisa = "\uf1f0";
fa.centercode = "\uf380";
fa.centos = "\uf789";
fa.certificate = "\uf0a3";
fa.chair = "\uf6c0";
fa.chairOffice = "\uf6c1";
fa.chalkboard = "\uf51b";
fa.chalkboardTeacher = "\uf51c";
fa.chargingStation = "\uf5e7";
fa.chartArea = "\uf1fe";
fa.chartBar = "\uf080";
fa.chartLine = "\uf201";
fa.chartLineDown = "\uf64d";
fa.chartNetwork = "\uf78a";
fa.chartPie = "\uf200";
fa.chartPieAlt = "\uf64e";
fa.check = "\uf00c";
fa.checkCircle = "\uf058";
fa.checkDouble = "\uf560";
fa.checkSquare = "\uf14a";
fa.chess = "\uf439";
fa.chessBishop = "\uf43a";
fa.chessBishopAlt = "\uf43b";
fa.chessBoard = "\uf43c";
fa.chessClock = "\uf43d";
fa.chessClockAlt = "\uf43e";
fa.chessKing = "\uf43f";
fa.chessKingAlt = "\uf440";
fa.chessKnight = "\uf441";
fa.chessKnightAlt = "\uf442";
fa.chessPawn = "\uf443";
fa.chessPawnAlt = "\uf444";
fa.chessQueen = "\uf445";
fa.chessQueenAlt = "\uf446";
fa.chessRook = "\uf447";
fa.chessRookAlt = "\uf448";
fa.chevronCircleDown = "\uf13a";
fa.chevronCircleLeft = "\uf137";
fa.chevronCircleRight = "\uf138";
fa.chevronCircleUp = "\uf139";
fa.chevronDoubleDown = "\uf322";
fa.chevronDoubleLeft = "\uf323";
fa.chevronDoubleRight = "\uf324";
fa.chevronDoubleUp = "\uf325";
fa.chevronDown = "\uf078";
fa.chevronLeft = "\uf053";
fa.chevronRight = "\uf054";
fa.chevronSquareDown = "\uf329";
fa.chevronSquareLeft = "\uf32a";
fa.chevronSquareRight = "\uf32b";
fa.chevronSquareUp = "\uf32c";
fa.chevronUp = "\uf077";
fa.child = "\uf1ae";
fa.chimney = "\uf78b";
fa.chrome = "\uf268";
fa.church = "\uf51d";
fa.circle = "\uf111";
fa.circleNotch = "\uf1ce";
fa.city = "\uf64f";
fa.clawMarks = "\uf6c2";
fa.clipboard = "\uf328";
fa.clipboardCheck = "\uf46c";
fa.clipboardList = "\uf46d";
fa.clipboardListCheck = "\uf737";
fa.clipboardPrescription = "\uf5e8";
fa.clock = "\uf017";
fa.clone = "\uf24d";
fa.closedCaptioning = "\uf20a";
fa.cloud = "\uf0c2";
fa.cloudDownload = "\uf0ed";
fa.cloudDownloadAlt = "\uf381";
fa.cloudDrizzle = "\uf738";
fa.cloudHail = "\uf739";
fa.cloudHailMixed = "\uf73a";
fa.cloudMeatball = "\uf73b";
fa.cloudMoon = "\uf6c3";
fa.cloudMoonRain = "\uf73c";
fa.cloudRain = "\uf73d";
fa.cloudRainbow = "\uf73e";
fa.cloudShowers = "\uf73f";
fa.cloudShowersHeavy = "\uf740";
fa.cloudSleet = "\uf741";
fa.cloudSnow = "\uf742";
fa.cloudSun = "\uf6c4";
fa.cloudSunRain = "\uf743";
fa.cloudUpload = "\uf0ee";
fa.cloudUploadAlt = "\uf382";
fa.clouds = "\uf744";
fa.cloudsMoon = "\uf745";
fa.cloudsSun = "\uf746";
fa.cloudscale = "\uf383";
fa.cloudsmith = "\uf384";
fa.cloudversify = "\uf385";
fa.club = "\uf327";
fa.cocktail = "\uf561";
fa.code = "\uf121";
fa.codeBranch = "\uf126";
fa.codeCommit = "\uf386";
fa.codeMerge = "\uf387";
fa.codepen = "\uf1cb";
fa.codiepie = "\uf284";
fa.coffee = "\uf0f4";
fa.coffeeTogo = "\uf6c5";
fa.coffin = "\uf6c6";
fa.cog = "\uf013";
fa.cogs = "\uf085";
fa.coins = "\uf51e";
fa.columns = "\uf0db";
fa.comment = "\uf075";
fa.commentAlt = "\uf27a";
fa.commentAltCheck = "\uf4a2";
fa.commentAltDollar = "\uf650";
fa.commentAltDots = "\uf4a3";
fa.commentAltEdit = "\uf4a4";
fa.commentAltExclamation = "\uf4a5";
fa.commentAltLines = "\uf4a6";
fa.commentAltMinus = "\uf4a7";
fa.commentAltPlus = "\uf4a8";
fa.commentAltSlash = "\uf4a9";
fa.commentAltSmile = "\uf4aa";
fa.commentAltTimes = "\uf4ab";
fa.commentCheck = "\uf4ac";
fa.commentDollar = "\uf651";
fa.commentDots = "\uf4ad";
fa.commentEdit = "\uf4ae";
fa.commentExclamation = "\uf4af";
fa.commentLines = "\uf4b0";
fa.commentMinus = "\uf4b1";
fa.commentPlus = "\uf4b2";
fa.commentSlash = "\uf4b3";
fa.commentSmile = "\uf4b4";
fa.commentTimes = "\uf4b5";
fa.comments = "\uf086";
fa.commentsAlt = "\uf4b6";
fa.commentsAltDollar = "\uf652";
fa.commentsDollar = "\uf653";
fa.compactDisc = "\uf51f";
fa.compass = "\uf14e";
fa.compassSlash = "\uf5e9";
fa.compress = "\uf066";
fa.compressAlt = "\uf422";
fa.compressArrowsAlt = "\uf78c";
fa.compressWide = "\uf326";
fa.conciergeBell = "\uf562";
fa.confluence = "\uf78d";
fa.connectdevelop = "\uf20e";
fa.containerStorage = "\uf4b7";
fa.contao = "\uf26d";
fa.conveyorBelt = "\uf46e";
fa.conveyorBeltAlt = "\uf46f";
fa.cookie = "\uf563";
fa.cookieBite = "\uf564";
fa.copy = "\uf0c5";
fa.copyright = "\uf1f9";
fa.corn = "\uf6c7";
fa.couch = "\uf4b8";
fa.cow = "\uf6c8";
fa.cpanel = "\uf388";
fa.creativeCommons = "\uf25e";
fa.creativeCommonsBy = "\uf4e7";
fa.creativeCommonsNc = "\uf4e8";
fa.creativeCommonsNcEu = "\uf4e9";
fa.creativeCommonsNcJp = "\uf4ea";
fa.creativeCommonsNd = "\uf4eb";
fa.creativeCommonsPd = "\uf4ec";
fa.creativeCommonsPdAlt = "\uf4ed";
fa.creativeCommonsRemix = "\uf4ee";
fa.creativeCommonsSa = "\uf4ef";
fa.creativeCommonsSampling = "\uf4f0";
fa.creativeCommonsSamplingPlus = "\uf4f1";
fa.creativeCommonsShare = "\uf4f2";
fa.creativeCommonsZero = "\uf4f3";
fa.creditCard = "\uf09d";
fa.creditCardBlank = "\uf389";
fa.creditCardFront = "\uf38a";
fa.cricket = "\uf449";
fa.criticalRole = "\uf6c9";
fa.crop = "\uf125";
fa.cropAlt = "\uf565";
fa.cross = "\uf654";
fa.crosshairs = "\uf05b";
fa.crow = "\uf520";
fa.crown = "\uf521";
fa.css3 = "\uf13c";
fa.css3Alt = "\uf38b";
fa.cube = "\uf1b2";
fa.cubes = "\uf1b3";
fa.curling = "\uf44a";
fa.cut = "\uf0c4";
fa.cuttlefish = "\uf38c";
fa.dAndD = "\uf38d";
fa.dAndDBeyond = "\uf6ca";
fa.dagger = "\uf6cb";
fa.dashcube = "\uf210";
fa.database = "\uf1c0";
fa.deaf = "\uf2a4";
fa.deer = "\uf78e";
fa.deerRudolph = "\uf78f";
fa.delicious = "\uf1a5";
fa.democrat = "\uf747";
fa.deploydog = "\uf38e";
fa.deskpro = "\uf38f";
fa.desktop = "\uf108";
fa.desktopAlt = "\uf390";
fa.dev = "\uf6cc";
fa.deviantart = "\uf1bd";
fa.dewpoint = "\uf748";
fa.dharmachakra = "\uf655";
fa.dhl = "\uf790";
fa.diagnoses = "\uf470";
fa.diamond = "\uf219";
fa.diaspora = "\uf791";
fa.dice = "\uf522";
fa.diceD10 = "\uf6cd";
fa.diceD12 = "\uf6ce";
fa.diceD20 = "\uf6cf";
fa.diceD4 = "\uf6d0";
fa.diceD6 = "\uf6d1";
fa.diceD8 = "\uf6d2";
fa.diceFive = "\uf523";
fa.diceFour = "\uf524";
fa.diceOne = "\uf525";
fa.diceSix = "\uf526";
fa.diceThree = "\uf527";
fa.diceTwo = "\uf528";
fa.digg = "\uf1a6";
fa.digitalOcean = "\uf391";
fa.digitalTachograph = "\uf566";
fa.diploma = "\uf5ea";
fa.directions = "\uf5eb";
fa.discord = "\uf392";
fa.discourse = "\uf393";
fa.divide = "\uf529";
fa.dizzy = "\uf567";
fa.dna = "\uf471";
fa.doNotEnter = "\uf5ec";
fa.dochub = "\uf394";
fa.docker = "\uf395";
fa.dog = "\uf6d3";
fa.dogLeashed = "\uf6d4";
fa.dollarSign = "\uf155";
fa.dolly = "\uf472";
fa.dollyEmpty = "\uf473";
fa.dollyFlatbed = "\uf474";
fa.dollyFlatbedAlt = "\uf475";
fa.dollyFlatbedEmpty = "\uf476";
fa.donate = "\uf4b9";
fa.doorClosed = "\uf52a";
fa.doorOpen = "\uf52b";
fa.dotCircle = "\uf192";
fa.dove = "\uf4ba";
fa.download = "\uf019";
fa.draft2digital = "\uf396";
fa.draftingCompass = "\uf568";
fa.dragon = "\uf6d5";
fa.drawCircle = "\uf5ed";
fa.drawPolygon = "\uf5ee";
fa.drawSquare = "\uf5ef";
fa.dreidel = "\uf792";
fa.dribbble = "\uf17d";
fa.dribbbleSquare = "\uf397";
fa.dropbox = "\uf16b";
fa.drum = "\uf569";
fa.drumSteelpan = "\uf56a";
fa.drumstick = "\uf6d6";
fa.drumstickBite = "\uf6d7";
fa.drupal = "\uf1a9";
fa.duck = "\uf6d8";
fa.dumbbell = "\uf44b";
fa.dumpster = "\uf793";
fa.dumpsterFire = "\uf794";
fa.dungeon = "\uf6d9";
fa.dyalog = "\uf399";
fa.ear = "\uf5f0";
fa.earMuffs = "\uf795";
fa.earlybirds = "\uf39a";
fa.ebay = "\uf4f4";
fa.eclipse = "\uf749";
fa.eclipseAlt = "\uf74a";
fa.edge = "\uf282";
fa.edit = "\uf044";
fa.eject = "\uf052";
fa.elementor = "\uf430";
fa.elephant = "\uf6da";
fa.ellipsisH = "\uf141";
fa.ellipsisHAlt = "\uf39b";
fa.ellipsisV = "\uf142";
fa.ellipsisVAlt = "\uf39c";
fa.ello = "\uf5f1";
fa.ember = "\uf423";
fa.empire = "\uf1d1";
fa.emptySet = "\uf656";
fa.engineWarning = "\uf5f2";
fa.envelope = "\uf0e0";
fa.envelopeOpen = "\uf2b6";
fa.envelopeOpenDollar = "\uf657";
fa.envelopeOpenText = "\uf658";
fa.envelopeSquare = "\uf199";
fa.envira = "\uf299";
fa.equals = "\uf52c";
fa.eraser = "\uf12d";
fa.erlang = "\uf39d";
fa.ethereum = "\uf42e";
fa.ethernet = "\uf796";
fa.etsy = "\uf2d7";
fa.euroSign = "\uf153";
fa.exchange = "\uf0ec";
fa.exchangeAlt = "\uf362";
fa.exclamation = "\uf12a";
fa.exclamationCircle = "\uf06a";
fa.exclamationSquare = "\uf321";
fa.exclamationTriangle = "\uf071";
fa.expand = "\uf065";
fa.expandAlt = "\uf424";
fa.expandArrows = "\uf31d";
fa.expandArrowsAlt = "\uf31e";
fa.expandWide = "\uf320";
fa.expeditedssl = "\uf23e";
fa.externalLink = "\uf08e";
fa.externalLinkAlt = "\uf35d";
fa.externalLinkSquare = "\uf14c";
fa.externalLinkSquareAlt = "\uf360";
fa.eye = "\uf06e";
fa.eyeDropper = "\uf1fb";
fa.eyeEvil = "\uf6db";
fa.eyeSlash = "\uf070";
fa.facebook = "\uf09a";
fa.facebookF = "\uf39e";
fa.facebookMessenger = "\uf39f";
fa.facebookSquare = "\uf082";
fa.fantasyFlightGames = "\uf6dc";
fa.fastBackward = "\uf049";
fa.fastForward = "\uf050";
fa.fax = "\uf1ac";
fa.feather = "\uf52d";
fa.featherAlt = "\uf56b";
fa.fedex = "\uf797";
fa.fedora = "\uf798";
fa.female = "\uf182";
fa.fieldHockey = "\uf44c";
fa.fighterJet = "\uf0fb";
fa.figma = "\uf799";
fa.file = "\uf15b";
fa.fileAlt = "\uf15c";
fa.fileArchive = "\uf1c6";
fa.fileAudio = "\uf1c7";
fa.fileCertificate = "\uf5f3";
fa.fileChartLine = "\uf659";
fa.fileChartPie = "\uf65a";
fa.fileCheck = "\uf316";
fa.fileCode = "\uf1c9";
fa.fileContract = "\uf56c";
fa.fileCsv = "\uf6dd";
fa.fileDownload = "\uf56d";
fa.fileEdit = "\uf31c";
fa.fileExcel = "\uf1c3";
fa.fileExclamation = "\uf31a";
fa.fileExport = "\uf56e";
fa.fileImage = "\uf1c5";
fa.fileImport = "\uf56f";
fa.fileInvoice = "\uf570";
fa.fileInvoiceDollar = "\uf571";
fa.fileMedical = "\uf477";
fa.fileMedicalAlt = "\uf478";
fa.fileMinus = "\uf318";
fa.filePdf = "\uf1c1";
fa.filePlus = "\uf319";
fa.filePowerpoint = "\uf1c4";
fa.filePrescription = "\uf572";
fa.fileSignature = "\uf573";
fa.fileSpreadsheet = "\uf65b";
fa.fileTimes = "\uf317";
fa.fileUpload = "\uf574";
fa.fileUser = "\uf65c";
fa.fileVideo = "\uf1c8";
fa.fileWord = "\uf1c2";
fa.fill = "\uf575";
fa.fillDrip = "\uf576";
fa.film = "\uf008";
fa.filmAlt = "\uf3a0";
fa.filter = "\uf0b0";
fa.fingerprint = "\uf577";
fa.fire = "\uf06d";
fa.fireAlt = "\uf7e4";
fa.fireExtinguisher = "\uf134";
fa.fireSmoke = "\uf74b";
fa.firefox = "\uf269";
fa.fireplace = "\uf79a";
fa.firstAid = "\uf479";
fa.firstOrder = "\uf2b0";
fa.firstOrderAlt = "\uf50a";
fa.firstdraft = "\uf3a1";
fa.fish = "\uf578";
fa.fistRaised = "\uf6de";
fa.flag = "\uf024";
fa.flagAlt = "\uf74c";
fa.flagCheckered = "\uf11e";
fa.flagUsa = "\uf74d";
fa.flame = "\uf6df";
fa.flask = "\uf0c3";
fa.flaskPoison = "\uf6e0";
fa.flaskPotion = "\uf6e1";
fa.flickr = "\uf16e";
fa.flipboard = "\uf44d";
fa.flushed = "\uf579";
fa.fly = "\uf417";
fa.fog = "\uf74e";
fa.folder = "\uf07b";
fa.folderMinus = "\uf65d";
fa.folderOpen = "\uf07c";
fa.folderPlus = "\uf65e";
fa.folderTimes = "\uf65f";
fa.folders = "\uf660";
fa.font = "\uf031";
fa.fontAwesome = "\uf2b4";
fa.fontAwesomeAlt = "\uf35c";
fa.fontAwesomeFlag = "\uf425";
fa.fontAwesomeLogoFull = "\uf4e6";
fa.fonticons = "\uf280";
fa.fonticonsFi = "\uf3a2";
fa.footballBall = "\uf44e";
fa.footballHelmet = "\uf44f";
fa.forklift = "\uf47a";
fa.fortAwesome = "\uf286";
fa.fortAwesomeAlt = "\uf3a3";
fa.forumbee = "\uf211";
fa.forward = "\uf04e";
fa.foursquare = "\uf180";
fa.fragile = "\uf4bb";
fa.freeCodeCamp = "\uf2c5";
fa.freebsd = "\uf3a4";
fa.frog = "\uf52e";
fa.frostyHead = "\uf79b";
fa.frown = "\uf119";
fa.frownOpen = "\uf57a";
fa.fulcrum = "\uf50b";
fa.function = "\uf661";
fa.funnelDollar = "\uf662";
fa.futbol = "\uf1e3";
fa.galacticRepublic = "\uf50c";
fa.galacticSenate = "\uf50d";
fa.gamepad = "\uf11b";
fa.gasPump = "\uf52f";
fa.gasPumpSlash = "\uf5f4";
fa.gavel = "\uf0e3";
fa.gem = "\uf3a5";
fa.genderless = "\uf22d";
fa.getPocket = "\uf265";
fa.gg = "\uf260";
fa.ggCircle = "\uf261";
fa.ghost = "\uf6e2";
fa.gift = "\uf06b";
fa.giftCard = "\uf663";
fa.gifts = "\uf79c";
fa.gingerbreadMan = "\uf79d";
fa.git = "\uf1d3";
fa.gitSquare = "\uf1d2";
fa.github = "\uf09b";
fa.githubAlt = "\uf113";
fa.githubSquare = "\uf092";
fa.gitkraken = "\uf3a6";
fa.gitlab = "\uf296";
fa.gitter = "\uf426";
fa.glassChampagne = "\uf79e";
fa.glassCheers = "\uf79f";
fa.glassMartini = "\uf000";
fa.glassMartiniAlt = "\uf57b";
fa.glassWhiskey = "\uf7a0";
fa.glassWhiskeyRocks = "\uf7a1";
fa.glasses = "\uf530";
fa.glassesAlt = "\uf5f5";
fa.glide = "\uf2a5";
fa.glideG = "\uf2a6";
fa.globe = "\uf0ac";
fa.globeAfrica = "\uf57c";
fa.globeAmericas = "\uf57d";
fa.globeAsia = "\uf57e";
fa.globeEurope = "\uf7a2";
fa.globeSnow = "\uf7a3";
fa.globeStand = "\uf5f6";
fa.gofore = "\uf3a7";
fa.golfBall = "\uf450";
fa.golfClub = "\uf451";
fa.goodreads = "\uf3a8";
fa.goodreadsG = "\uf3a9";
fa.google = "\uf1a0";
fa.googleDrive = "\uf3aa";
fa.googlePlay = "\uf3ab";
fa.googlePlus = "\uf2b3";
fa.googlePlusG = "\uf0d5";
fa.googlePlusSquare = "\uf0d4";
fa.googleWallet = "\uf1ee";
fa.gopuram = "\uf664";
fa.graduationCap = "\uf19d";
fa.gratipay = "\uf184";
fa.grav = "\uf2d6";
fa.greaterThan = "\uf531";
fa.greaterThanEqual = "\uf532";
fa.grimace = "\uf57f";
fa.grin = "\uf580";
fa.grinAlt = "\uf581";
fa.grinBeam = "\uf582";
fa.grinBeamSweat = "\uf583";
fa.grinHearts = "\uf584";
fa.grinSquint = "\uf585";
fa.grinSquintTears = "\uf586";
fa.grinStars = "\uf587";
fa.grinTears = "\uf588";
fa.grinTongue = "\uf589";
fa.grinTongueSquint = "\uf58a";
fa.grinTongueWink = "\uf58b";
fa.grinWink = "\uf58c";
fa.gripHorizontal = "\uf58d";
fa.gripLines = "\uf7a4";
fa.gripLinesVertical = "\uf7a5";
fa.gripVertical = "\uf58e";
fa.gripfire = "\uf3ac";
fa.grunt = "\uf3ad";
fa.guitar = "\uf7a6";
fa.gulp = "\uf3ae";
fa.hSquare = "\uf0fd";
fa.h1 = "\uf313";
fa.h2 = "\uf314";
fa.h3 = "\uf315";
fa.hackerNews = "\uf1d4";
fa.hackerNewsSquare = "\uf3af";
fa.hackerrank = "\uf5f7";
fa.hammer = "\uf6e3";
fa.hammerWar = "\uf6e4";
fa.hamsa = "\uf665";
fa.handHeart = "\uf4bc";
fa.handHolding = "\uf4bd";
fa.handHoldingBox = "\uf47b";
fa.handHoldingHeart = "\uf4be";
fa.handHoldingMagic = "\uf6e5";
fa.handHoldingSeedling = "\uf4bf";
fa.handHoldingUsd = "\uf4c0";
fa.handHoldingWater = "\uf4c1";
fa.handLizard = "\uf258";
fa.handPaper = "\uf256";
fa.handPeace = "\uf25b";
fa.handPointDown = "\uf0a7";
fa.handPointLeft = "\uf0a5";
fa.handPointRight = "\uf0a4";
fa.handPointUp = "\uf0a6";
fa.handPointer = "\uf25a";
fa.handReceiving = "\uf47c";
fa.handRock = "\uf255";
fa.handScissors = "\uf257";
fa.handSpock = "\uf259";
fa.hands = "\uf4c2";
fa.handsHeart = "\uf4c3";
fa.handsHelping = "\uf4c4";
fa.handsUsd = "\uf4c5";
fa.handshake = "\uf2b5";
fa.handshakeAlt = "\uf4c6";
fa.hanukiah = "\uf6e6";
fa.hashtag = "\uf292";
fa.hatSanta = "\uf7a7";
fa.hatWinter = "\uf7a8";
fa.hatWitch = "\uf6e7";
fa.hatWizard = "\uf6e8";
fa.haykal = "\uf666";
fa.hdd = "\uf0a0";
fa.headSide = "\uf6e9";
fa.headVr = "\uf6ea";
fa.heading = "\uf1dc";
fa.headphones = "\uf025";
fa.headphonesAlt = "\uf58f";
fa.headset = "\uf590";
fa.heart = "\uf004";
fa.heartBroken = "\uf7a9";
fa.heartCircle = "\uf4c7";
fa.heartRate = "\uf5f8";
fa.heartSquare = "\uf4c8";
fa.heartbeat = "\uf21e";
fa.helicopter = "\uf533";
fa.helmetBattle = "\uf6eb";
fa.hexagon = "\uf312";
fa.highlighter = "\uf591";
fa.hiking = "\uf6ec";
fa.hippo = "\uf6ed";
fa.hips = "\uf452";
fa.hireAHelper = "\uf3b0";
fa.history = "\uf1da";
fa.hockeyMask = "\uf6ee";
fa.hockeyPuck = "\uf453";
fa.hockeySticks = "\uf454";
fa.hollyBerry = "\uf7aa";
fa.home = "\uf015";
fa.homeHeart = "\uf4c9";
fa.hoodCloak = "\uf6ef";
fa.hooli = "\uf427";
fa.hornbill = "\uf592";
fa.horse = "\uf6f0";
fa.horseHead = "\uf7ab";
fa.hospital = "\uf0f8";
fa.hospitalAlt = "\uf47d";
fa.hospitalSymbol = "\uf47e";
fa.hotTub = "\uf593";
fa.hotel = "\uf594";
fa.hotjar = "\uf3b1";
fa.hourglass = "\uf254";
fa.hourglassEnd = "\uf253";
fa.hourglassHalf = "\uf252";
fa.hourglassStart = "\uf251";
fa.houseDamage = "\uf6f1";
fa.houseFlood = "\uf74f";
fa.houzz = "\uf27c";
fa.hryvnia = "\uf6f2";
fa.html5 = "\uf13b";
fa.hubspot = "\uf3b2";
fa.humidity = "\uf750";
fa.hurricane = "\uf751";
fa.iCursor = "\uf246";
fa.iceSkate = "\uf7ac";
fa.icicles = "\uf7ad";
fa.idBadge = "\uf2c1";
fa.idCard = "\uf2c2";
fa.idCardAlt = "\uf47f";
fa.igloo = "\uf7ae";
fa.image = "\uf03e";
fa.images = "\uf302";
fa.imdb = "\uf2d8";
fa.inbox = "\uf01c";
fa.inboxIn = "\uf310";
fa.inboxOut = "\uf311";
fa.indent = "\uf03c";
fa.industry = "\uf275";
fa.industryAlt = "\uf3b3";
fa.infinity = "\uf534";
fa.info = "\uf129";
fa.infoCircle = "\uf05a";
fa.infoSquare = "\uf30f";
fa.inhaler = "\uf5f9";
fa.instagram = "\uf16d";
fa.integral = "\uf667";
fa.intercom = "\uf7af";
fa.internetExplorer = "\uf26b";
fa.intersection = "\uf668";
fa.inventory = "\uf480";
fa.invision = "\uf7b0";
fa.ioxhost = "\uf208";
fa.italic = "\uf033";
fa.itunes = "\uf3b4";
fa.itunesNote = "\uf3b5";
fa.jackOLantern = "\uf30e";
fa.java = "\uf4e4";
fa.jedi = "\uf669";
fa.jediOrder = "\uf50e";
fa.jenkins = "\uf3b6";
fa.jira = "\uf7b1";
fa.joget = "\uf3b7";
fa.joint = "\uf595";
fa.joomla = "\uf1aa";
fa.journalWhills = "\uf66a";
fa.js = "\uf3b8";
fa.jsSquare = "\uf3b9";
fa.jsfiddle = "\uf1cc";
fa.kaaba = "\uf66b";
fa.kaggle = "\uf5fa";
fa.key = "\uf084";
fa.keySkeleton = "\uf6f3";
fa.keybase = "\uf4f5";
fa.keyboard = "\uf11c";
fa.keycdn = "\uf3ba";
fa.keynote = "\uf66c";
fa.khanda = "\uf66d";
fa.kickstarter = "\uf3bb";
fa.kickstarterK = "\uf3bc";
fa.kidneys = "\uf5fb";
fa.kiss = "\uf596";
fa.kissBeam = "\uf597";
fa.kissWinkHeart = "\uf598";
fa.kite = "\uf6f4";
fa.kiwiBird = "\uf535";
fa.knifeKitchen = "\uf6f5";
fa.korvue = "\uf42f";
fa.lambda = "\uf66e";
fa.lamp = "\uf4ca";
fa.landmark = "\uf66f";
fa.landmarkAlt = "\uf752";
fa.language = "\uf1ab";
fa.laptop = "\uf109";
fa.laptopCode = "\uf5fc";
fa.laravel = "\uf3bd";
fa.lastfm = "\uf202";
fa.lastfmSquare = "\uf203";
fa.laugh = "\uf599";
fa.laughBeam = "\uf59a";
fa.laughSquint = "\uf59b";
fa.laughWink = "\uf59c";
fa.layerGroup = "\uf5fd";
fa.layerMinus = "\uf5fe";
fa.layerPlus = "\uf5ff";
fa.leaf = "\uf06c";
fa.leafHeart = "\uf4cb";
fa.leafMaple = "\uf6f6";
fa.leafOak = "\uf6f7";
fa.leanpub = "\uf212";
fa.lemon = "\uf094";
fa.less = "\uf41d";
fa.lessThan = "\uf536";
fa.lessThanEqual = "\uf537";
fa.levelDown = "\uf149";
fa.levelDownAlt = "\uf3be";
fa.levelUp = "\uf148";
fa.levelUpAlt = "\uf3bf";
fa.lifeRing = "\uf1cd";
fa.lightbulb = "\uf0eb";
fa.lightbulbDollar = "\uf670";
fa.lightbulbExclamation = "\uf671";
fa.lightbulbOn = "\uf672";
fa.lightbulbSlash = "\uf673";
fa.lightsHoliday = "\uf7b2";
fa.line = "\uf3c0";
fa.link = "\uf0c1";
fa.linkedin = "\uf08c";
fa.linkedinIn = "\uf0e1";
fa.linode = "\uf2b8";
fa.linux = "\uf17c";
fa.lips = "\uf600";
fa.liraSign = "\uf195";
fa.list = "\uf03a";
fa.listAlt = "\uf022";
fa.listOl = "\uf0cb";
fa.listUl = "\uf0ca";
fa.location = "\uf601";
fa.locationArrow = "\uf124";
fa.locationCircle = "\uf602";
fa.locationSlash = "\uf603";
fa.lock = "\uf023";
fa.lockAlt = "\uf30d";
fa.lockOpen = "\uf3c1";
fa.lockOpenAlt = "\uf3c2";
fa.longArrowAltDown = "\uf309";
fa.longArrowAltLeft = "\uf30a";
fa.longArrowAltRight = "\uf30b";
fa.longArrowAltUp = "\uf30c";
fa.longArrowDown = "\uf175";
fa.longArrowLeft = "\uf177";
fa.longArrowRight = "\uf178";
fa.longArrowUp = "\uf176";
fa.loveseat = "\uf4cc";
fa.lowVision = "\uf2a8";
fa.luchador = "\uf455";
fa.luggageCart = "\uf59d";
fa.lungs = "\uf604";
fa.lyft = "\uf3c3";
fa.mace = "\uf6f8";
fa.magento = "\uf3c4";
fa.magic = "\uf0d0";
fa.magnet = "\uf076";
fa.mailBulk = "\uf674";
fa.mailchimp = "\uf59e";
fa.male = "\uf183";
fa.mandalorian = "\uf50f";
fa.mandolin = "\uf6f9";
fa.map = "\uf279";
fa.mapMarked = "\uf59f";
fa.mapMarkedAlt = "\uf5a0";
fa.mapMarker = "\uf041";
fa.mapMarkerAlt = "\uf3c5";
fa.mapMarkerAltSlash = "\uf605";
fa.mapMarkerCheck = "\uf606";
fa.mapMarkerEdit = "\uf607";
fa.mapMarkerExclamation = "\uf608";
fa.mapMarkerMinus = "\uf609";
fa.mapMarkerPlus = "\uf60a";
fa.mapMarkerQuestion = "\uf60b";
fa.mapMarkerSlash = "\uf60c";
fa.mapMarkerSmile = "\uf60d";
fa.mapMarkerTimes = "\uf60e";
fa.mapPin = "\uf276";
fa.mapSigns = "\uf277";
fa.markdown = "\uf60f";
fa.marker = "\uf5a1";
fa.mars = "\uf222";
fa.marsDouble = "\uf227";
fa.marsStroke = "\uf229";
fa.marsStrokeH = "\uf22b";
fa.marsStrokeV = "\uf22a";
fa.mask = "\uf6fa";
fa.mastodon = "\uf4f6";
fa.maxcdn = "\uf136";
fa.medal = "\uf5a2";
fa.medapps = "\uf3c6";
fa.medium = "\uf23a";
fa.mediumM = "\uf3c7";
fa.medkit = "\uf0fa";
fa.medrt = "\uf3c8";
fa.meetup = "\uf2e0";
fa.megaphone = "\uf675";
fa.megaport = "\uf5a3";
fa.meh = "\uf11a";
fa.mehBlank = "\uf5a4";
fa.mehRollingEyes = "\uf5a5";
fa.memory = "\uf538";
fa.mendeley = "\uf7b3";
fa.menorah = "\uf676";
fa.mercury = "\uf223";
fa.meteor = "\uf753";
fa.microchip = "\uf2db";
fa.microphone = "\uf130";
fa.microphoneAlt = "\uf3c9";
fa.microphoneAltSlash = "\uf539";
fa.microphoneSlash = "\uf131";
fa.microscope = "\uf610";
fa.microsoft = "\uf3ca";
fa.mindShare = "\uf677";
fa.minus = "\uf068";
fa.minusCircle = "\uf056";
fa.minusHexagon = "\uf307";
fa.minusOctagon = "\uf308";
fa.minusSquare = "\uf146";
fa.mistletoe = "\uf7b4";
fa.mitten = "\uf7b5";
fa.mix = "\uf3cb";
fa.mixcloud = "\uf289";
fa.mizuni = "\uf3cc";
fa.mobile = "\uf10b";
fa.mobileAlt = "\uf3cd";
fa.mobileAndroid = "\uf3ce";
fa.mobileAndroidAlt = "\uf3cf";
fa.modx = "\uf285";
fa.monero = "\uf3d0";
fa.moneyBill = "\uf0d6";
fa.moneyBillAlt = "\uf3d1";
fa.moneyBillWave = "\uf53a";
fa.moneyBillWaveAlt = "\uf53b";
fa.moneyCheck = "\uf53c";
fa.moneyCheckAlt = "\uf53d";
fa.monitorHeartRate = "\uf611";
fa.monkey = "\uf6fb";
fa.monument = "\uf5a6";
fa.moon = "\uf186";
fa.moonCloud = "\uf754";
fa.moonStars = "\uf755";
fa.mortarPestle = "\uf5a7";
fa.mosque = "\uf678";
fa.motorcycle = "\uf21c";
fa.mountain = "\uf6fc";
fa.mountains = "\uf6fd";
fa.mousePointer = "\uf245";
fa.mugHot = "\uf7b6";
fa.mugMarshmallows = "\uf7b7";
fa.music = "\uf001";
fa.napster = "\uf3d2";
fa.narwhal = "\uf6fe";
fa.neos = "\uf612";
fa.networkWired = "\uf6ff";
fa.neuter = "\uf22c";
fa.newspaper = "\uf1ea";
fa.nimblr = "\uf5a8";
fa.nintendoSwitch = "\uf418";
fa.node = "\uf419";
fa.nodeJs = "\uf3d3";
fa.notEqual = "\uf53e";
fa.notesMedical = "\uf481";
fa.npm = "\uf3d4";
fa.ns8 = "\uf3d5";
fa.nutritionix = "\uf3d6";
fa.objectGroup = "\uf247";
fa.objectUngroup = "\uf248";
fa.octagon = "\uf306";
fa.odnoklassniki = "\uf263";
fa.odnoklassnikiSquare = "\uf264";
fa.oilCan = "\uf613";
fa.oilTemp = "\uf614";
fa.oldRepublic = "\uf510";
fa.om = "\uf679";
fa.omega = "\uf67a";
fa.opencart = "\uf23d";
fa.openid = "\uf19b";
fa.opera = "\uf26a";
fa.optinMonster = "\uf23c";
fa.ornament = "\uf7b8";
fa.osi = "\uf41a";
fa.otter = "\uf700";
fa.outdent = "\uf03b";
fa.page4 = "\uf3d7";
fa.pagelines = "\uf18c";
fa.paintBrush = "\uf1fc";
fa.paintBrushAlt = "\uf5a9";
fa.paintRoller = "\uf5aa";
fa.palette = "\uf53f";
fa.palfed = "\uf3d8";
fa.pallet = "\uf482";
fa.palletAlt = "\uf483";
fa.paperPlane = "\uf1d8";
fa.paperclip = "\uf0c6";
fa.parachuteBox = "\uf4cd";
fa.paragraph = "\uf1dd";
fa.parking = "\uf540";
fa.parkingCircle = "\uf615";
fa.parkingCircleSlash = "\uf616";
fa.parkingSlash = "\uf617";
fa.passport = "\uf5ab";
fa.pastafarianism = "\uf67b";
fa.paste = "\uf0ea";
fa.patreon = "\uf3d9";
fa.pause = "\uf04c";
fa.pauseCircle = "\uf28b";
fa.paw = "\uf1b0";
fa.pawAlt = "\uf701";
fa.pawClaws = "\uf702";
fa.paypal = "\uf1ed";
fa.peace = "\uf67c";
fa.pegasus = "\uf703";
fa.pen = "\uf304";
fa.penAlt = "\uf305";
fa.penFancy = "\uf5ac";
fa.penNib = "\uf5ad";
fa.penSquare = "\uf14b";
fa.pencil = "\uf040";
fa.pencilAlt = "\uf303";
fa.pencilPaintbrush = "\uf618";
fa.pencilRuler = "\uf5ae";
fa.pennant = "\uf456";
fa.pennyArcade = "\uf704";
fa.peopleCarry = "\uf4ce";
fa.percent = "\uf295";
fa.percentage = "\uf541";
fa.periscope = "\uf3da";
fa.personBooth = "\uf756";
fa.personCarry = "\uf4cf";
fa.personDolly = "\uf4d0";
fa.personDollyEmpty = "\uf4d1";
fa.personSign = "\uf757";
fa.phabricator = "\uf3db";
fa.phoenixFramework = "\uf3dc";
fa.phoenixSquadron = "\uf511";
fa.phone = "\uf095";
fa.phoneOffice = "\uf67d";
fa.phonePlus = "\uf4d2";
fa.phoneSlash = "\uf3dd";
fa.phoneSquare = "\uf098";
fa.phoneVolume = "\uf2a0";
fa.php = "\uf457";
fa.pi = "\uf67e";
fa.pie = "\uf705";
fa.piedPiper = "\uf2ae";
fa.piedPiperAlt = "\uf1a8";
fa.piedPiperHat = "\uf4e5";
fa.piedPiperPp = "\uf1a7";
fa.pig = "\uf706";
fa.piggyBank = "\uf4d3";
fa.pills = "\uf484";
fa.pinterest = "\uf0d2";
fa.pinterestP = "\uf231";
fa.pinterestSquare = "\uf0d3";
fa.placeOfWorship = "\uf67f";
fa.plane = "\uf072";
fa.planeAlt = "\uf3de";
fa.planeArrival = "\uf5af";
fa.planeDeparture = "\uf5b0";
fa.play = "\uf04b";
fa.playCircle = "\uf144";
fa.playstation = "\uf3df";
fa.plug = "\uf1e6";
fa.plus = "\uf067";
fa.plusCircle = "\uf055";
fa.plusHexagon = "\uf300";
fa.plusOctagon = "\uf301";
fa.plusSquare = "\uf0fe";
fa.podcast = "\uf2ce";
fa.podium = "\uf680";
fa.podiumStar = "\uf758";
fa.poll = "\uf681";
fa.pollH = "\uf682";
fa.pollPeople = "\uf759";
fa.poo = "\uf2fe";
fa.pooStorm = "\uf75a";
fa.poop = "\uf619";
fa.portrait = "\uf3e0";
fa.poundSign = "\uf154";
fa.powerOff = "\uf011";
fa.pray = "\uf683";
fa.prayingHands = "\uf684";
fa.prescription = "\uf5b1";
fa.prescriptionBottle = "\uf485";
fa.prescriptionBottleAlt = "\uf486";
fa.presentation = "\uf685";
fa.print = "\uf02f";
fa.printSlash = "\uf686";
fa.procedures = "\uf487";
fa.productHunt = "\uf288";
fa.projectDiagram = "\uf542";
fa.pumpkin = "\uf707";
fa.pushed = "\uf3e1";
fa.puzzlePiece = "\uf12e";
fa.python = "\uf3e2";
fa.qq = "\uf1d6";
fa.qrcode = "\uf029";
fa.question = "\uf128";
fa.questionCircle = "\uf059";
fa.questionSquare = "\uf2fd";
fa.quidditch = "\uf458";
fa.quinscape = "\uf459";
fa.quora = "\uf2c4";
fa.quoteLeft = "\uf10d";
fa.quoteRight = "\uf10e";
fa.quran = "\uf687";
fa.rProject = "\uf4f7";
fa.rabbit = "\uf708";
fa.rabbitFast = "\uf709";
fa.racquet = "\uf45a";
fa.radiation = "\uf7b9";
fa.radiationAlt = "\uf7ba";
fa.rainbow = "\uf75b";
fa.raindrops = "\uf75c";
fa.ram = "\uf70a";
fa.rampLoading = "\uf4d4";
fa.random = "\uf074";
fa.raspberryPi = "\uf7bb";
fa.ravelry = "\uf2d9";
fa.react = "\uf41b";
fa.reacteurope = "\uf75d";
fa.readme = "\uf4d5";
fa.rebel = "\uf1d0";
fa.receipt = "\uf543";
fa.rectangleLandscape = "\uf2fa";
fa.rectanglePortrait = "\uf2fb";
fa.rectangleWide = "\uf2fc";
fa.recycle = "\uf1b8";
fa.redRiver = "\uf3e3";
fa.reddit = "\uf1a1";
fa.redditAlien = "\uf281";
fa.redditSquare = "\uf1a2";
fa.redhat = "\uf7bc";
fa.redo = "\uf01e";
fa.redoAlt = "\uf2f9";
fa.registered = "\uf25d";
fa.renren = "\uf18b";
fa.repeat = "\uf363";
fa.repeat1 = "\uf365";
fa.repeat1Alt = "\uf366";
fa.repeatAlt = "\uf364";
fa.reply = "\uf3e5";
fa.replyAll = "\uf122";
fa.replyd = "\uf3e6";
fa.republican = "\uf75e";
fa.researchgate = "\uf4f8";
fa.resolving = "\uf3e7";
fa.restroom = "\uf7bd";
fa.retweet = "\uf079";
fa.retweetAlt = "\uf361";
fa.rev = "\uf5b2";
fa.ribbon = "\uf4d6";
fa.ring = "\uf70b";
fa.road = "\uf018";
fa.robot = "\uf544";
fa.rocket = "\uf135";
fa.rocketchat = "\uf3e8";
fa.rockrms = "\uf3e9";
fa.route = "\uf4d7";
fa.routeHighway = "\uf61a";
fa.routeInterstate = "\uf61b";
fa.rss = "\uf09e";
fa.rssSquare = "\uf143";
fa.rubleSign = "\uf158";
fa.ruler = "\uf545";
fa.rulerCombined = "\uf546";
fa.rulerHorizontal = "\uf547";
fa.rulerTriangle = "\uf61c";
fa.rulerVertical = "\uf548";
fa.running = "\uf70c";
fa.rupeeSign = "\uf156";
fa.rv = "\uf7be";
fa.sadCry = "\uf5b3";
fa.sadTear = "\uf5b4";
fa.safari = "\uf267";
fa.sass = "\uf41e";
fa.satellite = "\uf7bf";
fa.satelliteDish = "\uf7c0";
fa.save = "\uf0c7";
fa.scalpel = "\uf61d";
fa.scalpelPath = "\uf61e";
fa.scanner = "\uf488";
fa.scannerKeyboard = "\uf489";
fa.scannerTouchscreen = "\uf48a";
fa.scarecrow = "\uf70d";
fa.scarf = "\uf7c1";
fa.schlix = "\uf3ea";
fa.school = "\uf549";
fa.screwdriver = "\uf54a";
fa.scribd = "\uf28a";
fa.scroll = "\uf70e";
fa.scrollOld = "\uf70f";
fa.scrubber = "\uf2f8";
fa.scythe = "\uf710";
fa.sdCard = "\uf7c2";
fa.search = "\uf002";
fa.searchDollar = "\uf688";
fa.searchLocation = "\uf689";
fa.searchMinus = "\uf010";
fa.searchPlus = "\uf00e";
fa.searchengin = "\uf3eb";
fa.seedling = "\uf4d8";
fa.sellcast = "\uf2da";
fa.sellsy = "\uf213";
fa.server = "\uf233";
fa.servicestack = "\uf3ec";
fa.shapes = "\uf61f";
fa.share = "\uf064";
fa.shareAll = "\uf367";
fa.shareAlt = "\uf1e0";
fa.shareAltSquare = "\uf1e1";
fa.shareSquare = "\uf14d";
fa.sheep = "\uf711";
fa.shekelSign = "\uf20b";
fa.shield = "\uf132";
fa.shieldAlt = "\uf3ed";
fa.shieldCheck = "\uf2f7";
fa.shieldCross = "\uf712";
fa.ship = "\uf21a";
fa.shippingFast = "\uf48b";
fa.shippingTimed = "\uf48c";
fa.shirtsinbulk = "\uf214";
fa.shoePrints = "\uf54b";
fa.shoppingBag = "\uf290";
fa.shoppingBasket = "\uf291";
fa.shoppingCart = "\uf07a";
fa.shopware = "\uf5b5";
fa.shovel = "\uf713";
fa.shovelSnow = "\uf7c3";
fa.shower = "\uf2cc";
fa.shredder = "\uf68a";
fa.shuttleVan = "\uf5b6";
fa.shuttlecock = "\uf45b";
fa.sigma = "\uf68b";
fa.sign = "\uf4d9";
fa.signIn = "\uf090";
fa.signInAlt = "\uf2f6";
fa.signLanguage = "\uf2a7";
fa.signOut = "\uf08b";
fa.signOutAlt = "\uf2f5";
fa.signal = "\uf012";
fa.signal1 = "\uf68c";
fa.signal2 = "\uf68d";
fa.signal3 = "\uf68e";
fa.signal4 = "\uf68f";
fa.signalAlt = "\uf690";
fa.signalAlt1 = "\uf691";
fa.signalAlt2 = "\uf692";
fa.signalAlt3 = "\uf693";
fa.signalAltSlash = "\uf694";
fa.signalSlash = "\uf695";
fa.signature = "\uf5b7";
fa.simCard = "\uf7c4";
fa.simplybuilt = "\uf215";
fa.sistrix = "\uf3ee";
fa.sitemap = "\uf0e8";
fa.sith = "\uf512";
fa.skating = "\uf7c5";
fa.skeleton = "\uf620";
fa.sketch = "\uf7c6";
fa.skiJump = "\uf7c7";
fa.skiLift = "\uf7c8";
fa.skiing = "\uf7c9";
fa.skiingNordic = "\uf7ca";
fa.skull = "\uf54c";
fa.skullCrossbones = "\uf714";
fa.skyatlas = "\uf216";
fa.skype = "\uf17e";
fa.slack = "\uf198";
fa.slackHash = "\uf3ef";
fa.slash = "\uf715";
fa.sledding = "\uf7cb";
fa.sleigh = "\uf7cc";
fa.slidersH = "\uf1de";
fa.slidersHSquare = "\uf3f0";
fa.slidersV = "\uf3f1";
fa.slidersVSquare = "\uf3f2";
fa.slideshare = "\uf1e7";
fa.smile = "\uf118";
fa.smileBeam = "\uf5b8";
fa.smilePlus = "\uf5b9";
fa.smileWink = "\uf4da";
fa.smog = "\uf75f";
fa.smoke = "\uf760";
fa.smoking = "\uf48d";
fa.smokingBan = "\uf54d";
fa.sms = "\uf7cd";
fa.snake = "\uf716";
fa.snapchat = "\uf2ab";
fa.snapchatGhost = "\uf2ac";
fa.snapchatSquare = "\uf2ad";
fa.snowBlowing = "\uf761";
fa.snowboarding = "\uf7ce";
fa.snowflake = "\uf2dc";
fa.snowflakes = "\uf7cf";
fa.snowman = "\uf7d0";
fa.snowmobile = "\uf7d1";
fa.snowplow = "\uf7d2";
fa.socks = "\uf696";
fa.solarPanel = "\uf5ba";
fa.sort = "\uf0dc";
fa.sortAlphaDown = "\uf15d";
fa.sortAlphaUp = "\uf15e";
fa.sortAmountDown = "\uf160";
fa.sortAmountUp = "\uf161";
fa.sortDown = "\uf0dd";
fa.sortNumericDown = "\uf162";
fa.sortNumericUp = "\uf163";
fa.sortUp = "\uf0de";
fa.soundcloud = "\uf1be";
fa.sourcetree = "\uf7d3";
fa.spa = "\uf5bb";
fa.spaceShuttle = "\uf197";
fa.spade = "\uf2f4";
fa.speakap = "\uf3f3";
fa.spider = "\uf717";
fa.spiderBlackWidow = "\uf718";
fa.spiderWeb = "\uf719";
fa.spinner = "\uf110";
fa.spinnerThird = "\uf3f4";
fa.splotch = "\uf5bc";
fa.spotify = "\uf1bc";
fa.sprayCan = "\uf5bd";
fa.square = "\uf0c8";
fa.squareFull = "\uf45c";
fa.squareRoot = "\uf697";
fa.squareRootAlt = "\uf698";
fa.squarespace = "\uf5be";
fa.squirrel = "\uf71a";
fa.stackExchange = "\uf18d";
fa.stackOverflow = "\uf16c";
fa.staff = "\uf71b";
fa.stamp = "\uf5bf";
fa.star = "\uf005";
fa.starAndCrescent = "\uf699";
fa.starChristmas = "\uf7d4";
fa.starExclamation = "\uf2f3";
fa.starHalf = "\uf089";
fa.starHalfAlt = "\uf5c0";
fa.starOfDavid = "\uf69a";
fa.starOfLife = "\uf621";
fa.stars = "\uf762";
fa.staylinked = "\uf3f5";
fa.steam = "\uf1b6";
fa.steamSquare = "\uf1b7";
fa.steamSymbol = "\uf3f6";
fa.steeringWheel = "\uf622";
fa.stepBackward = "\uf048";
fa.stepForward = "\uf051";
fa.stethoscope = "\uf0f1";
fa.stickerMule = "\uf3f7";
fa.stickyNote = "\uf249";
fa.stocking = "\uf7d5";
fa.stomach = "\uf623";
fa.stop = "\uf04d";
fa.stopCircle = "\uf28d";
fa.stopwatch = "\uf2f2";
fa.store = "\uf54e";
fa.storeAlt = "\uf54f";
fa.strava = "\uf428";
fa.stream = "\uf550";
fa.streetView = "\uf21d";
fa.strikethrough = "\uf0cc";
fa.stripe = "\uf429";
fa.stripeS = "\uf42a";
fa.stroopwafel = "\uf551";
fa.studiovinari = "\uf3f8";
fa.stumbleupon = "\uf1a4";
fa.stumbleuponCircle = "\uf1a3";
fa.subscript = "\uf12c";
fa.subway = "\uf239";
fa.suitcase = "\uf0f2";
fa.suitcaseRolling = "\uf5c1";
fa.sun = "\uf185";
fa.sunCloud = "\uf763";
fa.sunDust = "\uf764";
fa.sunHaze = "\uf765";
fa.sunrise = "\uf766";
fa.sunset = "\uf767";
fa.superpowers = "\uf2dd";
fa.superscript = "\uf12b";
fa.supple = "\uf3f9";
fa.surprise = "\uf5c2";
fa.suse = "\uf7d6";
fa.swatchbook = "\uf5c3";
fa.swimmer = "\uf5c4";
fa.swimmingPool = "\uf5c5";
fa.sword = "\uf71c";
fa.swords = "\uf71d";
fa.synagogue = "\uf69b";
fa.sync = "\uf021";
fa.syncAlt = "\uf2f1";
fa.syringe = "\uf48e";
fa.table = "\uf0ce";
fa.tableTennis = "\uf45d";
fa.tablet = "\uf10a";
fa.tabletAlt = "\uf3fa";
fa.tabletAndroid = "\uf3fb";
fa.tabletAndroidAlt = "\uf3fc";
fa.tabletRugged = "\uf48f";
fa.tablets = "\uf490";
fa.tachometer = "\uf0e4";
fa.tachometerAlt = "\uf3fd";
fa.tachometerAltAverage = "\uf624";
fa.tachometerAltFast = "\uf625";
fa.tachometerAltFastest = "\uf626";
fa.tachometerAltSlow = "\uf627";
fa.tachometerAltSlowest = "\uf628";
fa.tachometerAverage = "\uf629";
fa.tachometerFast = "\uf62a";
fa.tachometerFastest = "\uf62b";
fa.tachometerSlow = "\uf62c";
fa.tachometerSlowest = "\uf62d";
fa.tag = "\uf02b";
fa.tags = "\uf02c";
fa.tally = "\uf69c";
fa.tape = "\uf4db";
fa.tasks = "\uf0ae";
fa.taxi = "\uf1ba";
fa.teamspeak = "\uf4f9";
fa.teeth = "\uf62e";
fa.teethOpen = "\uf62f";
fa.telegram = "\uf2c6";
fa.telegramPlane = "\uf3fe";
fa.temperatureFrigid = "\uf768";
fa.temperatureHigh = "\uf769";
fa.temperatureHot = "\uf76a";
fa.temperatureLow = "\uf76b";
fa.tencentWeibo = "\uf1d5";
fa.tenge = "\uf7d7";
fa.tennisBall = "\uf45e";
fa.terminal = "\uf120";
fa.textHeight = "\uf034";
fa.textWidth = "\uf035";
fa.th = "\uf00a";
fa.thLarge = "\uf009";
fa.thList = "\uf00b";
fa.theRedYeti = "\uf69d";
fa.theaterMasks = "\uf630";
fa.themeco = "\uf5c6";
fa.themeisle = "\uf2b2";
fa.thermometer = "\uf491";
fa.thermometerEmpty = "\uf2cb";
fa.thermometerFull = "\uf2c7";
fa.thermometerHalf = "\uf2c9";
fa.thermometerQuarter = "\uf2ca";
fa.thermometerThreeQuarters = "\uf2c8";
fa.theta = "\uf69e";
fa.thinkPeaks = "\uf731";
fa.thumbsDown = "\uf165";
fa.thumbsUp = "\uf164";
fa.thumbtack = "\uf08d";
fa.thunderstorm = "\uf76c";
fa.thunderstormMoon = "\uf76d";
fa.thunderstormSun = "\uf76e";
fa.ticket = "\uf145";
fa.ticketAlt = "\uf3ff";
fa.tilde = "\uf69f";
fa.times = "\uf00d";
fa.timesCircle = "\uf057";
fa.timesHexagon = "\uf2ee";
fa.timesOctagon = "\uf2f0";
fa.timesSquare = "\uf2d3";
fa.tint = "\uf043";
fa.tintSlash = "\uf5c7";
fa.tire = "\uf631";
fa.tireFlat = "\uf632";
fa.tirePressureWarning = "\uf633";
fa.tireRugged = "\uf634";
fa.tired = "\uf5c8";
fa.toggleOff = "\uf204";
fa.toggleOn = "\uf205";
fa.toilet = "\uf7d8";
fa.toiletPaper = "\uf71e";
fa.toiletPaperAlt = "\uf71f";
fa.tombstone = "\uf720";
fa.tombstoneAlt = "\uf721";
fa.toolbox = "\uf552";
fa.tools = "\uf7d9";
fa.tooth = "\uf5c9";
fa.toothbrush = "\uf635";
fa.torah = "\uf6a0";
fa.toriiGate = "\uf6a1";
fa.tornado = "\uf76f";
fa.tractor = "\uf722";
fa.tradeFederation = "\uf513";
fa.trademark = "\uf25c";
fa.trafficCone = "\uf636";
fa.trafficLight = "\uf637";
fa.trafficLightGo = "\uf638";
fa.trafficLightSlow = "\uf639";
fa.trafficLightStop = "\uf63a";
fa.train = "\uf238";
fa.tram = "\uf7da";
fa.transgender = "\uf224";
fa.transgenderAlt = "\uf225";
fa.trash = "\uf1f8";
fa.trashAlt = "\uf2ed";
fa.treasureChest = "\uf723";
fa.tree = "\uf1bb";
fa.treeAlt = "\uf400";
fa.treeChristmas = "\uf7db";
fa.treeDecorated = "\uf7dc";
fa.treeLarge = "\uf7dd";
fa.trees = "\uf724";
fa.trello = "\uf181";
fa.triangle = "\uf2ec";
fa.tripadvisor = "\uf262";
fa.trophy = "\uf091";
fa.trophyAlt = "\uf2eb";
fa.truck = "\uf0d1";
fa.truckContainer = "\uf4dc";
fa.truckCouch = "\uf4dd";
fa.truckLoading = "\uf4de";
fa.truckMonster = "\uf63b";
fa.truckMoving = "\uf4df";
fa.truckPickup = "\uf63c";
fa.truckPlow = "\uf7de";
fa.truckRamp = "\uf4e0";
fa.tshirt = "\uf553";
fa.tty = "\uf1e4";
fa.tumblr = "\uf173";
fa.tumblrSquare = "\uf174";
fa.turkey = "\uf725";
fa.turtle = "\uf726";
fa.tv = "\uf26c";
fa.tvRetro = "\uf401";
fa.twitch = "\uf1e8";
fa.twitter = "\uf099";
fa.twitterSquare = "\uf081";
fa.typo3 = "\uf42b";
fa.uber = "\uf402";
fa.ubuntu = "\uf7df";
fa.uikit = "\uf403";
fa.umbrella = "\uf0e9";
fa.umbrellaBeach = "\uf5ca";
fa.underline = "\uf0cd";
fa.undo = "\uf0e2";
fa.undoAlt = "\uf2ea";
fa.unicorn = "\uf727";
fa.union = "\uf6a2";
fa.uniregistry = "\uf404";
fa.universalAccess = "\uf29a";
fa.university = "\uf19c";
fa.unlink = "\uf127";
fa.unlock = "\uf09c";
fa.unlockAlt = "\uf13e";
fa.untappd = "\uf405";
fa.upload = "\uf093";
fa.ups = "\uf7e0";
fa.usb = "\uf287";
fa.usdCircle = "\uf2e8";
fa.usdSquare = "\uf2e9";
fa.user = "\uf007";
fa.userAlt = "\uf406";
fa.userAltSlash = "\uf4fa";
fa.userAstronaut = "\uf4fb";
fa.userChart = "\uf6a3";
fa.userCheck = "\uf4fc";
fa.userCircle = "\uf2bd";
fa.userClock = "\uf4fd";
fa.userCog = "\uf4fe";
fa.userCrown = "\uf6a4";
fa.userEdit = "\uf4ff";
fa.userFriends = "\uf500";
fa.userGraduate = "\uf501";
fa.userInjured = "\uf728";
fa.userLock = "\uf502";
fa.userMd = "\uf0f0";
fa.userMinus = "\uf503";
fa.userNinja = "\uf504";
fa.userPlus = "\uf234";
fa.userSecret = "\uf21b";
fa.userShield = "\uf505";
fa.userSlash = "\uf506";
fa.userTag = "\uf507";
fa.userTie = "\uf508";
fa.userTimes = "\uf235";
fa.users = "\uf0c0";
fa.usersClass = "\uf63d";
fa.usersCog = "\uf509";
fa.usersCrown = "\uf6a5";
fa.usps = "\uf7e1";
fa.ussunnah = "\uf407";
fa.utensilFork = "\uf2e3";
fa.utensilKnife = "\uf2e4";
fa.utensilSpoon = "\uf2e5";
fa.utensils = "\uf2e7";
fa.utensilsAlt = "\uf2e6";
fa.vaadin = "\uf408";
fa.valueAbsolute = "\uf6a6";
fa.vectorSquare = "\uf5cb";
fa.venus = "\uf221";
fa.venusDouble = "\uf226";
fa.venusMars = "\uf228";
fa.viacoin = "\uf237";
fa.viadeo = "\uf2a9";
fa.viadeoSquare = "\uf2aa";
fa.vial = "\uf492";
fa.vials = "\uf493";
fa.viber = "\uf409";
fa.video = "\uf03d";
fa.videoPlus = "\uf4e1";
fa.videoSlash = "\uf4e2";
fa.vihara = "\uf6a7";
fa.vimeo = "\uf40a";
fa.vimeoSquare = "\uf194";
fa.vimeoV = "\uf27d";
fa.vine = "\uf1ca";
fa.vk = "\uf189";
fa.vnv = "\uf40b";
fa.volcano = "\uf770";
fa.volleyballBall = "\uf45f";
fa.volume = "\uf6a8";
fa.volumeDown = "\uf027";
fa.volumeMute = "\uf6a9";
fa.volumeOff = "\uf026";
fa.volumeSlash = "\uf2e2";
fa.volumeUp = "\uf028";
fa.voteNay = "\uf771";
fa.voteYea = "\uf772";
fa.vrCardboard = "\uf729";
fa.vuejs = "\uf41f";
fa.walking = "\uf554";
fa.wallet = "\uf555";
fa.wand = "\uf72a";
fa.wandMagic = "\uf72b";
fa.warehouse = "\uf494";
fa.warehouseAlt = "\uf495";
fa.watch = "\uf2e1";
fa.watchFitness = "\uf63e";
fa.water = "\uf773";
fa.waterLower = "\uf774";
fa.waterRise = "\uf775";
fa.weebly = "\uf5cc";
fa.weibo = "\uf18a";
fa.weight = "\uf496";
fa.weightHanging = "\uf5cd";
fa.weixin = "\uf1d7";
fa.whale = "\uf72c";
fa.whatsapp = "\uf232";
fa.whatsappSquare = "\uf40c";
fa.wheat = "\uf72d";
fa.wheelchair = "\uf193";
fa.whistle = "\uf460";
fa.whmcs = "\uf40d";
fa.wifi = "\uf1eb";
fa.wifi1 = "\uf6aa";
fa.wifi2 = "\uf6ab";
fa.wifiSlash = "\uf6ac";
fa.wikipediaW = "\uf266";
fa.wind = "\uf72e";
fa.windWarning = "\uf776";
fa.window = "\uf40e";
fa.windowAlt = "\uf40f";
fa.windowClose = "\uf410";
fa.windowMaximize = "\uf2d0";
fa.windowMinimize = "\uf2d1";
fa.windowRestore = "\uf2d2";
fa.windows = "\uf17a";
fa.windsock = "\uf777";
fa.wineBottle = "\uf72f";
fa.wineGlass = "\uf4e3";
fa.wineGlassAlt = "\uf5ce";
fa.wix = "\uf5cf";
fa.wizardsOfTheCoast = "\uf730";
fa.wolfPackBattalion = "\uf514";
fa.wonSign = "\uf159";
fa.wordpress = "\uf19a";
fa.wordpressSimple = "\uf411";
fa.wpbeginner = "\uf297";
fa.wpexplorer = "\uf2de";
fa.wpforms = "\uf298";
fa.wpressr = "\uf3e4";
fa.wreath = "\uf7e2";
fa.wrench = "\uf0ad";
fa.xRay = "\uf497";
fa.xbox = "\uf412";
fa.xing = "\uf168";
fa.xingSquare = "\uf169";
fa.yCombinator = "\uf23b";
fa.yahoo = "\uf19e";
fa.yandex = "\uf413";
fa.yandexInternational = "\uf414";
fa.yarn = "\uf7e3";
fa.yelp = "\uf1e9";
fa.yenSign = "\uf157";
fa.yinYang = "\uf6ad";
fa.yoast = "\uf2b1";
fa.youtube = "\uf167";
fa.youtubeSquare = "\uf431";
fa.zhihu = "\uf63f";
module.exports = fa;
