import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { AnomalieAziendaHeader } from "components/Views/Aziende/AnomalieAziendaHeader.js";
import Buttons from "components/CustomButtons/Button.js";
import ButtonsMUI from "@material-ui/core/Button";
import { InputTextarea } from "primereact/inputtextarea";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Dialog } from "primereact/dialog";
import queryString from "query-string";
import AnomalieAPI from "API/AnomalieAPI";
import helpers from "../../helpers/Helpers";
import Helpers from "../../helpers/Helpers";
import authApi from "../../API/auth.api";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";
import CorrispondenzaAPI from "../../API/CorrispondenzaAPI";
import moment from "moment";
import { dateFmt, dateTimeFmt } from "../../helpers/AppConstants";
import { Tooltip } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { parse } from "query-string";
class AnomalieXAzienda extends Component {
  state = {
    rowsCustodi: [],
    rowsPulitori: [],
    selectedGridAnomalieCustodi: null,
    selectedGridAnomaliePulitori: null,
    isChecked: false,
    textArea: "",
    loadingPage: false,
    openPopUp: false,
    loadingGridCustodi: true,
    loadingGridPulitori: true,
    VisibleButtons: false,
    isVisible: true,
    tipoAnomalie: null,
    rowsPerPagePuli: 12,
    rowsPerPageCust: 12,
    openPopUpDocumento: false,
    openPopUpDocumentoRegolarita: false,
    textAreaDocumento1:
      "Buongiorno,\nin riferimento alla documentazione inviataci a fronte della nostra segnalazione del __/__/____ prot.: ____/____, volevamo comunicarVi quanto segue:",
    textAreaDocumento2:
      "Finché il condominio non effettuerà un nuovo versamento relativo ai periodi sopra elencati e non correggerà gli UNIEMENS, la posizione contributiva del suddetto non potrà essere considerata regolare.\n",
    textAreaDocumento3:
      "Buongiorno,\nIn riferimento alla documentazione inviataci a fronte della nostra segnalazione del __/__/____ prot.: ____/____, volevamo comunicarVi che le anomalie sono state regolarizzate ed ad oggi la posizione del condominio risulta regolare."
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onVediClick = this.onVediClick.bind(this);
    this.PredisponiRegolarita = this.PredisponiRegolarita.bind(this);
    this.PredisponiSpedizione = this.PredisponiSpedizione.bind(this);
    this.TextAreaValue = this.TextAreaValue.bind(this);
    this.AnnullaAnomalie = this.AnnullaAnomalie.bind(this);
    this.AnnullaAnomalieSelez = this.AnnullaAnomalieSelez.bind(this);
    this.onHidePopup = this.onHidePopup.bind(this);
    this.onHidePopupDocumento = this.onHidePopupDocumento.bind(this);
    this.TextAreaValueDocumento = this.TextAreaValueDocumento.bind(this);
    this.onHidePopupDocumentoRegolarita =
      this.onHidePopupDocumentoRegolarita.bind(this);
    this.PredisponiSpedizioneClick = this.PredisponiSpedizioneClick.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
  }

  backButtonClick() {
    let qS = parse(this.props.location.search);
    let maintainSearch = "?maintainSearch=1";
    if (!qS.type)
      this.props.history.push(
        "/admin/aziende/" + this.props.match.params.aziendaId + maintainSearch
      );
    else if (qS.type === "fromVersamenti")
      this.props.history.push(
        "/admin/versamenti/details/" +
          this.props.match.params.aziendaId +
          maintainSearch
      );
    else if (qS.type === "fromFiltroAnomalie")
      this.props.history.push("/admin/filtroanomalie/");
  }

  onHidePopupDocumento() {
    this.setState({
      openPopUpDocumento: false
    });
  }

  onHidePopupDocumentoRegolarita() {
    this.setState({
      openPopUpDocumentoRegolarita: false
    });
  }

  TextAreaValueDocumento(event, field) {
    this.setState({
      [field]: event.target.value
    });
  }

  async componentDidMount() {
    await this.refreshGrid();
    const response = await CorrispondenzaAPI.getLastForCompany(
      this.props.match.params.aziendaId
    );
    if (response?.message?.includes("200") && response?.result[0]) {
      const corr = response?.result[0];
      const textAreaDocumento1 =
        "Buongiorno,\nin riferimento alla documentazione inviataci a fronte della nostra segnalazione del " +
        moment(corr.data, dateTimeFmt).format(dateFmt) +
        " prot.: " +
        corr.prot +
        "/" +
        corr.anno +
        ", volevamo comunicarVi quanto segue:";
      const textAreaDocumento3 =
        "Buongiorno,\nIn riferimento alla documentazione inviataci a fronte della nostra segnalazione del " +
        moment(corr.data, dateTimeFmt).format(dateFmt) +
        " prot.: " +
        corr.prot +
        "/" +
        corr.anno +
        ", volevamo comunicarVi che le anomalie sono state regolarizzate ed ad oggi la posizione del condominio risulta regolare.";
      this.setState({ textAreaDocumento1, textAreaDocumento3 });
    }
  }

  onVediClick() {
    let qS = queryString.parse(this.props.location.search);
    const url =
      "/admin/AnomalieRegolarizzate/" + this.props.match.params.aziendaId;
    if (!qS.type) {
      this.props.history.push(url);
    } else if (qS.type === "fromVersamenti") {
      this.props.history.push(url + "?type=fromVersamenti");
    } else if (qS.type === "fromFiltroAnomalie") {
      this.props.history.push(url + "?type=fromFiltroAnomalie");
    }
  }

  handleChange(e) {
    this.setState({ isChecked: e.target.checked });
  }

  async PredisponiRegolarita() {
    this.setState({ loadingPage: true });
    await AnomalieAPI.PredisponiRegolarita(
      this.props.match.params.aziendaId,
      this.state.textAreaDocumento3
    );
    this.setState({ loadingPage: false });
    this.onHidePopupDocumentoRegolarita();
  }

  async PredisponiSpedizione() {
    this.setState({ loadingPage: true });
    const res = await AnomalieAPI.PredisponiSpedizione(
      this.props.match.params.aziendaId,
      this.state.textAreaDocumento1,
      this.state.textAreaDocumento2,
      this.state.selectedGridAnomalieCustodi,
      this.state.selectedGridAnomaliePulitori
    );
    this.setState({ loadingPage: false });
    if (res) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Si è verificato un errore durante la generazione del documento."
      );
    } else this.onHidePopupDocumento();
  }

  onHidePopup() {
    this.setState({
      openPopUp: false,
      textArea: "",
      selectedGridAnomalieCustodi: null,
      selectedGridAnomaliePulitori: null
    });
  }

  PredisponiSpedizioneClick() {
    if (
      this.state.selectedGridAnomalieCustodi?.length > 0 ||
      this.state.selectedGridAnomaliePulitori?.length > 0
    ) {
      this.setState({ openPopUpDocumento: true });
    } else {
      this.props.addAlert.current.addAlert(
        "warning",
        "Nessuna riga selezionata"
      );
    }
  }

  AnnullaAnomalie() {
    if (
      this.state.selectedGridAnomalieCustodi?.length > 0 ||
      this.state.selectedGridAnomaliePulitori?.length > 0
    ) {
      this.setState({ openPopUp: true });
    } else {
      this.props.addAlert.current.addAlert(
        "warning",
        "Nessuna riga selezionata"
      );
    }
  }

  async refreshGrid() {
    this.setState({
      loadingGridCustodi: true,
      loadingGridPulitori: true
    });

    let aziendaId = this.props.match.params.aziendaId;
    let qS = queryString.parse(this.props.location.search);
    if (!qS.type)
      this.setState({
        VisibleButtons: false
      });
    else if (qS.type === "fromVersamenti")
      this.setState({
        VisibleButtons: true
      });

    this.setState({
      rowsCustodi: await AnomalieAPI.GetAnomalieAttiveByAziendaId(
        aziendaId,
        "CUST"
      ),
      rowsPulitori: await AnomalieAPI.GetAnomalieAttiveByAziendaId(
        aziendaId,
        "PULI"
      ),
      tipoAnomalie: await AnomalieAPI.GetAnomalieType()
    });

    this.setState({
      loadingGridCustodi: false,
      loadingGridPulitori: false
    });
  }

  async AnnullaAnomalieSelez() {
    let selected = [];
    if (this.state.selectedGridAnomalieCustodi?.length > 0)
      selected = selected.concat(this.state.selectedGridAnomalieCustodi);
    if (this.state.selectedGridAnomaliePulitori?.length > 0)
      selected = selected.concat(this.state.selectedGridAnomaliePulitori);

    if (selected.length > 0) {
      const username = (await authApi.getProfile())?.result[0]?.username;
      const result = await AnomalieAPI.AnnullaAnomalie(
        selected,
        this.state.textArea,
        username
      );

      if (result) {
        this.props.addAlert.current.addAlert(
          "success",
          "Anomalie annullate correttamente"
        );
        this.onHidePopup();
        await this.refreshGrid();
      } else {
        this.props.addAlert.current.addAlert(
          "danger",
          "Impossibile annullare le anomalie selezionate"
        );
      }
    }
  }

  TextAreaValue(event) {
    this.setState({
      textArea: event.target.value
    });
  }

  async handleTableClick(e, field) {
    this.setState({
      [field]: e.value
    });
  }

  render() {
    return (
      <>
        {this.state.loadingPage && <LoaderSpinner />}
        <Dialog
          header="Nota per Anomalie da Annullare"
          visible={this.state.openPopUp}
          style={{ width: "50vw" }}
          onHide={this.onHidePopup}
        >
          <InputTextarea
            style={{ marginTop: "5px" }}
            rowsCustodi={6}
            cols={30}
            value={this.state.textArea}
            onChange={this.TextAreaValue}
            autoResize
          />
          <Buttons
            style={{ width: "40%", marginLeft: "30%", marginRight: "30%" }}
            onClick={this.AnnullaAnomalieSelez}
            color="danger"
          >
            Annulla Anomalie Selezionate
          </Buttons>
        </Dialog>
        <Dialog
          header="Predisponi documento anomalie per spedizione"
          visible={this.state.openPopUpDocumento}
          style={{ width: "50vw" }}
          onHide={this.onHidePopupDocumento}
        >
          <InputTextarea
            style={{ marginTop: "5px" }}
            cols={30}
            value={this.state.textAreaDocumento1}
            onChange={(e) =>
              this.TextAreaValueDocumento(e, "textAreaDocumento1")
            }
            autoResize
          />
          <InputTextarea
            style={{ marginTop: "5px" }}
            cols={30}
            value={this.state.textAreaDocumento2}
            onChange={(e) =>
              this.TextAreaValueDocumento(e, "textAreaDocumento2")
            }
            autoResize
          />
          <Buttons
            style={{ width: "40%", marginLeft: "30%", marginRight: "30%" }}
            onClick={this.PredisponiSpedizione}
            color="primary"
          >
            Predisponi documento anomalie per spedizione
          </Buttons>
        </Dialog>
        <Dialog
          header="Predisponi documento regolarità"
          visible={this.state.openPopUpDocumentoRegolarita}
          style={{ width: "50vw" }}
          onHide={this.onHidePopupDocumentoRegolarita}
        >
          <InputTextarea
            style={{ marginTop: "5px" }}
            cols={30}
            value={this.state.textAreaDocumento3}
            onChange={(e) =>
              this.TextAreaValueDocumento(e, "textAreaDocumento3")
            }
            autoResize
          />
          <Buttons
            style={{ width: "40%", marginLeft: "30%", marginRight: "30%" }}
            onClick={this.PredisponiRegolarita}
            color="primary"
          >
            Predisponi documento regolarità
          </Buttons>
        </Dialog>
        <AnomalieAziendaHeader
          style={{ zIndex: "2" }}
          isVisible={this.state.isVisible}
          aziendaId={this.props.match.params.aziendaId}
          {...this.props}
        />

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="success"
                style={{ zIndex: "2", position: "relative" }}
                className={"float-right "}
              >
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Anomalie Attive Custodi
                </h4>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  size="small"
                  aria-label="contained primary button group"
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    marginTop: "12px",
                    marginBottom: "10px",
                    marginLeft: "10px",
                    marginRight: "10px"
                  }}
                >
                  {this.state.VisibleButtons && (
                    <ButtonGroup>
                      <ButtonsMUI onClick={this.PredisponiSpedizioneClick}>
                        Predisponi Documento Anomalie per Spedizione
                      </ButtonsMUI>
                      <ButtonsMUI
                        onClick={() =>
                          this.setState({ openPopUpDocumentoRegolarita: true })
                        }
                      >
                        Predisponi Documento Regolarita
                      </ButtonsMUI>
                      <ButtonsMUI onClick={this.AnnullaAnomalie}>
                        Annulla Anomalie Selezionate
                      </ButtonsMUI>
                    </ButtonGroup>
                  )}
                  <ButtonsMUI onClick={this.onVediClick}>
                    Vedi Regolarizzate
                  </ButtonsMUI>
                </ButtonGroup>

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Alla pagina precedente"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.backButtonClick}
                  >
                    <ArrowBackIcon />
                  </Button>
                </Tooltip>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rowsCustodi}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPageCust}
                  rowsPerPageOptions={[12, 24, 36, 48]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  loading={this.state.loadingGridCustodi}
                  selection={this.state.selectedGridAnomalieCustodi}
                  scrollable
                  scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={(e) =>
                    this.handleTableClick(e, "selectedGridAnomalieCustodi")
                  }
                  selectionMode="multiple"
                  metaKeySelection={false}
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3em", paddingLeft: "0.25em" }}
                  />
                  <Column
                    field="cod_anomalia"
                    header="Tipo"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    body={(row) => {
                      return (
                        <>
                          {row.cod_anomalia +
                            " - " +
                            this.state.tipoAnomalie.find(
                              (x) => x.codice === row.cod_anomalia
                            )?.notazione}
                        </>
                      );
                    }}
                  />
                  <Column
                    field="codQuota"
                    header="Cod.Quota"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                  />
                  <Column
                    field="comp"
                    header="Comp."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                  />
                  <Column
                    field="importoDov"
                    header="Dov."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    body={(row) => helpers.DecimalDTTemplate(row, "importoDov")}
                  />
                  <Column
                    field="importoDic"
                    header="Dich."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    body={(row) => helpers.DecimalDTTemplate(row, "importoDic")}
                  />
                  <Column
                    field="dataRilevaz"
                    header="Data Rilev."
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    body={(row) => Helpers.dateToString(row, "dataRilevaz")}
                  />
                  <Column
                    field="dataComunic"
                    header="Data Comun."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={(row) => Helpers.dateToString(row, "dataComunic")}
                  />
                  <Column
                    field="numComun"
                    header="N. Com."
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                  />
                  <Column
                    field="dtsistem"
                    header="Data Sistem."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                  />
                  <Column
                    field="stato"
                    header="Stato"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                  />
                  <Column
                    field="dataAnnul"
                    header="Data Annul."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={(row) => Helpers.dateToString(row, "dataAnnul")}
                  />
                  <Column
                    field="utente"
                    header="Utente"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                  />
                  <Column
                    field="note"
                    header="Note"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader
                color="success"
                style={{ zIndex: "2", position: "relative", height: "62px" }}
                className={"float-right "}
              >
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Anomalie Attive Pulitori
                </h4>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rowsPulitori}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPagePuli}
                  rowsPerPageOptions={[12, 24, 36, 48]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  loading={this.state.loadingGridPulitori}
                  selection={this.state.selectedGridAnomaliePulitori}
                  scrollable
                  scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={(e) =>
                    this.handleTableClick(e, "selectedGridAnomaliePulitori")
                  }
                  selectionMode="multiple"
                  metaKeySelection={false}
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3em", paddingLeft: "0.25em" }}
                  />
                  <Column
                    field="cod_anomalia"
                    header="Tipo"
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    body={(row) => {
                      return (
                        <>
                          {row.cod_anomalia +
                            " - " +
                            this.state.tipoAnomalie.find(
                              (x) => x.codice === row.cod_anomalia
                            )?.notazione}
                        </>
                      );
                    }}
                  />
                  <Column
                    field="codQuota"
                    header="Cod.Quota"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                  />
                  <Column
                    field="comp"
                    header="Comp."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                  />
                  <Column
                    field="importoDov"
                    header="Dov."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    body={(row) => helpers.DecimalDTTemplate(row, "importoDov")}
                  />
                  <Column
                    field="importoDic"
                    header="Dich."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    body={(row) => helpers.DecimalDTTemplate(row, "importoDic")}
                  />
                  <Column
                    field="dataRilevaz"
                    header="Data Rilev."
                    headerStyle={{ width: "130px" }}
                    sortable={true}
                    body={(row) => Helpers.dateToString(row, "dataRilevaz")}
                  />
                  <Column
                    field="dataComunic"
                    header="Data Comun."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={(row) => Helpers.dateToString(row, "dataComunic")}
                  />
                  <Column
                    field="numComun"
                    header="N. Com."
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                  />
                  <Column
                    field="dtsistem"
                    header="Data Sistem."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                  />
                  <Column
                    field="stato"
                    header="Stato"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                  />
                  <Column
                    field="dataAnnul"
                    header="Data Annul."
                    headerStyle={{ width: "140px" }}
                    sortable={true}
                    body={(row) => Helpers.dateToString(row, "dataAnnul")}
                  />
                  <Column
                    field="utente"
                    header="Utente"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                  />
                  <Column
                    field="note"
                    header="Note"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default AnomalieXAzienda;
