import Utils from "./Utils";

const qualifica1API = {

    getAll: async function () {
        const response = await Utils.CreatePostRequest("coasco/uniq1/getall");
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async update(q1) {
        const response = await Utils.CreatePutRequest("coasco/uniq1/update", q1);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async insert(q1) {
        const response = await Utils.CreatePostRequest("coasco/uniq1/insert", q1);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async delete(q1) {
        const response = await Utils.CreateDeleteRequest("coasco/uniq1/delete?id=" + q1.id);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    getNew() {
        return {
            codice: "",
            descrizione: "",
            id: 0
        };
    }

};

export default qualifica1API;