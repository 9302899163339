import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "providers";
// Api
import BorseAPI from "API/BorseAPI";
import UtentiAPI from "API/Utenti.api.js";

// Components
import Table from "sharedComponents/Table.component";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

const ReportComunicazioniBorse = () => {
  const [tableData, setTableData] = useState([]);
  const [firstRowToDisplay, setFirstRowToDisplay] = useState(0);
  const [rowsForPage, setRowsForPage] = useState(20);
  const [isLoading, setIsLoading] = useState(false);
  const { profile } = useContext(AuthContext);
  
  async function getData() {
    try {
      setIsLoading(true);
      const borseReports = await BorseAPI.getComunicazioniBorseReport();
      const _borseReports = [];
      for (let i = 0; i < borseReports?.length; i++) {
        _borseReports.push({
          ...borseReports[i],
          stato:
          borseReports[i]?.stato === "IN_CORSO"
              ? "IN CORSO"
              : borseReports[i]?.stato
        });
      }
      setTableData(_borseReports);
    } catch (error) {
      console.error("🚀 -> error", error);
      alert("Errore nel caricamento");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, [profile]);

  const title = (
    <h4
      style={{
        lineHeight: "0",
        display: "inline",
        verticalAlign: "bottom",
        lineHeight: "1"
      }}
    >
      Report Comunicazione Richieste Borse
    </h4>
  );

  const cardHeader = <CardHeader color="success">{title}</CardHeader>;

  const tableColumns = [
    { field: "id", header: "ID" },
    { field: "nomeZip", header: "Nome ZIP" },
    { field: "stato", header: "Stato" },
    { field: "dataInizio", header: "Data Inizio" },
    { field: "dataFine", header: "Data Fine" }
  ];

  async function downloadReportComunicazioniBorse(id) {
    try {
      setIsLoading(true);
      await BorseAPI.downloadReportComunicazioniBorse(id);
    } catch (error) {
      console.error("🚀 -> error", error);
      alert("Errore nel caricamento");
    } finally {
      setIsLoading(false);
    }
  }

  function downloadHandler(rowData) {
    const id = rowData?.id;
    downloadReportComunicazioniBorse(id);
  }

  const cardBody = (
    <CardBody>
      <Table
        tableData={tableData}
        firstRowToDisplay={firstRowToDisplay}
        setFirstRowToDisplay={setFirstRowToDisplay}
        rowsForPage={rowsForPage}
        setRowsForPage={setRowsForPage}
        emptyMessage="Nessun Report Da Visualizzare"
        isLoading={isLoading}
        columns={tableColumns}
        downloadHandler={downloadHandler}
      />
    </CardBody>
  );

  const card = (
    <Card>
      {cardHeader}
      {cardBody}
    </Card>
  );

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        {card}
      </GridItem>
    </GridContainer>
  );
};

export default ReportComunicazioniBorse;
