import Utils from "./Utils";
import helpers from "../helpers/Helpers";
import * as AppConstants from "../helpers/AppConstants";

const CaricamentiAPI = {
  getAll: async function () {
    const res = await Utils.CreatePostRequest(`coasco/caricamenti/getall`, {});
    if (res?.message?.includes("200")) {
      helpers.parseDates(res.result, ["datacaricamento"]);
      return res.result;
    } else return null;
  },

  async downloadFileErrori(codice) {
    await Utils.CreatePostRequestDownloadFile(
      AppConstants.SiteUrl + "coasco/caricamenti/download?id=" + codice,
      "errori_" + codice,
      "txt"
    );
  },

  GetVerificaCaricamenti() {
    return [
      {
        id: 1,
        datainizio: "02/04/2020",
        datafine: "25/04/2020",
        codq: "PULI",
        importo: 60.0,
        numrighe: 4224
      },
      {
        id: 2,
        datainizio: "02/04/2020",
        datafine: "25/04/2020",
        codq: "CUST",
        importo: 60.0,
        numrighe: 4224
      },
      {
        id: 3,
        datainizio: "02/04/2020",
        datafine: "25/04/2020",
        codq: "PULI",
        importo: 60.0,
        numrighe: 4224
      },
      {
        id: 4,
        datainizio: "02/04/2020",
        datafine: "25/04/2020",
        codq: "PULI",
        importo: 60.0,
        numrighe: 4224
      },
      {
        id: 5,
        datainizio: "02/04/2020",
        datafine: "25/04/2020",
        codq: "CUST",
        importo: 44460.0,
        numrighe: 4224
      },
      {
        id: 6,
        datainizio: "02/04/2020",
        datafine: "25/04/2020",
        codq: "PULI",
        importo: 55560.0,
        numrighe: 4224
      },
      {
        id: 7,
        datainizio: "02/04/2020",
        datafine: "25/04/2020",
        codq: "PULI",
        importo: 777760.0,
        numrighe: 4224
      },
      {
        id: 8,
        datainizio: "02/04/2020",
        datafine: "25/04/2020",
        codq: "CUST",
        importo: 555560.0,
        numrighe: 4224
      },
      {
        id: 9,
        datainizio: "02/04/2020",
        datafine: "25/04/2020",
        codq: "PULI",
        importo: 6998640.0,
        numrighe: 4224
      },
      {
        id: 10,
        datainizio: "02/04/2020",
        datafine: "25/04/2020",
        codq: "PULI",
        importo: 69786740.0,
        numrighe: 4224
      },
      {
        id: 11,
        datainizio: "02/04/2020",
        datafine: "25/04/2020",
        codq: "CUST",
        importo: 60.0,
        numrighe: 4224
      },
      {
        id: 12,
        datainizio: "02/04/2020",
        datafine: "25/04/2020",
        codq: "PULI",
        importo: 60.0,
        numrighe: 4224
      }
    ];
  },

  UploadOnPreCarXCarichiF24: async function (obj) {
    const url = "coasco/scarichif24/update";
    return await Utils.CreatePutRequest(url, obj);
  },

  GetAnni() {
    let res = [];
    for (let x = 1900; x < new Date().getFullYear() + 20; x++)
      res.push(x.toString());
    return res;
  },

  getYearsFromNow() {
    let res = [];
    for (let x = new Date().getFullYear(); x >= 1900; x--)
      res.push(x.toString());
    return res;
  },

  AddCaricamenti: async function (file) {
    return await Utils.CreatePostRequestWithFile(
      "coasco/corrispondenza/insert",
      null,
      file
    );
  },

  UpdateCaricamenti: async function () {
    return 1;
  }
};
export default CaricamentiAPI;
