import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Switch from "@material-ui/core/Switch";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import helpers from "helpers/Helpers";
import CassaAPI from "API/CassaAPI";
import moment from "moment";
import {dateTimeFmt, rowsPerPageOptions} from "helpers/AppConstants";
import { dateFmt } from "helpers/AppConstants";

class RicercaSuCassa extends React.Component {
  state = {
    rows: [],
    selectedGrid: null,
    loadingGrid: false,
    totalRecords: 0,
    lastPage: 0,
    showFilters: true,
    page: {
      first: 0,
      page: 0,
      rows: 10
    },
    filters: {},
    sort: {
      sortField: 'sede',
      sortOrder: 1
    }
  };

  constructor(props) {
    super(props);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.onFilter = this.onFilter.bind(this);
  }

  async onSort(event) {
    this.setState({sort: event}, async () => await this.refreshGrid());
  }

  toQueryString(filters, sort) {
    const queryString = '&sortfield=' + sort.sortField + '&sortorder=' + (sort.sortOrder === 1 ? 'ASC' : 'DESC');
    return Object.keys(filters).map(key => `${key}=${filters[key].value}`).join('&') + queryString;
  }

  async onFilter(event) {
    this.setState({filters: event.filters}, async () => await this.refreshGrid());
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  async refreshGrid() {

    this.setState({
      loadingGrid: true
    });

    const qs = this.toQueryString(this.state.filters, this.state.sort);
    const rows = await CassaAPI.SearchFQCassa(this.state.page.first, this.state.page.rows, qs);

    if (!rows) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Il server non ha risposto alla richiesta."
      );
    } else {
      this.setState({
        rows: rows.result,
        totalRecords: rows.tot,
        loadingGrid: false
      });
    }
  }

  async ChangeFilterGrid(e) {
    this.setState({
      showFilters: e.target.checked,
      filters: {}
    }, async () => await this.refreshGrid());
  }

  handleTableClick(e) {
    this.setState({
      selectedGrid: e.value
    });
  }

  async onPage(event) {
    this.setState({page: event}, async () => await this.refreshGrid());
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Ricerca su Cassa
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.showFilters}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  first={this.state.page.first}
                  lazy
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.page.rows} rowsPerPageOptions={rowsPerPageOptions}
                  onPage={this.onPage}
                  totalRecords={this.state.totalRecords}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  loading={this.state.loadingGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                  onFilter={this.onFilter}
                  filters={this.state.filters}
                  onSort={this.onSort}
                  sortField={this.state.sort.sortField}
                  sortOrder={this.state.sort.sortOrder}
                >
                  <Column
                    field="codQuota"
                    header="Cod. Quota"
                    sortable={true}
                    filter={this.state.showFilters}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "130px" }}
                  />
                  <Column
                    field="sede"
                    header="Sede"
                    sortable={true}
                    filter={this.state.showFilters}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "100px" }}
                  />
                  <Column
                    field="periodo"
                    header="Periodo"
                    sortable={true}
                    filter={this.state.showFilters}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "120px" }}
                  />
                  <Column
                    field="banca"
                    header="Banca"
                    sortable={true}
                    filter={this.state.showFilters}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "100px" }}
                  />
                  <Column
                    field="nettoAccr"
                    header="Netto Accreditato"
                    body={(rowData) =>
                      helpers.DecimalDTTemplate(rowData, "nettoAccr")
                    }
                    sortable={true}
                    filter={this.state.showFilters}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "190px" }}
                  />
                  <Column
                    field="dataInc"
                    header="Data Incasso"
                    sortable={true}
                    filter={this.state.showFilters}
                    body={(row) =>
                      row.dataInc
                        ? moment(row.dataInc, dateTimeFmt).format(dateFmt)
                        : null
                    }
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    headerStyle={{ width: "150px" }}
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default RicercaSuCassa;
