import Utils from "./Utils";
import Helpers from "helpers/Helpers.js";
import * as AppConstants from "../helpers/AppConstants";
import helpers from "helpers/Helpers.js";

const partialURL = "coasco/cassa";
const baseURL = AppConstants.SiteUrl + partialURL;

const CassaAPI = {

  GetFQCassa: async function (start, size) {
    return await Utils.CreatePostRequest(
      `${partialURL}/getAll?start=${start}&size=${size}`
    );
  },

  GetCassaBySedePeriodoQuota: async function (sede, periodo, codice) {
    let response = await Utils.CreatePostRequest(
      "coasco/cassa/getBySedePeriodoQuota" +
        "?sede=" +
        sede +
        "&periodo=" +
        periodo +
        "&quota=" +
        codice
    );
    if (response?.result) {
      helpers.formatCurrency(response.result, "nettoAccr");
      return response;
    } else return null;
  },

  SearchFQCassa: async function (start, size, filters) {
    let res = await Utils.SendPostRequestTo(
      `${baseURL}/getByFilter?start=${start}&size=${size}&${filters}`
    );
    helpers.formatCurrency(res.result, "nettoAccr");
    return res;
  },

  GetAssociaPagamentiForAllineaCassa: async function () {
    const result = await Utils.CreatePostRequest("coasco/allineacassa/getall");
    helpers.formatCurrency(result.result, "importo", "accr", "diff");
    return result.result;
  },

  banche: async function () {
    const result = await Utils.CreatePostRequest(
      "coasco/banche/getall?start=1&size=100"
    );

    return result.result;
  },

  AddCassa: async function (cassaobj, bank, dataInc) {
    let data = Helpers.ConvertToItalianDateForPrime(dataInc);
    let appoggio = data.split("/");
    data = appoggio[1] + "/" + appoggio[0] + "/" + appoggio[2] + " 00:00:00";

    return await Utils.CreatePostRequest(
      "coasco/allineacassa/loadoncassa" + "?bank=" + bank + "&dataInc=" + data,
      cassaobj
    );
  },

  deleteCassa: async function (id) {
    return await Utils.CreateDeleteRequest("coasco/cassa/delete?id=" + id);
  },

  GetEmptyCassa: async function () {
    return { id: 0, banca: null, nettoAccr: null, dataInc: null };
  },

  insertCassa: async function (obj) {
    return await Utils.CreatePostRequest("coasco/cassa/insert", obj);
  },

  updateCassa: async function (obj) {
    return await Utils.CreatePutRequest("coasco/cassa/update", obj);
  }
};
export default CassaAPI;
