import Utils from "./Utils";
import moment from "moment";
import { dateFmt } from "../helpers/AppConstants";

const ReportAPI = {
  getReports: async function (tipo) {
    const res = await Utils.CreatePostRequest("ebinprof/report/getByTipo?tipo=" + tipo);
    return res?.result;
  },

  getReport: async function (id) {
    const res = await Utils.CreatePostRequest(
      `ebinprof/report/getById?id=${id}`
    );
    return res?.result;
  },

  GetRiepilogoXAnno: async function (sede, cod_quota, anno) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/riepilogoXAnno?sede=" +
        sede +
        "&anno=" +
        anno +
        "&cod_quota=" +
        cod_quota.name
    );
  },

  GetCASPO: async function (sede, cod_quota, meseAnno) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/caspo?sede=" +
        sede +
        "&meseAnno=" +
        meseAnno +
        "&cod_quota=" +
        cod_quota.name
    );
  },

  GetQIncassiXProvincia: async function (anno) {
    const response = await Utils.CreatePostRequest(
      "coasco/report/qincassixprovincia?anno=" + anno
    );
    if (response?.message?.includes("200")) {
      return response.result;
    } else return null;
  },

  GetSituazioneXSede: async function (
    selectedCodice,
    MesseContInizio,
    MesseContFine
  ) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/situazionexsede?cod_quota=" +
        selectedCodice.name +
        "&meseAnnoInizio=" +
        MesseContInizio +
        "&meseAnnoFine=" +
        MesseContFine
    );
  },

  GetSituazioneXProvincia: async function (
    selectedCodice,
    MesseContInizio,
    MesseContFine
  ) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/situazionexprovincia?cod_quota=" +
        selectedCodice.name +
        "&meseAnnoInizio=" +
        MesseContInizio +
        "&meseAnnoFine=" +
        MesseContFine
    );
  },

  GetIncassiRiepilogo: async function (annoInizio, annoFine) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/incassiriepilogo?annoInizio=" +
        annoInizio +
        "&annoFine=" +
        annoFine
    );
  },

  GetIncassiScarichiRiepilogo: async function (annoInizio, annoFine) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/incassiscarichiriepilogo?annoInizio=" +
        annoInizio +
        "&annoFine=" +
        annoFine
    );
  },

  GetResocontoCorrisp: async function (annoInizio) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/resocontocorrisp?annoInizio=" + annoInizio
    );
  },

  GetRegistroProtocollo: async function (annoInizio) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/registroProtocollo?annoInizio=" + annoInizio
    );
  },

  compXAnno95_10: async function (cod_quota) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/compXAnno95_10?cod_quota=" + cod_quota
    );
  },

  compXAnno10_20: async function (cod_quota) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/compXAnno10_20?cod_quota=" + cod_quota
    );
  },

  compXProv95_10: async function (cod_quota) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/compXProv95_10?cod_quota=" + cod_quota
    );
  },

  compXProv10_20: async function (cod_quota) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/compXProv10_20?cod_quota=" + cod_quota
    );
  },

  incassiDettaglio: async function (dataInizio, dataFine, banca) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/incassiDettaglio?dataInizio=" +
        moment(dataInizio).format(dateFmt) +
        "&dataFine=" +
        moment(dataFine).format(dateFmt) +
        "&banca=" +
        banca
    );
  },

  incassiScarichiDettaglio: async function (dataInizio, dataFine, banca) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/incassiScarichiDettaglio?dataInizio=" +
        moment(dataInizio).format(dateFmt) +
        "&dataFine=" +
        moment(dataFine).format(dateFmt) +
        "&banca=" +
        banca
    );
  },

  fattureDettaglio: async function (dataInizio, dataFine) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/fattureDettaglio?dataInizio=" +
        moment(dataInizio).format(dateFmt) +
        "&dataFine=" +
        moment(dataFine).format(dateFmt)
    );
  },

  fattureScarichiDettaglio: async function (dataInizio, dataFine) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/fattureScarichiDettaglio?dataInizio=" +
        moment(dataInizio).format(dateFmt) +
        "&dataFine=" +
        moment(dataFine).format(dateFmt)
    );
  },

  periodiContributivi: async function (
    sede,
    cod_quota,
    causale,
    competenzaInizio,
    competenzaFine
  ) {
    await Utils.CreatePostRequestDownloadReport(
      "coasco/report/periodiContributivi?sede=" +
        sede.sap +
        "&cod_quota=" +
        cod_quota.name +
        "&causale=" +
        causale.codice +
        "&competenzaInizio=" +
        competenzaInizio +
        "&competenzaFine=" +
        competenzaFine
    );
  }
};

export default ReportAPI;
