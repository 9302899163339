import React, { Component } from "react";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import { Button } from "primereact/button";
import queryString from "query-string";
import AziendeAPI from "API/AziendeAPI";
import DipendentiAPI from "API/DipendentiAPI";
export class RDLHeader extends Component {
  state = {
    azienda: {},
    dipendenti: {}
  };

  constructor(props) {
    super(props);

    this.backButtonClick = this.backButtonClick.bind(this);
  }

  async componentDidMount() {
    var aziendaId = this.props.match.params.aziendaId;
    var aziendaById =
      aziendaId !== 0 && (await AziendeAPI.GetAziendaById(aziendaId));
    var dipendenteId = this.props.match.params.dipendenteId;
    var dipendentiById = await DipendentiAPI.GetDipendentiById(dipendenteId);

    this.setState({
      azienda: aziendaById[0],
      dipendenti: dipendentiById[0]
    });
  }

  backButtonClick() {
    let aziendaId = this.props.match.params.aziendaId;
    let qS = queryString.parse(this.props.history.location.search);
    let maintainSearch = "?maintainSearch=1";
    if (!qS.type) {
      if (aziendaId > 0) this.props.history.push("/admin/aziende/" + aziendaId);
      else if (aziendaId == 0)
        this.props.history.push(
          "/admin/dipendenti/" +
            this.props.match.params.dipendenteId +
            maintainSearch
        );
    } else if (qS.type === "fromVersamenti")
      this.props.history.push(
        "/admin/DipendentiXAzienda/" +
          aziendaId +
          "?type=" +
          qS.type +
          "&typeId=" +
          this.props.match.params.dipendenteId
      );
    else if (qS.type === "fromModificaRichieste")
      this.props.history.push(
        "/admin/DipendentiXAzienda/" +
          aziendaId +
          "?type=" +
          qS.type +
          "&typeId=" +
          qS.typeId
      );
    else if (qS.type === "fromRichieste")
      this.props.history.push(
        "/admin/DipendentiXAzienda/" +
          aziendaId +
          "?type=" +
          qS.type +
          "&typeId=" +
          qS.typeId
      );
    else if (qS.type === "fromUniemens")
      this.props.history.push(
        "/admin/UniemensXAzienda/" + aziendaId + "?type=fromVersamenti&open=1"
      );
    else if (qS.type === "fromModificaDipendente")
      this.props.history.push("/admin/ModificaDipendente/" + this.props.match.params.dipendenteId);
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Button
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />

                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Dipendente
                </h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Nominativo"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.dipendenti.nominativo
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Codice Fiscale"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.dipendenti.cf
                      }}
                    />
                  </GridItem>
                  {this.state.azienda && (
                    <>
                      <GridItem xs={3}>
                        <CustomInput
                          labelText="Matricola"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.azienda.matricola
                          }}
                        />
                      </GridItem>
                      <GridItem xs={3}>
                        <CustomInput
                          labelText="Ragione Sociale"
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            disabled: true,
                            value: this.state.azienda.denominazione
                          }}
                        />
                      </GridItem>
                    </>
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
