import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import ButtonsMUI from "@material-ui/core/Button";
import Clear from "@material-ui/icons/Clear";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Search from "@material-ui/icons/Search";
import YearPicker from "components/YearPicker/index";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Dropdown } from "primereact/dropdown";
import StatisticheAPI from "../../API/StatisticheAPI";
import Utils from "../../API/Utils";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";

const List = [
  { id: 1, name: "Attive per anno/mese Costituz." },
  { id: 2, name: "Cessate per anno/mese Cessaz." },
  { id: 3, name: "Raggruppate per Tipo di Cessaz." },
  { id: 4, name: "Attive per Provincia" },
  { id: 5, name: "Cessate per Provincia" },
  { id: 6, name: "Attive con Consulenti per Provincia" },
  { id: 7, name: "Attive con Amministr. per Provincia" },
  { id: 8, name: "Attive per Prov. con Amministr. con Email" },
  { id: 9, name: "Attive per Prov. con Consulente con Email" },
  { id: 10, name: "Mesi di versamenti Pulitori" },
  { id: 11, name: "Mesi di versamenti Custodi" },
  { id: 12, name: "Aziende che hanno versato su Custodi O Pulitori (Anno)" },
  { id: 13, name: "Aziende che hanno versato su Custodi E Pulitori (Anno)" },
  { id: 14, name: "Separate per CodQuota (Anno)" },
  { id: 15, name: "Rimborsi per Versamenti in Ecceso per Comp. (Anno)" },
  { id: 16, name: "MIA pervenuti per Provincia" },
  { id: 17, name: "Attive per Numero RDL Attivi" }
];

class AziendeXStatistiche extends Component {

  state = {
    rows: [],
    columns: [],
    filterGrid: null,
    selectedGrid: null,
    anno: new Date().getFullYear(),
    loadingGrid: false,
    radioChecked: null,
    selectedRadioButton: null,
    isVisible: false,
    sortable: false,
    rowsPerPage: 6,
    loadingPage: false
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.IncreaseYear = this.IncreaseYear.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.DecreaseYear = this.DecreaseYear.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.RadioHandleChange = this.RadioHandleChange.bind(this);
    this.onClickEsportaDettaglio = this.onClickEsportaDettaglio.bind(this);
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  async handleSearchClick() {
    this.setState({selectedGrid: null});
    await this.refreshGrid();
  }

  async handleDateChange(dateParam) {
    this.setState({
      anno: dateParam
    });
  }

  IncreaseYear() {
    this.setState(
      {
        anno: this.state.anno + 1
      },
      async () => await this.refreshGrid()
    );
  }

  DecreaseYear() {
    this.setState(
      {
        anno: this.state.anno - 1
      },
      async () => await this.refreshGrid()
    );
  }

  async handleClearClick() {
    this.setState({
      isVisible: false,
      selectedRadioButton: null,
      radioChecked: null,
      rows: [],
      columns: [],
      selectedGrid: null,
      sortable: false,
      anno: new Date().getFullYear()
    });
  }

  async componentDidMount() {
    this.setState({
      rows: []
    });
  }

  HandleDropDownChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  RadioHandleChange(e, val) {
    this.setState({
      [e]: val
    });

    if (val !== null)
      this.setState({
        radioChecked: val.id,
        isVisible:
          val.id === 12 || val.id === 13 || val.id === 14 || val.id === 15
      });
  }

  async refreshGrid() {

    this.setState({
      loadingGrid: true
    });

    if (this.state.selectedRadioButton !== null) {

      this.setState({
        sortable: true
      });

      switch (this.state.selectedRadioButton.id) {
        case 1:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.aziende.AttiveXAnnoMeseCostituz"),
            columns: [{field: "count", header: "Count"}, { field: "data_costituzione", header: "Data Costituzione" }]
          });
          break;
        case 2:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.aziende.CessateXAnnoMeseCessaz"),
            columns: [{field: "count", header: "Count"}, { field: "data_cessazione", header: "Data Cessazione" }]
          });
          break;
        case 3:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.aziende.XTipoCessaz"),
            columns: [{field: "count", header: "Count"}, { field: "tipo_cessazione", header: "Tipo Cessazione" }]
          });
          break;

        case 4:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.aziende.AttiveXProvincia"),
            columns: [{field: "count", header: "Count"}, { field: "prov", header: "Provincia" }, {field: "regione", header: "Regione"}]
          });
          break;
        case 5:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.aziende.CessateXProvincia"),
            columns: [{field: "count", header: "Count"}, { field: "prov", header: "Provincia" }, {field: "regione", header: "Regione"}]
          });
          break;

        case 6:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.aziende.AttiveConConsXProvincia"),
            columns: [{field: "count", header: "Count"}, { field: "prov", header: "Provincia" }, {field: "regione", header: "Regione"}]
          });
          break;
        case 7:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.aziende.AttiveConAmmXProvincia"),
            columns: [{field: "count", header: "Count"}, { field: "prov", header: "Provincia" }, {field: "regione", header: "Regione"}]
          });
          break;
        case 8:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.aziende.AttiveXProvConAmmEmail"),
            columns: [{field: "count", header: "Count"}, { field: "prov", header: "Provincia" }, {field: "regione", header: "Regione"}]
          });
          break;
        case 9:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.aziende.AttiveXProvConConsEmail"),
            columns: [{field: "count", header: "Count"}, { field: "prov", header: "Provincia" }, {field: "regione", header: "Regione"}]
          });
          break;
        case 10:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.aziende.MesiVersamentiPuli"),
            columns: [{field: "count", header: "Count"}, { field: "mesi_tot", header: "Mesi Totali" }, {field: "anni", header: "Anno"}, {field: "mesi", header: "Mesi"}]
          });
          break;
        case 11:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.aziende.MesiVersamentiCust"),
            columns: [{field: "count", header: "Count"}, { field: "mesi_tot", header: "Mesi Totali" }, {field: "anni", header: "Anno"}, {field: "mesi", header: "Mesi"}]
          });
          break;
        case 12:
          this.setState({
            rows: await StatisticheAPI.getAnno("statistiche.aziende.XVersamentoCustOPuli", this.state.anno),
            columns: [{field: "count", header: "Count"}]
          });
          break;
        case 13:
          this.setState({
            rows: await StatisticheAPI.getAnno("statistiche.aziende.XVersamentoCustEPuli", this.state.anno),
            columns: [{field: "count", header: "Count"}]
          });
          break;
        case 14:
          this.setState({
            rows: await StatisticheAPI.getAnno("statistiche.aziende.XCodQuota", this.state.anno),
            columns: [{ field: "count", header: "Count" }, { field: "cod_quota", header: "Codice Quota" }]
          });
          break;
        case 15:
          this.setState({
            rows: await StatisticheAPI.getAnno("statistiche.aziende.RimborsiXVersamenti", this.state.anno),
            columns: [
              { field: "count", header: "Count" },
              { field: "competenza", header: "Competenza" },
              { field: "importo", header: "Importo" }
            ]
          });
          break;
        case 16:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.aziende.PervenutiXProvincia"),
            columns: [{ field: "count", header: "Count" },{ field: "prov", header: "Provincia" },{ field: "regione", header: "Regione" }]
          });
          break;
        case 17:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.aziende.AttiveXNumRdlAttivi"),
            columns: [{ field: "count", header: "Count" }, { field: "num_dipendenti", header: "Numero Dipendenti" }]
          });
          break;
        default:
          this.setState({
            columns: [{ field: "", header: "" }]
          });
      }
    }

    this.setState({
      loadingGrid: false
    });
  }

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: e.value
    });
  };

  async onClickEsportaDettaglio() {
    if (this.state.selectedGrid) {
      let params = [];
      let queryName;
      switch (this.state.selectedRadioButton.id) {
        case 1:
          params.push(this.state.selectedGrid.data_costituzione);
          queryName = "statistiche.aziende.AttiveXAnnoMeseCostituzDettaglio";
          break;
        case 2:
          params.push(this.state.selectedGrid.data_cessazione);
          queryName = "statistiche.aziende.CessateXAnnoMeseCessazDettaglio";
          break;
        case 3:
          params.push(this.state.selectedGrid.tipo_cessazione);
          queryName = "statistiche.aziende.XTipoCessazDettaglio";
          break;
        case 4:
          params.push(this.state.selectedGrid.prov);
          queryName = "statistiche.aziende.AttiveXProvinciaDettaglio";
          break;
        case 5:
          params.push(this.state.selectedGrid.prov);
          queryName = "statistiche.aziende.CessateXProvinciaDettaglio";
          break;
        case 6:
          params.push(this.state.selectedGrid.prov);
          queryName = "statistiche.aziende.AttiveConConsXProvinciaDettaglio";
          break;
        case 7:
          params.push(this.state.selectedGrid.prov);
          queryName = "statistiche.aziende.AttiveConAmmXProvinciaDettaglio";
          break;
        case 8:
          params.push(this.state.selectedGrid.prov);
          queryName = "statistiche.aziende.AttiveXProvConAmmEmailDettaglio";
          break;
        case 9:
          params.push(this.state.selectedGrid.prov);
          queryName = "statistiche.aziende.AttiveXProvConConsEmailDettaglio";
          break;
        case 10:
          params.push(this.state.selectedGrid.mesi_tot);
          queryName = "statistiche.aziende.MesiVersamentiPuliDettaglio";
          break;
        case 11:
          params.push(this.state.selectedGrid.mesi_tot);
          queryName = "statistiche.aziende.MesiVersamentiCustDettaglio";
          break;
        case 12:
          params.push(this.state.anno);
          queryName = "statistiche.aziende.XVersamentoCustOPuliDettaglio";
          break;
        case 13:
          params.push(this.state.anno);
          queryName = "statistiche.aziende.XVersamentoCustEPuliDettaglio";
          break;
        case 14:
          params.push(this.state.selectedGrid.cod_quota, this.state.anno);
          queryName = "statistiche.aziende.XCodQuotaDettaglio";
          break;
        case 15:
          params.push(this.state.selectedGrid.competenza);
          queryName = "statistiche.aziende.RimborsiXVersamentiDettaglio";
          break;
        case 16:
          params.push(this.state.selectedGrid.prov);
          queryName = "statistiche.aziende.PervenutiXProvinciaDettaglio";
          break;
        case 17:
          params.push(this.state.selectedGrid.num_dipendenti);
          queryName = "statistiche.aziende.AttiveXNumRdlAttiviDettaglio";
          break;
      }

      this.setState(
          {loadingPage: true},
          async () => {
            await StatisticheAPI.getDettaglio(queryName, params);
            this.setState({loadingPage: false});
          }
      );
    }
  }

  render() {
    const dynamicColumns = this.state.columns.map((col) => {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          sortable={this.state.sortable}
          filter={this.state.filterGrid}
        />
      );
    });
    return (
      <>
        {this.state.loadingPage && (<LoaderSpinner />)}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4>Statistiche Aziende</h4>
              </CardHeader>
              <CardBody
                style={{
                  paddingTop: "8px",
                  paddingBottom: "6px"
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6} style={{ marginTop: "8px" }}>
                    <Dropdown
                      display="chip"
                      optionLabel="name"
                      placeholder="Cerca per tipo"
                      maxSelectedLabels={1}
                      value={this.state.selectedRadioButton}
                      options={List}
                      onChange={(e) =>
                        this.RadioHandleChange("selectedRadioButton", e.value)
                      }
                    />
                  </GridItem>

                  {this.state.isVisible && (
                    <GridItem style={{ marginTop: "15px", zIndex: "9000" }}>
                      <div
                        className="float-right date"
                        style={{
                          verticalAlign: "top",
                          lineHeight: "0",
                          marginRight: "5px",
                          display: "inline-flex"
                        }}
                      >
                        <SkipPreviousIcon
                          onClick={this.DecreaseYear}
                          className="slide"
                        />
                        <YearPicker
                          onChange={this.handleDateChange}
                          value={this.state.anno}
                          className="date"
                        />
                        <SkipNextIcon
                          onClick={this.IncreaseYear}
                          className="slide"
                        />
                      </div>
                    </GridItem>
                  )}

                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Cerca"
                    placement="top"
                    style={{ marginTop: "5px" }}
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleSearchClick}
                    >
                      <Search />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    arrow
                    style={{ marginLeft: "4px", marginTop: "5px" }}
                    id="tooltip-top"
                    title="Reimposta"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleClearClick}
                    >
                      <Clear />
                    </Button>
                  </Tooltip>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className="d-inline">
                  Elaborazioni Statistiche per Aziende
                </h4>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  size="small"
                  aria-label="contained primary button group"
                  className="float-right m-1"
                >
                  <ButtonsMUI onClick={() => Utils.ExportExcel(this.state.rows)}>Export</ButtonsMUI>
                  {this.state.selectedGrid &&
                    <ButtonsMUI onClick={this.onClickEsportaDettaglio}>Esporta dettaglio</ButtonsMUI>
                  }
                </ButtonGroup>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage}
                  loading={this.state.loadingGrid}
                  rowsPerPageOptions={[6, 12, 24]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  {dynamicColumns}
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default AziendeXStatistiche;
