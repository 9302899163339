import React from "react";
import queryString from "query-string";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ModificaAzienda from "components/Views/Aziende/ModificaAzienda.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import helpers from "helpers/Helpers";
import Button from "components/CustomButtons/Button.js";
import { Button as BackButton } from "primereact/button";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { AutoComplete } from "primereact/autocomplete";
import LoaderSpinner from "../../../src/components/Layout/LoaderSpinner.js";
import AziendeAPI from "../../API/AziendeAPI.js";
import { rowsPerPageOptions } from "../../helpers/AppConstants";

class Aziende extends React.Component {
  state = {
    filteredRows: [],
    totalRecords: 0,
    selectedAzienda: null,
    selectedGridAzienda: null,
    filterGrid: false,
    loadingGrid: false,
    hideHeader: false,
    firstRowToDisplay: 0,
    lastPage: 0,
    sortField: "denominazione",
    sortOrder: 1,
    visibleLoader: false,
    filteredMatricolaListByInput: null,
    filteredRagioneListByInput: null,
    filterByRagioneSociale: "",
    filterByMatricola: "",
    rowsPerPage: 10
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.searchMatricola = this.searchMatricola.bind(this);
    this.btnSearchRef = React.createRef();
    this.searchRagione = this.searchRagione.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.LoadElementsInGrid = this.LoadElementsInGrid.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
  }

  async onNuovoClick() {
    this.setState({
      visibleLoader: true
    });

    const nuovoazienda = await AziendeAPI.GetNuovoAziendaById();
    this.setState({
      selectedGridAzienda: null,
      selectedAzienda: nuovoazienda
    });

    this.setState({
      visibleLoader: false
    });
  }

  async onCloseGridClick() {
    this.setState({
      selectedGridAzienda: null,
      selectedAzienda: null
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  HandleSaveClick = async (RowItem) => {
    if (RowItem.id === null) {
      const nuovaAzienda = await AziendeAPI.AddAzienda(RowItem);
      this.state.selectedAzienda = nuovaAzienda.result[0];
    } else {
      await AziendeAPI.UpdateAzienda(RowItem);
    }
    this.props.addAlert.current.addAlert("success", "Dati salvati.");
    await this.LoadElementsInGrid();
    return true;
  };

  handleTableClick = async (e) => {
    this.setState({
      visibleLoader: true
    });

    const azienda = await AziendeAPI.GetAziendaById(e.value.id);

    this.setState({
      selectedAzienda: azienda[0],
      selectedGridAzienda: e.value
    });

    this.setState({
      visibleLoader: false
    });
  };

  searchRagione(event) {
    setTimeout(async () => {
      const list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByRagione(event.query)
          : [];

      this.setState({
        filteredRagioneListByInput: list
      });
    }, 250);
  }

  searchMatricola(event) {
    setTimeout(async () => {
      const list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByMatr(event.query)
          : [];
      this.setState({
        filteredMatricolaListByInput: list
      });
    }, 250);
  }

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  HandleChangeMaxLength(e, val) {
    let test = val.toString();
    if (test.length <= 10) {
      this.setState({
        [e]: val
      });
    }
  }

  handleSearchClick = async () => {
    helpers.SaveSearchFilters(
      {
        filterByRagioneSociale: this.state.filterByRagioneSociale,
        filterByMatricola: this.state.filterByMatricola
      },
      "AziendeList"
    );

    this.setState({
      selectedAzienda: null,
      selectedGridAzienda: null
    });

    await this.LoadElementsInGrid();
  };

  async handleClearClick() {
    helpers.SaveSearchFilters(null, "AziendeList");
    helpers.SaveSearchFilters(null, "AziendeListOrder");
    this.setState({
      loadingGrid: true
    });
    this.setState(
      {
        selectedAzienda: null,
        selectedGridAzienda: null,

        filteredMatricolaListByInput: null,
        filteredRagioneListByInput: null,

        filterByMatricola: "",
        filterByRagioneSociale: ""
      },
      async () => {
        await this.LoadElementsInGrid();
      }
    );
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });

    let filterByRagioneSociale = this.state.filterByRagioneSociale;
    let filterByMatricola = this.state.filterByMatricola;

    let sortField = "matricola";
    let sortOrder = 1;

    const queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      const savedFilters = helpers.GetSearchFilters("AziendeList");
      if (savedFilters !== null) {
        filterByRagioneSociale = savedFilters.filterByRagioneSociale;
        filterByMatricola = savedFilters.filterByMatricola;
      }

      const savedOrders = helpers.GetSearchFilters("AziendeListOrder");
      if (savedOrders !== null) {
        sortField = savedOrders.sortField;
        sortOrder = savedOrders.sortOrder;
      }
    } else {
      helpers.SaveSearchFilters(null, "AziendeListOrder");
      helpers.SaveSearchFilters(null, "AziendeList");
    }
    const responseObject = await AziendeAPI.SearchPageSort(
      filterByMatricola,
      filterByRagioneSociale,
      0,
      10,
      sortField,
      sortOrder
    );
    const filteredRowsInitial = responseObject.result;

    this.setState(
      {
        filteredRows: filteredRowsInitial,
        totalRecords: responseObject.tot,
        filterByMatricola: filterByMatricola,
        filterByRagioneSociale: filterByRagioneSociale,
        sortField: sortField,
        sortOrder: sortOrder
      },
      async () => {
        let filteredRows = filteredRowsInitial;

        let selectedRow = null;

        const aziendaId = this.props.match.params.aziendaId;
        if (aziendaId > 0) {
          selectedRow = (await AziendeAPI.GetAziendaById(aziendaId))[0];
        }

        this.setState({
          firstRowToDisplay: 0,
          filteredRows: filteredRows
        });

        if (selectedRow)
          await this.handleTableClick({
            value: selectedRow
          });
      }
    );

    this.setState({
      loadingGrid: false
    });
  }

  async LoadElementsInGrid() {
    this.setState({ loadingGrid: false });

    const responseObject = await AziendeAPI.SearchPageSort(
      this.state.filterByMatricola,
      this.state.filterByRagioneSociale,
      this.state.firstRowToDisplay,
      this.state.rowsPerPage,
      this.state.sortField,
      this.state.sortOrder
    );

    this.setState({
      filteredRows: responseObject.result,
      totalRecords: responseObject.tot,
      loadingGrid: false
    });
  }

  async onSortChange(e) {
    helpers.SaveSearchFilters(
      {
        sortField: e.sortField,
        sortOrder: e.sortOrder
      },
      "AziendeListOrder"
    );

    this.setState(
      {
        sortField: e.sortField,
        sortOrder: e.sortOrder,
        loadingGrid: true
      },
      async () => {
        await this.LoadElementsInGrid();
      }
    );
  }

  async onSelectMatricola(e) {
    const azienda = await AziendeAPI.GetAziendaByMatr(e.value);
    this.setState({ selectedAzienda: azienda });
  }

  async onPageChange(e) {
    this.setState(
      {
        firstRowToDisplay: e.first,
        rowsPerPage: e.rows
      },
      async () => await this.LoadElementsInGrid()
    );
  }

  async backButtonClick() {
    const qS = queryString.parse(this.props.location.search);
    if (!qS.type) await this.handleClearClick();
    else if (qS.type === "fromFiltroGenerico")
      this.props.history.push("/admin/filtrogenerico?maintainSearch=1");
  }

  render() {
    return (
      <>
        {this.state.visibleLoader && <LoaderSpinner fullHeight={true} />}

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <BackButton
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />

                <h4 style={{ lineHeight: "1" }}>Aziende</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={3} style={{ marginTop: "6px" }}>
                    <AutoComplete
                      value={this.state.filterByMatricola}
                      suggestions={this.state.filteredMatricolaListByInput}
                      placeholder="Cerca per Matricola"
                      completeMethod={this.searchMatricola}
                      onChange={(e) =>
                        this.HandleChangeMaxLength("filterByMatricola", e.value)
                      }
                      className="matricolaAutocomplete"
                      type="number"
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          this.handleSearchClick();
                        }
                      }}
                      onSelect={(e) => this.onSelectMatricola(e)}
                    />
                  </GridItem>

                  <GridItem xs={3} style={{ marginTop: "6px" }}>
                    <AutoComplete
                      value={this.state.filterByRagioneSociale}
                      suggestions={this.state.filteredRagioneListByInput}
                      completeMethod={this.searchRagione}
                      placeholder="Cerca per Ragione Sociale"
                      onChange={(e) =>
                        this.HandleChange("filterByRagioneSociale", e.value)
                      }
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.handleSearchClick();
                        }
                      }}
                    />
                  </GridItem>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Cerca"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      ref={this.btnSearchRef}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleSearchClick}
                    >
                      <Search />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    arrow
                    style={{ marginLeft: "4px" }}
                    id="tooltip-top"
                    title="Reimposta"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleClearClick}
                    >
                      <Clear />
                    </Button>
                  </Tooltip>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Elenco Aziende
                </h4>
                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inseriscinuova azienda"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.filteredRows}
                  dataKey="id"
                  first={this.state.firstRowToDisplay}
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  onPage={this.onPageChange}
                  onSort={this.onSortChange}
                  sortField={this.state.sortField}
                  sortOrder={this.state.sortOrder}
                  lazy
                  totalRecords={this.state.totalRecords}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGridAzienda}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="matricola"
                    header="Matricola"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    style={{ width: "400px" }}
                  />
                  <Column
                    field="denominazione"
                    header="Ragione Sociale"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.selectedAzienda && (
          <ModificaAzienda
            dataItem={this.state.selectedAzienda}
            onSaveClick={this.HandleSaveClick}
            {...this.props}
            hideHeader={this.state.hideHeader}
            onAnnullaClick={this.onCloseGridClick}
          />
        )}
      </>
    );
  }
}

export default Aziende;
