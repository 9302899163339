import Utils from "./Utils";
import moment from "moment";
import { dateFmt, SiteUrl as siteUrl } from "../helpers/AppConstants";
import Helpers from "../helpers/Helpers";
import { getToken } from "common";

const AnomalieAPI = {
  tipoOkAnomalie: {
    0: "Attiva",
    1: "OKSys",
    2: "OKMan",
    3: "Annull"
  },

  manipolaDate(result) {
    result.forEach((x) => {
      if (x.dataRilevaz !== null)
        x.dataRilevaz = moment(x.dataRilevaz, dateFmt).toDate();
      if (x.dataComunic !== null)
        x.dataComunic = moment(x.dataComunic, dateFmt).toDate();
      if (x.dataOk !== null) x.dataOk = moment(x.dataOk, dateFmt).toDate();
      x.tipo_ok += " - " + this.tipoOkAnomalie[x.tipo_ok];
    });
  },

  filterResultByMatricola(matricola, result) {
    matricola = matricola.toString().toLowerCase();
    return result.filter(
      (row) => row.matricola.toLowerCase().indexOf(matricola) >= 0
    );
  },

  async GetAnomalieByFilter(filters) {
    let partialUrl = "coasco/anomalie/getAnomaliebyFilter?";
    for (const [key, value] of Object.entries(filters))
      partialUrl += key + "=" + value + "&";
    const response = await Utils.CreatePostRequest(partialUrl);
    if (response?.message?.includes("200")) {
      return { result: response.result, totalRecords: response.tot };
    } else return null;
  },

  field(sortField) {
    switch (sortField) {
      case "":
        sortField = "matricola";
        break;
      case "codQuota":
        sortField = "cod_quota";
        break;
      case "importoDov":
        sortField = "importo_dov";
        break;
      case "importoDic":
        sortField = "importo_dic";
        break;
      case "dataRilevaz":
        sortField = "data_rilevaz";
        break;
      case "dataComunic":
        sortField = "data_comunic";
        break;
      case "numComun":
        sortField = "num_comun";
        break;
      case "dataOk":
        sortField = "data_ok";
        break;
    }
    return sortField;
  },

  order(sortOrder) {
    switch (sortOrder) {
      case 1:
        sortOrder = "ASC";
        break;
      case -1:
        sortOrder = "DESC";
        break;
      case 0:
        sortOrder = "";
        break;
    }
    return sortOrder;
  },

  GetAnomalieCaricate: async function (
    year,
    matricola,
    start,
    size,
    sortField,
    sortOrder
  ) {
    sortField = this.field(sortField);
    sortOrder = this.order(sortOrder);

    const url = `coasco/anomalie/getCaricate?dataComunic=${year}&start=${start}&size=${size}&sortfield=${sortField}&order=${sortOrder}&matricola=${
      matricola ?? ""
    }`;
    const response = await Utils.CreatePostRequest(url);
    if (response?.message?.includes("200")) {
      this.manipolaDate(response.result);
      Helpers.formatCurrency(response.result, "importoDov", "importoDic");
      return response;
    } else return null;
  },

  GetAnomalieAnnullate: async function (
    year,
    matricola,
    start,
    size,
    sortField,
    sortOrder
  ) {
    sortField = this.field(sortField);
    sortOrder = this.order(sortOrder);

    const url = `coasco/anomalie/getAnnullate?dataComunic=${year}&start=${start}&size=${size}&sortfield=${sortField}&order=${sortOrder}&matricola=${
      matricola ?? ""
    }`;
    const response = await Utils.CreatePostRequest(url);
    if (response?.message?.includes("200")) {
      this.manipolaDate(response.result);
      Helpers.formatCurrency(response.result, "importoDov", "importoDic");
      return response;
    } else return null;
  },

  GetAnomalieType: async function () {
    const response = await Utils.CreatePostRequest(
      "coasco/tipoanomalia/getall"
    );
    if (response && response.message && response.message.includes("200")) {
      return response.result;
    } else return null;
  },

  GetAnomalieRegolarizzate: async function (
    year,
    matricola,
    start,
    size,
    sortField,
    sortOrder
  ) {
    sortField = this.field(sortField);
    sortOrder = this.order(sortOrder);

    const url = `coasco/anomalie/getRegolarizzate?dataComunic=${year}&start=${start}&size=${size}&sortfield=${sortField}&order=${sortOrder}&matricola=${
      matricola ?? ""
    }`;
    const response = await Utils.CreatePostRequest(url);
    if (response?.message?.includes("200")) {
      this.manipolaDate(response.result);
      Helpers.formatCurrency(response.result, "importoDov", "importoDic");
      return response;
    } else return null;
  },

  GetAnomalieAttiveByAziendaId: async function (aziendaId, codiceQuota) {
    const response = await Utils.CreatePostRequest(
      "coasco/anomalie/getAnomalieAttiveByAziendaId?aziendaId=" +
        aziendaId +
        "&codiceQuota=" +
        codiceQuota
    );
    if (response && response.message && response.message.includes(200)) {
      Helpers.parseDates(response.result, [
        "dataRilevaz",
        "dataComunic",
        "dataOk",
        "dataAnnul"
      ]);
      Helpers.formatCurrency(response.result, "importoDov", "importoDic");
      return response.result;
    } else return null;
  },

  GetRegolarizzateByAziendaId: async function (aziendaId, codiceQuota) {
    const response = await Utils.CreatePostRequest(
      `coasco/anomalie/getRegolarizzateByAziendaId?aziendaId=${aziendaId}&codiceQuota=${codiceQuota}`
    );
    if (response && response.message && response.message.includes(200)) {
      Helpers.parseDates(response.result, [
        "dataRilevaz",
        "dataComunic",
        "dataOk",
        "dataAnnul"
      ]);
      Helpers.formatCurrency(response.result, "importoDov", "importoDic");
      return response.result;
    } else return null;
  },

  AnnullaAnomalie: async function (anomalie, note, username) {
    const ids = anomalie.map((anomalia) => anomalia.id);
    const response = await Utils.CreatePostRequest(
      "coasco/anomalie/cancel?username=" + username,
      { ids, note }
    );

    if (response?.message?.includes("200")) {
      return response.result;
    } else return null;
  },

  PredisponiSpedizione: async function (
    aziendaId,
    testo1,
    testo2,
    anomaliaIdsCust,
    anomaliaIdsPuli
  ) {
    return await Utils.CreatePostRequestFile(
      "coasco/anomalie/predisponiSpedizione?aziendaId=" +
        aziendaId +
        "&testo1=" +
        encodeURIComponent(testo1) +
        "&testo2=" +
        encodeURIComponent(testo2) +
        "&anomaliaIdsCust=" +
        (anomaliaIdsCust?.map((i) => i.id) ?? []) +
        "&anomaliaIdsPuli=" +
        (anomaliaIdsPuli?.map((i) => i.id) ?? [])
    );
  },

  async PredisponiRegolarita(aziendaId, text) {
    await Utils.CreatePostRequestFile(
      "coasco/anomalie/predisponiDocumentoRegolarita?aziendaId=" +
        aziendaId +
        "&text=" +
        encodeURIComponent(text)
    );
  },

  PreparaMail: async function (
    anno,
    dataProt,
    oggetto,
    emailText,
    senderEmail,
    files,
    amministratore,
    cond_azienda,
    arrayId,
    altroDest
  ) {
    const res = await Utils.httpPostWithStatus(
      "coasco/anomalie/preparaMail" +
        "?anno=" +
        anno +
        "&dataProt=" +
        dataProt +
        "&oggetto=" +
        encodeURIComponent(oggetto) +
        "&emailText=" +
        encodeURIComponent(emailText) +
        "&senderEmail=" +
        encodeURIComponent(senderEmail) +
        "&amministratore=" +
        amministratore +
        "&cond_azienda=" +
        cond_azienda +
        "&altroDest=" +
        altroDest,
      files,
      arrayId,
      "ids"
    );
    return res;
  },

  getAnomaliesReport: async function () {
    const url = `coasco/reportanomalie/retrieveall`;
    const res = await Utils.CreateGetRequest(url);
    return res;
  },

  downloadAnomalyReport: async function (id) {
    await Utils.httpGetDownloadZip(
      `coasco/reportanomalie/download/${id}`,
      "Lettere Anomalie Massive"
    );
  }
};
export default AnomalieAPI;
