import React, { Component } from "react";
import clone from "clone";
import { Dropdown } from "primereact/dropdown";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import LoaderSpinner from "../../Layout/LoaderSpinner.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import ButtonsMUI from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "components/CustomButtons/Button.js";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CardFooter from "components/Card/CardFooter.js";
import StaticLists from "helpers/StaticLists.js";
import Tooltip from "@material-ui/core/Tooltip";
import UndoIcon from "@material-ui/icons/Undo";
import { Calendar } from "primereact/calendar";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import queryString from "query-string";
import helpers from "helpers/Helpers";
import LavoroAPI from "API/LavoroAPI";
export class ModificaLavoroXDipendenti extends Component {
  state = {
    RowItem: null,
    ModificaTitle: "Nuovo",
    groupClass: "",
    Lavoro_Q1_List: [],
    Lavoro_Q2_List: [],
    Lavoro_Q3_List: [],
    Tipo_Profili_List: [],
    Tipo_Contratto_List: [],
    Tipo_Denuncia_List: [],
    Tipo_Lavoratore_List: [],
    selectedLavoro_Q1: null,
    selectedLavoro_Q2: null,
    selectedLavoro_Q3: null,
    selectedTipo_Contratto: null,
    selectedTipo_Denuncia: null,
    selectedTipo_Lavoratore: null,
    selectedTipo_Profili: null,
    matricola: null,
    cf: null,

    CodqList: [],
    Codq: null,
    disabledFields: false,

    //VALIDATIONS
    fieldValidations: {},
    validationRules: {
      matricola: "EQ-10",
      cf: "CFPIVA",
      cod_quota: "",
      data_inizio: ""
    }
  };

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.SetText = this.SetText.bind(this);
    this.OpenStoricoRDLPage = this.OpenStoricoRDLPage.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);

    this.monthNavigatorTemplate = this.monthNavigatorTemplate.bind(this);
    this.yearNavigatorTemplate = this.yearNavigatorTemplate.bind(this);
  }

  monthNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }

  yearNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }

  async isValidForm() {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    if (this.props.dataItem.id !== null) {
      // For update, skip validation for some fields
      validationRules["cf"] = "";
    }

    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);
    let isValid = Object.values(fieldValidations).every((x) => x === "success");

    this.setState({
      fieldValidations: fieldValidations
    });

    return isValid;
  }

  SetText(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({ RowItem: newRowItem });
  }

  OpenStoricoRDLPage() {
    let qS = queryString.parse(this.props.history.location.search);
    let aziendaId = this.props.match.params.aziendaId;
    if (!qS.type) {
      this.props.history.push(
        "/admin/rdl/storico/" +
          aziendaId +
          "/" +
          this.props.match.params.dipendenteId +
          "?typeId=" +
          this.state.RowItem.id
      );
    } else if (qS.type === "fromVersamenti")
      this.props.history.push(
        "/admin/rdl/storico/" +
          aziendaId +
          "/" +
          this.props.match.params.dipendenteId +
          "?type=" +
          qS.type +
          "&typeId=" +
          this.state.RowItem.id
      );
    else if (qS.type === "fromModificaRichieste")
      this.props.history.push(
        "/admin/rdl/storico/" +
          aziendaId +
          "/" +
          this.props.match.params.dipendenteId +
          "?type=" +
          qS.type +
          "&typeId=" +
          this.state.RowItem.id
      );
    else if (qS.type === "fromRichieste")
      this.props.history.push(
        "/admin/rdl/storico/" +
          aziendaId +
          "/" +
          this.props.match.params.dipendenteId +
          "?type=" +
          qS.type +
          "&typeId=" +
          this.state.RowItem.id
      );
      else if (qS.type === "fromUniemens")
      this.props.history.push(
        "/admin/rdl/storico/" +
          aziendaId +
          "/" +
          this.props.match.params.dipendenteId +
          "?typeId=" +
          this.state.RowItem.id +
          "&type=" +
          qS.type 
      );
  }

  HandleChange(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;
    this.setState({ RowItem: newRowItem });
  }

  HandleSaveClick = async () => {
    if (await this.isValidForm()) {
      let sd = await this.props.onSaveClick(this.state.RowItem);
      return sd;
    } else return;
  };

  HandleDropDownChange(e, val, propName) {
    let rowItem = this.state.RowItem;
    rowItem[propName] = val;

    this.setState({
      [e]: val,
      RowItem: rowItem
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.dataItem.id !== prevProps.dataItem.id)
      this.setState({
        //RowItem: this.props.dataItem,
        RowItem: {
          ...this.props.dataItem,
          data_inizio: helpers.GetDateObjectFromStringDate(
            this.props.dataItem.data_inizio
          ),
          data_fine: helpers.GetDateObjectFromStringDate(
            this.props.dataItem.data_fine
          )
        },
        ModificaTitle: this.props.dataItem.id > 0 ? "Modifica" : "Nuovo",
        disabledFields: this.props.dataItem.id > 0 ? true : false,
        groupClass: this.props.dataItem.id > 0 ? "" : "hide",
        selectedLavoro_Q1: helpers.ReturnIdFromListByType(
          this.state.Lavoro_Q1_List,
          "Lavoro",
          this.props.dataItem.q1
        ),
        selectedLavoro_Q2: helpers.ReturnIdFromListByType(
          this.state.Lavoro_Q2_List,
          "Lavoro",
          this.props.dataItem.q2
        ),
        selectedTipo_Profili: helpers.ReturnIdFromListByType(
          this.state.Tipo_Profili_List,
          "Lavoro",
          this.props.dataItem.tipo_profilo
        ),
        selectedLavoro_Q3: helpers.ReturnIdFromListByType(
          this.state.Lavoro_Q3_List,
          "Lavoro",
          this.props.dataItem.q3
        ),
        selectedTipo_Contratto: helpers.ReturnIdFromListByType(
          this.state.Tipo_Contratto_List,
          "Contrato",
          this.props.dataItem.tipo_contr
        ),
        selectedTipo_Denuncia: helpers.ReturnIdFromListByType(
          this.state.Tipo_Denuncia_List,
          "Denuncia",
          this.props.dataItem.tipo_den
        ),
        selectedTipo_Lavoratore: helpers.ReturnIdFromListByType(
          this.state.Tipo_Lavoratore_List,
          "Lavoro",
          this.props.dataItem.tipo_lav
        ),
        Codq: this.props.dataItem.cod_quota
      });
  }

  async componentDidMount() {
    if (this.props.dataItem !== null) {
      let contList = await LavoroAPI.GetAllTipoContratto();
      let q1List = await LavoroAPI.GetAllQ1();
      q1List.sort((a, b) => a.id - b.id);

      this.setState({
        RowItem: {
          ...this.props.dataItem,
          data_inizio: helpers.GetDateObjectFromStringDate(
            this.props.dataItem.data_inizio
          ),
          data_fine: helpers.GetDateObjectFromStringDate(
            this.props.dataItem.data_fine
          )
        },
        ModificaTitle: this.props.dataItem.id > 0 ? "Modifica" : "Nuovo",
        groupClass: this.props.dataItem.id > 0 ? "" : "hide",
        disabledFields: this.props.dataItem.id > 0 ? true : false,
        CodqList: await StaticLists.CodiceQuota(),
        Lavoro_Q1_List: q1List.map((x) => {
          return {
            codice: x.codice,
            descrizione: x.codice + " - " + x.descrizione
          };
        }),
        Lavoro_Q2_List: await LavoroAPI.GetAllQ2(),
        Lavoro_Q3_List: await LavoroAPI.GetAllQ3(),
        Tipo_Contratto_List: contList.map((x) => {
          return {
            codice: x.codice,
            descrizione: x.codice + " - " + x.descrizione
          };
        }),
        Tipo_Denuncia_List: await LavoroAPI.GetAllTipoDenuncia(),
        Tipo_Lavoratore_List: await LavoroAPI.GetAllTipoLavoratore(),
        Tipo_Profili_List: await LavoroAPI.GetAllTipoProfili()
      });
      this.setState({
        selectedLavoro_Q1: helpers.ReturnIdFromListByType(
          this.state.Lavoro_Q1_List,
          "Lavoro",
          this.props.dataItem.q1
        ),
        selectedLavoro_Q2: helpers.ReturnIdFromListByType(
          this.state.Lavoro_Q2_List,
          "Lavoro",
          this.props.dataItem.q2
        ),
        selectedLavoro_Q3: helpers.ReturnIdFromListByType(
          this.state.Lavoro_Q3_List,
          "Lavoro",
          this.props.dataItem.q3
        ),
        selectedTipo_Profili: helpers.ReturnIdFromListByType(
          this.state.Tipo_Profili_List,
          "Lavoro",
          this.props.dataItem.tipo_profilo
        ),
        selectedTipo_Contratto: helpers.ReturnIdFromListByType(
          this.state.Tipo_Contratto_List,
          "Contrato",
          this.props.dataItem.tipo_contr
        ),
        //selectedTipo_Contratto: helpers.ReturnIdFromListByType(this.state.Tipo_Contratto_List, "Contrato", this.props.dataItem.tipo_contr),
        selectedTipo_Denuncia: helpers.ReturnIdFromListByType(
          this.state.Tipo_Denuncia_List,
          "Denuncia",
          this.props.dataItem.tipo_den
        ),
        selectedTipo_Lavoratore: helpers.ReturnIdFromListByType(
          this.state.Tipo_Lavoratore_List,
          "Lavoro",
          this.props.dataItem.tipo_lav
        ),
        Codq: this.props.dataItem.cod_quota
      });
    }
  }

  SetNum(field, newValue, maxLength) {
    let test = newValue.toString();

    if (test.length <= maxLength) {
      let newRowItem = this.state.RowItem;
      newRowItem[field] = newValue;

      this.setState({ RowItem: newRowItem });
    }
  }

  OnInputBlurCF = async () => {
    let RowItem = this.state.RowItem;
    let validationRules = {
      cf: "CFPIVA"
    };

    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);
    Object.values(fieldValidations).every((x) => x === "success");

    if (fieldValidations["cf"] === "error") {
      this.props.addAlert.current.addAlert(
        "warning",
        "Il Codice Fiscale non e' sintatticamente corretto: CCCCCCNNCNNCNNNC o NNNNNNNNNNN!"
      );
      return false;
    }
  };

  render() {
    if (this.state.RowItem === null)
      return (
        <>
          {" "}
          <LoaderSpinner fullHeight={false} />{" "}
        </>
      );
    else
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.state.ModificaTitle} Rapporto di Lavoro
                  </h4>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Annulla"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.props.onAnnullaClick}
                    >
                      <UndoIcon />
                    </Button>
                  </Tooltip>

                  <ButtonGroup
                    variant="contained"
                    color="primary"
                    size="small"
                    aria-label="contained primary button group"
                    className={"float-right " + this.state.groupClass}
                    style={{ verticalAlign: "center", margin: "0.5%" }}
                  >
                    <ButtonsMUI onClick={this.OpenStoricoRDLPage}>
                      Storico RDL
                    </ButtonsMUI>
                  </ButtonGroup>
                </CardHeader>

                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Id"
                        id="id-disabled"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.id || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Ultimo Aggiornamento"
                        id="datault"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.dataultimoagg || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Tipo Ultimo Aggiornamento"
                        id="ultimoagg2"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.tipo_caric || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Utente Ultimo Agg."
                        id="ultimoagg3"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.utente_caric || ""
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.cf &&
                          this.state.fieldValidations.cf === "success"
                        }
                        error={
                          this.state.fieldValidations.cf &&
                          this.state.fieldValidations.cf === "error"
                        }
                        labelText="Codice Fiscale *"
                        id="codice"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disabledFields,
                          onChange: (event) =>
                            this.SetText("cf", event.target.value),
                          onBlur: (event) =>
                            this.OnInputBlurCF(event.target.value),
                          value: this.state.RowItem.cf || "",
                          maxLength: 16
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.matricola &&
                          this.state.fieldValidations.matricola === "success"
                        }
                        error={
                          this.state.fieldValidations.matricola &&
                          this.state.fieldValidations.matricola === "error"
                        }
                        labelText="Matricola *"
                        id="matricola"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disabledFields,
                          onChange: (event) =>
                            this.SetNum("matricola", event.target.value, 10),
                          value: this.state.RowItem.matricola || "",
                          maxLength: 10
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2} className="select">
                      <CustomSelect
                        success={
                          this.state.fieldValidations.cod_quota === "success"
                        }
                        error={
                          this.state.fieldValidations.cod_quota === "error"
                        }
                        labelText="Codice Quota *"
                        id="cq"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleDropDownChange(
                              "Codq",
                              event.target.value,
                              "cod_quota"
                            ),
                          value: this.state.Codq || ""
                        }}
                      >
                        {this.state.CodqList.map((item, i) => (
                          <MenuItem value={item.name} key={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "6px" }}
                    >
                      <p
                        className="calendarParagraph"
                        style={{
                          color:
                            this.state.fieldValidations["data_inizio"] ===
                            "error"
                              ? "#f44336"
                              : "#565656"
                        }}
                      >
                        <small>Data Inizio *</small>
                      </p>
                      <Calendar
                        className="calendarCustomedForMUI"
                        success={
                          this.state.fieldValidations.data_inizio === "success"
                        }
                        error={
                          this.state.fieldValidations.data_inizio === "error"
                        }
                        id="dataconst"
                        value={
                          new Date(
                            helpers.ConvertToItalianDateForPrime(
                              this.state.RowItem.data_inizio
                            )
                          )
                        }
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          this.HandleChange("data_inizio", e.target.value)
                        }
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "6px" }}
                    >
                      <p
                        className="calendarParagraph"
                        style={{ color: "#565656" }}
                      >
                        <small>Data Fine</small>
                      </p>

                      <Calendar
                        className="calendarCustomedForMUI"
                        id="dataconst"
                        value={
                          new Date(
                            helpers.ConvertToItalianDateForPrime(
                              this.state.RowItem.data_fine
                            )
                          )
                        }
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          this.HandleChange("data_fine", e.target.value)
                        }
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Imponibile"
                        id="Imponibile"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("imponibile", event.target.value),
                          value: this.state.RowItem.imponibile || null
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={2} className="select">
                      <CustomSelect
                        labelText="Tipo Denuncia"
                        id="tip"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleDropDownChange(
                              "selectedTipo_Denuncia",
                              event.target.value,
                              "tipo_den"
                            ),
                          value: this.state.selectedTipo_Denuncia || ""
                        }}
                      >
                        {this.state.Tipo_Denuncia_List.map((item, i) => (
                          <MenuItem value={item.codice} key={item.codice}>
                            {item.codice + " - " + item.descrizione}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={4}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="lblNoMargin" style={{ color: 565656 }}>
                        <small>Q1</small>
                      </p>
                      <Dropdown
                        value={this.state.selectedLavoro_Q1}
                        optionValue="codice"
                        resetFilterOnHide
                        id="alddjskdcns"
                        itemTemplate={helpers.dropDownOptionTemplateMod}
                        // valueTemplate={helpers.selectedDropdownTemplate}
                        className="dropdownCustomisedForMUI"
                        options={this.state.Lavoro_Q1_List}
                        onChange={(e) =>
                          this.HandleDropDownChange(
                            "selectedLavoro_Q1",
                            e.target.value,
                            "q1"
                          )
                        }
                        optionLabel="descrizione"
                        filter
                        filterBy="descrizione"
                      />{" "}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} className="select">
                      <CustomSelect
                        labelText="Q2"
                        id="q2"
                        formControlProps={{
                          fullWidth: true
                          //className: 'CustomHeightSelect'
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleDropDownChange(
                              "selectedLavoro_Q2",
                              event.target.value,
                              "q2"
                            ),
                          value: this.state.selectedLavoro_Q2 || ""
                        }}
                      >
                        {this.state.Lavoro_Q2_List.map((item, i) => (
                          <MenuItem value={item.codice} key={item.codice}>
                            {item.codice + " - " + item.descrizione}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} className="select">
                      <CustomSelect
                        labelText="Q3"
                        id="q3"
                        formControlProps={{
                          fullWidth: true
                          //className: 'CustomHeightSelect'
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleDropDownChange(
                              "selectedLavoro_Q3",
                              event.target.value,
                              "q3"
                            ),
                          value: this.state.selectedLavoro_Q3 || ""
                        }}
                      >
                        {this.state.Lavoro_Q3_List.map((item, i) => (
                          <MenuItem value={item.codice} key={item.codice}>
                            {item.codice + " - " + item.descrizione}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={4}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="lblNoMargin" style={{ color: 565656 }}>
                        <small>Tipo Contratto</small>
                      </p>
                      <Dropdown
                        value={this.state.selectedTipo_Contratto}
                        optionValue="codice"
                        resetFilterOnHide
                        itemTemplate={helpers.dropDownOptionTemplateMod}
                        // valueTemplate={helpers.selectedDropdownTemplate}
                        className="dropdownCustomisedForMUI"
                        options={this.state.Tipo_Contratto_List}
                        onChange={(e) =>
                          this.HandleDropDownChange(
                            "selectedTipo_Contratto",
                            e.target.value,
                            "tipo_contr"
                          )
                        }
                        optionLabel="descrizione"
                        filter
                        filterBy="descrizione"
                      />{" "}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} className="select">
                      <CustomSelect
                        labelText="Tipo Profili"
                        id="Tipo_Profili"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleDropDownChange(
                              "selectedTipo_Profili",
                              event.target.value,
                              "tipo_profilo"
                            ),
                          value: this.state.selectedTipo_Profili || ""
                        }}
                      >
                        {this.state.Tipo_Profili_List.map((item, i) => (
                          <MenuItem value={item.codice} key={item.codice}>
                            {item.codice + " - " + item.descrizione}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={4} className="select">
                      <CustomSelect
                        labelText="Tipo Lavoratore"
                        id="Tipo_Lavoratore_List"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleDropDownChange(
                              "selectedTipo_Lavoratore",
                              event.target.value,
                              "tipo_lav"
                            ),
                          value: this.state.selectedTipo_Lavoratore || ""
                        }}
                      >
                        {this.state.Tipo_Lavoratore_List.map((item, i) => (
                          <MenuItem value={item.codice} key={item.codice}>
                            {item.codice + " - " + item.descrizione}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>
                </CardBody>

                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}
