import Utils from "./Utils";

const defaultUrl = "coasco/utente";

const UtentiAPI = {
  newUser: function () {
    return {
      codice: null,
      username: null,
      user: null,
      userType: null
    };
  },
  getUsers: async function () {
    const url = `${defaultUrl}/getall`;

    const res = await Utils.CreatePostRequest(url);
    return res?.result;
  },

  getUser: async function (id) {
    const url = `${defaultUrl}/getById?id=${id}`;

    const res = await Utils.CreatePostRequest(url);
    return res?.result;
  },

  getUserByUsername: async function (username) {
    const url = `${defaultUrl}/getByUsername?username=${username}`;

    const res = await Utils.CreatePostRequest(url);
    return res?.result;
  },

  getUserTypes: async function () {
    const url = `${defaultUrl}/getAllUfficio`;

    const res = await Utils.CreatePostRequest(url);
    return res?.result;
  },

  createUser: async function (data) {
    const url = `${defaultUrl}/insert`;

    const res = await Utils.CreatePostRequest(url, data);
    return res?.result;
  },

  updateUser: async function (data) {
    const url = `${defaultUrl}/update`;

    const res = await Utils.CreatePostRequest(url, data);
    return res?.result;
  },

  deleteUser: async function (id) {
    const url = `${defaultUrl}/delete?id=${id}`;

    const res = await Utils.CreateDeleteRequest(url);
    return res?.result;
  }
};

export default UtentiAPI;
