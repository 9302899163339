import * as AppConstants from "../helpers/AppConstants";
import Utils from "./Utils";

const baseURL = AppConstants.SiteUrl + "ebinprof/TipoEtic";

const EtichetteAPI = {
  deleteLabelType: async function (id) {
    const response = await Utils.SendDeleteRequestTo(
      `${baseURL}/delete?id=${id}`
    );
    return response.result;
  },

  getAll: async function () {
    const response = await Utils.SendPostRequestTo(`${baseURL}/getall`);
    return response.result;
  },

  getById: async function (id) {
    const res = await Utils.SendPostRequestTo(
      `${baseURL}/getById?codice=${id}`
    );
    return res.result;
  },

  create: async function (data) {
    const response = await Utils.SendPostRequestTo(`${baseURL}/insert`, data);
    return response;
  },

  update: async function (data) {
    const response = await Utils.SendPutRequestTo(`${baseURL}/update`, data);
    return response;
  },

  EtichetteForBorse: function (etichetteId) {
    let rows = [
      { id: 1, name: "100 X 42", larghezza: "100", altezza: "42" },
      { id: 2, name: "100 X 50", larghezza: "100", altezza: "50" },
      { id: 3, name: "105 X 45", larghezza: "105", altezza: "45" }
    ];

    if (etichetteId > 0) {
      rows = rows.filter((row) => row.id === etichetteId);
      return rows;
    } else return rows;
  },

  NuovoEtichetteForBorse: function (etichetteId) {
    let rows = [{ id: 1, name: "", larghezza: "", altezza: "" }];
    return rows;
  },

  AddEtichette: async function (etichetteobj) {
    return 1;
  },
  UpdateEtichette: async function (etichetteobj) {
    return 1;
  }
};
export default EtichetteAPI;
