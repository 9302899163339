import React from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Tooltip from "@material-ui/core/Tooltip";
import CustomButton from "components/CustomButtons/Button.js";
import ButtonsMUI from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { InputText } from "primereact/inputtext";
import AddIcon from "@material-ui/icons/Add";
import { Button as OtherButton } from "primereact/button";
import Switch from "@material-ui/core/Switch";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Helpers from "helpers/Helpers.js";
import { Dropdown } from "primereact/dropdown";
import StaticLists from "helpers/StaticLists";
import CustomInput from "components/CustomInput/CustomInput";
import CassaAPI from "API/CassaAPI";
import ScarichiAPI from "API/ScarichiAPI";
import TabulatiAPI from "API/TabulatiAPI";
import SedeComuneProvAPI from "API/SedeComuneProvAPI";
import Close from "@material-ui/icons/Close";
import { confirmPopup } from "primereact/confirmpopup";
import Button from "components/CustomButtons/Button.js";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "moment/locale/it";
import moment from "moment";
import { dateTimeFmt } from "helpers/AppConstants";
import { dateFmt } from "helpers/AppConstants";
import { currencyFormatter } from "helpers/AppConstants";
import BancheAPI from "../../API/BancheAPI";
moment.locale("it");

class Tabulati extends React.Component {
  state = {
    rows: [],
    rowsCassa: [],
    selectedGrid: null,
    selectedGridCassa: null,
    filterGridCassa: false,
    TabulatiMultipleTable: [],
    rowsTabulati: [],
    Codice: [],
    filterGrid: false,
    rowsFatturate: [],
    selectedGridFatturate: null,
    filterGridFatturate: false,
    loadingGrid: false,
    loadingGridCassa: false,
    loadingGridFatt: false,
    loadingGridScar: false,
    selectedGridScarichi: null,
    filterGridScarichi: false,
    anno: new Date().getFullYear(),
    rowsScarichi: [],
    Sede: [],
    visibleFields: true,
    clearContent: false,
    cassaFieldValidations: { banca: "", nettoAccr: "", dataInc: "" },
    speseFieldValidations: { fatturate: "" },
    listaBanche: null,
    tabulatiRowsPulsante: [],

    classNameForCassaTextInput: "p-d-block",
    classNameForFattNumberInput: "p-d-block",
    classNameForCassaNumberInput: "p-d-block",

    totImpScarichi: null,
    totImpTabulati: null,
    totImpNetto: null,
    totImpAccr: null,
    totImpFatt: null,

    totQuoteScarichi: null,
    totQuoteTabulati: null,
    totQuoteFatture: null,
    totQuoteAccr: null,

    daFattScarichi: null,
    daFattTabulati: null,

    tariffa: 1,
    tariffaScarichi: 1,
    bollo: null,
    errore: null,
    differenza: null,
    differenzaDaFatt: null,

    data: null,
    chiuso: null
  };

  constructor(props) {
    super(props);
    this.SetText = this.SetText.bind(this);
    this.btnSearchRef = React.createRef();
    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.handleTableClickScarichi = this.handleTableClickScarichi.bind(this);
    this.ChangeFilterGridScarichi = this.ChangeFilterGridScarichi.bind(this);
    this.ChangeFilterGridFatturate = this.ChangeFilterGridFatturate.bind(this);
    this.handleTableClickFatturate = this.handleTableClickFatturate.bind(this);
    this.handleTableClickCassa = this.handleTableClickCassa.bind(this);
    this.ChangeFilterGridCassa = this.ChangeFilterGridCassa.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
    this.onNuovoRowClick = this.onNuovoRowClick.bind(this);
    this.gridFattureRef = React.createRef();
    this.gridCassaRef = React.createRef();

    this.onSpeseEditSave = this.onSpeseEditSave.bind(this);
    this.speseEditorValidator = this.speseEditorValidator.bind(this);

    this.onCassaEditSave = this.onCassaEditSave.bind(this);
    this.cassaEditorValidator = this.cassaEditorValidator.bind(this);

    this.originalCassaRows = {};
    this.originalFattureRows = {};
    this.inputTextEditor = this.inputTextEditor.bind(this);
    this.inputFullAnnoEditor = this.inputFullAnnoEditor.bind(this);
    this.onEditorValueChange = this.onEditorValueChange.bind(this);
    this.inputCodiceEditor = this.inputCodiceEditor.bind(this);
    this.nettoEditor = this.nettoEditor.bind(this);
    this.bancaEditor = this.bancaEditor.bind(this);
    this.SetDifferenzeDataFatt = this.SetDifferenzeDataFatt.bind(this);
    this.dataincassEditor = this.dataincassEditor.bind(this);
    this.onCassaEditInit = this.onCassaEditInit.bind(this);
    this.onCassaEditCancel = this.onCassaEditCancel.bind(this);
    this.fatturateEditor = this.fatturateEditor.bind(this);
    this.datafatEditor = this.datafatEditor.bind(this);
    this.onFatturateEditInit = this.onFatturateEditInit.bind(this);
    this.onFatturateEditCancel = this.onFatturateEditCancel.bind(this);
    this.inputNumberEditor = this.inputNumberEditor.bind(this);
    this.onEditorDateValueChange = this.onEditorDateValueChange.bind(this);

    this.Delete = this.Delete.bind(this);
    this.DeleteCassa = this.DeleteCassa.bind(this);

    this.SumDaFatturare = this.SumDaFatturare.bind(this);
    this.SumDaFatturareNuovo = this.SumDaFatturareNuovo.bind(this);
    this.SetDifferenzeDataFattNuovo =
      this.SetDifferenzeDataFattNuovo.bind(this);
    this.onRedirectDaTabulati = this.onRedirectDaTabulati.bind(this);
  }

  backButtonClick() {
    const maintainSearch = "?maintainSearch=1";
    this.props.history.push(
      "/admin/elenchitabulati/" +
        this.props.match.params.tabulatiId +
        maintainSearch
    );
  }

  async onNuovoRowClick(param) {
    if (param === "cassa") {
      Helpers.CloseAllEditRows(this.gridCassaRef);
      let rowsCassa = this.state.rowsCassa;
      let newLine = await CassaAPI.GetEmptyCassa();
      newLine.banca = (
        await BancheAPI.GetByCodiceQuota(this.state.rows[0].codice)
      ).cod_banca;
      rowsCassa.splice(0, 0, newLine);
      this.setState({ rowsCassa: rowsCassa }, function () {
        Helpers.OpenEditFirstRow(this.gridCassaRef);
      });
    } else if (param === "fatture") {
      Helpers.CloseAllEditRows(this.gridFattureRef);
      let rowsFatturate = this.state.rowsFatturate;
      let newLine = await TabulatiAPI.GetEmptyFatture();
      rowsFatturate.splice(0, 0, newLine);

      this.setState({ rowsFatturate: rowsFatturate }, function () {
        Helpers.OpenEditFirstRow(this.gridFattureRef);
      });
    }
  }

  async onSpeseEditSave(event) {
    if (event.valid) {
      let scomposizione = this.props.history.location.search.split("/");
      let sede = scomposizione[2];
      let codice = scomposizione[1];
      let ripartizione = scomposizione[3] + "/" + scomposizione[4];

      let obj = {
        id: event.data.id === 0 ? null : event.data.id,
        fatturate: event.data.fatturate,
        sedeInps: sede,
        data_fattura: moment(event.data.datafat, dateFmt).format(dateTimeFmt),
        cod_quota: codice,
        mese: scomposizione[4],
        periodo: ripartizione
      };
      obj.id === null
        ? await TabulatiAPI.insertFatture(obj)
        : await TabulatiAPI.updateFatture(obj);
      await this.refreshGrid();
      window.location.reload();
    }
  }

  speseEditorValidator(event) {
    let isValid = true;
    let RowItem = event;
    let speseFieldValidations = this.state.speseFieldValidations;
    let classNameForFattNumberInput = this.state.classNameForFattNumberInput;

    for (let propName in speseFieldValidations) {
      let validation =
        RowItem[propName] === null ||
        RowItem[propName].length === 0 ||
        !RowItem[propName].toString().replace(/\s/g, "").length
          ? "error"
          : "success";

      speseFieldValidations[propName] = validation;
      if (validation === "error") {
        isValid = false;
        classNameForFattNumberInput = "p-invalid p-d-block";
      } else if (validation === "success") {
        classNameForFattNumberInput = "p-d-block";
        isValid = true;
      }
    }
    this.setState({
      speseFieldValidations: speseFieldValidations,
      classNameForFattNumberInput: classNameForFattNumberInput
    });

    return isValid;
  }

  async onCassaEditSave(event) {
    if (event.valid) {
      let scomposizione = this.props.history.location.search.split("/");
      let sede = scomposizione[2];
      let codice = scomposizione[1];
      let ripartizione = scomposizione[3] + "/" + scomposizione[4];

      let obj = {
        id: event.data.id === 0 ? null : event.data.id,
        nettoAccr: Number(event.data.nettoAccr.replace(",", "")),
        banca: event.data.banca,
        sede: sede,
        dataInc: moment(event.data.dataInc, dateFmt).format(dateTimeFmt),
        codQuota: codice,
        mese: scomposizione[4],
        periodo: ripartizione,
        anno: scomposizione[3]
      };
      obj.id === null
        ? await CassaAPI.insertCassa(obj)
        : await CassaAPI.updateCassa(obj);
      await this.refreshGrid();
      window.location.reload();
    }
  }

  cassaEditorValidator(event) {
    let isValid = true;
    let cassaFieldValidations = this.state.cassaFieldValidations;
    let classNameForCassaTextInput = this.state.classNameForCassaTextInput;
    let classNameForCassaNumberInput = this.state.classNameForCassaNumberInput;

    for (var propName in cassaFieldValidations) {
      let validation =
        event[propName] === null ||
        event[propName].length === 0 ||
        !event[propName].toString().replace(/\s/g, "").length
          ? "error"
          : "success";
      cassaFieldValidations[propName] = validation;
      if (validation === "error") {
        isValid = false;
      }
      if (propName === "nettoAccr") {
        if (validation === "error")
          classNameForCassaNumberInput = "p-invalid p-d-block";
        else if (validation === "success") {
          classNameForCassaNumberInput = "p-d-block";
        }
      } else if (propName === "banca") {
        if (validation === "error")
          classNameForCassaTextInput = "p-invalid p-d-block";
        else if (validation === "success") {
          classNameForCassaTextInput = "p-d-block";
        }
      }
    }
    this.setState({
      cassaFieldValidations: cassaFieldValidations,
      classNameForCassaTextInput: classNameForCassaTextInput,
      classNameForCassaNumberInput: classNameForCassaNumberInput
    });
    return isValid;
  }

  inputTextEditor(productKey, props, field) {
    return (
      <InputText
        type="text"
        required={true}
        className={this.state.classNameForCassaTextInput}
        success={this.state.cassaFieldValidations[field] === "success"}
        error={this.state.cassaFieldValidations[field] === "error"}
        value={props.rowData[field]}
        onChange={(e) =>
          this.onEditorValueChange(productKey, props, e.target.value)
        }
      />
    );
  }

  inputNumberEditor(productKey, props, field) {
    if (field === "fatturate")
      return (
        <InputText
          type="number"
          required={true}
          success={this.state.speseFieldValidations[field] === "success"}
          error={this.state.speseFieldValidations[field] === "error"}
          className={this.state.classNameForFattNumberInput}
          value={props.rowData[field]}
          onChange={(e) =>
            this.onEditorValueChange(productKey, props, e.target.value)
          }
        />
      );
    else if (field === "nettoAccr")
      return (
        <InputText
          type="number"
          required={true}
          className={this.state.classNameForCassaNumberInput}
          success={this.state.cassaFieldValidations[field] === "success"}
          error={this.state.cassaFieldValidations[field] === "error"}
          value={props.rowData[field]}
          onChange={(e) =>
            this.onEditorValueChange(productKey, props, e.target.value)
          }
        />
      );
  }

  onEditorValueChange(productKey, props, value) {
    let updatededitRows = [...props.value];
    updatededitRows[props.rowIndex][props.field] = value;
    this.setState({ [`${productKey}`]: updatededitRows });
  }

  onEditorDateValueChange(productKey, props, value) {
    if (value === null || value === "")
      this.onEditorValueChange(productKey, props, value);
    else this.onEditorValueChange(productKey, props, value.format(dateFmt));
  }

  inputCodiceEditor(productKey, props, field) {
    return (
      <Dropdown
        options={this.state.Codice}
        optionValue="name"
        style={{ width: "100%" }}
        optionLabel="name"
        type="text"
        value={props.rowData[field]}
        onChange={(e) => this.onEditorValueChange(productKey, props, e.value)}
        itemTemplate={(option) => {
          return <span>{option.name}</span>;
        }}
      />
    );
  }

  inputFullAnnoEditor(productKey, props, field) {
    return (
      <KeyboardDatePicker
        className="ctrlMuiToPF"
        clearable
        value={
          props.rowData[field]
            ? moment(props.rowData[field], dateFmt).toDate()
            : null
        }
        placeholder="gg/MM/aaaa"
        onChange={(e) => this.onEditorDateValueChange(productKey, props, e, "")}
        format="DD/MM/yyyy"
      />
    );
  }

  nettoEditor(productKey, props) {
    return this.inputNumberEditor(productKey, props, "nettoAccr");
  }

  bancaEditor(productKey, props) {
    return (
      <Dropdown
        value={props.rowData["banca"]}
        optionValue="cod_banca"
        optionLabel="cod_banca"
        className="dropdownCustomisedForMUI"
        options={this.state.listaBanche}
        onChange={(e) =>
          this.onEditorValueChange(productKey, props, e.value, "banca")
        }
        filter
        filterBy="name"
        resetFilterOnHide
      />
    );
  }

  dataincassEditor(productKey, props) {
    return this.inputFullAnnoEditor(productKey, props, "dataInc");
  }

  onCassaEditInit(event) {
    this.originalCassaRows[event.index] = {
      ...this.state.rowsCassa[event.index]
    };
  }

  onCassaEditCancel(event) {
    let editRows = [...this.state.rowsCassa];
    editRows[event.index] = this.originalCassaRows[event.index];
    delete this.originalCassaRows[event.index];

    if (!(editRows[0].id > 0)) editRows.shift();

    this.setState({ rowsCassa: editRows });
  }

  fatturateEditor(productKey, props) {
    return this.inputNumberEditor(productKey, props, "fatturate");
  }

  datafatEditor(productKey, props) {
    return this.inputFullAnnoEditor(productKey, props, "datafat");
  }

  onFatturateEditInit(event) {
    this.originalFattureRows[event.index] = {
      ...this.state.rowsFatturate[event.index]
    };
  }

  onFatturateEditCancel(event) {
    let editRows = [...this.state.rowsFatturate];
    editRows[event.index] = this.originalFattureRows[event.index];
    delete this.originalFattureRows[event.index];

    if (!(editRows[0].id > 0)) editRows.shift();

    this.setState({ rowsFatturate: editRows });
  }

  async componentDidMount() {
    let scomposizione = this.props.history.location.search.split("/");
    let sede = scomposizione[2];
    let codice = scomposizione[1];
    let ripartizione = scomposizione[3] + "/" + scomposizione[4];
    let anno = scomposizione[3];
    const chiuso = scomposizione[5] === "SI";

    this.setState({ chiuso: chiuso });

    let allSedi = await SedeComuneProvAPI.GetAllSede();
    let banche = await BancheAPI.GetAll();

    this.setState({
      loadingGridScar: true,
      loadingGridCassa: true,
      loadingGridFatt: true,
      loadingGrid: true
    });

    this.setState({
      Sede: allSedi.map((x) => {
        return { sap: x.sap, descrizione: x.sap + " - " + x.descrizione };
      }),
      Codice: StaticLists.CodiceQuota(),
      rowsScarichi: await ScarichiAPI.GetScarichiTabulatiF24(
        sede,
        codice,
        ripartizione
      ),
      rows: await TabulatiAPI.GetDettagliTabulatiFiltrato(
        codice,
        sede,
        anno,
        ripartizione
      ),
      rowsTabulati: await TabulatiAPI.GetDettagliTabulatiFiltrato(
        codice,
        sede,
        anno,
        ripartizione
      ),
      rowsFatturate: await TabulatiAPI.GetFatture(sede, ripartizione, codice),
      rowsCassa: (
        await CassaAPI.GetCassaBySedePeriodoQuota(sede, ripartizione, codice)
      ).result,
      tabulatiRowsPulsante: await TabulatiAPI.GetTabulatiBySedeRipartoQuota(
        sede,
        ripartizione,
        codice
      ),
      listaBanche: banche
    });

    if(this.state.rows && this.state.rows.length === 1){
      this.setState({
        selectedGrid: this.state.rows[0]
      });
    }

    this.setState({
      loadingGridScar: false,
      loadingGridCassa: false,
      loadingGridFatt: false,
      loadingGrid: false
    });

    let totImpScarichi = 0;
    let totImpTabulati = 0;
    let totImpNetto = 0;
    let totImpFatt = 0;

    let totQuoteScarichi = 0;
    let totQuoteTabulati = 0;
    let totQuoteFatture = 0;
    let totQuoteAccr = 0;

    for (let i in this.state.tabulatiRowsPulsante) {
      totImpTabulati += this.state.tabulatiRowsPulsante[i].importo;
      totQuoteTabulati += this.state.tabulatiRowsPulsante[i].righe;
    }

    for (let i in this.state.rowsScarichi) {
      totImpScarichi += this.state.rowsScarichi[i].impquota;
      totQuoteScarichi += this.state.rowsScarichi[i].numero;
    }

    for (let i in this.state.rowsCassa) {
      totImpNetto += this.state.rowsCassa[i].nettoAccr;
      totQuoteAccr += 1;
    }

    for (let i in this.state.rowsFatturate) {
      totImpFatt += this.state.rowsFatturate[i].fatturate;
      totQuoteFatture += 1;
    }

    this.setState({
      totImpScarichi: totImpScarichi,
      totImpTabulati: totImpTabulati,
      totImpNetto: totImpNetto,
      totImpAccr: this.state.rowsTabulati[0].accreditato,

      totQuoteScarichi: totQuoteScarichi,
      totQuoteTabulati: totQuoteTabulati,
      totQuoteFatture: totQuoteFatture,
      totQuoteAccr: totQuoteAccr,
      totImpFatt: totImpFatt,

      differenza: this.state.rowsTabulati[0].diffaccr,
      differenzaDaFatt: this.state.rowsTabulati[0].difffatt,
      tariffa: await TabulatiAPI.getTariffaTabulati(ripartizione),
      tariffaScarichi: await TabulatiAPI.getTariffaScarichi(ripartizione),
      data: Helpers.GetDateObjectFromStringDate(
        this.state.rowsTabulati[0].dataarr.split(" ")[0]
      ),
      bollo: this.state.rowsTabulati[0].bollo,
      errore: this.state.rowsTabulati[0].errore
    });

    this.SumDaFatturareNuovo(
      totQuoteScarichi,
      this.state.tariffaScarichi,
      this.state.bollo,
      "scarichi"
    );
    this.SumDaFatturareNuovo(
      totQuoteTabulati,
      this.state.tariffa,
      this.state.bollo,
      "tabulati"
    );

    this.setState({
      TabulatiMultipleTable: await TabulatiAPI.GetTabulatiMultipleTable(
        null,
        this.state.rowsScarichi.length,
        this.state.rowsTabulati.length,
        this.state.rowsCassa.length,
        this.state.rowsFatturate.length
      )
    });

    if (this.state.TabulatiMultipleTable) {
      this.SumDaFatturare(
        "dafattscarichi",
        "qscarichi",
        "tariffascarichi",
        "bolloscarichi"
      );
      this.SumDaFatturare(
        "dafatttabulati",
        "qtabulati",
        "tariffatab",
        "bollotab"
      );
      this.SetDifferenzeDataFatt(
        "datafat",
        "dafatdiff",
        "dafattfatture",
        "dafattscarichi",
        "dafatttabulati"
      );
      this.SetDifferenzeDataFatt(
        "importo",
        "impdiff",
        "impaccrediti",
        "impscarichi",
        "imptabulati",
        "impnetto"
      );
    }

    if (this.state.rowsCassa !== null && this.state.rowsCassa.length > 0) {
      let sum = 0;
      for (let i = 0; i < this.state.rowsCassa.length; i++) {
        sum += this.state.rowsCassa[i].netto
          ? parseFloat(this.state.rowsCassa[i].netto)
          : 0;
      }
      this.SetText("impaccrediti", sum);
    }
  }

  async refreshGrid() {
    let scomposizione = this.props.history.location.search.split("/");
    let sede = scomposizione[2];
    let codice = scomposizione[1];
    let ripartizione = scomposizione[3] + "/" + scomposizione[4];
    let anno = scomposizione[3];

    let allSedi = await SedeComuneProvAPI.GetAllSede();

    this.setState({
      loadingGridScar: true,
      loadingGridCassa: true,
      loadingGridFatt: true,
      loadingGrid: true
    });

    this.setState({
      Sede: allSedi.map((x) => {
        return { sap: x.sap, descrizione: x.sap + " - " + x.descrizione };
      }),
      Codice: await StaticLists.CodiceQuota(),
      rowsScarichi: await ScarichiAPI.GetScarichiTabulatiF24(
        sede,
        codice,
        ripartizione
      ),
      rows: await TabulatiAPI.GetDettagliTabulatiFiltrato(
        codice,
        sede,
        anno,
        ripartizione
      ),
      rowsTabulati: await TabulatiAPI.GetDettagliTabulatiFiltrato(
        codice,
        sede,
        anno,
        ripartizione
      ),
      rowsFatturate: await TabulatiAPI.GetFatture(sede, ripartizione, codice),
      rowsCassa: (
        await CassaAPI.GetCassaBySedePeriodoQuota(sede, ripartizione, codice)
      ).result
    });

    this.setState({
      loadingGridScar: false,
      loadingGridCassa: false,
      loadingGridFatt: false,
      loadingGrid: false
    });

    this.setState({
      TabulatiMultipleTable: await TabulatiAPI.GetTabulatiMultipleTable(
        null,
        this.state.rowsScarichi.length,
        this.state.rowsTabulati.length,
        this.state.rowsCassa.length,
        this.state.rowsFatturate.length
      )
    });

    if (this.state.TabulatiMultipleTable) {
      this.SumDaFatturare(
        "dafattscarichi",
        "qscarichi",
        "tariffascarichi",
        "bolloscarichi"
      );
      this.SumDaFatturare(
        "dafatttabulati",
        "qtabulati",
        "tariffatab",
        "bollotab"
      );
      this.SetDifferenzeDataFatt(
        "datafat",
        "dafatdiff",
        "dafattfatture",
        "dafattscarichi",
        "dafatttabulati"
      );
      this.SetDifferenzeDataFatt(
        "importo",
        "impdiff",
        "impaccrediti",
        "impscarichi",
        "imptabulati",
        "impnetto"
      );
    }

    if (this.state.rowsCassa !== null && this.state.rowsCassa.length > 0) {
      let i;
      let sum = 0;
      for (i = 0; i < this.state.rowsCassa.length; i++) {
        sum += this.state.rowsCassa[i].netto
          ? parseFloat(this.state.rowsCassa[i].netto)
          : 0;
      }

      this.setState({
        totImpNetto: this.state.rowsCassa
          .map((x) => x.nettoAccr)
          .reduce((x, y) => x + y),
        totQuoteAccr: this.state.rowsCassa.length,
        totImpFatt: this.state.rowsFatturate
          .map((x) => x.fatturate)
          .reduce((x, y) => x + y),
        totQuoteFatture: this.state.rowsFatturate.length
      });

      this.SetText("impaccrediti", sum);
    }
  }

  ChangeFilterGrid(e) {
    this.setState({
      filterGrid: e.target.checked
    });
  }

  ChangeFilterGridScarichi(e) {
    this.setState({
      filterGridScarichi: e.target.checked
    });
  }

  ChangeFilterGridFatturate(e) {
    this.setState({
      filterGridFatturate: e.target.checked
    });
  }

  ChangeFilterGridCassa(e) {
    this.setState({
      filterGridCassa: e.target.checked
    });
  }

  SumDaFatturare(field, quote, tariffa, bollo) {
    let newRowItem = this.state.TabulatiMultipleTable;
    newRowItem[field] =
      newRowItem[quote] * newRowItem[tariffa] + newRowItem[bollo];

    this.setState({ TabulatiMultipleTable: newRowItem });
  }

  SumDaFatturareNuovo(quote, tariffa, bollo, tipo) {
    if (tipo === "scarichi") {
      let risultato = quote * tariffa;
      this.setState({ daFattScarichi: risultato });
    } else if (tipo === "tabulati") {
      let risultato = quote * tariffa + bollo;
      this.setState({ daFattTabulati: risultato });
    }
  }

  SetDifferenzeDataFatt(type, field, a, b, c, d) {
    let newRowItem = this.state.TabulatiMultipleTable;
    if (type === "datafat")
      newRowItem[field] = newRowItem[a] - (newRowItem[b] + newRowItem[c]);
    else if (type === "importo")
      newRowItem[field] =
        newRowItem[a] - (newRowItem[b] + newRowItem[c] + newRowItem[d]);

    this.setState({ TabulatiMultipleTable: newRowItem });
  }

  SetDifferenzeDataFattNuovo(type, a, b, c, d) {
    let risultato = null;
    if (type === "datafat") {
      risultato = a - (b + c);
      this.setState({ differenzaDaFatt: risultato });
    } else if (type === "importo") {
      risultato = a - (b + c + d);
      this.setState({ differenza: risultato });
    }
  }

  SetText(field, newValue) {
    let newRowItem = this.state.TabulatiMultipleTable;
    newRowItem[field] = parseFloat(newValue);

    let obj = { TabulatiMultipleTable: newRowItem };
    obj[field] = parseFloat(newValue);

    this.setState(obj);

    this.SumDaFatturare(
      "dafattscarichi",
      "qscarichi",
      "tariffascarichi",
      "bolloscarichi"
    );
    this.SumDaFatturare(
      "dafatttabulati",
      "qtabulati",
      "tariffatab",
      "bollotab"
    );
    this.SetDifferenzeDataFatt(
      "datafat",
      "dafatdiff",
      "dafattfatture",
      "dafattscarichi",
      "dafatttabulati"
    );
    this.SetDifferenzeDataFatt(
      "importo",
      "impdiff",
      "impaccrediti",
      "impscarichi",
      "imptabulati",
      "impnetto"
    );
  }

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: e.value
    });
  };

  handleTableClickScarichi = async (e) => {
    this.setState({
      selectedGridScarichi: e.value
    });
  };

  handleTableClickFatturate = async (e) => {
    this.setState({
      selectedGridFatturate: e.value
    });
  };

  handleTableClickCassa = async (e) => {
    this.setState({
      selectedGridCassa: e.value
    });
  };

  async handleDateChange(dateParam) {
    this.setState({
      anno: dateParam
    });
  }

  IncreaseYear() {
    this.setState({
      anno: this.state.anno + 1
    });
  }

  DecreaseYear() {
    this.setState({
      anno: this.state.anno - 1
    });
  }

  Delete(rowData) {
    if (rowData.id !== 0) {
      return (
        <Button
          round
          color="danger"
          className="actionButton actionButtonRound btnRound float-right"
          key={rowData.id}
          onClick={(e) => this.onDeleteClick(e, rowData.id, "fatture")}
        >
          <Close className="icon" />
        </Button>
      );
    } else {
      return <label>{}</label>;
    }
  }

  DeleteCassa(rowData) {
    if (rowData.id !== 0) {
      return (
        <Button
          round
          color="danger"
          className="actionButton actionButtonRound btnRound float-right"
          key={rowData.id}
          onClick={(e) => this.onDeleteClick(e, rowData.id, "cassa")}
        >
          <Close className="icon" />
        </Button>
      );
    } else {
      return <label>{}</label>;
    }
  }

  onDeleteClick(event, id, tipo) {
    let recordId = id;

    event.stopPropagation();

    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      // anchor: this.anchor,
      accept: () => this.DeleteRecord(recordId, tipo),
      reject: () => {}
    });
  }

  async DeleteRecord(id, tipo) {
    let response;
    if (tipo === "fatture") {
      response = await TabulatiAPI.delete(id);
    } else if (tipo === "cassa") {
      response = await CassaAPI.deleteCassa(id);
    }

    if (response.result === null) {
      window.setTimeout(() => {
        //this.onCloseGridClick();
        this.props.addAlert.current.addAlert("warning", response.message);
      }, 1200);
    } else {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("success", "Dati eliminati.");
      }, 1200);
    }
    this.setState({
      loadingGrid: true,
      selectedConsulente: null
    });
    await this.refreshGrid();
  }

  onRedirectDaTabulati() {
    this.props.history.push(
      "/admin/databulati/" +
        this.props.match.params.tabulatiId +
        this.props.location.search
    );
  }

  render() {
    return (
      <>
        <div ref={this.btnSearchRef} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <OtherButton
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Tabulati
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={5}
                  loading={this.state.loadingGrid}
                  //rowsPerPageOptions={[5, 10, 20]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="codice"
                    header="Cod"
                    headerStyle={{ width: "50px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="sede"
                    header="Sede"
                    headerStyle={{ width: "50px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="riparto"
                    header="Rip."
                    headerStyle={{ width: "40px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="righe"
                    header="Righe"
                    headerStyle={{ width: "60px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importo"
                    header="Importo"
                    headerStyle={{ width: "100px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "importo")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="tariff"
                    header="Tariff"
                    headerStyle={{ width: "80px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "tariff")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="bollo"
                    header="Bollo"
                    headerStyle={{ width: "100px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "bollo")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="spese"
                    header="Spese"
                    headerStyle={{ width: "100px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "spese")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="nettodaacrr"
                    header="NettoDaAcrr"
                    headerStyle={{ width: "100px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "nettodaacrr")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="accreditato"
                    header="Accr"
                    headerStyle={{ width: "100px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "accreditato")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="diffaccr"
                    header="Diff."
                    headerStyle={{ width: "100px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "diffaccr")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="errore"
                    header="Errore"
                    headerStyle={{ width: "100px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "errore")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="fatturato"
                    header="Fatturato"
                    headerStyle={{ width: "100px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "fatturato")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="difffatt"
                    header="DiffFatt"
                    headerStyle={{ width: "100px" }}
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "difffatt")
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="dataarr"
                    header="DataArr"
                    headerStyle={{ width: "80px" }}
                    body={(row) =>
                      moment(row.dataarr, dateTimeFmt).format(dateFmt)
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="tipoultimoagg"
                    header="TipoUltimoAgg"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="dataultimo"
                    header="DataUltimo"
                    headerStyle={{ width: "80px" }}
                    body={(row) =>
                      moment(row.dataultimo, dateTimeFmt).format(dateFmt)
                    }
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.visibleFields && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="ebinprofsuccess">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    Da Scarichi
                  </h4>
                  <FormControlLabel
                    className="float-right"
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%",
                      color: "white"
                    }}
                    control={
                      <Switch
                        checked={this.state.filterGridScarichi}
                        onChange={this.ChangeFilterGridScarichi}
                        name="chkFilterGrid"
                        color="default"
                      />
                    }
                    label={"Attiva Filtri"}
                  />
                  <ButtonGroup
                    variant="contained"
                    color="secondary"
                    size="small"
                    aria-label="contained secondary button group"
                    className="float-right"
                    style={{
                      verticalAlign: "center",
                      marginTop: "5px",
                      marginBottom: "5px",
                      marginRight: "5px"
                    }}
                  >
                    <ButtonsMUI onClick={this.onRedirectDaTabulati}>
                      Tabulati
                    </ButtonsMUI>
                  </ButtonGroup>
                </CardHeader>
                <CardBody>
                  <DataTable
                    emptyMessage=""
                    className="p-datatable-striped p-datatable-sm"
                    value={this.state.rowsScarichi}
                    dataKey="id"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                    rows={5}
                    loading={this.state.loadingGridScar}
                    paginatorLeft={this.paginatorLeft}
                    paginatorRight={this.paginatorRight}
                    selection={this.state.selectedGridScarichi}
                    scrollable
                    style={{ width: "100%" }}
                    onSelectionChange={this.handleTableClickScarichi}
                    selectionMode="single"
                  >
                    <Column
                      field="comp"
                      header="Comp"
                      headerStyle={{ width: "90px" }}
                      sortable={true}
                      filter={this.state.filterGridScarichi}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="numero"
                      header="# Quote"
                      headerStyle={{ width: "90px" }}
                      sortable={true}
                      filter={this.state.filterGridScarichi}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="impquota"
                      header="ImpQuota"
                      headerStyle={{ width: "150px" }}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "impquota")
                      }
                      sortable={true}
                      filter={this.state.filterGridScarichi}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                  </DataTable>
                </CardBody>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="ebinprofinfo">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    Fatture
                  </h4>
                  <FormControlLabel
                    className="float-right"
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%",
                      color: "white"
                    }}
                    control={
                      <Switch
                        checked={this.state.filterGridFatturate}
                        onChange={this.ChangeFilterGridFatturate}
                        name="chkFilterGrid"
                        color="default"
                      />
                    }
                    label={"Attiva Filtri"}
                  />
                  {!this.state.chiuso && (
                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Inserisci"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <CustomButton
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="float-right"
                        onClick={() => this.onNuovoRowClick("fatture")}
                      >
                        <AddIcon />
                      </CustomButton>
                    </Tooltip>
                  )}
                </CardHeader>
                <CardBody>
                  <DataTable
                    emptyMessage=""
                    className="p-datatable-striped p-datatable-sm"
                    value={this.state.rowsFatturate}
                    dataKey="id"
                    paginator
                    ref={this.gridFattureRef}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                    rows={5}
                    loading={this.state.loadingGridFatt}
                    onRowEditInit={this.onFatturateEditInit}
                    onRowEditCancel={this.onFatturateEditCancel}
                    onRowEditSave={this.onSpeseEditSave}
                    rowEditorValidator={this.speseEditorValidator}
                    editMode="row"
                    paginatorLeft={this.paginatorLeft}
                    paginatorRight={this.paginatorRight}
                    selection={this.state.selectedGridFatturate}
                    scrollable
                    style={{ width: "100%" }}
                    onSelectionChange={this.handleTableClickFatturate}
                    selectionMode="single"
                  >
                    {!this.state.chiuso && (
                      <Column
                        rowEditor
                        headerStyle={{ width: "80px" }}
                        bodyStyle={{ textAlign: "center" }}
                      />
                    )}
                    <Column
                      field="fatturate"
                      header="Fatturate"
                      editor={(props) =>
                        this.fatturateEditor("rowsFatturate", props)
                      }
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "fatturate")
                      }
                      headerStyle={{ width: "100px" }}
                      sortable={true}
                      filter={this.state.filterGridFatturate}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="datafat"
                      header="DataFatt"
                      body={(row) =>
                        moment(row.datafat, dateTimeFmt).format(dateFmt)
                      }
                      editor={(props) =>
                        this.datafatEditor("rowsFatturate", props)
                      }
                      headerStyle={{ width: "200px" }}
                      sortable={true}
                      filter={this.state.filterGridFatturate}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    {!this.state.chiuso && (
                      <Column
                        header=""
                        body={this.Delete}
                        icon="pi pi-times"
                        label="Delete"
                        className="p-button-danger p-button-outlined colDelete"
                      />
                    )}
                  </DataTable>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}

        {this.state.visibleFields && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="ebinprofinfo">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    Cassa
                  </h4>
                  <FormControlLabel
                    className="float-right"
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%",
                      color: "white"
                    }}
                    control={
                      <Switch
                        checked={this.state.filterGridCassa}
                        onChange={this.ChangeFilterGridCassa}
                        name="chkFilterGrid"
                        color="default"
                      />
                    }
                    label={"Attiva Filtri"}
                  />
                  {!this.state.chiuso && (
                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Inserisci"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <CustomButton
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="float-right"
                        onClick={() => this.onNuovoRowClick("cassa")}
                      >
                        <AddIcon />
                      </CustomButton>
                    </Tooltip>
                  )}
                </CardHeader>
                <CardBody>
                  <DataTable
                    emptyMessage=""
                    className="p-datatable-striped p-datatable-sm"
                    value={this.state.rowsCassa}
                    dataKey="id"
                    paginator
                    ref={this.gridCassaRef}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                    rows={5}
                    loading={this.state.loadingGridCassa}
                    onRowEditInit={this.onCassaEditInit}
                    onRowEditCancel={this.onCassaEditCancel}
                    onRowEditSave={this.onCassaEditSave}
                    rowEditorValidator={this.cassaEditorValidator}
                    editMode="row"
                    paginatorLeft={this.paginatorLeft}
                    paginatorRight={this.paginatorRight}
                    selection={this.state.selectedGridCassa}
                    scrollable
                    style={{ width: "100%" }}
                    onSelectionChange={this.handleTableClickCassa}
                    selectionMode="single"
                  >
                    {!this.state.chiuso && (
                      <Column
                        rowEditor
                        headerStyle={{ width: "40px" }}
                        bodyStyle={{ textAlign: "center" }}
                      />
                    )}
                    <Column
                      field="banca"
                      header="Banca"
                      headerStyle={{ width: "100px" }}
                      editor={(props) => this.bancaEditor("rowsCassa", props)}
                      sortable={true}
                      filter={this.state.filterGridCassa}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="nettoAccr"
                      header="NettoAccr."
                      headerStyle={{ width: "120px" }}
                      editor={(props) => this.nettoEditor("rowsCassa", props)}
                      body={(rowData) =>
                        Helpers.DecimalDTTemplate(rowData, "nettoAccr")
                      }
                      sortable={true}
                      filter={this.state.filterGridCassa}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    <Column
                      field="dataInc"
                      header="DataInc"
                      headerStyle={{ width: "100px" }}
                      body={(row) =>
                        moment(row.dataInc, dateTimeFmt).format(dateFmt)
                      }
                      editor={(props) =>
                        this.dataincassEditor("rowsCassa", props)
                      }
                      sortable={true}
                      filter={this.state.filterGridCassa}
                      filterMatchMode="contains"
                      filterPlaceholder=""
                    />
                    {!this.state.chiuso && (
                      <Column
                        header=""
                        headerStyle={{ width: "30px" }}
                        body={this.DeleteCassa}
                        icon="pi pi-times"
                        label="Delete"
                        className="p-button-danger p-button-outlined colDelete"
                      />
                    )}
                  </DataTable>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <CardHeader color="ebinprofinfo">
                  <h4
                    style={{
                      lineHeight: "1",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    Riepilogo
                  </h4>
                </CardHeader>
                <CardBody
                  style={{
                    backgroundColor: "white",
                    marginTop: "10px",
                    marginBottom: "10px",
                    marginLeft: "20px",
                    marginRight: "20px"
                  }}
                >
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2}>
                      <p>Quote</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <p>Importo</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <p>Tariffa</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <p>Bollo</p>{" "}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <p>Da Fatturare</p>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <p style={{ marginTop: "5px" }}>Da Scarichi</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="qscarichi"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.totQuoteScarichi || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="impscarichi"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: true, //convertor
                          value: currencyFormatter.format(
                            this.state.totImpScarichi || ""
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="tariffascarichi"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: false, //convertor,
                          type: "number",
                          step: "0.001",
                          onChange: (event) =>
                            this.SetText("tariffaScarichi", event.target.value),
                          value: this.state.tariffaScarichi ?? ""
                        }}
                        text="€"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="dafattscarichi"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: true, //convertor
                          value: currencyFormatter.format(
                            this.state.totQuoteScarichi *
                              this.state.tariffaScarichi
                          )
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <p style={{ marginTop: "5px" }}>Da Tabulati</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="qtabulati"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.totQuoteTabulati || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="imptabulati"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: true, //convertor
                          value: currencyFormatter.format(
                            this.state.totImpTabulati || ""
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="tariffatab"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: false, //convertor
                          type: "number",
                          value: this.state.selectedGrid?.tariff ?? "",
                          onChange: (e) =>
                            this.SetText("tariffa", e.target.value)
                        }}
                        text="€"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="bollotab"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: false, //convertor
                          type: "number",
                          step: "0.01",
                          onChange: (event) =>
                            this.setState({
                              bollo: parseFloat(event.target.value)
                            }),
                          value:
                            this.state.bollo !== null ? this.state.bollo : ""
                        }}
                        text="€"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="dafatttabulati"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: true, //convertor
                          value: currencyFormatter.format(
                            this.state.totQuoteTabulati * this.state.tariffa +
                              this.state.bollo
                          )
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <p style={{ marginTop: "5px" }}>Netto Da Accr</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} />

                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="impnetto"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: true, //convertor
                          value: currencyFormatter.format(
                            this.state.totImpNetto || ""
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2} />
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <p style={{ marginTop: "5px" }}>Accrediti</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="qaccrediti"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.totQuoteAccr || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="impaccrediti"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: true, //convertor
                          value: currencyFormatter.format(
                            this.state.totImpAccr || ""
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2} />
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <p style={{ marginTop: "5px" }}>Fatture</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="qfatture"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.totQuoteFatture || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="dafattfatture"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: true,
                          value: currencyFormatter.format(
                            this.state.totImpFatt || ""
                          )
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <p style={{ marginTop: "5px" }}>Differenze</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="impdiff"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: true,
                          value: currencyFormatter.format(
                            this.state.differenza || ""
                          )
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="dafatdiff"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: true,
                          value: currencyFormatter.format(
                            this.state.totImpFatt -
                              (this.state.totQuoteTabulati *
                                this.state.tariffa +
                                this.state.bollo)
                          )
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <p style={{ marginTop: "5px" }}>Errore</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        id="imperrore"
                        formControlProps={{
                          fullWidth: true,
                          className: "ctrlMuiToPF"
                        }}
                        inputProps={{
                          disabled: false,
                          type: "number",
                          onChange: (event) =>
                            this.setState({
                              errore: event.target.value
                            }),
                          value:
                            this.state.errore !== null ? this.state.errore : ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={2} />
                    <GridItem xs={12} sm={12} md={3}>
                      <p style={{ marginTop: "5px" }}>Data Tab.</p>
                      <Calendar
                        id="datapag"
                        value={this.state.data}
                        onChange={(event) =>
                          this.SetText("datatab", event.value)
                        }
                        monthNavigator
                        yearNavigator
                        dateFormat="dd/mm/yy"
                        yearRange="1900:2099"
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        )}
      </>
    );
  }
}

export default Tabulati;
