import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import YearPicker from "components/YearPicker/index";
import CustomButtons from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import MenuItem from "@material-ui/core/MenuItem";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import SedeComuneProvAPI from "API/SedeComuneProvAPI";
import AziendeAPI from "../../../API/AziendeAPI";
import { AutoComplete } from "primereact/autocomplete";
import CustomInput from "components/CustomInput/CustomInput.js";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
export class Header extends Component {
  state = {
    tipo: "Tutta",
    data: null,
    prot: null,
    causale: null,
    sede: null,
    provSap: null,
    sapMatricola: null,
    daMatricola: null,
    aMatricola: null,
    mittente: null,
    Causale: [],
    SedeInps: [],
    Prov_SAP: [],

    filteredDAMatricolaListByInput: null,
    filteredAMatricolaListByInput: null
  };

  constructor(props) {
    super(props);

    this.backButtonClick = this.backButtonClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.searchDAMatricola = this.searchDAMatricola.bind(this);
    this.searchAMatricola = this.searchAMatricola.bind(this);

    this.monthNavigatorTemplate = this.monthNavigatorTemplate.bind(this);
    this.yearNavigatorTemplate = this.yearNavigatorTemplate.bind(this);
  }

  monthNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }

  yearNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }

  async componentDidMount() {
    let allProvince = await SedeComuneProvAPI.GetAllProvincieProvincie();
    let allSedi = await SedeComuneProvAPI.GetAllSede();
    this.setState({
      Causale: await CorrispondenzaAPI.GetCausaliForCorrispondenza(),
      SedeInps: allSedi.map((x) => {
        return { sap: x.sap, descrizione: x.sap + " - " + x.descrizione };
      }),
      Prov_SAP: allProvince
    });
  }

  HandleChange(field, newValue) {
    this.setState({ [field]: newValue });
  }

  backButtonClick() {}

  handleClearClick() {
    this.setState({
      tipo: "Tutta",
      prot: null,
      causale: null,
      sede: null,
      provSap: null,
      sapMatricola: null,
      daMatricola: null,
      aMatricola: null,
      data: null
    });
    this.props.OnClear();
  }

  handleSearchClick() {
    this.props.OnSearch(
      this.state.tipo,
      this.props.Anno,
      this.state.data,
      this.state.prot,
      this.state.causale,
      this.state.codsap,
      this.state.provSap,
      this.state.sapMatricola,
      this.state.daMatricola,
      this.state.aMatricola,
      this.state.mittente
    );
  }

  HandleDropDownChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  HandleDAChangeMaxLength(field, newValue) {
    let test = newValue.toString();

    if (test.length <= 10) {
      this.setState({ daMatricola: newValue });
    }
  }

  HandleAChangeMaxLength(field, newValue) {
    let test = newValue.toString();

    if (test.length <= 10) {
      this.setState({ aMatricola: newValue });
    }
  }

  searchDAMatricola(event) {
    setTimeout(async () => {
      let list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByMatr(event.query)
          : [];
      this.setState({
        filteredDAMatricolaListByInput: list
      });
    }, 250);
  }

  searchAMatricola(event) {
    setTimeout(async () => {
      let list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByMatr(event.query)
          : [];
      this.setState({
        filteredAMatricolaListByInput: list
      });
    }, 250);
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                {this.props.visibleBack && (
                  <Button
                    icon="pi pi-arrow-left"
                    onClick={this.backButtonClick}
                    className="p-button-rounded p-button-primary p-button-sm float-left"
                    style={{ verticalAlign: "bottom", marginRight: "2px" }}
                  />
                )}

                <h4
                  style={{
                    lineHeight: this.props.lineHeight,
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Corrispondenza
                </h4>
              </CardHeader>

              <CardBody style={{ paddingTop: "2px" }}>
                <GridItem xs={12} sm={12} md={12}>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={3}
                      style={{ marginTop: "25px" }}
                    >
                      <div>
                        {this.props.Options.map((options) => {
                          return (
                            <div
                              key={options.id}
                              className="p-field-radiobutton"
                              style={{ display: "inline", marginRight: "8px" }}
                            >
                              <RadioButton
                                name="category"
                                value={options}
                                onChange={(e) =>
                                  this.props.RadioHandleChange("tipo", e.value)
                                }
                                checked={this.props.radioChecked === options.id}
                              />
                              <label
                                className="labelCheckbox"
                                htmlFor={options.id}
                              >
                                {options.name}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "3px", zIndex: "1000" }}
                      className="yearPicker"
                    >
                      <p
                        className="calendarParagraph"
                        style={{ color: "#565656" }}
                      >
                        <small>Anno</small>
                      </p>

                      <YearPicker
                        onChange={this.props.HandleYearPickerChange}
                        value={this.props.Anno}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Protocollo"
                        id="protocollo"
                        formControlProps={{
                          fullWidth: true
                        }}
                        style={{ color: "#565656" }}
                        inputProps={{
                          type: "number",
                          onChange: (event) =>
                            this.HandleChange("prot", event.target.value),
                          value: this.state.prot || ""
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "4px", zIndex: "1000" }}
                    >
                      <p
                        className="calendarParagraph"
                        style={{ color: "#565656" }}
                      >
                        <small>Data</small>
                      </p>
                      <Calendar
                        className="calendarCustomedForMUI"
                        id="date"
                        value={this.state.data}
                        dateFormat="dd/mm/yy"
                        onChange={(e) =>
                          this.HandleChange("data", e.target.value)
                        }
                        monthNavigator
                        yearNavigator
                        yearRange="1900:2999"
                        monthNavigatorTemplate={this.monthNavigatorTemplate}
                        yearNavigatorTemplate={this.yearNavigatorTemplate}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2} className="select">
                      <CustomSelect
                        labelText="Causale"
                        id="caus"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.props.disable,
                          onChange: (e) =>
                            this.HandleChange("causale", e.target.value),
                          value: this.state.causale || ""
                        }}
                      >
                        {this.state.Causale.map((item) => (
                          <MenuItem
                            value={item.codice.toString()}
                            key={item.codice}
                          >
                            {item.codice} - {item.descrizione}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="lblNoMargin">
                        <small>Sede INPS </small>
                      </p>
                      <Dropdown
                        value={this.state.codsap}
                        //required={true}
                        optionValue="sap"
                        className="dropdownCustomisedForMUI"
                        resetFilterOnHide
                        options={this.state.SedeInps}
                        onChange={(e) =>
                          this.HandleDropDownChange("codsap", e.target.value)
                        }
                        optionLabel="descrizione"
                        filter
                        filterBy="descrizione"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3} className="select">
                      <CustomSelect
                        labelText="Prov SAP"
                        id="provSAP"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.HandleChange("provSap", event.target.value),
                          value: this.state.provSap || ""
                        }}
                      >
                        {this.state.Prov_SAP.map((item) => (
                          <MenuItem value={item.sigla} key={item.sigla}>
                            {item.sigla} - {item.nome}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="lblNoMargin">
                        <small>SAP Matricola</small>
                      </p>
                      <Dropdown
                        value={this.state.sapMatricola}
                        id="sapmatricola"
                        optionValue="sap"
                        className="dropdownCustomisedForMUI"
                        resetFilterOnHide
                        options={this.state.SedeInps}
                        onChange={(e) =>
                          this.HandleDropDownChange(
                            "sapMatricola",
                            e.target.value
                          )
                        }
                        optionLabel="descrizione"
                        filter
                        filterBy="descrizione"
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "2px" }}
                    >
                      <p className="calendarParagraph">
                        <small>Da Matricola</small>
                      </p>
                      <AutoComplete
                        style={{ height: "26px" }}
                        value={this.state.daMatricola}
                        suggestions={this.state.filteredDAMatricolaListByInput}
                        completeMethod={this.searchDAMatricola}
                        onChange={(e) =>
                          this.HandleDAChangeMaxLength("matricola", e.value)
                        }
                        className="matricolaAutocomplete"
                        type="number"
                      />
                    </GridItem>

                    <GridItem
                      xs={12}
                      sm={12}
                      md={2}
                      style={{ marginTop: "2px" }}
                    >
                      <p className="calendarParagraph">
                        <small>A Matricola</small>
                      </p>
                      <AutoComplete
                        style={{ height: "26px" }}
                        value={this.state.aMatricola}
                        suggestions={this.state.filteredAMatricolaListByInput}
                        completeMethod={this.searchAMatricola}
                        onChange={(e) =>
                          this.HandleAChangeMaxLength("matricola", e.value)
                        }
                        className="matricolaAutocomplete"
                        type="number"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Mittente"
                        id="mittente"
                        formControlProps={{
                          fullWidth: true
                        }}
                        style={{ color: "#565656" }}
                        inputProps={{
                          type: "text",
                          onChange: (event) =>
                            this.HandleChange("mittente", event.target.value),
                          value: this.state.mittente || ""
                        }}
                      />
                    </GridItem>

                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Cerca"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <CustomButtons
                        ref={this.btnSearchRef}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        style={{ marginTop: "12px" }}
                        onClick={this.handleSearchClick}
                      >
                        <Search />
                      </CustomButtons>
                    </Tooltip>

                    <Tooltip
                      arrow
                      style={{ marginLeft: "4px" }}
                      id="tooltip-top"
                      title="Reimposta"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <CustomButtons
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        style={{ marginLeft: "4px", marginTop: "12px" }}
                        onClick={this.handleClearClick}
                      >
                        <Clear />
                      </CustomButtons>
                    </Tooltip>
                  </GridContainer>
                </GridItem>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
