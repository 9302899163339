import React, { createContext, useState } from "react";

// Api
import authApi from "API/auth.api";

// Common
import { getToken, removeToken, getRole, removeRole } from "common";
import UtentiAPI from "API/Utenti.api";

export const AuthContext = createContext(null);

const { Provider } = AuthContext;

export const AuthProvider = ({ ...props }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    getToken() ? true : false
  );
  const [token, setToken] = useState(getToken());
  const [role, setRole] = useState(getRole() || "");
  const [profile, setProfile] = useState(null);
  const [user, setUser] = useState(null);

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  async function login(username, password) {
    setIsLoggingIn(true);
    try {
      const res = await authApi.login(username, password);
      if (res) return res;
    } catch (error) {
      console.error("🚀 => LOGIN", error);
    } finally {
      setIsLoggingIn(false);
    }
  }

  function logout() {
    setIsAuthenticated(false);
    removeToken();
    removeRole();
    setToken("");
    setRole("");
    setProfile(null);
  }

  const [isLoadingProfile, setIsLoadingProfile] = useState(false);
  async function getProfile() {
    setIsLoadingProfile(true);
    try {
      const res = await authApi.getProfile();
      if (res) {
        setProfile(res.result[0]);
        const resUser = await UtentiAPI.getUserByUsername(
          res.result[0]?.username
        );
        if (resUser) {
          setUser(resUser[0]);
        } else logout();
      } else logout();
      return true;
    } catch (error) {
      console.error("🚀 => GET PROFILE", error);
    } finally {
      setIsLoadingProfile(false);
    }
  }

  return (
    <Provider
      value={{
        profile,
        setProfile,
        user,

        isAuthenticated,
        setIsAuthenticated,
        token,
        setToken,
        role,
        setRole,

        isLoggingIn,
        login,
        logout,

        isLoadingProfile,
        getProfile
      }}
    >
      {props.children}
    </Provider>
  );
};
