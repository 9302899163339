import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomInput from "components/CustomInput/CustomInput.js";
import LoaderSpinner from "../../components/Layout/LoaderSpinner.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import CustomButtons from "components/CustomButtons/Button.js";
import { ModificaCorrispXRichiedente } from "components/Views/Corrispondenza/ModificaCorrispXRichiedente.js";
import { Button } from "primereact/button";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import RichiedentiAPI from "API/RichiedentiAPI";
import RichiestiAPI from "API/RichiestiAPI.js";
import helpers from "helpers/Helpers.js";
import { rowsPerPageOptions } from "../../helpers/AppConstants";
import DipendentiAPI from "API/DipendentiAPI.js";

class CorrispXRichiedenti extends React.Component {
  state = {
    richiedenti: {},
    title: "Corrispondenza",
    disabledFields: false,
    richiesti: [],
    loadingGrid: false,
    visibleLoader: false,
    filterGrid: false,
    selectedGrid: null,
    attachments: [],
    rows: []
  };

  constructor(props) {
    super(props);
    this.backButtonClick = this.backButtonClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.btnSearchRef = React.createRef();
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

  buildRows(corrs, requests) {
    corrs.forEach((corr) => {
      let req = requests
        .filter((req) => req.idRichies === corr.idrichies)?.pop();
      corr.protRichies = req.protRichies;
    });
  }

  async getRequests(reqIds) {
    let requests = [];
    for (const i in reqIds) {
      const response = await RichiestiAPI.GetRequestById(reqIds[i]);
      requests.push(response[0]);
    }
    return requests;
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });
    try {
      const applicantId = this.props.match.params.richiedentiId;
      const applicant = await RichiedentiAPI.GetRichiedentiBorseStudio(
        applicantId
      );

      const workRelationship = await DipendentiAPI.GetRDLByDipendenteId(
        applicantId
      )[0];

      const corrs = await CorrispondenzaAPI.GetCorrispondenzaEbinByCF(
        applicant?.cfRich
      );

      this.setState({
        applicantEmail: applicant?.email,
        companyEmail: workRelationship?.email,
        richiedenti: applicant,
        applicant,
        rows: corrs.result
      });
    } catch (err) {
      this.props.addAlert.current.addAlert(
        "danger",
        "Si è verificato un errore nel caricamento dati"
      );
    }

    this.setState({
      loadingGrid: false
    });
  }

  async onNuovoClick() {
    this.setState({
      visibleLoader: true,
    });
    this.setState({
      selectedCorrisp:null
    })
    setTimeout(() => {
      let nuovocorrispondenza =
      CorrispondenzaAPI.GetNuovoCorrispondenzaForRichiedente();
      this.setState({
        selectedGrid: null,
        selectedCorrisp: nuovocorrispondenza
    });
      this.setState({
        visibleLoader: false
    });
    }, 200);
   
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  handleTableClick = async (e) => {
      this.setState({
        visibleLoader: true,
        selectedCorrisp: null
      });
      const corrisp = await CorrispondenzaAPI.getEbinCorrespondenceById(
        e.value.id
      );
      this.setState({
        selectedCorrisp: {
          ...corrisp,
          dtlett: helpers.GetDateObjectFromStringDate(corrisp.dtlett)
        },
        selectedGrid: e.value
      });
      this.setState({
        visibleLoader: false
      });
  };

  backButtonClick() {
    this.props.history.push(
      "/admin/richiedenti/modificainserisci/" +
        this.props.match.params.richiedentiId
    );
  }

  onCloseGridClick() {
    this.setState({
      selectedGrid: null,
      selectedCorrisp: null
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  formatDate(date) {
    const year = date?.getFullYear();
    const month = date?.getMonth() + 1;
    const _month = month > 10 ? month : `0${month}`;
    const day = date?.getDate();
    const _day = day > 10 ? day : `0${day}`;
    return `${_day}/${_month}/${year} 00:00:00`;
  }

  HandleSaveClick = async (
    data,
    user,
    files,
    otherRecipient,
    senderEmail,
    isLetterDateChanged
  ) => {
    this.setState({ loadingGrid: true, selectedCorrisp: null });
    const senderId = user[0]?.codice;
    const object = data?.oggetto;
    let json;

    const applicantEmail = this.state?.applicantEmail?.toLowerCase();
    const companyEmail = this.state?.companyEmail?.toLowerCase();
    const recipientEmails = [];
    otherRecipient && recipientEmails.push(otherRecipient);
    applicantEmail && recipientEmails.push(applicantEmail);
    companyEmail && recipientEmails.push(companyEmail);
    let letterDate = !!data.dtlett ? data?.dtlett : null;
    if(!!letterDate){
      letterDate = isLetterDateChanged ? letterDate : this.formatDate(data?.dtlett);
    }

    if (data.id) {
      json = {
        id: data?.id,
        cfrichied: this.state?.richiedenti?.cfRich,
        anno: data?.anno,
        causale: data?.causale,
        tipoinvio: data?.tipoinvio,
        prot: data.prot,
        tipo: data?.tipo === 1 ? "ENTRATA" : "USCITA",
        oggetto: data?.oggetto,
        dtlett: letterDate,
        data: `${data?.data} 00:00:00`,
        testomail: data?.testomail,
        mittmail: data?.mittmail,
        mitt_dest: `${this.state.richiedenti?.cognome} ${this.state.richiedenti?.nome}`
      };
      const res = await CorrispondenzaAPI.UpdateCorrispondenzaEbin1(
        senderId,
        recipientEmails,
        object,
        json,
        files
      );
      this.setState({ loadingGrid: false, selectedCorrisp: {} });
    } else {
      json = {
        cfrichied: this.state.richiedenti?.cfRich,
        anno: data?.anno,
        causale: data?.causale,
        tipoinvio: data?.tipoinvio,
        tipo: data?.tipo === 1 ? "ENTRATA" : "USCITA",
        oggetto: data?.oggetto,
        dtlett: letterDate,
        data: `${data?.data} 00:00:00`,
        testomail: data?.testomail,
        mittmail: data?.mittmail,
        mitt_dest: `${this.state.richiedenti?.cognome} ${this.state.richiedenti?.nome}`
      };
      const res = await CorrispondenzaAPI.AddCorrispondenzaEbin(
        senderId,
        recipientEmails,
        object,
        json,
        files
      );
      if (json?.tipo?.toUpperCase() === 'USCITA' && json?.tipoinvio === 1) {
        const ris = new Blob([res.data]);
        let url = window.URL.createObjectURL(ris);
        let a = document.createElement("a");
        a.href = url;
        a.download = "lettera.pdf";
        a.click();
        a.remove();
        this.setState({
          selectedCorrisp: null,
          selectedGrid: null
        });
      }
      this.setState({ loadingGrid: false, selectedCorrisp: {} });
    }

    window.setTimeout(async () => {
      const corrs = await CorrispondenzaAPI.GetCorrispondenzaEbinByCF(
        this.state.applicant?.cfRich
      );
      this.setState({ rows: corrs.result, selectedCorrisp: {} });
      this.props.addAlert.current.addAlert("success", "Dati salvati.");
    }, 2200);
    return true;
  };

  render() {
    return (
      <>
        {" "}
        <div ref={this.btnSearchRef} />
        {this.state.visibleLoader && <LoaderSpinner fullHeight={true} />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <Button
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />

                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Richiedente
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Codice Fiscale"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.richiedenti?.cfRich || ""
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Nominativo"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.richiedenti?.cognome
                          ? `${this.state.richiedenti?.cognome} ${this.state.richiedenti?.nome}`
                          : ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borsesuccess">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Corrispondenza
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <CustomButtons
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </CustomButtons>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10}
                  rowsPerPageOptions={rowsPerPageOptions}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  scrollDirection="horizontal"
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="anno"
                    header="Anno"
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterPlaceholder=""
                  />
                  <Column
                    field="prot"
                    header="Prot."
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="tipo"
                    header="Tipo"
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="tipoinvio"
                    body={(rowData) =>
                      rowData?.tipoinvio?.id === 1 ? "Lettera" : "E-mail"
                    }
                    header="Tipo Invio"
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data"
                    header="Data"
                    body={(rowData) => rowData?.data?.substr(0, 10)}
                    headerStyle={{ width: "90px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterPlaceholder=""
                  />
                  <Column
                    field="causale.codice"
                    header="Causale"
                    headerStyle={{ width: "110px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="mitt_dest"
                    header="Mitt. / Dest."
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="oggetto"
                    header="Oggetto"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.selectedCorrisp && (
          <ModificaCorrispXRichiedente
            activeSnackbar={this.props.addAlert.current.addAlert}
            dataItem={this.state.selectedCorrisp}
            onSaveClick={this.HandleSaveClick}
            title={this.state.title}
            attachments={this.state.attachments}
            disabledFields={this.state.disabledFields}
            history={this.props.history}
            onAnnullaClick={this.onCloseGridClick}
          />
        )}
      </>
    );
  }
}

export default CorrispXRichiedenti;
