import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import ButtonsMUI from "@material-ui/core/Button";
import Clear from "@material-ui/icons/Clear";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Search from "@material-ui/icons/Search";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import StatisticheAPI from "../../API/StatisticheAPI";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";
import Utils from "../../API/Utils";

const List = [
  { id: 1, name: "Consulenti Attivi con numero Aziende" },
  { id: 2, name: "Consulenti con Email o PEC" },
  { id: 3, name: "Consulenti per Provincia" },
  { id: 4, name: "Durata rapporti Consulenti-Ditte" }
];

class ConsulentiXStatistiche extends Component {

  state = {
    rows: [],
    columns: [],
    filterGrid: null,
    selectedGrid: null,
    anno: new Date().getFullYear(),
    loadingGrid: false,
    radioChecked: null,
    selectedRadioButton: null,
    loadingPage: false,
    rowsPerPage: 5
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.IncreaseYear = this.IncreaseYear.bind(this);
    this.DecreaseYear = this.DecreaseYear.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.RadioHandleChange = this.RadioHandleChange.bind(this);
    this.onClickEsportaDettaglio = this.onClickEsportaDettaglio.bind(this);
  }

  async onClickEsportaDettaglio() {
    if (this.state.selectedGrid) {
      let params = [];
      let queryName;
      switch (this.state.selectedRadioButton.id) {
        case 1:
          params.push(this.state.selectedGrid.num_aziende);
          queryName = "statistiche.consulenti.AttiviNumAziendeDettaglio";
          break;
        case 2:
          params.push(this.state.selectedGrid.tipo);
          queryName = "statistiche.consulenti.ConEmailOPecDettaglio";
          break;
        case 3:
          params.push(this.state.selectedGrid.prov);
          queryName = "statistiche.consulenti.XProvinciaDettaglio";
          break;
        case 4:
          params.push(this.state.selectedGrid.num_mesi);
          queryName = "statistiche.consulenti.XRapportiConsAzDettaglio";
          break;
      }

      this.setState(
          {loadingPage: true},
          async () => {
            await StatisticheAPI.getDettaglio(queryName, params);
            this.setState({loadingPage: false});
          }
      );
    }
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  async handleSearchClick() {
    this.setState({selectedGrid: null});
    await this.refreshGrid();
  }

  async handleDateChange(dateParam) {
    this.setState({
      anno: dateParam
    });
  }

  IncreaseYear() {
    this.setState(
      {
        anno: this.state.anno + 1
      },
      async () => await this.refreshGrid()
    );
  }

  DecreaseYear() {
    this.setState(
      {
        anno: this.state.anno - 1
      },
      async () => await this.refreshGrid()
    );
  }

  async handleClearClick() {
    this.setState({
      selectedRadioButton: null,
      radioChecked: null,
      anno: new Date().getFullYear(),
      rows: [],
      columns: [],
      selectedGrid: null
    });
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  HandleDropDownChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  RadioHandleChange(e, val) {
    this.setState({
      [e]: val
    });

    if (val !== null)
      this.setState({
        radioChecked: val.id
      });
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true
    });

    if (this.state.selectedRadioButton !== null) {
      switch (this.state.selectedRadioButton.id) {
        case 1:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.consulenti.AttiviNumAziende"),
            columns: [{ field: "count", header: "Count" }, { field: "num_aziende", header: "Numero Aziende" }]
          });
          break;
        case 2:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.consulenti.ConEmailOPec"),
            columns: [{ field: "count", header: "Count" }, { field: "tipo", header: "Tipo" }]
          });
          break;
        case 3:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.consulenti.XProvincia"),
            columns: [ { field: "count", header: "Count" }, { field: "prov", header: "Provincia" }, { field: "regione", header: "Regione" } ]
          });
          break;

        case 4:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.consulenti.XRapportiConsAz"),
            columns: [ { field: "count", header: "Count" }, { field: "num_mesi", header: "Numero Mesi" } ]
          });
          break;

        default:
          this.setState({
            rows: [], columns: []
          });
      }
    } else this.setState({rows: [], columns: []});

    this.setState({
      loadingGrid: false
    });
  }

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: e.value
    });
  };

  render() {
    const dynamicColumns = this.state.columns.map(col => {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          sortable={true}
          filter={this.state.filterGrid}
        />
      );
    });
    return (
      <>
        {this.state.loadingPage && (<LoaderSpinner />)}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4>
                  Statistiche Consulenti
                </h4>
              </CardHeader>
              <CardBody
                style={{
                  paddingTop: "8px",
                  paddingBottom: "6px"
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "8px" }}>
                    <Dropdown
                      display="chip"
                      optionLabel="name"
                      placeholder="Cerca per tipo"
                      maxSelectedLabels={1}
                      value={this.state.selectedRadioButton}
                      options={List}
                      onChange={(e) =>
                        this.RadioHandleChange("selectedRadioButton", e.value)
                      }
                    />
                  </GridItem>

                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Cerca"
                    placement="top"
                    style={{ marginTop: "5x" }}
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleSearchClick}
                    >
                      <Search />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    arrow
                    style={{ marginLeft: "4px", marginTop: "5px" }}
                    id="tooltip-top"
                    title="Reimposta"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleClearClick}
                    >
                      <Clear />
                    </Button>
                  </Tooltip>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className="d-inline">
                  Elaborazioni Statistiche per Consulenti
                </h4>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  size="small"
                  aria-label="contained primary button group"
                  className="float-right m-1"
                >
                  <ButtonsMUI onClick={() => Utils.ExportExcel(this.state.rows)}>Export</ButtonsMUI>
                  {this.state.selectedGrid && (
                      <ButtonsMUI onClick={this.onClickEsportaDettaglio}>Esporta dettaglio</ButtonsMUI>
                  )}
                </ButtonGroup>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage}
                  loading={this.state.loadingGrid}
                  rowsPerPageOptions={[5, 10, 20]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  {dynamicColumns}
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default ConsulentiXStatistiche;
