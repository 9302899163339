import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import LoaderSpinner from "../../../src/components/Layout/LoaderSpinner.js";
import { AutoComplete } from "primereact/autocomplete";
import helpers from "helpers/Helpers";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { ModificaDipendenteXAzienda } from "components/Views/Dipendenti/ModificaDipendenteXAzienda.js";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ButtonsMUI from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";
import queryString from "query-string";
import { confirmPopup } from "primereact/confirmpopup";
import DipendentiAPI from "API/DipendentiAPI";
import RDLAPI from "API/RDLAPI";
import { Button as BackButton } from "primereact/button";
import { AuthContext } from "providers";
import UtentiAPI from "API/Utenti.api.js";
import {rowsPerPageOptions} from "../../helpers/AppConstants";

class Dipendenti extends React.Component {
  static contextType = AuthContext;

  state = {
    defaultCompany: 1500209,

    filteredRows: [],
    initialRows: [],
    totalRecords: 0,
    viewButtons: false,

    selectedGridDipendente: null,
    selectedDipendente: null,

    visibleLoader: false,

    sortField: "cf",
    sortOrder: 1,

    rowsPerPage: 10,
    loadingGrid: false,

    filterByNominativo: "",
    filterByCodice: "",
    nominativoFullList: [],
    codiceFullList: [],
    filteredCodiceListByInput: [],
    filteredNominativoListByInput: [],

    firstRowToDisplay: 0,

    viewRDLNuovoDip: false
  };

  constructor(props) {
    super(props);

    this.handleTableClick = this.handleTableClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.ChangeButtons = this.ChangeButtons.bind(this);
    this.btnSearchRef = React.createRef();
    this.RedirectBtn = this.RedirectBtn.bind(this);
    this.DeletePulitoriGridTemplate =
      this.DeletePulitoriGridTemplate.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);

    this.searchCf = this.searchCf.bind(this);
    this.searchNominativo = this.searchNominativo.bind(this);
    this.DeleteRecord = this.DeleteRecord.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.LoadElementsInGrid = this.LoadElementsInGrid.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
  }

  async onNuovoClick() {
    this.setState({
      visibleLoader: true
    });

    let nuovodipendenti = await DipendentiAPI.GetNuovoDipendentiById();
    this.setState({
      selectedGridDipendente: null,
      company: this.state.defaultCompany,
      selectedDipendente: nuovodipendenti,
      viewButtons: false,
      viewRDLNuovoDip: false
    });

    this.setState({
      visibleLoader: false
    });
  }

  searchNominativo(event) {
    setTimeout(async () => {
      let list =
        event.query.trim().length >= 3
          ? await DipendentiAPI.SearchByNominativo(event.query)
          : [];

      let newList = [];

      let i;
      for (i = 0; i < list.length; i++) {
        newList.push(list[i].nominativo);
      }

      list = newList;

      this.setState({
        filteredNominativoListByInput: list
      });
    }, 250);
  }

  searchCf(event) {
    setTimeout(async () => {
      let list =
        event.query.trim().length >= 3
          ? await DipendentiAPI.SearchByCf(event.query)
          : [];
      this.setState({
        filteredCodiceListByInput: list
      });
    }, 250);
  }

  RedirectBtn(buttonName) {
    if (buttonName === "Storico")
      this.props.history.push(
        "/admin/StoricoXDipendente/" +
          "0" +
          "/" +
          this.state.selectedDipendente.id
      );
    else if (buttonName === "RDL")
      this.props.history.push(
        "/admin/RDL/" + 0 + "/" + this.state.selectedDipendente.id
      );
    else if (buttonName === "Corrisp")
      this.props.history.push(
        `/admin/Dipendenti/${this.state.selectedDipendente.id}/Corrispondenza`
      );
  }
  async onCloseGridClick() {
    this.setState({
      selectedGridDipendente: null,
      selectedDipendente: null
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  DeletePulitoriGridTemplate(rowData) {
    if (rowData.utente !== "Sistema")
      return (
        <Button
          round
          color="danger"
          className="actionButton actionButtonRound btnRound float-right"
          key={rowData.Id}
          onClick={(e) => this.onDeleteClick(e, rowData.id)}
        >
          <Close className="icon" />
        </Button>
      );
  }

  onDeleteClick(event, id) {
    let recordId = id;

    event.stopPropagation();

    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => this.DeleteRecord(recordId),
      reject: () => {}
    });
  }

  async DeleteRecord(id) {
    let response;

    response = await DipendentiAPI.DeleteDipendenti(id);

    if (response.result === null) {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("warning", response.message);
      }, 1200);
    } else {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("success", "Dati eliminati.");
      }, 1200);
    }

    this.setState({
      loadingGrid: true,
      selectedDipendente: null
    });

    let filterByCodice = this.state.filterByCodice;
    let filterByNominativo = this.state.filterByNominativo;

    let sortField = "cf";
    let sortOrder = 1;
    let pageToView = 0;
    let queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      let savedFilters = helpers.GetSearchFilters("DipendentiList");
      if (savedFilters !== null) {
        filterByCodice = savedFilters.filterByCodice;
        filterByNominativo = savedFilters.filterByNominativo;
      }
      let savedOrders = helpers.GetSearchFilters("DipendenteListOrder");
      if (savedOrders !== null) {
        sortField = savedOrders.sortField;
        sortOrder = savedOrders.sortOrder;
      }

      pageToView = helpers.GetLastPage("DipendenteListPage");
    } else {
      helpers.SaveSearchFilters(null, "DipendenteListOrder");
      helpers.SaveSearchFilters(null, "DipendentiList");
    }

    let responseObject = await DipendentiAPI.SearchPageSort(
      filterByNominativo,
      filterByCodice,
      pageToView,
      sortField,
      sortOrder
    );
    let filteredRowsInitial = responseObject.result;

    this.setState(
      {
        filteredRows: filteredRowsInitial,
        totalRecords: responseObject.tot,
        filterByNominativo: filterByNominativo,
        filterByCodice: filterByCodice,
        lastPage: pageToView,
        sortField: sortField,
        sortOrder: sortOrder
      },
      async () => {
        let filteredRows = filteredRowsInitial;

        let selectedRow = null;

        let dipendenteId = this.props.match.params.dipendenteId;
        if (dipendenteId > 0) {
          selectedRow = helpers.ReturnRowFromListById(
            filteredRows,
            dipendenteId
          );
        }

        this.setState({
          firstRowToDisplay: pageToView * 10,
          filteredRows: filteredRows
        });

        if (selectedRow)
          await this.handleTableClick({
            value: selectedRow
          });
      }
    );

    this.setState({
      loadingGrid: false
    });
  }

  handleTableClick = async (e) => {
    this.setState({
      visibleLoader: true
    });

    let dipendente = await DipendentiAPI.GetDipendentiById(e.value.id);
    const rdlRes = await RDLAPI.getByEmployeeId(dipendente[0].id);

    this.setState({
      selectedDipendente: dipendente[0],
      company:
        rdlRes.length > 0 ? rdlRes?.pop()?.azienda?.id : this.state.defaultCompany,
      selectedGridDipendente: e.value,
      viewButtons: true,
      viewRDLNuovoDip: false
    });

    this.setState({
      visibleLoader: false
    });
  };

  HandleChange = async (event, val) => {
    this.setState({
      [event]: val
    });
  };

  handleSearchClick = async () => {
    helpers.SaveSearchFilters(
      {
        filterByCodice: this.state.filterByCodice,
        filterByNominativo: this.state.filterByNominativo
      },
      "DipendentiList"
    );

    this.setState({
      loadingGrid: true,
      selectedDipendente: null,
      selectedGridDipendente: null
    }, async () => await this.LoadElementsInGrid());
  };

  async handleClearClick() {
    helpers.SaveSearchFilters(null, "DipendenteListOrder");
    helpers.SaveSearchFilters(null, "DipendentiList");
    this.setState({
      loadingGrid: true
    });
    this.setState(
      {
        selectedDipendente: null,
        selectedGridDipendente: null,

        filteredCodiceListByInput: [],
        filteredNominativoListByInput: [],

        filterByNominativo: "",
        filterByCodice: ""
      },
      async () => {
        await this.LoadElementsInGrid();
      }
    );
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });

    let filterByCodice = this.state.filterByCodice;
    let filterByNominativo = this.state.filterByNominativo;

    let sortField = "cf";
    let sortOrder = 1;
    let pageToView = 0;
    let queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      let savedFilters = helpers.GetSearchFilters("DipendentiList");
      if (savedFilters !== null) {
        filterByCodice = savedFilters.filterByCodice;
        filterByNominativo = savedFilters.filterByNominativo;
      }
      let savedOrders = helpers.GetSearchFilters("DipendenteListOrder");
      if (savedOrders !== null) {
        sortField = savedOrders.sortField;
        sortOrder = savedOrders.sortOrder;
      }

      pageToView = helpers.GetLastPage("DipendenteListPage");
    } else {
      helpers.SaveSearchFilters(null, "DipendenteListOrder");
      helpers.SaveSearchFilters(null, "DipendentiList");
    }

    let responseObject = await DipendentiAPI.SearchPageSort(
      filterByNominativo, filterByCodice,
      0, 10,
      sortField, sortOrder
    );
    let filteredRowsInitial = responseObject.result;

    this.setState(
      {
        filteredRows: filteredRowsInitial,
        totalRecords: responseObject.tot,
        filterByNominativo: filterByNominativo,
        filterByCodice: filterByCodice,
        lastPage: pageToView,
        sortField: sortField,
        sortOrder: sortOrder
      },
      async () => {
        let filteredRows = filteredRowsInitial;

        let selectedRow = null;

        let dipendenteId = this.props.match.params.dipendenteId;
        if (dipendenteId > 0) {
          selectedRow = (
            await DipendentiAPI.GetDipendentiById(dipendenteId)
          )[0];
        }

        this.setState({
          firstRowToDisplay: pageToView * 10,
          filteredRows: filteredRows
        });

        if (selectedRow)
          await this.handleTableClick({
            value: selectedRow
          });
      }
    );

    this.setState({
      loadingGrid: false
    });
  }

  async LoadElementsInGrid() {
    let responseObject = await DipendentiAPI.SearchPageSort(
      this.state.filterByNominativo,
      this.state.filterByCodice,
      this.state.firstRowToDisplay, this.state.rowsPerPage,
      this.state.sortField,
      this.state.sortOrder
    );

    this.setState({
      filteredRows: responseObject.result,
      totalRecords: responseObject.tot,
      loadingGrid: false
    });
  }

  async onSortChange(e) {
    helpers.SaveSearchFilters(
      {
        sortField: e.sortField,
        sortOrder: e.sortOrder
      },
      "DipendenteListOrder"
    );

    this.setState(
      {
        sortField: e.sortField,
        sortOrder: e.sortOrder,
        loadingGrid: true
      },
      async () => {
        await this.LoadElementsInGrid();
      }
    );
  }

  async onPageChange(e) {
    this.setState({
      firstRowToDisplay: e.first,
      rowsPerPage: e.rows,
      loadingGrid: true
    }, async () => await this.LoadElementsInGrid());
  }

  HandleSaveClick = async (RowItem) => {
    const { profile } = this.context;
    const { username } = profile;
    const res = await UtentiAPI.getUserByUsername(username);
    const user = res[0].utente;
    if (RowItem.id === null) {
      let dipendenteNuovo = await DipendentiAPI.AddDipendenti(RowItem, user);
      if (dipendenteNuovo.message.includes("200")) {
        this.state.selectedDipendente = dipendenteNuovo.result[0];
        this.state.viewRDLNuovoDip = true;
        this.props.addAlert.current.addAlert("success", "Dati salvati.");
      } else {
        this.props.addAlert.current.addAlert("error", "Dati non salvati.");
      }
    } else {
      let dipendenteNuovo = await DipendentiAPI.UpdateDipendenti(RowItem, user);
      if (dipendenteNuovo.message.includes("200")) {
        this.props.addAlert.current.addAlert("success", "Dati salvati.");
      } else {
        this.props.addAlert.current.addAlert("error", "Dati non salvati.");
      }
    }

    let filterByCodice = this.state.filterByCodice;
    let filterByNominativo = this.state.filterByNominativo;

    let sortField = "cf";
    let sortOrder = 1;
    let pageToView = 0;
    let queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      let savedFilters = helpers.GetSearchFilters("DipendentiList");
      if (savedFilters !== null) {
        filterByCodice = savedFilters.filterByCodice;
        filterByNominativo = savedFilters.filterByNominativo;
      }
      let savedOrders = helpers.GetSearchFilters("DipendenteListOrder");
      if (savedOrders !== null) {
        sortField = savedOrders.sortField;
        sortOrder = savedOrders.sortOrder;
      }

      pageToView = helpers.GetLastPage("DipendenteListPage");
    } else {
      helpers.SaveSearchFilters(null, "DipendenteListOrder");
      helpers.SaveSearchFilters(null, "DipendentiList");
    }

    let responseObject = await DipendentiAPI.SearchPageSort(
      filterByNominativo,
      filterByCodice,
      pageToView,
      sortField,
      sortOrder
    );
    const filteredRowsInitial = responseObject.result;

    this.setState(
      {
        filteredRows: filteredRowsInitial,
        totalRecords: responseObject.tot,
        filterByNominativo: filterByNominativo,
        filterByCodice: filterByCodice,
        lastPage: pageToView,
        sortField: sortField,
        sortOrder: sortOrder
      },
      async () => {
        let filteredRows = filteredRowsInitial;

        let selectedRow = null;

        const dipendenteId = this.props.match.params.dipendenteId;
        if (dipendenteId > 0) {
          selectedRow = helpers.ReturnRowFromListById(
            filteredRows,
            dipendenteId
          );
        }

        this.setState({
          firstRowToDisplay: pageToView * 10,
          filteredRows: filteredRows
        });

        if (selectedRow)
          await this.handleTableClick({
            value: selectedRow
          });
      }
    );

    this.setState({
      loadingGrid: false
    });

    return true;
  };

  ChangeButtons = async (boolean, existentDipendenti) => {
    await this.handleTableClick({
      value: existentDipendenti[0]
    });
  };

  async backButtonClick() {
    const qS = queryString.parse(this.props.location.search);
    if (!qS.type) await this.handleClearClick();
    else if (qS.type === "fromFiltroGenerico")
      this.props.history.push("/admin/filtrogenerico?maintainSearch=1");
  }

  render() {
    return (
      <>
        {this.state.visibleLoader && <LoaderSpinner />}

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <BackButton
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />
                <h4 style={{ lineHeight: "1" }}>Dipendenti</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={3} style={{ marginTop: "6px" }}>
                    <AutoComplete
                      value={this.state.filterByNominativo}
                      suggestions={this.state.filteredNominativoListByInput}
                      placeholder="Cerca per Nominativo"
                      completeMethod={this.searchNominativo}
                      onChange={(e) =>
                        this.HandleChange("filterByNominativo", e.value)
                      }
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.handleSearchClick();
                        }
                      }}
                    />
                  </GridItem>

                  <GridItem xs={3} style={{ marginTop: "6px" }}>
                    <AutoComplete
                      value={this.state.filterByCodice}
                      suggestions={this.state.filteredCodiceListByInput}
                      completeMethod={this.searchCf}
                      placeholder="Cerca per Codice"
                      onChange={(e) =>
                        this.HandleChange("filterByCodice", e.value)
                      }
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          this.handleSearchClick();
                        }
                      }}
                    />
                  </GridItem>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Cerca"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      ref={this.btnSearchRef}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleSearchClick}
                    >
                      <Search />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Reimposta"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{ marginLeft: "4px" }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleClearClick}
                    >
                      <Clear />
                    </Button>
                  </Tooltip>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Elenco Dipendenti
                </h4>

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.filteredRows}
                  dataKey="id"
                  first={this.state.firstRowToDisplay}
                  onPage={this.onPageChange}
                  onSort={this.onSortChange}
                  sortField={this.state.sortField}
                  sortOrder={this.state.sortOrder}
                  lazy
                  totalRecords={this.state.totalRecords}
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage} rowsPerPageOptions={rowsPerPageOptions}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGridDipendente}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="nominativo"
                    header="Nominativo"
                    sortable={true}
                  />
                  <Column
                    field="cf"
                    header="Codice fiscale"
                    sortable={true}
                  />
                  <Column
                    field="indirizzo"
                    header="Indirizzo"
                    sortable={true}
                  />
                  <Column
                    field="comunex"
                    header="Comune"
                    sortable={true}
                  />
                  <Column
                    field="email"
                    header="E-mail"
                    headerStyle={{ width: "250px" }}
                    sortable={true}
                  />
                  <Column
                    field="pec"
                    header="Pec"
                    sortable={true}
                  />
                  <Column
                    header=""
                    body={this.DeletePulitoriGridTemplate}
                    icon="pi pi-times"
                    label="Delete"
                    className="p-button-danger p-button-outlined colDelete"
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.selectedDipendente && (
          <ModificaDipendenteXAzienda
            aziendaId={this.props.match.params.dipendenteId}
            dataItem={this.state.selectedDipendente}
            history={this.props.history}
            onAnnullaClick={this.onCloseGridClick}
            onSaveClick={this.HandleSaveClick}
            addAlert={this.props.addAlert}
            ChangeButtons={this.ChangeButtons}
          >
            {this.state.viewButtons && (
              <ButtonGroup
                variant="contained"
                color="primary"
                size="small"
                aria-label="contained primary button group"
                className="float-right"
                style={{ verticalAlign: "center", margin: "0.5%" }}
              >
                <ButtonsMUI onClick={() => this.RedirectBtn("Storico")}>
                  Storico Dip.
                </ButtonsMUI>
                <ButtonsMUI onClick={() => this.RedirectBtn("RDL")}>
                  RDL
                </ButtonsMUI>
                <ButtonsMUI onClick={() => this.RedirectBtn("Corrisp")}>
                  Corrisp.
                </ButtonsMUI>
              </ButtonGroup>
            )}

            {this.state.viewRDLNuovoDip && (
              <ButtonGroup
                variant="contained"
                color="primary"
                size="small"
                aria-label="contained primary button group"
                className="float-right"
                style={{ verticalAlign: "center", margin: "0.5%" }}
              >
                <ButtonsMUI onClick={() => this.RedirectBtn("RDL")}>
                  RDL
                </ButtonsMUI>
              </ButtonGroup>
            )}
          </ModificaDipendenteXAzienda>
        )}
      </>
    );
  }
}

export default Dipendenti;
