import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import Tooltip from "@material-ui/core/Tooltip";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ButtonsMUI from "@material-ui/core/Button";
import StaticLists from "helpers/StaticLists.js";
import AziendeAPI from "../../API/AziendeAPI.js";
import { RadioButton } from "primereact/radiobutton";
import AmministratoriAPI from "API/AmministratoriAPI.js";
import ConsulentiAPI from "API/ConsulentiAPI.js";
import DipendentiAPI from "API/DipendentiAPI.js";
import SedeComuneProvAPI from "../../API/SedeComuneProvAPI";
import RegioniAPI from "../../API/RegioniAPI";
import queryString from "query-string";
import helpers from "../../helpers/Helpers";
import PreparaMailGenerico from "./PreparaMailGenerico";
import CorrispondenzaAPI from "../../API/CorrispondenzaAPI";
import authApi from "../../API/auth.api";

class ImpostazioneFiltroXSpedizioni extends React.Component {
  state = {
    rows: [],
    selectedGrid: null,
    selectedTipoDestinario: null,
    selectedCaratteristiche: null,
    TipoDestinatario: [],
    Caratteristiche: [],
    loadingGrid: false,
    selectedProvincia: null,
    selectedRegione: null,
    selectedCodQ: null,
    name: "",
    arrayId: null,
    columns: [],
    Provincia: [],
    Regione: [],
    CodiceQuota: [],
    List: [],
    boolList: false,
    visibleRadioButtons: false,
    radioChecked: null,
    selectedRadioButton: null,
    isPreparaMail: false,
    prots: null,
    ufficio: null
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.onRedirectClick = this.onRedirectClick.bind(this);
    this.DocActionsGridTemplate = this.DocActionsGridTemplate.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.btnSearchRef = React.createRef();
    this.annullaClick = this.annullaClick.bind(this);
    this.PreparaMail = this.PreparaMail.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
  }

  async HandleSaveClick(
    anno,
    dataProt,
    oggetto,
    emailText,
    senderEmail,
    files,
    selectedCaus,
    altroDest
  ) {
    try{
      const response = await CorrispondenzaAPI.PreparaMailGenerico(
        anno,
        dataProt,
        oggetto,
        emailText,
        senderEmail,
        files,
        selectedCaus,
        this.state.name,
        this.state.selectedGrid,
        altroDest, this.state.ufficio
      );
      response === 200
        ? this.props.addAlert.current.addAlert(
            "success",
            "Richiesta di preparazione mail presa in carico, per il download del report accedere alla sezione “Report Comunicazioni”."
          )
        : this.props.addAlert.current.addAlert(
            "danger",
            "La richiesta di preparazione mail non è stata presa in carico correttamente."
          );
    } catch(err){
        this.props.addAlert.current.addAlert(
          "danger",
          "Errore generico"
        );
    }
    return true;
    
  }

  PreparaMail() {
    if (
      this.state.selectedGrid !== null &&
      this.state.selectedGrid.length > 0
    ) {
      this.setState({ isPreparaMail: true });
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare almeno un risultato"
      );
    }
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  async componentDidMount() {
    await this.refreshGrid();

    const response = await authApi.getProfile();
    if (response?.message?.includes("200")) {
      this.setState({ufficio: response.result[0].ufficio});
    }

    this.setState({
      Provincia: await SedeComuneProvAPI.GetAllProvincieProvincie(),
      Regione: await RegioniAPI.GetRegioni(),
      CodiceQuota: StaticLists.CodiceQuota(),
      Caratteristiche: StaticLists.Caratteristiche_Destinatario(),
      TipoDestinatario: StaticLists.TipoDestinatario(),
      List: await StaticLists.EmailAziende()
    });

    const qS = queryString.parse(this.props.location.search);
    if (qS.maintainSearch === "1") {
      const searchFilters = helpers.GetSearchFilters(this.props.match.path);
      if (searchFilters) {
        for (const [key, val] of Object.entries(searchFilters)) {
          if (key) this.HandleDropDownChange(key, val);
        }
        await this.handleSearchClick();
      }
    }

    if (this.state.CodiceQuota !== null) {
      this.setState({
        boolList: true
      });
    } else {
      this.setState({
        boolList: false
      });
    }
  }

  HandleDropDownChange(e, val) {
    this.setState({
      [e]: val
    });

    if (e === "selectedRegione") {
      this.setState({ selectedProvincia: null });
    } else if (e === "selectedProvincia") {
      this.setState({ selectedRegione: null });
    }

    if (e === "selectedTipoDestinario")
      this.setState({
        name: val.name
      });

    if (this.state.name === "Aziende") {
      if (e === "selectedCaratteristiche") {
        if (val.length === 1 || val.length === 2) {
          if (val[0].cod === "EM") {
            this.setState({
              visibleRadioButtons: true
            });
          }
        } else
          this.setState({
            visibleRadioButtons: false
          });
      }
    }

    if (e === "selectedRadioButton" && val !== null)
      this.setState({
        radioChecked: val.id
      });
  }

  DocActionsGridTemplate(rowData) {
    return (
      <>
        <Button
          round
          color="info"
          key={rowData.Id}
          className="actionButton actionButtonRound btnRound"
          onClick={(e) => this.onRedirectClick(e, rowData)}
        >
          <VisibilityIcon className="icon" />
        </Button>
      </>
    );
  }

  onRedirectClick(e, rowData) {
    e.stopPropagation();
    const type = "?type=fromFiltroGenerico";
    if (this.state.name === "Aziende")
      this.props.history.push("/admin/aziende/" + rowData.id + type);
    else if (this.state.name === "Amministratori")
      this.props.history.push("/admin/amministratori/" + rowData.id + type);
    else if (this.state.name === "Consulenti")
      this.props.history.push("/admin/consulenti/" + rowData.id + type);
    else if (this.state.name === "Dipendenti")
      this.props.history.push("/admin/dipendenti/" + rowData.id + type);
  }

  handleSearchClick = async () => {
    let filters = {};
    if (this.state.selectedTipoDestinario)
      filters.selectedTipoDestinario = this.state.selectedTipoDestinario;
    if (this.state.selectedRegione)
      filters.selectedRegione = this.state.selectedRegione;
    if (this.state.selectedProvincia)
      filters.selectedProvincia = this.state.selectedProvincia;
    if (this.state.selectedCodQ) filters.selectedCodQ = this.state.selectedCodQ;
    if (this.state.selectedCaratteristiche)
      filters.selectedCaratteristiche = this.state.selectedCaratteristiche;
    if (this.state.selectedRadioButton)
      filters.selectedRadioButton = this.state.selectedRadioButton;

    helpers.SaveSearchFilters(filters, this.props.match.path);

    this.setState({
      selectedGrid: null
    });

    await this.refreshGrid(this.state.name);
  };

  handleClearClick() {
    helpers.SaveSearchFilters(null, this.props.match.path);
    this.setState({
      selectedGrid: null,
      selectedProvincia: null,
      selectedRegione: null,
      rows: [],
      selectedTipoDestinario: null,
      selectedCaratteristiche: null,
      selectedCodQ: null,
      visibleRadioButtons: false,
      name: "",
      columns: [{ field: "", header: "" }],
      isPreparaMail: false
    });
  }

  annullaClick() {
    this.setState({ isPreparaMail: false });
  }

  async refreshGrid(name) {
    this.setState({
      loadingGrid: true,
      isPreparaMail: false
    });

    switch (name) {
      case "Aziende":
        this.setState({
          rows: await AziendeAPI.GetForFiltroPerPosta(
            this.state.selectedCodQ,
            this.state.selectedCaratteristiche,
            this.state.selectedProvincia,
            this.state.selectedRegione
          ),
          columns: [
            { field: "matricola", header: "Matricola" },
            { field: "denominazione", header: "RagioneSociale" }
          ]
        });
        break;

      case "Amministratori":
        this.setState({
          rows: await AmministratoriAPI.GetForFiltroPerPosta(
            this.state.selectedCodQ,
            this.state.selectedCaratteristiche,
            this.state.selectedProvincia,
            this.state.selectedRegione
          ),
          columns: [
            { field: "nominativo", header: "Nominativo" },
            { field: "cf", header: "CodFis" }
          ]
        });
        break;

      case "Consulenti":
        this.setState({
          rows: await ConsulentiAPI.GetForFiltroPerPosta(
            this.state.selectedCodQ,
            this.state.selectedCaratteristiche,
            this.state.selectedProvincia,
            this.state.selectedRegione
          ),
          columns: [
            { field: "nominativo", header: "Nominativo" },
            { field: "cf", header: "CodFis" }
          ]
        });
        break;

      case "Dipendenti":
        this.setState({
          rows: await DipendentiAPI.GetForFiltroPerPosta(
            this.state.selectedCodQ,
            this.state.selectedCaratteristiche,
            this.state.selectedProvincia,
            this.state.selectedRegione
          ),
          columns: [
            { field: "nominativo", header: "Nominativo" },
            { field: "cf", header: "CodFis" }
          ]
        });
        break;
      default:
        this.setState({
          rows: [],
          columns: [{ field: "", header: "" }]
        });
    }

    this.setState({
      loadingGrid: false
    });
  }

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: e.value
    });

    if (e.value.length > 0)
      this.setState({
        arrayId: e.value[0].id
      });
    else {
      this.setState({ isPreparaMail: false });
    }
  };

  render() {
    const dynamicColumns = this.state.columns.map((col) => {
      return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          sortable={true}
          filter={this.state.filterGrid}
        />
      );
    });

    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary" style={{ height: "51.09px" }}>
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Impostazioni filtro per posta
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{
                      display: "inline",
                      verticalAlign: "bottom",
                      width: "100px",
                      marginRight: "5px"
                    }}
                  >
                    <p className="lblNoMargin">Tipo destinatario</p>
                    <Dropdown
                      display="chip"
                      placeholder="Cerca per tipo"
                      optionLabel="name"
                      value={this.state.selectedTipoDestinario}
                      options={this.state.TipoDestinatario}
                      onChange={(e) =>
                        this.HandleDropDownChange(
                          "selectedTipoDestinario",
                          e.target.value
                        )
                      }
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{
                      display: "inline",
                      verticalAlign: "bottom",
                      width: "100px",
                      marginRight: "5px"
                    }}
                  >
                    <p className="lblNoMargin">Caratteristiche Destinario</p>
                    <MultiSelect
                      display="chip"
                      maxSelectedLabels={1}
                      placeholder="Cerca per caratteristiche"
                      optionLabel="name"
                      style={{ width: "200px" }}
                      value={this.state.selectedCaratteristiche}
                      options={this.state.Caratteristiche}
                      onChange={(e) => {
                        this.HandleDropDownChange(
                          "selectedCaratteristiche",
                          e.target.value
                        );
                      }}
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{
                      display: "inline",
                      verticalAlign: "bottom",
                      width: "100px",
                      marginRight: "5px"
                    }}
                  >
                    <p className="lblNoMargin">Codice Quota</p>
                    <MultiSelect
                      display="chip"
                      maxSelectedLabels={1}
                      optionLabel="name"
                      style={{ width: "200px" }}
                      value={this.state.selectedCodQ}
                      options={this.state.CodiceQuota}
                      placeholder="Cerca per codice quota"
                      onChange={(e) =>
                        this.HandleDropDownChange(
                          "selectedCodQ",
                          e.target.value
                        )
                      }
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{
                      display: "inline",
                      verticalAlign: "bottom",
                      width: "100px",
                      marginRight: "5px"
                    }}
                  >
                    <p className="lblNoMargin">Provincia</p>
                    <Dropdown
                      value={this.state.selectedProvincia}
                      options={this.state.Provincia}
                      onChange={(e) =>
                        this.HandleDropDownChange(
                          "selectedProvincia",
                          e.target.value
                        )
                      }
                      placeholder="Cerca per provincia"
                      optionLabel="nome"
                      filter
                      filterBy="nome"
                    />
                  </GridItem>

                  <GridItem
                    xs={12}
                    sm={12}
                    md={2}
                    style={{
                      display: "inline",
                      verticalAlign: "bottom",
                      width: "100px"
                    }}
                  >
                    <p className="lblNoMargin">Regione</p>
                    <MultiSelect
                      value={this.state.selectedRegione}
                      options={this.state.Regione}
                      onChange={(e) =>
                        this.HandleDropDownChange(
                          "selectedRegione",
                          e.target.value
                        )
                      }
                      placeholder="Cerca per regione"
                      optionLabel="nome"
                      filter
                      display="chip"
                    />
                  </GridItem>

                  {this.state.visibleRadioButtons && (
                    <div
                      className="float-left"
                      style={{ margin: "11px", marginTop: "20px" }}
                    >
                      {this.state.List.map((type) => {
                        return (
                          <div
                            key={type.id}
                            className="p-field-radiobutton"
                            style={{ display: "inline", marginRight: "10px" }}
                          >
                            <RadioButton
                              inputId={type.id}
                              name="category"
                              value={type}
                              onChange={(e) =>
                                this.HandleDropDownChange(
                                  "selectedRadioButton",
                                  e.value
                                )
                              }
                              checked={this.state.radioChecked === type.id}
                            />
                            <label className="labelCheckbox" htmlFor={type.id}>
                              {type.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  )}

                  <GridItem xs={12} sm={12} md={2}>
                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Cerca"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <Button
                        ref={this.btnSearchRef}
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%",
                          marginLeft: "0",
                          marginRight: "2%",
                          marginTop: "12%"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        onClick={this.handleSearchClick}
                      >
                        <Search />
                      </Button>
                    </Tooltip>

                    <Tooltip
                      arrow
                      id="tooltip-top"
                      title="Reimposta"
                      placement="top"
                      classes={{ tooltip: "tooltip-me" }}
                    >
                      <Button
                        style={{
                          verticalAlign: "center",
                          lineHeight: "1",
                          margin: "0.1%",
                          marginRight: "0",
                          marginTop: "12%"
                        }}
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className="p-button-rounded p-button-primary p-button-sm float-left"
                        icon="pi pi-arrow-left"
                        onClick={this.handleClearClick}
                      >
                        <Clear />
                      </Button>
                    </Tooltip>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Risultati
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
                <ButtonsMUI
                  className="float-right"
                  variant="contained"
                  color="primary"
                  onClick={this.PreparaMail}
                >
                  Prepara mail
                </ButtonsMUI>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id" paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={5}
                  rowsPerPageOptions={[5, 10, 15, 100]}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="multiple"
                  metaKeySelection={false}
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "40px", paddingLeft: "3px" }}
                  />
                  {dynamicColumns}
                  <Column
                    header=""
                    headerStyle={{ width: "5%" }}
                    body={this.DocActionsGridTemplate}
                    className="colDocActions"
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {this.state.isPreparaMail && (
          <PreparaMailGenerico
            {...this.props}
            onAnnullaClick={this.annullaClick}
            onSaveClick={this.HandleSaveClick}
            name={this.state.name}
            prots={this.state.prots}
            ufficio={this.state.ufficio}
          />
        )}
      </>
    );
  }
}
export default ImpostazioneFiltroXSpedizioni;
