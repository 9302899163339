import React, {Component} from "react";
import clone from "clone";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";

import LoaderSpinner from "../../Layout/LoaderSpinner.js";
import ConsulentiAPI from "API/ConsulentiAPI";
import {Dropdown} from "primereact/dropdown";
import UndoIcon from "@material-ui/icons/Undo";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import helpers from "helpers/Helpers";

export class ModificaUtente extends Component {
  state = {
    RowItem: null,
    ModificaTitle: "Nuovo",
    groupClass: "",
    disabledFields: false,
    ProvinciaList: [],
    selectedUserType: null,

    selectedComune: null,
    notFilteredComune: [],
    filteredComune: [],

    //VALIDATIONS
    fieldValidations: {},
    validationRules: {
      utente: ""
    }
  };

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.SetText = this.SetText.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.OnInputBlur = this.OnInputBlur.bind(this);
    this.ChangeButtons = this.ChangeButtons.bind(this);
  }

  async isValidForm(showAlert = false) {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    if (this.props.dataItem.codice !== null) {
      // For update, skip validation for some fields
    }

    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);
    let isValid = Object.values(fieldValidations).every((x) => x === "success");
    this.setState({
      fieldValidations: fieldValidations
    });

    return isValid;
  }

  SetText(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;
    this.setState({ RowItem: newRowItem });
  }

  async OnInputBlur(newCAP) {
    let rowItem = this.state.RowItem;
    this.setState({
      RowItem: rowItem
    });
  }

  HandleSaveClick = async () => {
    if ((await this.isValidForm()) && this.state.RowItem.username) {
      return await this.props.onSaveClick(
          this.state.RowItem
      );
    } else
      return this.props.addAlert.current.addAlert(
        "warning",
        "Inserire l'Username"
      );
  };

  HandleDropDownChange(e, val) {
    let { RowItem } = this.state;
    RowItem[e] = val;
    this.setState({ RowItem });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.dataItem !== null) {
      if (
        this.props.dataItem.codice !== prevProps.dataItem.codice ||
        this.props.dataItem.tipo !== prevProps.dataItem.tipo
      ) {
        let selectedUserType = this.props.userTypes.filter(
          (row) => row.code === this.props.dataItem.ufficio
        )[0]?.name;

        this.setState({
          RowItem: this.props.dataItem.tipo
            ? this.props.dataItem[0]
            : this.props.dataItem,
          ModificaTitle: this.props.dataItem.codice > 0 ? "Modifica" : "Nuovo",
          disabledFields: this.props.dataItem.codice > 0 ? true : false,
          groupClass: this.props.dataItem.codice > 0 ? "" : "hide",
          selectedUserType //((this.props.dataItem.id > 0) && (this.props.dataItem.prov !== null) && (this.props.dataItem.prov.length > 0)) ? helpers.ReturnIdFromListByType(this.state.ProvinciaList, "Provincia", this.props.dataItem.prov) : null,
        });
      }
    }
  }

  async componentDidMount() {
    if (this.props.dataItem !== null) {
      this.setState({
        RowItem: this.props.dataItem,
        disabledFields: this.props.dataItem.codice > 0 ? true : false,
        ModificaTitle: this.props.dataItem.codice > 0 ? "Modifica" : "Nuovo",
        groupClass: this.props.dataItem.codice > 0 ? "" : "hide"
      });
    }
  }

  OnInputBlurCF = async () => {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);

    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);
    Object.values(fieldValidations).every((x) => x === "success");

    // this.setState({
    // 	fieldValidations: fieldValidations,
    // });

    if (fieldValidations["cf"] === "error") {
      this.props.addAlert.current.addAlert(
        "warning",
        "Il Codice Fiscale non e' sintatticamente corretto: CCCCCCNNCNNCNNNC o NNNNNNNNNNN!"
      );
      return false;
    }

    let existentConsulenti = [];
    if (
      this.state.RowItem.cf !== null &&
      this.state.RowItem.cf.length === 16 &&
      this.props.dataItem.codice === null
    ) {
      existentConsulenti = await ConsulentiAPI.GetCfConsAmm(
        this.state.RowItem.cf
      );
      if (existentConsulenti.length !== 0) {
        this.ChangeButtons(existentConsulenti);
        this.props.addAlert.current.addAlert(
          "warning",
          "Codice Fiscale Trovato"
        );
      }
    }
    return;
  };

  ChangeButtons = async (existentConsulenti) => {
    var sd = await this.props.ChangeButtons(existentConsulenti);
    return sd;
  };

  // ChangeButtons = async (boolean,cf,existentConsulenti)  => {
  //     await this.handleTableClick({
  //       value:  existentConsulenti[0]
  //     });

  //     return;
  //   }

  render() {
    if (this.state.RowItem === null)
      return <LoaderSpinner fullHeight={false} />;
    else
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="info">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.state.ModificaTitle} Utente
                  </h4>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Annulla"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.props.onAnnullaClick}
                    >
                      <UndoIcon />
                    </Button>
                  </Tooltip>

                  {this.props.children}
                </CardHeader>

                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Codice"
                        id="id-disabled"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          onChange: (event) =>
                            this.SetText("codice", event.target.value),
                          value: this.state.RowItem.codice || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.username &&
                          this.state.fieldValidations.username === "success"
                        }
                        error={
                          this.state.fieldValidations.username &&
                          this.state.fieldValidations.username === "error"
                        }
                        labelText={
                          this.props.dataItem?.codice ? "Username" : "Username*"
                        }
                        id="username"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.props.dataItem?.codice ? true : false,
                          onChange: (event) =>
                            this.SetText("username", event.target.value),
                          value: this.state.RowItem.username || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.utente &&
                          this.state.fieldValidations.utente === "success"
                        }
                        error={
                          this.state.fieldValidations.utente &&
                          this.state.fieldValidations.utente === "error"
                        }
                        labelText="Utente *"
                        id="utente"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("utente", event.target.value),
                          value: this.state.RowItem.utente || ""
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.password &&
                          this.state.fieldValidations.password === "success"
                        }
                        error={
                          this.state.fieldValidations.password &&
                          this.state.fieldValidations.password === "error"
                        }
                        labelText="Password"
                        id="password"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("password", event.target.value),
                          value: this.state.RowItem.password || ""
                        }}
                      />
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      style={{ marginTop: "4px" }}
                    >
                      <p className="lblNoMargin" style={{ color: 565656 }}>
                        <small>Tipo Utente</small>
                      </p>
                      <Dropdown
                        value={this.state.RowItem.ufficio ?? ""}
                        optionValue="code"
                        resetFilterOnHide
                        className="dropdownCustomisedForMUI"
                        options={this.props.userTypes}
                        onChange={(e) =>
                          this.HandleDropDownChange(
                            "ufficio",
                            e.target.value
                          )
                        }
                        optionLabel="name"
                        filter
                        filterBy="name"
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}
