import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import LoaderSpinner from "components/Layout/LoaderSpinner.js";
import Helpers from "helpers/Helpers.js";
import { AmministratoreHeader } from "components/Views/Amministratori/AmministratoreHeader.js";
import Button from "components/CustomButtons/Button.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ModificaDitte from "components/Views/Ditte/ModificaDitte.js";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ButtonsMUI from "@material-ui/core/Button";
import helpers from "helpers/Helpers";
import queryString from "query-string";
import DitteAPI from "API/DitteAPI";
import AmministratoriAPI from "API/AmministratoriAPI";
import Close from "@material-ui/icons/Close";
import { confirmPopup } from "primereact/confirmpopup";
import {rowsPerPageOptions} from "../../helpers/AppConstants";

export class DitteXAmministratore extends Component {
  state = {
    rows: null,
    selectedRow: null,
    selectedGridStorico: null,
    filterGrid: false,
    visibleButtons: false,
    visibleLoader: false,
    loadingGrid: false,
    firstRowToDisplay: 0,
    amministratoreSelected: null,
    storia: null, rowsPerPage: 5
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.btnSearchRef = React.createRef();
    this.RedirectBtn = this.RedirectBtn.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.DeleteGridTemplate = this.DeleteGridTemplate.bind(this);
  }

  async refreshGrid() {
    const amministratoriId = this.props.match.params.amministratoreId;
    const amministratori = await AmministratoriAPI.GetAmministratoriById(
      amministratoriId
    );

    this.setState({
      storia: this.props.match.params.amministratoreId,
      loadingGrid: true,
      amministratoreSelected: amministratori[0]
    });

    let rw;
    if (this.props.match.params.aziendaId === "0") {
      rw = await DitteAPI.GetAllDitteByAmministratoriId(
        this.props.match.params.amministratoreId
      );
    } else {
      rw = await DitteAPI.GetAllDitteByAmministratoriIdAziendaId(
        this.props.match.params.amministratoreId,
        this.props.match.params.aziendaId
      );
    }

    let selectedRow = null;
    let firstRowToDisplay = 0;

    const qS = queryString.parse(this.props.history.location.search);
    if (qS.typeId > 0) {
      selectedRow = helpers.ReturnRowFromListById(rw, qS.typeId);
      firstRowToDisplay = helpers.GetFirstElementOfPageIncludingId(
        qS.typeId, rw, 10
      );
    }

    this.setState({
      firstRowToDisplay: firstRowToDisplay,
      rows: rw
    });

    if (selectedRow)
      await this.handleTableClick({
        value: selectedRow
      });
    this.setState({
      loadingGrid: false
    });

    if (rw.length === 0) {
      await this.onNuovoClick();
    }
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  onCloseGridClick() {
    this.setState({
      selectedGridStorico: null,
      selectedRow: null
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  RedirectBtn(buttonName) {
    if (buttonName === "Storico") {
      const qS = queryString.parse(this.props.history.location.search);

      if (!qS.type)
        this.props.history.push(
          "/admin/ditte/storicoamministratore/" +
            this.props.match.params.aziendaId +
            "/" +
            this.props.match.params.amministratoreId +
            "?typeId=" +
            this.state.selectedRow.id
        );
      else if (qS.type === "fromAmministratori")
        this.props.history.push(
          "/admin/ditte/storicoamministratore/" +
            this.props.match.params.aziendaId +
            "/" +
            this.props.match.params.amministratoreId +
            "?type=fromAmministratori" +
            "&typeId=" +
            this.state.selectedRow.id
        );
    }
  }

  async handleTableClick(e) {
    this.setState({
      visibleLoader: true
    });

    this.setState({
      selectedGridStorico: e.value,
      visibleButtons: true,
      selectedRow: e.value
    });
    this.setState({
      visibleLoader: false
    });
  }

  async onNuovoClick() {
    this.setState({
      visibleLoader: true
    });
    const nuovo = await DitteAPI.GetNuovoDitteByAmministratoriId(null);

    this.setState({
      selectedGridStorico: null,
      selectedRow: nuovo,
      visibleButtons: false
    });
    this.setState({
      visibleLoader: false
    });
  }

  HandleSaveClick = async (RowItem) => {
    let risultato;
    if (RowItem.id === null || RowItem.id === "") {
      risultato = await DitteAPI.AddAmministratoreDitte(
        RowItem,
        this.props.match.params.amministratoreId
      );
    } else {
      risultato = await DitteAPI.UpdateAmministratoreDitte(RowItem);
    }
    if (!risultato.result) {
      if (risultato.message)
        this.props.addAlert.current.addAlert("danger", `${risultato.message}`);
      else
        this.props.addAlert.current.addAlert(
          "danger",
          "Si è verificato un errore sconosciuto"
        );
    } else {
      this.props.addAlert.current.addAlert("success", "Dati salvati.");
    }

    await this.refreshGrid();

    return true;
  };

  DeleteGridTemplate(rowData) {
    if (rowData.utente !== "Sistema" && this.props.match.params.aziendaId === 0)
      return (
        <Button
          round
          color="danger"
          className="actionButton actionButtonRound btnRound float-right"
          key={rowData.Id}
          onClick={(e) => this.onDeleteClick(e, rowData.id)}
        >
          <Close className="icon" />
        </Button>
      );
  }

  onDeleteClick(event, id) {
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        this.DeleteRecord(id);
      },
      reject: () => {}
    });
  }

  async DeleteRecord(id) {
    let response;

    response = await AmministratoriAPI.DeleteAziendeAmministratori(id);

    this.refreshGrid();

    if (response.result === null) {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("warning", response.message);
      }, 1200);
    } else {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("success", "Dati eliminati.");
      }, 1200);
    }

    this.setState({
      selectedRow: null
    });
  }

  backButtonClick = () => {
    let qS = queryString.parse(this.props.history.location.search);
    let maintainSearch = "?maintainSearch=1";
    let amministratoriId = this.props.match.params.amministratoreId;
    if (!qS.type)
      this.props.history.push(
        "/admin/AmministratoriXAzienda/" +
          this.props.match.params.aziendaId +
          "?typeId=" +
          amministratoriId
      );
    else if (qS.type === "fromAmministratori")
      this.props.history.push(
        "/admin/amministratori/" +
          amministratoriId +
          maintainSearch +
          "&typeId=" +
          amministratoriId
      );
  };

  render() {
    return (
      <div>
        <div ref={this.btnSearchRef} />
        <AmministratoreHeader
          {...this.props}
          dataItem={this.state.selectedRow}
          history={this.props.history}
          amministratore={this.state.amministratoreSelected}
        />
        {this.state.visibleLoader && <LoaderSpinner fullHeight={true} />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Aziende
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Alla pagina precedente"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.backButtonClick}
                  >
                    <ArrowBackIcon />
                  </Button>
                </Tooltip>
              </CardHeader>

              <CardBody>
                {
                  <div className="card">
                    <DataTable
                      emptyMessage=""
                      className="p-datatable-striped p-datatable-sm"
                      value={this.state.rows}
                      dataKey="id"
                      first={this.state.firstRowToDisplay}
                      onPage={(e) =>
                        this.setState({ firstRowToDisplay: e.first })
                      }
                      paginator
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                      rows={this.state.rowsPerPage} rowsPerPageOptions={rowsPerPageOptions}
                      paginatorLeft={this.paginatorLeft}
                      paginatorRight={this.paginatorRight}
                      loading={this.state.loadingGrid}
                      selection={this.state.selectedGridStorico}
                      scrollable
                      style={{ width: "100%" }}
                      onSelectionChange={this.handleTableClick}
                      selectionMode="single"
                    >
                      <Column
                        field="matricola"
                        header="Matricola"
                        sortable={true}
                        filter={this.state.filterGrid}
                        filterMatchMode="contains"
                        filterPlaceholder=""
                      />
                      <Column
                        field="denominazione"
                        header="Ragione Sociale"
                        sortable={true}
                        filter={this.state.filterGrid}
                        filterMatchMode="contains"
                        filterPlaceholder=""
                      />
                      <Column
                        field="data_inizio"
                        header="Inizio"
                        body={(rowData) =>
                          Helpers.DateTemplateWithHours(rowData, "data_inizio")
                        }
                        sortable={true}
                        filter={this.state.filterGrid}
                        filterMatchMode="contains"
                        filterPlaceholder=""
                      />
                      <Column
                        field="data_fine"
                        header="Fine"
                        body={(rowData) =>
                          Helpers.DateTemplateWithHours(rowData, "data_fine")
                        }
                        sortable={true}
                        filter={this.state.filterGrid}
                        filterMatchMode="contains"
                        filterPlaceholder=""
                      />
                      <Column
                        field="data_ult_agg"
                        header="Data Ultimo Agg."
                        body={(rowData) =>
                          Helpers.DateTemplateWithHours(rowData, "data_ult_agg")
                        }
                        sortable={true}
                        filter={this.state.filterGrid}
                        filterMatchMode="contains"
                        filterPlaceholder=""
                      />
                      <Column
                        field="utente"
                        header="Utente"
                        sortable={true}
                        filter={this.state.filterGrid}
                        filterMatchMode="contains"
                        filterPlaceholder=""
                      />
                      <Column
                        header=""
                        body={this.DeleteGridTemplate}
                        icon="pi pi-times"
                        label="Delete"
                        className="p-button-danger p-button-outlined colDelete"
                      />
                    </DataTable>
                  </div>
                }
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.selectedRow && (
          <ModificaDitte
            dataItem={this.state.selectedRow}
            {...this.props}
            amministratore={this.state.amministratoreSelected}
            onAnnullaClick={this.onCloseGridClick}
            onSaveClick={this.HandleSaveClick}
          >
            {this.state.visibleButtons && (
              <ButtonGroup
                variant="contained"
                color="primary"
                size="small"
                aria-label="contained primary button group"
                className="float-right"
                style={{ verticalAlign: "center", margin: "0.5%" }}
              >
                <ButtonsMUI onClick={() => this.RedirectBtn("Storico")}>
                  Storico Amministratori Ditta
                </ButtonsMUI>
              </ButtonGroup>
            )}
          </ModificaDitte>
        )}
      </div>
    );
  }
}
export default DitteXAmministratore;
