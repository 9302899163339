import Utils from "./Utils";

const ConsulentiAPI = {
  getConsultantsByCompanyId: async function (companyId) {
    let result = await Utils.CreatePostRequest(
      `coasco/consulenti_aziende/getbyidazienda?idazienda=${companyId}`
    );
    if (result === null) result = [];
    return result.result;
  },

  GetAllConsulenti: async function () {
    let result = await Utils.CreatePostRequest("coasco/consulenti/getall", {});
    return result.result;
  },

  GetConsulentiById: async function (consulentiId) {
    let result = await Utils.CreatePostRequest(
      "coasco/consulenti/getbyid?id=" + consulentiId
    );
    return result.result;
  },

  GetNuovoConsulenti: async function () {
    return {
      id: null,
      cf: null,
      nominativo: null,
      nome: null,
      cognome: null,
      indirizzo: null,
      comune: null,
      cap: null,
      prov: null,
      piva: null,
      fax: null,
      tel: null,
      cell: null,
      email: null,
      pec: null,
      data_ult_agg: null,
      tipo_ult_agg: null,
      utente: null,
      indirizzoxposta: null,
      comunexposta: null,
      capxposta: null,
      provxposta: null
    };
  },

  GetAllCf: async function (cf) {
    let result = await Utils.CreatePostRequest(
      "coasco/consulenti/searchbycf?cf=" + cf + "&maxResults=" + 100
    );
    return result?.result;
  },

  GetCfConsAmm: async function (cf) {
    let result = await Utils.CreatePostRequest(
      "coasco/consulenti/getbycfconsamm?cf=" + cf
    );

    if (result === null) {
      result = [];
    } else if (result.result[0].consulenti.length !== 0) {
      result = result.result[0].consulenti;
      result.tipo = "consulente";
    } else if (result.result[0].amministratori.length !== 0) {
      result = result.result[0].amministratori;
      result.tipo = "amministratore";
      result[0].id = null;
    } else if (
      result.result[0].consulenti.length === 0 &&
      result.result[0].amministratori.length === 0
    ) {
      result = [];
    }

    return result;
  },

  GetConsulentiAziende: async function (id) {
    let result = await Utils.CreatePostRequest(
      "coasco/consulenti_aziende/getbyid?id=" + id
    );

    return result.result;
  },

  GetConsulentiForAziendaByAziendaId: async function (aziendaId) {
    let result = await Utils.CreatePostRequest(
      "coasco/consulenti_aziende/getbyidazienda?idazienda=" + aziendaId
    );
    if (result === null) result = [];
    else
      result = result.result.map(item => ({
        ...item.consulente,
        data_inizio: item.data_inizio,
        data_fine: item.data_fine,
        data_ult_agg: item.data_ult_agg,
        id_rel: item.id
      }));

    return result;
  },

  GetNuovoConsulenteForAzienda: async function () {
    return {
      id: null,
      cf: null,
      nominativo: null,
      indirizzo: null,
      comune: null,
      cap: null,
      prov: null,
      piva: null,
      fax: null,
      tel: null,
      cell: null,
      email: null,
      email2: null,
      pec: null,
      data_ult_agg: null,
      tipo_ult_agg: null,
      indirizzoxposta: null,
      comunexposta: null,
      capxposta: null,
      provxposta: null,
      utente: null
    };
  },

  GetByCF: async function (cf) {
    return await Utils.CreatePostRequest(
      "coasco/consulenti/getbycf?cf=" + cf + "&maxResults=" + 100
    );
  },

  AddConsulenti: async function (consobj, user) {
    consobj.utente = user;
    return await Utils.CreatePutRequest("coasco/consulenti/update", consobj);
  },

  UpdateConsulenti: async function (consobj, user) {
    consobj.utente = user;
    return await Utils.CreatePutRequest("coasco/consulenti/update", consobj);
  },

  AddConsulentiForAzienda: async function (consobj, id_azienda) {
    consobj.utente = "Claudio";
    await Utils.CreatePutRequest(
      "coasco/consulenti_aziende/insert?id_azienda=" + id_azienda,
      consobj
    );
  },

  UpdateConsulentiForAzienda: async function (consobj, id_azienda) {
    consobj.utente = "Claudio";
    await Utils.CreatePutRequest(
      "coasco/consulenti_aziende/update?id_azienda=" + id_azienda,
      consobj
    );
  },

  DeleteConsulenti: async function (consId) {
    return await Utils.CreateDeleteRequest(
      "coasco/consulenti/delete?id=" + consId
    );
  },

  GetByIdAzienda: async function (aziendaId) {
    const response = await Utils.CreatePostRequest(
      "coasco/consulenti_aziende/getbyidazienda?idazienda=" + aziendaId
    );
    if (response?.message?.includes("200")) {
      return response.result;
    } else return null;
  },

  GetForFiltroPerPosta: async function (
    selectedCodQ,
    selectedCaratteristiche,
    selectedProvincia,
    selectedRegione
  ) {
    const cod_quota =
      "?cod_quota=" + (selectedCodQ?.map((codQ) => codQ.name) ?? "");
    const caratteristiche =
      "&caratteristiche=" +
      (selectedCaratteristiche?.map((car) => car.cod) ?? "");
    const provincia = "&provincia=" + (selectedProvincia?.sigla ?? "");
    const regione =
      "&regione=" + (selectedRegione?.map((reg) => reg.nome) ?? "");
    const url =
      "coasco/consulenti/getForFiltroPerPosta" +
      cod_quota +
      caratteristiche +
      provincia +
      regione;
    const response = await Utils.CreatePostRequest(url);
    if (response?.message?.includes("200")) {
      return response.result.map((row) => {
        return { nominativo: row[0], cf: row[1], id: row[2] };
      });
    } else return null;
  }
};
export default ConsulentiAPI;
