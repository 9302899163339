import Utils from "./Utils";
import * as AppConstants from "../helpers/AppConstants";

const partialURL = "ebinprof/BorseResult";
const baseURL = AppConstants.SiteUrl + partialURL;

const StatisticheBorseAPI = {
  getAnnoTipoProvincia: async function () {
    return await Utils.SendPostRequestTo(
      `${baseURL}/getBorseAssegAnnoTipoProv`
    );
  },

  getBorseAssegAnnoTipoSesso: async function () {
    return await Utils.SendPostRequestTo(
      `${baseURL}/getBorseAssegAnnoTipoSesso`
    );
  }
};

export default StatisticheBorseAPI;
