import Utils from "./Utils";

const causaliContributiAPI = {

    async getAll() {
        const response = await Utils.CreatePostRequest("coasco/causali/getall");
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async update(causale) {
        const response = await Utils.CreatePutRequest("coasco/causali/update", causale);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async insert(causale) {
        const response = await Utils.CreatePostRequest("coasco/causali/insert", causale);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async delete(causale) {
        const response = await Utils.CreateDeleteRequest("coasco/causali/delete?id=" + causale.codice);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    getNew() {
        return {
            codice: "",
            descrizione: ""
        };
    }

};

export default causaliContributiAPI;