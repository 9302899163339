import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ButtonsMUI from "@material-ui/core/Button";
import { Dropdown } from "primereact/dropdown";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AnniAccademiciAPI from "API/AnniAccademiciAPI";
import ReportAPI from "API/ReportAPI";
import Utils from "../../API/Utils";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";

class ReportBorse extends Component {

  state = {
    anniAccademici: [],
    reports: [],
    reportsCommissione: [],
    selectedAnnoCommissione: null,
    selectedReportCommissione: null,
    selectedAnno: null,
    selectedReport: null,
    loadingPage: false,
  };

  constructor(props) {
    super(props);
    this.avviaCommissione = this.avviaCommissione.bind(this);
    this.avvia = this.avvia.bind(this);
  }

  async avvia() {

      if (!this.state.selectedReport) {
          this.props.addAlert.current.addAlert("danger", "Selezionare il tipo di report");
          return;
      }

      if (!this.state.selectedAnno) {
          this.props.addAlert.current.addAlert("danger", "Selezionare l'anno");
          return;
      }

      this.setState({loadingPage: true});
      let url = "ebinprof/reportborse/";

      switch (this.state.selectedReport.descr) {
          case "1 - Indirizzi-Contatti Richiedenti anno XXX.xlsx":
              url += "reportIndirizziContattiRichiedentiAnno"
              break;
          case "2 - Indirizzi-Contatti Vincitori anno XXX.xlsx":
              url += "reportIndirizziContattiVincitoriAnno";
              break;
          case "3 - Indirizzi-Contatti Non Vincitori anno XXX.xlsx":
              url += "reportIndirizziContattiNonVincitoriAnno";
              break;
          case "4 - E-mail - PEC partecipanti anno XXX.xlsx":
              url += "reportEmailPecPartecipantiAnno";
              break;
          case "5 - Contatti partecipanti anno XXX.xlsx":
              url += "reportContattiPartecipantiAnno";
              break;
          case "6 - IBAN Vincitori anno XXX.xlsx":
              url += "reportIbanVincitoriAnno";
              break;
          case "7 - Richieste in lavorazione anno XXX.xlsx":
              url += "reportRichiesteLavorazioneAnno";
              break;
          case "8 - Elenco partecipanti Borse di Studio anno XXX.xlsx":
              url += "reportElencoPartecipantiBorseAnno";
              break;
          case "9 - Report CU - ex CUD - anno XXX.xlsx":
              url += "reportCuExCudAnno";
              break;
          case "10 - Export generale Borse - anno 20XX_20YY.xlsx":
              url += "reportExportGeneraleBorseAnno";
              break;
      }

      url += "?anno=" + this.state.selectedAnno.descr;
      await Utils.CreatePostRequestDownloadReport(url);
      this.setState({loadingPage: false});
  }

  async avviaCommissione() {

    if (!this.state.selectedReportCommissione) {
      this.props.addAlert.current.addAlert("danger", "Selezionare il tipo di report");
      return;
    }

    if (!this.state.selectedAnnoCommissione) {
      this.props.addAlert.current.addAlert("danger", "Selezionare l'anno");
      return;
    }

    this.setState({loadingPage: true});
    let url = "ebinprof/reportborsexcommissione/";

    switch (this.state.selectedReportCommissione.descr) {
      case "1 - BS 20XX-20YY - report diplomati con disabilità":
        url += "reportDiplomatiDisabili"
        break;
      case "2 - BS 20XX-20YY - report diplomati":
        url += "reportDiplomati";
        break;
      case "3 - BS 20XX-20YY - report studenti universitari con disabilità":
        url += "reportStudentiUniversitariDisabili";
        break;
      case "4 - BS 20XX-20YY - report studenti universitari":
        url += "reportStudentiUniversitari";
        break;
      case "5 - BS 20XX-20YY - report laureati anno 20YY":
        url += "reportLaureati";
        break;
      case "6 - BS 20XX-20YY - Report laureati anno 20YY con Tesi DL SS SE":
        url += "reportL4000";
        break;
    }

    url += "?anno=" + this.state.selectedAnnoCommissione.descr;
    await Utils.CreatePostRequestDownloadReport(url);
    this.setState({loadingPage: false});
  }

  async componentDidMount() {
    this.setState({
      reportsCommissione: await ReportAPI.getReports("reportbsc"),
        reports: await ReportAPI.getReports("reportbs"),
      anniAccademici: await AnniAccademiciAPI.AnnoAccademiciForBorse()
    });
  }

  render() {
    return (
      <>
        {this.state.loadingPage && <LoaderSpinner />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <h4>Report per la Commissione</h4>
              </CardHeader>
              <CardBody>
                <GridContainer className="mt-2">
                  <GridItem xs={12} sm={12} md={7}>
                    <Dropdown
                      placeholder="Tipo di report"
                      optionLabel="descr"
                      value={this.state.selectedReportCommissione}
                      options={this.state.reportsCommissione}
                      onChange={e => this.setState({selectedReportCommissione: e.value})}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Dropdown
                      placeholder="Anno"
                      optionLabel="descr"
                      value={this.state.selectedAnnoCommissione}
                      options={this.state.anniAccademici}
                      onChange={e => this.setState({selectedAnnoCommissione: e.value})}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <ButtonGroup variant="contained" color="default" size="small">
                      <ButtonsMUI onClick={this.avviaCommissione}>
                        Avvia
                      </ButtonsMUI>
                    </ButtonGroup>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <h4>Report Borse di Studio</h4>
              </CardHeader>
              <CardBody>
                <GridContainer className="mt-2">
                  <GridItem xs={12} sm={12} md={7}>
                    <Dropdown
                      placeholder="Tipo di report"
                      optionLabel="descr"
                      value={this.state.selectedReport}
                      options={this.state.reports}
                      onChange={e => this.setState({selectedReport: e.value})}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Dropdown
                      placeholder="Anno"
                      optionLabel="descr"
                      value={this.state.selectedAnno}
                      options={this.state.anniAccademici}
                      onChange={e => this.setState({selectedAnno: e.value})}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <ButtonGroup variant="contained" color="default" size="small">
                      <ButtonsMUI onClick={this.avvia}>
                        Avvia
                      </ButtonsMUI>
                    </ButtonGroup>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default ReportBorse;
