import { MenuItem } from "@material-ui/core";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CustomSelect from "components/CustomInput/CustomSelect";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import PropTypes from "prop-types";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";
import CustomInput from "components/CustomInput/CustomInput";

export const TestoEmail = (props) => {
  const { userEmails, accounts } = props;
  const totAccounts = userEmails?.concat(accounts);

  const selectComponent = (
    <CustomSelect
      labelText="Account Email"
      id="account"
      formControlProps={{
        fullWidth: true
      }}
      inputProps={{
        label: "Seleziona un account",
        onChange: (e) => props.setAccount(e.target.value),
        value: props.selectedAccount || ""
      }}
    >
      {totAccounts?.map((item) => (
        <MenuItem value={item.email} key={item.id}>
          {item.email}
        </MenuItem>
      ))}
    </CustomSelect>
  );

  return (
    <Card>
      <CardHeader color="info">
        <h4
          style={{
            lineHeight: "1",
            display: "inline",
            verticalAlign: "bottom"
          }}
        >
          Testo Email
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} className="select">
            {selectComponent}
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                  labelText="Altro destinatario"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                      onChange: e => props.setAltroDest(e.target.value),
                      value: props.altroDest ?? ""
                  }}
              />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            className="input"
            style={{ marginTop: "5px" }}
          >
            <InputTextarea
              autoResize
              value={props.text}
              onChange={(e) => props.setEmailText(e.target.value)}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

TestoEmail.propTypes = {
  selectedAccount: PropTypes.string,
  setAccount: PropTypes.func,
  text: PropTypes.string,
  accounts: PropTypes.array,
  setEmailText: PropTypes.func
};
