import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";

import ButtonsMUI from "@material-ui/core/Button";
import Clear from "@material-ui/icons/Clear";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import Search from "@material-ui/icons/Search";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import StatisticheAPI from "../../API/StatisticheAPI";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";
import helpers from "../../helpers/Helpers";
import Utils from "../../API/Utils";

const List = [
  { id: 1, name: "Regolariz. per CodQuota per Tipo per Mese Rilev." },
  { id: 2, name: "Regolariz. per CodQuota per Tipo per Competenza" },
  { id: 3, name: "Regolariz. per CodQuota per Tipo per Provincia" },
  { id: 4, name: "Regolariz. per CodQuota per Tipo per Range di Importo" },
  { id: 5, name: "Regolariz. per CodQuota per Tipo con Importo" },
  { id: 6, name: "Regolariz. per Tempo Regolariz. per Tipo Regol." },
  { id: 7, name: "Regolariz. per Tempo Regolariz. da Comunic." },
  { id: 8, name: "Regolariz. Manuali per CodQuota per Tipo per Mese Regol." },
  { id: 9, name: "Regolariz. Comunicate per CodQuota per Tipo con Importo" },
  { id: 10, name: "Aziende con Regolarizzate" },
  { id: 11, name: "Aziende con Regolarizzate per CodQuota per Tipo" },
  { id: 12, name: "Non implementata" },
  { id: 13, name: "Non implementata" },
  {
    id: 14,
    name: "Regol. Comunic con Importi Totali di riepilogo(Aggior. Mensile)"
  }
];
class AnomalieRegolarizzateXStatistiche extends Component {

  state = {
    rows: [],
    columns: [],
    filterGrid: null,
    selectedGrid: null,
    anno: new Date().getFullYear(),
    loadingGrid: false,
    radioChecked: null,
    selectedRadioButton: null,
    loadingPage: false,
    rowsPerPage: 5
  };

  constructor(props) {
    super(props);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.RadioHandleChange = this.RadioHandleChange.bind(this);
    this.onClickEsportaDettaglio = this.onClickEsportaDettaglio.bind(this);
  }

  async onClickEsportaDettaglio() {
    if (this.state.selectedGrid) {
      let params = [];
      let queryName;
      switch (this.state.selectedRadioButton.id) {
        case 1:
          params.push(this.state.selectedGrid.cod_quota);
          params.push(this.state.selectedGrid.cod_anomalia);
          params.push(this.state.selectedGrid.periodo);
          queryName = "statistiche.anomalie.RegolarizCodQCodAnomPeriodoDettaglio";
          break;
        case 2:
          params.push(this.state.selectedGrid.cod_quota);
          params.push(this.state.selectedGrid.cod_anomalia);
          params.push(this.state.selectedGrid.comp);
          queryName = "statistiche.anomalie.RegolarizCodQCodAnomCompDettaglio";
          break;
        case 3:
          params.push(this.state.selectedGrid.cod_quota);
          params.push(this.state.selectedGrid.cod_anomalia);
          params.push(this.state.selectedGrid.prov);
          queryName = "statistiche.anomalie.RegolarizCodQCodAnomProvDettaglio";
          break;
        case 4:
          params.push(this.state.selectedGrid.cod_quota);
          params.push(this.state.selectedGrid.cod_anomalia);
          params.push(this.state.selectedGrid.range_importo);
          queryName = "statistiche.anomalie.RegolarizCodQCodAnomRangeImpDettaglio";
          break;
        case 5:
          params.push(this.state.selectedGrid.cod_quota);
          params.push(this.state.selectedGrid.cod_anomalia);
          queryName = "statistiche.anomalie.RegolarizXCodQCodAnomImpDettaglio";
          break;
        case 6:
          params.push(this.state.selectedGrid.mesi_da_rilev);
          params.push(this.state.selectedGrid.tipo_ok);
          queryName = "statistiche.anomalie.RegolarizzateXTempoTipoRegolDettaglio";
          break;
        case 7:
          params.push(this.state.selectedGrid.mesi_da_comunic);
          queryName = "statistiche.anomalie.RegolarizzateXTempoDaComunicDettaglio";
          break;
        case 8:
          params.push(this.state.selectedGrid.cod_quota);
          params.push(this.state.selectedGrid.cod_anomalia);
          params.push(this.state.selectedGrid.periodo);
          queryName = "statistiche.anomalie.RegolarizManuCodQCodAnomPeriodoDettaglio";
          break;
        case 9:
          params.push(this.state.selectedGrid.cod_quota);
          params.push(this.state.selectedGrid.cod_anomalia);
          queryName = "statistiche.anomalie.RegolarizComunXCodQCodAnomImpDettaglio";
          break;
        case 10:
          queryName = "statistiche.anomalie.RegolarizXAziendeDettaglio";
          break;
        case 11:
          params.push(this.state.selectedGrid.cod_quota);
          params.push(this.state.selectedGrid.cod_anomalia);
          queryName = "statistiche.anomalie.RegolarizXAziendeCodQCodAnomDettaglio";
          break;
        case 12:
          params.push(this.state.selectedGrid.cod_quota);
          params.push(this.state.selectedGrid.cod_anomalia);
          queryName = "statistiche.anomalie.";
          break;
        case 13:
          params.push(this.state.selectedGrid.cod_quota);
          params.push(this.state.selectedGrid.cod_anomalia);
          queryName = "statistiche.anomalie.";
          break;
        case 14:
          params.push(this.state.selectedGrid.cod_quota);
          params.push(this.state.selectedGrid.cod_anomalia);
          params.push(this.state.selectedGrid.num_anomalie);
          queryName = "statistiche.anomalie.";
          break;
      }

      this.setState(
          {loadingPage: true},
          async () => {
            await StatisticheAPI.getDettaglio(queryName, params);
            this.setState({loadingPage: false});
          }
      );
    }
  }

  async handleSearchClick() {
    this.setState({selectedGrid: null});
    await this.refreshGrid();
  }

  async handleClearClick() {
    this.setState({
      selectedRadioButton: null,
      radioChecked: null,
      anno: new Date().getFullYear(),
      rows: [],
      columns: [],
      selectedGrid: null
    });

    await this.refreshGrid();
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  HandleDropDownChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  RadioHandleChange(e, val) {
    this.setState({
      [e]: val
    });

    if (val !== null)
      this.setState({
        radioChecked: val.id
      });
  }

  async refreshGrid() {
    this.setState({
      loadingGrid: true
    });

    if (this.state.selectedRadioButton !== null) {
      switch (this.state.selectedRadioButton.id) {
        case 1:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.anomalie.RegolarizCodQCodAnomPeriodo"),
            columns: [
              { field: "count", header: "Count" },
              { field: "cod_quota", header: "Codice Quota" },
              { field: "cod_anomalia", header: "Tipo" },
              { field: "periodo", header: "Periodo" }
            ]
          });
          break;
        case 2:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.anomalie.RegolarizCodQCodAnomComp"),
            columns: [
              { field: "count", header: "Count" },
              { field: "cod_quota", header: "Codice Quota" },
              { field: "cod_anomalia", header: "Tipo" },
              { field: "comp", header: "Competenza" }
            ]
          });
          break;
        case 3:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.anomalie.RegolarizCodQCodAnomProv"),
            columns: [
              { field: "count", header: "Count" },
              { field: "cod_quota", header: "Codice Quota" },
              { field: "cod_anomalia", header: "Tipo" },
              { field: "prov", header: "Provincia" },
              { field: "regione", header: "Regione" }
            ]
          });
          break;

        case 4:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.anomalie.RegolarizCodQCodAnomRangeImp"),
            columns: [
              { field: "count", header: "Count" },
              { field: "cod_quota", header: "Codice Quota" },
              { field: "cod_anomalia", header: "Tipo" },
              { field: "range_importo", header: "Range Importo" }
            ]
          });
          break;
        case 5:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.anomalie.RegolarizXCodQCodAnomImp"),
            columns: [
              { field: "count", header: "Count" },
              { field: "cod_quota", header: "Codice Quota" },
              { field: "cod_anomalia", header: "Tipo" },
              { field: "importo", header: "Importo" }
            ]
          });
          break;

        case 6:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.anomalie.RegolarizzateXTempoTipoRegol"),
            columns: [
              { field: "count", header: "Count" },
              { field: "mesi_da_rilev", header: "Mesi da rilevazione" },
              { field: "tipo_ok", header: "Tipo ok" },
              { field: "descrizione", header: "Descrizione" }
            ]
          });
          break;
        case 7:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.anomalie.RegolarizzateXTempoDaComunic"),
            columns: [
              { field: "count", header: "Count" },
              { field: "mesi_da_comunic", header: "Mesi da comunicazione" }
            ]
          });
          break;
        case 8:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.anomalie.RegolarizManuCodQCodAnomPeriodo"),
            columns: [
              { field: "count", header: "Count" },
              { field: "cod_quota", header: "Codice Quota" },
              { field: "cod_anomalia", header: "Tipo" },
              { field: "periodo", header: "Periodo" }
            ]
          });
          break;
        case 9:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.anomalie.RegolarizComunXCodQCodAnomImp"),
            columns: [
              { field: "count", header: "Count" },
              { field: "cod_quota", header: "Codice Quota" },
              { field: "cod_anomalia", header: "Tipo" },
              { field: "importo", header: "Importo" }
            ]
          });
          break;
        case 10:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.anomalie.RegolarizXAziende"),
            columns: [
              { field: "count", header: "Count" }
            ]
          });
          break;
        case 11:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.anomalie.RegolarizXAziendeCodQCodAnom"),
            columns: [
              { field: "count", header: "Count" },
              { field: "cod_quota", header: "Codice Quota" },
              { field: "cod_anomalia", header: "Tipo" }
            ]
          });
          break;
        case 12:
          this.setState({
            rows: [],
            columns: []
          });
          break;
        case 13:
          this.setState({
            rows: [],
            columns: []
          });
          break;
        case 14:
          this.setState({
            rows: await StatisticheAPI.get("statistiche.anomalie.RegolarizComunXCodQCodAnomImpTot"),
            columns: [
              { field: "count", header: "Count" },
              { field: "importo", header: "Importo" },
              { field: "cod_quota", header: "Codice Quota" },
              { field: "cod_anomalia", header: "Tipo" }
            ]
          });
          break;
        default:
          this.setState({
            rows: [],
            columns: []
          });
      }
    }

    this.setState({
      loadingGrid: false
    });
  }

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: e.value
    });
  };

  render() {
    const dynamicColumns = this.state.columns.map(col => {
      if (col.field === "importo") {
        return (
            <Column
                key={col.field}
                field={col.field}
                header={col.header}
                sortable={true}
                filter={this.state.filterGrid}
                body={row => <>{helpers.DecimalDTTemplate(row, "importo")}</>}
            />
        );
      } else return (
        <Column
          key={col.field}
          field={col.field}
          header={col.header}
          sortable={true}
          filter={this.state.filterGrid}
        />
      );
    });
    return (
      <>
        {this.state.loadingPage && (<LoaderSpinner />)}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4>
                  Statistiche su Anomalie Regolarizzate
                </h4>
              </CardHeader>
              <CardBody
                style={{
                  paddingTop: "8px",
                  paddingBottom: "6px"
                }}
              >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4} style={{ marginTop: "8px" }}>
                    <Dropdown
                      display="chip"
                      optionLabel="name"
                      placeholder="Cerca per tipo"
                      maxSelectedLabels={1}
                      value={this.state.selectedRadioButton}
                      options={List}
                      onChange={(e) =>
                        this.RadioHandleChange("selectedRadioButton", e.value)
                      }
                    />
                  </GridItem>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Cerca"
                    placement="top"
                    style={{ marginTop: "5px" }}
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleSearchClick}
                    >
                      <Search />
                    </Button>
                  </Tooltip>

                  <Tooltip
                    arrow
                    style={{ marginLeft: "4px", marginTop: "5px" }}
                    id="tooltip-top"
                    title="Reimposta"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="p-button-rounded p-button-primary p-button-sm float-left"
                      icon="pi pi-arrow-left"
                      onClick={this.handleClearClick}
                    >
                      <Clear />
                    </Button>
                  </Tooltip>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className="d-inline">
                  Elaborazioni Statistiche su Anomalie Regolarizzate
                </h4>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  size="small"
                  aria-label="contained primary button group"
                  className="float-right m-1"
                >
                  <ButtonsMUI onClick={() => Utils.ExportExcel(this.state.rows)}>Export</ButtonsMUI>
                  {this.state.selectedGrid && (
                      <ButtonsMUI onClick={this.onClickEsportaDettaglio}>Esporta dettaglio</ButtonsMUI>
                  )}
                </ButtonGroup>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage}
                  loading={this.state.loadingGrid}
                  rowsPerPageOptions={[5, 10, 20]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  {dynamicColumns}
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default AnomalieRegolarizzateXStatistiche;
