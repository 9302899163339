import Utils from "./Utils";

const AmministratoriAPI = {
  getAdministratorById: async function (amministratoriId) {
    const url = `coasco/amministratori/getbyid?id=${amministratoriId}`;
    return await Utils.CreatePostRequest(url);
  },

  getAdministratorsByCompanyId: async function (companyId) {
    let result = await Utils.CreatePostRequest(
      `coasco/amministratori_aziende/getbyidazienda?idazienda=${companyId}`
    );
    if (result === null) result = [];
    return result.result;
  },

  GetAll: async function () {
    let result = await Utils.CreatePostRequest(
      "coasco/amministratori/getall",
      {}
    );
    if (result === null) result = [];
    return result.result;
  },

  GetAmministratoriById: async function (amministratoriId) {
    let result = await Utils.CreatePostRequest(
      "coasco/amministratori/getbyid?id=" + amministratoriId
    );
    if (result === null) result = {};
    return result.result;
  },

  GetNuovoAmministratoriById: async function () {
    return {
      id: null,
      cf: null,
      nome: null,
      cognome: null,
      nominativo: null,
      indirizzo: null,
      comunex: null,
      capx: null,
      provx: null,
      piva: null,
      fax: null,
      tel: null,
      cell: null,
      email: null,
      pec: null,
      data_ult_agg: null,
      tipo_ult_agg: null,
      indirizzoxposta: null,
      comunexposta: null,
      capxposta: null,
      provxposta: null,
      utente: null
    };
  },

  GetByCF: async function (cf) {
    return await Utils.CreatePostRequest(
      "coasco/amministratori/getbycf?cf=" + cf + "&maxResults=" + 100
    );
  },

  GetAllCf: async function (cf) {
    let result = await Utils.CreatePostRequest(
      "coasco/amministratori/searchbycf?cf=" + cf + "&maxResults=" + 100
    );
    if (result === null) result = [];
    return result.result;
  },

  GetCfAmmCons: async function (cf) {
    let result = await Utils.CreatePostRequest(
      "coasco/amministratori/getbycfconsamm?cf=" + cf
    );

    if (result === null) {
      result = [];
    } else if (result.result[0].amministratori.length !== 0) {
      result = result.result[0].amministratori;
      result.tipo = "amministratore";
    } else if (result.result[0].consulenti.length !== 0) {
      result = result.result[0].consulenti;
      result.tipo = "consulente";
      result[0].id = null;
    } else if (
      result.result[0].consulenti.length === 0 &&
      result.result[0].amministratori.length === 0
    ) {
      result = [];
    }
    return result;
  },

  GetAmministratoriForAziendaByAziendaId: async function (aziendaId) {
    let resultObj = await Utils.CreatePostRequest(
      "coasco/amministratori_aziende/getbyidazienda?idazienda=" + aziendaId
    );
    if (resultObj)
      return resultObj.result.map(item => ({
        ...item.amministratore,
        azienda_id: item.azienda.id,
        data_inizio: item.data_inizio,
        data_fine: item.data_fine,
        cf: item.amministratore.cf,
        nominativo: item.amministratore?.nominativo,
        id: item.amministratore?.id,
        id_rel: item.id
      }));
    return {};
  },

  GetAmministratoriForAziendaById: async function (amministratoriId) {
    let result = await Utils.CreatePostRequest(
      "coasco/amministratori_aziende/getbyid?id=" + amministratoriId
    );
    if (result === null) result = {};
    return result.result;
  },

  GetNuovoAmministratoriForAziendaById: async function () {
    return {
      id: null,
      cf: null,
      nominativo: null,
      indirizzo: null,
      comunex: null,
      capx: null,
      provx: null,
      piva: null,
      fax: null,
      tel: null,
      cell: null,
      email: null,
      email2: null,
      pec: null,
      data_ult_agg: null,
      tipo_ult_agg: null,
      indirizzoxposta: null,
      comunexposta: null,
      capxposta: null,
      provxposta: null,
      utente: null
    };
  },

  AddAmministratori: async function (ammobj, user) {
    ammobj.utente = user;
    return await Utils.CreatePutRequest(
      "coasco/amministratori/update",
      ammobj
    );
  },
  UpdateAmministratori: async function (ammobj, user) {
    ammobj.utente = user;
    return await Utils.CreatePutRequest(
      "coasco/amministratori/update",
      ammobj
    );
  },

  DeleteAmministratori: async function (ammId) {
    return await Utils.CreateDeleteRequest(
      "coasco/amministratori/delete?id=" + ammId
    );
  },

  AddAmministratoriForAzienda: async function (ammobj, id_azienda) {
    ammobj.utente = "Claudio";
    await Utils.CreatePutRequest(
      "coasco/amministratori_aziende/insert?id_azienda=" + id_azienda,
      ammobj
    );
  },

  DeleteAziendeAmministratori: async function (ammId) {
    return await Utils.CreateDeleteRequest(
      "coasco/amministratori_aziende/delete?id=" + ammId
    );
  },

  GetByIdAzienda: async function (aziendaId) {
    const response = await Utils.CreatePostRequest(
      "coasco/amministratori_aziende/getbyidazienda?idazienda=" + aziendaId
    );
    if (response?.message?.includes("200")) {
      return response.result;
    } else return null;
  },

  GetForFiltroPerPosta: async function (
    selectedCodQ,
    selectedCaratteristiche,
    selectedProvincia,
    selectedRegione
  ) {
    const cod_quota =
      "?cod_quota=" + (selectedCodQ?.map((codQ) => codQ.name) ?? "");
    const caratteristiche =
      "&caratteristiche=" +
      (selectedCaratteristiche?.map((car) => car.cod) ?? "");
    const provincia = "&provincia=" + (selectedProvincia?.sigla ?? "");
    const regione =
      "&regione=" + (selectedRegione?.map((reg) => reg.nome) ?? "");
    const url =
      "coasco/amministratori/getForFiltroPerPosta" +
      cod_quota +
      caratteristiche +
      provincia +
      regione;
    const response = await Utils.CreatePostRequest(url);
    if (response?.message?.includes("200")) {
      return response.result.map((row) => {
        return { nominativo: row[0], cf: row[1], id: row[2] };
      });
    } else return null;
  }
};
export default AmministratoriAPI;
