import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { AziendaHeader } from "components/Views/Aziende/AziendaHeader.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import YearPicker from "components/YearPicker/index";
import CustodiPulitoriAPI from "API/CustodiPulitoriAPI";
import helpers from "../../helpers/Helpers";
import {Tooltip} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "../../components/CustomButtons/Button";
import {parse} from "query-string";

class VersamentiXAzienda extends Component {

  state = {
    rowsCustodi: [],
    rowsPulitori: [],
    selectedGriCustodi: null,
    selectedGridPulitori: null,
    filterCustodi: null,
    filterPulitori: null,
    isChecked: false,
    loadingGrid: false,
    loadingGridPulitori: false,
    filterLabelTextCustodi: "Attiva Filtri",
    filterLabelTextPulitori: "Attiva Filtri",
    competenzaAnno: new Date().getFullYear(),
    competenzaAnnoDT: new Date().getFullYear()
  };

  constructor(props) {
    super(props);
    this.ChangeFilterPulitori = this.ChangeFilterPulitori.bind(this);
    this.ChangeFilterCustodi = this.ChangeFilterCustodi.bind(this);
    this.handleTableClickCustodi = this.handleTableClickCustodi.bind(this);
    this.handleTableClickPulitori = this.handleTableClickPulitori.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.IncreaseYear = this.IncreaseYear.bind(this);
    this.DecreaseYear = this.DecreaseYear.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  async handleDateChange(dateParam) {
    this.setState(
      {
        competenzaAnno: dateParam
      },
      async () => await this.refreshGrid()
    );
  }

  async ChangeFilterPulitori(e) {
    this.setState({ filterPulitori: e.target.checked });
  }

  async IncreaseYear() {
    this.setState(
      {
        competenzaAnno: this.state.competenzaAnno + 1
      },
      async () => await this.refreshGrid()
    );
  }

  async DecreaseYear() {
    this.setState(
      {
        competenzaAnno: this.state.competenzaAnno - 1
      },
      async () => await this.refreshGrid()
    );
  }

  async ChangeFilterCustodi(e) {
    this.setState({ filterCustodi: e.target.checked });
  }

  handleChange(e) {
    this.setState({ isChecked: e.target.checked });
  }

  async refreshGrid() {
    let aziendaId = this.props.match.params.aziendaId;

    this.setState({
      loadingGrid: true
    });

    let rwC = await CustodiPulitoriAPI.GetPulitoriCustodiByAziendaId(
      aziendaId,
      this.state.competenzaAnno,
      "CUST"
    );
    helpers.parseDates(rwC, ["data_inser"]);
    helpers.formatCurrency(rwC, "retribuzioni", "importo_quota");
    this.setState({
      rowsCustodi: rwC,
      loadingGrid: false
    });

    this.setState({
      loadingGridPulitori: true
    });

    let rwP = await CustodiPulitoriAPI.GetPulitoriCustodiByAziendaId(
      aziendaId,
      this.state.competenzaAnno,
      "PULI"
    );
    helpers.parseDates(rwP, ["data_inser"]);
    helpers.formatCurrency(rwP, "retribuzioni", "importo_quota");
    this.setState({
      rowsPulitori: rwP,
      loadingGridPulitori: false
    });
  }

  async onSearchClick() {
    await this.refreshGrid();
  }

  async handleTableClickCustodi(e) {
    this.setState({
      selectedGridCustodi: e.value
    });
  }

  async handleTableClickPulitori(e) {
    this.setState({
      selectedGridPulitori: e.value
    });
  }

  backButtonClick() {
    let qS = parse(this.props.location.search);
    let maintainSearch = "?maintainSearch=1";
    if (!qS.type)
      this.props.history.push('/admin/aziende/' + this.props.match.params.aziendaId + maintainSearch);
    else if (qS.type === "fromVersamenti")
      this.props.history.push('/admin/versamenti/details/' + this.props.match.params.aziendaId + maintainSearch);
    else if (qS.type === "fromModificaRichieste")
      this.props.history.push('/admin/richiedenti/modificarichieste/' + this.props.match.params.aziendaId + '/' + qS.typeId + maintainSearch);
    else if (qS.type === "fromRichieste")
      this.props.history.push('/admin/richiedenti/modificarichieste/' + this.props.match.params.aziendaId + '/' + qS.typeId + maintainSearch + '&type=' + qS.type);
    else if (qS.type === "fromConsulenti")
      this.props.history.push('/admin/consulenti/' + this.props.match.params.aziendaId + maintainSearch);
    else if (qS.type === "fromAmministratori")
      this.props.history.push('/admin/amministratori/' + this.props.match.params.aziendaId + maintainSearch);
    else if (qS.type === "fromDipendenti")
      this.props.history.push('/admin/dipendenti/' + this.props.match.params.aziendaId + maintainSearch);
  }

  render() {
    return (
      <div>
        <AziendaHeader
          aziendaId={this.props.match.params.aziendaId}
          {...this.props}
        />

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                  className="float-left"
                >
                  Custodi
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterCustodi}
                      onChange={this.ChangeFilterCustodi}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={this.state.filterLabelTextCustodi}
                />

                <div
                  className="float-left date"
                  style={{
                    verticalAlign: "top",
                    lineHeight: "1",
                    marginRight: "5px",
                    display: "inline-flex",
                    marginTop: "10px"
                  }}
                >
                  <SkipPreviousIcon
                    onClick={this.DecreaseYear}
                    className="slide"
                  />
                  <YearPicker
                    onChange={this.handleDateChange}
                    value={this.state.competenzaAnno}
                    className="date"
                  />
                  <SkipNextIcon onClick={this.IncreaseYear} className="slide" />
                </div>

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Alla pagina precedente"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.backButtonClick}
                  >
                    <ArrowBackIcon />
                  </Button>
                </Tooltip>

              </CardHeader>
              <CardBody>
                <DataTable
                  value={this.state.rowsCustodi}
                  className="p-datatable-striped p-datatable-sm"
                  dataKey="id"
                  paginator emptyMessage=""
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={12} rowsPerPageOptions={[12, 24, 36, 48]}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGridCustodi}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClickCustodi}
                  selectionMode="multiple"
                  metaKeySelection={false}
                >
                  <Column
                    field="codice_quota"
                    header="Codice Quota"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="competenza"
                    header="Competenza"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="ripartizione"
                    header="Ripartizione"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importo_quota"
                    header="Importo Quota"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    body={r => helpers.DecimalDTTemplate(r, "importo_quota")}
                  />
                  <Column
                    field="totale_dip"
                    header="Totale Dip."
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="retribuzioni"
                    header="Retribuzioni"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    body={r => helpers.DecimalDTTemplate(r, "retribuzioni")}
                  />
                  <Column
                    field="data_inser"
                    header="Data Inserimento"
                    headerStyle={{ width: "180px" }}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    body={row => helpers.dateTimeToString(row, "data_inser")}
                  />
                  <Column
                    field="tipo_ultimo_agg"
                    header="Tipo Ultimo Agg."
                    headerStyle={{ width: "170px" }}
                    sortable={true}
                    filter={this.state.filterCustodi}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Pulitori
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterPulitori}
                      onChange={this.ChangeFilterPulitori}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={this.state.filterLabelTextPulitori}
                />
              </CardHeader>

              <CardBody>
                <DataTable
                  value={this.state.rowsPulitori}
                  className="p-datatable-striped p-datatable-sm"
                  dataKey="id"
                  paginator emptyMessage=""
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={12} rowsPerPageOptions={[12, 24, 36, 48]}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGridPulitori}
                  loading={this.state.loadingGridPulitori}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClickPulitori}
                  selectionMode="multiple"
                  metaKeySelection={false}
                >
                  <Column
                    field="codice_quota"
                    header="Codice Quota"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="competenza"
                    header="Competenza"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="ripartizione"
                    header="Ripartizione"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importo_quota"
                    header="Importo Quota"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    body={r => helpers.DecimalDTTemplate(r, "importo_quota")}
                  />
                  <Column
                    field="totale_dip"
                    header="Totale Dip."
                    headerStyle={{ width: "120px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="retribuzioni"
                    header="Retribuzioni"
                    headerStyle={{ width: "150px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    body={r => helpers.DecimalDTTemplate(r, "retribuzioni")}
                  />
                  <Column
                    field="data_inser"
                    header="Data Inserimento"
                    headerStyle={{ width: "180px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    body={row => helpers.dateTimeToString(row, "data_inser")}
                  />
                  <Column
                    field="tipo_ultimo_agg"
                    header="Tipo Ultimo Agg."
                    headerStyle={{ width: "170px" }}
                    sortable={true}
                    filter={this.state.filterPulitori}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
export default VersamentiXAzienda;
