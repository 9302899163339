import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { FileUpload } from "primereact/fileupload";
import CaricamentiAPI from "API/CaricamentiAPI";
import { SiteUrl } from "helpers/AppConstants";
import { getToken } from "../../common";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";

class CaricamentoUniemens extends React.Component {
  state = {
    selectedTipo: null,
    disabledUpload: true,
    selectedFile: null,
    fileUpload: null,
    loaderSpinner: false
  };

  constructor(props) {
    super(props);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onError = this.onError.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.btnSearchRef = React.createRef();
  }

  onSelect(e) {
    if (e.files.length > 0) {
      for (const file of e.files) {
        if (
          !file.name.toUpperCase().startsWith("CUSTUNIEMENSLAV") &&
          !file.name.toUpperCase().startsWith("PULIUNIEMENSLAV") &&
          !file.name.toUpperCase().startsWith("PULIUNIEMENSDEL") &&
          !file.name.toUpperCase().startsWith("CUSTUNIEMENSDEL")
        ) {
          this.props.addAlert.current.addAlert(
            "danger",
            "Uno o più file non rispetta il formato del nome"
          );
          this.fileUpload.clear();
        }
      }
    }
  }

  async componentDidMount() {
    await this.refreshGrid();
  }

  onUpload(e) {
    const response = JSON.parse(e?.xhr?.response);
    if (response?.message?.includes("200")) {
      this.props.addAlert.current.addAlert("success", "File preso in carica");
    } else {
      this.props.addAlert.current.addAlert("danger", response?.message);
    }
    this.setState({loaderSpinner: false});
  }

  onError(e) {
    const response = JSON.parse(e?.xhr?.response);
    if (response?.message?.includes("200")) {
      this.props.addAlert.current.addAlert("success", "File preso in carica");
    } else {
      this.props.addAlert.current.addAlert("danger", response?.message);
    }
    this.setState({loaderSpinner: false});
  }

  onSubmit() {
    const jsonObject = {
      TipoDiScarico: this.state.selectedTipo.id
    };
    const file = this.state.selectedFile;
  }

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });

    if (val !== null) {
      this.setState({
        disabledUpload: false
      });
    } else
      this.setState({
        disabledUpload: true
      });
  }

  async refreshGrid() {
    this.setState({});
  }

  handleUpload = async (files) => {
    const response = await CaricamentiAPI.AddCaricamenti(
      this.state.selectedFile
    );
    if (response && response.result)
      this.props.addAlert.current.addAlert("success", "File caricati");
    else
      this.props.addAlert.current.addAlert(
        "danger",
        "Si è verificato un errore nel caricamento dei file"
      );

    return true;
  };

  render() {
    return (
      <>
        {this.state.loaderSpinner && <LoaderSpinner />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <h4
                  style={{
                    lineHeight: "1",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Funzionalita di supporto: Carica gli Uniemens
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FileUpload
                      ref={(e) => (this.fileUpload = e)}
                      onSelect={this.onSelect}
                      chooseLabel="Scegli"
                      uploadLabel="Carica"
                      cancelLabel="Annulla"
                      name="file"
                      url={`${SiteUrl}coasco/caricamenti/insert`}
                      onUpload={this.onUpload}
                      onError={this.onError}
                      multiple
                      accept=".txt"
                      emptyTemplate={<p className="p-m-0">Seleziona file</p>}
                      onBeforeSend={(e) => {
                        e.xhr.setRequestHeader(
                          "Authorization",
                          `Bearer ${getToken()}`
                        );
                        this.setState({loaderSpinner: true});
                        return e;
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default CaricamentoUniemens;
