import React from "react";
import * as AppConstants from "./AppConstants";
import { dateFmt, dateTimeFmt } from "./AppConstants";
import moment from "moment";

const helpers = {
  generatePDF: function (res, fileName) {
    const _res = new Blob([res?.data]);
    let url = window.URL.createObjectURL(_res);
    let a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}.pdf`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  },

  generateExcel: function (res, fileName) {
    const _res = new Blob([res?.data]);
    let url = window.URL.createObjectURL(_res);
    let a = document.createElement("a");
    a.href = url;
    a.download = `${fileName}.xlsx`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  },

  FilteredListForAutocomplete: function (event, fullList) {
    let filteredListByInput = [];

    if (event.query.trim().length > 3) {
      if (fullList.length > 0) {
        fullList
          .filter((m) => {
            return m.toLowerCase().includes(event.query.toLowerCase());
          })
          .filter((val) => {
            if (val.length > 0) {
              filteredListByInput.push(val);
            }
          });
      }
    }

    return filteredListByInput;
  },

  FilteredCfList: function (event, fullList) {
    let filteredCfListByInput = [];

    if (event.query.trim().length > 3) {
      if (fullList.length > 0) {
        fullList
          .filter((m) => {
            return (
              m.cf && m.cf.toLowerCase().includes(event.query.toLowerCase())
            );
          })
          .filter((val) => {
            if (val.cf.length > 0) {
              filteredCfListByInput.push(val.cf);
            }
          });
      }
    }

    return filteredCfListByInput;
  },

  FormatDate: function (date) {
    if (date === null) return "";

    let d = date,
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  },

  ConvertToItalianDate: function (date) {
    if (date === null) return "";

    let d = date.split("/");

    let day = d[1];
    let month = d[0];
    let year = d[2];

    month = this.AddingZeroForDaysAndMonths(month);
    day = this.AddingZeroForDaysAndMonths(day);

    return [day, month, year].join("/");
  },

  FormatPrimeReactDate: function (date) {
    if (date === null) return "";

    let d = date.split("/");

    let day = d[0];
    let month = d[1];
    let year = d[2];

    return [month, day, year].join("/");
  },

  GetYear: function (date) {
    if (date === null) return "";
    return date.getFullYear();
  },

  DateTemplateWithHours: function (rowData, field) {
    if (rowData && rowData[field])
      return <> {this.ConvertToItalianDateWithHours(rowData[field])}</>;
    else return <></>;
  },

  ConvertToItalianDateWithHours: function (date) {
    if (date === null || date === "") return "";
    else if (typeof date === "number") {
      let d = new Date(date);

      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();

      month = this.AddingZeroForDaysAndMonths(month);
      day = this.AddingZeroForDaysAndMonths(day);

      date = [day, month, year].join("/");

      return date;
    } else return date.split(" ")[0];
  },

  compareStringDates: function (data1, data2) {
    let data1Split = data1.split(" ");
    let data2Split = data2.split(" ");

    let data1ToCompare = data1Split[0].split("/");
    let data2ToCompare = data2Split[0].split("/");

    if (parseInt(data1ToCompare[2]) < parseInt(data2ToCompare[2])) {
      return true;
    } else if (parseInt(data1ToCompare[2]) > parseInt(data2ToCompare[2])) {
      return false;
    } else if (parseInt(data1ToCompare[2]) === parseInt(data2ToCompare[2])) {
      if (parseInt(data1ToCompare[1]) < parseInt(data2ToCompare[1])) {
        return true;
      } else if (parseInt(data1ToCompare[1]) > parseInt(data2ToCompare[1])) {
        return false;
      } else if (parseInt(data1ToCompare[1]) === parseInt(data2ToCompare[1])) {
        if (parseInt(data1ToCompare[0]) < parseInt(data2ToCompare[0])) {
          return true;
        } else if (parseInt(data1ToCompare[0]) > parseInt(data2ToCompare[0])) {
          return false;
        } else if (
          parseInt(data1ToCompare[0]) === parseInt(data2ToCompare[0])
        ) {
          return true;
        }
      }
    }
  },

  convertToEnglishDayAndMonthFromString: function (data) {
    if (data === null || data === "") {
      return "";
    } else if (typeof data === "string") {
      let dataSplit = data.split(" ");

      let dataDaRiordinare = dataSplit[0].split("/");
      return (
        dataDaRiordinare[2] +
        "-" +
        dataDaRiordinare[1] +
        "-" +
        dataDaRiordinare[0]
      );
    } else if (typeof data === "object") {
      return data;
    }
  },

  GetDateObjectFromStringDate: function (date) {
    if (date === null || date === "") return "";
    else if (typeof date === "number") {
      let d = new Date(date);
      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      month = this.AddingZeroForDaysAndMonths(month);
      day = this.AddingZeroForDaysAndMonths(day);
      date = [month, day, year].join("/");
      return date;
    } else {
      date = date.split(" ")[0];
      if (date.indexOf("/") > 1) {
        let dateLst = date.split("/");
        return new Date([dateLst[1], dateLst[0], dateLst[2]].join("/"));
      } else {
        let dateLst = date.split("-");
        return new Date([dateLst[1], dateLst[2], dateLst[0]].join("/"));
      }
    }
  },

  ConvertToItalianDateForPrime: function (date) {
    if (date === null || date === "") return "";
    else if (typeof date === "number") {
      let d = new Date(date);
      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      month = this.AddingZeroForDaysAndMonths(month);
      day = this.AddingZeroForDaysAndMonths(day);
      date = [month, day, year].join("/");
      return date;
    } else {
      let d = new Date(date);
      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      month = this.AddingZeroForDaysAndMonths(month);
      day = this.AddingZeroForDaysAndMonths(day);
      return [month, day, year].join("/");
    }
  },

  convertToItalianDateTime: function (date) {
    if (date === null || date === "") return "";
    else if (typeof date === "number") {
      let d = new Date(date);
      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      month = this.AddingZeroForDaysAndMonths(month);
      day = this.AddingZeroForDaysAndMonths(day);
      date = [month, day, year].join("/");
      return date;
    } else {
      let d = new Date(date);
      const hours = d.getHours();
      const minutes = d.getMinutes();
      const seconds = d.getSeconds();
      const _seconds = seconds < 10 ? `0${seconds}` : seconds;
      const time = `${hours}:${minutes}:${_seconds}`;
      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      month = this.AddingZeroForDaysAndMonths(month);
      day = this.AddingZeroForDaysAndMonths(day);
      const _date = [month, day, year].join("/");
      return `${_date} ${time}`;
    }
  },

  AddingZeroForDaysAndMonths: function (dateElement) {
    if (dateElement < 10) dateElement = "0" + dateElement;
    return dateElement;
  },

  DecimalDTTemplate: function (rowData, field) {
    const fieldForCurrency = field + "ForCurrency";
    if (
      rowData &&
      rowData[fieldForCurrency] !== undefined &&
      rowData[fieldForCurrency] !== null
    )
      return rowData[fieldForCurrency];
    else if (rowData && rowData[field] !== null)
      return <>€ {this.numberWithCommas(rowData[field])}</>;
    else return <></>;
  },

  formatCurrency: function (rows, ...fields) {
    if (rows !== null) {
      for (const field of fields) {
        const max = Math.max(
          ...rows.map((r) => helpers.numberWithCommas(r[field] ?? "").length)
        );
        for (let r of rows) {
          if (r[field] !== null) {
            r[field + "ForCurrency"] = (
              <span style={{ whiteSpace: "pre", fontFamily: "monospace" }}>
                € {this.numberWithCommas(r[field]).padStart(max, " ")}
              </span>
            );
          }
        }
      }
    }
  },

  NegativeDecimalDTTemplate: function (rowData, field) {
    return <>-€ {this.numberWithCommas(rowData[field])}</>;
  },

  DecimalInputTemplate: function (field) {
    if (field) return <>€ {this.numberWithCommas(field)}</>;
    else return <></>;
  },

  DateTemplate: function (rowData, field) {
    if (rowData && rowData[field])
      return <>{this.ConvertToItalianDate(rowData[field])}</>;
    else return <></>;
  },

  numberWithCommas: function (x) {
    return x.toLocaleString("it-It", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  },

  SimulateEventFire: function (el, etype) {
    try {
      if (el.fireEvent) {
        el.fireEvent("on" + etype);
      } else {
        let evObj = document.createEvent("Events");
        evObj.initEvent(etype, true, false);
        el.dispatchEvent(evObj);
      }
    } catch (err) {
      console.error(err);
    }
  },

  ReturnDateFromArray: function (list) {
    let year, month, day;
    if (list === null) return "";
    else if (list.length > 0) {
      year = list[0];
      month = list[1];
      day = list[2];
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      return [month, day, year].join("/").toString();
    }
  },

  ReturnDateFromArrayForUltimoAgg: function (list) {
    let year, month, day;
    if (list === null) return "";
    else if (list.length > 0) {
      year = list[0];
      month = list[1];
      day = list[2];
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      return [day, month, year].join("/").toString();
    }
  },

  ConvertDateAsArray: function (date) {
    if (date === null) return "";
    let d = date;
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();
    return [year, month, day].join("-");
  },

  ConvertDateAsArrayWithSlash: function (date) {
    if (date === null || date === "") return "";
    else if (typeof date === "number") {
      let d = new Date(date);
      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      month = this.AddingZeroForDaysAndMonths(month);
      day = this.AddingZeroForDaysAndMonths(day);
      date = [month, day, year].join("/") + " 00:00:00";
      return date;
    } else {
      let d = date;
      let month = ("" + (d.getMonth() + 1)).padStart(2, "0");
      let day = ("" + d.getDate()).padStart(2, "0");
      let year = ("" + d.getFullYear()).padStart(2, "0");
      return [day, month, year].join("/") + " 00:00:00";
    }
  },

  OpenEditFirstRow: function (gridRef) {
    let btn =
      gridRef.current.container.childNodes[0].firstChild.childNodes[1]
        .firstChild.childNodes[1].childNodes[0].childNodes[0].childNodes[0];
    this.SimulateEventFire(btn, "click");
  },

  CloseAllEditRows: function (gridRef) {
    try {
      let rows =
        gridRef.current.container.childNodes[0].firstChild.childNodes[1]
          .firstChild.childNodes[1].childNodes;
      let currIdx = 0;
      let found = false;

      while (!found && currIdx <= rows.length) {
        let buttons = rows[currIdx].childNodes[0].childNodes;
        if (buttons.length === 2) this.SimulateEventFire(buttons[1], "click");
        currIdx++;
      }
    } catch (err) {}
  },

  dropDownOptionTemplate(option) {
    if (option)
      return (
        <div className="dropdownElementsStyle">
          <div>
            {option.codice} - {option.descrizione}
          </div>
        </div>
      );
    else return <></>;
  },

  dropDownOptionTemplateMod(option) {
    if (option)
      return (
        <div className="dropdownElementsStyle">
          <div>{option.descrizione}</div>
        </div>
      );
    else return <></>;
  },

  selectedDropdownTemplate(option) {
    if (option) {
      return (
        <div>
          {option.codice} - {option.name}
        </div>
      );
    } else return <></>;
  },

  ReturnRowFromListById: function (list, id) {
    return list?.filter((row) => row.id === id)[0];
  },

  ReturnIdFromList: function (list, id) {
    if (list !== null && list.length > 0) {
      return this.ReturnRowFromListById(list, id)?.id;
    } else return <></>;
  },

  ReturnIdFromListByType: function (list, type, element) {
    if (list !== null && list.length > 0) {
      if (type === "ProvByComune") {
        list = list.filter((row) => row.nome === element);

        if (list !== null) list = list[0].prov.sigla;
        else list = "";

        return list;
      }

      if (type === "ProvinciaSigla") {
        list = list.filter((row) => row.name === element);

        if (list.length > 0) list = list[0].cod;
        else list = element;
        return list;
      }

      if (type === "ComuneByProvList") {
        return list?.filter((x) => x.prov?.sigla === element);
      }

      if (type === "ComuneByCAP") {
        list = list?.filter((row) => row.nome === element);
        if (list !== null && list.length > 0) list = list[0].nome;
        else list = "";

        return list;
      }
      if (type === "Provincia") {
        list = list.filter((row) => row.cod === element);

        if (list.length > 0) list = list[0].name;
        else list = element;
        return list;
      }
      if (type === "Nazionalita") {
        list = list.filter((row) => row.cod === element);
        if (list.length > 0) list = list[0].id;
        else list = 0;

        return list;
      }

      if (type === "Sede") {
        list = list.filter((row) => row.sap === element);

        if (list.length > 0) {
          list = list[0].sap;
        } else list = 0;

        return list;
      }

      if (
        type === "TipoAzienda" ||
        type === "TipoCess" ||
        type === "Denuncia" ||
        type === "Lavoro" ||
        type === "Contrato"
      ) {
        list = list.filter((row) => row.codice === element);
        if (list.length > 0) {
          list = list[0].codice;
        } else list = 0;

        return list;
      }

      if (type === "Comune") {
        list = list.filter((row) => row.nome === element);
        if (list.length > 0) {
          list = list[0].nome;
        } else list = "";
        return list;
      }

      if (type === "Sesso" || type === "Codice") {
        list = list.filter((row) => row.name === element);
        if (list.length > 0) list = list[0].id;
        else list = 0;

        return list;
      }
    } else return <></>;
  },

  ReturnElementFromListByIdAndType: function (list, type, id) {
    if (list?.length > 0) {
      if (type === "Sede") {
        list = list.filter((row) => row.id === id)[0].cod;
        return list;
      }
      if (type === "Codice") {
        list = list.filter((row) => row.id === id)[0].name;
        return list;
      }
    } else return <></>;
  },

  GetFirstElementOfPageIncludingId: function (idToView, list, elPerPage) {
    let pos = list.findIndex((obj) => obj.id === idToView) + 1;
    return Math.trunc(pos / elPerPage) * elPerPage;
  },

  SaveSearchFilters: function (filterObject, pageName) {
    localStorage.setItem(
      AppConstants.UserId + "#" + pageName,
      JSON.stringify(filterObject)
    );
  },

  GetSearchFilters: function (pageName) {
    let savedFilter = localStorage.getItem(
      AppConstants.UserId + "#" + pageName
    );
    if (savedFilter !== null) return JSON.parse(savedFilter);
    return savedFilter;
  },

  SaveLastPage: function (lastPage, pageName) {
    localStorage.setItem(
      AppConstants.UserId + "#" + pageName,
      JSON.stringify(lastPage)
    );
  },
  GetLastPage: function (pageName) {
    let savedFilter = localStorage.getItem(
      AppConstants.UserId + "#" + pageName
    );
    if (savedFilter !== null) return JSON.parse(savedFilter);
    else return 0;
  },

  ValidateForm: function (formValues, validationRules) {
    let fieldValidations = {};
    for (let propName in validationRules) {
      let validation = validationRules[propName].split("-");
      validation = {
        rule: validation[0],
        value: validation[1],
        optional: validation[2]
      };

      let fieldValue = formValues[propName];

      // non optional fields will be checked for null or length 0 before the rules
      let validationResult =
        fieldValue === null ||
        fieldValue === undefined ||
        fieldValue?.length === 0 ||
        !fieldValue.toString().replace(/\s/g, "")?.length
          ? "error"
          : "success";

      if (validationResult === "error" && validation.optional === "optional") {
        // value is empty but optional
        validationResult = "success";
        fieldValidations[propName] = validationResult;
        continue;
      }

      // If the property passed the not null or empty validation, check the other rules
      if (validationResult === "success") {
        switch (validation.rule) {
          // exact is used when the value should be of exactly {validation.value} in length
          case "EQ":
            validationResult =
              fieldValue?.length === parseInt(validation.value, 10)
                ? "success"
                : "error";
            break;
          case "LTEQ":
            validationResult =
              fieldValue?.length <= parseInt(validation.value, 10)
                ? "success"
                : "error";
            break;
          case "GTEQ":
            validationResult =
              fieldValue?.length >= parseInt(validation.value, 10)
                ? "success"
                : "error";
            break;
          case "LT":
            validationResult =
              fieldValue?.length < parseInt(validation.value, 10)
                ? "success"
                : "error";
            break;
          case "GT":
            validationResult =
              fieldValue?.length > parseInt(validation.value, 10)
                ? "success"
                : "error";
            break;
          case "CF":
            let re = /^[a-zA-Z]{6}\d{2}[a-zA-Z]\d{2}[a-zA-Z]\d{3}[a-zA-Z]$/;
            validationResult = re.test(fieldValue) ? "success" : "error";
            break;
            case "CFPIVA":
              let reg = /^[a-zA-Z]{6}\d{2}[a-zA-Z]\d{2}[a-zA-Z]\d{3}[a-zA-Z]$/;
              let reg2 = /^[0-9]{11}$/;
              validationResult = (reg.test(fieldValue) || reg2.test(fieldValue))? "success" : "error";
              break;
          case "NUMBER":
            validationResult = isNaN(Number(fieldValue)) ? "error" : "success";
            break;
        }
      }
      fieldValidations[propName] = validationResult;
    }

    return fieldValidations;
  },

  parseDates: function (rows, fields) {
    for (let row of rows) {
      for (let field of fields)
        row[field] = row[field]
          ? moment(row[field], dateTimeFmt).toDate()
          : null;
    }
  },

  dateToString: function (row, field) {
    return row[field] ? <>{moment(row[field]).format(dateFmt)}</> : "";
  },

  dateTimeToString: function (row, field) {
    return row[field] ? <>{moment(row[field]).format(dateTimeFmt)}</> : "";
  },

  truncateString(row, field) {
    if (row[field] && row[field].length > 10)
      return <>{row[field].slice(0, 10) + "..."}</>;
    else return <>{row[field]}</>;
  },

  comuniView(comuni) {
    return comuni
      .map((c) => c.nome)
      .filter((n, idx, arr) => arr.indexOf(n) === idx)
      .map((n) => {
        return { label: n, value: n };
      });
  }
};

export default helpers;
