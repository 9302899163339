import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { ModificaUtente } from "components/Views/Utenti/ModificaUtente.component.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import LoaderSpinner from "../../../src/components/Layout/LoaderSpinner.js";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import queryString from "query-string";
import Close from "@material-ui/icons/Close";
import helpers from "helpers/Helpers";
import { confirmPopup } from "primereact/confirmpopup";
import ConsulentiAPI from "API/ConsulentiAPI";
import UtentiAPI from "API/Utenti.api.js";
class Utenti extends React.Component {
  anchor = null;
  state = {
    filteredRows: [],
    initialRows: [],

    viewButtons: false,

    selectedGridConsulente: null,
    selectedUser: null,

    visibleLoader: false,

    filterGrid: false,
    loadingGrid: false,

    filterByNominativo: "",
    filterByCodice: "",

    idForDelete: 0,

    nominativoFullList: [],
    codiceFullList: [],
    filteredCodiceListByInput: null,
    filteredNominativoListByInput: null,

    firstRowToDisplay: 0,
    viewNuovoConsulente: false
  };

  constructor(props) {
    super(props);

    this.handleTableClick = this.handleTableClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.getFilteredRows = this.getFilteredRows.bind(this);
    this.btnSearchRef = React.createRef();
    this.RedirectBtn = this.RedirectBtn.bind(this);
    this.DeletePulitoriGridTemplate =
      this.DeletePulitoriGridTemplate.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);

    this.searchCf = this.searchCf.bind(this);
    this.searchNominativo = this.searchNominativo.bind(this);
    this.onEnterPress = this.onEnterPress.bind(this);

    this.DeleteRecord = this.DeleteRecord.bind(this);
    this.RefreshGrid = this.RefreshGrid.bind(this);
    this.ChangeButtons = this.ChangeButtons.bind(this);
  }

  onEnterPress(event) {
    if (event.key === "Enter") {
      if (this.state.filterByCodice.length > 3) this.handleSearchClick();
    }
  }

  async onNuovoClick() {
    this.setState({
      visibleLoader: true,
      viewNuovoConsulente: false
    });

    var newUser = await UtentiAPI.newUser();
    this.setState({
      selectedGridConsulente: null,
      selectedUser: newUser,
      viewButtons: false
    });

    this.setState({
      visibleLoader: false
    });
  }

  RedirectBtn(buttonName) {
    if (buttonName === "Storico")
      this.props.history.push(
        "/admin/StoricoXConsulente/" + "0/" + this.state.selectedUser.codice
      );
    else if (buttonName === "Ditte")
      this.props.history.push(
        "/admin/DitteXConsulente/" + "0/" + this.state.selectedUser.codice
      );
    else if (buttonName === "Corrisp")
      this.props.history.push(
        "/admin/Corrispondenza/" +
          this.state.selectedUser.codice +
          "?type=fromConsulenti"
      );
  }

  getFilteredRows() {
    var filteredRows = this.state.initialRows;

    if (this.state.filterByNominativo)
      filteredRows = filteredRows.filter(
        (row) =>
          row.nominativo &&
          row.nominativo
            .toLowerCase()
            .indexOf(this.state.filterByNominativo.toString().toLowerCase()) >=
            0
      );

    if (this.state.filterByCodice)
      filteredRows = filteredRows.filter(
        (row) =>
          row.cf &&
          row.cf
            .toLowerCase()
            .indexOf(this.state.filterByCodice.toLowerCase()) >= 0
      );

    return filteredRows;
  }

  searchNominativo(event) {
    setTimeout(() => {
      this.setState({
        filteredNominativoListByInput: helpers.FilteredListForAutocomplete(
          event,
          this.state.nominativoFullList
        )
      });
    }, 250);
  }

  searchCf(event) {
    setTimeout(() => {
      this.setState({
        filteredCodiceListByInput: helpers.FilteredCfList(
          event,
          this.state.codiceFullList
        )
      });
    }, 250);
  }

  handleSearchClick = async () => {
    helpers.SaveSearchFilters(
      {
        filterByCodice: this.state.filterByCodice,
        filterByNominativo: this.state.filterByNominativo
      },
      "ConsulentiList"
    );

    this.setState({
      selectedUser: null,
      selectedGridConsulente: null,
      filteredRows: this.getFilteredRows()
    });
  };

  async handleClearClick() {
    helpers.SaveSearchFilters(null, "ConsulentiList");
    this.setState({
      loadingGrid: true
    });
    this.setState({
      filteredRows: await UtentiAPI.getUsers(),
      userTypes: await UtentiAPI.getUserTypes(),
      selectedGridConsulente: null,
      selectedUser: null,
      filteredCodiceListByInput: null,
      filteredNominativoListByInput: null,
      filterByNominativo: "",
      filterByCodice: ""
    });
    this.setState({
      loadingGrid: false
    });
  }

  DeletePulitoriGridTemplate(rowData) {
    return (
      <Button
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData.codice}
        onClick={(e) => this.onDeleteClick(e, rowData.codice)}
      >
        <Close className="icon" />
      </Button>
    );
  }

  onDeleteClick(event, id) {
    var recordId = id;

    event.stopPropagation();

    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => this.DeleteRecord(recordId),
      reject: () => {}
    });
  }

  async DeleteRecord(id) {
    let response;

    response = await UtentiAPI.deleteUser(id);

    if (response.result === null) {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("warning", response.message);
      }, 1200);
    } else {
      window.setTimeout(() => {
        this.props.addAlert.current.addAlert("success", "Dati eliminati.");
      }, 1200);
    }

    this.setState({
      loadingGrid: true,
      selectedUser: null
    });

    var initialRows = await UtentiAPI.getUsers();

    var mycflist = await ConsulentiAPI.GetAllCf(this.state.filterByCodice);
    let mynominativolist = [];

    if (initialRows.length > 0) {
      initialRows.filter((val) => {

        return val.nominativo !== null && mynominativolist.push(val.nominativo);
      });
    }

    let filterByCodice = this.state.filterByCodice;
    let filterByNominativo = this.state.filterByNominativo;

    let queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      var savedFilters = helpers.GetSearchFilters("ConsulentiList");
      if (savedFilters !== null) {
        filterByCodice = savedFilters.filterByCodice;
        filterByNominativo = savedFilters.filterByNominativo;
      }
    } else helpers.SaveSearchFilters(null, "ConsulentiList");

    this.setState(
      {
        initialRows: initialRows,
        filterByCodice: filterByCodice,
        filterByNominativo: filterByNominativo,

        codiceFullList: mycflist.length > 0 ? mycflist : [],
        nominativoFullList: mynominativolist.length > 0 ? mynominativolist : []
      },
      async () => {
        let filteredRows = this.getFilteredRows();

        var selectedRow = null;
        var firstRowToDisplay = 0;

        var consulenteId = this.props.match.params.consulenteId;
        if (consulenteId > 0) {
          selectedRow = helpers.ReturnRowFromListById(
            filteredRows,
            consulenteId
          );
          firstRowToDisplay = helpers.GetFirstElementOfPageIncludingId(
            consulenteId,
            filteredRows,
            10
          );
        }

        this.setState({
          firstRowToDisplay: firstRowToDisplay,
          filteredRows: filteredRows
        });

        if (selectedRow)
          await this.handleTableClick({
            value: selectedRow
          });
      }
    );

    this.setState({
      loadingGrid: false
    });
  }

  //#endregion

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });

    var initialRows = await UtentiAPI.getUsers();
    const userTypes = await UtentiAPI.getUserTypes();

    var mycflist = await ConsulentiAPI.GetAllCf(this.state.filterByCodice);
    let mynominativolist = [];

    if (initialRows.length > 0) {
      initialRows.filter((val) => {

        return val.nominativo !== null && mynominativolist.push(val.nominativo);
      });
    }

    // get filtered values object
    let filterByCodice = this.state.filterByCodice;
    let filterByNominativo = this.state.filterByNominativo;

    var queryStringObject = queryString.parse(
      this.props.history.location.search
    );
    if (
      queryStringObject !== null &&
      queryStringObject.maintainSearch === "1"
    ) {
      var savedFilters = helpers.GetSearchFilters("ConsulentiList");
      if (savedFilters !== null) {
        filterByCodice = savedFilters.filterByCodice;
        filterByNominativo = savedFilters.filterByNominativo;
      }
    } else helpers.SaveSearchFilters(null, "ConsulentiList");

    this.setState(
      {
        initialRows: initialRows,
        filterByCodice: filterByCodice,
        filterByNominativo: filterByNominativo,
        userTypes,
        codiceFullList: mycflist.length > 0 ? mycflist : [],
        nominativoFullList: mynominativolist.length > 0 ? mynominativolist : []
      },
      async () => {
        let filteredRows = this.getFilteredRows();

        var selectedRow = null;
        var firstRowToDisplay = 0;

        var consulenteId = this.props.match.params.consulenteId;
        if (consulenteId > 0) {
          selectedRow = helpers.ReturnRowFromListById(
            filteredRows,
            consulenteId
          );
          firstRowToDisplay = helpers.GetFirstElementOfPageIncludingId(
            consulenteId,
            filteredRows,
            10
          );
        }

        this.setState({
          firstRowToDisplay: firstRowToDisplay,
          filteredRows: filteredRows
        });

        if (selectedRow)
          await this.handleTableClick({
            value: selectedRow
          });
      }
    );

    this.setState({
      loadingGrid: false
    });
  }

  //GRID FUNCTIONS

  onCloseGridClick() {
    this.setState({
      selectedGridConsulente: null,
      selectedUser: null
    });
    if (this.btnSearchRef.current) {
      try {
        this.btnSearchRef.current.scrollIntoView();
      } catch(err){}
    }
  }

  //#################################
  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  handleTableClick = async (e) => {
    this.setState({
      visibleLoader: true
    });

    const userId = e.value.codice;
    var user = await UtentiAPI.getUser(userId);

    this.setState({
      selectedUser: { ...user[0], password: null },
      selectedGridConsulente: e.value,
      viewButtons: true,
      viewNuovoConsulente: false
    });

    this.setState({
      visibleLoader: false
    });
  };

  HandleChange = async (event, val) => {
    this.setState({
      [event]: val
    });
  };

  HandleSaveClick = async (RowItem) => {
    const data = {
      codice: null,
      codice_profilo: 0,
      disabilitato: 0,
      password: RowItem.password,
      ufficio: RowItem.ufficio,
      username: RowItem.username,
      utente: RowItem.utente
    };
    if (RowItem.codice === null) {
      const res = await UtentiAPI.createUser(data);
      if (res) {
        this.state.selectedUser = res[0];
        this.setState({
          selectedUser: false
        });
      } else {
        this.setState({
          loadingGrid: false
        });
        if (RowItem.password) {
          this.props.addAlert.current.addAlert(
            "warning",
            "Inserire il tipo di Utente"
          );
        } else {
          this.props.addAlert.current.addAlert(
            "warning",
            "Inserire la password"
          );
        }
        return true;
      }
    } else {
      const _data = {
        codice: RowItem.codice,
        codice_profilo: RowItem.codice_profilo,
        disabilitato: RowItem.disabilitato,
        password: RowItem.password,
        ufficio: RowItem.ufficio,
        username: RowItem.username,
        utente: RowItem.utente
      };
      await UtentiAPI.updateUser(_data);
    }

    this.props.addAlert.current.addAlert("success", "Dati salvati.");

    var initialRows = await UtentiAPI.getUsers();

    this.setState(
      {
        initialRows: initialRows
      },
      async () => {
        let filteredRows = this.getFilteredRows();

        var selectedRow = null;
        var firstRowToDisplay = 0;

        var consulenteId = this.props.match.params.consulenteId;
        if (consulenteId > 0) {
          selectedRow = helpers.ReturnRowFromListById(
            filteredRows,
            consulenteId
          );
          firstRowToDisplay = helpers.GetFirstElementOfPageIncludingId(
            consulenteId,
            filteredRows,
            10
          );
        }

        this.setState({
          firstRowToDisplay: firstRowToDisplay,
          filteredRows: filteredRows
        });

        if (selectedRow)
          await this.handleTableClick({
            value: selectedRow
          });
      }
    );

    this.setState({
      loadingGrid: false
    });

    return true;
  };

  async RefreshGrid() {
    this.setState({
      loadingGrid: true
    });
    this.setState({
      filteredRows: await UtentiAPI.getUsers()
    });
    this.setState({
      loadingGrid: false
    });
  }

  ChangeButtons = async (existentConsulenti) => {

    if (existentConsulenti.tipo === "consulente") {
      await this.handleTableClick({
        value: existentConsulenti[0]
      });
    } else if (existentConsulenti.tipo === "amministratore") {
      this.setState({
        selectedGridConsulente: null,
        selectedUser: existentConsulenti,
        viewButtons: false,
        viewNuovoConsulente: false
      });
    }

    return;
  };

  render() {
    return (
      <>
        {this.state.visibleLoader && <LoaderSpinner />}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Utenti
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.filteredRows}
                  dataKey="codice"
                  first={this.state.firstRowToDisplay}
                  onPage={(e) => this.setState({ firstRowToDisplay: e.first })}
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={10}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGridConsulente}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="username"
                    header="Username"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="utente"
                    header="Utente"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="descrizione_ufficio"
                    header="Tipo Utente"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterPlaceholder=""
                  />
                  <Column
                    header=""
                    body={this.DeletePulitoriGridTemplate}
                    icon="pi pi-times"
                    label="Delete"
                    className="p-button-danger p-button-outlined colDelete"
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.selectedUser && (
          <ModificaUtente
            dataItem={this.state.selectedUser}
            aziendaId={this.props.match.params.consulenteId}
            onSaveClick={this.HandleSaveClick}
            history={this.props.history}
            onAnnullaClick={this.onCloseGridClick}
            addAlert={this.props.addAlert}
            ChangeButtons={this.ChangeButtons}
            userTypes={this.state.userTypes}
          />
        )}
      </>
    );
  }
}

export default Utenti;
