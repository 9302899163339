import React, { Component } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import helpers from "helpers/Helpers";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.js";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { DitteXConsulenteHeader } from "components/Views/Ditte/DitteXConsulenteHeader.js";
import queryString from "query-string";
import DitteAPI from "API/DitteAPI";
import {rowsPerPageOptions} from "../../helpers/AppConstants";

export class StoricoXConsulenteDitta extends Component {
  state = {
    rows: null,
    isVisible: false,
    selectedRow: null,
    selectedGrid: null,
    filterGrid: false,
    rowsPerPage: 10,
    loadingGrid: false
  };

  constructor(props) {
    super(props);

    this.handleTableClick = this.handleTableClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });
    var consulenteId = this.props.match.params.consulenteId;
    var qS = queryString.parse(this.props.history.location.search);
    let rw;
    if (qS.typeId > 0)
      rw = await DitteAPI.GetAllStoricoDitteByConsulentiId(qS.typeId);
    else rw = await DitteAPI.GetAllStoricoDitteByConsulentiId(consulenteId);

    this.setState({
      rows: rw
    });
    this.setState({
      loadingGrid: false
    });
  }

  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  async handleTableClick(e) {
    this.setState({
      selectedGrid: e.value
    });
  }

  backButtonClick = () => {

    let qS = queryString.parse(this.props.history.location.search);
    let maintainSearch = "&maintainSearch=1";
    this.props.history.push('/admin/DitteXConsulente/' + this.props.match.params.aziendaId + '/' + this.props.match.params.consulenteId + '?typeId=' + qS.typeId + maintainSearch);
  }

  render() {
    return (
      <>
        <DitteXConsulenteHeader dataItem={this.state.rows} {...this.props} />

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Storico
                </h4>

                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Alla pagina precedente"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.backButtonClick}
                  >
                    <ArrowBackIcon />
                  </Button>
                </Tooltip>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage} rowsPerPageOptions={rowsPerPageOptions}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  loading={this.state.loadingGrid}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="data_inizio"
                    body={(rowData) =>
                      helpers.DateTemplateWithHours(rowData, "data_inizio")
                    }
                    header="Inizio"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data_fine"
                    body={(rowData) =>
                      helpers.DateTemplateWithHours(rowData, "data_fine")
                    }
                    header="Fine"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data_ult_agg"
                    body={(rowData) =>
                      helpers.DateTemplateWithHours(rowData, "data_ult_agg")
                    }
                    header="Ultimo Aggiornamento"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="utente"
                    header="Utente"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default StoricoXConsulenteDitta;
