export let JwtToken = "0NWFhLTkzM2EtMWQyMzc0MjAyMmM3IiwibmJmIjoxNjE1ODgwOTc4LCJleHAiOjE2MTU5NjczNzgsImlhdCI6MTYxNTg4MDk3OH0.pSaO5B-hSVRla_ULn1vj71D_8EEu4wAWWIAGiv1WlOU";
export let UserEmail = null;
export let UserId = 1;
export let UserName = null;
export const rowsPerPageOptions = [5, 10, 20, 50, 100];
export const rowsPerPageOptionsMensile = [12, 24, 36, 48];

export const SiteUrl = process.env.REACT_APP_API_ENDPOINT;

export const dateTimeFmt = "DD/MM/YYYY HH:mm:ss";
export const dateFmt = "DD/MM/YYYY";
export const currencyFormatter = new Intl.NumberFormat("it-IT", {
  style: "currency",
  currency: "EUR"
});