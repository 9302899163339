import Utils from "./Utils";

const tipoDenunciaAPI = {

    getAll: async function () {
        const response = await Utils.CreatePostRequest("coasco/unitipoden/getall");
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async update(tipoDenuncia) {
        const response = await Utils.CreatePutRequest("coasco/unitipoden/update", tipoDenuncia);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async insert(tipoDenuncia) {
        const response = await Utils.CreatePostRequest("coasco/unitipoden/insert", tipoDenuncia);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    async delete(tipoDenuncia) {
        const response = await Utils.CreateDeleteRequest("coasco/unitipoden/delete?id=" + tipoDenuncia.codice);
        if (response?.message?.includes("200")) {
            return response.result;
        } else return null;
    },

    getNew() {
        return {
            codice: "",
            descrizione: ""
        };
    }

};

export default tipoDenunciaAPI;