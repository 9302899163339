import Login from "views/Login/Login.page";
import Aziende from "views/Aziende/Aziende.js";
import Dipendenti from "views/Dipendenti/Dipendenti.js";
import ConsulentiXAzienda from "views/Consulenti/ConsulentiXAzienda.js";
import DipendentiXAzienda from "views/Dipendenti/DipendentiXAzienda.js";
import Report from "views/Versamenti/Report.js";
import Protocollo from "views/Borse/Protocollo.js";
import ProtocolloCoasco from "views/Versamenti/ProtocolloCoasco.js";
import StoricoXAzienda from "views/Storico/StoricoXAzienda.js";
import AziendeXStatistiche from "views/Aziende/AziendeXStatistiche.js";
import ExportAnagrafiche from "views/Export/ExportAnagrafiche.js";
import AmministratoriXStatistiche from "views/Amministratori/AmministratoriXStatistiche.js";
import DipendentiXStatistiche from "views/Dipendenti/DipendentiXStatistiche.js";
import ConsulentiXStatistiche from "views/Consulenti/ConsulentiXStatistiche.js";
import AnomalieRegolarizzateXAzienda from "views/Anomalie/AnomalieRegolarizzateXAzienda.js";
import VersamentiUniemens from "views/Versamenti/VersamentiUniemens.js";
import ElencoLavoratiXStatistiche from "views/Lavoro/ElencoLavoratiXStatistiche.js";
import AnomalieXAzienda from "views/Anomalie/AnomalieXAzienda.js";
import AnomalieRegolarizzateXStatistiche from "views/Anomalie/AnomalieRegolarizzateXStatistiche.js";
import AnomalieRilevateXStatistiche from "views/Anomalie/AnomalieRilevateXStatistiche.js";
import VersamentiXAzienda from "views/Versamenti/VersamentiXAzienda.js";
import CorrispondenzaXVersamenti from "views/Corrispondenza/CorrispondenzaXVersamenti.js";
import CaricamentoUniemens from "views/Caricamento/CaricamentoUniemens.js";
import CaricamentoF24 from "views/Caricamento/CaricamentoF24.js";
import CaricamentoInps from "views/Caricamento/CaricamentoInps.js";
import LavoroXAzienda from "views/Lavoro/LavoroXAzienda.js";
import UniemensXAzienda from "views/Uniemens/UniemensXAzienda.js";
import AmministratoriXAzienda from "views/Amministratori/AmministratoriXAzienda.js";
import StoricoXDipendente from "views/Storico/StoricoXDipendente.js";
import StoricoRDL from "views/Storico/StoricoRDL.js";
import VersamentiTrasferiti from "views/Versamenti/VersamentiTrasferiti.js";
import BorseDiStudio from "views/Borse/BorseDiStudio.js";
import Statistiche from "views/Statistiche/Statistiche.js";
import ReportBorse from "views/ReportBorse/ReportBorse.js";
import StoricoXConsulente from "views/Storico/StoricoXConsulente.js";
import StoricoXAmministratori from "views/Storico/StoricoXAmministratori.js";
import DitteXConsulente from "views/Ditte/DitteXConsulente.js";
import DitteXAmministratore from "views/Ditte/DitteXAmministratore.js";
import StoricoXConsulenteDitta from "views/Storico/StoricoXConsulenteDitta.js";
import StoricoXAmministratoreDitta from "views/Storico/StoricoXAmministratoreDitta.js";
import Consulenti from "views/Consulenti/Consulenti.js";
import TipoEtichette from "views/Borse/TipoEtichette.js";
import TipoParentela from "views/Borse/TipoParentela.js";
import VerificaCaricamenti from "views/Caricamento/VerificaCaricamenti.js";
import FileElaborati from "views/Caricamento/FileElaborati.js";
import VerificaCaricamentiUniemens from "views/Caricamento/VerificaCaricamentiUniemens.js";
import Amministratori from "views/Amministratori/Amministratori.js";
import ImpostazioneFiltroXSpedizioni from "views/Impostazione/ImpostazioneFiltroXSpedizioni.js";
import ImpostazioneFiltroXAnomalie from "views/Impostazione/ImpostazioneFiltroXAnomalie.js";
import ReportPostaAnomalie from "views/Anomalie/ReportPostaAnomalie.js";
import ReportComunicazioniAnagrafica from "views/Anagrafica/ReportComunicazioniAnagrafica.js";
import ReportComunicazioniBorse from "views/Borse/ReportComunicazioniBorse.js";
import AnomalieComunicateXStatistiche from "views/Anomalie/AnomalieComunicateXStatistiche.js";
import AnomalieLavorate from "views/Anomalie/AnomalieLavorate.js";
import CSVPoste from "views/Borse/CSVPoste.js";
import StoricoBatchXAnomalie from "views/Storico/StoricoBatchXAnomalie.js";
import RicercaSuCassa from "views/Versamenti/RicercaSuCassa.js";
import ChiusuraTabulati from "views/Versamenti/ChiusuraTabulati.js";
import AllineaCassa from "views/Versamenti/AllineaCassa.js";
import Richiedenti from "views/Richiedenti/Richiedenti.js";
import Richieste from "views/Richieste/Richieste.js";
import ElenchiTabulati from "views/Versamenti/ElenchiTabulati.js";
import Versamenti from "views/Versamenti/Versamenti.js";
import CorrispXRichiedenti from "views/Corrispondenza/CorrispXRichiedenti.js";
import CongruitaVersamenti from "views/Versamenti/CongruitaVersamenti.js";
import InserisciModificaRichiedenti from "views/Richiedenti/InserisciModificaRichiedenti.js";
import VersamentiDetails from "views/Versamenti/VersamentiDetails.js";
import Corrispondenza from "views/Corrispondenza/Corrispondenza.js";
import CorrispondenzaAmministratore from "views/Corrispondenza/CorrispondenzaAmministratore";
import CorrispondenzaConsulente from "views/Corrispondenza/CorrispondenzaConsulente";
import CorrispondenzaDipendente from "views/Corrispondenza/CorrispondenzaDipendente";
import CorrispXRichieste from "views/Corrispondenza/CorrispXRichieste.js";
import ModificaRichiesteXRichiedenti from "views/Richieste/ModificaRichiesteXRichiedenti.js";
import Punteggi from "views/Punteggi/Punteggi.js";
import Scarichi from "views/Versamenti/Scarichi.js";
import VerificaContributiva from "views/Contributiva/VerificaContributiva.js";
import AnniAccademici from "views/Borse/AnniAccademici.js";
import Utenti from "views/Utenti/Utenti.page.js";
import ModificaDipendente from "views/Borse/ModificaDipendente.js";
import TipoBorse from "views/Borse/TipoBorse.js";
import Tabulati from "views/Versamenti/Tabulati.js";
import DaTabulati from "views/Versamenti/DaTabulati.js";
import DocumentiDaAllegareBorse from "views/Borse/DocumentiDaAllegareBorse.js";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import GridOn from "@material-ui/icons/GridOn";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import LibraryAddCheckIcon from "@material-ui/icons/LibraryAddCheck";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import EuroIcon from "@material-ui/icons/Euro";
import AllineaSpese from "views/Versamenti/AllineaSpese.js";
import BackupIcon from "@material-ui/icons/Backup";
import { Profile } from "./views/Profile/Profile.page";
import GestioneEmail from "./views/GestioneEmail/GestioneEmail.page";
import IndirizziSedi from "views/GestioneTabelle/IndirizziSedi";
import AgenziePostali from "views/GestioneTabelle/AgenziePostali";
import Aliquota from "views/GestioneTabelle/Aliquota";
import AliquotaSpese from "views/GestioneTabelle/AliquotaSpese";
import Qualifica1 from "views/GestioneTabelle/Qualifica1";
import Qualifica2 from "views/GestioneTabelle/Qualifica2";
import Qualifica3 from "views/GestioneTabelle/Qualifica3";
import TipoContratto from "views/GestioneTabelle/TipoContratto";
import TipoDenuncia from "views/GestioneTabelle/TipoDenuncia";
import TipoLavoratore from "views/GestioneTabelle/TipoLavoratore";
import CausaliCorrispondenza from "views/GestioneTabelle/CausaliCorrispondenza";
import CausaliContributi from "views/GestioneTabelle/CausaliContributi";
import SediInps from "views/GestioneTabelle/SediInps";
import VerificaCaricamentiDM10 from "views/Caricamento/VerificaCaricamentiDM10";

let dashRoutes = [
  {
    path: "/utenti",
    name: "Gestione Utenti",
    // pathToControl: "/ModificaDipendente/",
    icon: DashboardIcon,
    component: Utenti,
    layout: "/admin",
    visible: false
  },
  {
    path: "/gestioneEmail",
    name: "Gestione Email",
    icon: DashboardIcon,
    component: GestioneEmail,
    layout: "/admin",
    visible: false
  },
  {
    path: "/profilo",
    name: "Profilo",
    // pathToControl: "/ModificaDipendente/",
    icon: DashboardIcon,
    component: Profile,
    layout: "/admin",
    visible: false
  },
  {
    path: "/ModificaDipendente/:dipendenteId/",
    name: "Modifica Dipendente",
    pathToControl: "/ModificaDipendente/",
    icon: DashboardIcon,
    component: ModificaDipendente,
    layout: "/admin",
    visible: false
  },
  {
    path: "/DipendentiXAzienda/:aziendaId/",
    name: "Ricerca Dipendenti",
    pathToControl: "/DipendentiXAzienda/",
    icon: DashboardIcon,
    component: DipendentiXAzienda,
    layout: "/admin",
    visible: false
  },
  {
    path: "/tabulati/:tabulatiId/",
    name: "Tabulati",
    pathToControl: "/tabulati/",
    icon: DashboardIcon,
    component: Tabulati,
    layout: "/admin",
    visible: false
  },
  {
    path: "/databulati/:tabulatiId/",
    name: "Tabulati",
    pathToControl: "/databulati/",
    icon: DashboardIcon,
    component: DaTabulati,
    layout: "/admin",
    visible: false
  },
  {
    path: "/CongruitaVersamenti/:aziendaId/",
    name: "Congruità Versamenti",
    pathToControl: "/CongruitaVersamenti/",
    icon: DashboardIcon,
    component: CongruitaVersamenti,
    layout: "/admin",
    visible: false
  },
  // {
  //   path: "/richiperats/:typeId",
  //   pathToControl: "/richiperats/",
  //   name: "Richieste perv. per Anno, Tipo, Sesso",
  //   mini: "RATS",
  //   component: RichiestePerATS,
  //   layout: "/admin",
  //   visible: false
  // },
  // {
  //   path: "/richiperatp/:typeId",
  //   pathToControl: "/richiperatp/",
  //   name: "Richieste perv. per Anno, Tipo, Provincia",
  //   mini: "RATP",
  //   component: RichiestePerATS,
  //   layout: "/admin",
  //   visible: false
  // },
  // {
  //   path: "/borseperats/:typeId",
  //   pathToControl: "/borseperats/",
  //   name: "Borse Assegnate per Anno, Tipo, Sesso",
  //   mini: "BATS",
  //   component: RichiestePerATS,
  //   layout: "/admin",
  //   visible: false
  // },
  // {
  //   path: "/borseperatp/:typeId",
  //   pathToControl: "/borseperatp/",
  //   name: "Borse Assegnate per Anno, Tipo, Provincia",
  //   mini: "BATP",
  //   component: RichiestePerATS,
  //   layout: "/admin",
  //   visible: false
  // },

  {
    path: "/StoricoXConsulente/:aziendaId/:consulenteId",
    name: "Storico Consulente",
    pathToControl: "/StoricoXConsulente/",
    icon: DashboardIcon,
    component: StoricoXConsulente,
    layout: "/admin",
    visible: false
  },
  {
    path: "/CorrispondenzaXVersamenti/:aziendaId/",
    name: "Corrispondenza",
    pathToControl: "/CorrispondenzaXVersamenti/",
    icon: DashboardIcon,
    component: CorrispondenzaXVersamenti,
    layout: "/admin",
    visible: false
  },
  {
    path: "/DitteXConsulente/:aziendaId/:consulenteId",
    name: "Ditte Consulente",
    pathToControl: "/DitteXConsulente/",
    icon: DashboardIcon,
    component: DitteXConsulente,
    layout: "/admin",
    visible: false
  },
  {
    path: "/DitteXAmministratore/:aziendaId/:amministratoreId",
    name: "Ditte Collegate all'Amministratore",
    pathToControl: "/DitteXAmministratore/",
    icon: DashboardIcon,
    component: DitteXAmministratore,
    layout: "/admin",
    visible: false
  },
  {
    path: "/StoricoXAzienda/:aziendaId",
    name: "Storico Azienda",
    pathToControl: "/StoricoXAzienda/",
    icon: DashboardIcon,
    component: StoricoXAzienda,
    layout: "/admin",
    visible: false
  },
  {
    path: "/StoricoXDipendente/:aziendaId/:dipendenteId",
    name: "Storico Dipendente",
    pathToControl: "/StoricoXDipendente/",
    icon: DashboardIcon,
    component: StoricoXDipendente,
    layout: "/admin",
    visible: false
  },
  {
    path: "/StoricoXAmministratori/:aziendaId/:amministratoreId",
    name: "Storico Amministratori",
    pathToControl: "/StoricoXAmministratori/",
    icon: DashboardIcon,
    component: StoricoXAmministratori,
    layout: "/admin",
    visible: false
  },
  {
    path: "/richiedenti/modificainserisci/:richiedentiId",
    pathToControl: "/richiedenti/modificainserisci/",
    name: "Richiedenti",
    icon: DashboardIcon,
    component: InserisciModificaRichiedenti,
    layout: "/admin",
    visible: false
  },
  {
    path: "/richiedenti/modificarichieste/:richiedentiId/:richiesteId",
    name: "Modifica Richieste",
    icon: DashboardIcon,
    component: ModificaRichiesteXRichiedenti,
    layout: "/admin",
    visible: false
  },
  {
    path: "/richiedenti/punteggi/:richiedentiId/:richiesteId",
    name: "Punteggi",
    icon: DashboardIcon,
    pathToControl: "/richiedenti/punteggi/",
    component: Punteggi,
    layout: "/admin",
    visible: false
  },
  {
    path: "/richiedenti/verificacontributiva/:richiedentiId/:richiesteId",
    name: "Verifica Contributiva",
    icon: DashboardIcon,
    component: VerificaContributiva,
    layout: "/admin",
    visible: false
  },
  {
    path: "/versamenti/details/:versamentiId",
    name: "Versamenti",
    icon: DashboardIcon,
    component: VersamentiDetails,
    pathToControl: "/versamenti/",
    layout: "/admin",
    visible: false
  },
  {
    path: "/VersamentiUniemens/:versamentiId",
    name: "Versamenti Uniemens",
    pathToControl: "/VersamentiUniemens/",
    icon: DashboardIcon,
    component: VersamentiUniemens,
    layout: "/admin",
    visible: false
  },
  {
    path: "/VersamentiTrasferiti/:versamentiId/:year/:codiceQuota",
    name: "Versamenti Trasferiti",
    icon: DashboardIcon,
    component: VersamentiTrasferiti,
    pathToControl: "/VersamentiTrasferiti/",
    layout: "/admin",
    visible: false
  },
  {
    path: "/richiedenti/corrisp/:richiedentiId",
    name: "Corrispondenza relative al Richiedente",
    icon: DashboardIcon,
    component: CorrispXRichiedenti,
    layout: "/admin",
    visible: false
  },
  {
    path: "/richieste/corrispxrichieste/:richiedentiId/:richiesteId",
    name: "Corrispondenza relative al Richiedente",
    icon: DashboardIcon,
    component: CorrispXRichieste,
    layout: "/admin",
    visible: false
  },
  {
    path: "/richiedenti/corrispxrichiedenti/:richiedentiId",
    name: "Corrispondenza relative al Richiedente",
    icon: DashboardIcon,
    component: CorrispXRichiedenti,
    layout: "/admin",
    visible: false
  },
  {
    path: "/Corrispondenza/:aziendaId",
    name: "Corrispondenza",
    pathToControl: "/Corrispondenza/",
    icon: DashboardIcon,
    component: Corrispondenza,
    layout: "/admin",
    visible: false
  },
  {
    path: "/Amministratori/:administratorId/Corrispondenza",
    name: "CorrispondenzaAmministratore",
    pathToControl: "/CorrispondenzaAmministratore/",
    icon: DashboardIcon,
    component: CorrispondenzaAmministratore,
    layout: "/admin",
    visible: false
  },
  {
    path: "/Consulenti/:consultantId/Corrispondenza",
    name: "CorrispondenzaConsulente",
    pathToControl: "/CorrispondenzaConsulente/",
    icon: DashboardIcon,
    component: CorrispondenzaConsulente,
    layout: "/admin",
    visible: false
  },
  {
    path: "/Dipendenti/:employeeId/Corrispondenza",
    name: "CorrispondenzaDipendente",
    pathToControl: "/CorrispondenzaDipendente/",
    icon: DashboardIcon,
    component: CorrispondenzaDipendente,
    layout: "/admin",
    visible: false
  },
  {
    path: "/ditte/storicoconsulente/:aziendaId/:consulenteId",
    name: "Storico Rapporto Azienda",
    icon: DashboardIcon,
    pathToControl: "/ditte/storicoconsulente/",
    component: StoricoXConsulenteDitta,
    layout: "/admin",
    visible: false
  },
  {
    path: "/ditte/storicoamministratore/:aziendaId/:amministratoreId",
    name: "Storico Rapporto Azienda",
    icon: DashboardIcon,
    pathToControl: "/ditte/storicoamministratore/",
    component: StoricoXAmministratoreDitta,
    layout: "/admin",
    visible: false
  },
  {
    path: "/rdl/storico/:aziendaId/:dipendenteId",
    name: "Storico RDL",
    pathToControl: "/rdl/storico/",
    icon: DashboardIcon,
    component: StoricoRDL,
    layout: "/admin",
    visible: false
  },
  {
    path: "/ConsulentiXAzienda/:aziendaId",
    name: "Ricerca Consulenti",
    pathToControl: "/ConsulentiXAzienda/",
    icon: DashboardIcon,
    component: ConsulentiXAzienda,
    layout: "/admin",
    visible: false
  },
  {
    path: "/RDL/:aziendaId/:dipendenteId",
    name: "RDL del Lavoratore",
    icon: DashboardIcon,
    pathToControl: "/RDL/",
    component: LavoroXAzienda,
    layout: "/admin",
    visible: false
  },
  {
    path: "/VersamentiXAzienda/:aziendaId",
    name: "Versamenti",
    icon: DashboardIcon,
    pathToControl: "/VersamentiXAzienda/",
    component: VersamentiXAzienda,
    layout: "/admin",
    visible: false
  },
  {
    path: "/UniemensXAzienda/:aziendaId",
    name: "Uniemens",
    icon: DashboardIcon,
    pathToControl: "/UniemensXAzienda/",
    component: UniemensXAzienda,
    layout: "/admin",
    visible: false
  },
  {
    path: "/AmministratoriXAzienda/:aziendaId",
    name: "Ricerca Amministratori",
    pathToControl: "/AmministratoriXAzienda/",
    icon: DashboardIcon,
    component: AmministratoriXAzienda,
    layout: "/admin",
    visible: false
  },
  {
    path: "/AnomalieXAzienda/:aziendaId",
    name: "Dettaglio Anomalie Attive Azienda",
    icon: DashboardIcon,
    component: AnomalieXAzienda,
    pathToControl: "/AnomalieXAzienda/",
    layout: "/admin",
    visible: false
  },
  {
    path: "/AnomalieRegolarizzate/:aziendaId",
    name: "Dettaglio Anomalie Regolarizzate Azienda",
    icon: DashboardIcon,
    component: AnomalieRegolarizzateXAzienda,
    pathToControl: "/AnomalieRegolarizzate/",
    layout: "/admin",
    visible: false
  },
  // {
  //   path: "/dashboard",
  //   pathToControl: "/dashboard",
  //   name: "Dashboard",
  //   icon: DashboardIcon,
  //   component: Dashboard,
  //   layout: "/admin",
  //   visible: true
  // },

  {
    collapse: true,
    name: "Anagrafiche",
    icon: AccountBoxIcon,
    state: "anagraficheCollapse",
    visible: true,
    views: [
      {
        path: "/aziende/:aziendaId",
        pathToControl: "/aziende/",
        name: "Aziende",
        mini: "Az",
        component: Aziende,
        layout: "/admin",
        visible: true
      },
      {
        path: "/amministratori/:amministratoreId",
        pathToControl: "/amministratori/",
        name: "Amministratori",
        mini: "Am",
        component: Amministratori,
        layout: "/admin",
        visible: true
      },
      {
        path: "/consulenti/:consulenteId",
        pathToControl: "/consulenti/",
        name: "Consulenti",
        mini: "Co",
        component: Consulenti,
        layout: "/admin",
        visible: true
      },
      {
        path: "/dipendenti/:dipendenteId",
        pathToControl: "/dipendenti/",
        name: "Dipendenti",
        mini: "Di",
        component: Dipendenti,
        layout: "/admin",
        visible: true
      },
      {
        path: "/filtrogenerico",
        pathToControl: "/filtrogenerico",
        name: "Filtro e posta generico",
        mini: "IFS",
        component: ImpostazioneFiltroXSpedizioni,
        layout: "/admin",
        visible: true
      },
      {
        path: "/comunicazionianagraficareport",
        pathToControl: "/comunicazionianagraficareport",
        name: "Report Comunicazioni",
        mini: "RCA",
        component: ReportComunicazioniAnagrafica,
        layout: "/admin",
        visible: true
      }

      // {
      //   path: "/register-page",
      //   pathToControl: "/register-page",
      //   name: "Filtro e Posta Generico",
      //   mini: "Fi",
      //   component: Aziende,
      //   layout: "/auth",
      //   visible: true
      // },
      // {
      //   path: "/lock-screen-page",
      //   pathToControl: "/lock-screen-page",
      //   name: "Vis. Protocolli Multi Dest.",
      //   mini: "Vp",
      //   component: Aziende,
      //   layout: "/auth",
      //   visible: true
      // }
    ]
  },

  {
    collapse: true,
    name: "Anomalie",
    icon: AssignmentLateIcon,
    state: "anomalieCollapse",
    visible: true,
    views: [
      {
        path: "/filtroanomalie",
        pathToControl: "/filtroanomalie",
        name: "Filtro e Posta Anomalie",
        mini: "IFA",
        component: ImpostazioneFiltroXAnomalie,
        layout: "/admin",
        visible: true
      },
      {
        path: "/postaanomaliereport",
        pathToControl: "/postaanomaliereport",
        name: "Report Posta Anomalie",
        mini: "RPA",
        component: ReportPostaAnomalie,
        layout: "/admin",
        visible: true
      },
      {
        path: "/anomalie",
        pathToControl: "/anomalie",
        name: "Anomalie Lavorate",
        mini: "AN",
        component: AnomalieLavorate,
        layout: "/admin",
        visible: true
      },

      {
        path: "/storicobatch",
        pathToControl: "/storicobatch",
        name: "Storico Batch",
        mini: "SB",
        component: StoricoBatchXAnomalie,
        layout: "/admin",
        visible: true
      }
    ]
  },

  {
    collapse: true,
    name: "Borse",
    icon: AccountBalanceIcon,
    state: "borseCollapse",
    visible: true,
    views: [
      {
        path: "/richiedenti/:richiedentiId",
        pathToControl: "/richiedenti",
        name: "Richiedenti",
        mini: "RCD",
        component: Richiedenti,
        layout: "/admin",
        visible: true
      },
      {
        path: "/richieste/:richiesteId",
        pathToControl: "/richieste",
        name: "Richieste",
        mini: "RCS",
        component: Richieste,
        layout: "/admin",
        visible: true
      },
      {
        path: "/comunicazionirichborsereport",
        pathToControl: "/comunicazionirichborsereport",
        name: "Report Comunicazioni",
        mini: "RCB",
        component: ReportComunicazioniBorse,
        layout: "/admin",
        visible: true
      },
      {
        collapse: true,
        name: "Funzioni Generali",
        icon: PlaylistAddCheckIcon,
        state: "fugeCollapse",
        visible: true,
        views: [
          {
            path: "/protocolloborse",
            pathToControl: "/protocolloborse",
            name: "Protocollo",
            mini: "P",
            component: Protocollo,
            layout: "/admin",
            visible: true
          },
          {
            path: "/statisticheborse",
            pathToControl: "/statisticheborse",
            name: "Statistiche",
            mini: "S",
            component: Statistiche,
            layout: "/admin",
            visible: true
          },
          {
            path: "/reportborse",
            pathToControl: "/reportborse",
            name: "Report",
            mini: "RE",
            component: ReportBorse,
            layout: "/admin",
            visible: true
          },
          {
            collapse: true,
            name: "Export Borse",
            icon: GridOn,
            state: "exportCollapse",
            visible: true,
            views: [
              {
                path: "/csvposte",
                pathToControl: "/csvposte",
                name: "CSV Poste",
                mini: "CP",
                component: CSVPoste,
                layout: "/admin",
                visible: true
              }
            ]
          },
          {
            path: "/tipoetichette",
            pathToControl: "/tipoetichette",
            name: "Tipologia etichette",
            mini: "TE",
            component: TipoEtichette,
            layout: "/admin",
            visible: true
          }
        ]
      },
      {
        collapse: true,
        name: "Funzionalita Borse",
        icon: LibraryAddCheckIcon,
        state: "fuboCollapse",
        visible: true,
        views: [
          {
            path: "/anniaccademici",
            pathToControl: "/anniaccademici",
            name: "Anni Accademici",
            mini: "AA",
            component: AnniAccademici,
            layout: "/admin",
            visible: true
          },
          {
            path: "/tipoborse",
            pathToControl: "/tipoborse",
            name: "Tipologia Borse",
            mini: "TB",
            component: TipoBorse,
            layout: "/admin",
            visible: true
          },
          {
            path: "/borsedistudio",
            pathToControl: "/borsedistudio",
            name: "Borse di Studio",
            mini: "BS",
            component: BorseDiStudio,
            layout: "/admin",
            visible: true
          },
          {
            path: "/documentiallegare",
            pathToControl: "/documentiallegare",
            name: "Documenti da Allegare",
            mini: "DDA",
            component: DocumentiDaAllegareBorse,
            layout: "/admin",
            visible: true
          },
          {
            path: "/tipoparentela",
            pathToControl: "/tipoparentela",
            name: "Tipologia Parentela",
            mini: "TP",
            component: TipoParentela,
            layout: "/admin",
            visible: true
          }
        ]
      }
    ]
  },
  {
    path: "",
    pathToControl: "",
    name: "Log In Page",
    mini: "LI",
    component: Login,
    layout: "/auth",
    visible: false
  },
  {
    collapse: true,
    name: "COASCO",
    icon: GridOn,
    state: "coascoCollapse",
    visible: true,
    views: [
      {
        collapse: true,
        name: "Gestione Versamenti",
        icon: EuroIcon,
        state: "gdsaCollapse",
        visible: true,
        views: [
          {
            path: "/versamenti/:aziendaId",
            pathToControl: "/versamenti",
            name: "Gestione Matricola",
            mini: "GM",
            component: Versamenti,
            layout: "/admin",
            visible: true
          },
          {
            path: "/scarichi",
            pathToControl: "/scarichi",
            name: "Scarichi F24",
            mini: "Sc",
            component: Scarichi,
            layout: "/admin",
            visible: true
          },

          {
            path: "/ricercasucassa",
            pathToControl: "/ricercasucassa",
            name: " Ricerca su Cassa",
            mini: "RC",
            component: RicercaSuCassa,
            layout: "/admin",
            visible: true
          },

          {
            path: "/allineacassa",
            pathToControl: "/allineacassa",
            name: "Allinea cassa F24",
            mini: "AC",
            component: AllineaCassa,
            layout: "/admin",
            visible: true
          },

          {
            path: "/allineaspese",
            pathToControl: "/allineaspese",
            name: "Allinea spese F24",
            mini: "AS",
            component: AllineaSpese,
            layout: "/admin",
            visible: true
          },
          {
            path: "/elenchitabulati/:tabulatiId",
            pathToControl: "/elenchitabulati",
            name: "Elenchi Tabulati",
            mini: "ET",
            component: ElenchiTabulati,
            layout: "/admin",
            visible: true
          },
          {
            path: "/chiusuratabulati",
            pathToControl: "/chiusuratabulati",
            name: "Chiusura Tabulati",
            mini: "CT",
            component: ChiusuraTabulati,
            layout: "/admin",
            visible: true
          }
        ]
      },
      {
        collapse: true,
        name: "Procedure di caricamento",
        icon: BackupIcon,
        state: "pcaricCollapse",
        visible: true,
        views: [
          {
            path: "/caricamentoF24",
            pathToControl: "/caricamentoF24",
            name: "Caricamento F24",
            mini: "CA",
            component: CaricamentoF24,
            layout: "/admin",
            visible: true
          },
          {
            path: "/caricamentouniemens",
            pathToControl: "/caricamentouniemens",
            name: "Caricamento Uniemens",
            mini: "CU",
            component: CaricamentoUniemens,
            layout: "/admin",
            visible: true
          },
          {
            path: "/caricamentoinps",
            pathToControl: "/caricamentoinps",
            name: "Caricamento DM10",
            mini: "CI",
            component: CaricamentoInps,
            layout: "/admin",
            visible: true
          },
          {
            path: "/fileelaborati",
            pathToControl: "/fileelaborati",
            name: "File Elaborati",
            mini: "VC",
            component: FileElaborati,
            layout: "/admin",
            visible: true
          },
          {
            path: "/verificacaricamenti",
            pathToControl: "/verificacaricamenti",
            name: "Verifica Caricamenti F24",
            mini: "VC",
            component: VerificaCaricamenti,
            layout: "/admin",
            visible: true
          },
          {
            path: "/uniemensverificacaricamenti",
            pathToControl: "/uniemensverificacaricamenti",
            name: "Verifica Caricamenti Uniemens",
            mini: "VCU",
            component: VerificaCaricamentiUniemens,
            layout: "/admin",
            visible: true
          },
          {
            path: "/dm10verificacaricamenti",
            pathToControl: "/dm10verificacaricamenti",
            name: "Verifica Caricamenti DM10",
            mini: "VCDM",
            component: VerificaCaricamentiDM10,
            layout: "/admin",
            visible: true
          }
        ]
      },

      // {
      //   collapse: true,
      //   name: "Gestione tabelle solo visualiz.",
      //   icon: GridOn,
      //   state: "gstabvisCollapse",
      //   visible: true,
      //   views: [],
      // },

      {
        path: "/protocollocoasco",
        pathToControl: "/protocollocoasco",
        name: "Protocollo",
        mini: "PR",
        component: ProtocolloCoasco,
        layout: "/admin",
        visible: true
      },
      {
        path: "/report",
        pathToControl: "/report",
        name: "Report",
        mini: "Re",
        component: Report,
        layout: "/admin",
        visible: true
      },
      {
        collapse: true,
        name: "Gestione Tabelle",
        //icon: BackupIcon,
        mini: "GT",
        state: "gestioneTabelleCollapse",
        visible: true,
        views: [
          {
            collapse: true,
            name: "Gestione",
            //icon: EuroIcon,
            mini: "G",
            state: "gestioneCollapse",
            visible: true,
            views: [
              {
                path: "/sediinps",
                pathToControl: "/sediinps",
                name: "Sedi Inps",
                mini: "SI",
                component: SediInps,
                layout: "/admin",
                visible: true
              },
              {
                path: "/indirizzisedi",
                pathToControl: "/indirizzisedi",
                name: "Indirizzi Sedi",
                mini: "IS",
                component: IndirizziSedi,
                layout: "/admin",
                visible: true
              },
              {
                path: "/agenziepostali",
                pathToControl: "/agenziepostali",
                name: "Agenzie Postali",
                mini: "AP",
                component: AgenziePostali,
                layout: "/admin",
                visible: true
              },
              {
                path: "/causalicorrispondenza",
                pathToControl: "/causalicorrispondenza",
                name: "Causali Corrispondenza",
                mini: "CR",
                component: CausaliCorrispondenza,
                layout: "/admin",
                visible: true
              },
              {
                path: "/causalicontributi",
                pathToControl: "/causaicontributi",
                name: "Causali Contributi",
                mini: "CN",
                component: CausaliContributi,
                layout: "/admin",
                visible: true
              },
              {
                path: "/aliquote",
                pathToControl: "/aliquote",
                name: "Aliquote",
                mini: "A",
                component: Aliquota,
                layout: "/admin",
                visible: true
              },
              {
                path: "/aliquotespese",
                pathToControl: "/aliquotespese",
                name: "Aliquote Spese",
                mini: "AS",
                component: AliquotaSpese,
                layout: "/admin",
                visible: true
              }
            ]
          },
          {
            collapse: true,
            name: "UNIEMENS",
            //icon: EuroIcon,
            mini: "UN",
            state: "uniemensCollapse",
            visible: true,
            views: [
              {
                path: "/qualifica1",
                pathToControl: "/qualifica1",
                name: "Qualifica 1",
                mini: "Q1",
                component: Qualifica1,
                layout: "/admin",
                visible: true
              },
              {
                path: "/qualifica2",
                pathToControl: "/qualifica2",
                name: "Qualifica 2",
                mini: "Q2",
                component: Qualifica2,
                layout: "/admin",
                visible: true
              },
              {
                path: "/qualifica3",
                pathToControl: "/qualifica3",
                name: "Qualifica 3",
                mini: "Q3",
                component: Qualifica3,
                layout: "/admin",
                visible: true
              },
              {
                path: "/tipocontratto",
                pathToControl: "/tipocontratto",
                name: "Tipo Contratto",
                mini: "TC",
                component: TipoContratto,
                layout: "/admin",
                visible: true
              },
              {
                path: "/tipodenuncia",
                pathToControl: "/tipodenuncia",
                name: "Tipo Denuncia",
                mini: "TD",
                component: TipoDenuncia,
                layout: "/admin",
                visible: true
              },
              {
                path: "/tipolavoratore",
                pathToControl: "/tipolavoratore",
                name: "Tipo Lavoratore",
                mini: "TL",
                component: TipoLavoratore,
                layout: "/admin",
                visible: true
              }
            ]
          }
        ]
      }
    ]
  },

  {
    collapse: true,
    name: "Statistiche",
    icon: DashboardIcon,
    state: "statCollapse",
    visible: true,
    views: [
      {
        path: "/statisticheaziende",
        pathToControl: "/statisticheaziende",
        name: "Aziende",
        mini: "SAZ",
        component: AziendeXStatistiche,
        layout: "/admin",
        visible: true
      },
      {
        path: "/statisticheamministratori",
        pathToControl: "/statisticheamministratori",
        name: "Amministratori",
        mini: "SAM",
        component: AmministratoriXStatistiche,
        layout: "/admin",
        visible: true
      },
      {
        path: "/statisticheconsulenti",
        pathToControl: "/statisticheconsulenti",
        name: "Consulenti",
        mini: "SCO",
        component: ConsulentiXStatistiche,
        layout: "/admin",
        visible: true
      },
      {
        path: "/statistichedipendenti",
        pathToControl: "/statistichedipendenti",
        name: "Dipendenti",
        mini: "SDI",
        component: DipendentiXStatistiche,
        layout: "/admin",
        visible: true
      },
      {
        path: "/statisticheanomalierilevate",
        pathToControl: "/statisticheanomalierilevate",
        name: "Anomalie Rilevate",
        mini: "SAR",
        component: AnomalieRilevateXStatistiche,
        layout: "/admin",
        visible: true
      },
      {
        path: "/statisticheanomalieregolarizzate",
        pathToControl: "/statisticheanomalieregolarizzate",
        name: "Anomalie Regolarizzate",
        mini: "SARE",
        component: AnomalieRegolarizzateXStatistiche,
        layout: "/admin",
        visible: true
      },
      {
        path: "/statisticheanomaliecomunicate",
        pathToControl: "/statisticheanomaliecomunicate",
        name: "Anomalie Comunicate",
        mini: "SAC",
        component: AnomalieComunicateXStatistiche,
        layout: "/admin",
        visible: true
      },
      {
        path: "/statisticheelenco",
        pathToControl: "/statisticheelenco",
        name: "Elenco Movimentazioni",
        mini: "SE",
        component: ElencoLavoratiXStatistiche,
        layout: "/admin",
        visible: true
      }
    ]
  },

  {
    path: "/exportanagrafiche",
    pathToControl: "/exportanagrafiche",
    name: "Export Anagrafiche",
    icon: ImportExportIcon,
    component: ExportAnagrafiche,
    layout: "/admin",
    visible: true
  }
];
export default dashRoutes;
