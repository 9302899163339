import Utils from "./Utils";
import * as AppConstants from "../helpers/AppConstants";

const baseURL = AppConstants.SiteUrl + "ebinprof/Borse";
const baseURLDocs = AppConstants.SiteUrl + "/ebinprof/DocBS";
const baseURLDocs2 = AppConstants.SiteUrl + "ebinprof/DocBS";

const BorseAPI = {
  deleteScholarship: async function (id) {
    const url = `ebinprof/Borse/delete?id=${id}`;

    const res = await Utils.CreateDeleteRequest(url);
    return res?.result;
  },

  GetBorse: function (borseId) {
    let rows = [
      {
        id: 1,
        name: "Laureati",
        anno: "2018/2019",
        annoId: 2,
        borseId: 1,
        cod: "Laur",
        contributo: "3000",
        num: "33",
        visib: "Laureati",
        visibId: 1,
        docId: 1
      },
      {
        id: 2,
        name: "Laureati in Diritto del Lavoro o Scienze Sociali",
        borseId: 2,
        annoId: 2,
        anno: "2018/2019",
        cod: "L4000",
        contributo: "4000",
        num: "3",
        visib: "Laureati",
        visibId: 1,
        docId: 1
      },
      {
        id: 3,
        name: "Studenti Scuola Media Superiore",
        anno: "2018/2019",
        borseId: 3,
        annoId: 2,
        cod: "StMS",
        contributo: "2000",
        num: "35",
        visib: "Studenti Scuola Media",
        visibId: 2,
        docId: 2
      },
      {
        id: 4,
        name: "Studenti Scuola Media Superiore Disabili",
        borseId: 4,
        anno: "2018/2019",
        annoId: 2,
        cod: "StMSD",
        contributo: "1500",
        num: "43",
        visib: "Studenti Scuola Media",
        visibId: 2,
        docId: 3
      },
      {
        id: 5,
        name: "Studenti Universitari",
        anno: "2017/2018",
        borseId: 5,
        annoId: 3,
        cod: "StUni",
        contributo: "5000",
        num: "3",
        visib: "Studenti Universitari",
        visibId: 3,
        docId: 4
      },
      {
        id: 6,
        name: "Studenti Universitari Disabili",
        anno: "2017/2018",
        borseId: 6,
        annoId: 3,
        cod: "SUDis",
        contributo: "2000",
        num: "33",
        visib: "Studenti Universitari",
        visibId: 3,
        docId: 5
      }
    ];
    if (borseId !== null) {
      rows = rows.filter((row) => row.id === borseId)[0];
      return rows;
    } else return rows;
  },

  AllBorse: async function () {
    const response = await Utils.SendPostRequestTo(`${baseURL}/getall`);
    return response.result;
  },

  VisibilitaForBorse: function () {
    let rows = [
      { id: 1, name: "Laureati" },
      { id: 2, name: "Studenti Scuola Media" },
      { id: 3, name: "Studenti Universitari" },
      { id: 4, name: "Studenti Scuola Media Disabili" },
      { id: 5, name: "Studenti Universitari Disabili" },
      {
        id: 6,
        name: "Laureati con tesi in diritto del lavoro, scienze sociali o scienze economiche"
      }
    ];
    return rows;
  },

  NuovoBorse: function () {
    let rows = [
      {
        id: 0,
        name: "",
        anno: "",
        cod: "",
        contributo: "",
        num: "",
        visib: "",
        annoId: 0,
        borseId: 0
      }
    ];
    return rows;
  },

  AddBorsa: async function (borsaobj) {
    const response = await Utils.SendPostRequestTo(
      `${baseURL}/insert`,
      borsaobj
    );
    return response;
  },

  UpdateBorsa: async function (borsaobj) {
    const response = await Utils.SendPutRequestTo(
      `${baseURL}/update`,
      borsaobj
    );
    return response;
  },

  getDocsById: async function (id) {
    const response = await Utils.SendPostRequestTo(
      `${baseURLDocs}/getById?codice=${id}`
    );
    return response.result;
  },

  getDocsByIdBs: async function (id) {
    const response = await Utils.SendPostRequestTo(
      `${baseURLDocs2}/getByIdBS?idBS=${id}`
    );
    return response.result;
  },

  deleteDocByIdBs: async function (id) {
    return await Utils.SendDeleteRequestTo(`${baseURLDocs2}/delete?id=${id}`);
  },

  addDoc: async function (
    documentIds,
    scholarshipId,
    importFromLastYear = false
  ) {
    const res = await Utils.SendPostRequestTo(
      `${baseURLDocs2}/insert?idBorsa=${scholarshipId}&importaDaAnnoPrec=${importFromLastYear}`,
      documentIds
    );
    return res;
  },

  updateDoc: async function (data) {
    const response = await Utils.SendPutRequestTo(
      `${baseURLDocs2}/update`,
      data
    );
    return response.result;
  },
  getComunicazioniBorseReport: async function () {
    const url = `coasco/reportcomunicazionirichborse/retrieveall`;
    const res = await Utils.CreateGetRequest(url);
    return res;
  },

  downloadReportComunicazioniBorse: async function (id) {
    await Utils.httpGetDownloadZip(
      `coasco/reportcomunicazionirichborse/download/${id}`,
      "Lettere Comunicazioni Richieste Borse"
    );
  }
};
export default BorseAPI;
