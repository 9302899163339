import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import moment from "moment";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import CardFooter from "../../components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import UndoIcon from "@material-ui/icons/Undo";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import { FileUpload } from "primereact/fileupload";
import StaticLists from "helpers/StaticLists";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import { dateTimeFmt } from "helpers/AppConstants";
import { CorrispondenzaText } from "../../components/Views/Corrispondenza/CorrispondenzaText.js";
import { FormControlLabel, FormGroup, FormLabel } from "@material-ui/core";
import { Checkbox } from "primereact/checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import CustomSelect from "../../components/CustomInput/CustomSelect";

export class PreparaMailGenerico extends Component {

    state = {
        dataProt: moment().format(dateTimeFmt),
        anno: new Date().getFullYear(),
        tipoProt: {},
        oggetto: null,
        selectedCaus: null,
        files: [],
        senderEmail: "",
        emailText: "",
        showEmailText: true,
        selectedAmm: null,
        causali: null,
        prots: null,
        altroDest: "",
        accounts: null
    };

    constructor(props) {
        super(props);
        this.onSaveClick = this.onSaveClick.bind(this);
        this.handleSenderEmail = this.handleSenderEmail.bind(this);
        this.HandleChange = this.HandleChange.bind(this);
        this.onUpload = this.onUpload.bind(this);
        this.handleTextEmail = this.handleTextEmail.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.isValidForm = this.isValidForm.bind(this);
        this.handleChangeCausale = this.handleChangeCausale.bind(this);
        this.newProto = React.createRef();
    }

    handleChangeCausale(e) {
        this.setState({
            selectedCaus: e.target.value
        }, () => {
            const causObj = this.state.causali.find(item => item.codice === e.target.value);
            this.HandleChange("oggetto", causObj.oggetto);
            this.handleTextEmail(`Buongiorno,\n${causObj.testomail ?? ""}\n--\nFondo Coasco`);
            this.setState({emailText: `Buongiorno,\n${causObj.testomail ?? ""}\n--\nFondo Coasco`});
        });
    }

    onUpload(event) {
        this.setState({ files: event.files });
        this.props.addAlert.current.addAlert(
            "success",
            "File pronti al caricamento"
        );
    }

    isValidForm() {
        if (this.state.anno <= 0)
            return false;
        if (!this.state.dataProt)
            return false;
        if (this.state.tipoProt?.id !== 2)
            return false;
        if (!this.state.oggetto) {
            this.props.addAlert.current.addAlert("danger", "Inserire l'oggetto");
            return false;
        }
        if (!this.state.emailText) {
            this.props.addAlert.current.addAlert("danger", "Inserire il testo");
            return false;
        }
        if (!this.state.senderEmail) {
            this.props.addAlert.current.addAlert("danger", "Selezionare un mittente");
            return false;
        }

        return true;
    };

    HandleChange(field, newValue) {
        this.setState({[field]: newValue});
    }

    async onSaveClick() {
        if (this.isValidForm()) {
            return await this.props.onSaveClick(
                this.state.anno,
                this.state.dataProt,
                this.state.oggetto,
                this.state.emailText,
                this.state.senderEmail,
                this.state.files,
                this.state.selectedCaus,
                this.state.altroDest
            );
        }
    };

    removeFile(event) {
        if (this.state.files) {
            const files = this.state.files.filter((file) => file !== event.file);
            this.setState({ files: files });
        }
    }

    async componentDidMount() {
        let causali, accounts;
        if (this.props.ufficio === "EBINPROF") {
            causali = await CorrispondenzaAPI.GetCausaliEbinForCorrispondenza();
            accounts = StaticLists.Corrisp_Account_ebin();
        } else {
            causali = await CorrispondenzaAPI.GetCausaliForCorrispondenza();
            accounts = StaticLists.Corrisp_Account();
        }

        this.setState({
            tipoProt: { id: 2, name: "Uscita" },
            causali: causali, accounts
        },
            () => this.newProto?.current?.scrollIntoView()
        );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.prots !== this.props.prots) {
            this.setState({prots: this.props.prots})
        }
    }

    handleSenderEmail(account) {
        this.HandleChange("mittmail", account);
        this.setState({
            senderEmail: account
        });
    }

    handleTextEmail(text) {
        this.HandleChange("testomail", text);
        this.setState({
            emailText: text
        });
    }

    render() {
        return (
            <div ref={this.newProto}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="success">
                                <h4
                                    style={{
                                        lineHeight: "0",
                                        display: "inline",
                                        verticalAlign: "bottom"
                                    }}
                                >
                                    Prepara Mail
                                </h4>
                                <Tooltip
                                    arrow
                                    id="tooltip-top"
                                    title="Annulla"
                                    placement="top"
                                    classes={{ tooltip: "tooltip-me" }}
                                >
                                    <Button
                                        style={{
                                            verticalAlign: "center",
                                            lineHeight: "1",
                                            margin: "0.1%"
                                        }}
                                        color="white"
                                        aria-label="edit"
                                        justIcon
                                        round
                                        className="float-right"
                                        onClick={this.props.onAnnullaClick}
                                    >
                                        <UndoIcon/>
                                    </Button>
                                </Tooltip>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={2}>
                                        <CustomInput
                                            labelText="Anno"
                                            id="anno"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: this.state.anno
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2}>
                                        <CustomInput
                                            labelText='Data Protocollo'
                                            id="dataProt"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: this.state.dataProt
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2}>
                                        <CustomInput
                                            labelText="Protocollo"
                                            id="prot"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: this.state.prots ?? ""
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={2}>
                                        <CustomInput
                                            labelText="Tipo Protocollo"
                                            id="tipoProt"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                disabled: true,
                                                value: this.state.tipoProt.id + ' - ' + this.state.tipoProt.name
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={4}>
                                        <FormLabel style={{position: "relative", top: "12px", left: "-10px"}}>Destinatario</FormLabel>
                                        <FormGroup row>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox color="primary" checked={true} disabled={true}/>
                                                }
                                                label={this.props.name}
                                                className="labelCheckbox"
                                            />
                                        </FormGroup>
                                    </GridItem>
                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4} className="select">
                                        <CustomSelect
                                            labelText="Causale"
                                            id="caus"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: this.handleChangeCausale,
                                                value: this.state.selectedCaus ?? ""
                                            }}
                                        >
                                            {this.state.causali?.map(item => (
                                                <MenuItem value={item.codice} key={item.codice}>
                                                    {item.codice + " - " + item.descrizione}
                                                </MenuItem>
                                            ))}
                                        </CustomSelect>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Oggetto"
                                            id="ogg"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange: e => this.setState({oggetto: e.target.value}),
                                                value: this.state.oggetto ?? ""
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={this.state.showEmailText ? 6 : 12} className="upload mt-4">
                                        <Card>
                                            <CardHeader color="info">
                                                <h4
                                                    style={{
                                                        lineHeight: "1",
                                                        display: "inline",
                                                        verticalAlign: "bottom"
                                                    }}
                                                >
                                                    Selezione Allegato
                                                </h4>
                                            </CardHeader>
                                            <CardBody>
                                                <FileUpload
                                                    url="" name="attachs" customUpload
                                                    uploadLabel={"Aggiungi all'email"}
                                                    uploadHandler={this.onUpload}
                                                    onRemove={this.removeFile}
                                                    accept=".txt,.pdf,.csv,.xlsx,.docx,.doc"
                                                    maxFileSize={100_000_000}
                                                    onClear={() => this.setState({ files: [] })}
                                                    emptyTemplate={<p className="p-m-0">Seleziona file</p>}
                                                />
                                            </CardBody>
                                        </Card>
                                    </GridItem>
                                    {this.state.showEmailText && (
                                        <GridItem xs={12} sm={12} md={6} className="mt-4">
                                            <CorrispondenzaText
                                                showAltroDest={false}
                                                accounts={this.state.accounts ?? []}
                                                setAccount={this.handleSenderEmail}
                                                selectedAccount={this.state.senderEmail}
                                                text={this.state.emailText}
                                                setEmailText={this.handleTextEmail}
                                                altroDest={this.state.altroDest ?? ""}
                                                setAltroDest={val => this.setState({altroDest: val})}
                                            />
                                        </GridItem>
                                    )}
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <ButtonSaveWithLoading
                                    onClick={this.onSaveClick}
                                    text={"SALVA"}
                                    isValidForm={this.isValidForm}
                                />
                            </CardFooter>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default PreparaMailGenerico;