import React, { Component } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import UndoIcon from "@material-ui/icons/Undo";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { DataTable } from "primereact/datatable";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import { Column } from "primereact/column";
import Tooltip from "@material-ui/core/Tooltip";
import CustomButtons from "components/CustomButtons/Button.js";
import { confirmPopup } from "primereact/confirmpopup";
import Close from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import BorseAPI from "API/BorseAPI";
import DocsAPI from "API/DocsAPI";
import { Dropdown } from "primereact/dropdown";
import CustomButton from "components/CustomButtons/Button.js";
import TipoBorseAPI from "API/TipoBorsaAPI";
import CustomSelect from "components/CustomInput/CustomSelect";
import { Checkbox, MenuItem } from "@material-ui/core";
import AnniAccademiciAPI from "API/AnniAccademiciAPI";
import { MultiSelect } from "primereact/multiselect";
import ButtonsMUI from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import AuthApi from "../../API/auth.api";
import UtentiAPI from "../../API/Utenti.api";
import authApi from "../../API/auth.api";

class DocumentiDaAllegareBorse extends Component {
  state = {
    ModificaTitle: "Crea",
    selectedGrid: null,
    selectedDocGrid: null,
    loadingGrid: false,
    loadingDocGrid: false,
    filterGrid: null,
    filterDocGrid: null,
    rows: [],
    isVisible: false,
    docRows: [],
    documento: null,
    selectedDocument: null,

    fieldValidations: {},
    validationRules: {
      name: ""
    }
  };
  constructor(props) {
    super(props);

    this.btnSearchRef = React.createRef();
    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.handleTableDocClick = this.handleTableDocClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.ChangeFilterDocGrid = this.ChangeFilterDocGrid.bind(this);
    this.SetText = this.SetText.bind(this);
    this.onAnnullaClick = this.onAnnullaClick.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.DeletePulitoriGridTemplate =
      this.DeletePulitoriGridTemplate.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.createNewDocument = this.createNewDocument.bind(this);
    this.AddNewDocument = this.AddNewDocument.bind(this);
    this.OnCancel = this.OnCancel.bind(this);
    this.DeleteDocument = this.DeleteDocument.bind(this);
    this.UpdateDocument = this.UpdateDocument.bind(this);
    this.showSnackbar = this.showSnackbar.bind(this);
  }

  async componentDidMount() {
    await this.refreshGrid();

    const allDocs = await DocsAPI.getAll();
    this.setState((prevState) => {
      return { ...prevState, allDocuments: allDocs.result };
    });

    const allScolarhips = await TipoBorseAPI.GetAllTipoBorse();
    this.setState((prevState) => {
      return { ...prevState, allScolarships: allScolarhips.result };
    });

    const academicYears = await AnniAccademiciAPI.AnnoAccademiciForBorse();
    this.setState((prevState) => {
      return { ...prevState, academicYears };
    });
  }

  ChangeFilterDocGrid(e) {
    this.setState({ filterDocGrid: e.target.checked });
  }

  SetText(field, newValue) {
    this.setState((prevState) => {
      return { ...prevState, [field]: newValue };
    });
  }

  createNewDocument() {
    this.setState((prevState) => {
      return {
        ...prevState,
        newDocumentVisible: true
      };
    });
  }

  DeletePulitoriGridTemplate(rowData) {
    return (
      <CustomButtons
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData.Id}
        onClick={this.onDeleteClick}
      >
        <Close className="icon" />
      </CustomButtons>
    );
  }

  onDeleteClick(event, id) {
    const scholarshipId = id.idTipoDoc;
    event.stopPropagation();

    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: async () => {
        const res = await BorseAPI.deleteDocByIdBs(scholarshipId);
        if (res?.message?.includes("200")) {
          this.props.addAlert.current.addAlert(
            "success",
            "Documento Eliminato Con Successo."
          );
          await this.refreshGrid();
        } else {
          this.props.addAlert.current.addAlert("danger", res?.message);
        }
      },
      reject: () => {}
    });
  }

  async onNuovoClick() {
    this.setState({
      documento: await DocsAPI.NuovoDocumentoForBorse(),
      ModificaTitle: "Inserisci",
      isVisible: true,
      editDocument: false,
      newDocumentName: false,
      newDocumentVisible: false
    });
  }

  async refreshGrid() {
    if (this.state?.selectedGrid?.idBorsa) {
      this.setState({ loadingDocGrid: true });
      const docsRes = await BorseAPI.getDocsByIdBs(
        this.state?.selectedGrid?.idBorsa
      );
      this.setState({ docRows: docsRes });
      this.setState({ loadingDocGrid: false });
    } else {
      this.setState({loadingGrid: true, loadingDocGrid: true});
      this.setState({rows: await BorseAPI.AllBorse()},
        function () {
          this.setState({
            loadingGrid: false,
            loadingDocGrid: false
          });
        }
      );
    }
  }

  async isValidForm() {
    return true;
  }

  showSnackbar(type, message) {
    this.props.addAlert.current.addAlert(type, message);
  }

  HandleSaveClick = async () => {
    const documents = this.state?.selectedDocument;
    const documentIds = [];
    documents &&
      documents.length > 0 &&
      documents.map((document) => {
        return documentIds.push(document?.iddoc);
      });
    const scholarshipId = this.state.selectedGrid?.idBorsa;
    const importFromLastYear = this.state.importFromLastYear;

    const res = await BorseAPI.addDoc(
      documentIds,
      scholarshipId,
      importFromLastYear
    );
    if (res && res?.result) {
      this.showSnackbar("success", "Operazione avvenuta con successo");
      this.setState({
        isVisible: false,
        selectedDocument: null,
        importFromLastYear: false
      });
      this.refreshGrid();
    } else this.showSnackbar("danger", "Operazione non riuscita");
    return true;
  };

  ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  onAnnullaClick() {
    this.setState({
      isVisible: false,
      newDocumentVisible: false,
      selectedDocGrid: null,
      editDocumentVisible: false
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  async handleTableDocClick(e) {
    const document = JSON.parse(JSON.stringify(e.value));
    this.setState({
      selectedDocGrid: e.value,
      isVisible: false,
      documento: document,
      ModificaTitle: "Modifica",
      newDocumentVisible: false,
      editDocumentVisible: true,
      selectedYear: document.annoBS,
      newDocumentName: document.descr,
      selectedScolarship: document.idBS.tipoBorsa.descrBr
    });
  }

  async handleTableClick(e) {
    const scholarshipTypeId = e.value.tipoBorsa.idTipoBS;
    const academicYear = e.value.annoAcc.idAnno;
    this.setState({ loadingDocGrid: true });
    const documents = await DocsAPI.getDocumentsByAcademicYearScholarshipTypeId(
      scholarshipTypeId,
      academicYear
    );
    const docs = await BorseAPI.getDocsByIdBs(e.value.idBorsa);
    this.setState({
      scholarshipTypeId: e.value.tipoBorsa.idTipoBS,
      filteredDocuments: documents.result,
      selectedGrid: e.value,
      selectedDocGrid: null,
      isVisible: false,
      docRows: docs,
      rowClicked: true,
      editDocumentVisible: false
    });
    this.setState({ loadingDocGrid: false });
  }

  OnCancel() {
    this.setState((prevState) => {
      return {
        ...prevState,
        createDate: null,
        newDocumentName: null,
        selectedScolarship: null
      };
    });
  }

  async isValidForm2() {
    return true;
  }

  HandleDropDownChange(e, val) {
    this.setState({
      [e]: val
    });
  }

  async AddNewDocument() {
    const newDocument = {
      annoBS: this.state.annoAcc,
      descr: this.state.newDocumentName,
      tipoBS: this.state.selectedScolarship.idTipoBS
    };
    DocsAPI.addDocument(newDocument);
    this.refreshGrid();
    this.OnCancel();
    setTimeout(() => {
      this.setState((prevState) => {
        return { ...prevState, newDocumentVisible: false };
      });
      this.props.addAlert.current.addAlert("success", "Dati salvati.");
    }, 2200);
  }

  async UpdateDocument() {
    const username = (await authApi.getProfile()).result.pop().username;
    const document = {
      ...this.state.selectedDocGrid,
      annoBS: this.state.selectedYear,
      descr: this.state.newDocumentName,
      tipoBS: this.state.allScolarships.find(bs => bs.descrBr === this.state.selectedScolarship)?.idTipoBS,
      utente: (await UtentiAPI.getUserByUsername(username))?.pop()
    };
    const res = await BorseAPI.updateDoc(document);
    if (res) {
      await this.refreshGrid();
      this.OnCancel();
      this.setState((prevState) => {
        return { ...prevState, editDocumentVisible: false };
      });
      this.props.addAlert.current.addAlert(
        "success",
        "Documento Aggiornato con Successo."
      );
    }
  }

  DeleteDocument(rowData) {
    return (
      <CustomButton
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData.idRichied}
        onClick={(e) => this.onDeleteClick(e, rowData)}
      >
        <Close className="icon" />
      </CustomButton>
    );
  }

  render() {
    return (
      <>
        <div ref={this.btnSearchRef} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borsesuccess">
                <h4 className="d-inline">Collegamento Documentazione a Categorie per Annualità</h4>
                <ButtonGroup
                    variant="contained"
                    size="small" color="default"
                    aria-label="contained primary button group"
                    className="float-right m-2"
                >
                  <ButtonsMUI onClick={this.createNewDocument}>
                    Crea nuovo documento
                  </ButtonsMUI>
                </ButtonGroup>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <Card>
                      <CardHeader color="borsesuccess">
                        <h4
                            style={{
                              lineHeight: "0",
                              display: "inline",
                              verticalAlign: "bottom"
                            }}
                        >
                          Borse di Studio Presenti
                        </h4>

                        <FormControlLabel
                            className="float-right"
                            style={{
                              verticalAlign: "center",
                              lineHeight: "1",
                              margin: "0.1%",
                              marginRight: "6px",
                              color: "white"
                            }}
                            control={
                              <Switch
                                  checked={this.state.filterGrid}
                                  onChange={this.ChangeFilterGrid}
                                  name="chkFilterGrid"
                                  color="default"
                              />
                            }
                            label={"Attiva Filtri"}
                        />
                      </CardHeader>
                      <CardBody>
                        <DataTable
                            emptyMessage=""
                            className="p-datatable-striped p-datatable-sm"
                            value={this.state.rows}
                            dataKey="idBorsa"
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                            rows={5}
                            loading={this.state.loadingGrid}
                            rowsPerPageOptions={[5, 10, 20, 50, 100]}
                            paginatorLeft={this.paginatorLeft}
                            paginatorRight={this.paginatorRight}
                            scrollable
                            style={{ width: "100%" }}
                            onSelectionChange={(e) => {
                              this.setState({ selectedRow: e.value });
                              this.handleTableClick(e);
                            }}
                            selectionMode="single"
                            selection={this.state.selectedRow}
                        >
                          <Column
                              field="annoAcc.descr"
                              header="Annualità"
                              headerStyle={{ width: "130px" }}
                              sortable={true}
                              filter={this.state.filterGrid}
                              filterMatchMode="contains"
                              filterPlaceholder=""
                          />
                          <Column
                              field="tipoBorsa.descr"
                              header="Categoria"
                              headerStyle={{ width: "300px" }}
                              sortable={true}
                              filter={this.state.filterGrid}
                              filterMatchMode="contains"
                              filterPlaceholder=""
                          />
                          <Column
                              field="tipoBorsa.descrBr"
                              header="Sigla B.S."
                              headerStyle={{ width: "120px" }}
                              sortable={true}
                              filter={this.state.filterGrid}
                              filterMatchMode="contains"
                              filterPlaceholder=""
                          />
                          <Column
                              field="nborse"
                              header="Numero B.S."
                              headerStyle={{ width: "140px" }}
                              sortable={true}
                              filter={this.state.filterGrid}
                              filterMatchMode="contains"
                              filterPlaceholder=""
                          />
                          <Column
                              field="contributo"
                              header="Importo"
                              headerStyle={{ width: "120px" }}
                              sortable={true}
                              filter={this.state.filterGrid}
                              filterMatchMode="contains"
                              filterPlaceholder=""
                          />
                        </DataTable>
                      </CardBody>
                    </Card>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Card>
                      <CardHeader color="borsesuccess">
                        <h4
                            style={{
                              lineHeight: "0",
                              display: "inline",
                              verticalAlign: "bottom"
                            }}
                        >
                          Documenti Collegati
                        </h4>

                        <FormControlLabel
                            className="float-right"
                            style={{
                              verticalAlign: "center",
                              lineHeight: "1",
                              margin: "0.1%",
                              marginRight: "6px",
                              color: "white"
                            }}
                            control={
                              <Switch
                                  checked={this.state.filterDocGrid}
                                  onChange={this.ChangeFilterDocGrid}
                                  name="chkFilterGrid"
                                  color="default"
                              />
                            }
                            label={"Attiva Filtri"}
                        />
                        {this.state.rowClicked && (
                            <Tooltip
                                arrow
                                id="tooltip-top"
                                title="Inserisci"
                                placement="top"
                                classes={{ tooltip: "tooltip-me" }}
                            >
                              <CustomButtons
                                  style={{
                                    verticalAlign: "center",
                                    lineHeight: "1",
                                    margin: "0.1%",
                                    marginLeft: "4px"
                                  }}
                                  color="white"
                                  aria-label="edit"
                                  justIcon
                                  round
                                  className="float-right"
                                  onClick={this.onNuovoClick}
                              >
                                <AddIcon />
                              </CustomButtons>
                            </Tooltip>
                        )}
                      </CardHeader>
                      <CardBody>
                        <DataTable
                            emptyMessage=""
                            className="p-datatable-striped p-datatable-sm"
                            value={this.state.docRows}
                            dataKey="idTipoDoc"
                            paginator
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                            currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                            rows={5}
                            loading={this.state.loadingDocGrid}
                            rowsPerPageOptions={[5, 10, 20]}
                            paginatorLeft={this.paginatorLeft}
                            paginatorRight={this.paginatorRight}
                            selection={this.state.selectedDocGrid}
                            scrollable
                            style={{ width: "100%" }}
                            onSelectionChange={this.handleTableDocClick}
                            selectionMode="single"
                            sortField="descr"
                            sortOrder={1}
                        >
                          <Column
                              header=""
                              headerStyle={{ width: "40px" }}
                              body={this.DeleteDocument}
                              icon="pi pi-times"
                              label="Delete"
                              className="p-button-danger p-button-outlined colDelete"
                          />
                          <Column
                              field="descr"
                              header="Descrizione"
                              sortable={true}
                              filter={this.state.filterDocGrid}
                              filterMatchMode="contains"
                              filterPlaceholder=""
                          />
                        </DataTable>
                      </CardBody>
                    </Card>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>

          {this.state.isVisible && (
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="borseinfo">
                    <h4
                        style={{
                          lineHeight: "0",
                          display: "inline",
                          verticalAlign: "bottom"
                        }}
                    >
                      Inserisci Documenti Collegati alla Borsa Studio
                    </h4>

                    <Tooltip
                        arrow
                        id="tooltip-top"
                        title="Annulla"
                        placement="top"
                        classes={{ tooltip: "tooltip-me" }}
                    >
                      <CustomButtons
                          style={{
                            verticalAlign: "center",
                            lineHeight: "1",
                            margin: "0.1%",
                            marginLeft: "0",
                            marginRight: "0"
                          }}
                          color="white"
                          aria-label="edit"
                          justIcon
                          round
                          className="float-right"
                          onClick={this.onAnnullaClick}
                      >
                        <UndoIcon />
                      </CustomButtons>
                    </Tooltip>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <FormControlLabel
                            style={{
                              margin: "0px",
                              marginRight: "8px"
                            }}
                            control={
                              <Checkbox
                                  color="primary"
                                  checked={this.state.importFromLastYear}
                                  onChange={(e) =>
                                      this.setState({
                                        importFromLastYear: e.target.checked
                                      })
                                  }
                              />
                            }
                            label="Importa dall'anno precedente"
                            className="labelCheckbox"
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={8}>
                        <MultiSelect
                            filter
                            display="chip"
                            optionLabel="descr"
                            value={this.state.selectedDocument}
                            options={this.state.filteredDocuments}
                            placeholder="Cerca per documento"
                            onChange={(e) => {
                              this.setState((prevState) => {
                                return {
                                  ...prevState,
                                  selectedDocument: e.target.value
                                };
                              });
                            }}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <ButtonSaveWithLoading
                        onClick={this.HandleSaveClick}
                        text={"SALVA"}
                        isValidForm={this.isValidForm}
                    />
                  </CardFooter>
                </Card>
              </GridItem>
          )}

          {this.state.newDocumentVisible && (
            <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="borseinfo">
                    <h4
                        style={{
                          lineHeight: "0",
                          display: "inline",
                          verticalAlign: "bottom"
                        }}
                    >
                      {`${this.state.ModificaTitle} Documento`}
                    </h4>

                    <Tooltip
                        arrow
                        id="tooltip-top"
                        title="Annulla"
                        placement="top"
                        classes={{ tooltip: "tooltip-me" }}
                    >
                      <CustomButtons
                          style={{
                            verticalAlign: "center",
                            lineHeight: "1",
                            margin: "0.1%",
                            marginLeft: "0",
                            marginRight: "0"
                          }}
                          color="white"
                          aria-label="edit"
                          justIcon
                          round
                          className="float-right"
                          onClick={this.onAnnullaClick}
                      >
                        <UndoIcon />
                      </CustomButtons>
                    </Tooltip>
                  </CardHeader>
                  <CardBody>
                    <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                    >
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                            labelText="Titolo Documento *"
                            id="doc"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (e) =>
                                  this.SetText("newDocumentName", e.target.value),
                              value: this.state.newDocumentName || ""
                            }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        Tipo Borsa *
                        <Dropdown
                            style={{ marginTop: "0" }}
                            className="dropdownCustomisedForMUI"
                            value={this.state.selectedScolarship}
                            options={this.state.allScolarships}
                            optionLabel="descr"
                            onChange={(e) =>
                                this.setState((prevState) => {
                                  return {
                                    ...prevState,
                                    selectedScolarship: e.target.value
                                  };
                                })
                            }
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} className="select">
                        <CustomSelect
                            success={
                                this.state.fieldValidations.annoAcc &&
                                this.state.fieldValidations.annoAcc === "success"
                            }
                            error={
                                this.state.fieldValidations.annoAcc &&
                                this.state.fieldValidations.annoAcc === "error"
                            }
                            labelText="Anno Accademico *"
                            id="anno"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: (e) => {
                                this.HandleDropDownChange(
                                    "annoAcc",
                                    e.target.value
                                );
                                this.setState({
                                  selectedYear: this.state.academicYears.find(
                                      (year) => year.idAnno === e.target.value
                                  )
                                });
                              },
                              value: this.state.selectedYear?.idAnno || ""
                            }}
                        >
                          {this.state.academicYears?.map((year) => (
                              <MenuItem value={year.idAnno} key={year.idAnno}>
                                {year.descr}
                              </MenuItem>
                          ))}
                        </CustomSelect>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <ButtonSaveWithLoading
                        disabled={
                            !this.state.selectedYear ||
                            !this.state.newDocumentName ||
                            !this.state.selectedScolarship
                        }
                        onClick={this.AddNewDocument}
                        text={"SALVA"}
                        isValidForm={this.isValidForm2}
                    />
                  </CardFooter>
                </Card>
              </GridItem>
          )}

          {this.state.editDocumentVisible && (
              <GridItem xs={12} sm={12} md={12}>
                <Card>
                  <CardHeader color="borseinfo">
                    <h4
                        style={{
                          lineHeight: "0",
                          display: "inline",
                          verticalAlign: "bottom"
                        }}
                    >
                      Modifica Documento
                    </h4>

                    <Tooltip
                        arrow
                        id="tooltip-top"
                        title="Annulla"
                        placement="top"
                        classes={{ tooltip: "tooltip-me" }}
                    >
                      <CustomButtons
                          style={{
                            verticalAlign: "center",
                            lineHeight: "1",
                            margin: "0.1%",
                            marginLeft: "0",
                            marginRight: "0"
                          }}
                          color="white"
                          aria-label="edit"
                          justIcon
                          round
                          className="float-right"
                          onClick={this.onAnnullaClick}
                      >
                        <UndoIcon />
                      </CustomButtons>
                    </Tooltip>
                  </CardHeader>
                  <CardBody>
                    <GridContainer
                        style={{ display: "flex", alignItems: "center" }}
                    >
                      <GridItem xs={12} sm={12} md={4}>
                        <CustomInput
                            labelText="Titolo Documento *"
                            id="doc"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              disabled: false,
                              onChange: (e) =>
                                  this.SetText("newDocumentName", e.target.value),
                              value: this.state.newDocumentName
                            }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        Tipo Borsa *
                        <Dropdown
                            value={this.state.selectedScolarship}
                            options={this.state.allScolarships}
                            optionValue="descrBr"
                            optionLabel="descrBr"
                            className="dropdownCustomisedForMUI"
                            onChange={(e) => {
                              this.setState((prevState) => {
                                return {
                                  ...prevState,
                                  selectedScolarship: e.target.value
                                };
                              });
                            }}
                            style={{ marginTop: "0" }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4} className="select">
                        <CustomSelect
                            success={
                                this.state.fieldValidations.annoAcc &&
                                this.state.fieldValidations.annoAcc === "success"
                            }
                            error={
                                this.state.fieldValidations.annoAcc &&
                                this.state.fieldValidations.annoAcc === "error"
                            }
                            labelText="Anno Accademico *"
                            id="anno"
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: (e) => {
                                this.HandleDropDownChange(
                                    "selectedYear",
                                    e.target.value
                                );
                                this.setState({
                                  selectedYear: e.target.value
                                });
                              },
                              value: this.state.selectedYear || ""
                            }}
                        >
                          {this.state.academicYears?.map((year) => (
                              <MenuItem value={year.idAnno} key={year.idAnno}>
                                {year.descr}
                              </MenuItem>
                          ))}
                        </CustomSelect>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                    <ButtonSaveWithLoading
                        onClick={this.UpdateDocument}
                        text={"SALVA"}
                        isValidForm={this.isValidForm2}
                    />
                  </CardFooter>
                </Card>
              </GridItem>
          )}
        </GridContainer>
      </>
    );
  }
}
export default DocumentiDaAllegareBorse;
