import Utils from "./Utils";
import * as AppConstants from "../helpers/AppConstants";

const baseURL = AppConstants.SiteUrl + "ebinprof/TipoParent";

const ParentelaAPI = {
  deleteRelationshipType: async function (id) {
    const url = `ebinprof/TipoParent/delete?id=${id}`;

    const res = await Utils.CreateDeleteRequest(url);
    return res?.result;
  },

  GetAllTipoParentela: async function () {
    const response = await Utils.SendPostRequestTo(`${baseURL}/getall`);
    return response.result;
  },

  GetTipoParentela: async function (parentelaId) {
    return await Utils.SendPostRequestTo(
      `${baseURL}/getById?codice=${parentelaId}`
    );
  },

  NuovoTipoParentela: function (parentelaId) {
    let rows = [{ id: 1, name: "" }];
    return rows;
  },

  add: async function (data) {
    const _data = {
      id: "",
      descr: data.name
    };
    const response = await Utils.SendPostRequestTo(`${baseURL}/insert`, _data);
    return response.result;
  },

  update: async function (data) {
    const _data = {
      id: data.id,
      descr: data.name
    };
    const response = await Utils.SendPutRequestTo(`${baseURL}/update`, _data);
    return response.result;
  }
};
export default ParentelaAPI;
