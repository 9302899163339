import React, {
  Fragment as Container,
  useContext,
  useRef,
  useState,
  useEffect
} from "react";
import UtentiAPI from "API/Utenti.api";
import classes from "./Profile.module.css";
import { UserIcon } from "components/UI/icons";
import { ProfileForm as Form } from "components/Views/Profile";
import { AuthContext } from "providers";
import { Typography } from "@material-ui/core";

export const Profile = ({ ...props }) => {
  const formRef = useRef(null);
  const [account, setAccount] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const { profile } = useContext(AuthContext);

  async function getUserHandler() {
    const res = await UtentiAPI.getUserByUsername(profile?.username);
    setAccount(res);
  }

  useEffect(() => {
    profile && getUserHandler();
  }, [profile]);

  useEffect(() => {
    account &&
      setFormValues({
        username: account[0].username,
        user: account[0].utente,
        email: account[0].email,
        office: profile.ufficio
      });
  }, [account]);

  const usernameComponent = <Typography size="1.8em">My Profile</Typography>;

  const accountImgComponent = (
    <div className={classes.account_img_container}>
      <UserIcon className={classes.icon} />
    </div>
  );

  const accountString = profile?.username ? `@${profile?.username}` : "";
  const accountNameComponent = (
    <div className={classes.accountName}>
      <Typography>{accountString}</Typography>
    </div>
  );

  async function onUpdate(values) {
    const data = {
      codice: account[0].codice,
      codice_profilo: account[0].codice_profilo,
      disabilitato: account[0].disabilitato,
      email_utente: values?.email,
      password: values?.newPassword || null,
      ufficio: account[0].ufficio,
      username: account[0].username,
      utente: values?.user
    };
    const res = await UtentiAPI.updateUser(data);
    !res
      ? props.addAlert.current.addAlert(
          "danger",
          "Errore nell'aggiornamento del profilo"
        )
      : props.addAlert.current.addAlert(
          "success",
          "Profilo aggiornato correttamente"
        );
  }

  const formComponent = (
    <Form
      componentRef={formRef}
      initialValues={formValues}
      onSubmit={onUpdate}
    />
  );

  return (
    <Container>
      {usernameComponent}
      <div className={classes.container}>
        {accountImgComponent}
        {accountNameComponent}
        {formValues && formComponent}
      </div>
    </Container>
  );
};
