import React, { Component } from "react";
import clone from "clone";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Dropdown } from "primereact/dropdown";
import LoaderSpinner from "../../Layout/LoaderSpinner.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import UndoIcon from "@material-ui/icons/Undo";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import MenuItem from "@material-ui/core/MenuItem";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Close from "@material-ui/icons/Close";
import { confirmPopup } from "primereact/confirmpopup";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import helpers from "helpers/Helpers";
import AziendeAPI from "API/AziendeAPI";
import StaticLists from "helpers/StaticLists";
import CorrispondenzaAPI from "API/CorrispondenzaAPI";
import TipoInvioAPI from "API/TipoInvioAPI.js";
import SedeComuneProvAPI from "API/SedeComuneProvAPI.js";
import { AuthContext } from "providers/auth.provider.js";
import UtentiAPI from "API/Utenti.api.js";
import { Calendar } from "primereact/calendar";
import { dateTimeFmt } from "helpers/AppConstants.js";
import moment from "moment";
import { TestoEmail } from "./TestoEmail.component";
import { TestoLettera } from "../Protocollo/TestoLettera.component.js";
import { InputTextarea } from "primereact/inputtextarea";

export class ModificaCorrispondenzaXRichieste extends Component {
  static contextType = AuthContext;
  state = {
    RowItem: null,
    ModificaTitle: "Nuovo",
    groupClass: "",
    deletecolumns: [],
    downloadcolumns: [],
    tipoinvioList: 0,
    Protocollo_List: [],
    Causale_List: [],
    W350_List: [],
    W300_List: [],
    Amm_List: [],
    Buchi_List: [],
    Cust_List: [],
    Puli_List: [],
    SAP_List: [],
    selectedSap: null,
    selectedTipo: 0,
    selectedW350: null,
    selectedW300: null,
    selectedAmm: null,
    selectedBuchi: null,
    selectedCust: null,
    selectedPuli: null,
    selectedProt: null,
    isVisible: false,
    loadingGrid: false,
    files: null,
    isValid: true,
    torequest: true,
    toINPS: false,
    altroDest: null,

    protocolTypes: [
      { id: 1, name: "Entrata" },
      { id: 2, name: "Uscita" }
    ],

    sendTypes: [
      { id: 1, name: "Lettera" },
      { id: 2, name: "E-mail" }
    ],

    emailText: "",

    //VALIDATIONS
    fieldValidations: {},
    validationRules: {
      tipo: "",
      oggetto: ""
    },
    destMail: null,
  };

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.DeleteAttachment = this.DeleteAttachment.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.handleDeleteAttachment = this.handleDeleteAttachment.bind(this);
    this.DownloadAttachmment = this.DownloadAttachmment.bind(this);
    this.onUpload = this.onUpload.bind(this);
    this.onDownloadClick = this.onDownloadClick.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.monthNavigatorTemplate = this.monthNavigatorTemplate.bind(this);
    this.yearNavigatorTemplate = this.yearNavigatorTemplate.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.handleDownloadAttachment = this.handleDownloadAttachment.bind(this);
    this.getUser = this.getUser.bind(this);
    this.deleteHandler = this.deleteHandler.bind(this);
    this.refreshAttachmentTable = this.refreshAttachmentTable.bind(this);
    this.SetText = this.SetText.bind(this);
    this.newProto = React.createRef();
  }

  HandleDropDownChange(propLabel, value) {
    const rowItem = this.state.RowItem;
    rowItem[propLabel] = value;
    this.setState({
      RowItem: rowItem
    });
  }

  SetText(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;

    this.setState({
      RowItem: newRowItem,
      emailText: newValue
    });
  }

  async refreshAttachmentTable() {
    this.setState({ loadingGrid: true });
    const res = await CorrispondenzaAPI.GetCorrispondenzaEbinPathAttach(
      this.props.dataItem.id
    );
    this.setState({
      attachments: res.result
    });
    this.setState({ loadingGrid: false });
  }

  async handleTableClick(rowData) {
    const _rowData = rowData?.value;
    const paths = _rowData?.path?.split(".");
    const extension = paths[paths.length - 1];
    if (extension === "pdf") {
      const _data = _rowData.path?.split(".");
      this.state.office === "EBINPROF"
        ? await CorrispondenzaAPI.openPdfEbin(
            _rowData?.id,
            _data[_data.length - 1]
          )
        : await CorrispondenzaAPI.openPdf(
            _rowData?.id,
            _data[_data.length - 1]
          );
    }
  }

  monthNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }

  yearNavigatorTemplate(e) {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }

  async onUpload(event) {
    this.setState({ files: event.files });
    this.props.addAlert.current.addAlert(
      "success",
      "File pronti al caricamento"
    );
  }

  async handleDeleteAttachment(id) {
    const response =
      this.state.office === "EBINPROF"
        ? await CorrispondenzaAPI.DeleteCorrispondenzaEbinAttachment(id)
        : await CorrispondenzaAPI.DeleteCorrispondenzaAttachment(id);
    if (response && response.result) {
      this.props.addAlert.current.addAlert("success", "Allegato eliminato");
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "Eliminazione non riuscita"
      );
    }
    await this.refreshAttachmentTable();
  }

  deleteHandler(event, rowData) {
    event.preventDefault();
    event.stopPropagation();
    this.onDeleteClick(event, rowData?.id);
  }

  DeleteAttachment(rowData) {
    return (
      <Button
        round
        color="danger"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData?.id}
        onClick={(event) => this.deleteHandler(event, rowData)}
      >
        <Close className="icon" />
      </Button>
    );
  }

  DownloadAttachmment(rowData) {
    const paths = rowData?.path?.split(".");
    const extension = paths[paths.length - 1];
    return (
      <Button
        round
        color="primary"
        className="actionButton actionButtonRound btnRound float-right"
        key={rowData?.id}
        onClick={(event) => {
          this.onDownloadClick(event, rowData?.id, extension);
        }}
      >
        <GetAppRoundedIcon className="icon" />
      </Button>
    );
  }

  onDeleteClick(event, id) {
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi eliminare questo record?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        this.handleDeleteAttachment(id);
      },
      reject: () => {}
    });
  }

  async handleDownloadAttachment(id, extension) {
    this.state.office === "EBINPROF"
      ? await CorrispondenzaAPI.downloadCorrespondenceAttachmentEbin(id, extension)
      : await CorrispondenzaAPI.DownloadCorrispondenzaAttachment(id, extension);
    await this.refreshAttachmentTable();
  }

  onDownloadClick(event, id, extension) {
    event.preventDefault();
    event.stopPropagation();
    confirmPopup({
      target: event.currentTarget,
      message: "Vuoi scaricarlo?",
      header: "Confirmation",
      icon: "pi pi-info-circle",
      accept: () => {
        this.handleDownloadAttachment(id, extension);
      },
      reject: () => {}
    });
  }

  isValidForm = async () => {
    let RowItem = this.state.RowItem;
    let validationRules = clone(this.state.validationRules);
    let fieldValidations = helpers.ValidateForm(RowItem, validationRules);

    this.setState({
      fieldValidations: fieldValidations
    });
    return Object.values(fieldValidations).every((x) => x === "success");
  };

  HandleChange(field, newValue) {
    let newRowItem = this.state.RowItem;
    newRowItem[field] = newValue;
    this.setState({ RowItem: newRowItem });
  }

  HandleSaveClick = async () => {
    const rowItem = {
      ...this.state.RowItem,
      emailAccount: this.state.selectedEmail,
      otherRecipient: this.state.altroDest,
      mittmail: this.state.selectedEmail
    };
    const isFormValid = await this.isValidForm();
    if (isFormValid) {
      let corr = rowItem;
      corr.testomail = this.state.emailText;
      if (this.state.files && this.state.files.length > 0) {
        let files = [...this.state.files];
        return await this.props.onSaveClick(
          corr,
          this.state.user,
          files
        );
      } else {
        return await this.props.onSaveClick(
          corr,
          this.state.user,
          []
        );
      }
    }
  };

  async getUser(username) {
    const res = await UtentiAPI.getUserByUsername(username);
    this.setState({ user: res, office: res?.pop()?.descrizione_ufficio });
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });
    const { profile } = this.context;

    await this.getUser(profile?.username);

    if (this.props.dataItem?.id) {
      const res = await CorrispondenzaAPI.GetCorrispondenzaEbinPathAttach(
        this.props.dataItem.id
      );
      this.setState({
        attachments: res.result
      });
    }
    
    const allProvince = await SedeComuneProvAPI.GetAllProvincieProvincie();
    const allSedi = await SedeComuneProvAPI.GetAllSede();
    this.setState({
      deletecolumns: [
        {
          header: "",
          body: this.DeleteAttachment,
          icon: "pi pi-times",
          label: "Delete",
          className: "p-button-danger p-button-outlined colDelete"
        }
      ],
      downloadcolumns: [
        {
          header: "",
          body: this.DownloadAttachmment,
          icon: "pi pi-times",
          label: "Download",
          className: "p-button-danger p-button-outlined colDownload"
        }
      ],
      tipoinvioList: await TipoInvioAPI.GetAll(),
      RowItem: {
        ...this.props.dataItem,
        tipo: typeof this.props.dataItem.tipo === 'number' ? this.props.dataItem.tipo : 2,
        causal: this.props.dataItem?.causale || "01"
      },
      provices: allProvince,
      Protocollo_List: StaticLists.Corrisp_TipoProtocollo(),
      Causale_List: await CorrispondenzaAPI.GetCausaliEbinForCorrispondenza(),
      W350_List: StaticLists.Corrisp_W350(),
      W300_List: StaticLists.Corrisp_W300(),
      Amm_List: StaticLists.Corrisp_Amm(),
      SAP_List: allSedi,
      isVisible: this.props.dataItem.Id <= 0,
      Buchi_List: StaticLists.Corrisp_Buchi(),
      Cust_List: StaticLists.Corrisp_Cust(),
      Puli_List: StaticLists.Corrisp_Puli(),
      ModificaTitle: this.props.dataItem.id > 0 ? "Modifica" : "Nuovo",
      groupClass: this.props.dataItem.id > 0 ? "" : "hide"
    });

    if(!this.state.RowItem?.id || this.state.RowItem?.id <= 0) {
      this.setState({
        RowItem: {
          ...this.state.RowItem,
          tipoinvio:2
        }
      });
    }

    if(this.props.dataItem.id > 0){
      this.setState({
        destMail: this.props.dataItem?.destmail?.split(",")
      });
    }

    this.setState({
      selectedW350: this.props.dataItem.w350,
      selectedW300: this.props.dataItem.w300,
      selectedAmm: this.props.dataItem.amm,
      selectedBuchi: helpers.ReturnIdFromList(
        this.state.Buchi_List,
        this.props.dataItem.prbuchicont
      ),
      selectedSap: helpers.ReturnIdFromList(
        this.state.SAP_List,
        this.props.dataItem.sap
      ),
      selectedCust: helpers.ReturnIdFromList(
        this.state.Cust_List,
        this.props.dataItem.cust
      ),
      selectedPuli: helpers.ReturnIdFromList(
        this.state.Puli_List,
        this.props.dataItem.puli
      ),
      selectedProt: helpers.ReturnIdFromList(
        this.state.Protocollo_List,
        this.props.dataItem.tipo
      )
    });
   
    const disableAll = this.props.dataItem?.tipo === 2 && (this.state.RowItem?.tipoinvio === 2); //Uscita
    this.setState({disableAll})
    this.setState({
      emailText: this.state.RowItem?.testoMail
    });

    this.setState({
      loadingGrid: false
    });

    try {
      this.newProto.current.scrollIntoView();
    } catch(err){}
  }

  SetNum(field, newValue, maxLength) {
    if (newValue.toString().length <= maxLength) {
      let newRowItem = this.state.RowItem;
      newRowItem[field] = newValue;

      this.setState({ RowItem: newRowItem });
    }
  }

  async OnInputBlurCheck(valToCheck, type) {
    let validationRules;

    type === "matricola"
      ? (validationRules = {
          matricola: "EQ-10"
        })
      : (validationRules = {
          iban: "EQ-27"
        });
    let fieldValidations = helpers.ValidateForm(
      this.state.RowItem,
      validationRules
    );

    Object.values(fieldValidations).every((x) => x === "success");

    this.setState({
      fieldValidations: fieldValidations
    });

    let existentMatricola = [];
    if (
      this.state.RowItem.matricola?.length > 0 &&
      this.props.dataItem?.id === null &&
      type === "matricola"
    ) {
      existentMatricola = await AziendeAPI.GetAllMatricola(
        this.state.RowItem.matricola
      );
      if (existentMatricola.length > 0) {
        this.props.addAlert.current.addAlert("warning", "Matricola esistente");
      }
    }
  }

  handleFile(e) {
    this.setState({ files: e.target.files[0] });
  }

  handleUpload = async () => {
    await CorrispondenzaAPI.AddCorrispondenza(
      this.state.RowItem,
      this.state.files
    );
  };

  render() {
    const dynamicDeleteColumns = this.state.deletecolumns.map((col) => {
      return (
        <Column
          key={col.body}
          body={col.body}
          icon={col.icon}
          label={col.label}
          className={col.className}
          header={col.header}
        />
      );
    });

    const dynamicDownloadColumns = this.state.downloadcolumns.map((col) => {
      return (
        <Column
          key={col.body}
          body={col.body}
          icon={col.icon}
          label={col.label}
          className={col.className}
          header={col.header}
        />
      );
    });

    const destinatariEmail = 
    <div>
      <br/>
      <label>Destinatari Email</label>
      <GridContainer>
      {this.state.destMail?.map(el => 
          <GridItem xs={12} sm={12} md={4} style={{ marginTop: "-1vh" }}>
            <CustomInput id={el} formControlProps={{fullWidth: true}} inputProps={{disabled: true, value:el}}/>
          </GridItem>
      )}
      </GridContainer>
    </div>;

    if (this.state.RowItem === null)
      return (
        <>
          {" "}
          <LoaderSpinner fullHeight={false} />{" "}
        </>
      );
    else
      return (
        <div ref={this.newProto}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="borseinfo">
                  <h4
                    style={{
                      lineHeight: "0",
                      display: "inline",
                      verticalAlign: "bottom"
                    }}
                  >
                    {this.state.ModificaTitle} Protocollo
                  </h4>
                  <Tooltip
                    arrow
                    id="tooltip-top"
                    title="Annulla"
                    placement="top"
                    classes={{ tooltip: "tooltip-me" }}
                  >
                    <Button
                      style={{
                        verticalAlign: "center",
                        lineHeight: "1",
                        margin: "0.1%"
                      }}
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      className="float-right"
                      onClick={this.props.onAnnullaClick}
                    >
                      <UndoIcon />
                    </Button>
                  </Tooltip>
                  {this.props.children}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem md={4}>
                      <CustomInput
                        labelText="Id"
                        id="id"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.id || ""
                        }}
                      />
                    </GridItem>
                    <GridItem md={4}>
                      <CustomInput
                        labelText="Anno"
                        id="anno"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value:
                            this.state.RowItem.anno || new Date().getFullYear()
                        }}
                      />
                    </GridItem>
                    <GridItem md={4}>
                      <CustomInput
                        labelText="Protocollo"
                        id="prot"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.RowItem.prot || ""
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={4} className="select">
                      <CustomSelect
                        labelText="Tipo Protocollo"
                        success={
                          this.state.fieldValidations.tipo &&
                          this.state.fieldValidations.tipo === "success"
                        }
                        error={
                          this.state.fieldValidations.tipo &&
                          this.state.fieldValidations.tipo === "error"
                        }
                        id="prot"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.props.dataItem?.id,
                          onChange: (e) =>
                            this.HandleDropDownChange("tipo", e.target.value),
                          value: this.state.RowItem.tipo || ""
                        }}
                      >
                        {this.state.protocolTypes.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.id} - {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                    <GridItem md={4} className="select">
                      <CustomSelect
                        labelText="Tipo Invio"
                        id="tipoinvioList"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: this.state.disableAll, //this.props.disable,
                          onChange: (event) =>
                            this.HandleChange("tipoinvio", event.target.value),
                          value: this.state.RowItem.tipoinvio
                        }}
                      >
                        {this.state.sendTypes.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.id} - {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                    {this.state.RowItem.tipoinvio === 1 && (
                      <GridItem xs={12} sm={12} md={4}>
                        <p
                          className="calendarParagraph"
                          style={{
                            position: "relative",
                            top: "4px",
                            color:
                              this.state.fieldValidations["dtlett"] === "error"
                                ? "#f44336"
                                : "#565656"
                          }}
                        >
                          <small>Data Lettera</small>
                        </p>
                        <Calendar
                          className="calendarCustomedForMUI"
                          view="date"
                          id="dataconst"
                          value={this.state.RowItem.dtlett || ""}
                          dateFormat="dd/mm/yy"
                          monthNavigator
                          yearNavigator
                          yearRange="1900:2999"
                          onChange={(e) =>
                            this.HandleChange(
                              "dtlett",
                              moment(e.target.value).format(dateTimeFmt)
                            )
                          }
                        />
                      </GridItem>
                    )}
                  </GridContainer>
                  <GridContainer>
                    <GridItem md={4} className="select">
                      <CustomSelect
                        labelText="Causale"
                        id="caus"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled : this.state.disableAll,
                          onChange: (e) =>
                            this.HandleDropDownChange("causal", e.target.value),
                          value: this.state.RowItem.causal
                        }}
                      >
                        {this.state.Causale_List.map((item) => (
                          <MenuItem value={item.codice} key={item.codice}>
                            {item.codice + " - " + item.descrizione}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                    <GridItem md={8}>
                      <CustomInput
                        labelText="Oggetto"
                        success={
                          this.state.fieldValidations.oggetto &&
                          this.state.fieldValidations.oggetto === "success"
                        }
                        error={
                          this.state.fieldValidations.oggetto &&
                          this.state.fieldValidations.oggetto === "error"
                        }
                        id="ogg"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled : this.state.disableAll,
                          onChange: (event) =>
                            this.HandleChange("oggetto", event.target.value),
                          value: this.state.RowItem.oggetto
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  
                  {this.state.destMail && destinatariEmail}
                  
                  {this.state.ModificaTitle !== "Nuovo" && this.state.RowItem?.tipo === 2 && 
                    this.state.RowItem?.tipoinvio === 2 && (
                      <GridContainer style={{ marginBottom: "2vh" }}>
                         <GridItem xs={12} sm={12} md={7} style = {{marginTop : "1vh"}}>
                          <label htmlFor="testoMail">Testo Email</label>
                          <InputTextarea id="testoMail"
                            disabled={true}
                            autoResize
                            value={this.state.RowItem?.testoMail || ""}                            
                          />
                        </GridItem>
                      </GridContainer>
                    )}
                    
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={this.state.RowItem.tipo === 2 ? 6 : 12}
                      className="upload mt-2"
                    >
                    {!this.state.disableAll && (
                      <div className="upload">
                        <Toast
                          ref={(el) => {
                            this.toast = el;
                          }}
                        />
                        <Card className="">
                          <CardHeader color="info">
                            <h4
                              style={{
                                lineHeight: "1",
                                display: "inline",
                                verticalAlign: "bottom"
                              }}
                            >
                              Selezione Allegato
                            </h4>
                          </CardHeader>
                          <CardBody>
                            <FileUpload
                              url=""
                              name="attachs"
                              customUpload
                              uploadLabel={"Aggiungi"}
                              uploadHandler={this.onUpload}
                              onRemove={this.removeFile}
                              accept=".txt,.pdf,.csv,.xlsx,.docx,.doc"
                              maxFileSize={100_000_000}
                              onClear={() => this.setState({ files: [] })}
                              emptyTemplate={
                                <p className="p-m-0">Seleziona file</p>
                              }
                            />
                          </CardBody>
                        </Card>
                      </div>
                      )}   
                    </GridItem>
                    {this.state.RowItem.tipo === 2 && this.state.RowItem.tipoinvio === 1 && (
                      <GridItem xs={12} sm={12} md={6} className="mt-3">
                        <TestoLettera
                          text={this.state.emailText}
                          setEmailText={(value) => {
                            this.SetText("testomail", value);
                            this.setState({emailText: value});
                          }}
                        />
                      </GridItem>
                    )}
                    {this.state.RowItem?.tipo === 2 && 
                      this.state.RowItem?.tipoinvio === 2 && this.state.ModificaTitle != "Modifica" &&(
                        <GridItem md={6} className="mt-2">
                          <TestoEmail
                            accounts={StaticLists.Corrisp_Account_ebin()}
                            setAccount={(val) =>
                              this.setState({ selectedEmail: val })
                            }
                            selectedAccount={this.state.selectedEmail}
                            text={this.state.RowItem.testomail}
                            setEmailText={(val) => {
                              let { RowItem } = this.state;
                              RowItem.testomail = val;
                              this.setState({ RowItem });
                              this.setState({emailText: val});
                            }}
                            // recipientEmail={this.state.recipientEmail}
                            // setRecipientEmail={this.handleRcipientEmail}
                            userEmails={[]}
                            altroDest={this.state.altroDest ?? ""}
                            setAltroDest={(val) =>
                              this.setState({ altroDest: val })
                            }
                          />
                        </GridItem>
                      )}
                  </GridContainer>
                  <DataTable
                    emptyMessage=""
                    className="p-datatable-striped p-datatable-sm table mt-3"
                    value={this.state.attachments}
                    dataKey="id"
                    paginator
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                    rows={10}
                    loading={this.state.loadingGrid}
                    paginatorLeft={this.paginatorLeft}
                    paginatorRight={this.paginatorRight}
                    selection={this.state.selectedGrid}
                    scrollable
                    style={{ width: "100%" }}
                    onSelectionChange={this.handleTableClick}
                    selectionMode="single"
                  >
                    <Column
                      field="path"
                      header="Allegati"
                      sortable={true}
                      filter={this.state.filterGrid}
                      filterPlaceholder=""
                    />
                    {!this.state.disableAll && dynamicDeleteColumns}
                    {dynamicDownloadColumns}
                  </DataTable>
                </CardBody>
                {!(this.state.ModificaTitle =="Modifica"  && this.state.disableAll) && (
                  <CardFooter>
                    <ButtonSaveWithLoading
                      onClick={this.HandleSaveClick}
                      text={"SALVA"}
                      isValidForm={this.isValidForm}
                    />
                  </CardFooter>
                )}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}
