import Utils from "./Utils";
import helpers from "../helpers/Helpers";

const VersamentiAPI = {
  GetVersamentiTrasferiti: async function (aziendaId, year, codiceQuota) {
    const result = await Utils.CreatePostRequest(
      "coasco/verstrasf/gettrasfverso?idazienda=" +
        aziendaId +
        "&anno=" +
        year +
        "&quota=" +
        codiceQuota,
      {}
    );
    if (result === null) return [];
    helpers.formatCurrency(result.result, "importo");
    return result.result;
  },

  GetVersamentiRicevuti: async function (aziendaId, year, codiceQuota) {
    const result = await Utils.CreatePostRequest(
      "coasco/verstrasf/gettrasfda?idazienda=" +
        aziendaId +
        "&anno=" +
        year +
        "&quota=" +
        codiceQuota,
      {}
    );
    if (result === null) return [];
    helpers.formatCurrency(result.result, "importo");
    return result.result;
  },

  async GetVersamentiUniemensForDipendente(dipendenteCf) {
    const response = await Utils.CreatePostRequest(
      "coasco/versuni/getVersUniByCf?cf=" + dipendenteCf
    );
    if (response?.message?.includes("200")) {
      return response?.result;
    } else return null;
  },

  AddVersamenti: async function (versamentiObj) {
    return await Utils.CreatePutRequest(
      "coasco/versamenti/insert",
      versamentiObj
    );
  },

  UpdateVersamenti: async function (versamentiObj) {
    versamentiObj.causale = versamentiObj.causale.split(" ")[0];
    return await Utils.CreatePutRequest(
      "coasco/versamenti/insert",
      versamentiObj
    );
  },

  DeleteVersamenti: async function (id) {
    return await Utils.CreateDeleteRequest("coasco/versamenti/delete", id);
  }
};
export default VersamentiAPI;
