import React from "react";
import Loader from "react-loader-spinner";

//import AutocompleteAPI from "API/AutocompleteAPI.js";
class LoaderSpinner extends React.Component {
  state = {
    styleDiv: {
      width: "100%",
      height: "100%",
      position: "fixed",
      top: 0,
      backgroundColor: "#AEAEAE80",
      zIndex: "99999"
    },

    styleLoader: {
      marginLeft: "calc(50% - 50px)",
      marginTop: "calc(25% - 50px)"
    }
  };

  async componentDidMount() {
    if (this.props.fullHeight === false)
      this.setState({
        styleDiv: {
          width: "100%",
          position: "absolute",
          backgroundColor: "#AEAEAE80",
          zIndex: "99999"
        },
        styleLoader: { marginLeft: "calc(50% - 50px)" }
      });
    else if (this.props.report)
      this.setState({
        styleDiv: {
          width: "97.5%",
          height: "88.7%",
          position: "absolute",
          backgroundColor: "#AEAEAE80",
          zIndex: "99999"
        },
        styleLoader: { marginLeft: "calc(44%)", marginTop: "calc(9%)" }
      });
  }

  render() {
    return (
      <>
        <div style={this.state.styleDiv}>
          <Loader
            style={this.state.styleLoader}
            type="TailSpin"
            //  type="ThreeDots"
            color="#000000"
            height={100}
            width={100}
            visible={true}
          />
        </div>
      </>
    );
  }
}

export default LoaderSpinner;
