import React, { Component } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import { Button } from "primereact/button";
import queryString from "query-string";
import AmministratoriAPI from "API/AmministratoriAPI";
import AziendeAPI from "API/AziendeAPI";
export class AmministratoreHeader extends Component {
  state = {
    azienda: {},
    amministratore: []
  };

  constructor(props) {
    super(props);

    this.backButtonClick = this.backButtonClick.bind(this);
  }

  async componentDidMount() {
    let aziendaId = this.props.match.params.aziendaId;
    let aziendaById = await AziendeAPI.GetAziendaById(aziendaId);
    let amministratoriId = this.props.match.params.amministratoreId;
    let amministratori = await AmministratoriAPI.GetAmministratoriById(
      amministratoriId
    );
    this.setState({
      azienda: aziendaById[0],
      amministratore: amministratori[0]
    });
  }

  backButtonClick() {

    let qS = queryString.parse(this.props.history.location.search);
    let amministratoriId = this.props.match.params.amministratoreId;
    if (!qS.type)
      this.props.history.push(
        "/admin/aziende/" + this.props.match.params.aziendaId
      );
    else if (qS.type === "fromAmministratori")
      this.props.history.push("/admin/amministratori/" + amministratoriId);
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Button
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />

                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Amministratori
                </h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Codice Fiscale"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.amministratore.cf || ""
                      }}
                    />
                  </GridItem>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Nominativo"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.amministratore.nominativo || ""
                      }}
                    />
                  </GridItem>

                  {this.state.azienda && (
                    <GridItem xs={3}>
                      <CustomInput
                        labelText="Matricola"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.azienda.matricola
                        }}
                      />
                    </GridItem>
                  )}
                  {this.state.azienda && (
                    <GridItem xs={3}>
                      <CustomInput
                        labelText="Ragione Sociale"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: this.state.azienda.denominazione
                        }}
                      />
                    </GridItem>
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
