import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { RadioButton } from "primereact/radiobutton";
import ButtonsMUI from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {Dropdown} from "primereact/dropdown";
import AnniAccademiciAPI from "../../API/AnniAccademiciAPI";
import LoaderSpinner from "../../components/Layout/LoaderSpinner";
import Utils from "../../API/Utils";

class CSVPoste extends Component {

  state = {
    anniAccademici: [],
    annoAccademico: null,
    selectedType: null,
    radioChecked: false,
    loadingPage: false,
    OpzioniCSV: [
      { name: "Dipendenti", key: "DI" },
      { name: "Consulenti", key: "CO" },
      { name: "Amministratori", key: "AM" },
      { name: "Richiedenti BS", key: "RBS" }
    ]
  };

  constructor(props) {
    super(props);
    this.avvia = this.avvia.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
  }

  async componentDidMount() {
    this.setState({
      anniAccademici: await AnniAccademiciAPI.AnnoAccademiciForBorse()
    });
  }

  async avvia() {

    let url = "/export/";

    switch (this.state.radioChecked) {
      case "DI":
          url = "coasco" + url + "CSVDipendenti";
        break;
      case "CO":
        url = "coasco" + url + "CSVConsulenti";
        break
      case "AM":
        url = "coasco" + url + "CSVAmministratori";
        break;
      case "RBS":
        if (this.state.annoAccademico)
          url = "ebinprof" + url + "CSVRichiedenti?aa=" + this.state.annoAccademico.descr;
        else
          this.props.addAlert.current.addAlert("danger", "Selezionare l'anno accademico");
        break;
      default:
        return;
    }

    this.setState({loadingPage: true});
    await Utils.CreatePostRequestDownloadReport(url);
    this.setState({loadingPage: false});
  }

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });

    if (val !== null)
      this.setState({
        radioChecked: val.key
      });
  }

  render() {
    return (
      <>
        {this.state.loadingPage && <LoaderSpinner />}
        <div ref={this.btnSearchRef} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="borseprimary">
                <h4>Export CSV per Poste</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={5}>
                    Opzioni per CSV
                    <div>
                      {this.state.OpzioniCSV.map((csv) => {
                        return (
                          <div
                            key={csv.key}
                            className="p-field-radiobutton"
                            style={{ display: "inline", marginRight: "8px" }}
                          >
                            <RadioButton
                              inputId={csv.key}
                              name="category"
                              value={csv}
                              onChange={(e) =>
                                this.HandleChange("selectedType", e.value)
                              }
                              checked={this.state.radioChecked === csv.key}
                              className="mr-1"
                            />
                            <label className="labelCheckbox" htmlFor={csv.key}>
                              {csv.name}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <Dropdown options={this.state.anniAccademici} value={this.state.annoAccademico} disabled={this.state.radioChecked !== "RBS"} placeholder="Anno Accademico"
                              optionLabel="descr" onChange={e => this.setState({annoAccademico: e.value})} />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <ButtonGroup variant="contained" color="default" size="small" className="float-right">
                      <ButtonsMUI onClick={this.avvia}>Avvia</ButtonsMUI>
                    </ButtonGroup>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
export default CSVPoste;
