import Utils from "./Utils";

const BASEURL = "coasco/aliquota/";

const AliquotaAPI = {
  GetByCode: async function (code) {
    let result = await Utils.CreatePostRequest(
      BASEURL + "getbycode?code=" + code
    );
    return result.result;
  },

  GetAll: async function () {
    let response = await Utils.CreatePostRequest(BASEURL + "getall");
    return response.result;
  },

  async update(aliquota) {
    const response = await Utils.CreatePutRequest("coasco/aliquota/update", aliquota);
    if (response?.message?.includes("200")) {
      return response.result;
    } else return null;
  },

  async insert(aliquota) {
    const response = await Utils.CreatePostRequest("coasco/aliquota/insert", aliquota);
    if (response?.message?.includes("200")) {
      return response.result;
    } else return null;
  },

  async delete(aliquota) {
    const response = await Utils.CreateDeleteRequest("coasco/aliquota/delete?id=" + aliquota.id);
    if (response?.message?.includes("200")) {
      return response.result;
    } else return null;
  },

  getNew() {
    return {
      aliquota: "",
      cod_quota: "",
      ripfn: "",
      ripin: ""
    };
  }
};

export default AliquotaAPI;
