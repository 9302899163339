import Utils from "./Utils";

const NazionalitaAPI = {
  GetAll: async function () {
    let result = await Utils.CreatePostRequest("coasco/nazionalita/getall", {});
    if (result === null) return [];
    return result.result;
  },

  GetNazionalitaByCode: async function (nazionalitaCode) {
    let result = await Utils.CreatePostRequest(
      "coasco/nazionalita/getbycod?codice=" + nazionalitaCode,
      {}
    );
    if (result === null) return [];
    return result.result;
  }
};

export default NazionalitaAPI;
