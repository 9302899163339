import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CustomSelect from "components/CustomInput/CustomSelect.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import CardFooter from "components/Card/CardFooter.js";
import LoaderSpinner from "../../Layout/LoaderSpinner.js";
import ButtonSaveWithLoading from "components/CustomButtons/ButtonSaveWithLoading";
import helpers from "helpers/Helpers";
import { AutoComplete } from "primereact/autocomplete";
import { KeyboardDatePicker } from "@material-ui/pickers";
import "moment/locale/it";
import DipendentiAPI from "API/DipendentiAPI";
import ParentelaAPI from "API/ParentelaAPI";
import BorseAPI from "API/BorseAPI";

export class ModificaRichiesti extends Component {
  state = {
    RowItem: {},
    isLoading: false,
    ModificaTitle: "Nuovo",
    groupClass: "",
    rows: [],
    Parentela: [],
    Borse: [],
    selectedCFEmployee: null,
    selectedBorse: null,
    selectedParentela: null,
    datarichiesta: new Date(),
    tipoborse: 0,
    parentela: 0,
    dipendente: 0,

    //VALIDATIONS
    fieldValidations: {},
    validationRules: {
      cfRich: "CFPIVA",
      cfDip: "CFPIVA",
      dataArr: "",
      idBorsa: "",
      tipoParent: ""
    }
  };

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.SetText = this.SetText.bind(this);
    this.OpenStoricoRDLPage = this.OpenStoricoRDLPage.bind(this);
    this.HandleDropDownChange = this.HandleDropDownChange.bind(this);
    this.isValidForm = this.isValidForm.bind(this);
    this.searchEmployee = this.searchEmployee.bind(this);
  }

  async isValidForm() {
    const RowItem = {
      cfRich: this.props.candidate.cfRich,
      cfDip: this.state.selectedCFEmployee,
      dataArr: this.state.datarichiesta,
      idBorsa: this.state.selectedBorse,
      tipoParent: this.state.selectedParentela
    };

    let fieldValidations = helpers.ValidateForm(
      RowItem,
      this.state.validationRules
    );
    this.setState({
      fieldValidations: fieldValidations,
      RowItem: RowItem
    });
    return Object.values(fieldValidations).every((x) => x === "success");
  }

  SetText(field, newValue) {
    this.setState({ [field]: newValue });
  }

  OpenStoricoRDLPage() {
    if (this.props.aziendaId > 0)
      this.props.history.push(
        "/admin/rdl/storico/" +
          this.props.aziendaId +
          "/" +
          this.props.dipendentiId
      );
    else if (this.props.aziendaId === 0)
      this.props.history.push(
        "/admin/rdl/storico/0/" + this.props.dipendentiId
      );
  }

  HandleChange(field, newValue) {
    this.setState({ [field]: newValue });
  }

  searchEmployee(event) {
    setTimeout(async () => {
      var list =
        event.query.trim().length >= 3
          ? await DipendentiAPI.SearchByCf(event.query)
          : [];
      this.setState({
        searchedEmployees: list
      });
    }, 250);
  }

  HandleDropDownChange(e, val, propName) {
    this.setState({
      [e]: val
    });

    switch (propName) {
      case "tipoborse":
        this.setState({
          tipoborse: val
        });
        break;
      case "dipendente":
        this.setState({
          dipendente: val
        });
        break;
      case "parentela":
        this.setState({
          parentela: val
        });
        break;
    }
  }

  HandleSaveClick = async () => {
    await this.props.onSaveClick(this.state.RowItem);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.candidate.Id !== prevProps.candidate.Id)
      this.setState({
        ModificaTitle: this.props.candidate.Id > 0 ? "Modifica" : "Nuovo",
        groupClass: this.props.candidate.Id > 0 ? "" : "hide",
        selectedBorse: helpers.ReturnIdFromList(
          this.state.Borse,
          this.state.tipoborse
        ),
        selectedParentela: helpers.ReturnIdFromList(
          this.state.Parentela,
          this.state.parentela
        )
      });
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    this.setState({
      ModificaTitle: this.props.candidate.idRichied > 0 ? "Modifica" : "Nuovo",
      groupClass: this.props.candidate.idRichied > 0 ? "" : "hide",
      Parentela: await ParentelaAPI.GetAllTipoParentela(),
      Borse: (await BorseAPI.AllBorse()).sort((a, b) =>
        b.annoAcc.descr.localeCompare(a.annoAcc.descr)
      )
    });

    this.setState({
      selectedBorse: helpers.ReturnIdFromList(
        this.state.Borse,
        this.state.tipoborse
      ),
      selectedParentela: helpers.ReturnIdFromList(
        this.state.Parentela,
        this.state.parentela
      )
    });
    this.setState({ isLoading: false });
  }

  render() {
    if (this.state.isLoading)
      return (
        <>
          {" "}
          <LoaderSpinner fullHeight={false} />{" "}
        </>
      );
    else
      return (
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.Cognome &&
                          this.state.fieldValidations.Cognome === "success"
                        }
                        error={
                          this.state.fieldValidations.Cognome &&
                          this.state.fieldValidations.Cognome === "error"
                        }
                        labelText="Richiedente *"
                        id=""
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value:
                            this.props.candidate.cognome +
                            " " +
                            this.props.candidate.nome
                        }}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        success={
                          this.state.fieldValidations.cfRich &&
                          this.state.fieldValidations.cfRich === "success"
                        }
                        error={
                          this.state.fieldValidations.cfRich &&
                          this.state.fieldValidations.cfRich === "error"
                        }
                        labelText="Codice *"
                        id="codice"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (event) =>
                            this.SetText("codicefiscale", event.target.value),
                          value: this.props.candidate.cfRich || "",
                          maxLength: 16
                        }}
                      />
                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={6}
                      style={{ marginTop: "6px" }}
                    >
                      <p
                        className="calendarParagraph"
                        style={{
                          color:
                            this.state.fieldValidations["datarichiesta"] ===
                            "error"
                              ? "#f44336"
                              : "#565656"
                        }}
                      >
                        <small>Data Richiesta *</small>
                      </p>

                      <KeyboardDatePicker
                        // success={this.state.fieldValidations.dataArr && this.state.fieldValidations.dataArr === "success"}
                        // error={this.state.fieldValidations.dataArr && this.state.fieldValidations.dataArr === "error"}

                        className="ctrlMuiToPF"
                        clearable
                        value={this.state.datarichiesta}
                        placeholder="gg/MM/aaaa"
                        onChange={(e) => this.HandleChange("datarichiesta", e)}
                        format="DD/MM/YYYY"
                        invalidDateMessage="Data non valida"
                      />
                    </GridItem>

                    <GridItem xs={6} className="select">
                      <CustomSelect
                        success={
                          this.state.fieldValidations.tipoBorsa &&
                          this.state.fieldValidations.tipoBorsa === "success"
                        }
                        error={
                          this.state.fieldValidations.tipoBorsa &&
                          this.state.fieldValidations.tipoBorsa === "error"
                        }
                        labelText="Tipologia Borsa *"
                        id="borse"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (e) =>
                            this.HandleDropDownChange(
                              "selectedBorse",
                              e.target.value,
                              "tipoborse"
                            ),
                          value: this.state.selectedBorse || ""
                        }}
                      >
                        {this.state.Borse.sort(
                          (a, b) => b.annoAcc.descr - a.annoAcc.descr
                        ).map((item) => (
                          <MenuItem value={item.idBorsa} key={item.idBorsa}>
                            <b style={{ marginRight: "10px" }}>
                              {item.annoAcc.descr}
                            </b>
                            {item.tipoBorsa.descrBr}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>

                    <GridItem xs={6} className="select">
                      <p
                        className="calendarParagraph"
                        style={{
                          color:
                            this.state.fieldValidations["cfDip"] === "error"
                              ? "#f44336"
                              : "#565656"
                        }}
                      >
                        <small>Parente di*</small>
                      </p>
                      <AutoComplete
                        value={this.state.selectedCFEmployee}
                        suggestions={this.state.searchedEmployees}
                        placeholder="Cerca per CF"
                        maxLength={16}
                        completeMethod={this.searchEmployee}
                        className="matricolaAutocomplete"
                        onChange={(e) =>
                          this.setState({ selectedCFEmployee: e.value })
                        }
                        onSelect={(e) =>
                          this.setState({ selectedCFEmployee: e.value })
                        }
                      />
                    </GridItem>

                    <GridItem xs={6} className="select">
                      <CustomSelect
                        success={
                          this.state.fieldValidations.tipoParent &&
                          this.state.fieldValidations.tipoParent === "success"
                        }
                        error={
                          this.state.fieldValidations.tipoParent &&
                          this.state.fieldValidations.tipoParent === "error"
                        }
                        labelText="Parentela *"
                        id="par"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          disabled: false,
                          onChange: (e) =>
                            this.HandleDropDownChange(
                              "selectedParentela",
                              e.target.value,
                              "parentela"
                            ),
                          value: this.state.selectedParentela || ""
                        }}
                      >
                        {this.state.Parentela.map((item) => (
                          <MenuItem value={item.id} key={item.id}>
                            {item.id} - {item.descr}
                          </MenuItem>
                        ))}
                      </CustomSelect>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <ButtonSaveWithLoading
                    onClick={this.HandleSaveClick}
                    text={"SALVA"}
                    isValidForm={this.isValidForm}
                  />
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      );
  }
}
