import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import Tooltip from "@material-ui/core/Tooltip";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import Button from "components/CustomButtons/Button.js";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ButtonsMUI from "@material-ui/core/Button";
import { Checkbox } from "primereact/checkbox";
import helpers from "helpers/Helpers";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import StaticLists from "helpers/StaticLists";
import AziendeAPI from "API/AziendeAPI";
import AnomalieAPI from "API/AnomalieAPI";
import moment from "moment";
import { dateFmt, rowsPerPageOptions } from "../../helpers/AppConstants";
import SedeComuneProvAPI from "../../API/SedeComuneProvAPI";
import RegioniAPI from "../../API/RegioniAPI";
import PreparaMail from "./PreparaMail";

class ImpostazioneFiltroXAnomalie extends React.Component {
  state = {
    filteredRows: [],
    prots: null,

    loadingGrid: false,
    checkedTutti: false,

    selectedProvincia: null,
    selectedRegione: null,
    selectedCodice: null,
    selectedSAP: null,
    selectedGrid: null,
    selectedAnomalia: [],
    selectedGia: null,

    arrayId: null,

    Provincia: [],
    Regione: [],
    Anomalie: [],
    SAP: [],
    GiaScritto: [],
    CodiceQuota: [],

    inizioDate: new Date(2014, 0, 1),
    fineDate: new Date(),

    filteredMatricolaListByInput: null,
    firstRowToDisplay: 0,
    filterByMatricola: "",

    isPreparaMail: false,

    rowsPerPage: 5,
    totalRecords: 0,

    sortfield: null,
    order: null
  };

  constructor(props) {
    super(props);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.DocActionsGridTemplate = this.DocActionsGridTemplate.bind(this);
    this.onRedirectClick = this.onRedirectClick.bind(this);
    this.HandleChange = this.HandleChange.bind(this);
    this.searchMatricola = this.searchMatricola.bind(this);
    this.btnSearchRef = React.createRef();
    this.ChkAnomalieChange = this.ChkAnomalieChange.bind(this);
    this.PreparaMail = this.PreparaMail.bind(this);
    this.annullaClick = this.annullaClick.bind(this);
    this.onPage = this.onPage.bind(this);
    this.onSort = this.onSort.bind(this);
    this.headerRef = React.createRef();
  }

  async onPage(e) {
    const { selectedGrid } = this.state;
    this.setState(
      { firstRowToDisplay: e.first, rowsPerPage: e.rows },
      async () => {
        await this.handleSearchClick();
        this.setState({ selectedGrid });
      }
    );
  }

  async onSort(e) {
    this.setState(
      { sortfield: e.sortField, order: e.sortOrder },
      async () => await this.handleSearchClick()
    );
  }

  async HandleSaveClick(
    anno,
    dataProt,
    oggetto,
    emailText,
    senderEmail,
    files,
    amministratore,
    cond_azienda,
    altroDest
  ) {
    const arrayId = [];
    this.state.selectedGrid.map((x) => {
      return arrayId.push(parseInt(x?.id_azienda));
    });
    const res = await AnomalieAPI.PreparaMail(
      anno,
      dataProt,
      oggetto,
      emailText,
      senderEmail,
      files,
      amministratore,
      cond_azienda,
      arrayId,
      altroDest
    );
    res === 200
      ? this.props.addAlert.current.addAlert(
          "success",
          "Richiesta di preparazione mail presa in carico, per il download del report accedere alla sezione “Report Posta Anomalie”."
        )
      : this.props.addAlert.current.addAlert(
          "danger",
          "La richiesta di preparazione mail non è stata presa in carico correttamente."
        );
    return true;
  }

  PreparaMail() {
    if (
      this.state.selectedGrid !== null &&
      this.state.selectedGrid.length > 0
    ) {
      this.setState({ isPreparaMail: true });
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "Selezionare almeno un risultato"
      );
    }
  }

  annullaClick() {
    this.setState({ isPreparaMail: false });
    try {
      this.headerRef.current.scrollIntoView();
    } catch(err){}
  }

  async componentDidMount() {
    let firstDay = new Date(2014, 0, 1);
    let lastDay = new Date();

    this.setState({
      inizioDate: firstDay,
      fineDate: lastDay,
      Provincia: await SedeComuneProvAPI.GetAllProvincieProvincie(),
      Regione: await RegioniAPI.GetRegioni(),
      Anomalie: await AnomalieAPI.GetAnomalieType(),
      CodiceQuota: StaticLists.CodiceQuota(),
      GiaScritto: StaticLists.GiaScritto(),
      SAP: await SedeComuneProvAPI.GetAllSede()
    });
  }

  searchMatricola(event) {
    setTimeout(async () => {
      let list =
        event.query.trim().length >= 3
          ? await AziendeAPI.SearchByMatr(event.query)
          : [];
      this.setState({
        filteredMatricolaListByInput: list
      });
    }, 250);
  }

  HandleChange(e, val) {
    this.setState({
      [e]: val
    });

    let listfortutti = [];

    if (e === "selectedRegione") {
      this.setState({
        selectedProvincia: null,
        selectedSAP: null,
        filterByMatricola: ""
      });
    } else if (e === "selectedProvincia") {
      this.setState({
        selectedRegione: null,
        selectedSAP: null,
        filterByMatricola: ""
      });
    } else if (e === "selectedSAP") {
      this.setState({
        selectedRegione: null,
        selectedProvincia: null,
        filterByMatricola: ""
      });
    } else if (e === "filterByMatricola") {
      this.setState({
        selectedRegione: null,
        selectedProvincia: null,
        selectedSAP: null
      });
    } else if (e === "checkedTutti") {
      if (val === true) {
        listfortutti = this.state.Anomalie.map((anomalie) => anomalie.codice);
      } else listfortutti = [];
      this.setState({
        selectedAnomalia: listfortutti
      });
    }
  }

  ChkAnomalieChange(e, val) {
    let currentValue = this.state[e];
    if (currentValue.indexOf(val.codice) >= 0)
      currentValue.splice(currentValue.indexOf(val.codice), 1);
    else currentValue.push(val.codice);
    this.setState({
      [e]: currentValue
    });
  }

  DocActionsGridTemplate(rowData) {
    return (
      <>
        <Button
          round
          color="info"
          className="actionButton actionButtonRound btnRound  "
          key={rowData.Id}
          onClick={(e) => this.onRedirectClick(e, rowData)}
        >
          <VisibilityIcon className="icon" />
        </Button>
      </>
    );
  }

  onRedirectClick(e, rowData) {
    e.stopPropagation();
    this.props.history.push(
      "/admin/AnomalieXAzienda/" +
        rowData.id_azienda +
        "?type=fromFiltroAnomalie"
    );
  }

  handleSearchClick = async () => {
    this.setState({ loadingGrid: true, isPreparaMail: false });

    helpers.SaveSearchFilters(
      {
        filterByMatricola: this.state.filterByMatricola
      },
      "AnomalieList"
    );

    this.setState({
      selectedGrid: null
    });

    let filters = {
      compInizio: moment(this.state.inizioDate).format(dateFmt),
      compFine: moment(this.state.fineDate).format(dateFmt),
      start: this.state.firstRowToDisplay,
      size: this.state.rowsPerPage
    };

    if (this.state.selectedAnomalia.length > 0)
      filters.tipoAnomalia = this.state.selectedAnomalia;
    if (this.state.filterByMatricola !== "")
      filters.matricola = this.state.filterByMatricola;
    if (this.state.selectedSAP !== null)
      filters.codiceSap = this.state.selectedSAP.sap;
    if (this.state.selectedGia !== null)
      filters.giascritto = this.state.selectedGia.id;
    if (
      this.state.selectedCodice !== null &&
      this.state.selectedCodice.length > 0
    )
      filters.quota = this.state.selectedCodice.map((x) => x.name);
    if (
      this.state.selectedRegione !== null &&
      this.state.selectedRegione.length > 0
    )
      filters.regione = this.state.selectedRegione.map((x) => x.id);
    if (this.state.selectedProvincia !== null)
      filters.provincia = this.state.selectedProvincia.sigla;
    if (this.state.order !== null)
      filters.order = this.state.order === 1 ? "ASC" : "DESC";
    if (this.state.sortfield !== null) {
      switch (this.state.sortfield) {
        case "matricola":
          filters.sortfield = "az.matricola";
          break;
        case "cod_quota":
          filters.sortfield = "an.cod_quota";
          break;
        case "denominazone":
          filters.sortfield = "az.denominazione";
          break;
        case "cap":
          filters.sortfield = "az.cap";
          break;
        case "comune":
          filters.sortfield = "az.comune";
          break;
        case "prov":
          filters.sortfield = "az.prov";
          break;
        case "codice_fiscale":
          filters.sortfield = "az.codice_fiscale";
          break;
        case "nanom":
          filters.sortfield = "nanom";
          break;
      }
    }

    let { result, totalRecords } = await AnomalieAPI.GetAnomalieByFilter(
      filters
    );

    if (result !== null) {
      this.setState({ filteredRows: result, totalRecords: totalRecords });
    } else {
      this.props.addAlert.current.addAlert(
        "danger",
        "Impossibile effetture la ricerca"
      );
    }

    this.setState({ loadingGrid: false });
  };

  handleClearClick = async () => {
    helpers.SaveSearchFilters(null, "AnomalieList");
    let date = new Date();

    this.setState({
      chkAnomalieSelected: null,
      filteredMatricolaListByInput: null,
      filterByMatricola: "",
      selectedProvincia: null,
      selectedRegione: null,
      selectedCodice: null,
      selectedAnomalia: [],
      selectedSAP: null,
      selectedGrid: null,
      selectedGia: null,
      isPreparaMail: false,
      inizioDate: new Date(2014, 0, 1),
      fineDate: new Date(date.getFullYear(), date.getMonth() + 1, 1),
      filteredRows: []
    });
  };

  handleTableClick = async (e) => {
    this.setState({
      selectedGrid: e.value
    });

    if (e.value.length > 0)
      this.setState({
        arrayId: e.value[0].id
      });
    else {
      this.setState({ isPreparaMail: false });
      try {
        this.headerRef.current.scrollIntoView();
      } catch(err){}
    }
  };

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <h4 ref={this.headerRef}>Impostazioni Filtro per Posta</h4>
              </CardHeader>
              <CardBody>
                <div style={{ width: "100%" }}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <Card>
                        <CardHeader color="primary">
                          <h5 style={{ margin: "0" }}>Tipo di Anomalia</h5>
                        </CardHeader>
                        <CardBody className="d-flex flex-row justify-content-around">
                          <FormControlLabel
                            style={{
                              margin: "0px",
                              marginRight: "8px"
                            }}
                            control={
                              <Checkbox
                                color="primary"
                                checked={this.state.checkedTutti}
                                onChange={(e) =>
                                  this.HandleChange(
                                    "checkedTutti",
                                    e.target.checked
                                  )
                                }
                              />
                            }
                            label="&nbsp;Tutti"
                            className="labelCheckbox"
                          />
                          {this.state.Anomalie.map((anomalie) => {
                            return (
                              <FormControlLabel
                                key={anomalie.codice}
                                className="labelCheckbox"
                                label={`\u00A0${anomalie.codice} ${anomalie.notazione}`}
                                style={{ margin: "0px", marginRight: "8px" }}
                                control={
                                  <Checkbox
                                    inputId={anomalie.codice}
                                    color="primary"
                                    name="anomalie"
                                    value={anomalie}
                                    onChange={(e) =>
                                      this.ChkAnomalieChange(
                                        "selectedAnomalia",
                                        e.value
                                      )
                                    }
                                    checked={
                                      this.state.selectedAnomalia.indexOf(
                                        anomalie.codice
                                      ) >= 0
                                    }
                                  />
                                }
                              />
                            );
                          })}
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Card style={{ marginTop: "0px" }}>
                        <CardBody>
                          <GridContainer>
                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="date mb-2"
                            >
                              <p className="lblNoMargin">Comp. Inizio</p>
                              <Calendar
                                id="icon"
                                value={this.state.inizioDate}
                                view="month"
                                dateFormat="mm/yy"
                                onChange={(e) =>
                                  this.HandleChange("inizioDate", e.value)
                                }
                                showIcon
                                yearNavigator
                                yearRange="1900:2099"
                                className="w-100"
                              />
                            </GridItem>

                            <GridItem
                              xs={12}
                              sm={12}
                              md={6}
                              className="date mb-2"
                            >
                              <p className="lblNoMargin">Comp. Fine</p>
                              <Calendar
                                id="icon"
                                value={this.state.fineDate}
                                view="month"
                                dateFormat="mm/yy"
                                onChange={(e) =>
                                  this.HandleChange("fineDate", e.value)
                                }
                                showIcon
                                yearNavigator
                                yearRange="1900:2099"
                                className="w-100"
                              />
                            </GridItem>
                          </GridContainer>

                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6}>
                              <p className="lblNoMargin">Gia scritto?</p>
                              <Dropdown
                                display="chip"
                                optionLabel="name"
                                maxSelectedLabels={1}
                                placeholder="Gia scritto?"
                                value={this.state.selectedGia}
                                options={this.state.GiaScritto}
                                onChange={(e) =>
                                  this.HandleChange("selectedGia", e.value)
                                }
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={6}>
                              <p className="lblNoMargin">Quota</p>
                              <MultiSelect
                                display="chip"
                                optionLabel="name"
                                maxSelectedLabels={1}
                                value={this.state.selectedCodice}
                                options={this.state.CodiceQuota}
                                placeholder="Cerca per codice quota"
                                onChange={(e) =>
                                  this.HandleChange("selectedCodice", e.value)
                                }
                              />
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <Card style={{ marginTop: "0px" }}>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={6} className="mb-2">
                              <p className="lblNoMargin">Azienda</p>
                              <AutoComplete
                                value={this.state.filterByMatricola}
                                placeholder="Cerca per Matricola"
                                suggestions={
                                  this.state.filteredMatricolaListByInput
                                }
                                completeMethod={this.searchMatricola}
                                onChange={(e) =>
                                  this.HandleChange(
                                    "filterByMatricola",
                                    e.value
                                  )
                                }
                                className="matricolaAutocomplete"
                                type="number"
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={6} className="mb-2">
                              <p className="lblNoMargin">SAP</p>
                              <Dropdown
                                value={this.state.selectedSAP}
                                options={this.state.SAP}
                                placeholder="Cerca SAP"
                                onChange={(e) =>
                                  this.HandleChange("selectedSAP", e.value)
                                }
                                optionLabel="descrizione"
                                itemTemplate={(option) => (
                                  <>
                                    {option.sap} - {option.descrizione}
                                  </>
                                )}
                                valueTemplate={(option, prop) => {
                                  if (option)
                                    return (
                                      <>
                                        {option.sap} - {option.descrizione}
                                      </>
                                    );
                                  else return <>{prop.placeholder}</>;
                                }}
                                filter
                                filterBy="sap,descrizione"
                              />{" "}
                            </GridItem>
                          </GridContainer>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={4}>
                              <p className="lblNoMargin">Regione</p>

                              <MultiSelect
                                filter
                                display="chip"
                                optionLabel="nome"
                                value={this.state.selectedRegione}
                                options={this.state.Regione}
                                placeholder="Cerca per regione"
                                onChange={(e) =>
                                  this.HandleChange("selectedRegione", e.value)
                                }
                              />
                            </GridItem>

                            <GridItem xs={12} sm={12} md={5}>
                              <p className="lblNoMargin">Provincia</p>
                              <Dropdown
                                value={this.state.selectedProvincia}
                                options={this.state.Provincia}
                                onChange={(e) =>
                                  this.HandleChange(
                                    "selectedProvincia",
                                    e.value
                                  )
                                }
                                placeholder="Cerca per provincia"
                                optionLabel="nome"
                                filter
                                filterBy="nome"
                              />{" "}
                            </GridItem>

                            <GridItem xs={12} sm={12} md={3}>
                              <Tooltip
                                arrow
                                id="tooltip-top"
                                title="Cerca"
                                placement="top"
                                classes={{ tooltip: "tooltip-me" }}
                              >
                                <Button
                                  ref={this.btnSearchRef}
                                  style={{
                                    verticalAlign: "center",
                                    padding: "0px",
                                    marginLeft: "24px",
                                    marginTop: "15px"
                                  }}
                                  color="white"
                                  aria-label="edit"
                                  justIcon
                                  round
                                  className="p-button-rounded p-button-primary p-button-sm float-left"
                                  icon="pi pi-arrow-left"
                                  onClick={this.handleSearchClick}
                                >
                                  <Search />
                                </Button>
                              </Tooltip>

                              <Tooltip
                                arrow
                                id="tooltip-top"
                                title="Reimposta"
                                placement="top"
                                classes={{ tooltip: "tooltip-me" }}
                              >
                                <Button
                                  style={{
                                    verticalAlign: "center",
                                    padding: "0px",
                                    marginTop: "15px",
                                    marginLeft: "6px",
                                    marginRight: "0"
                                  }}
                                  color="white"
                                  aria-label="edit"
                                  justIcon
                                  round
                                  className="p-button-rounded p-button-primary p-button-sm float-left"
                                  icon="pi pi-arrow-left"
                                  onClick={this.handleClearClick}
                                >
                                  <Clear />
                                </Button>
                              </Tooltip>
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                  </GridContainer>
                </div>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4 className="d-inline">Risultati</h4>
                <ButtonGroup
                  variant="contained"
                  color="primary"
                  size="small"
                  aria-label="contained primary button group"
                  className="float-right m-1"
                >
                  <ButtonsMUI onClick={this.PreparaMail}>
                    Prepara mail
                  </ButtonsMUI>
                </ButtonGroup>
              </CardHeader>
              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.filteredRows}
                  dataKey="id_azienda"
                  // first={this.state.firstRowToDisplay}
                  // onPage={this.onPage}
                  // onSort={this.onSort}
                  // sortOrder={this.state.order}
                  // sortField={this.state.sortfield}
                  paginator
                  // lazy
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  // totalRecords={this.state.totalRecords}
                  rows={this.state.rowsPerPage}
                  rowsPerPageOptions={rowsPerPageOptions}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="multiple"
                  metaKeySelection={true}
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "40px", paddingLeft: "0.25em" }}
                  />
                  <Column
                    field="matricola"
                    header="Matricola"
                    sortable={true}
                    style={{ width: "135px" }}
                  />
                  <Column
                    field="cod_quota"
                    header="Codice Quota"
                    sortable={true}
                    style={{ width: "150px" }}
                  />
                  <Column
                    field="denominazione"
                    header="Ragione Sociale"
                    style={{ width: "300px" }}
                    sortable={true}
                  />
                  <Column
                    field="cap"
                    header="CAP"
                    sortable={true}
                    style={{ width: "80px" }}
                  />
                  <Column
                    field="comune"
                    header="Comune"
                    sortable={true}
                    style={{ width: "150px" }}
                  />
                  <Column
                    field="prov"
                    header="Provincia"
                    sortable={true}
                    style={{ width: "150px" }}
                  />
                  <Column
                    field="codiceFiscale"
                    header="C. F."
                    sortable={true}
                    style={{ width: "150px" }}
                    body={(row) => <>{row.partita_iva ?? row.codice_fiscale}</>}
                  />
                  <Column
                    field="nanom"
                    header="N. Anom."
                    sortable={true}
                    style={{ width: "110px" }}
                  />
                  <Column
                    header=""
                    style={{ width: "50px" }}
                    body={this.DocActionsGridTemplate}
                    className="colDocActions"
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
          {this.state.isPreparaMail && (
            <GridItem xs={12} sm={12} md={12}>
              <PreparaMail
                {...this.props}
                onAnnullaClick={this.annullaClick}
                onSaveClick={this.HandleSaveClick}
                prots={this.state.prots}
              />
            </GridItem>
          )}
        </GridContainer>
      </>
    );
  }
}
export default ImpostazioneFiltroXAnomalie;
