import Utils from "./Utils";

const RegioniAPI = {
  GetRegioni: async function () {
    const response = await Utils.CreatePostRequest('coasco/regione/getall');
    if (response && response.message && response.message.includes('200')) {
      return response.result;
    } else return null;
  }
};

export default RegioniAPI;
