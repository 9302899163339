import React, { Component } from "react";
import { ModificaDipendenteXAzienda } from "components/Views/Dipendenti/ModificaDipendenteXAzienda.js";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { AziendaHeader } from "components/Views/Aziende/AziendaHeader.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import helpers from "helpers/Helpers";
import AddIcon from "@material-ui/icons/Add";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ButtonsMUI from "@material-ui/core/Button";
import queryString from "query-string";
import DipendentiAPI from "API/DipendentiAPI";
import {rowsPerPageOptions} from "../../helpers/AppConstants";

export class DipendentiXAzienda extends Component {
  state = {
    rows: null,
    selectedDipendenti: null,
    selectedGridDipendenti: null,
    viewButtons: false,
    viewRDLNuovoDip: false,
    filterGrid: false,
    loadingGrid: false,
    firstRowToDisplay: 0,
    qSIdFromRichieste: 0,
    rowsPerPage: 10
  };

  constructor(props) {
    super(props);
    this.ChangeButtons = this.ChangeButtons.bind(this);
    this.handleTableClick = this.handleTableClick.bind(this);
    this.HandleSaveClick = this.HandleSaveClick.bind(this);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.onNuovoClick = this.onNuovoClick.bind(this);
    this.onCloseGridClick = this.onCloseGridClick.bind(this);
    this.btnSearchRef = React.createRef();
    this.RedirectBtn = this.RedirectBtn.bind(this);
  }
  RedirectBtn(buttonName) {
    let qS = queryString.parse(this.props.location.search);
    if (buttonName === "RDL") {
      if (!qS.type)
        this.props.history.push(
          "/admin/RDL/" +
            this.props.match.params.aziendaId +
            "/" +
            this.state.selectedDipendenti.id
        );
      else if (qS.type === "fromVersamenti")
        this.props.history.push(
          "/admin/RDL/" +
            this.props.match.params.aziendaId +
            "/" +
            this.state.selectedDipendenti.id +
            "?type=" +
            qS.type
        );
      else if (qS.type === "fromModificaRichieste")
        this.props.history.push(
          "/admin/RDL/" +
            this.props.match.params.aziendaId +
            "/" +
            this.state.selectedDipendenti.id +
            "?type=" +
            qS.type +
            "&typeId=" +
            qS.typeId
        );
      else if (qS.type === "fromRichieste")
        this.props.history.push(
          "/admin/RDL/" +
            this.props.match.params.aziendaId +
            "/" +
            this.state.selectedDipendenti.id +
            "?type=" +
            qS.type +
            "&typeId=" +
            qS.typeId
        );
    } else if (buttonName === "Storico") {
      if (!qS.type)
        this.props.history.push(
          "/admin/StoricoXDipendente/" +
            this.props.match.params.aziendaId +
            "/" +
            this.state.selectedDipendenti.id
        );
      else if (qS.type === "fromVersamenti")
        this.props.history.push(
          "/admin/StoricoXDipendente/" +
            this.props.match.params.aziendaId +
            "/" +
            this.state.selectedDipendenti.id +
            "?type=" +
            qS.type
        );
      else if (qS.type === "fromModificaRichieste")
        this.props.history.push(
          "/admin/StoricoXDipendente/" +
            this.props.match.params.aziendaId +
            "/" +
            this.state.selectedDipendenti.id +
            "?type=" +
            qS.type +
            "&typeId=" +
            qS.typeId
        );
      else if (qS.type === "fromRichieste")
        this.props.history.push(
          "/admin/StoricoXDipendente/" +
            this.props.match.params.aziendaId +
            "/" +
            this.state.selectedDipendenti.id +
            "?type=" +
            qS.type +
            "&typeId=" +
            qS.typeId
        );
    }
  }

  async componentDidMount() {
    this.setState({
      loadingGrid: true
    });
    let qS = queryString.parse(this.props.location.search);

    let rw = await DipendentiAPI.GetDipendentiForAzienda(
      this.props.match.params.aziendaId
    );
    let selectedRow = null;
    let firstRowToDisplay = 0;
    if (qS.typeId > 0) {
      selectedRow = helpers.ReturnRowFromListById(rw, qS.typeId);
      firstRowToDisplay = helpers.GetFirstElementOfPageIncludingId(
        qS.typeId, rw, 10
      );
    }

    this.setState({
      firstRowToDisplay: firstRowToDisplay,
      rows: rw
    });

    if (selectedRow)
      await this.handleTableClick({
        value: selectedRow
      });
    this.setState({
      loadingGrid: false
    });
  }

  onCloseGridClick() {
    this.setState({
      selectedGridDipendenti: null,
      selectedDipendenti: null
    });
    try {
      this.btnSearchRef.current.scrollIntoView();
    } catch(err){}
  }

  async onNuovoClick() {
    let nuovo = await DipendentiAPI.GetNuovoDipendenteForAzienda();
    this.setState({
      selectedGridDipendenti: null,
      selectedDipendenti: nuovo,
      viewButtons: false,
      viewRDLNuovoDip: false
    });
  }
  async ChangeFilterGrid(e) {
    this.setState({ filterGrid: e.target.checked });
  }

  async handleTableClick(e) {
    let dep = await DipendentiAPI.GetDipendentiById(e.value.id);
    this.setState({
      viewButtons: true,
      viewRDLNuovoDip: false,
      selectedGridDipendenti: e.value,
      selectedDipendenti: dep[0]
    });
  }

  HandleSaveClick = async (RowItem) => {
    let firstRowToDisplay = 0,
      dipendenteNuovo;

    if (RowItem.id === null) {
      dipendenteNuovo = await DipendentiAPI.UpdateDipendenti(RowItem);
      this.state.selectedDipendenti = dipendenteNuovo.result[0];
      this.state.viewRDLNuovoDip = true;
    } else {
      dipendenteNuovo = await DipendentiAPI.UpdateDipendenti(RowItem);
      firstRowToDisplay = this.state.firstRowToDisplay;
    }

    if (dipendenteNuovo.message.includes("200")) {
      this.props.addAlert.current.addAlert("success", "Dati salvati.");
    } else {
      this.props.addAlert.current.addAlert("error", "Dati non salvati.");
    }

    this.setState({
      loadingGrid: true
    });
    let rw = await DipendentiAPI.GetDipendentiForAzienda(
      this.props.match.params.aziendaId
    );
    this.setState({
      firstRowToDisplay: firstRowToDisplay,
      rows: rw
    });
    this.setState({
      loadingGrid: false
    });

    return true;
  };

  ChangeButtons = async (boolean, existentDipendenti) => {
    await this.handleTableClick({
      value: existentDipendenti[0]
    });
  };

  backButtonClick = () => {
    let qS = queryString.parse(this.props.location.search);
    let maintainSearch = "?maintainSearch=1";
    if (!qS.type)
      this.props.history.push(
        "/admin/aziende/" + this.props.match.params.aziendaId + maintainSearch
      );
    else if (qS.type === "fromVersamenti")
      this.props.history.push(
        "/admin/versamenti/details/" +
          this.props.match.params.aziendaId +
          maintainSearch
      );
    else if (qS.type === "fromModificaRichieste")
      this.props.history.push(
        "/admin/richiedenti/modificarichieste/" +
          this.props.match.params.aziendaId +
          "/" +
          qS.typeId +
          maintainSearch
      );
    else if (qS.type === "fromRichieste")
      this.props.history.push(
        "/admin/richiedenti/modificarichieste/" +
          this.props.match.params.aziendaId +
          "/" +
          qS.typeId +
          maintainSearch +
          "&type=" +
          qS.type
      );
    else if (qS.type === "fromConsulenti")
      this.props.history.push(
        "/admin/consulenti/" +
          this.props.match.params.aziendaId +
          maintainSearch
      );
    else if (qS.type === "fromAmministratori")
      this.props.history.push(
        "/admin/amministratori/" +
          this.props.match.params.aziendaId +
          maintainSearch
      );
    else if (qS.type === "fromDipendenti")
      this.props.history.push(
        "/admin/dipendenti/" +
          this.props.match.params.aziendaId +
          maintainSearch
      );
  };

  render() {
    return (
      <div>
        <div ref={this.btnSearchRef} />
        <AziendaHeader
          aziendaId={this.props.match.params.aziendaId}
          {...this.props}
        />

        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Dipendenti
                </h4>
                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Inserisci"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.onNuovoClick}
                  >
                    <AddIcon />
                  </Button>
                </Tooltip>

                <Tooltip
                  arrow
                  id="tooltip-top"
                  title="Alla pagina precedente"
                  placement="top"
                  classes={{ tooltip: "tooltip-me" }}
                >
                  <Button
                    style={{
                      verticalAlign: "center",
                      lineHeight: "1",
                      margin: "0.1%"
                    }}
                    aria-label="edit"
                    justIcon
                    round
                    className="float-right"
                    onClick={this.backButtonClick}
                  >
                    <ArrowBackIcon />
                  </Button>
                </Tooltip>
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  first={this.state.firstRowToDisplay}
                  onPage={(e) => this.setState({ firstRowToDisplay: e.first, rowsPerPage: e.rows })}
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={this.state.rowsPerPage} rowsPerPageOptions={rowsPerPageOptions}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  loading={this.state.loadingGrid}
                  selection={this.state.selectedGridDipendenti}
                  scrollable
                  style={{ width: "100%" }}
                  onSelectionChange={this.handleTableClick}
                  selectionMode="single"
                >
                  <Column
                    field="nominativo"
                    header="Nominativo"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cf"
                    header="Codice fiscale"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="indirizzo"
                    header="Indirizzo"
                    headerStyle={{ width: "200px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="comunex"
                    header="Comune"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="cod_quota"
                    header="Codice Quota"
                    headerStyle={{width: "150px"}}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data_inizio"
                    body={(rowData) =>
                      helpers.DateTemplateWithHours(rowData, "data_inizio")
                    }
                    header="Inizio"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="data_fine"
                    body={(rowData) =>
                      helpers.DateTemplateWithHours(rowData, "data_fine")
                    }
                    header="Fine"
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.selectedDipendenti && (
          <ModificaDipendenteXAzienda
            employeeId={this.state.selectedDipendenti.id}
            aziendaId={this.props.match.params.dipendenteId}
            dataItem={this.state.selectedDipendenti}
            history={this.props.history}
            onAnnullaClick={this.onCloseGridClick}
            onSaveClick={this.HandleSaveClick}
            addAlert={this.props.addAlert}
            ChangeButtons={this.ChangeButtons}
          >
            {this.state.viewButtons && (
              <ButtonGroup
                variant="contained"
                color="primary"
                size="small"
                aria-label="contained primary button group"
                className="float-right"
                style={{ verticalAlign: "center", margin: "0.5%" }}
              >
                <ButtonsMUI onClick={() => this.RedirectBtn("Storico")}>
                  Storico Dip.
                </ButtonsMUI>
                <ButtonsMUI onClick={() => this.RedirectBtn("RDL")}>
                  RDL
                </ButtonsMUI>
              </ButtonGroup>
            )}

            {this.state.viewRDLNuovoDip && (
              <ButtonGroup
                variant="contained"
                color="primary"
                size="small"
                aria-label="contained primary button group"
                className="float-right"
                style={{ verticalAlign: "center", margin: "0.5%" }}
              >
                <ButtonsMUI onClick={() => this.RedirectBtn("RDL")}>
                  RDL
                </ButtonsMUI>
              </ButtonGroup>
            )}
          </ModificaDipendenteXAzienda>
        )}
      </div>
    );
  }
}
export default DipendentiXAzienda;
