import Utils from "./Utils";

const GestioneTabelleAPI = {

  GetAllIndirizziSedi: async function() {
    return await Utils.CreatePostRequest("coasco/indsedi/getall", {});
  },

  GetAllAgenziePostali: async function() {
    return await Utils.CreatePostRequest("coasco/agenziepostali/getall", {});
  },

  GetAllCausaliContributi: async function() {
    return await Utils.CreatePostRequest("coasco/causali/getall", {});
  },
  
  GetAllCausaliCorrispondenza: async function() {
    return await Utils.CreatePostRequest("coasco/caucorr/getall", {});
  },

  GetAllAliquote: async function() {
    return await Utils.CreatePostRequest("coasco/aliquota/getall", {});
  },

  GetAllAliquoteSpese: async function() {
    return await Utils.CreatePostRequest("coasco/AliquotaSpese/getall", {});
  },

  GetAllQualifica1: async function() {
    return await Utils.CreatePostRequest("coasco/uniq1/getall", {});
  },

  GetAllQualifica2: async function() {
    return await Utils.CreatePostRequest("coasco/uniq2/getall", {});
  },

  GetAllQualifica3: async function() {
    return await Utils.CreatePostRequest("coasco/uniq3/getall", {});
  },

  GetAllTipoContratto: async function() {
    return await Utils.CreatePostRequest("coasco/unitipocontratto/getall", {});
  },

  GetAllTipoDenuncia: async function() {
    return await Utils.CreatePostRequest("coasco/unitipoden/getall", {});
  },

  GetAllTipoLavoratore: async function() {
    return await Utils.CreatePostRequest("coasco/unitipolav/getall", {});
  },
}

export default GestioneTabelleAPI;