import React from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { Button as OtherButton } from "primereact/button";
import Switch from "@material-ui/core/Switch";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Helpers from "helpers/Helpers.js";
import TabulatiAPI from "API/TabulatiAPI";
import "moment/locale/it";
import moment from "moment";
moment.locale("it");

class DaTabulati extends React.Component {
  state = {
    rows: [],
    selectedGrid: null,
    filterGrid: false,
    loadingGrid: false
  };

  constructor(props) {
    super(props);
    this.ChangeFilterGrid = this.ChangeFilterGrid.bind(this);
    this.backButtonClick = this.backButtonClick.bind(this);
  }

  backButtonClick() {
    this.props.history.push(
      "/admin/tabulati/" +
        this.props.match.params.tabulatiId +
        this.props.location.search
    );
  }

  async componentDidMount() {
    const scomposizione = this.props.history.location.search.split("/");
    const sede = scomposizione[2];
    const codice = scomposizione[1];
    const ripartizione = scomposizione[3] + "/" + scomposizione[4];

    this.setState({ loadingGrid: true });

    this.setState({
      rows: await TabulatiAPI.GetTabulatiBySedeRipartoQuota(
        sede,
        ripartizione,
        codice
      )
    });

    this.setState({
      loadingGrid: false
    });
  }

  ChangeFilterGrid(e) {
    this.setState({
      filterGrid: e.target.checked
    });
  }

  render() {
    return (
      <>
        <div ref={this.btnSearchRef} />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="ebinprofprimary">
                <OtherButton
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />
                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Da Tabulati
                </h4>
                <FormControlLabel
                  className="float-right"
                  style={{
                    verticalAlign: "center",
                    lineHeight: "1",
                    margin: "0.1%",
                    color: "white"
                  }}
                  control={
                    <Switch
                      checked={this.state.filterGrid}
                      onChange={this.ChangeFilterGrid}
                      name="chkFilterGrid"
                      color="default"
                    />
                  }
                  label={"Attiva Filtri"}
                />
              </CardHeader>

              <CardBody>
                <DataTable
                  emptyMessage=""
                  className="p-datatable-striped p-datatable-sm"
                  value={this.state.rows}
                  dataKey="id"
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Visualizzati {first} - {last} di {totalRecords}"
                  rows={5}
                  loading={this.state.loadingGrid}
                  paginatorLeft={this.paginatorLeft}
                  paginatorRight={this.paginatorRight}
                  selection={this.state.selectedGrid}
                  scrollable
                  style={{ width: "100%" }}
                >
                  <Column
                    field="competenza"
                    header="Competenza"
                    headerStyle={{ width: "50px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="righe"
                    header="# Quote"
                    headerStyle={{ width: "50px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="importo"
                    header="Imp. Quote"
                    headerStyle={{ width: "40px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                    body={(rowData) =>
                      Helpers.DecimalDTTemplate(rowData, "importo")
                    }
                  />
                  <Column
                    field="tipoultimoagg"
                    header="Tipo Ult. Agg."
                    headerStyle={{ width: "60px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="codice"
                    header="Codice"
                    headerStyle={{ width: "100px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="sede"
                    header="Sede"
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="riparto"
                    header="Riparto"
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                  <Column
                    field="dataultimo"
                    header="Data Ultimo Agg."
                    headerStyle={{ width: "80px" }}
                    sortable={true}
                    filter={this.state.filterGrid}
                    filterMatchMode="contains"
                    filterPlaceholder=""
                  />
                </DataTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}

export default DaTabulati;
