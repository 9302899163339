import React, { Component } from "react";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { Button } from "primereact/button";
import queryString from "query-string";
import DipendentiAPI from "API/DipendentiAPI";
export class DipendenteHeader extends Component {
  state = {
    dipendente: {}
  };

  constructor(props) {
    super(props);

    this.backButtonClick = this.backButtonClick.bind(this);
  }

  async componentDidMount() {
    const dipendenteId = this.props.match.params.dipendenteId;
    const dipendenteById = await DipendentiAPI.GetDipendentiById(dipendenteId);

    this.setState({
      dipendente: dipendenteById[0]
    });
  }

  backButtonClick() {
    let qS = queryString.parse(this.props.history.location.search);
    let aziendaId = this.props.match.params.aziendaId;
    let dipendentiId = this.props.match.params.dipendenteId;
    if (!qS.type) {
      if (aziendaId > 0)
        this.props.history.push('/admin/aziende/0');
      else if (aziendaId == 0)
        this.props.history.push('/admin/dipendenti/0');
    }
    else if (qS.type === "fromVersamenti")
      this.props.history.push('/admin/DipendentiXAzienda/' + aziendaId + "?type=" + qS.type + "&typeId=" + dipendentiId);

    else if (qS.type === "fromModificaRichieste")
      this.props.history.push('/admin/DipendentiXAzienda/' + aziendaId + "?type=" + qS.type + '&typeId=' + qS.typeId);

    else if (qS.type === "fromRichieste")
      this.props.history.push('/admin/DipendentiXAzienda/' + aziendaId + "?type=" + qS.type + '&typeId=' + qS.typeId);

    else if (qS.type === "fromModificaDipendente")
      this.props.history.push("/admin/ModificaDipendente/" + dipendentiId);
  }

  render() {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <Button
                  icon="pi pi-arrow-left"
                  onClick={this.backButtonClick}
                  className="p-button-rounded p-button-primary p-button-sm float-left"
                  style={{ verticalAlign: "bottom", marginRight: "2px" }}
                />

                <h4
                  style={{
                    lineHeight: "0",
                    display: "inline",
                    verticalAlign: "bottom"
                  }}
                >
                  Dipendente
                </h4>
              </CardHeader>

              <CardBody>
                <GridContainer>
                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Nominativo"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.dipendente.nominativo || ""
                      }}
                    />
                  </GridItem>

                  <GridItem xs={1}></GridItem>

                  <GridItem xs={3}>
                    <CustomInput
                      labelText="Codice Fiscale"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        disabled: true,
                        value: this.state.dipendente.cf || ""
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}
