import Utils from "./Utils";

const LavoroAPI = {
  GetAllTipoDenuncia: async function () {
    let result = await Utils.CreatePostRequest("coasco/unitipoden/getall", {});
    return result.result;
  },

  GetAllTipoContratto: async function () {
    const result = await Utils.CreatePostRequest(
      "coasco/unitipocontratto/getall",
      {}
    );
    return result.result;
  },

  GetAllTipoLavoratore: async function () {
    const result = await Utils.CreatePostRequest("coasco/unitipolav/getall", {});
    return result.result;
  },

  GetAllQ1: async function () {
    const result = await Utils.CreatePostRequest("coasco/uniq1/getall", {});
    return result.result;
  },

  GetAllQ2: async function () {
    const result = await Utils.CreatePostRequest("coasco/uniq2/getall", {});
    return result.result;
  },

  GetAllQ3: async function () {
    const result = await Utils.CreatePostRequest("coasco/uniq3/getall", {});
    return result.result;
  },

  GetAllTipoProfili: async function () {
    let result = await Utils.CreatePostRequest("coasco/tipoprofiliprof/getall");
    if (result === null) result = [];
    return result.result;
  }
};
export default LavoroAPI;
