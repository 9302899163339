import * as AppConstants from "../helpers/AppConstants";
import moment from "moment";
import axios from "axios";
import { getToken } from "common";
import { utils, write } from "xlsx";
import saveAs from "file-saver";

const Utils = {
  LogOut: async function () {
    await this.CreatePostRequest("auth/logout", null);

    localStorage.removeItem("UserLogin_Token");
    localStorage.removeItem("UserLogin_Name");
  },

  HasToken: function () {
    var token = localStorage.getItem("UserLogin_Token");
    return token !== null && token.length > 0;
  },

  SetLoginUser: function (jwtToken, UserName) {
    localStorage.setItem("UserLogin_Token", jwtToken);
    localStorage.setItem("UserLogin_Name", UserName);
  },

  FormatISODateToCustom: function (date, format = "DD/MM/YYYY") {
    if (date === undefined || date === null) return "";
    if (date.length === 0) return "";

    var momentDate = moment(date);
    var formatedDate = momentDate.format(format);

    return formatedDate;
  },

  FormatDate: function (date) {
    if (date === null) return "";

    var d = date,
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },

  GetNextMonth: function (date) {
    if (date === null) return "";

    var d = date,
      month = "" + (d.getMonth() + 2),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  },

  GetYear: function (date) {
    if (date === null) return "";
    var d = date,
      year = d.getFullYear();
    return year;
  },

  FormatPrice: function (price, fractionDigits, currency = "") {
    if (price === null) return "";

    var p = price.toLocaleString("it-It", {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    });

    switch (currency) {
      case "EUR":
        p = "€ " + p;
        break;
      case "USD":
        p = "$ " + p;
        break;
      case "GBP":
        p = "£ " + p;
        break;
      default:
    }

    return p;
  },

  CreatePostRequestPartialEmpty: async function (partialUrl, jsonObject) {
    let resultObject = null;
    let dataPostObj = {
      method: "POST",
      credentials: "same-origin",
      mode: "same-origin",
      body: JSON.stringify(jsonObject),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    };

    await fetch(AppConstants.SiteUrl + partialUrl, dataPostObj)
      .then((response) => response.json())
      .then((json) => {
        resultObject = json;
      })
      .catch((error) => {
        resultObject = null;
      });

    return resultObject;
  },

  CreatePostRequest: async function (partialUrl, jsonObject) {
    let resultObject = null;
    let dataPostObj = {
      method: "POST",
      body: JSON.stringify(jsonObject),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    };

    await fetch(AppConstants.SiteUrl + partialUrl, dataPostObj)
      .then((response) => response.json())
      .then((json) => {
        resultObject = json;
      })
      .catch((error) => {
        resultObject = null;
      });
    return resultObject;
  },

  CreatePutRequest: async function (partialUrl, jsonObject) {
    let resultObject = null;
    let dataPostObj = {
      method: "PUT",
      body: JSON.stringify(jsonObject),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    };

    await fetch(AppConstants.SiteUrl + partialUrl, dataPostObj)
      .then((response) => response.json())
      .then((json) => {
        resultObject = json;
      })
      .catch(() => {
        resultObject = null;
      });
    return resultObject;
  },

  CreatePostRequestDownloadZip: async function (
    partialUrl,
    jsonObject,
    fileName
  ) {
    let resultObject = null;

    axios
      .request({
        url: AppConstants.SiteUrl + partialUrl,
        method: "POST",
        data: JSON.stringify(jsonObject),
        headers: {
          Accept: "application/octet-stream",
          "Content-Type": "application/json",
          Authorization: getToken() && `Bearer ${getToken()}`
        },
        responseType: "blob"
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));

        const link = document.createElement("a");

        link.href = downloadUrl;

        link.setAttribute("download", `${fileName}.zip`);

        document.body.appendChild(link);

        link.click();

        link.remove();
      });
    return resultObject;
  },

  CreatePostRequestDownloadFile: async function (url, fileName, extension) {
    await axios
      .request({
        url,
        method: "POST",
        data: JSON.stringify({}),
        headers: {
          Accept: "application/octet-stream",
          "Content-Type": "application/json",
          Authorization: getToken() && `Bearer ${getToken()}`
        },
        responseType: "blob"
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${fileName}.${extension}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  },

  httpGetDownloadFile: async function (url, fileName, extension) {
    await axios
      .request({
        url,
        method: "GET",
        data: JSON.stringify({}),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getToken() && `Bearer ${getToken()}`
        },
        responseType: "blob"
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${fileName}.${extension}`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  },

  CreatePostRequestOpenPdf: async function (url, fileName, extension) {
    await axios
      .request({
        url,
        method: "POST",
        data: JSON.stringify({}),
        headers: {
          Accept: "application/octet-stream",
          "Content-Type": "application/json",
          Authorization: getToken() && `Bearer ${getToken()}`
        },
        responseType: "blob"
      })
      .then(({ data }) => {
        var file = new Blob([data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  },

  httpGetOpenPdf: async function (url, fileName, extension) {
    await axios
      .request({
        url,
        method: "GET",
        data: JSON.stringify({}),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getToken() && `Bearer ${getToken()}`
        },
        responseType: "blob"
      })
      .then(({ data }) => {
        var file = new Blob([data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
  },

  SendPostRequestTo: async (url, body) => {
    let request = {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    };
    return await fetch(url, request)
      .then((resp) => resp.json())
      .catch((error) => console.error(error));
  },

  SendPostRequestToNoJson: async (url, body, fileName) => {
    let request = {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/pdf",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    };
    return await fetch(url, request)
      .then((resp) => resp.blob())
      .then((ris) => {
        var url = window.URL.createObjectURL(ris);
        var a = document.createElement("a");
        a.href = url;
        a.download = `${fileName}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => console.error(error));
  },

  SendMultiDataRequestTo: async (url, body) => {
    let request = {
      method: "POST",
      mode: "no-cors",
      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    };
    return await fetch(url, request)
      .then((resp) => resp)
      .catch((error) => console.error(error));
  },

  SendPutRequestTo: async (url, body) => {
    let request = {
      method: "PUT",
      mode: "cors",

      body: JSON.stringify(body),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    };
    return await fetch(url, request)
      .then((resp) => resp.json())
      .catch((error) => console.error(error));
  },

  SendDeleteRequestTo: async (url) => {
    let request = {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    };
    return await fetch(url, request).then((resp) => resp.json());
  },

  CreatePostRequestEmpty: async function (FullURL, jsonObject) {
    let resultObject = null;
    let dataPostObj = {
      method: "POST",
      credentials: "same-origin",
      mode: "same-origin",
      body: JSON.stringify(jsonObject),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    };
    await fetch(FullURL, dataPostObj)
      .then((response) => response.json())
      .then((json) => {
        resultObject = json;
      })
      .catch((error) => {
        resultObject = null;
      });

    return resultObject;
  },

  PostWithWWWForm: async function (FullURL, details) {
    let result = null;

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    await fetch(FullURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: getToken() && `Bearer ${getToken()}`
      },
      body: formBody
    })
      .then((response) => response.json())
      .then((json) => {
        result = json;
      });

    return result;
  },

  CreatePostRequestWithStatus: async function (partialUrl, jsonObject) {
    let resultObject = null;

    let dataPostObj = {
      method: "POST",
      credentials: "same-origin",
      mode: "same-origin",
      body: JSON.stringify(jsonObject),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    };
    await fetch(AppConstants.SiteUrl + partialUrl, dataPostObj)
      .then((response) => response.json())
      .then((json) => {
        resultObject = json;
      })
      .catch((error) => {
        resultObject = error;
        console.error(error);
      });

    return resultObject;
  },

  CreatePutRequestWithFile: async function (partialUrl, jsonObject, files) {
    const formData = new FormData();
    if (jsonObject.testomail) {
      formData.append(
        "testomail",
        new Blob([jsonObject.testomail], { type: "text/plain" })
      );
      delete jsonObject.testomail;
    }
    formData.append(
      "corr",
      new Blob([JSON.stringify(jsonObject)], { type: "application/json" })
    );
    if (files) files.forEach((file) => formData.append("attachs", file));

    return axios({
      url: AppConstants.SiteUrl + partialUrl,
      method: "PUT",
      data: formData,
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    })
      .then((resp) => {
        return new Blob([resp.data]);
      })
      .then((ris) => {
        var url = window.URL.createObjectURL(ris);
        var a = document.createElement("a");
        a.href = url;
        a.download = "lettera.pdf";
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => {
        console.error(error);
      });
  },

  CreatePostRequestWithFile: async function (
    partialUrl,
    senderId,
    recipientEmails,
    object,
    jsonObject,
    files
  ) {
    const formData = new FormData();
    object &&
      formData.append("oggetto", new Blob([object], { type: "text/plain" }));
    senderId &&
      formData.append(
        "idMitt",
        new Blob([senderId], { type: "application/json" })
      );
    recipientEmails &&
      recipientEmails.length > 0 &&
      formData.append(
        "dest",
        new Blob([recipientEmails], { type: "application/json" })
      );

    if (jsonObject?.testomail) {
      formData.append(
        "testomail",
        new Blob([jsonObject?.testomail], { type: "text/plain" })
      );
      delete jsonObject?.testomail;
    }
    formData.append(
      "corr",
      new Blob([JSON.stringify(jsonObject)], { type: "application/json" })
    );
    if (files) files.forEach((file) => formData.append("attachs", file));

    return axios({
      url: AppConstants.SiteUrl + partialUrl,
      method: "POST",
      data: formData,
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        console.error(error);
      });
  },

  CreatePostRequestWithFileMail: async function (
    partialUrl,
    senderId,
    recipientEmails,
    object,
    jsonObject,
    files
  ) {
    const formData = new FormData();
    object &&
      formData.append("oggetto", new Blob([object], { type: "text/plain" }));
    senderId &&
      formData.append(
        "idMitt",
        new Blob([senderId], { type: "application/json" })
      );
    recipientEmails &&
      recipientEmails.length > 0 &&
      formData.append(
        "dest[]",
        new Blob([JSON.stringify(recipientEmails)], {
          type: "application/json"
        })
      );

    if (jsonObject.testomail) {
      formData.append(
        "testomail",
        new Blob([jsonObject.testomail], { type: "text/plain" })
      );
      delete jsonObject.testomail;
    }
    formData.append(
      "corr",
      new Blob([JSON.stringify(jsonObject)], { type: "application/json" })
    );
    if (files) files.forEach((file) => formData.append("attachs", file));

    return axios({
      url: AppConstants.SiteUrl + partialUrl,
      method: "POST",
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    })
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        console.error(error);
      });
  },

  CreatePostRequestFile: async function (partialUrl) {
    await axios({
      url: AppConstants.SiteUrl + partialUrl,
      method: "POST",
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", "lettera.pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error(error);
      });
  },

  CreateDeleteRequest: async function (partialUrl, jsonObject) {
    let resultObject = null;
    let dataPostObj = {
      method: "DELETE",
      mode: "cors",
      body: JSON.stringify(jsonObject),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    };
    await fetch(AppConstants.SiteUrl + partialUrl, dataPostObj)
      .then((response) => response.json())
      .then((json) => {
        resultObject = json;
      })
      .catch((error) => {
        resultObject = null;
        console.error(error);
      });

    return resultObject;
  },

  CreateGetRequest: async function (partialUrl) {
    let resultObject = null;
    let dataPostObj = {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: getToken() && `Bearer ${getToken()}`,
        dataType: "json"
      }
    };

    await fetch(AppConstants.SiteUrl + partialUrl, dataPostObj)
      .then((response) => response.json())
      .then((responseJson) => {
        resultObject = responseJson;
      })
      .catch((error) => {
        console.error(error);
      });
    return resultObject.result;
  },

  CreateGenericGetRequest: async function (url) {
    let resultObject = null;
    let dataPostObj = {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    };

    await fetch(url, dataPostObj)
      .then((response) => response.json())
      .then((responseJson) => {
        resultObject = responseJson;
      })
      .catch((error) => {
        console.error(error);
      });

    return resultObject;
  },

  RefreshToken: async function (jwtToken) {
    AppConstants.JwtToken = jwtToken;

    localStorage.setItem("UserLogin_Token", jwtToken);
  },

  RefreshEmail: async function (userEmail) {
    localStorage.setItem("UserLogin_Email", userEmail);
    AppConstants.UserEmail = userEmail;
  },

  GetParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },

  CheckAllFieldNotEmpty(obj) {
    return Object.values(obj).every((value) => {
      return value !== null && value.toString() !== "";
    });
  },

  ExportExcel(rows) {
    const worksheet = utils.json_to_sheet(rows);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
    const excelBuffer = write(workbook, { bookType: "xlsx", type: "array" });
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const data = new Blob([excelBuffer], { type: EXCEL_TYPE });
    saveAs(data, "export_" + new Date().getTime() + ".xlsx");
  },

  CreatePostRequestSendFilesDownloadZip: async function (
    partialUrl,
    files,
    fileName
  ) {
    let resultObject = null;
    const formData = new FormData();
    if (files) files.forEach((file) => formData.append("files", file));

    await axios
      .request({
        url: AppConstants.SiteUrl + partialUrl,
        method: "POST",
        data: formData,
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
          Authorization: getToken() && `Bearer ${getToken()}`
        },
        responseType: "blob"
      })
      .then(async (json) => {
        if (json?.headers["content-type"] === "application/zip") {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([json?.data])
          );
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {
          resultObject = JSON.parse(await json.data.text());
        }
      });
    return resultObject;
  },

  CreatePostRequestDownloadReport: async function (partialUrl) {
    await axios({
      url: AppConstants.SiteUrl + partialUrl,
      method: "POST",
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    })
      .then((response) => {
        saveAs(
          new Blob([response.data]),
          response.headers["content-disposition"].split("=")[1]
        );
      })
      .catch((error) => {
        console.error(error);
      });
  },

  httpGetDownloadZip: async function (partialUrl, fileName) {
    await axios({
      url: AppConstants.SiteUrl + partialUrl,
      method: "GET",
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    })
      .then((response) => {
        saveAs(new Blob([response.data]), `${fileName}.zip`);
      })
      .catch((error) => {
        console.error(error);
      });
  },

  httpPostWithStatus: async function (partialUrl, files, elements, name) {
    const formData = new FormData();
    let resStatus;
    if (files) files.forEach((file) => formData.append("files", file));
    elements &&
    elements.length > 0 &&
      formData.append(
        name,
        new Blob([JSON.stringify(elements)], { type: "application/json" })
      );

    await axios({
      url: AppConstants.SiteUrl + partialUrl,
      method: "POST",
      data: formData,
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    })
      .then((res) => {
        resStatus = res?.status;
      })
      .catch((error) => {
        console.error("🚀 -> error", error);
      });
    return resStatus;
  },
  httpPostWithStatusDownload: async function (partialUrl, elements, name) {
    const formData = new FormData();
    elements &&
    elements.length > 0 &&
      formData.append(
        name,
        new Blob([JSON.stringify(elements)], { type: "application/json" })
      );

    return axios({
      url: AppConstants.SiteUrl + partialUrl,
      method: "POST",
      data: formData,
      responseType: "blob",
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        Authorization: getToken() && `Bearer ${getToken()}`
      }
    })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.error("🚀 -> error", error);
      });
  }
};

export default Utils;