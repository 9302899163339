import Utils from "./Utils";

const AnagraficaAPI = {
  getComunicazioniAnagrReport: async function (ufficio) {
    const url = `coasco/reportcomunicazionianagrafica/retrieveall?progetto=`+ ufficio;
    const res = await Utils.CreateGetRequest(url);
    return res;
  },

  downloadReportComunicazioniAnagr: async function (id) {
    await Utils.httpGetDownloadZip(
      `coasco/reportcomunicazionianagrafica/download/${id}`,
      "Lettere Comunicazioni Anagrafica"
    );
  }
};
export default AnagraficaAPI;
